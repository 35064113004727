import { Button } from '@mui/material';
import React from 'react';

export default function ActionButton({ Icon = () => <></>, textColor, backgroundColor, onClick, children }) {
    return (
        <Button
            startIcon={<Icon />}
            sx={{ color: textColor, backgroundColor, borderRadius: '10px', '&:hover': { backgroundColor } }}
            onClick={onClick}
        >
            {children}
        </Button>
    );
}
