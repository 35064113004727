import theme from '../../config/style/muiTheme';

const styles = {
    container: {
        marginTop: '40px',
    },
    title: {
        color: '#2C5871',
        fontWeight: '400',
    },
    grid: {
        margin: "60px auto 0",
        maxWidth: "1200px",
    },
    illustrationContainer: {
        display: 'flex',
        justifyContent: {
            xs: 'center',
            sm: 'center',
            md: 'center',
            lg: 'flex-start',
        },
    },
    illustration: {
        maxWidth: {
            xs: "150px",
            sm: "250px",
            md: "450px",
        },
        marginLeft: {
            xs: '0px',
            sm: '0px',
            md: '0px',
            lg: '120px'
        }
    },
    copy: {
        bottom: 10,
        fontSize: {
            xs: '0.7rem',
            sm: '0.7rem',
            md: '0.7rem',
        },
        marginTop: 3,
        padding: '10px 30px',
        position: {
            xs: 'static',
            sm: 'static',
            md: 'static',
            lg: 'absolute',
        },
        textAlign: 'center',
        width: {
            xs: '90%',
            sm: '90%',
            md: '90%',
            lg: '70%',
        },
    },
};


export default styles;
