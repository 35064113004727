import theme from '../../../../../config/style/muiTheme';

const styles = {
    EmptycomponentListItem: {
        textAlign: 'center',
        color: theme.palette.purple[100],
    },
};

export default styles;
