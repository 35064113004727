import React from 'react';
import { Button, Popover, Box, CircularProgress } from '@mui/material';
import { activateConversation } from 'comm-recipientapp-shared/src/api/chat';
import { utils, hooks } from 'comm-recipientapp-shared';
import styles from './styles';

function PopoverModal(props) {
    const { 
        conversationId, 
        popover, 
        open, 
        onClose, 
        anchorEl, 
        chatUser, 
        currentChat 
    } = props;

    const [disableDeleteDirectMessageButton, setDisableDeleteDirectMessageButton] = React.useState(false);

    const strings = utils.useStrings();

    const { getMemberOfByChatUser } = hooks.chat.useChat();

    const handleCloseModal = e => {
        setDisableDeleteDirectMessageButton(false);
        onClose(e);
    };

    const handleActivate = async e => {
        e.stopPropagation();
        setDisableDeleteDirectMessageButton(true);
        const { customerid, personid } = chatUser;
        const postBody = {
            customerid,
            personid,
            activate: false,
        };

        try {
            const { status } = await activateConversation(conversationId, postBody);
            if (status === 200) {
                await getMemberOfByChatUser(chatUser, currentChat, conversationId);            }
        } catch (error) {
            /* empty */
        }
        handleCloseModal(e);
    };

    return (
        <Popover
            data-popover={popover}
            open={open}
            anchorEl={anchorEl}
            onClose={handleCloseModal}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <Box sx={styles.ButttonBox} tabIndex={-1}>
                <Button sx={styles.leaveGroup} disabled={disableDeleteDirectMessageButton} onClick={handleActivate} aria-label={strings.DELETE}>
                    {strings.DELETE}
                </Button>
                {disableDeleteDirectMessageButton && <CircularProgress size={24} sx={styles.CircularProgress} />}
            </Box>
        </Popover>
    );
}

export default PopoverModal;
