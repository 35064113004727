import React from 'react';
import { Box } from '@mui/material';
import Emergency from '../Icons/Emergency';
import BroadcastOnPersonalIcon from '@mui/icons-material/BroadcastOnPersonal';
import styles from './styles';

export default function CategoryItem({ category }) {

    return (
        <Box
            sx={{
                ...styles.initials_container,
            }}
        >
            {(category.priority === 1) ? <Emergency></Emergency> : <BroadcastOnPersonalIcon color="primary"></BroadcastOnPersonalIcon>}
        </Box>
    );
}
