import { Button as MaterialUIButton, styled } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

const styles = {
    padding: '7px 12px 9px',
    background: '#EFF0F4',
    color: '#535764',
    fontWeight: '500',
    lineHeight: '150%',
    fontSize: '16px',
    width: '120px',
};

const Button = styled(MaterialUIButton)(styles);

export const WithLoader = styled(LoadingButton)(styles);

export default Button;
