import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import FormControlLabel from '@mui/material/FormControlLabel';
import { utils } from 'comm-recipientapp-shared';
import { handleEnterKeyPress } from 'comm-recipientapp-shared/src/utils/helpers';
import Typography from '@mui/material/Typography';
import styles from './styles';

function EmailCard({ preferences, item, activeEmail, ...props }) {
    const strings = utils.useStrings();
    const currentEmail = preferences.emails?.find(val => val.email === activeEmail?.email);
    const emailMeChecked = currentEmail?.notificationTypes?.includes(item.id);

    return (
        <Card sx={styles.message_card} tabIndex="0">
            <Box sx={styles.message_card_bg}>
                <Typography sx={styles.message_card_title} variant="h5">
                    {item.name}
                </Typography>
            </Box>
            <Box sx={styles.message_card_bottom}>
                <RadioGroup name="radio-buttons-group">
                    <FormControlLabel
                        value="email"
                        checked={emailMeChecked}
                        aria-label={`${strings.EMAIL_ME}, ${emailMeChecked ? strings.CHECKED : strings.UNCHECKED}`}
                        data-testid={`email_checked_${props.index}`}
                        onClick={() => props.handleUpdateEmail(item.id)}
                        onKeyDown={handleEnterKeyPress(() => props.handleUpdateEmail(item.id))}
                        control={<Radio checkedIcon={<CheckBoxIcon />} icon={<CheckBoxOutlineBlankIcon />} aria-hidden />}
                        label={<Typography aria-hidden>{strings.EMAIL_ME}</Typography>}
                        tabIndex="0"
                    />
                    {item.type === utils.constants.SURVEY && (
                        <Typography sx={styles.survey_title} variant="subtitle2">
                            {strings.SURVEYS_ARE_NOT_DISPLAYED_IN_SCHOOLMESSENGER_MESSAGES}
                        </Typography>
                    )}
                </RadioGroup>
            </Box>
        </Card>
    );
}

export default EmailCard;
