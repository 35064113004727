/* eslint-disable import/prefer-default-export */
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import React from 'react';

export const useStrings = () => {
    const { t } = useTranslation();

    return {
        //MOBILE
        CONSENT_POPUP_HEADER: t('ConsentPopup.POPUP_HEADER'),
        CONSENT_POPUP_DESCRIPTION: t('ConsentPopup.POPUP_DESCRIPTION'),
        CONSENT_POPUP_CONFIRM_MESSAGE: t('ConsentPopup.POPUP_CONFIRM_MESSAGE'),
        CONSETN_POPUP_SELECT_DEFAULT_OPTION: t('ConsentPopup.SELECT_DEFAULT_OPTION'),
        ABSENCE_CONNECTOR_ESTIMATED_ARRIVAL_TIME: t('AttendanceConfiguration.absencePopup.connectors.ESTIMATED_ARRIVAL_TIME'),
        ABSENCE_CONNECTOR_FOR: t('AttendanceConfiguration.absencePopup.connectors.FOR'),
        ABSENCE_CONNECTOR_FROM: t('AttendanceConfiguration.absencePopup.connectors.FROM'),
        ABSENCE_CONNECTOR_LEAVE_AND_RETURN: t('AttendanceConfiguration.absencePopup.connectors.LEAVE_AND_RETURN'),
        ABSENCE_CONNECTOR_LEAVING_SCHOOL: t('AttendanceConfiguration.absencePopup.connectors.LEAVING_SCHOOL'),
        ABSENCE_CONNECTOR_TO: t('AttendanceConfiguration.absencePopup.connectors.TO'),
        ABSENCE_DELETE_DELETE_MESSAGE: t('AttendanceConfiguration.absencePopup.deleteSection.DELETE_MESSAGE'),
        ABSENCE_DELETE_TITLE: t('AttendanceConfiguration.absencePopup.deleteSection.DELETE_TITLE'),
        ABSENCE_EDIT_COMMENTS_PLACEHOLDER: t('AttendanceConfiguration.absencePopup.editSection.fieldLabels.COMMENTS_PLACEHOLDER'),
        ABSENCE_EDIT_FIELD_DATE_OF_ABSENCE: t('AttendanceConfiguration.absencePopup.editSection.fieldLabels.DATE_OF_ABSENCE'),
        ABSENCE_EDIT_FIELD_LAST_DAY_OF_ABSENCE: t('AttendanceConfiguration.absencePopup.editSection.fieldLabels.LAST_DAY_OF_ABSENCE'),
        ABSENCE_EDIT_FIELD_RETURNING_AT_SCHOOL_AT: t('AttendanceConfiguration.absencePopup.editSection.fieldLabels.RETURNING_AT_SCHOOL_AT'),
        ABSENCE_EDIT_FIELD_TIME_OF_DEPARTURE: t('AttendanceConfiguration.absencePopup.editSection.fieldLabels.TIME_OF_DEPARTURE'),
        ABSENCE_EDIT_TITLE_ATTACHMENTS: t('AttendanceConfiguration.absencePopup.titles.ADD_ATTACHMENT'),
        ABSENCE_EMPTY_VIEW_MESSAGE: t('AttendanceConfiguration.emptyView.MESSAGE'),
        ABSENCE_FILTER_ALL_KYDS: t('mobile.absenceScreen.all_my_kids'),
        ABSENCE_FILTER_BUTTON: t('mobile.absenceScreen.show_absences'),
        ABSENCE_FILTER_TEXT: t('mobile.absenceScreen.filter_absence_text'),
        ABSENCE_FORM_ATTACHMENT_BUTTON: t('mobile.absenceScreen.attachments_button'),
        ABSENCE_FORM_ATTACHMENT_CONFIRM: t('mobile.absenceScreen.attachments_confirm'),
        ABSENCE_FORM_ATTACHMENT_INFO: t('mobile.absenceScreen.attachments_info'),
        ABSENCE_FORM_ATTACHMENT_TAKE_PHOTO: t('mobile.absenceScreen.attachments_take_photo'),
        ABSENCE_FORM_ATTACHMENT_MAX_SIZE_FILE: t('mobile.absenceScreen.attachments_max_size'),
        ABSENCE_FORM_ATTACHMENT_UPLOAD_FILE: t('mobile.absenceScreen.attachments_upload_file'),
        ABSENCE_FORM_ATTACHMENT_SELECT_PHOTO: t('mobile.absenceScreen.attachments_select_photo'),
        ABSENCE_FORM_ATTACHMENT: t('mobile.absenceScreen.attachments_label'),
        ABSENCE_FORM_COMMENT_PLACEHOLDER: t('mobile.absenceScreen.comments_placeholder'),
        ABSENCE_FORM_COMMENT: t('mobile.absenceScreen.comments_label'),
        ABSENCE_FORM_DESCRIPTION: t('mobile.absenceScreen.description'),
        ABSENCE_FORM_EDIT_DESCRIPTION: t('mobile.absenceScreen.edit_description'),
        ABSENCE_FORM_EDIT_TITLE: t('mobile.absenceScreen.edit_title'),
        ABSENCE_FORM_PARAGRAPH_ONE: t('mobile.absenceScreen.paragraph_one'),
        ABSENCE_FORM_PARAGRAPH_TWO: t('mobile.absenceScreen.paragraph_two'),
        ABSENCE_FORM_REASON_PLACEHOLDER: t('mobile.absenceScreen.reason_placeholder'),
        ABSENCE_FORM_SET_DATE: t('mobile.absenceScreen.set_date'),
        ABSENCE_FORM_SET_TIME: t('mobile.absenceScreen.set_time'),
        ABSENCE_FORM_TITLE: t('mobile.absenceScreen.title'),
        ABSENCE_GENERIC_ERROR_TITLE: t('AttendanceConfiguration.absencePopup.editSection.fieldLabels.ERROR_MESSAGE'),
        ABSENCE_POPUP_ATTACHMENTS: t('AttendanceConfiguration.absencePopup.cardTitles.ATTACHMENTS'),
        ABSENCE_POPUP_COMMENTS: t('AttendanceConfiguration.absencePopup.cardTitles.COMMENTS'),
        ABSENCE_POPUP_DELETE_BUTTON: t('AttendanceConfiguration.absencePopup.buttons.DELETE'),
        ABSENCE_POPUP_EDIT_BUTTON: t('AttendanceConfiguration.absencePopup.buttons.EDIT'),
        ABSENCE_POPUP_NOT_EDITABLE_ABSENCE: t('AttendanceConfiguration.absencePopup.cardTitles.NOT_EDITABLE_ABSENCE'),
        ABSENCE_POPUP_REASON: t('AttendanceConfiguration.absencePopup.cardTitles.REASON'),
        ABSENCE_POPUP_SAVE_BUTTON: t('AttendanceConfiguration.absencePopup.buttons.SAVE'),
        ABSENCE_POPUP_CANCEL_BUTTON: t('AttendanceConfiguration.absencePopup.buttons.CANCEL'),
        ABSENCE_POPUP_TYPE_OF_ABSENCE: t('AttendanceConfiguration.absencePopup.cardTitles.TYPE_OF_ABSENCE'),
        ABSENCE_POPUP_WHEN: t('AttendanceConfiguration.absencePopup.cardTitles.WHEN'),
        ABSENCE_REASON_APT: t('AttendanceConfiguration.absenceReasons.APT'),
        ABSENCE_REASON_BER: t('AttendanceConfiguration.absenceReasons.BER'),
        ABSENCE_REASON_ILL: t('AttendanceConfiguration.absenceReasons.ILL'),
        ABSENCE_REASON_MSB: t('AttendanceConfiguration.absenceReasons.MSB'),
        ABSENCE_REASON_OTH: t('AttendanceConfiguration.absenceReasons.OTH'),
        ABSENCE_REASON_REL: t('AttendanceConfiguration.absenceReasons.REL'),
        ABSENCE_REASON_VAC: t('AttendanceConfiguration.absenceReasons.VAC'),
        ABSENCE_REASON_WEA: t('AttendanceConfiguration.absenceReasons.WEA'),
        ABSENCE_SYNC_COMPLETE_BANNER_TITLE: t('AttendanceConfiguration.syncPopup.BANNER_TITLE'),
        ABSENCE_SYNC_COMPLETE_MESSAGE: t('AttendanceConfiguration.syncPopup.MESSAGE'),
        ABSENCE_SYNC_COMPLETE_OK_BUTTON: t('AttendanceConfiguration.syncPopup.buttons.OK'),
        UNEXPLAINED_ABSENCE_SURFACE_TITLE: t('AttendanceConfiguration.unexplainedAbsences.SURFACE_TITLE'),
        UNEXPLAINED_ABSENCE_ABSENCE_TYPE: t('AttendanceConfiguration.unexplainedAbsences.ABSENCE_TYPE'),
        UNEXPLAINED_ABSENCE_ABSENCE_REASON: t('AttendanceConfiguration.unexplainedAbsences.PROVIDE_A_REASON_TEXT'),
        UNEXPLAINED_ABSENCE_POPUP_TITLE: t('AttendanceConfiguration.unexplainedAbsences.popup.TITLE'),
        UNEXPLAINED_ABSENCE_POPUP_DESCRIPTION: t('AttendanceConfiguration.unexplainedAbsences.popup.DESCRIPTION'),
        UNEXPLAINED_ABSENCE_POPUP_CANCEL_BUTTON: t('AttendanceConfiguration.unexplainedAbsences.popup.buttons.CANCEL'),
        UNEXPLAINED_ABSENCE_POPUP_SAVE_BUTTON: t('AttendanceConfiguration.unexplainedAbsences.popup.buttons.SAVE'),
        UNEXPLAINED_ABSENCE_POPUP_FOOTER_DESCRIPTION: t('AttendanceConfiguration.unexplainedAbsences.popup.FOOTER_DESCRIPTION'),
        AFTER_AUTHENTICATING: t('learnMoreScreen.afterAuthenticating'),
        AFTER_SUCCESSFULLY_LOGGING_IN: t('learnMoreScreen.afterSuccessfullyLoggingIn'),
        ALL_FILTER_BUTTON: t('AttendanceConfiguration.filterBarSection.buttons.ALL'),
        AM_PM: t('AttendanceConfiguration.absencePopup.timeSelection.ampm'),
        APPLY_FILTERS: t('mobile.filterScreen.applyFilters'),
        ATTENDANCE_EXAM: t('AttendanceConfiguration.holidaysConfiguration.types.EXAM'),
        ATTENDANCE_HOLIDAY: t('AttendanceConfiguration.holidaysConfiguration.types.HOLIDAY'),
        ATTENDANCE_PIN_DISCLAIMER: t('mobile.attendanceScreen.pinDisclaimer'),
        ATTENDANCE_PIN_MANAGE: t('mobile.attendanceScreen.managePin'),
        ATTENDANCE_PIN_NOTE: t('mobile.attendanceScreen.pinNote'),
        ATTENDANCE_PIN_SELECT_STUDENT_TITLE: t('mobile.attendanceScreen.pinSelectStudentTitle'),
        ATTENDANCE_PIN_SELECT_STUDENT: t('mobile.attendanceScreen.pinSelectStudent'),
        ATTENDANCE_PIN_SETTINGS: t('mobile.attendanceScreen.pinSettings'),
        ATTENDANCE_PIN: t('mobile.attendanceScreen.pin'),
        ATTENDANCE_TIME_SHOULD_BE_BETWEEN_START_AND_END: (startTime, endTime) =>
            t('mobile.attendanceScreen.timeShouldBeBetweenStartAndEnd', { startTime, endTime }),
        ATTENDANCE_UNEXPLAINED_ABSENCE_COUNT: count => t('mobile.attendanceScreen.unexplainedabsence', { count }),
        ATTENDANCE_UNEXPLAINED_ABSENCES_COUNT: count => t('mobile.attendanceScreen.unexplainedabsences', { count }),
        CHANGE_YOUR_SCHOOL_MESSENGER_COMMUNICATION_PREFERENCES: t('learnMoreScreen.changeYourSchoolMessengerCommunicationPreferences'),
        CHAT_WITH_TEACHERS_VIA_TEACHER_MESSAGING: t('learnMoreScreen.chatWithTeachersViaTeacherMessaging'),
        CLICK_SIGN_UP: t('learnMoreScreen.clickSignUp'),
        CODE_SENT: t('mobile.preferencesScreen.codeSent'),
        CREATE_FOUR_DIGIT_PIN: t('mobile.attendanceScreen.createFourDigitPin'),
        NEW_CODE_HAS_BEEN_SENT_TO: t('mobile.preferencesScreen.newCodeHasBeenSent'),
        IN_ORDER_TO_VERIFY_THE_EMAIL: t('mobile.preferencesScreen.inOrderToVerifyTheEmail'),
        CONTACTS_MISSING_SECTION_BANNER_TITLE: t('ContactsConfiguration.missingContactSection.BANNER_TITLE'),
        CONTACTS_MISSING_SECTION_MAIN_DESCRIPTION: t('ContactsConfiguration.missingContactSection.MAIN_DESCRIPTION'),
        CONTACTS_MISSING_SECTION_SECONDARY_DESCRIPTION: t('ContactsConfiguration.missingContactSection.SECONDARY_DESCRIPTION'),
        DATE_OF_ABSENCE: t('AttendanceConfiguration.absencePopup.editSection.fieldLabels.DATE_OF_ABSENCE'),
        DELETE_ITEM_MESSAGE: t('mobile.inboxScreen.deleteItem'),
        DO_YOU_HAVE_MORE_QUESTIONS: t('learnMoreScreen.doYouHaveMoreQuestions'),
        EARLY_DEPARTURE: t('AttendanceConfiguration.absenceTypes.earlyDeparture'),
        NEW_EMAIL_ADDED: t('mobile.preferencesScreen.newEmailAdded'),
        NEW_EMAIL_HAS_BEEN_ADDED_SUCCESSFULLY: t('mobile.preferencesScreen.newEmailAddedSuccessfully'),
        EMAIL_VERIFICATION: t('mobile.preferencesScreen.emailVerification'),
        ENTER_YOUR_EMAIL_ADDRESS: t('learnMoreScreen.enterYourEmailAddress'),
        ERROR_COMPONENT_LOGOUT_BUTTON: t('Error.ErrorComponent.buttons.LOGOUT'),
        ERROR_COMPONENT_RELOAD_BUTTON: t('Error.ErrorComponent.buttons.RELOAD'),
        EXPIRED_SESSION_MESSAGE: t('SessionManagement.EXPIRED_SESSION_MESSAGE'),
        FATAL_SERVER_ERROR_SNACKBAR: t('Error.ServerErrorSnackbar.FATAL_ERROR_MESSAGE'),
        FEEL_FREE_TO_EMAIL: t('learnMoreScreen.feelFreeToEmail'),
        FIRST_DAY_MUST_BE_AFTER_TERM_START: t('mobile.attendanceScreen.firstDayMustBeAfterTermStart'),
        WEEKENDS_NOT_ALLOWED: t('mobile.attendanceScreen.weekendsNotAllowed'),
        FORGOT_PASSOWRD_INPUT_FIELD_ERROR: t('A valid email is required, ex. jane@example.com'),
        FULL_DAY: t('AttendanceConfiguration.absenceTypes.fullDay'),
        GO_TO_LOGIN: t('ActivationAccount.GoToLogin'),
        PLANNED_ABSENCE_DETAILS_READONLY: t('mobile.attendanceScreen.plannedAbsenceReadonly'),
        TODAY_ABSENCE_DETAILS_READONLY: t('mobile.attendanceScreen.todayAbsenceReadonly'),
        PLANNED_ABSENCE_DETAILS_FUTURE: t('mobile.attendanceScreen.plannedAbsenceFuture'),
        HOURS: t('AttendanceConfiguration.absencePopup.timeSelection.hours'),
        HOW_DO_I_SIGN_UP_FOR_SCHOOL_MESSENGER: t('learnMoreScreen.howDoISignUpForSchoolMessenger'),
        INBOX_FOR_YOU_SECTION: t('NewInboxSecion.sections.NEW_FOR_YOU'),
        INBOX_PREVIOUSLY_READ_SECTION: t('NewInboxSecion.sections.PREVIOUSLY_READ'),
        INBOX_NO_MESSSAGES_TITLE: t('NewInboxSecion.NO_MESSAGES_TITLE'),
        INBOX_NO_MESSSAGES_DESCRIPTION: t('NewInboxSecion.NO_MESSAGES_DESCRIPTION'),
        IS_SCHOOLMESSENGER_SECURE: t('learnMoreScreen.isSchoolMessengerSecure'),
        ITS_THAT_EASY: t('learnMoreScreen.itsThatEasy'),
        LAST_DAY_MUST_BE_AFTER_FIRST_DAY: t('mobile.attendanceScreen.lastDayMustBeAfterFirstDay'),
        LAST_DAY_MUST_BE_BEFORE_TERM_END: t('mobile.attendanceScreen.lastDayMustBeBeforeTermEnd'),
        LATE: t('AttendanceConfiguration.absenceTypes.late'),
        LEARN_MORE_SCREEN_LEARN_MORE_ABOUT_SCHOOL_MESSENGER: t('learnMoreScreen.learnMoreAboutSchoolMessenger'),
        LEARN_MORE_SCREEN_WHAT_IS_SCHOOL_MESSENGER_CONTENT: t('learnMoreScreen.whatIsSchoolMessengerContent'),
        LEARN_MORE_SCREEN_WHAT_IS_SCHOOL_MESSENGER: t('learnMoreScreen.whatIsSchoolMessenger'),
        LEAVE_GROUP_CHAT: t('mobile.chatScreen.leaveGroup'),
        LEAVE_GROUP_CHAT_CONFIRMATION: t('mobile.chatScreen.leaveGroupConfirmation'),
        MANAGE_YOUR_STUDENT_ATTENDANCE_VIA_SAFEARRIVAL: t('learnMoreScreen.manageYourStudentAttendanceViaSafeArrival'),
        MESSAGE_TO_REQUIRE_PIN_TO_EXPLAIN_ABSENCES_MODAL: t('ConsentManageYourPin.modalBodyText'),
        MINUTES: t('AttendanceConfiguration.absencePopup.timeSelection.minutes'),
        MULTI_DAY: t('AttendanceConfiguration.absenceTypes.multiDay'),
        NETWORK_ERROR: t('mobile.errors.networkError'),
        NEW_INBOX_SEARCH_BAR_PLACEHOLDER: t('NewInboxSecion.searchBar.PLACEHOLDER'),
        NEW_INBOX_TITLE: t('NewInboxSecion.TITLE'),
        NOTHING_TO_SEE_YET: t('mobile.inboxScreen.emptyList.title'),
        USER_SETTINGS_NOTIFICATION_UPDATE_ERROR: t('mobile.userSettingsScreen.notificationUpdateError'),
        USER_SETTINGS_NOTIFICATION_UPDATE_ERROR_MESSAGE: t('mobile.userSettingsScreen.notificationUpdateErrorMessage'),
        NOTIFICATION_UPDATE_ERROR: t('mobile.notificationScreen.notificationUpdateError'),
        NOTIFICATION_UPDATE_ERROR_MESSAGE: t('mobile.notificationScreen.notificationUpdateErrorMessage'),
        OKAY: t('mobile.okay'),
        ONLY_SHOW: t('mobile.filterScreen.onlyShow'),
        OPEN_FILE_ERROR: t('mobile.errors.openFileError'),
        GENERIC_CHAT_ERROR: t('mobile.errors.genericChatError'),
        UNABLE_TO_PERFORM_ACTION_ERROR: t('mobile.errors.unableToPerformAction'),
        OPT_INTO_PUSH_NOTIFICATIONS_MOBILE_ONLY: t('learnMoreScreen.optIntoPushNotificationsMobileOnly'),
        PARTIAL_DAY: t('AttendanceConfiguration.absenceTypes.partialDay'),
        PLANNED_ABSENCE_DETAILS: t('mobile.attendanceScreen.plannedAbsenceDetails'),
        PREFERENCES_SET_PREFERENCES_TO_NUMBER_HEADER_TITLE_PHONE: t(
            'PreferencesConfiguration.contactInformation.setPreferencesToNumber.HEADER_TITLE_PHONE'
        ),
        PREFERENCES_SET_PREFERENCES_TO_NUMBER_HEADER_TITLE_EMAIL: t(
            'PreferencesConfiguration.contactInformation.setPreferencesToNumber.HEADER_TITLE_EMAIL'
        ),
        PREFERENCES_SET_PREFERENCES_TO_NUMBER_POPUP_TEXT: t('PreferencesConfiguration.contactInformation.setPreferencesToNumber.POPUP_MESSAGE'),
        PREFERENCES_SET_PREFERENCES_TO_NUMBER_YES_BUTTON: t('PreferencesConfiguration.contactInformation.setPreferencesToNumber.YES_BUTTON'),
        PREFERENCES_SET_PREFERENCES_TO_NUMBER_NO_BUTTON: t('PreferencesConfiguration.contactInformation.setPreferencesToNumber.NO_BUTTON'),
        PREFERENCES_ADD_EMAIL_BANNER_TITLE: t('PreferencesConfiguration.contactInformation.addEmail.BANNER_TITLE'),
        PREFERENCES_ADD_EMAIL_CANCEL_BUTTON: t('PreferencesConfiguration.contactInformation.addEmail.buttons.CANCEL'),
        PREFERENCES_ADD_EMAIL_ERROR_ALREADY_EXIST: t('PreferencesConfiguration.contactInformation.addEmail.errorMessages.EMAIL_ALREADY_EXIST'),
        PREFERENCES_ADD_EMAIL_ERROR_MAX_NUMBER_REACHED: t('PreferencesConfiguration.contactInformation.addEmail.errorMessages.MAX_NUMBER_REACHED'),
        PREFERENCES_ADD_EMAIL_FIELD_LABEL: t('PreferencesConfiguration.contactInformation.addEmail.FIELD_LABEL'),
        PREFERENCES_ADD_EMAIL_SAVE_BUTTON: t('PreferencesConfiguration.contactInformation.addEmail.buttons.SAVE'),
        PREFERENCES_ADD_EMAIL_SETUP_BANNER_TITLE: t('PreferencesConfiguration.contactInformation.addEmail.preferencesSetup.BANNER_TITLE'),
        PREFERENCES_ADD_EMAIL_SETUP_DESCRIPTION: t('PreferencesConfiguration.contactInformation.addEmail.preferencesSetup.DESCRIPTION'),
        PREFERENCES_ADD_EMAIL_SETUP_NO_BUTTON: t('PreferencesConfiguration.contactInformation.addEmail.preferencesSetup.buttons.NO'),
        PREFERENCES_ADD_EMAIL_SETUP_YES_BUTTON: t('PreferencesConfiguration.contactInformation.addEmail.preferencesSetup.buttons.YES'),
        PREFERENCES_ADD_NUMBER_BANNER_TITLE: t('PreferencesConfiguration.contactInformation.addPhoneNumber.BANNER_TITLE'),
        PREFERENCES_ADD_NUMBER_CANCEL_BUTTON: t('PreferencesConfiguration.contactInformation.addPhoneNumber.buttons.CANCEL'),
        PREFERENCES_ADD_NUMBER_DESCRIPTION: t('PreferencesConfiguration.contactInformation.addPhoneNumber.DESCRIPTION'),
        PREFERENCES_ADD_NUMBER_ERROR_ALREADY_EXIST: t(
            'PreferencesConfiguration.contactInformation.addPhoneNumber.errorMessages.NUMBER_ALREADY_EXIST'
        ),
        PREFERENCES_ADD_NUMBER_ERROR_MAX_NUMBER_REACHED: t(
            'PreferencesConfiguration.contactInformation.addPhoneNumber.errorMessages.MAX_NUMBER_REACHED'
        ),
        PREFERENCES_ADD_NUMBER_FIELD_LABEL: t('PreferencesConfiguration.contactInformation.addPhoneNumber.FIELD_LABEL'),
        PREFERENCES_ADD_NUMBER_SAVE_BUTTON: t('PreferencesConfiguration.contactInformation.addPhoneNumber.buttons.SAVE'),
        PREFERENCES_DELETE_CONTACT_BANNER_TITLE: t('PreferencesConfiguration.contactInformation.deleteContactInfo.BANNER_TITLE'),
        PREFERENCES_DELETE_CONTACT_CANCEL_BUTTON: t('PreferencesConfiguration.contactInformation.deleteContactInfo.buttons.CANCEL'),
        PREFERENCES_DELETE_CONTACT_DELETE_BUTTON: t('PreferencesConfiguration.contactInformation.deleteContactInfo.buttons.DELETE'),
        PREFERENCES_DELETE_CONTACT_DESCRIPTION: t('PreferencesConfiguration.contactInformation.deleteContactInfo.DESCRIPTION'),
        PREFERENCES_NOT_ALLOWED_DELELETE_CONTACT_BANNER_TITLE: t(
            'PreferencesConfiguration.contactInformation.notAllowedToDeleteContactInfo.BANNER_TITLE'
        ),
        PREFERENCES_NOT_ALLOWED_DELELETE_CONTACT_DESCRIPTION: t(
            'PreferencesConfiguration.contactInformation.notAllowedToDeleteContactInfo.DESCRIPTION'
        ),
        PREFERENCES_SNACKBAR_DELETED_EMAIL_MESSAGE: t('PreferencesConfiguration.contactInformation.snackbar.DELETED_EMAIL_MESSAGE'),
        PREFERENCES_SNACKBAR_DELETED_PHONE_MESSAGE: t('PreferencesConfiguration.contactInformation.snackbar.DELETED_PHONE_MESSAGE'),
        PREFERENCES_VERIFY_NUMBER_BANNER_TITLE: t('PreferencesConfiguration.contactInformation.verifyPhoneNumber.BANNER_TITLE'),
        PREFERENCES_VERIFY_NUMBER_CALL_OPTION: t('PreferencesConfiguration.contactInformation.verifyPhoneNumber.CALL_OPTION'),
        PREFERENCES_VERIFY_NUMBER_CANCEL_BUTTON: t('PreferencesConfiguration.contactInformation.verifyPhoneNumber.buttons.CANCEL'),
        PREFERENCES_VERIFY_NUMBER_DESCRIPTION: t('PreferencesConfiguration.contactInformation.verifyPhoneNumber.DESCRIPTION'),
        PREFERENCES_VERIFY_NUMBER_SEND_CODE_BUTTON: t('PreferencesConfiguration.contactInformation.verifyPhoneNumber.buttons.SEND_CODE'),
        PREFERENCES_VERIFY_NUMBER_SMS_OPTION: t('PreferencesConfiguration.contactInformation.verifyPhoneNumber.SMS_OPTION'),
        PREFERENCES_VERIFY_NUMBER_TITLE: t('PreferencesConfiguration.contactInformation.verifyPhoneNumber.TITLE'),
        PROCESS_TOKEN: t('ActivationAccount.ProcessToken'),
        RESET_PASSWORD_404_CODE_PART_ONE: t('Reset_password_404_code.Part_one'),
        RESET_PASSWORD_404_CODE_LINK_PART: t('Reset_password_404_code.Link_part'),
        RETURN_TIME_SHOULD_BE_AFTER_DEPARTURE_TIME: t('mobile.attendanceScreen.returnTimeMustBeAfterDepartureTime'),
        RETURN_TIME_SHOULD_BE_AT_LEAST_15_MINUTES_AFTER: t('mobile.attendanceScreen.returnTimeMustBeAtLeast15MinutesAfterDeparture'),
        SEARCH_DOTS: t('mobile.searchInput.placeholder'),
        SEE_YOUR_RELATED_SCHOOL_MESSENGER_CONTACTS_ACROSS_SCHOOLS_AND_DISTRICTS: t(
            'learnMoreScreen.seeYourRelatedSchoolMessengerContactsAcrossSchoolsAndDistricts'
        ),
        SELECT_AS_MANY_OPTIONS_YOU_WANT: t('mobile.filterScreen.selectAsMany'),
        SIGNING_UP_IS_EASY: t('learnMoreScreen.signingUpIsEasy'),
        SPLASH_WELCOME_TO: SchoolMessenger => t('splashComponent.WelcomeTo', { SchoolMessenger }),
        SPLASH_ALT_WElCOME_IMAGE: t('splashComponent.altWelcomeImage'),
        SPLASH_SCHOOLMESSENGER: t('splashComponent.SchoolMessenger'),
        SPLASH_BODY_MESSAGE_PART_ONE: t('splashComponent.BodyMessagePartOne'),
        SPLASH_BODY_MESSAGE_PART_TWO: t('splashComponent.BodyMessagePartTwo'),
        THE_SIGN_UP_PROCESS: t('learnMoreScreen.theSignUpProcess'),
        TOKEN_IS_INVALID: t('ActivationAccount.TokenIsInvalid'),
        UNEXPLAINED_ABSENCES: t('mobile.unexplainedAbsencesScreen.title'),
        UNEXPLAINED_ABSENCES_FORM_DESCRIPTION: t('mobile.unexplainedAbsencesScreen.description_form'),
        UNEXPLAINED_ABSENCES_FORM_TITLE: t('mobile.unexplainedAbsencesScreen.title_form'),
        UPDATE_YOUR_SCHOOLMESSENGER_CONTACT_INFORMATION_ADD_PHONE_NUMBERS_EMAILS_AND_SMS_TEXT_MESSAGE_NUMBERS: t(
            'learnMoreScreen.updateYourSchoolMessengerContactInformationAddPhoneNumbersEmailsAndSMSTextMessageNumbers'
        ),
        UNSUCCESFULMATCHSCREEN_TITLE1: t('mobile.unsuccessfulMatchScreen.title1'),
        UNSUCCESFULMATCHSCREEN_DESCRIPTION1: t('mobile.unsuccessfulMatchScreen.description1'),
        UNSUCCESFULMATCHSCREEN_TITLE2: t('mobile.unsuccessfulMatchScreen.title2'),
        UNSUCCESFULMATCHSCREEN_DESCRIPTION2: t('mobile.unsuccessfulMatchScreen.description2'),
        UNSUCCESFULMATCHSCREEN_TITLECARD1: t('mobile.unsuccessfulMatchScreen.titleCard1'),
        UNSUCCESFULMATCHSCREEN_DESCRIPTIONCARD1: t('mobile.unsuccessfulMatchScreen.descriptionCard1'),
        UNSUCCESFULMATCHSCREEN_TITLECARD2: t('mobile.unsuccessfulMatchScreen.titleCard2'),
        UNSUCCESFULMATCHSCREEN_DESCRIPTIONCARD2: t('mobile.unsuccessfulMatchScreen.descriptionCard2'),
        UNSUCCESFULMATCHSCREEN_BUTTON_LOGOUT: t('mobile.unsuccessfulMatchScreen.buttonLogout'),
        TRY_DIFFERENT_EMAIL_TITLE: t('mobile.tryDifferentEmail.title'),
        TRY_DIFFERENT_EMAIL_DESCRIPTION: t('mobile.tryDifferentEmail.description'),
        TRY_DIFFERENT_EMAIL_PLACEHOLDER: t('mobile.tryDifferentEmail.placeholder'),
        TRY_DIFFERENT_EMAIL_BUTTON_CONTINUE: t('mobile.tryDifferentEmail.buttonContinue'),
        TRY_DIFFERENT_EMAIL_ERROR_SAME_EMAIL: t('mobile.tryDifferentEmail.errorSameEmail'),
        TRY_DIFFERENT_EMAIL_ERROR_INVALID: t('mobile.tryDifferentEmail.errorInvalid'),
        TRY_DIFFERENT_EMAIL_ERROR_CONFLICT_409: t('mobile.tryDifferentEmail.errorConflict409'),
        VERIFICATION_EMAILS_TITLE: t('mobile.verificationEmail.title'),
        VERIFICATION_EMAILS_BUTTON_VERIFY: t('mobile.verificationEmail.buttonVerify'),
        VERIFICATION_EMAILS_BUTTON_SEND_CODE: t('mobile.verificationEmail.buttonSendCode'),
        VERIFICATION_EMAILS_BUTTON_SEND_AGAIN_EMAIL: t('mobile.verificationEmail.sendAgainEmail'),
        VERIFICATION_EMAILS_A_NEW_CODE_HAS_BEEN_SENT: t('mobile.verificationEmail.aNewCodeHasBeenSent'),
        VIEW_ADDITIONAL_INFORMATION_LIKE_STUDENT_GRADES_LUNCH_BALANCE_DATA_ETC: t(
            'learnMoreScreen.viewAdditionalInformationLikeStudentGradesLunchBalanceDataEtc'
        ),
        VIEW_THE_LAST_30_DAYS_OF_SCHOOL_MESSENGER_MESSAGES: t('learnMoreScreen.viewTheLast30DaysOfSchoolMessengerMessages'),
        WHAT_SHOULD_I_EXPECT: t('learnMoreScreen.whatShouldIExpect'),
        WHEN_YOU_GET_NEW_MESSAGES_THEY_WILL_APPEAR_IN_THIS_AREA: t('mobile.inboxScreen.emptyList.subtitle'),
        BACK_TO_TOP: t('mobile.inboxScreen.backToTop'),
        WHERE_ENABLED_BY_YOUR_SCHOOL_OR_DISTRICT_YOU_MAY_ALSO_BE_ABLE_TO: t('learnMoreScreen.whereEnabledByYourSchoolOrDistrictYouMayAlsoBeAbleTo'),
        YOU_CURRENTLY_DO_NOT_HAVE_A_PHONE_NUMBER: t('mobile.userSettingsScreen.youCurrentlyDoNotHaveAPhoneNumber'),
        CURRENT_SCHOOL_TERM_STARTS: t('mobile.attendanceScreen.currentSchoolTermStarts'),
        CURRENT_SCHOOL_TERM_ENDS: t('mobile.attendanceScreen.currentSchoolTermEnds'),
        MY_PROFILE_HELP: t('mobile.myProfileScreen.onlineHelp'),
        MY_PROFILE_CONTACTS: t('mobile.myProfileScreen.contacts'),
        MY_PROFILE_PREFERENCES: t('mobile.myProfileScreen.preferences'),
        MY_PROFILE_SETTINGS: t('mobile.myProfileScreen.settings'),
        MY_PROFILE_LANGUAGE: t('mobile.myProfileScreen.language'),
        MY_PROFILE_DELETE_MY_ACCOUNT: t('mobile.myProfileScreen.deleteMyAccount'),
        MY_PROFILE: t('mobile.myProfileScreen.myProfile'),
        MY_PROFILE_DELETE_MY_ACCOUNT_CONFIRMATION: t('mobile.myProfileScreen.deleteMyAccountConfirm1'),
        MY_PROFILE_DELETE_MY_ACCOUNT_CONFIRMATION_2: t('mobile.myProfileScreen.deleteMyAccountConfirm2'),
        FEEDBACK_THANKS: t('mobile.myProfileScreen.thanksForYourFeedback'),
        FEEDBACK_RATE_APP_STORE: t('mobile.myProfileScreen.rateUsInAppStore'),
        FEEDBACK_SURE: t('mobile.myProfileScreen.sure'),
        FEEDBACK_DONT_ASK_ME_AGAIN: t('mobile.myProfileScreen.dontAskMeAgain'),
        FEEDBACK_RATE_SCHOOLMESSENGER: t('mobile.myProfileScreen.rateSchoolMessenger'),
        FEEDBACK_START_TYPING: t('mobile.myProfileScreen.startTyping'),
        FEEDBACK_SUBMITTING_TO_SCHOOLMESSENGER: t('mobile.myProfileScreen.submittingFeedbackNote'),
        FEEDBACK_SEND: t('mobile.myProfileScreen.send'),
        FEEDBACK_LEAVE_US_FEEDBACK: t('mobile.myProfileScreen.leaveUsFeedback'),
        LOGOUT_BUTTON: t('mobile.myProfileScreen.logout'),
        SIGN_UP_LOCATION_TEXT: t('signup.LOCATION_TEXT'),
        SIGN_UP_LOCATION_BUTTON: t('signup.SWITCH_LOCATION_BUTTON'),
        SIGN_UP_LOCATION_US: t('signup.US'),
        NO_RECORS_FOUND_EMAIL_TEXT: t('NoRecordsSection.EMAIL_TEXT'),
        NO_RECORS_FOUND_P1: t('NoRecordsSection.P1'),
        NO_RECORS_FOUND_P2: t('NoRecordsSection.P2'),
        UNEXPECTED_ERROR: t('ActivationAccount.UnexpectedError'),
        UNEXPECTED_ERROR_PLEASE_TRY_AGAIN_LATER: t('ActivationAccount.UnexpectedErrorPleaseTryAgain'),
        USER_SETTINGS: t('mobile.userSettingsScreen.settings'),
        USER_SETTINGS_HEADER: t('mobile.userSettingsScreen.headerTitle'),
        USER_SETTINGS_PUSH_NOTIFICATIONS: t('mobile.userSettingsScreen.pushNotifications'),
        USER_SETTINGS_ENABLE_NOTIFICATIONS: t('mobile.userSettingsScreen.toEnablePushNotificationsGoTo'),
        USER_SETTINGS_ABOUT: t('mobile.userSettingsScreen.aboutSchoolMessenger'),
        USER_SETTINGS_VERSION: t('mobile.userSettingsScreen.version'),
        USER_SETTINGS_RESOURCES: t('mobile.userSettingsScreen.resources'),
        USER_SETTINGS_TERMS: t('mobile.userSettingsScreen.termsOfUse'),
        USER_SETTINGS_PRIVACY: t('mobile.userSettingsScreen.privacyPolicy'),
        PREFERENCES_HEADER: t('mobile.preferencesScreen.header'),
        PREFERENCES_MESSAGE_PREFERENCES: t('mobile.preferencesScreen.messagePreferences'),
        PREFERENCES_EDIT_PERMISSON: t('mobile.preferencesScreen.editPermission'),
        PREFERENCES_RECEIVE_NOTIFICATIONS: t('mobile.preferencesScreen.howDoYouWantToReceiveNotifications'),
        PREFERENCES_CURRENTLY_DO_NOT_HAVE_PHONE: t('mobile.preferencesScreen.youCurrentlyDoNotHaveAPhone'),
        PREFERENCES_PHONE_CALL: t('mobile.preferencesScreen.phoneCall'),
        PREFERENCES_TEXT_MESSAGE: t('mobile.preferencesScreen.textMessage'),
        PREFERENCES_EMAIL: t('mobile.preferencesScreen.email'),
        PREFERENCES_EMAIL_ADDRESS: t('mobile.preferencesScreen.emailAddress'),
        PREFERENCES_WE_REQUIRE_ONE_PHONE_NUMBER: t('mobile.preferencesScreen.weRequireAtLeastOnePhoneNumber'),
        PREFERENCES_CONTACT_METHODS: t('mobile.preferencesScreen.contactMethods'),
        PREFERENCES_PREFERRED_CONTACT_METHODS: t('mobile.preferencesScreen.hereAreYourPreferredContactMethods'),
        PREFERENCES_CONTACT_THE_SCHOOL_DISTRICT_TO_UPDATE: t('mobile.preferencesScreen.contactTheSchoolDistrictToUpdate'),
        PREFERENCES_ADD_NEW: t('mobile.preferencesScreen.addNew'),
        PREFERENCES_WORD_SEPARATOR: t('mobile.preferencesScreen.wordSeparator'),
        PREFERENCES_CALL: t('mobile.preferencesScreen.call'),
        PREFERENCES_TEXT: t('mobile.preferencesScreen.text'),
        PREFERENCES_INVALID_PHONE: t('mobile.preferencesScreen.error.invalidPhone'),
        PREFERENCES_ADD_PHONE: t('mobile.preferencesScreen.addPhone'),
        PREFERENCES_ADD_EMAIL: t('mobile.preferencesScreen.addEmail'),
        PREFERENCES_CONTACT_TYPE: t('mobile.preferencesScreen.contactType'),
        PREFERENCES_PHONE: t('mobile.preferencesScreen.phone'),
        PREFERENCES_PHONE_NUMBER: t('mobile.preferencesScreen.phoneNumber'),
        PREFERENCES_NEW_CONTACT: t('mobile.preferencesScreen.newContact'),
        PREFERENCES_VERIFICATION_PHONE_TITLE_MODAL: t('mobile.preferencesScreen.verificationPhoneTitleModal'),
        PREFERENCES_VERIFICATION_PHONE_TITLE: t('mobile.preferencesScreen.verificationPhoneTitle'),
        PREFERENCES_VERIFICATION_PHONE_CALL_ME: t('mobile.preferencesScreen.verificationPhoneCallMe'),
        PREFERENCES_VERIFICATION_PHONE_TEXT_ME: t('mobile.preferencesScreen.verificationPhoneTextMe'),
        PREFERENCES_VERIFICATION_PHONE_BUTTON_SEND: t('mobile.preferencesScreen.verificationPhoneButtonSend'),
        PREFERENCES_CODE_TITLE_MODAL: t('mobile.preferencesScreen.verificationCodeTitleModal'),
        PREFERENCES_CODE_TITLE: t('mobile.preferencesScreen.verificationCodeTitle'),
        PREFERENCES_CODE_MESSAGE_NEW_CODE: t('mobile.preferencesScreen.verificationCodeMessageNewCode'),
        PREFERENCES_CODE_BUTTON_SEND: t('mobile.preferencesScreen.verificationCodeButtonSend'),
        PREFERENCES_VERIFICATION_SUCCESS_TITLE_MODAL: t('mobile.preferencesScreen.verificationSuccessTitleModal'),
        PREFERENCES_VERIFICATION_SUCCESS_TITLE: t('mobile.preferencesScreen.verificationSuccessTitle'),
        PREFERENCES_VERIFICATION_SUCCESS_TITLE_COMPLEMENT: t('mobile.preferencesScreen.verificationSuccessTitleComplement'),
        PREFERENCES_VERIFICATION_SUCCESS_SMS: t('mobile.preferencesScreen.verificationSuccessSms'),
        PREFERENCES_VERIFICATION_CLICK_HERE: t('mobile.preferencesScreen.verificationClickHere'),
        PREFERENCES_VERIFICATION_EMAIL_CODE_429: t('mobile.preferencesScreen.verificationEmailCode429'),
        PREFERENCES_VERIFICATION_PHONE_CODE_400: t('mobile.preferencesScreen.verificationPhoneCode400'),
        PREFERENCES_VERIFICATION_PHONE_CODE_403: t('mobile.preferencesScreen.verificationPhoneCode403'),
        PREFERENCES_VERIFICATION_PHONE_CODE_429: t('mobile.preferencesScreen.verificationPhoneCode429'),
        PREFERENCES_VERIFICATION_PHONE_VERIFICATION_CODE_400: t('mobile.preferencesScreen.verificationPhoneVerificationCode400'),
        PREFERENCES_EMERGENCY_PHONE: t('mobile.preferencesScreen.requiredPhone.emergency'),
        PREFERENCES_HIGH_PRIORITY_PHONE: t('mobile.preferencesScreen.requiredPhone.highPriority'),
        PREFERENCES_CANT_DELETE_PHONE: t('mobile.preferencesScreen.error.phoneCantBeDeleted'),
        PREFERENCES_CANT_DELETE_EMAIL: t('mobile.preferencesScreen.error.emailCantBeDeleted'),
        PREFERENCES_MAX_PHONE_ERROR: t('mobile.preferencesScreen.error.maxPhonesReached'),
        PREFERENCES_MAX_EMAIL_ERROR: t('mobile.preferencesScreen.error.maxEmailsReached'),
        PREFERENCES_NO_PHONES_ERROR: t('mobile.preferencesScreen.error.noPhonesAdded'),
        PREFERENCES_ALERT_MESSAGE: t('mobile.preferencesScreen.alertMsg'),
        RESEND_CODE: t('mobile.preferencesScreen.resendCode'),
        EMAIL_VERIFICATION_SUCCESS: t('mobile.preferencesScreen.emailVerificationSuccess'),
        EMAIL_VERIFIED_SUCCESSFULLY: t('mobile.preferencesScreen.emailVerifiedSuccessfully'),
        EMAIL_VERIFICATION_ERROR: t('mobile.preferencesScreen.issueAddingTheEmail'),
        EMAIL_VERIFICATION_ERROR_PHONE: t('mobile.preferencesScreen.issueAddingThePhone'),
        VERIFICATION_COMPLETED: t('ActivationAccount.VerificationCompleted'),
        INVALID_EMAIL: t('mobile.signUpScreen.error.invalidEmail'),
        CONSENT_DESCRIPTION: t('mobile.consentScreen.description'),
        CONSENT_DISCLAIMER: t('mobile.consentScreen.disclaimer'),
        CONSENT_CALL: t('mobile.consentScreen.buttons.CALL'),
        CONSENT_EMERGENCY: t('mobile.consentScreen.buttons.EMERGENCY'),
        CONSENT_SAVE: t('mobile.consentScreen.buttons.SAVE'),
        CONSENT_CANCEL: t('mobile.consentScreen.buttons.CANCEL'),
        STUDENT_CLAIMING_LOG_OUT: t('StudentClaiming.LOG_OUT'),
        STUDENT_CLAIMING_CONTACT_SCHOOL: t('StudentClaiming.CONTACT_SCHOOL'),
        STUDENT_CLAIMING_PLEASE_CONTACT_SCHOOL: t('StudentClaiming.PLEASE_CONTACT_SCHOOL'),
        STUDENT_CLAIMING_CONTACTS_WERE_FOUND: t('StudentClaiming.CONTACTS_WERE_FOUND'),
        STUDENT_CLAIMING_RECORDS: t('StudentClaiming.RECORDS'),
        STUDENT_CLAIMING_MISSING_CONTACTS: t('StudentClaiming.MISSING_CONTACTS'),
        STUDENT_CLAIMING_NOT_MY_CONTACTS: t('StudentClaiming.NOT_MY_CONTACTS'),
        STUDENT_CLAIMING_HEY: t('StudentClaiming.HEY'),
        SORRY_YOU_CANNOT_DELETE_THIS_EMAIL: t('mobile.preferencesScreen.sorryYouCannotDeleteThisEmail'),
        EMAIL_VERIFICATION_BANNER_VERIFY_TITLE: t('EmailVerification.BANNER_VERIFY_TITLE'),
        EMAIL_VERIFICATION_TYPE_CODE: t('EmailVerification.TYPE_CODE'),
        SUCCESS: t('mobile.attendanceScreen.success'),
        PIN_CREATED_SUCCESSFULLY: t('mobile.attendanceScreen.pinCreatedSuccessfully'),

        //WEB
        '6_255_CHARACTERS': t('6_255_CHARACTERS'),
        A_POWERFUL_NEW_WAY_TO_STAY_CONNECTED_TO_YOUR_STUDENT_SCHOOL_OR_DISTRICT: t(
            'A_POWERFUL_NEW_WAY_TO_STAY_CONNECTED_TO_YOUR_STUDENT_SCHOOL_OR_DISTRICT'
        ),
        A_NEW_CODE_HAS_BEEN_SENT_TO_THIS_EMAIL: t('A_NEW_CODE_HAS_BEEN_SENT_TO_THIS_EMAIL'),
        A_VERIFICATION_EMAIL_HAS_BEEN_RESEND_TO: t('A_VERIFICATION_EMAIL_HAS_BEEN_RESEND_TO'),
        A_VERIFICATION_EMAIL_HAS_BEEN_SENT_TO: t('A_VERIFICATION_EMAIL_HAS_BEEN_SENT_TO'),
        ADD_ATTACHMENT: t('ADD_ATTACHMENT'),
        ABSENCE: t('ABSENCE'),
        ACCESS_CODE: t('ACCESS_CODE'),
        ACTIVITY_BADGE: t('ACTIVITY_BADGE'),
        UNEXPLAINED: t('UNEXPLAINED'),
        ACCESS_CODE_MUST_BE_ALPHANUMERIC: t('ACCESS_CODE_MUST_BE_ALPHANUMERIC'),
        ADD_A_PHONE: t('ADD_A_PHONE'),
        ADD_AN_EMAIL_ADDRESS: t('ADD_AN_EMAIL_ADDRESS'),
        ADD_MORE: t('ADD_MORE'),
        CONTACT_THE_SCHOOL_DISTRICT_TO_UPDATE_ANY_CONTACT_INFORMATION: t('CONTACT_THE_SCHOOL_DISTRICT_TO_UPDATE_ANY_CONTACT_INFORMATION'),
        ALL_MESSAGES: t('ALL_MESSAGES'),
        ALL_MY_KIDS: t('ALL_MY_KIDS'),
        ALL_RIGHTS_RESERVED: t('ALL_RIGHTS_RESERVED'),
        AM_BUS: t('AM_BUS'),
        AND: t('AND'),
        AND_HOW_YOU_WANT_TO_RECEIVE_THEM: t('AND_HOW_YOU_WANT_TO_RECEIVE_THEM'),
        ANY_OF_THE_STUDENTS_GUARDIANS_WITH_ACCESS_TO_SAFE_ARRIVAL_CAN_UPDATE_THE_REQUIREMENTS_FOR_A_PIN_ANY_PIN_BELONGING_TO_ONE_OF_THE_STUDENTS_GUARDIANS_WITH_ACCESS_TO_SAFE_ARRIVAL_WILL_BE_ACCEPTED_BY_THE_SAFE_ARRIVAL_PHONE_SYSTEM:
            t(
                'ANY_OF_THE_STUDENTS_GUARDIANS_WITH_ACCESS_TO_SAFE_ARRIVAL_CAN_UPDATE_THE_REQUIREMENTS_FOR_A_PIN_ANY_PIN_BELONGING_TO_ONE_OF_THE_STUDENTS_GUARDIANS_WITH_ACCESS_TO_SAFE_ARRIVAL_WILL_BE_ACCEPTED_BY_THE_SAFE_ARRIVAL_PHONE_SYSTEM'
            ),
        AN_INTERNAL_ERROR_HAS_HAPPENED_PLEASE_TRY_AGAIN: t('AN_INTERNAL_ERROR_HAS_HAPPENED_PLEASE_TRY_AGAIN'),
        AN_UNEXPECTED_ERROR_HAS_HAPPENED_PLEASE_TRY_AGAIN: t('AN_UNEXPECTED_ERROR_HAS_HAPPENED_PLEASE_TRY_AGAIN'),
        AND_SMS: t('AND_SMS'),
        APP_NEEDS_ACCESS_TO_YOUR_STORAGE_TO_DOWNLOAD_PHOTOS: t('APP_NEEDS_ACCESS_TO_YOUR_STORAGE_TO_DOWNLOAD_PHOTOS'),
        APPLE_ITUNES: t('APPLE_ITUNES'),
        APPLE_APP_STORE_LOGO: t('APPLE_APP_STORE_LOGO'),
        ARCHIVE: t('ARCHIVE'),
        ARE_YOU_ASSOCIATED_WITH_THE_CONTACTS_LISTED_ABOVE: t('ARE_YOU_ASSOCIATED_WITH_THE_CONTACTS_LISTED_ABOVE'),
        ARIA_LABEL_START_DIRECT_MESSAGE_BUTTON: t('ARIA_LABEL_START_DIRECT_MESSAGE_BUTTON'),
        ARIA_LABEL_EXPAND_DIRECT_MESSAGES_LIST: t('ARIA_LABEL_EXPAND_DIRECT_MESSAGES_LIST'),
        ARIA_LABEL_JOIN_GROUP_BUTTON: t('ARIA_LABEL_JOIN_GROUP_BUTTON'),
        ARIA_LABEL_EXPAND_GROUPS_LIST: t('ARIA_LABEL_EXPAND_GROUPS_LIST'),
        ASK_THE_ADMIN_TO_SHARE_THE_ACCESS_CODE_FOR_THE_GROUP_YOU_ARE_TRYING_TO_JOIN: t(
            'ASK_THE_ADMIN_TO_SHARE_THE_ACCESS_CODE_FOR_THE_GROUP_YOU_ARE_TRYING_TO_JOIN'
        ),
        AT_LEAST_ONE_PHONE_IS_REQUIRED_FOR_THIS_NOTIFICATION_TYPE: t('AT_LEAST_ONE_PHONE_IS_REQUIRED_FOR_THIS_NOTIFICATION_TYPE'),
        AT_LEAST_ONE_NUMBER_IS_REQUIRED: t('AT_LEAST_ONE_NUMBER_IS_REQUIRED'),
        AT_LEAST_ONE_PHONE_IS_REQUIRED_FOR: t('AT_LEAST_ONE_PHONE_IS_REQUIRED_FOR'),
        AT: t('AT'),
        ATTACHMENT_ICON: t('ATTACHMENT_ICON'),
        ATTACHMENTS: t('ATTACHMENTS'),
        ATTENDANCE: t('ATTENDANCE'),
        ATTENTION: t('ATTENTION'),
        BACK_TO_LOGIN: t('BACK_TO_LOGIN'),
        BACK: t('BACK'),
        BROADCAST_TYPE: t('BROADCAST_TYPE'),
        BY_SELECTING_YES_AND_SAVE_I_CONSENT_TO_RECEIVE_CALLS_CONTAINING_PRE_RECORDED_VOICE_MESSAGES: t(
            'BY_SELECTING_YES_AND_SAVE_I_CONSENT_TO_RECEIVE_CALLS_CONTAINING_PRE_RECORDED_VOICE_MESSAGES'
        ),
        BY: t('BY'),
        CALL: t('CALL'),
        CALL_ME: t('CALL_ME'),
        CANCEL: t('CANCEL'),
        CALL_TECHNICAL_SUPPORT: t('CALL_TECHNICAL_SUPPORT'),
        CANT_BE_DELETED_AS_YOUR_ORGANIZATION_REQUIRES_AT_LEAST_1_PHONE_TO_BE_AVAILABLE_FOR_CONTACT: t(
            'CANT_BE_DELETED_AS_YOUR_ORGANIZATION_REQUIRES_AT_LEAST_1_PHONE_TO_BE_AVAILABLE_FOR_CONTACT'
        ),
        CANNOT_DELETE_IMPORTED_CONTACT_INFORMATION: t('CANNOT_DELETE_IMPORTED_CONTACT_INFORMATION'),
        CANNOT_DELETE_NON_IMPORTED_CONTACT_INFORMATION: t('CANNOT_DELETE_NON_IMPORTED_CONTACT_INFORMATION'),
        CATEGORIES: t('CATEGORIES'),
        CHANGE: t('CHANGE'),
        CHARACTERS: t('CHARACTERS'),
        CHAT_ARIA_LABEL_AVAILABLE_CHAT_CONVERSATIONS: t('CHAT_ARIA_LABEL_AVAILABLE_CHAT_CONVERSATIONS'),
        CHAT_ARIA_LABEL_AVAILABLE_CONTACTS: t('CHAT_ARIA_LABEL_AVAILABLE_CONTACTS'),
        CHAT_ARIA_LABEL_CHAT_ICON_AND_LOGO: t('CHAT_ARIA_LABEL_CHAT_ICON_AND_LOGO'),
        CHAT_ARIA_LABEL_CHAT_LOGO_AND_CONVERSATION_SEARCH_TOOLBAR: t('CHAT_ARIA_LABEL_CHAT_LOGO_AND_CONVERSATION_SEARCH_TOOLBAR'),
        CHAT_ARIA_LABEL_CONTACTS_LIST: t('CHAT_ARIA_LABEL_CONTACTS_LIST'),
        CHAT_ARIA_LABEL_CONVERSATIONS_CONTAINER: t('CHAT_ARIA_LABEL_CONVERSATIONS_CONTAINER'),
        CHAT_ARIA_LABEL_CURRENT_CONVERSATION_HEADING: t('CHAT_ARIA_LABEL_CURRENT_CONVERSATION_HEADING'),
        CHAT_ARIA_LABEL_CUSTOMER_SELECT: t('CHAT_ARIA_LABEL_CUSTOMER_SELECT'),
        CHAT_ARIA_LABEL_CUSTOMER_SELECT_CONTROLS: t('CHAT_ARIA_LABEL_CUSTOMER_SELECT_CONTROLS'),
        CHAT_ARIA_LABEL_CUSTOMER_SELECT_LABEL: t('CHAT_ARIA_LABEL_CUSTOMER_SELECT_LABEL'),
        CHAT_ARIA_LABEL_DIRECT_MESSAGE_CONVERSATIONS_ACCORDIAN: t('CHAT_ARIA_LABEL_DIRECT_MESSAGE_CONVERSATIONS_ACCORDIAN'),
        CHAT_ARIA_LABEL_SECTIONS_ACCORDIAN: t('CHAT_ARIA_LABEL_SECTIONS_ACCORDIAN'),
        CHAT_ARIA_LABEL_CLICK_TO_TOGGLE_DISPLAYED_SECTION_CONVERSATIONS: t('CHAT_ARIA_LABEL_CLICK_TO_TOGGLE_DISPLAYED_SECTION_CONVERSATIONS'),
        CHAT_ARIA_LABEL_SECTION_CONVERSATIONS_LIST: t('CHAT_ARIA_LABEL_SECTION_CONVERSATIONS_LIST'),
        CHAT_ARIA_LABEL_GROUP_CONVERSATIONS_ACCORDIAN: t('CHAT_ARIA_LABEL_GROUP_CONVERSATIONS_ACCORDIAN'),
        CHAT_ARIA_LABEL_NAVIGATE_TO_CHAT_APPLICATION: t('CHAT_ARIA_LABEL_NAVIGATE_TO_CHAT_APPLICATION'),
        CHAT_ARIA_LABEL_THIS_CONVERSATION_HAS_UNREAD_MESSAGES: t('CHAT_ARIA_LABEL_THIS_CONVERSATION_HAS_UNREAD_MESSAGES'),
        CHAT_ARIA_LABEL_YOU_HAVE_UNREAD_MESSAGES: t('CHAT_ARIA_LABEL_YOU_HAVE_UNREAD_MESSAGES'),
        CHAT_AVATAR_TEXT: t('CHAT_AVATAR_TEXT'),
        CHAT_DESCRIPTION: t('CHAT_DESCRIPTION'),
        CHAT_FEATURE_UNAVAILABLE: t('CHAT_FEATURE_UNAVAILABLE'),
        CHAT_LOGO: t('CHAT_LOGO'),
        CHAT_NAME: t('CHAT_NAME'),
        CHAT_USER: t('CHAT_USER'),
        CHAT: t('CHAT'),
        CHECKED: t('CHECKED'),
        CHEVRON_RIGHT_ICON: t('CHEVRON_RIGHT_ICON'),
        CHOOSE_THE_MESSAGES_YOU_ARE_INTERESTED_IN_RECEIVED_FROM: t('CHOOSE_THE_MESSAGES_YOU_ARE_INTERESTED_IN_RECEIVED_FROM'),
        CLASS_SCHEDULE_FOR: t('CLASS_SCHEDULE_FOR'),
        CLEAR: t('CLEAR'),
        CLICK_HERE: t('CLICK_HERE'),
        CLOSE: t('CLOSE'),
        CODE_WITH_DOTS: t('CODE_WITH_DOTS'),
        COMMENTS_CANNOT_EXCEED: t('COMMENTS_CANNOT_EXCEED'),
        COMMENTS_CHARACTER_LIMIT: t('COMMENTS_CHARACTER_LIMIT'),
        COMMENTS_ICON: t('COMMENTS_ICON'),
        COMMENTS: t('COMMENTS'),
        CONFIRMATION: t('CONFIRMATION'),
        CONTACT: t('CONTACT'),
        CONTACTS: t('CONTACTS'),
        CONTACT_MEANS: t('CONTACT_MEANS'),
        CONTINUE: t('CONTINUE'),
        COPYRIGHT: t('COPYRIGHT'),
        COPYRIGHT_NAME: t('COPYRIGHT_NAME'),
        CREATING_ACCOUNT_FOR: t('CREATING_ACCOUNT_FOR'),
        CURRENT: t('CURRENT'),
        CURRENTLY_SELECTED: t('CURRENTLY_SELECTED'),
        DATA_ERROR_OCCURRED: t('DATA_ERROR_OCCURRED'),
        DELETE_DIALOG_DESCRIPTION: t('DELETE_DIALOG_DESCRIPTION'),
        DECORATIVE_LINE_IMAGE: t('DECORATIVE_LINE_IMAGE'),
        DELETE_DIALOG_TITLE: t('DELETE_DIALOG_TITLE'),
        DELETE: t('DELETE'),
        DELETE_EMAIL: t('DELETE_EMAIL'),
        DELETE_PHONE: t('DELETE_PHONE'),
        DESCRIPTION: t('DESCRIPTION'),
        DID_YOU_KNOW_THAT_YOU_CAN_REQUIRE_A_PIN_TO_BE_ENTERED_BEFORE_YOUR_STUDENTS_ABSENCE_CAN_BE_REPORTED_OR_EXPLAINED_THROUGH_THE_SAFEARRIVAL_PHONE_SYSTEM:
            t(
                'DID_YOU_KNOW_THAT_YOU_CAN_REQUIRE_A_PIN_TO_BE_ENTERED_BEFORE_YOUR_STUDENTS_ABSENCE_CAN_BE_REPORTED_OR_EXPLAINED_THROUGH_THE_SAFEARRIVAL_PHONE_SYSTEM'
            ),
        DIRECT_MESSAGES: t('DIRECT_MESSAGES'),
        DO_NOT_CALL: t('DO_NOT_CALL'),
        DO_NOT_HAVE_A_CODE: t('DO_NOT_HAVE_A_CODE'),
        DONE: t('DONE'),
        DOWNLOAD_APP: t('DOWNLOAD_APP'),
        DOWNLOAD: t('DOWNLOAD'),
        EDIT: t('EDIT'),
        EMAIL_ADDRESS_ALERT: t('EMAIL_ADDRESS_ALERT'),
        ENVELOPE_ICON: t('ENVELOPE_ICON'),
        EMAIL_IS_REQUIRED: t('EMAIL_IS_REQUIRED'),
        EMAIL_ME: t('EMAIL_ME'),
        EMAIL_PASSWORD_AND_LOCATION: t('EMAIL_PASSWORD_AND_LOCATION'),
        EMAIL: t('EMAIL'),
        EMAIL_VERIFICATION_ATTEMPTS_ARE_OVER_THE_LIMIT: t('EMAIL_VERIFICATION_ATTEMPTS_ARE_OVER_THE_LIMIT'),
        EMAIL_VERIFICATION_IS_NOT_REQUIRED_IN_ORDER_TO_USE_SCHOOLMESSENGER_HOWEVER_YOU_MAY_SEE_INCOMPLETE: t(
            'EMAIL_VERIFICATION_IS_NOT_REQUIRED_IN_ORDER_TO_USE_SCHOOLMESSENGER_HOWEVER_YOU_MAY_SEE_INCOMPLETE'
        ),
        EMERGENCY_CALLS_MAY_BE_PLACED_TO_THIS_PHONE_AT_ANY_TIME_BUT_NON_EMERGENCY_CALLS_WILL_ONLY_BE_PLACED_IF_YOU_HAVE_GIVEN_CONSENT: t(
            'EMERGENCY_CALLS_MAY_BE_PLACED_TO_THIS_PHONE_AT_ANY_TIME_BUT_NON_EMERGENCY_CALLS_WILL_ONLY_BE_PLACED_IF_YOU_HAVE_GIVEN_CONSENT'
        ),
        EMERGENCY_CALLS_ONLY: t('EMERGENCY_CALLS_ONLY'),
        ENABLED_TO_RECEIVE_CALLS: t('ENABLED_TO_RECEIVE_CALLS'),
        ENABLED_TO_RECEIVE_EMAILS: t('ENABLED_TO_RECEIVE_EMAILS'),
        ENABLED_TO_RECEIVE_SMS: t('ENABLED_TO_RECEIVE_SMS'),
        END_DATE_OF_ABSENCE: t('END_DATE_OF_ABSENCE'),
        ENTER_A_DIFFERENT_EMAIL: t('ENTER_A_DIFFERENT_EMAIL'),
        ENTER_THE_CODE_THAT_WE_SEND_TO_YOUR_EMAIL_ADDRESS: t('ENTER_THE_CODE_THAT_WE_SEND_TO_YOUR_EMAIL_ADDRESS'),
        ENTER_VALID_EMAIL_FORMAT: t('ENTER_VALID_EMAIL_FORMAT'),
        ENTER_RECEIVED_CODE: t('ENTER_RECEIVED_CODE'),
        EXPECTED_RETURN_TIME: t('EXPECTED_RETURN_TIME'),
        EXPECTED_TIME_OF_ARRIVAL: t('EXPECTED_TIME_OF_ARRIVAL'),
        FAVORITES: t('FAVORITES'),
        FIND_CONTACTS_WITH_A_DIFFERENT_EMAIL: t('FIND_CONTACTS_WITH_A_DIFFERENT_EMAIL'),
        FILTER: t('FILTER'),
        FILTERED_BY: t('FILTERED_BY'),
        FILTERED_MESSAGES: t('FILTERED_MESSAGES'),
        FILTERED_RESULTS: t('FILTERED_RESULTS'),
        FIRST_DAY_OF_ABSENCE: t('FIRST_DAY_OF_ABSENCE'),
        FORGOT_PASSWORD: t('FORGOT_PASSWORD'),
        FORGOT_YOUR_PASSWORD: t('FORGOT_YOUR_PASSWORD'),
        FOR_USERS_IN_CANADA: t('FOR_USERS_IN_CANADA'),
        FOR_USERS_IN_THE_UNITED_STATES: t('FOR_USERS_IN_THE_UNITED_STATES'),
        FROM_FIRST_UPPERCASE: t('FROM_FIRST_UPPERCASE'),
        FROM: t('FROM'),
        GET_IN_TOUCH_WITH_THE_SCHOOL: t('GET_IN_TOUCH_WITH_THE_SCHOOL'),
        GOOGLE_PLAY: t('GOOGLE_PLAY'),
        GOOGLE_PLAY_STORE_LOGO: t('GOOGLE_PLAY_STORE_LOGO'),
        GROUPS: t('GROUPS'),
        HAS_NOT_BEEN_CONFIRMED: t('HAS_NOT_BEEN_CONFIRMED'),
        HAVE_AN_ACCOUNT: t('HAVE_AN_ACCOUNT'),
        HELP: t('HELP'),
        HIDE_PASSWORD: t('HIDE_PASSWORD'),
        HOW_DO_YOU_WANT_TO_FIND_YOUR_CHILD_S_INFORMATION: t('HOW_DO_YOU_WANT_TO_FIND_YOUR_CHILD_S_INFORMATION'),
        I_CONSENT_TO_RECEIVE_CALLS_CONTAINING_PRE_RECORDED_VOICE_MESSAGES: t('I_CONSENT_TO_RECEIVE_CALLS_CONTAINING_PRE_RECORDED_VOICE_MESSAGES'),
        I_DIDNT_RECEIVE_A_CODE: t('I_DIDNT_RECEIVE_A_CODE'),
        IF_YOU_DON_T_RECEIVE_AN_EMAIL_FIRST_CHECK_YOUR_SPAM_FOLDER_OR_OTHER_FILTERING_TOOLS_YOU_MAY_ALSO_WANT_TO_TRY_ANOTHER_EMAIL_ADDRESS_TO_ENSURE_THE_RIGHT_ONE_IS_REGISTERED:
            t(
                'IF_YOU_DON_T_RECEIVE_AN_EMAIL_FIRST_CHECK_YOUR_SPAM_FOLDER_OR_OTHER_FILTERING_TOOLS_YOU_MAY_ALSO_WANT_TO_TRY_ANOTHER_EMAIL_ADDRESS_TO_ENSURE_THE_RIGHT_ONE_IS_REGISTERED'
            ),
        IF: t('IF'),
        IF_YOU_HAVE_NO_CODE: t('IF_YOU_HAVE_NO_CODE'),
        INACTIVE_UNTIL_VERIFIED: t('INACTIVE_UNTIL_VERIFIED'),
        INBOX: t('INBOX'),
        INTERNAL_DATA_ERROR: t('INTERNAL_DATA_ERROR'),
        IS_REGISTERED_WITH_SCHOOL_MESSENGER_YOU_WILL_RECEIVE_INSTRUCTIONS_TO_RESET_YOUR_PASSWORD_WITHIN_A_FEW_MINUTES: t(
            'IS_REGISTERED_WITH_SCHOOL_MESSENGER_YOU_WILL_RECEIVE_INSTRUCTIONS_TO_RESET_YOUR_PASSWORD_WITHIN_A_FEW_MINUTES'
        ),
        IS_SOMEONE_STILL_MISSING: t('IS_SOMEONE_STILL_MISSING'),
        IS_YOUR_SCHOOL_IN_CANADA: t('IS_YOUR_SCHOOL_IN_CANADA'),
        ITS_OK_TO_CALL_THIS_PHONE: t('ITS_OK_TO_CALL_THIS_PHONE'),
        JOIN_GROUP_ICON: t('JOIN_GROUP_ICON'),
        JOIN_GROUP: t('JOIN_GROUP'),
        JOIN: t('JOIN'),
        JUST_TELL_US_WHO_WILL_BE_ABSENT_WHEN_AND_WHY_AND_WE_INFORM_THE_SCHOOL: t(
            'JUST_TELL_US_WHO_WILL_BE_ABSENT_WHEN_AND_WHY_AND_WE_INFORM_THE_SCHOOL'
        ),
        LANGUAGE: t('LANGUAGE'),
        LAST_DAY_OF_ABSENCE: t('LAST_DAY_OF_ABSENCE'),
        LAST_REPORTED_CLASSROOM_GRADE: t('LAST_REPORTED_CLASSROOM_GRADE'),
        LEARN_MORE_ABOUT_SCHOOL_MESSENGER: t('LEARN_MORE_ABOUT_SCHOOL_MESSENGER'),
        LEARN_MORE: t('LEARN_MORE'),
        LOADING_CONVERSATIONS: t('LOADING_CONVERSATIONS'),
        LOADING_MESSAGES: t('LOADING_MESSAGES'),
        LOADING: t('LOADING'),
        LOCATION: t('LOCATION'),
        LOG_IN_SIGN_UP_BEFORE: t('LOG_IN_SIGN_UP_BEFORE'),
        LOG_IN: t('LOG_IN'),
        LOG_OUT: t('LOG_OUT'),
        LOGIN_FAILED: t('LOGIN_FAILED'),
        MAKE_SURE_YOUR_PASSWORD_MATCH_THE_REQUIREMENTS: t('MAKE_SURE_YOUR_PASSWORD_MATCH_THE_REQUIREMENTS'),
        MAKE_SURE_YOUR: t('MAKE_SURE_YOUR'),
        MANAGE_YOUR_PIN_SETTINGS: t('MANAGE_YOUR_PIN_SETTINGS'),
        MANGO_HEART: t('MANGO_HEART'),
        MARK_ALL_AS_READ: t('MARK_ALL_AS_READ'),
        MARK_ALL_AS_UNREAD: t('MARK_ALL_AS_UNREAD'),
        MARK_AS_FAVORITE: t('MARK_AS_FAVORITE'),
        MARK_AS_READ: t('MARK_AS_READ'),
        MARK_AS_UNREAD: t('MARK_AS_UNREAD'),
        MATCH_YOUR_ACCOUNT_INFORMATION: t('MATCH_YOUR_ACCOUNT_INFORMATION'),
        MAX_LENGTH_IS: t('MAX_LENGTH_IS'),
        PLEASE_CONTACT_YOUR_SCHOOL_TO_UPDATE_EMAIL_ADDRESSES_ON_FILE: t('PLEASE_CONTACT_YOUR_SCHOOL_TO_UPDATE_EMAIL_ADDRESSES_ON_FILE'),
        MEAL_BALANCE: t('MEAL_BALANCE'),
        MESSAGES: t('MESSAGES'),
        MODAL_TITLE_ATTENDANCE_PIN: t('MODAL_TITLE_ATTENDANCE_PIN'),
        MORE_ACTIONS: t('MORE_ACTIONS'),
        MORE_RECIPIENTS: t('MORE_RECIPIENTS'),
        MUST_BE_A_VALID_4_DIGIT_CODE: t('MUST_BE_A_VALID_4_DIGIT_CODE'),
        MUST_BE_BETWEEN_6_AND_255_CHARACTERS: t('MUST_BE_BETWEEN_6_AND_255_CHARACTERS'),
        MUTE_THIS_CHAT: t('MUTE_THIS_CHAT'),
        MY_CONTACT_INFORMATION: t('MY_CONTACT_INFORMATION'),
        MY_CONTACTS: t('MY_CONTACTS'),
        MY_DISTRICTS: t('MY_DISTRICTS'),
        MY_EMAIL: t('MY_EMAIL'),
        MY_MESSAGE_PREFERENCES: t('MY_MESSAGE_PREFERENCES'),
        MY_PHONE: t('MY_PHONE'),
        MY_STUDENTS: t('MY_STUDENTS'),
        NEW_CONVERSATION_ICON: t('NEW_CONVERSATION_ICON'),
        NEW_DIRECT_MESSAGE_ICON: t('NEW_DIRECT_MESSAGE_ICON'),
        NEW_DIRECT_MESSAGE: t('NEW_DIRECT_MESSAGE'),
        NEW_PASSWORD: t('NEW_PASSWORD'),
        NEXT: t('NEXT'),
        NO_CALL_ME_FOR_EMERGENCIES_ONLY: t('NO_CALL_ME_FOR_EMERGENCIES_ONLY'),
        NO_CONVERSATIONS: t('NO_CONVERSATIONS'),
        NOT_ENABLED_TO_RECEIVE_CALLS: t('NOT_ENABLED_TO_RECEIVE_CALLS'),
        NOT_ENABLED_TO_RECEIVE_EMAILS: t('NOT_ENABLED_TO_RECEIVE_EMAILS'),
        NOT_ENABLED_TO_RECEIVE_SMS: t('NOT_ENABLED_TO_RECEIVE_SMS'),
        NO_MESSAGES: t('NO_MESSAGES'),
        NO_STUDENTS_FOUND: t('NO_STUDENTS_FOUND'),
        NOT_REGISTERED_YET: t('NOT_REGISTERED_YET'),
        NOTE_YOU_WILL_NOT_BE_ABLE_TO_LOGIN_INTO_YOUR_SCHOOLMESSENGER_ACCOUNT_UNTIL_YOU_HAVE_VERIFIED_YOUR_EMAIL: t(
            'NOTE_YOU_WILL_NOT_BE_ABLE_TO_LOGIN_INTO_YOUR_SCHOOLMESSENGER_ACCOUNT_UNTIL_YOU_HAVE_VERIFIED_YOUR_EMAIL'
        ),
        NOTE: t('NOTE'),
        NOTIFICATIONS: t('NOTIFICATIONS'),
        OOOPS_IT_LOOKS_LIKE_YOU_HAVE_ALREADY_USED_THAT_EMAIL_ADDRESS: t('OOOPS_IT_LOOKS_LIKE_YOU_HAVE_ALREADY_USED_THAT_EMAIL_ADDRESS'),
        OK_TO_CALL: t('OK_TO_CALL'),
        OK: t('OK'),
        OK_UPPERCASE: t('OK_UPPERCASE'),
        ON_YOUR_NEXT_LOGIN_YOU_WILL_BE_TAKEN_DIRECTLY_TO: t('ON_YOUR_NEXT_LOGIN_YOU_WILL_BE_TAKEN_DIRECTLY_TO'),
        ONE_LOWERCASE_LETTER: t('ONE_LOWERCASE_LETTER'),
        ONE_LOWERCASE_LETTER_ALREADY_ADDED: t('ONE_LOWERCASE_LETTER_ALREADY_ADDED'),
        ONE_LOWERCASE_LETTER_REQUIRED: t('ONE_LOWERCASE_LETTER_REQUIRED'),
        ONE_NUMBER: t('ONE_NUMBER'),
        ONE_UPPERCASE_LETTER: t('ONE_UPPERCASE_LETTER'),
        ONE_UPPERCASE_LETTER_ALREADY_INCLUDED: t('ONE_UPPERCASE_LETTER_ALREADY_INCLUDED'),
        ONE_UPPERCASE_LETTER_REQUIRED: t('ONE_UPPERCASE_LETTER_REQUIRED'),
        CONVERSATION_OPTIONS: t('CONVERSATION_OPTIONS'),
        OPEN: t('OPEN'),
        OR_ONLY_SHOW: t('OR_ONLY_SHOW'),
        PASSWORD_IS_REQUIRED: t('PASSWORD_IS_REQUIRED'),
        PASSWORD: t('PASSWORD'),
        PENDING_CONSENT_TO_CALL: t('PENDING_CONSENT_TO_CALL'),
        PENDING_CONSENT: t('PENDING_CONSENT'),
        PENDING_CONSENT_POPUP_TITLE: t('PENDING_CONSENT_POPUP_TITLE'),
        PENDING_CONSENT_POPUP_PLEASE_SELECT_PHONE_NUMBER: t('PENDING_CONSENT_POPUP_PLEASE_SELECT_PHONE_NUMBER'),
        PENDING_CONSENT_POPUP_I_CONSENT: t('PENDING_CONSENT_POPUP_I_CONSENT'),
        PENDING_CONSENT_POPUP_OPTION_QUESTION: t('PENDING_CONSENT_POPUP_OPTION_QUESTION'),
        PENDING_CONSENT_POPUP_OPTION_YES_OK_TO_CALL: t('PENDING_CONSENT_POPUP_OPTION_YES_OK_TO_CALL'),
        PENDING_CONSENT_POPUP_OPTION_NO_EMERGENCIES_ONLY: t('PENDING_CONSENT_POPUP_OPTION_NO_EMERGENCIES_ONLY'),
        PERMISSION_SETTINGS_FOR: t('PERMISSION_SETTINGS_FOR'),
        PERSON_DETAILS: t('PERSON_DETAILS'),
        PHONE: t('PHONE'),
        PHONE_CALL: t('PHONE_CALL'),
        PHONE_NUMBER_STATUS_UNAVAILABLE: t('PHONE_NUMBER_STATUS_UNAVAILABLE'),
        PLEASE_CHECK_YOUR_EMAIL_FOR_A_CONFIRMATION_LINK_TO_ACTIVATE_YOUR_ACCOUNT_DIDNT_RECEIVE_A_CONFIRMATION_EMAIL: t(
            'PLEASE_CHECK_YOUR_EMAIL_FOR_A_CONFIRMATION_LINK_TO_ACTIVATE_YOUR_ACCOUNT_DIDNT_RECEIVE_A_CONFIRMATION_EMAIL'
        ),
        PLEASE_CONTACT_THE_SCHOOL: t('PLEASE_CONTACT_THE_SCHOOL'),
        PLEASE_CONTACT_THE_SCHOOL_FOR_EMAIL: t('PLEASE_CONTACT_THE_SCHOOL_FOR_EMAIL'),
        PLEASE_CONTACT_SCHOOL_CHAT: t('PLEASE_CONTACT_SCHOOL_CHAT'),
        PLEASE_CONTACT_YOUR_SCHOOL_ADMINISTRATOR_IF_YOU_WOULD_LIKE_TO_RECEIVE_CALLS_ON_THIS_NUMBER: t(
            'PLEASE_CONTACT_YOUR_SCHOOL_ADMINISTRATOR_IF_YOU_WOULD_LIKE_TO_RECEIVE_CALLS_ON_THIS_NUMBER'
        ),
        PLEASE_ENTER_A_PROPERLY_FORMATTED_EMAIL_ADDRESS: t('PLEASE_ENTER_A_PROPERLY_FORMATTED_EMAIL_ADDRESS'),
        PLEASE_ENTER_THE_4_DIGIT_CODE_SENT_TO_YOU_AT: t('PLEASE_ENTER_THE_4_DIGIT_CODE_SENT_TO_YOU_AT'),
        PLEASE_ENTER_YOUR_EMAIL_ADDRESS_WE_WILL_SEND_YOU_AN_EMAIL_WITH_INSTRUCTIONS_TO_RESET_YOUR_PASSWORD: t(
            'PLEASE_ENTER_YOUR_EMAIL_ADDRESS_WE_WILL_SEND_YOU_AN_EMAIL_WITH_INSTRUCTIONS_TO_RESET_YOUR_PASSWORD'
        ),
        PLEASE_FILL_CHARACTER: t('PLEASE_FILL_CHARACTER'),
        PLEASE_VERIFY_YOUR_ACCOUNT_BY_FOLLOWING_INSTRUCTIONS_WE_SENT_TO: t('PLEASE_VERIFY_YOUR_ACCOUNT_BY_FOLLOWING_INSTRUCTIONS_WE_SENT_TO'),
        PLEASE_WAIT_AT_LEAST_10_MINUTES_BEFORE_TRYING_TO_RESEND: t('PLEASE_WAIT_AT_LEAST_10_MINUTES_BEFORE_TRYING_TO_RESEND'),
        PLEASE_WAIT_AT_LEAST_10_MINUTES_BEFORE_TRYING_AGAIN: t('PLEASE_WAIT_AT_LEAST_10_MINUTES_BEFORE_TRYING_AGAIN'),
        PLEASE_WAIT: t('PLEASE_WAIT'),
        PLEASE_DOUBLE_CHECK_YOUR_LOGIN: t('PLEASE_DOUBLE_CHECK_YOUR_LOGIN'),
        PM_BUS: t('PM_BUS'),
        PREFERENCES: t('PREFERENCES'),
        PRESS_ENTER_TO_CHANGE_THESE_OPTIONS: t('PRESS_ENTER_TO_CHANGE_THESE_OPTIONS'),
        PRIVACY_POLICY: t('PRIVACY_POLICY'),
        REASON: t('REASON'),
        REFRESH: t('REFRESH'),
        REFRESHING_STUDENTS: t('REFRESHING_STUDENTS'),
        RELOAD_ICON: t('RELOAD_ICON'),
        REMOVE_SELECTION: t('REMOVE_SELECTION'),
        REPORT_AN_ABSENCE: t('REPORT_AN_ABSENCE'),
        REPORTING_ABSENCES_JUST_GOT_A_WHOLE_LOT_EASIER: t('REPORTING_ABSENCES_JUST_GOT_A_WHOLE_LOT_EASIER'),
        REQUESTS_FOR_PASSWORD_RESETS_ARE_ONLY_ALLOWED_ONCE_EVERY_TEN_MINUTES: t(
            'REQUESTS_FOR_PASSWORD_RESETS_ARE_ONLY_ALLOWED_ONCE_EVERY_TEN_MINUTES'
        ),
        RESEND_ACCOUNT_CONFIRMATION_EMAIL: t('RESEND_ACCOUNT_CONFIRMATION_EMAIL'),
        RESET_PASSWORD: t('RESET_PASSWORD'),
        RETURN_TO_INBOX: t('RETURN_TO_INBOX'),
        RETURN_TO_LOG_IN: t('RETURN_TO_LOG_IN'),
        RETURNING_TO_SCHOOL_AT: t('RETURNING_TO_SCHOOL_AT'),
        ROCKET_CHAT_IFRAME: t('ROCKET_CHAT_IFRAME'),
        SAFE_ARRIVAL_PIN_SETTINGS: t('SAFE_ARRIVAL_PIN_SETTINGS'),
        SAVE: t('SAVE'),
        SCHOOL_DISTRICT: t('SCHOOL_DISTRICT'),
        SCHOOL_DISTRICT_LABEL: t('SCHOOL_DISTRICT_LABEL'),
        SCHOOLMESSENGER_LOGO: t('SCHOOLMESSENGER_LOGO'),
        SCHOOLMESSENGER_ALLOWS_SCHOOLS_DISTRICTS_COLLEGES_AND_UNIVERSITIES: t('SCHOOLMESSENGER_ALLOWS_SCHOOLS_DISTRICTS_COLLEGES_AND_UNIVERSITIES'),
        SEARCH_FOR_A_CONTACT: t('SEARCH_FOR_A_CONTACT'),
        SEARCHED_MESSAGES: t('SEARCHED_MESSAGES'),
        SECTION_NAME: t('SECTION_NAME'),
        SECTIONS: t('SECTIONS'),
        SELECT_DISTRICT: t('SELECT_DISTRICT'),
        SEE_MORE: t('SEE_MORE'),
        SELECT_A_MESSAGE_FROM_THE_LIST_TO_VIEW_ITS_CONTENTS: t('SELECT_A_MESSAGE_FROM_THE_LIST_TO_VIEW_ITS_CONTENTS'),
        SELECT_ABSENCE_TYPE: t('SELECT_ABSENCE_TYPE'),
        SELECT_AS_MANY_OPTIONS: t('SELECT_AS_MANY_OPTIONS'),
        SELECT_REASON: t('SELECT_REASON'),
        SELECT_STUDENT: t('SELECT_STUDENT'),
        SELECT_THE_STUDENTS_FOR_WHICH_A_PIN_PERSONAL_IDENTIFICATION_NUMBER_MUST_BE_ENTERED_WHEN_USING_THE_SAFE_ARRIVAL_PHONE_SYSTEM: t(
            'SELECT_THE_STUDENTS_FOR_WHICH_A_PIN_PERSONAL_IDENTIFICATION_NUMBER_MUST_BE_ENTERED_WHEN_USING_THE_SAFE_ARRIVAL_PHONE_SYSTEM'
        ),
        SELECT_YOUR_MESSAGE_RECIPIENT: t('SELECT_YOUR_MESSAGE_RECIPIENT'),
        SELECTED_MESSAGE: t('SELECTED_MESSAGE'),
        SEND_TEXT: t('SEND_TEXT'),
        SEND: t('SEND'),
        SEND_CODE_TO: t('SEND_CODE_TO'),
        SET_NEW_PASSWORD: t('SET_NEW_PASSWORD'),
        SHOW: t('SHOW'),
        SHOW_PASSWORD: t('SHOW_PASSWORD'),
        SHOWING_ABSENCES_FOR: t('SHOWING_ABSENCES_FOR'),
        SIGN_UP_CHARACTERS: t('SIGN_UP_CHARACTERS'),
        SIGN_UP_ILLUSTRATION: t('SIGN_UP_ILLUSTRATION'),
        SIGN_UP_TITLE: t('SIGN_UP_TITLE'),
        SIGN_UP: t('SIGN_UP'),
        SMAPP_VIDEO: t('SMAPP_VIDEO'),
        SMS: t('SMS'),
        SOMETHING_WENT_WRONG_TRY_LATER: t('SOMETHING_WENT_WRONG_TRY_LATER'),
        SORRY_CHAT_UNAVAILABLE: t('SORRY_CHAT_UNAVAILABLE'),
        SORRY_NO_CONVERSATIONS_WERE_FOUND: t('SORRY_NO_CONVERSATIONS_WERE_FOUND'),
        SORRY_THAT_CODE_DOES_NOT_EXIST: t('SORRY_THAT_CODE_DOES_NOT_EXIST'),
        SORRY_YOUR_PHONE_CONSENT_STATUS_IS_UNAVAILABLE_AT_THIS_TIME_PLEASE_CHECK_AGAIN_LATER: t(
            'SORRY_YOUR_PHONE_CONSENT_STATUS_IS_UNAVAILABLE_AT_THIS_TIME_PLEASE_CHECK_AGAIN_LATER'
        ),
        SORRY_YOUR_PHONE_CONSENT_STATUS_IS_UNAVAILABLE_AT_THIS_TIME: t('SORRY_YOUR_PHONE_CONSENT_STATUS_IS_UNAVAILABLE_AT_THIS_TIME'),
        START_CHAT: t('START_CHAT'),
        STATUS: t('STATUS'),
        STORAGE_PERMISSION_NOT_GRANTED: t('STORAGE_PERMISSION_NOT_GRANTED'),
        STORAGE_PERMISSION_REQUIRED: t('STORAGE_PERMISSION_REQUIRED'),
        STUDENT_ABSENCES: t('STUDENT_ABSENCES'),
        STUDENTS: t('STUDENTS'),
        SUBJECT: t('SUBJECT'),
        SUBMIT: t('SUBMIT'),
        SUCCESSFULLY_SAVED_RETURNING_TO_THE_PREFERENCES_PAGE: t('SUCCESSFULLY_SAVED_RETURNING_TO_THE_PREFERENCES_PAGE'),
        SURVEYS_ARE_NOT_DISPLAYED_IN_SCHOOLMESSENGER_MESSAGES: t('SURVEYS_ARE_NOT_DISPLAYED_IN_SCHOOLMESSENGER_MESSAGES'),
        SWITCH_DISTRICT: t('SWITCH_DISTRICT'),
        SWITCH_LOCATION: t('SWITCH_LOCATION'),
        SYNCHRONIZE_CONTACTS_WITH_YOUR_SCHOOL_DISTRICT: t('SYNCHRONIZE_CONTACTS_WITH_YOUR_SCHOOL_DISTRICT'),
        SYNCHRONIZE_STUDENTS_WITH_YOUR_SCHOOL_DISTRICT: t('SYNCHRONIZE_STUDENTS_WITH_YOUR_SCHOOL_DISTRICT'),
        SYNC_CONTACTS: t('SYNC_CONTACTS'),
        FIND_CONTACTS: t('FIND_CONTACTS'),
        SYNCHRONIZED_COMPLETE: t('SYNCHRONIZED_COMPLETE'),
        SYNCHRONIZATION_ERROR: t('SYNCHRONIZATION_ERROR'),
        TAB_SPACE: t('TAB_SPACE'),
        TARDY: t('TARDY'),
        TERMS_OF_SERVICE: t('TERMS_OF_SERVICE'),
        TERMS_AND_CONDITIONS: t('TERMS_AND_CONDITIONS'),
        TEXT_MESSAGE: t('TEXT_MESSAGE'),
        TEXT_ONLY: t('TEXT_ONLY'),
        THANK_YOU: t('THANK_YOU'),
        THANK_YOU_FOR_CONFIRMING_YOUR_EMAIL_ADDRESS: t('THANK_YOU_FOR_CONFIRMING_YOUR_EMAIL_ADDRESS'),
        THIS_EMAIL_ADDRESS_IS_ALREADY_IN_USE: t('THIS_EMAIL_ADDRESS_IS_ALREADY_IN_USE'),
        THERE_ARE_NO_MESSAGES_FOUND: t('THERE_ARE_NO_MESSAGES_FOUND'),
        THERE_WAS_AN_ERROR_SYNCING_THE_CONTACTS: t('THERE_WAS_AN_ERROR_SYNCING_THE_CONTACTS'),
        THE_MOBILE_CARRIERS_ARE_NOT_LIABLE_FOR_DELAYED_OR_UNDELIVERED_MESSAGES: t(
            'THE_MOBILE_CARRIERS_ARE_NOT_LIABLE_FOR_DELAYED_OR_UNDELIVERED_MESSAGES'
        ),
        THIS_IS_THE_EMAIL_ADDRESS_YOUR_SCHOOL_HAS_ON_RECORD_IF_YOU_WISH_TO_USE_A_DIFFERENT_ONE_PLEASE_CONTACT_AND_ASK_THEM_TO_UPDATE_YOUR_EMAIL_ADDRESS:
            t(
                'THIS_IS_THE_EMAIL_ADDRESS_YOUR_SCHOOL_HAS_ON_RECORD_IF_YOU_WISH_TO_USE_A_DIFFERENT_ONE_PLEASE_CONTACT_AND_ASK_THEM_TO_UPDATE_YOUR_EMAIL_ADDRESS'
            ),
        THIS_NUMBER_WILL_ONLY_RECEIVE_TEXT_MESSAGES: t('THIS_NUMBER_WILL_ONLY_RECEIVE_TEXT_MESSAGES'),
        THIS_PHONE_WILL_REMAIN_DISABLED_FOR_ALL_NON_EMERGENCY_CALLS_UNTIL_YOU_PROVIDE_YOUR_CONSENT_TO_RECEIVE_PRE_RECORDED_MESSAGES: t(
            'THIS_PHONE_WILL_REMAIN_DISABLED_FOR_ALL_NON_EMERGENCY_CALLS_UNTIL_YOU_PROVIDE_YOUR_CONSENT_TO_RECEIVE_PRE_RECORDED_MESSAGES'
        ),
        THIS_PHONE: t('THIS_PHONE'),
        THIS_USER_ALREADY_EXISTS: t('THIS_USER_ALREADY_EXISTS'),
        THIS_WILL_ONLY_TAKE_A_MOMENT_NO_NEED_TO_REFRESH_THE_PAGE: t('THIS_WILL_ONLY_TAKE_A_MOMENT_NO_NEED_TO_REFRESH_THE_PAGE'),
        THIS_WILL_ONLY_TAKE_A_MOMENT_NO_NEED_TO_REFRESH: t('THIS_WILL_ONLY_TAKE_A_MOMENT_NO_NEED_TO_REFRESH'),
        TIME_OF_DEPARTURE: t('TIME_OF_DEPARTURE'),
        TIME_OF_LEAVE: t('TIME_OF_LEAVE'),
        TIME_OF_LATE: t('TIME_OF_LATE'),
        TO_SEND_NEW_CODE: t('TO_SEND_NEW_CODE'),
        TODAY: t('TODAY'),
        TOGGLE_PASSWORD_VISIBILITY: t('TOGGLE_PASSWORD_VISIBILITY'),
        TRY_ANOTHER_EMAIL_ADDRESS: t('TRY_ANOTHER_EMAIL_ADDRESS'),
        TRY_A_DIFFERENT_EMAIL: t('TRY_A_DIFFERENT_EMAIL'),
        TYPE_OF_ABSENCE: t('TYPE_OF_ABSENCE'),
        TYPE_THE_GROUP_ACCESS_CODE_BELOW: t('TYPE_THE_GROUP_ACCESS_CODE_BELOW'),
        UNCONFIRMED_ACCOUNT: t('UNCONFIRMED_ACCOUNT'),
        UNCONFIRMED_EMAIL_ADDRESS: t('UNCONFIRMED_EMAIL_ADDRESS'),
        UNITED_STATES: t('UNITED_STATES'),
        UNKNOWN: t('UNKNOWN'),
        UNREAD: t('UNREAD'),
        US: t('US'),
        VERIFICATION_EMAILS_ARE_ONLY_ALLOWED_TO_BE_SEND_ONCE_EVERY_10_MINUTES: t(
            'VERIFICATION_EMAILS_ARE_ONLY_ALLOWED_TO_BE_SEND_ONCE_EVERY_10_MINUTES'
        ),
        USE_A_PREVIOUSLY_VERIFIED_EMAIL_TO_FIND_YOUR_CHILDREN: t('USE_A_PREVIOUSLY_VERIFIED_EMAIL_TO_FIND_YOUR_CHILDREN'),
        USE_ANOTHER_EMAIL_ADDRESS_THAT_YOU_MAY_HAVE_PROVIDED_TO_THE_SCHOOL: t('USE_ANOTHER_EMAIL_ADDRESS_THAT_YOU_MAY_HAVE_PROVIDED_TO_THE_SCHOOL'),
        VIEW_CLASS_SCHEDULE: t('VIEW_CLASS_SCHEDULE'),
        VIEW_INFO: t('VIEW_INFO'),
        VIEWS: t('VIEWS'),
        VOICE_MESSAGE_ERROR: t('VOICE_MESSAGE_ERROR'),
        VOICE: t('VOICE'),
        WE_WERENOT_ABLE_TO_FIND_ANY_STUDENS: t('WE_WERENOT_ABLE_TO_FIND_ANY_STUDENS'),
        WARNING: t('WARNING'),
        WE_ARE_SAVING_YOUR_CHANGES: t('WE_ARE_SAVING_YOUR_CHANGES'),
        WELCOME: t('WELCOME'),
        WELCOME_TO_SCHOOLMESSENGER: t('WELCOME_TO_SCHOOLMESSENGER'),
        WELCOME_BACK_TO_SCHOOLMESSENGER: t('WELCOME_BACK_TO_SCHOOLMESSENGER'),
        WHEN: t('WHEN'),
        WHO_WILL_BE_ABSENT: t('WHO_WILL_BE_ABSENT'),
        WOULD_YOU_LIKE_TO_RECEIVE_OTHER_PRE_RECORDED_MESSAGES_FROM_YOUR_SCHOOL_OR_DISTRICT_FOR_TOPICS_THAT_YOU_SELECT_SUCH_AS_SCHOOL_AND_ATTENDANCE_NOTIFICATIONS:
            t(
                'WOULD_YOU_LIKE_TO_RECEIVE_OTHER_PRE_RECORDED_MESSAGES_FROM_YOUR_SCHOOL_OR_DISTRICT_FOR_TOPICS_THAT_YOU_SELECT_SUCH_AS_SCHOOL_AND_ATTENDANCE_NOTIFICATIONS'
            ),
        WRONG_VERIFICATION_CODE: t('WRONG_VERIFICATION_CODE'),
        YES: t('YES'),
        YES_ITS_OK_TO_CALL_ME_AT_THIS_NUMBER: t('YES_ITS_OK_TO_CALL_ME_AT_THIS_NUMBER'),
        YOU_ALREADY_HAVE_AT_LEAST_ONE_NUMBER: t('YOU_ALREADY_HAVE_AT_LEAST_ONE_NUMBER'),
        YOU_ALREADY_HAVE_BETWEEN_6_AND_255_CHARACTERS: t('YOU_ALREADY_HAVE_BETWEEN_6_AND_255_CHARACTERS'),
        YOU_ARE_ALMOST_SET: t('YOU_ARE_ALMOST_SET'),
        YOU_ARE_ALL_SET_YOU_CAN_LOG_IN_NOW_WITH_YOUR_NEW_PASSWORD: t('YOU_ARE_ALL_SET_YOU_CAN_LOG_IN_NOW_WITH_YOUR_NEW_PASSWORD'),
        YOU_CAN_SEND_MESSAGES_TO_THIS_GROUP_UPON_ADMIN_APPROVAL: t('YOU_CAN_SEND_MESSAGES_TO_THIS_GROUP_UPON_ADMIN_APPROVAL'),
        YOU_CURRENTLY_HAVE_NO_MESSAGES_IN_YOUR_INBOX: t('YOU_CURRENTLY_HAVE_NO_MESSAGES_IN_YOUR_INBOX'),
        YOU_CURRENTLY_HAVE_NO_PHONES_IN_YOUR_SCHOOL_MESSAGE: t('YOU_CURRENTLY_HAVE_NO_PHONES_IN_YOUR_SCHOOL_MESSAGE'),
        YOU_CURRENTLY_HAVE_NO_PHONES_IN_YOUR_SCHOOL_INSTRUCTION: t('YOU_CURRENTLY_HAVE_NO_PHONES_IN_YOUR_SCHOOL_INSTRUCTION'),
        YOU_WILL_NEED_TO_CONTACT_YOUR_SCHOOL_FOR_MORE_ASSISTANCE: t('YOU_WILL_NEED_TO_CONTACT_YOUR_SCHOOL_FOR_MORE_ASSISTANCE'),
        YOU_DO_NOT_HAVE_PERMISSION: t('YOU_DO_NOT_HAVE_PERMISSION'),
        YOU_WILL_BE_CALLED_AT_THIS_NUMBER_ONLY_IN_THE_EVENT_OF_AN_EMERGENCY: t('YOU_WILL_BE_CALLED_AT_THIS_NUMBER_ONLY_IN_THE_EVENT_OF_AN_EMERGENCY'),
        YOU_WILL_NOT_BE_CALLED_ON_THIS_PHONE: t('YOU_WILL_NOT_BE_CALLED_ON_THIS_PHONE'),
        YOU_WONT_BE_ABLE_TO_CHANGE_YOUR_CONSENT_STATUS_OR_UPDATE_YOUR_MESSAGE_PREFERENCES_TO_CALL_THIS_PHONE_UNTIL_YOUR_CONSENT_STATUS_BECOMES_AVAILABLE_AGAIN:
            t(
                'YOU_WONT_BE_ABLE_TO_CHANGE_YOUR_CONSENT_STATUS_OR_UPDATE_YOUR_MESSAGE_PREFERENCES_TO_CALL_THIS_PHONE_UNTIL_YOUR_CONSENT_STATUS_BECOMES_AVAILABLE_AGAIN'
            ),
        YOUR_4_DIGIT_PIN: t('YOUR_4_DIGIT_PIN'),
        YOUR_DISTRICT_REQUIRES_CONSENT_TO_ENABLE_THIS_NUMBER_YOU_CAN_CHANGE_YOUR_CONSENT_STATUS_IN_THE_CONTACT_PREFERENCE_SETTINGS: t(
            'YOUR_DISTRICT_REQUIRES_CONSENT_TO_ENABLE_THIS_NUMBER_YOU_CAN_CHANGE_YOUR_CONSENT_STATUS_IN_THE_CONTACT_PREFERENCE_SETTINGS'
        ),
        YOUR_PHONE_NUMBER_IS_CURRENTLY_PENDING_PLEASE_PROVIDE_OR_REVOKE_CONSENT_YOUR_PREFERENCES_WILL_NOT_BE_AFFECTED: t(
            'YOUR_PHONE_NUMBER_IS_CURRENTLY_PENDING_PLEASE_PROVIDE_OR_REVOKE_CONSENT_YOUR_PREFERENCES_WILL_NOT_BE_AFFECTED'
        ),
        YOUR_PIN_SETTINGS_HAVE_BEEN_SAVED: t('YOUR_PIN_SETTINGS_HAVE_BEEN_SAVED'),
        YOUR_SCHOOLMESSENGER_ACCOUNT_HAS_NOT_BEEN_CONFIRMED: t('YOUR_SCHOOLMESSENGER_ACCOUNT_HAS_NOT_BEEN_CONFIRMED'),
        YOUR_SCHOOLMESSENGER_ACCOUNT_EMAIL_ADDRESS: t('YOUR_SCHOOLMESSENGER_ACCOUNT_EMAIL_ADDRESS'),
        UNCHECKED: t('UNCHECKED'),
        UNSUBSCRIBE_CONTINUE_RECEIVING: t('UNSUBSCRIBE_CONTINUE_RECEIVING'),
        UNSUBSCRIBE_EMAIL_BLOCKED: t('UNSUBSCRIBE_EMAIL_BLOCKED'),
        UNSUBSCRIBE_EMAIL_NOTIFICATIONS: t('UNSUBSCRIBE_EMAIL_NOTIFICATIONS'),
        UNSUBSCRIBE_FOR_EMAIL: t('UNSUBSCRIBE_FOR_EMAIL'),
        UNSUBSCRIBE_PREFERENCES_UNAVAILABLE: t('UNSUBSCRIBE_PREFERENCES_UNAVAILABLE'),
        UNSUBSCRIBE_SELECT_FROM_LIST: t('UNSUBSCRIBE_SELECT_FROM_LIST'),
        UNSUBSCRIBE_THANK_YOU: t('UNSUBSCRIBE_THANK_YOU'),
        UNSUBSCRIBE_THIS_WILL_BLOCK: t('UNSUBSCRIBE_THIS_WILL_BLOCK'),
        UNSUBSCRIBE_UNSUBSCRIBE_FROM_ALL: t('UNSUBSCRIBE_UNSUBSCRIBE_FROM_ALL'),
        UNSUBSCRIBE_UPDATE_PREFERENCES: t('UNSUBSCRIBE_UPDATE_PREFERENCES'),
        UNSUBSCRIBE_UPDATE_FOR: t('UNSUBSCRIBE_UPDATE_FOR'),
        UNSUBSCRIBE_UPDATES_WILL_APPLY: t('UNSUBSCRIBE_UPDATES_WILL_APPLY'),
        UNSUBSCRIBE_YOU_CAN_ALSO: t('UNSUBSCRIBE_YOU_CAN_ALSO'),
        NOTE_EMAIL_VERIFICATION_IS_NOT_REQUIRED_IN_ORDER_TO_USE_SCHOOLMESSENGER_HOWEVER_YOU_MAY_SEE_INCOMPLETE_MESSAGES_AND_CONTACTS_VIEWS: t(
            'NOTE_EMAIL_VERIFICATION_IS_NOT_REQUIRED_IN_ORDER_TO_USE_SCHOOLMESSENGER_HOWEVER_YOU_MAY_SEE_INCOMPLETE_MESSAGES_AND_CONTACTS_VIEWS'
        ),
        VERIFY: t('VERIFY'),
        VERSION_UPDATE_AVILABLE: t('VERSION_UPDATE_AVILABLE'),
        VERSION_UPDATE_MESSAGE: t('VERSION_UPDATE_MESSAGE'),
        VERSION_UPDATE_NOW: t('VERSION_UPDATE_NOW'),
        SCHOOL_ICON: t('SCHOOL_ICON'),
        SELECT: t('SELECT'),
        SELECT_LANGUAGE: t('SELECT_LANGUAGE'),
        NO_LANGUAGES_FOUND: t('NO_LANGUAGES_FOUND'),
        CALENDAR: t('CALENDAR'),
        LISTVIEW: t('LISTVIEW'),
        SELECTED_APP_LANGUAGE: t('SELECTED_APP_LANGUAGE'),
        SEARCH: t('SEARCH'),
        CHOOSE_LANGUAGE: t('CHOOSE_LANGUAGE'),
        APPLY: t('APPLY'),
        WE_RESPECT_YOUR_PRIVACY: t('WE_RESPECT_YOUR_PRIVACY'),
        HERE: t('HERE'),
        EMERGENCY_ALERT_SERVICES_USING_SMS_TEXT_MESSAGING_ARE_SUPPORTED_ONLY_IN_YHE_US: t(
            'EMERGENCY_ALERT_SERVICES_USING_SMS_TEXT_MESSAGING_ARE_SUPPORTED_ONLY_IN_YHE_US'
        ),
        FILL_OUT_THIS_FORM_TO_UNSUBSCRIBE: t('FILL_OUT_THIS_FORM_TO_UNSUBSCRIBE'),
        SCHOOL_OR_DISTRICT: t('SCHOOL_OR_DISTRICT'),
        MOBILE_PHONE_NUMBER_TO_OPT_OUT: t('MOBILE_PHONE_NUMBER_TO_OPT_OUT'),
        SMS_OPT_OUT: t('SMS_OPT_OUT'),
        DID_YOU_RECEIVE_AN_OPT_IN_INVITATION_TEXT_MESSAGE: t('DID_YOU_RECEIVE_AN_OPT_IN_INVITATION_TEXT_MESSAGE'),
        IF_YOU_RECEIVED_A_TEXT_MESSAGE_REQUESTING_YOU_TO_OPT_INTO_YOUR_SCHOOL: t(
            'IF_YOU_RECEIVED_A_TEXT_MESSAGE_REQUESTING_YOU_TO_OPT_INTO_YOUR_SCHOOL'
        ),
        IF_YOU_WOULD_LIKE_TO_CONTINUE_RECEIVING_MESSAGES: t('IF_YOU_WOULD_LIKE_TO_CONTINUE_RECEIVING_MESSAGES'),
        IS_THE_INFORRMATION_YOU_HAVE_ENTERED_CORRECT: t('IS_THE_INFORRMATION_YOU_HAVE_ENTERED_CORRECT'),
        NO: t('NO'),
        YOU_HAVE_UNSUBSCRIBED_SUCCESSFULLY: t('YOU_HAVE_UNSUBSCRIBED_SUCCESSFULLY'),
        YOUR_RESPONSE_IS_SUBMITTED_SUCCESSFULLY: t('YOUR_RESPONSE_IS_SUBMITTED_SUCCESSFULLY'),
        ABSENCES_CAN_ONLY_BE_REPORTED_WITHIN_THE_CURRENT_SCHOOL_TERM: t('ABSENCES_CAN_ONLY_BE_REPORTED_WITHIN_THE_CURRENT_SCHOOL_TERM'),
    };
};
