/* eslint-disable indent */
// eslint-disable-next-line @spence1115/modules-newlines/import-declaration-newline
import { useState, useMemo, useCallback, useEffect } from 'react';
import moment from 'moment';

/**
 * @param {Record<string, any>} mappedFormFields
 * @param {string} initialAbsenceType
 * @param {number} absenceId
 */
export default function useEditAbsenceForm(mappedFormFields = {}, initialAbsenceType, absenceId) {
    const [absenceType, setAbsenceType] = useState(mappedFormFields.absenceType);
    const [reasonCode, setReasonCode] = useState(mappedFormFields.reasonCode);
    const [date, setDate] = useState(mappedFormFields.date);
    const [endDate, setEndDate] = useState(mappedFormFields.endDate);
    const [inTime, setInTime] = useState(mappedFormFields.inTime);
    const [outTime, setOutTime] = useState(mappedFormFields.outTime);
    const [attachment, setAttachment] = useState(mappedFormFields.attachment || null);
    const [comment, setComment] = useState(mappedFormFields.comment || null);

    useEffect(() => {
        setAbsenceType(mappedFormFields.absenceType);
        setReasonCode(mappedFormFields.reasonCode);
        setDate(mappedFormFields.date);
        setEndDate(mappedFormFields.endDate);
        setInTime(mappedFormFields.inTime);
        setOutTime(mappedFormFields.outTime);
        setAttachment(mappedFormFields.attachment);
        setComment(mappedFormFields.comment);
    }, [mappedFormFields]);

    const onValueChange = useCallback(
        /**
         * @param {string} formFieldName
         * @param {any} value
         */
        (formFieldName, value) => {
            switch (formFieldName) {
                case 'absenceType':
                    setAbsenceType(value);
                    break;
                case 'reasonCode':
                    setReasonCode(value);
                    break;
                case 'date':
                    setDate(value);
                    break;
                case 'endDate':
                    setEndDate(value);
                    break;
                case 'inTime':
                    setInTime(value);
                    break;
                case 'outTime':
                    setOutTime(value);
                    break;
                case 'attachment':
                    setAttachment(value);
                    break;
                case 'comment':
                    setComment(value);
                    break;
                default:
                    break;
            }
        },
        []
    );

    const formatDate = useCallback(
        /**
         * @param {string} date
         * @param {string} lang
         * @returns {string} Date in the format of 'Month DD, YYYY'
         */
        (dateToFormat, lang = 'en') => {
            const newDate = new Date(dateToFormat);
            return `${newDate.toLocaleDateString(lang, {
                month: 'long',
                timeZone: 'UTC',
            })} ${newDate.getUTCDate()}, ${newDate.getUTCFullYear()}`;
        },
        []
    );

    const getChangedValues = () => {
        const formFields = {
            absenceType,
            reasonCode,
            date,
            inTime,
            outTime,
            endDate,
            attachment,
            comment,
        };

        const mappedFormFieldsCopy = { ...mappedFormFields, absenceType: initialAbsenceType };

        const changedValues = Object.keys(formFields).reduce((acc, key) => {
            if (typeof formFields[key] === 'object') {
                if (JSON.stringify(formFields[key]) !== JSON.stringify(mappedFormFieldsCopy[key])) {
                    acc[key] = formFields[key];
                }
            } else if (formFields[key] !== mappedFormFieldsCopy[key]) {
                acc[key] = formFields[key];
            }

            return acc;
        }, {});

        return changedValues;
    };

    const canBeSent = useMemo(() => {
        const diffValues = getChangedValues();

        const valuesChanged = Object.keys(diffValues).length > 0;

        return valuesChanged;
        // eslint-disable-next-line array-element-newline
    }, [absenceType, reasonCode, date, inTime, outTime, endDate, attachment, comment]);

    const getArrayOfAbsenceDates = () => {
        if (absenceType === 'multiDay' && endDate) {
            const date1 = moment(date, 'YYYY-MM-DD');
            const date2 = moment(endDate, 'YYYY-MM-DD');

            const daysDifference = moment.duration(date2.diff(date1)).asDays();

            // Creates an array of dates with the difference between the two dates with the following format: 'YYYY-MM-DD'
            const datesArray = [];

            for (let i = 0; i <= daysDifference; i += 1) {
                const newDate = moment(date1).add(i, 'days').format('YYYY-MM-DD');

                datesArray.push({ date: newDate });
            }

            return datesArray;
        }

        const startDate = { date };

        if (inTime) {
            startDate.inTime = inTime;
        }

        if (outTime) {
            startDate.outTime = outTime;
        }

        return [startDate];
    };

    const getFormValues = () => {
        const changedValues = getChangedValues();

        // delete everything related with dates from the changes
        // values since we already mapped them in the dates array
        delete changedValues.outTime;
        delete changedValues.inTime;
        delete changedValues.endDate;
        delete changedValues.date;

        const mandatoryFields = {
            absenceDates: getArrayOfAbsenceDates(date, endDate),
            client: 'web',
            comment: comment || null,
            attachment: attachment || null,
            id: absenceId,
        };

        return Object.assign(mandatoryFields, changedValues);
    };

    return {
        onValueChange,
        absenceType,
        reasonCode,
        date,
        endDate,
        inTime,
        outTime,
        attachment,
        comment,
        canBeSent,
        formatDate,
        getFormValues,
    };
}
