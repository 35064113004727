import {React} from 'react';
import { 
    Box, 
    Link, 
    Typography, 
} from '@mui/material';

import { utils, env } from 'comm-recipientapp-shared';

import logoSm from 'comm-recipientapp-shared/src/assets/Logo.svg';
import gpLogo from 'comm-recipientapp-shared/src/assets/btn_google_play.png';
import asLogo from 'comm-recipientapp-shared/src/assets/btn_itunes_store.png';
import styles from './styles';


function SmLogoLinks() {
    const strings = utils.useStrings();
    return (
        <Box sx={styles.logoContainer} >
            <Box component="img" sx={styles.logoSm} src={logoSm} />
            <Box sx={styles.storeContainer}>
                <Link sx={styles.link} href={env.APPLE_STORE_URL} target={'_blank'}>
                    <Box component="img" src={asLogo} sx={styles.logoStore} />
                </Link>
                <Link sx={styles.link} href={env.GOOGLE_PLAY_URL} target={'_blank'}>
                    <Box component="img" src={gpLogo} sx={styles.logoStore} />
                </Link>
            </Box>
            <Typography sx={styles.linkContainer} variant="body1">
                <Link sx={styles.link} href={env.SMAPP_VIDEO} target={'_blank'}>
                    {strings.SMAPP_VIDEO}
                </Link>
            </Typography>
        </Box>
    )
}

export default SmLogoLinks;




