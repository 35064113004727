import theme from '../../../config/style/muiTheme';

const styles = {
    logoContainer: {
        display: 'inline-flex',
    },
    logoImg: {
        marginRight: 1,
    },
    chatLogoText: {
        fontWeight: 700,
        fontSize: 30,
        color: theme.palette.purple[100],
        fontFamily: 'Hornbill',
    },
};

export default styles;
