const styles = {
    auto: {
        margin: '0 auto',
    },
    content: {
        height: {
            xs: 'calc(100vh - 60px)',
            sm: 'calc(100vh - 72px)',
            md: 'calc(100vh - 68px)',
            lg: 'calc(100vh - 68px)',
            xl: 'calc(100vh - 68px)',
        },
        mt: '4px',
    },
    indicator_value: {
        paddingTop: '25px',
    },
    indicator: {
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: 100,
        marginTop: '100px',
        textAlign: 'center',
    },
    griMain: {
        height: '100%',
    },
    gridSplashScreen: { display: { xs: 'none', sm: 'none', md: 'block' } },
};

export default styles;
