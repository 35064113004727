import React from 'react';
import { useSelector } from 'react-redux';
import { PickerOverlay } from 'filestack-react';
import useAttachmentUploader from '../hooks/useAttachmentUploader';

export default function AttachmentUpload({ handleClose, handleUploadDone }) {
    const { fileStackConfig, apiKey, securityCredentials } = useAttachmentUploader();
    const { language } = useSelector(state => state.user);

    // PickerOverlay only supports these languages
    const validLangs = ['ca', 'da', 'de', 'en', 'es', 'fr', 'he', 'it', 'ja', 'ko', 'nl', 'no', 'pl', 'pt', 'sv', 'ru', 'vi', 'zh', 'tr', 'ar'];
    let lang = language ? language.split('-')[0] : 'en'; // handle en-US, etc.
    if (!validLangs.includes(lang)) {
        lang = 'en';
    }

    return (
        <PickerOverlay
            apikey={apiKey}
            pickerOptions={{
                ...fileStackConfig,
                onClose: () => handleClose(false),
                onFileUploadFinished: () => handleClose(false),
                lang,
            }}
            clientOptions={{ security: securityCredentials }}
            onUploadDone={result => {
                handleUploadDone(result);
                handleClose(false);
            }}
        />
    );
}
