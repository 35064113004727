import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export default function useAttachmentUploader() {
    const configuration = useSelector(state => state.user.account.configuration);

    const fileStackConfig = useMemo(() => {
        const config = configuration.attachments;

        return {
            accept: [
                'image/jpeg',
                'image/gif',
                'image/png',
                'image/heic',
                'text/plain',
                'application/pdf',
                'application/msword',
                '.doc',
                '.docx',
                '.pdf',
                '.txt',
            ],
            fromSources: config.fromSources,
            maxFiles: config.maxAttachmentsConversation,
            maxSize: config.maxAttachmentSize,

            storeTo: {
                location: config.location,
                container: config.container,
                region: config.region,
            },

            hideModalWhenUploading: false,
            uploadInBackground: false,
            startUploadingWhenMaxFilesReached: true,
        };
    }, [configuration]);

    const apiKey = useMemo(() => configuration.attachments.key, [configuration]);

    const securityCredentials = useMemo(
        () => ({
            policy: configuration.attachments.policy,
            signature: configuration.attachments.signature,
        }),
        [configuration]
    );

    return { fileStackConfig, apiKey, securityCredentials };
}
