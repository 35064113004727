import React from 'react';
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { PhoneNumberVerificationMethods } from 'comm-recipientapp-shared/src/utils/constants';
import { useStrings } from 'comm-recipientapp-shared/src/utils/useStrings';
import verificationImage from 'comm-recipientapp-shared/src/assets/verification_img.png';
import styles from '../styles';

export default function VerifyNumber({ handleVerificationMethodChange = () => {}, currentMethod = PhoneNumberVerificationMethods.SMS }) {
    const {
        PREFERENCES_VERIFY_NUMBER_CALL_OPTION,
        PREFERENCES_VERIFY_NUMBER_SMS_OPTION,
        PREFERENCES_VERIFY_NUMBER_TITLE,
        PREFERENCES_VERIFY_NUMBER_DESCRIPTION,
    } = useStrings();

    return (
        <Box sx={styles.verification}>
            <Box sx={styles.verification_image_container}>
                <img style={styles.verification_image} src={verificationImage} alt="verification" />
            </Box>
            <Typography sx={styles.verification_description}>{PREFERENCES_VERIFY_NUMBER_TITLE}.</Typography>
            <Typography sx={styles.verification_description}>{PREFERENCES_VERIFY_NUMBER_DESCRIPTION}?</Typography>
            <Box sx={styles.verification_radio_group_container}>
                <FormControl onChange={e => handleVerificationMethodChange(e.target.value)}>
                    <RadioGroup name="verification-group">
                        <FormControlLabel
                            value={PhoneNumberVerificationMethods.SMS}
                            control={<Radio />}
                            label={<Typography sx={styles.verification_radio_label}>{PREFERENCES_VERIFY_NUMBER_SMS_OPTION}</Typography>}
                            checked={currentMethod === PhoneNumberVerificationMethods.SMS}
                        />
                        <FormControlLabel
                            value={PhoneNumberVerificationMethods.CALL}
                            control={<Radio />}
                            label={<Typography sx={styles.verification_radio_label}>{PREFERENCES_VERIFY_NUMBER_CALL_OPTION}</Typography>}
                            checked={currentMethod === PhoneNumberVerificationMethods.CALL}
                        />
                    </RadioGroup>
                </FormControl>
            </Box>
        </Box>
    );
}
