import { constants } from 'comm-recipientapp-shared/src/utils';
import { useMemo } from 'react';

// eslint-disable-next-line import/prefer-default-export
export const usePreferencesSettings = school => {
    const settings = useMemo(() => {
        const schoolSettings = school.settings ? school.settings : null;
        const phonePriorityEnforcement = schoolSettings ? schoolSettings.phonePriorityEnforcement : null;
        const canDeleteImportedDestinations = schoolSettings ? schoolSettings.canDeleteImportedDestinations : false;
        const canAddDeleteManualDestinations = schoolSettings ? schoolSettings.canAddDeleteManualDestinations : false;
        const requireEmergencyPhone = phonePriorityEnforcement ? phonePriorityEnforcement.includes(constants.EMERGENCY_KEY) : false;
        const requireHighPriorityPhone = phonePriorityEnforcement ? phonePriorityEnforcement.includes(constants.ATTENDANCE_KEY) : false;
        const hasPhone = school.destinationPreferences ? school.destinationPreferences.phones.length > 0 : false;
        const hasContentError = (requireEmergencyPhone || requireHighPriorityPhone) && !hasPhone;

        return {
            canDeleteImportedDestinations,
            canAddDeleteManualDestinations,
            requireEmergencyPhone,
            requireHighPriorityPhone,
            hasPhone,
            hasContentError,
        };
    }, [school]);

    return settings;
};
