/* eslint-disable prettier/prettier */
import React from 'react';
import {
    Box,
    Stack,
    Skeleton,
} from "@mui/material";
import styles from './styles';
import { useStrings } from 'comm-recipientapp-shared/src/utils';

function SkeletonTitle({showOverlay}) {
    const strings = useStrings();
    return (
        <Stack 
            direction="column" 
            spacing={1} 
            sx={{
                ...styles.chatDataContainer, 
                display: showOverlay ? "none": "block"
            }}
        >
            <Box sx={styles.BoxAvatar}>
                <Skeleton
                    variant="rectangular"
                    width={40}
                    height={40}
                    aria-label={`${strings.LOADING} ${strings.CHAT_AVATAR_TEXT}`}
                    sx={styles.avatarText}
                    />
            </Box>
            <Box>
                <Skeleton
                    variant="rectangular"
                    width={220}
                    height={14}
                    sx={styles.skeletonText}
                    aria-label={`${strings.LOADING} ${strings.CHAT_NAME}`}
                />
            </Box>
            <Box>
                <Skeleton
                    variant="rectangular"
                    width={280}
                    height={12}
                    sx={styles.skeletonText}
                    aria-label={`${strings.LOADING} ${strings.CHAT_DESCRIPTION}`}
                />
            </Box>
        </Stack>
    );
}

export default SkeletonTitle;
