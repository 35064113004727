import React, { useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { useStrings } from 'comm-recipientapp-shared/src/utils/useStrings';
import styles, { MaskedStyledInput } from '../styles';
import '../styles.css';

export default function AddNumber({ phone = '', handlePhoneChange, isNumberComplete, maskedPhoneNumberRef }) {
    const { PREFERENCES_ADD_NUMBER_FIELD_LABEL, PREFERENCES_ADD_NUMBER_DESCRIPTION } = useStrings();

    /** @type {React.MutableRefObject<HTMLInputElement>} */
    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current) {
            if (phone.length === 0) {
                inputRef.current.classList.remove('text_field_error_state');
                return;
            }

            // check if the input is focused and if the current
            // value length is lower than the minimum length for US and CA phone numbers
            if (document.activeElement === inputRef.current && !isNumberComplete) {
                inputRef.current.classList.add('text_field_error_state');
            } else {
                inputRef.current.classList.remove('text_field_error_state');
            }
        }
    }, [phone]);

    return (
        <Box sx={styles.input_and_text_section}>
            <Typography component="label" htmlFor="phoneNumber" sx={styles.input_label}>
                {PREFERENCES_ADD_NUMBER_FIELD_LABEL}
            </Typography>
            <MaskedStyledInput
                mask="(#00) 000-0000"
                definitions={{
                    '#': /[0-9]/,
                }}
                onAccept={(value, maskRef) => {
                    if (value.length === 0) {
                        handlePhoneChange('');
                        return;
                    }

                    // eslint-disable-next-line no-param-reassign
                    maskedPhoneNumberRef.current = inputRef.current.value;
                    handlePhoneChange(maskRef.unmaskedValue);
                }}
                inputRef={inputRef}
                lazy={false}
                placeholderChar="X"
                id="phoneNumber"
            />
            <Typography sx={styles.description}>{PREFERENCES_ADD_NUMBER_DESCRIPTION}.</Typography>
        </Box>
    );
}
