import theme from '../../../config/style/muiTheme';

const styles = {
    titleContainer: {
        marginTop: "5px",
        marginBottom: "-10px",
        display: "inline-flex",
    },
    logoContainer: { 
        width: "44px", 
        paddingTop: "29px" 
    },
    logo: { 
        width: "100%", 
        height: "auto" 
    },
    title: {
        marginLeft: "5px"
    },
    svgContainer: {
        justifyContent: 'center',
        flexDirection: 'column',
        margin: "0 auto",
        maxWidth: "340px",
        minWidth: "120px",
        width: "60%",
        marginTop: "30px",
        marginBottom: "-15px",
    },
    no_data_title: { 
        fontWeight: '700', 
        fontSize: '24px', 
        lineHeight: '25.44px', 
        textAlign: 'center', 
        color: '#585988' 
    },
    no_data_subtitle: {
        fontWeight: '400',
        fontSize: '18px',
        lineHeight: '25.2px',
        textAlign: 'center',
        color: '#585988',
        marginTop: '20px',
    },
    no_data_message_container: { 
        display: 'flex', 
        justifyContent: 'center', 
        flexDirection: 'column' 
    },
};

export default styles;
