import theme from '../../../../config/style/muiTheme';

const Styles = {
    modal_styles: {
        width: '100%',
        maxWidth: '640px',
    },
    add_preferences_for_number_header: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
        height: '50px',
        alignItems: 'center',
    },
    add_preferences_for_number_header_text: { color: 'white' },
    add_preferences_for_number_text_container: { margin: '40px 40px' },
    add_preferences_for_number_buttons_container: { display: 'flex', justifyContent: 'center', gap: '20px', margin: '15px 0px' },
    add_preferences_for_number_cancel_button: { ':hover': { backgroundColor: 'white', color: theme.palette.primary.main } },
};

export default Styles;
