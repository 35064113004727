/* eslint-disable prettier/prettier */
import React from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import {
    Typography,
    Container,
    Stack,
    Button,
    InputLabel,
    FormControl,
    IconButton,
    InputAdornment,
    Input
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useStrings } from 'comm-recipientapp-shared/src/utils';
import useValidationsForNewPassword from './customHooks';
import Layout from '../Layout';
import Styles from './styles';

export default function ResetPassword() {
    const navigate = useNavigate();
    const location = useLocation();
    const strings = useStrings();

    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    const {
        handleChangePassword,
        handleClickShowPassword,
        setNewPasswordText,
        newPasswordButtonEnabled,
        hasOneLowerCaseLetter,
        hasOneNumber,
        hasOneUpperCaseLetter,
        hasBetween6And255Characters,
        loading,
        showConfirmMessage,
        showUnexpectedErrorMessage,
        show404ErrorMessage,
        showPassword,
        newPasswordText,
    } = useValidationsForNewPassword(token);

    const goToLogin = () => {
        navigate('/login');
    };
    const goToHome = () => {
        navigate('/');
    };

    React.useEffect(() => {
        if (!token) {
            goToHome();
        }
    }, []);

    return (
        <Layout>
            <Container maxWidth="sm" sx={Styles.content}>
                {!showConfirmMessage && <Stack sx={Styles.fillPasswordStack}>
                    <Typography sx={Styles.title}>{strings.RESET_PASSWORD}</Typography>
                    <FormControl sx={Styles.formControl} variant="standard">
                        <InputLabel htmlFor="standard-adornment-password">{strings.NEW_PASSWORD}</InputLabel>
                        <Input
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label={strings.TOGGLE_PASSWORD_VISIBILITY}
                                        onClick={handleClickShowPassword}
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            value={newPasswordText}
                            onChange={e => setNewPasswordText(e.target.value)}
                            max={255}
                        />
                    </FormControl>
                    <Stack direction='row' spacing={1}>
                        <Typography variant="caption" color={(hasOneLowerCaseLetter? 'success.dark': 'black')}>{strings.ONE_LOWERCASE_LETTER}</Typography>
                        <Typography variant="caption" color={(hasOneNumber? 'success.dark': 'black')}>{strings.ONE_NUMBER}</Typography>
                    </Stack>
                    <Stack direction='row' spacing={1}>
                        <Typography variant="caption" color={(hasOneUpperCaseLetter? 'success.dark': 'black')}>{strings.ONE_UPPERCASE_LETTER}</Typography>
                        <Typography variant="caption" color={(hasBetween6And255Characters? 'success.dark': 'black')}>{strings.SIGN_UP_CHARACTERS}</Typography>
                    </Stack>
                    <Button
                        variant="contained"
                        size="large"
                        fullWidth
                        disableElevation
                        onClick={handleChangePassword}
                        sx={Styles.setNewPasswordButton}
                        disabled={newPasswordButtonEnabled || loading}
                    >
                        {strings.SET_NEW_PASSWORD}
                    </Button>
                    {show404ErrorMessage && <Typography sx={Styles.TypographyErrorMessage}>
                        {strings.RESET_PASSWORD_404_CODE_PART_ONE}
                        <Link to="/forgotpassword">{strings.RESET_PASSWORD_404_CODE_LINK_PART}</Link>
                    </Typography>}
                    {showUnexpectedErrorMessage && <Typography sx={Styles.TypographyErrorMessage}>{strings.UNEXPECTED_ERROR}</Typography>}
                </Stack>}
                {showConfirmMessage && (
                    <Stack>
                        <Typography sx={Styles.TypographyProcessingToken}>{strings.YOU_ARE_ALL_SET_YOU_CAN_LOG_IN_NOW_WITH_YOUR_NEW_PASSWORD}</Typography>
                        <Button variant="contained" onClick={goToLogin} sx={Styles.goToLoginButton}>
                            {strings.GO_TO_LOGIN}
                        </Button>
                    </Stack>
                )}
            </Container>
        </Layout>
    );
}
