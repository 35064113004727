import theme from '../../../config/style/muiTheme';

const styles = {
    bottom_title: {
        color: theme.palette.blue['140'],
        fontSize: 11,
        textAlign: 'center',
    },
    circle_active: {
        alignItems: 'center',
        backgroundColor: '#43AC6A',
        border: '1px #A4B5C5 solid',
        borderRadius: '20px',
        display: 'flex',
        height: '40px',
        justifyContent: 'center',
        width: '40px',
    },
    circle_icon_active: {
        color: '#ffffff'
    },
    circle_icon_inactive: {
        color: theme.palette.grey['450'],
    },
    circle_inactive: {
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        border: `1px ${theme.palette.grey['450']} solid`,
        borderRadius: '20px',
        display: 'flex',
        height: '40px',
        justifyContent: 'center',
        width: '40px',
    },
    content_messages: {
        marginBottom: '20px',
        marginTop: '40px',
    },
    header_title: {
        color: '#2C5871',
        fontSize: 22,
        fontWeight: 300,
    },
    header: {
        alignItems: 'center',
        borderBottom: '1px solid #ddd',
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: '10px',
    },
    message_card_actions: {
        bottom: -18,
        display: 'flex',
        gap: '10px',
        justifyContent: 'center',
        position: 'absolute',
    },
    message_card_bg: {
        alignItems: 'center',
        backgroundColor: 'primary.main',
        display: 'flex',
        height: '99px',
        justifyContent: 'center',
        position: 'relative',
    },
    message_card_bottom: {
        alignItems: 'center',
        backgroundColor: '#ffffff',
        display: 'flex',
        height: '99px',
        justifyContent: 'center',
        padding: '15px',
    },
    message_card_title: {
        color: '#fff',
        fontSize: 20,
        fontWeight: 300,
    },
    message_card: {
        borderRadius: '0px',
        boxShadow: '0 1px 4px rgb(0 0 0 / 10%)',
        position: 'relative',
        width: '100%',
        cursor: 'pointer'
    },
    message_list: {
        marginTop: '15px',
    },
};

export default styles;
