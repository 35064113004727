import React, {useState} from 'react';
import {
    Box,
    Divider,
    Typography,
    Button
} from '@mui/material';
import { utils } from "comm-recipientapp-shared";
import { getMessageTitle } from '../Message/utils';
import styles from './styles';

export default function TitleSeparator({ title, badgeCount, handleMarkAllReadStatus, windowWidth, dividerStyles = {} }) {
    const strings = utils.useStrings();
    const isShowMarkAllLink = (badgeCount > 0) && (title === strings.ALL_MESSAGES);

    return (
        <Box sx={styles.container}>
            <Box sx={styles.title_container}>
                <Box sx={styles.title_box} tabIndex={0}>
                    <Typography sx={styles.title}>{title}:</Typography>
                    <Box sx={styles.badge_container}>
                        <Typography 
                            sx={styles.badge} 
                            data-testid="messageCount">
                            {badgeCount}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <Box sx={styles.divider_container}>
                <Divider variant="fullWidth" sx={{ ...styles.divider, ...dividerStyles }} />
            </Box>
            {
                isShowMarkAllLink ?
                <Box sx={styles.button}>
                    <Button 
                        tabIndex={0}
                        sx={styles.link_button} 
                        underline='none' 
                        onClick={handleMarkAllReadStatus}>
                        {getMessageTitle(strings.MARK_ALL_AS_READ, windowWidth)}
                    </Button>
                </Box> :
                <Box sx={styles.buttonDisabled}>
                    {getMessageTitle(strings.MARK_ALL_AS_READ, windowWidth)}
                </Box>
            }
        </Box>
    );
}
