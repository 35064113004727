import theme from "../../../config/style/muiTheme";

const styles = {
    listItem: {
        paddingLeft: 0, 
        paddingRight: 0
    },
    boxHandler: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    },
    flexStart: {
        display: "flex", 
        justifyContent: "flex-start", 
        alignItems: "center"
    },
    flexEnd: {
        display: "flex", 
        justifyContent: "flex-end", 
        alignItems: "center"
    },
    category: {
        fontSize: 10,
        height: 20
    },
    itemList:{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "space-between",
        padding: "5px 0px",
        width: '100%',
    },
    subject: {
        color: theme.palette.text.primary
    },
};

export default styles;
