import { environments } from '../utils';
import env from '../env';

const isDev = process.env.REACT_APP_ENVIRONMENT === 'development';
const keyEnvironment = env.REACT_APP_ENVIRONMENT_KEY;

export const RECIPIENT_SERVICE = isDev ? '/api/4' : `${environments.us.recipientService.apiBaseUrl}/recipient/api/1`;
export const RECIPIENT_SERVICE_PHONE_VERIFICATION = isDev ? '/api/3' : `${environments.us.recipientService.apiBaseUrl}/recipient/api/2`;
export const GET_RECIPIENT_SERVICE = country => {
    const recipientService = environments?.[country]?.recipientService?.[keyEnvironment];
    return `${recipientService}/recipient/api/1`;
};
export const GET_RECIPIENT_SERVICE_PHONE_VERIFICATION = country => {
    const recipientService = environments[country].recipientService[keyEnvironment];
    return `${recipientService}/recipient/api/2`;
};

export const CHAT_RECIPIENT_SERVICE = isDev ? '/api/5' : `${environments.us.recipientService.apiBaseUrl}/recipient/api/2`;
export const GET_CHAT_SERVICE = country => {
    const chatService = environments[country]?.recipientService[keyEnvironment];
    return `${chatService}/recipient/api/2`;
};

// API interceptor localStorage keys
export const ApiInterceptorKeys = {
    EXPIRED_SESSION: 'expired_session',
    FATAL_SERVER_ERROR: 'fatal_server_error',
};

export const WEB_PUBLIC_ROUTES = ['/forgotpassword', '/login', '/signup', '/learn'];
