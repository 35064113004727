import React from 'react';
import Landing from '../../components/Landing';
import Attendance from '../../components/Attendance';
import Preferences from '../../components/Preferences';
import EditPhonePreferences from '../../components/Preferences/Edit/Phone';
import EditEmailPreferences from '../../components/Preferences/Edit/Email';
import Students from '../../components/Students';
import MyContacts from '../../components/MyContacts';
import Chat from '../../components/Chat';
import Inbox from '../../components/Inbox';
import MessageDetails from '../../components/Inbox/MessageDetails';
import NotFoundRecords from '../../components/NotFoundRecords';
import AddNewEmail from '../../components/AddNewEmail';
import StudentsClaiming from '../../components/StudentsClaiming';
import GetInTouchWithTheSchool from '../../components/NotFoundRecords/GetInTouchWithTheSchool';
import ValidateEmail from '../../components/AddNewEmail/ValidateEmail';
import OneWaySMSSupport from '../../components/SMSOptOut/OneWaySMSSupport';

export const ONE_WAY_SMS_SUPPORT = '/42';

const privateRoutes = [
    {
        path: '/landing',
        title: 'Landing',
        exact: true,
        element: <Landing />,
    },
    {
        path: '/inbox',
        title: 'Inbox',
        exact: true,
        element: <Inbox />,
    },
    {
        path: '/attendance',
        title: 'Attendance',
        exact: true,
        element: <Attendance />,
    },
    {
        path: '/preferences',
        title: 'Preferences',
        exact: true,
        element: <Preferences />,
    },
    {
        path: '/preferences/edit/phone/:phone',
        title: 'Preferences',
        exact: true,
        element: <EditPhonePreferences />,
    },
    {
        path: '/preferences/edit/sms/:phone',
        title: 'Preferences',
        exact: true,
        element: <EditPhonePreferences />,
    },
    {
        path: '/preferences/edit/email/:index',
        title: 'Preferences',
        exact: true,
        element: <EditEmailPreferences />,
    },
    {
        path: '/my-students',
        title: 'My Students',
        exact: true,
        element: <Students />,
    },
    {
        path: '/mycontacts',
        title: 'My Contacts',
        exact: true,
        element: <MyContacts />,
    },
    {
        path: '/chat',
        title: 'Chat',
        exact: true,
        element: <Chat />,
    },
    {
        path: 'inbox/message-details',
        title: 'Message Details',
        exact: true,
        element: <MessageDetails />,
    },
    {
        path: '/not-found-records',
        title: 'Not Found Records',
        exact: true,
        element: <NotFoundRecords />,
    },
    {
        path: '/add-new-email',
        title: 'Add new email',
        exact: true,
        element: <AddNewEmail />,
    },
    {
        path: '/validate-email',
        title: 'Validate email',
        exact: true,
        element: <ValidateEmail />,
    },
    {
        path: '/get-in-touch-with-the-school',
        title: 'Get in touch with the school',
        exact: true,
        element: <GetInTouchWithTheSchool />,
    },
    // disable this for now while we define new details for this feature
    {
        path: '/students-to-claim',
        title: 'Students To Claim',
        exact: true,
        element: <StudentsClaiming />,
    },
    {
        path: ONE_WAY_SMS_SUPPORT,
        title: 'One-way SMS Support',
        exact: false,
        element: <OneWaySMSSupport />,
    },
];

export default privateRoutes;
