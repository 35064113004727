import React from 'react';

export default function TextPlain({ height, width, fillColor }) {
    return (
        <svg data-icon="TextPlain" height={height} width={width} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512'>
            <path
                fill={fillColor}
                d='M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z'
            />
        </svg>
    );
}
