import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Box, Typography } from '@mui/material';
import styles from './styles';

export default function BadgeNavButton ({name, showBadge, linkTo, isActive, icon, iconFocused}) {
    return (
        <Box
            aria-label={name}
            sx={styles.container}>
            <NavLink role="navigation" to={linkTo} >
                <Button
                    tabIndex={-1}
                    disableRipple
                    disableFocusRipple
                    sx={{
                        ...styles.button, 
                        ...(isActive && styles.activeButton),
                    }}
                    >
                    <Box
                        aria-hidden
                        component="img"
                        src={isActive ? iconFocused : icon}
                        sx={styles.icon}
                    />
                    <Typography sx={styles.typography}>
                        {name}
                    </Typography>
                </Button>
            </NavLink>
            {showBadge && (
                <Box tabIndex={-1} sx={styles.badge}></Box>
            )}
        </Box>
    );
};
