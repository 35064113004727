/* eslint-disable prettier/prettier */
/* eslint-disable import/prefer-default-export */
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { RC_DISCUSSION_GROUP_TYPES } from '../../utils/constants';
import { chatAPI } from '../../api';
import debounce from 'lodash.debounce';
import { sleep } from '../../utils/helpers';
import env from '../../env';

const isMobile = env.IS_MOBILE !== 'false';

import {
    setStateFromGetMemberOf,
    setCurrentChatUser,
    resetChatState,
    setTotalUnreadCount,
    setHasUnreadMessages,
} from '../../store/chat/chatSlice';

export const useChat = () => {
    const dispatch = useDispatch();
    const { account: { chatUsers } } = useSelector(state => state.user) || [];
    const [conversationItems, setConversationItems] = useState([]);
    
    const { 
        sections,
        discussionGroups,
        directMessages,
        contactsList 
    } = useSelector(state => state.chat);

    useEffect(() => {
        setConversationItems(
            discussionGroups.concat(sections.concat(directMessages))
        );
    }, [discussionGroups, sections, directMessages])
    
    const chatUser = chatUsers?.length > 0 ? chatUsers[0] : null;

    const activityApiCalls = async (chatUser) => {
        const { personid, customerid } = chatUser;

        let response;
        try { response = await chatAPI.getConversationsActivity({ customerid, personid }); } catch { }

        if (response?.data?.result?.items) {
            const activityResponseItems = response.data.result.items;
            const unreadCount = calculateTotalUnread(activityResponseItems);

            activityResponseItems.forEach(activityItem => {
                if (activityItem.unreadCount > 0 ) {
                    dispatch(setHasUnreadMessages({
                        conversationId: activityItem.conversationId,
                        hasUnreadMessages: true,
                    }));
                    try {
                        chatAPI.activateConversation(activityItem.conversationId, {
                            personid,
                            customerid,
                            activate: true
                        });
                    }
                    catch {}
                }
            });
            
            dispatch(setTotalUnreadCount(unreadCount));
        }
    };

    const calculateTotalUnread = (activityResponseItems) => {
        return activityResponseItems.filter((item) => {
            return item.unreadCount > 0;
        }).length;
    };
    
    const getConversationUnreadMessages = async (customerid, personid, conversationItems) => {
        try {
            const { data: { result: { items } } } = await chatAPI.getConversationsActivity({ customerid, personid });

            if (!items) {
                return conversationItems;
            }
                
            const unreadMessageFiltered = items.filter(({ unreadCount }) => (unreadCount > 0))
                .reduce((result, activity) => {
                    result.push(activity.conversationId);
                    return result;
                }, []);
            // add unread messages flags to each conversation
            const conversationWithUreadFlag = conversationItems.map((conversations, index) => {
                // Excluding the first conversation because it is the one that will be taken by default for the UI, so it's marked as read
                // immediately when the chat loads. This way, we avoid the user having to click the conversation again to disable the badge.
                const hasUnreadMessages = isMobile ? unreadMessageFiltered.includes(conversations.id) : (index !== 0 && unreadMessageFiltered.includes(conversations.id));
                
                return { ...conversations, hasUnreadMessages };
            });
            return conversationWithUreadFlag;
        } catch (error) {
            return conversationItems;
        }
    };

    const getDisabledUnreadConversations = (conversations, chatUser) => {
        return conversations.filter((conversation) => {
            const { members } = conversation;
            const currentUserMember = members.find(member => member.contact?.id === (chatUser.personid || chatUser.personId));
            const isActiveForUser = currentUserMember.active;

            return (
                currentUserMember &&
                (!isActiveForUser) &&
                (conversation.hasUnreadMessages === true)
            );
        });
    }

    const activateUnreadDisabledConversations = (unreadDisabledMessages, chatUser) => {
        const customerid = chatUser?.customerid || chatUser?.customerId;
        const personid = chatUser?.personid || chatUser?.personId;
        
        unreadDisabledMessages.forEach(message => {
            try {
                chatAPI.activateConversation(message.id, {
                    personid,
                    customerid,
                    activate: true
                });
            } catch {}
        });
    }

    const getMemberOfByChatUserForBottomTab = useCallback(debounce(async (user, currentChat, conversationId) => {
        try {
            const customerid = user?.customerid || user?.customerId;
            const personid = user?.personid || user?.personId;

            if (!customerid || !personid)
                return false;

            const { data } = await chatAPI.getConversationMemberOf(customerid, personid);

            return !(!data);
        } catch (error) {
            // TODO: Exception should not be swalled.
            return false;
        }
    }, 1000, {leading: true}), []);

    const getMemberOfByChatUser = useCallback(debounce(async (user, currentChat, conversationId) => {
        try {
            const customerid = user?.customerid || user?.customerId;
            const personid = user?.personid || user?.personId;

            if (!customerid || !personid)
                return false;

            const { data } = await chatAPI.getConversationMemberOf(customerid, personid);

            if (!data) return false;
            const conversationItems = data?.result?.items || [];
            
            const conversationItemsWithUnreadFlag = await getConversationUnreadMessages(customerid, personid, conversationItems);
            const unreadDisabledMessages = getDisabledUnreadConversations(conversationItemsWithUnreadFlag, user);
            activateUnreadDisabledConversations(unreadDisabledMessages, user);
            dispatch(setStateFromGetMemberOf(conversationItemsWithUnreadFlag, currentChat, conversationId));
            return true;
        } catch (error) {
            // TODO: Exception should not be swalled.
            return false;
        }
    }, 1000, {leading: true}), []);

    const getMemberOfByFirstChatUser = async () => {
        if (!chatUser) return false;
        await getMemberOfByChatUser(chatUser);
    };

    const getLogInFirstChatUser = async () => {
        if (!chatUser) return false;
        return await getLogInChatUser(chatUser);
    };

    const getLogInChatUser = useCallback(async (loginCredentials) => {
        try {
            const customerId = loginCredentials?.customerid || loginCredentials?.customerId;
            const personId = loginCredentials?.personid || loginCredentials?.personId;
            const { data } = await chatAPI.getConversationLogin(customerId, personId);
            if (!data?.result) return false;
            dispatch(setCurrentChatUser(data.result));
            return data.result;
        } catch (error) {
            return false;
        } 
    }, []);

    const changeChatUserSequence = async (loginCredentials) => {
        dispatch(resetChatState());
        const user = await getLogInChatUser(loginCredentials);
        await getMemberOfByChatUser(user);
    }

    const getNameToDisplayForConversations = conversation => {
        if (conversation.type === RC_DISCUSSION_GROUP_TYPES.PRIVATE) {
            const { members } = conversation;

            if (!members) {
                return conversation.name;
            }
            const talkingTo = members.find(member => member.contact.id !== (chatUser.personId || chatUser.personid));
            if (!talkingTo) {
                return conversation.name;
            }
            return talkingTo.contact.name;
        }
        return conversation.name;
    };

    const findConversationByRoomName = (rcroomname) => {
        return contactsList.find(contact => contact.rcroomname === rcroomname);
    };

    const countUnreadConversationMessagesForAllChatUsers = useCallback(debounce(async () => {
        try {
            const results = await Promise.all(chatUsers.map(async (c) => {
                await sleep(200);
                // this api has a very low rate limit threshold, so lets wait before calling it for other districts.
                const result = await chatAPI.getConversationsActivity({ customerid: c.customerid || c.customerId, personid: c.personid || c.personId });
                return result;
            }));
            if (results.filter((i) => i === undefined).length > 0)
                return;
            const sum = results.map((x) => x?.data?.result?.items || []).reduce((a, b) => a.concat(b), []).map(x => x.unreadCount || 0).reduce((a, b) => a + b, 0);
            dispatch(setTotalUnreadCount(sum));
        }
        catch {
            // TODO: Report to new-relic.
        }

    }, 1000, { leading: true }), []);


    return {
        getMemberOfByFirstChatUser,
        getMemberOfByChatUser,
        getMemberOfByChatUserForBottomTab,
        getLogInFirstChatUser,
        changeChatUserSequence,
        activityApiCalls,
        chatUser,
        findConversationByRoomName,
        getNameToDisplayForConversations,
        countUnreadConversationMessagesForAllChatUsers,
        conversationItems,
    };
};
