import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Typography } from '@mui/material';
import { useStrings } from 'comm-recipientapp-shared/src/utils';
import styles from './styles';

export default function NotAllowedToDeletePopup({ emailToDelete, handleClose }) {
    const language = useSelector(state => state.user.language);

    const {
        PREFERENCES_NOT_ALLOWED_DELELETE_CONTACT_BANNER_TITLE,
        PREFERENCES_NOT_ALLOWED_DELELETE_CONTACT_DESCRIPTION,
    } = useStrings();

    const splittedMessage = useMemo(
        () => PREFERENCES_NOT_ALLOWED_DELELETE_CONTACT_DESCRIPTION.split('/'),
        [language]
    );

    return (
        <Box>
            <Box sx={styles.banner_text_container}>
                <Typography sx={styles.banner_text}>
                    {PREFERENCES_NOT_ALLOWED_DELELETE_CONTACT_BANNER_TITLE}
                </Typography>
            </Box>

            <Typography sx={styles.description}>
                {splittedMessage[0]} <b>{emailToDelete}</b> {splittedMessage[1]}.
            </Typography>

            <Box sx={styles.button_container}>
                <Button variant="contained" onClick={() => handleClose()}>
                    OK
                </Button>
            </Box>
        </Box>
    );
}
