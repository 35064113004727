import { useCallback } from 'react';
import { updateMessagesStatus } from 'comm-recipientapp-shared/src/api/feed';
import { ARCHIVED, READ, UNREAD } from '../utils';

export default function useMessageActions(onActionFinished = () => {}, currentMessagesOnPage = []) {
    const messages = currentMessagesOnPage;

    const findMessage = (currentList, messageId) => {
        return currentList.find(message => {
            return message.id === messageId;
        });
    };

    const setMessageReadStatus = useCallback(
        async messageId => {
            await updateMessagesStatus([messageId], READ);
            onActionFinished();
        },
        [onActionFinished]
    );

    const toggleMessageReadStatus = useCallback(
        async messageId => {
            const message = findMessage(messages, messageId);
            if (message) {
                const status = message.status.includes(READ.toLowerCase()) ? UNREAD : READ;
                await updateMessagesStatus([messageId], status);
                onActionFinished();
            }
        },
        [messages, onActionFinished]
    );

    const archiveItem = useCallback(
        async messageId => {
            const message = findMessage(messages, messageId);
            if (message) {
                const status = ARCHIVED;
                await updateMessagesStatus([messageId], status);
                onActionFinished();
            }
        },
        [messages, onActionFinished]
    );

    const handleMarkAllReadStatus = useCallback(async () => {
        const currentDate = new Date();
        const currentTimestamp = currentDate.toISOString().slice(0, -5) + 'Z'; //needs to be UTC
        
        await updateMessagesStatus(null, READ, currentTimestamp);
        onActionFinished();
    }, [onActionFinished]);

    return {
        setMessageReadStatus,
        toggleMessageReadStatus,
        handleMarkAllReadStatus,
        archiveItem,
    };
}
