import { palette } from 'comm-recipientapp-shared';

const Styles = {
    content: {
        height: 'calc(100vh - 64px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    TypographyProcessingToken: {
        fontSize: '16px',
        fontWeight: 300,
        color: palette.blue['130'],
        margin: '40px auto',
        textAlign: 'center',
    },
    goToLoginButton: {
        '&:hover': {
            bgcolor: '#1E1F41',
        },
    },
    TypographyErrorMessage: {
        fontSize: '16px',
        fontWeight: 300,
        color: palette.error.dark,
        margin: '40px auto',
        textAlign: 'center',
    }
};

export default Styles;
