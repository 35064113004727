/* eslint-disable prettier/prettier */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line prettier/prettier
import {
    ListItemButton,
    ListItemIcon,
    Avatar,
    ListItemText,
    Typography,
    IconButton,
} from "@mui/material";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import { 
    setCurrentChat, 
    setHasUnreadMessages, 
    setUserActive,
} from 'comm-recipientapp-shared/src/store/chat/chatSlice';

import { utils } from 'comm-recipientapp-shared';

import PopoverModal from './PopoverModal';
import ActivityBadge from '../../utils/ActivityBadge';

import styles from '../styles';

function DirectMessageListItem(props) {
    const {
        conversationItem,
        setShowPanel,
        chatUser,
        disabled,
    } = props;
    
    const {
        type,
        id,
        customerid,
        hasUnreadMessages,
    } = conversationItem;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [displayChat, setDisplayChat] = React.useState(conversationItem);
    const { stringAvatar, getConsistentColorFromString } = utils.avatar;
    const strings = utils.useStrings();

    const open = Boolean(anchorEl);
    const popover = open ? 'simple-popover' : undefined;

    const dispatch = useDispatch();
    const {currentChat} = useSelector(state => state.chat);

    const handlePopOver = event => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handlePopOverClose = event => {
        setAnchorEl(null);
        event.stopPropagation();
    };

    const handleclick = () => {
        dispatch(setHasUnreadMessages({
            conversationId: conversationItem.id, 
            hasUnreadMessages: false})
        );
        dispatch(setCurrentChat(displayChat));
        dispatch(setUserActive(true));
        setShowPanel('chat');
    };

    const getDisplayChat = conversation => {
        const { members } = conversation;
        const talkingTo = members.find(member => member.contact?.id !== chatUser.personid);
        const name = talkingTo ? talkingTo.contact.name : conversation.name;
        return   {
            ...conversation,
            name
        };
    };

    useEffect(() => {
        setDisplayChat(getDisplayChat(conversationItem));
    }, [])

    return (
        <ListItemButton
            aria-label={displayChat.name}
            onClick={handleclick}
            disabled={disabled}
            sx={{
                ...(hasUnreadMessages ? { pl: 0 } : { pl: 2 }),
                ...(currentChat.id === conversationItem.id? styles.bgColorForSelectedConversation: {}),
            }}
        >
            {hasUnreadMessages && <ActivityBadge />}
            <ListItemIcon aria-hidden>
                <Avatar aria-hidden sx={{ ...styles.sideBarListItem, bgcolor: `${getConsistentColorFromString(displayChat.name)}` }} variant="rounded">
                    {stringAvatar(displayChat.name).children}
                </Avatar>
            </ListItemIcon>
            <ListItemText aria-hidden primary={<Typography sx={styles.ListItemButtonTypography}>{displayChat.name}</Typography>} />
            <IconButton  onClick={handlePopOver} aria-label={strings.CONVERSATION_OPTIONS}>
                <MoreHorizIcon aria-hidden fontSize="small"  data-testid="ellipsis" />
            </IconButton>
            <PopoverModal
                aria-hidden
                type={type}
                conversationId={id}
                customerid={customerid}
                data-popover={popover}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopOverClose}
                chatUser={chatUser}
                currentChat={currentChat}
            />
        </ListItemButton>
    );
}

export default DirectMessageListItem;
