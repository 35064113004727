/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Divider, Typography, IconButton, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { utils, actions } from 'comm-recipientapp-shared';
import useAttachment from './hooks/useAttachment';
import EditAbsence from './EditAbsence';
import AttachmentPreviewPopup from './AttachmentPreview';
import useAttendanceService from './hooks/useAttendanceService';
import DeleteAbsence from './DeleteAbsence';
import Button from '../../../../Shared/Button';
import styles from '../styles';

const { formatEventHour } = utils.attendanceUtils;
const { setCalendarPopupMode } = actions.absence;
const { AbsencePopupStates } = utils.constants;

/**
 * @typedef {object} Absence
 * @property {{
 *     eventStartDate: { date: string; inTime?: string; outTime?: string };
 *     start: { date: string; inTime?: string; outTime?: string };
 *     end: { date: string; inTime?: string; outTime?: string };
 * }} date
 * @property {string} reason
 * @property {string} reasonCode
 * @property {string} typeOfAbsence
 * @property {string} absenceType
 * @property {number} id
 * @property {{ isDeletable: boolean; isEditable: boolean; isUnexplained: boolean }} status
 * @property {string | null} comment
 * @property {{
 *     handle: string;
 *     url: string;
 *     filename: string;
 *     mimetype: string;
 *     size: number;
 * }} attachment
 * @property {{}} student
 * @property {string} assignedColor
 */

/**
 * @typedef {object} AbsencePopupProps
 * @property {Absence} popupData
 * @property {() => void} handleLocalAbsenceUpdate
 * @property {() => void} setIsAttachmentPreviewOpen
 * @property {boolean} isAttachmentPreviewOpen
 * @property {() => void} handleClose
 * @param {React.PropsWithChildren<AbsencePopupProps>} props
 */
export default function AbsencePopup(props) {
    const { handleClose, handleLocalAbsenceUpdate, isAttachmentPreviewOpen, popupData, setIsAttachmentPreviewOpen } = props;

    const { stringAvatar } = utils.avatar;

    const { language } = useSelector(state => state.user);

    const [isFormReadyToBeSent, setIsFormReadyToBeSent] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState(null);

    const { mode } = useSelector(state => state.absence.calendarPopup);

    const formControls = useRef();

    const dispatch = useDispatch();

    const matches = useMediaQuery('(max-width: 600px)');

    const {
        ABSENCE_CONNECTOR_ESTIMATED_ARRIVAL_TIME,
        ABSENCE_CONNECTOR_FROM,
        ABSENCE_CONNECTOR_LEAVING_SCHOOL,
        ABSENCE_CONNECTOR_TO,
        ABSENCE_POPUP_ATTACHMENTS,
        ABSENCE_POPUP_COMMENTS,
        ABSENCE_POPUP_DELETE_BUTTON,
        ABSENCE_POPUP_EDIT_BUTTON,
        ABSENCE_POPUP_REASON,
        ABSENCE_POPUP_TYPE_OF_ABSENCE,
        ABSENCE_POPUP_WHEN,
        ABSENCE_POPUP_NOT_EDITABLE_ABSENCE,
        ABSENCE_POPUP_SAVE_BUTTON,
        ABSENCE_POPUP_CANCEL_BUTTON,
    } = utils.useStrings();

    const studentInitial = useMemo(
        () => stringAvatar(`${popupData.student?.firstName || ' '} ${popupData.student?.lastName || ' '}`).children,
        [popupData]
    );

    const hourConnector = useMemo(() => {
        const absenceTypesConnectorMap = {
            earlyDeparture: ABSENCE_CONNECTOR_LEAVING_SCHOOL,
            late: ABSENCE_CONNECTOR_ESTIMATED_ARRIVAL_TIME,
            partialDay: ABSENCE_CONNECTOR_FROM,
        };

        return absenceTypesConnectorMap[popupData.absenceType];
    }, [ABSENCE_CONNECTOR_ESTIMATED_ARRIVAL_TIME, ABSENCE_CONNECTOR_FROM, ABSENCE_CONNECTOR_LEAVING_SCHOOL, popupData.absenceType]);

    const { updateAbsence, deleteAbsence } = useAttendanceService();

    const handleUpdateAbsence = async data => {
        const { id, ...rest } = data;

        updateAbsence(popupData.student.customerId, data.id, rest, language)
            .then(() => {
                handleLocalAbsenceUpdate();
                dispatch(setCalendarPopupMode(AbsencePopupStates.DEFAULT));
                handleClose();
            })
            .catch(err => {
                const errorResponse = err.response?.data[0];

                if (errorResponse.message) {
                    formControls.current.handleEditError(errorResponse.message);
                }
            });
    };

    const handleDeleteAbsence = async () => {
        deleteAbsence(popupData.student.customerId, popupData.id)
            .then(() => {
                handleLocalAbsenceUpdate();
                dispatch(setCalendarPopupMode(AbsencePopupStates.DEFAULT));
                handleClose();
            })
            .catch(err => {
                const errorResponse = err.response?.data[0];

                if (errorResponse.message) {
                    setDeleteErrorMessage(errorResponse.message);
                }
            });
    };

    const formattedDate = useMemo(() => {
        let initialDateString = '';

        const dateLocalizationTimezone = 'UTC';
        const dateStringFormat = 'long';

        const startDate = new Date(popupData.date?.eventStartDate?.date || popupData.date.start.date);

        const startDayOfTheWeek = startDate.toLocaleString(language, {
            weekday: dateStringFormat,
            timeZone: dateLocalizationTimezone,
        });
        const startDayOfTheMonth = startDate.getUTCDate();
        const startMonth = startDate
            .toLocaleString(language, {
                month: dateStringFormat,
                timeZone: dateLocalizationTimezone,
            })
            .slice(0, 3);

        initialDateString += `${startDayOfTheWeek}, ${startMonth} ${startDayOfTheMonth}`;

        if (popupData.date.end) {
            const endDate = new Date(popupData.date.end.date);

            const endDayOfTheWeek = endDate.toLocaleString(language, {
                weekday: dateStringFormat,
                timeZone: dateLocalizationTimezone,
            });
            const endDayOfTheMonth = endDate.getUTCDate();
            const endMonth = endDate
                .toLocaleString(language, {
                    month: dateStringFormat,
                    timeZone: dateLocalizationTimezone,
                })
                .slice(0, 3);

            initialDateString += ` ${ABSENCE_CONNECTOR_TO} ${endDayOfTheWeek}, ${endMonth} ${endDayOfTheMonth}`;
        }

        return initialDateString;
    }, [popupData, language, ABSENCE_CONNECTOR_TO]);

    const formattedHour = useMemo(() => formatEventHour(popupData, language), [popupData, language]);

    const { attachmentSizeInMb, attachmentUrl, AttachmentIcon, downloadAttachment, AttachmentType } = useAttachment(popupData.attachment);

    const handleFormReadyToBeSent = useCallback(isReady => {
        setIsFormReadyToBeSent(!isReady);
    }, []);

    const advanceButtonText = useMemo(
        () => ({
            [AbsencePopupStates.EDIT]: ABSENCE_POPUP_SAVE_BUTTON,
            [AbsencePopupStates.DEFAULT]: ABSENCE_POPUP_EDIT_BUTTON,
        }),
        []
    );

    const popupState = mode;

    return (
        <Box>
            {popupState === AbsencePopupStates.DEFAULT && (
                <>
                    {isAttachmentPreviewOpen && (
                        <AttachmentPreviewPopup
                            AttachmentIcon={AttachmentIcon}
                            attachmentMimeType={popupData.attachment.mimetype}
                            attachmentName={popupData.attachment.filename}
                            attachmentSize={attachmentSizeInMb}
                            attachmentUrl={attachmentUrl}
                            downloadAttachment={downloadAttachment}
                            handleClose={setIsAttachmentPreviewOpen}
                            isOpen={isAttachmentPreviewOpen}
                        />
                    )}

                    {!popupData.status.isEditable && (
                        <Box sx={styles.popup_not_editable_absence_text_container}>
                            <Box />
                            <Typography
                                sx={{
                                    ...styles.popup_not_editable_absence_text,
                                    ...{ fontSize: matches ? '12px' : '16px' },
                                }}
                            >
                                {ABSENCE_POPUP_NOT_EDITABLE_ABSENCE}
                            </Typography>
                            <Box sx={styles.not_editable_close_btn_contanier}>
                                <IconButton onClick={handleClose}>
                                    <CloseIcon sx={styles.not_editable_close_icon} />
                                </IconButton>
                            </Box>
                        </Box>
                    )}
                    <Box sx={styles.student_detail_container}>
                        <Box sx={styles.student_names_container}>
                            <Box
                                sx={{
                                    ...styles.student_initial,
                                    backgroundColor: popupData.assignedColor,
                                }}
                            >
                                {studentInitial}
                            </Box>
                            <Box>
                                <Typography sx={styles.student_names}>
                                    {popupData.student.firstName} {popupData.student.lastName}
                                </Typography>
                                <Typography sx={styles.student_org_name}>{popupData.student.organizationName}</Typography>
                            </Box>
                        </Box>
                        {popupData.status.isEditable && (
                            <Box sx={styles.student_detail_close_btn}>
                                <IconButton onClick={handleClose}>
                                    <CloseIcon sx={styles.popup_close_button} />
                                </IconButton>
                            </Box>
                        )}
                    </Box>
                    <Box style={styles.divider_container}>
                        <Divider variant="middle" />
                    </Box>
                    <Box sx={styles.pop_up_data_container}>
                        <Box sx={styles.popup_text_container}>
                            <Typography sx={styles.popup_text}>{ABSENCE_POPUP_WHEN}:</Typography>
                            <Typography sx={styles.popup_text_description}>
                                {formattedDate} {formattedHour.length > 1 ? `${hourConnector} ${formattedHour}` : ''}
                            </Typography>
                        </Box>
                        <Box sx={styles.popup_text_container}>
                            <Typography sx={styles.popup_text}>{ABSENCE_POPUP_TYPE_OF_ABSENCE}:</Typography>
                            <Typography sx={styles.popup_text_description}>{popupData.typeOfAbsence}</Typography>
                        </Box>
                        <Box sx={styles.popup_text_container}>
                            <Typography sx={styles.popup_text}>{ABSENCE_POPUP_REASON}:</Typography>
                            <Typography sx={styles.popup_text_description}>{popupData.reason}</Typography>
                        </Box>
                        {popupData.comment && (
                            <Box sx={styles.popup_text_container}>
                                <Typography sx={styles.popup_text}>{ABSENCE_POPUP_COMMENTS}:</Typography>
                                <Typography sx={styles.popup_text_description}>{popupData.comment}</Typography>
                            </Box>
                        )}
                        {popupData.attachment && (
                            <Box sx={styles.popup_text_container}>
                                <Typography sx={styles.popup_text}>{ABSENCE_POPUP_ATTACHMENTS}:</Typography>
                                <Box sx={styles.attachment_container}>
                                    {AttachmentType.startsWith('image/') ? (
                                        <img
                                            src={attachmentUrl}
                                            alt="attachment_file_preview"
                                            style={styles.edit_popup_attachments_details_preview_img}
                                            onClick={() => setIsAttachmentPreviewOpen()}
                                        />
                                    ) : (
                                        <Box onClick={() => setIsAttachmentPreviewOpen()}>
                                            <AttachmentIcon
                                                height={styles.popup_attachment_no_img.height}
                                                width={styles.popup_attachment_no_img.width}
                                                fillColor={styles.popup_attachment_no_img.color}
                                            />
                                        </Box>
                                    )}

                                    <Box sx={styles.edit_popup_attachments_metadata_container}>
                                        <Typography sx={{ ...styles.popup_text, ...styles.attachment_name }}>
                                            {popupData.attachment.filename}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                ...styles.popup_text_description,
                                                ...styles.attachment_size,
                                            }}
                                        >
                                            ({attachmentSizeInMb} MB)
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </>
            )}

            {popupState === AbsencePopupStates.EDIT && (
                <EditAbsence
                    popupData={popupData}
                    editAbsenceFormRef={formControls}
                    handleFormReadyToBeSent={handleFormReadyToBeSent}
                    handleClose={handleClose}
                />
            )}

            {popupState === AbsencePopupStates.DELETE && (
                <DeleteAbsence
                    studentFirstName={popupData.student.firstName}
                    handleDeleteAbsence={handleDeleteAbsence}
                    errorMessage={deleteErrorMessage}
                    handleClose={handleClose}
                />
            )}
            <Box sx={styles.buttons_container}>
                {popupData.status.isDeletable && !(popupState === AbsencePopupStates.EDIT) && (
                    <Button
                        focusRipple={false}
                        sx={popupState === AbsencePopupStates.DELETE ? styles.delete_absence_cancel : {}}
                        variant="contained"
                        onClick={() => {
                            if (popupState === AbsencePopupStates.EDIT || popupState === AbsencePopupStates.DELETE) {
                                handleClose();
                                dispatch(setCalendarPopupMode(AbsencePopupStates.DEFAULT));
                            }

                            if (popupState === AbsencePopupStates.DEFAULT) {
                                dispatch(setCalendarPopupMode(AbsencePopupStates.DELETE));
                            }
                        }}
                    >
                        {popupState === AbsencePopupStates.EDIT || popupState === AbsencePopupStates.DELETE ? ABSENCE_POPUP_CANCEL_BUTTON : ABSENCE_POPUP_DELETE_BUTTON}
                    </Button>
                )}

                {popupData.status.isEditable && (
                    <>
                        {popupState === AbsencePopupStates.EDIT && (
                            <Button
                                onClick={() => {
                                    handleClose();
                                    dispatch(setCalendarPopupMode(AbsencePopupStates.DEFAULT));
                                }}
                            >
                                {ABSENCE_POPUP_CANCEL_BUTTON}
                            </Button>
                        )}

                        <Button
                            focusRipple={false}
                            disabled={popupState === AbsencePopupStates.DEFAULT ? false : isFormReadyToBeSent}
                            variant="contained"
                            onClick={() => {
                                if (popupState === AbsencePopupStates.EDIT) {
                                    handleUpdateAbsence(formControls.current.getFormData());
                                }

                                if (popupState === AbsencePopupStates.DEFAULT) {
                                    dispatch(setCalendarPopupMode(AbsencePopupStates.EDIT));
                                }

                                if (popupState === AbsencePopupStates.DELETE) {
                                    handleDeleteAbsence();
                                }
                            }}
                        >
                            {popupState === AbsencePopupStates.EDIT || popupState === AbsencePopupStates.DEFAULT
                                ? advanceButtonText[popupState]
                                : ABSENCE_POPUP_DELETE_BUTTON}
                        </Button>
                    </>
                )}
            </Box>
        </Box>
    );
}
