
const styles = {
    box_container: { 
        width: '80%' 
    },
    grid_container: {
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
    },
    header: {
        color: '#2C5871',
        fontSize: '24px',
        marginBottom: '15px'
    },
    link: {
        color: '#33366A',
        fontWeight: '500'
    },
    text: {
        color: '#2C5871',
        lineHeight: '150%',
        margin: '20px 0',
        fontWeight: '400',
    }
};

export default styles;
