const styles = {
    container: {
        margin: '40px',
    },
    details_container: {
        whiteSpace: 'pre-wrap',
        marginTop: '20px',
    },
    buttons_container: {
        display: 'flex',
        gap: '30px',
        marginTop: '40px',
    },
};

export default styles;
