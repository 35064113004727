import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { actions } from 'comm-recipientapp-shared';
import FullScreenLoader from '../Utils/FullScreenLoader';
import { ONE_WAY_SMS_SUPPORT } from '../../config/routes/private';

function PrivateRoute() {
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const location = useLocation();

    const navigationPath = location.pathname === ONE_WAY_SMS_SUPPORT ? `/login${location.search}` : '/login';

    useEffect(() => {
        const fetchUser = async () => {
            if (user.account === null) {
                await dispatch(actions.user.getAccount());
            }
        };
        fetchUser();
    }, [user]);

    return <Fragment>{user.loading ? <FullScreenLoader /> : user.account ? <Outlet /> : <Navigate to={navigationPath} />}</Fragment>;
}

export default PrivateRoute;
