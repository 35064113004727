/* eslint-disable operator-linebreak */
import React, { useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import RadioGroup from '@mui/material/RadioGroup';
import PhoneIcon from '@mui/icons-material/Phone';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import MailIcon from '@mui/icons-material/Mail';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import WarningIcon from '@mui/icons-material/Warning';
import { utils } from 'comm-recipientapp-shared';
import { handleEnterKeyPress } from 'comm-recipientapp-shared/src/utils/helpers';
import { PENDING } from 'comm-recipientapp-shared/src/utils/constants';
import ModalError from './modalError';
import theme from '../../../../config/style/muiTheme';
import Button from '../../../Shared/Button';
import styles from './styles';

function MessageModalEdit({ activeSchool, preferences, handleClose, ...props }) {
    const strings = utils.useStrings();
    const [showModalError, setShowModalError] = React.useState(false);
    // eslint-disable-next-line max-len
    const phones = useMemo(() => {
        return preferences?.phones?.filter(phone => phone?.registry?.active) || [];
    }, [preferences]);

    const smses = preferences?.smses || [];
    const emails = preferences?.emails || [];

    const handleOnPhoneClick = (phoneChecked, labelStatus) => {
        if (!phoneChecked && props?.notification?.name !== utils.constants.NotificationPreferences.EMERGENCY && labelStatus === strings.DO_NOT_CALL) {
            setShowModalError(true);
        }
    };

    return (
        <>
            <Dialog open={props.open} onClose={handleClose} sx={styles.dialog} fullWidth maxWidth="sm">
                <Box sx={styles.dialog_header}>
                    <Typography variant="h5" sx={styles.dialog_title}>
                        {props.notification?.name}
                    </Typography>
                    <Typography variant="h6" sx={styles.dialog_subtitle}>
                        {props.notification?.info}
                    </Typography>
                </Box>
                <Box sx={styles.dialog_content}>
                    {props.errorType === utils.constants.ERROR_AT_LEAST_ONE && (
                        <Box sx={styles.error}>
                            <WarningIcon fontSize="small" sx={styles.error_icon} />
                            <Typography
                                sx={styles.error_value}
                            >{`${strings.AT_LEAST_ONE_PHONE_IS_REQUIRED_FOR} ${props.notification?.name} ${strings.NOTIFICATIONS}`}</Typography>
                        </Box>
                    )}
                    <Box sx={styles.content_item}>
                        <Box sx={styles.flex}>
                            <Box sx={styles.icon_circle}>
                                <PhoneIcon sx={styles.icon_color} />
                            </Box>
                            <Typography sx={styles.content_item_name}>{strings.PHONE_CALL}</Typography>
                        </Box>
                        <Box sx={styles.content_radios}>
                            <RadioGroup name="phone-calls-group">
                                {phones.map((phone, index) => {
                                    // eslint-disable-next-line max-len
                                    const phoneChecked = phone?.notificationTypes?.includes(props.notification?.id);
                                    const currentPhone = preferences?.phones?.find(val => val.phone === phone.phone);
                                    const phoneHaveEmergency = phone?.notificationTypes?.includes(utils.constants.EMERGENCY_KEY);
                                    const phoneStatus = utils.helpers.getPhoneStatusEmergency(phoneHaveEmergency, activeSchool, currentPhone);
                                    const labelStatus = utils.helpers.getConsentStatusLabel(phone, phoneStatus, strings);
                                    const formattedPhone = utils.helpers.formatPhoneNumber(phone.phone);

                                    return (
                                        <Box sx={styles.phoneListItem} key={phone.phone}>
                                            {labelStatus !== strings.DO_NOT_CALL && (
                                                <FormControlLabel
                                                    checked={phoneChecked}
                                                    control={<Checkbox />}
                                                    onChange={() => props.handleUpdatePhone(phone.phone)}
                                                    onClick={() => handleOnPhoneClick(phoneChecked, labelStatus)}
                                                    onKeyDown={handleEnterKeyPress(() => {
                                                        handleOnPhoneClick(phoneChecked, labelStatus);
                                                        props.handleUpdatePhone(phone.phone);
                                                    })}
                                                    label={
                                                        <Typography sx={{ color: phone.registry.consent === PENDING && theme.palette.error.dark }}>
                                                            {formattedPhone}
                                                        </Typography>
                                                    }
                                                    data-testid={`phones_call_${index}`}
                                                />
                                            )}
                                            {labelStatus === strings.DO_NOT_CALL && (
                                                <Box sx={styles.doNotCallPhoneContainer} data-testid="do-not-call-phone-list-item">
                                                    <DoNotDisturbAltIcon color="disabled" sx={styles.doNotDisturbIcon} />
                                                    <Typography sx={styles.formattedPhone}>{formattedPhone}</Typography>
                                                </Box>
                                            )}
                                            <Typography sx={styles.phone_status}>{labelStatus}</Typography>
                                        </Box>
                                    );
                                })}
                            </RadioGroup>
                        </Box>
                    </Box>
                    {props.notification?.type !== utils.constants.SURVEY && (
                        <Box sx={styles.content_item}>
                            <Box sx={styles.flex}>
                                <Box sx={styles.icon_circle}>
                                    <ChatBubbleIcon sx={styles.icon_color} />
                                </Box>
                                <Typography sx={styles.content_item_name}>{strings.TEXT_MESSAGE}</Typography>
                            </Box>
                            <Box sx={styles.content_radios}>
                                <RadioGroup name="sms-group">
                                    {smses.map((sms, i) => {
                                        const smsChecked = sms?.notificationTypes?.includes(props.notification?.id);
                                        return (
                                            <FormControlLabel
                                                key={i}
                                                checked={smsChecked}
                                                control={<Checkbox />}
                                                label={utils.helpers.formatPhoneNumber(sms.sms)}
                                                onClick={() => props.handleUpdateSMS(sms.sms)}
                                                onKeyDown={handleEnterKeyPress(() => props.handleUpdateSMS(sms.sms))}
                                                data-testid={`sms_call_${i}`}
                                            />
                                        );
                                    })}
                                </RadioGroup>
                            </Box>
                        </Box>
                    )}
                    <Box sx={styles.content_item}>
                        <Box sx={styles.flex}>
                            <Box sx={styles.icon_circle}>
                                <MailIcon sx={styles.icon_color} />
                            </Box>
                            <Typography sx={styles.content_item_name}>{strings.EMAIL}</Typography>
                        </Box>
                        <Box sx={styles.content_radios}>
                            <RadioGroup name="emails-group">
                                {emails.map((email, i) => {
                                    const emailChecked = email?.notificationTypes?.includes(props.notification?.id);
                                    return (
                                        <FormControlLabel
                                            key={i}
                                            checked={emailChecked}
                                            control={<Checkbox />}
                                            label={email.email}
                                            onClick={() => props.handleUpdateEmail(email.email)}
                                            onKeyDown={handleEnterKeyPress(() => props.handleUpdateEmail(email.email))}
                                            data-testid={`email_call_${i}`}
                                        />
                                    );
                                })}
                            </RadioGroup>
                        </Box>
                    </Box>
                    <Box sx={styles.actions_button}>
                        <Button variant="outlined" sx={styles.cancel_btn} onClick={handleClose}>
                            {strings.CANCEL}
                        </Button>
                        <Button variant="contained" onClick={props.handleSavePreferences}>
                            {strings.SAVE}
                        </Button>
                    </Box>
                </Box>
            </Dialog>
            <ModalError visible={showModalError} handleClose={() => setShowModalError(false)} />
        </>
    );
}

export default MessageModalEdit;
