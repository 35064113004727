import { React, useMemo, useEffect, useState } from 'react';
import { useSearchParams, useNavigate, Link as RouterLink } from 'react-router-dom';
import { Box, Grid, Link, Typography, useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import { utils, env } from 'comm-recipientapp-shared';
import { resendPowerschoolEmail } from 'comm-recipientapp-shared/src/store/auth/authSlice';
import ConfirmedEmail from './ConfirmedEmail';
import SmLogoLinks from '../SmLogoLinks';
import NewEmailOrNewUser from './NewEmailOrNewUser';
import UnconfirmedEmail from './UnconfirmedEmail';
import signUpIllustra from 'comm-recipientapp-shared/src/assets/signupillustra.svg';
import Layout from '../Layout';
import Title from '../Shared/SecondaryTitle';
import styles from './styles';

function Powerschool() {
    const matches = useMediaQuery('(max-width:900px)');
    const strings = utils.useStrings();
    const copyRightText = useMemo(() => '\u00A9 ' + new Date().getFullYear() + ' ' + strings.COPYRIGHT_NAME, []);

    let [params, setParams] = useSearchParams();

    const action = params.get('landingpage');
    const accessToken = params.get('access_token');

    const unconfirmedEmail = 'powerschool/unconfirmedemail';
    const confirmedEmail = 'powerschool/confirmedemail';
    const newUser = 'powerschool/newuser';
    const newEmail = 'powerschool/newemail';

    const [open, setOpen] = useState(false);
    const [messageDisplay, setMessageDisplay] = useState(null);
    const [initialized, setInitialized] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const navigate = useNavigate();

    const user = useSelector(state => state.user);
    const { isPowerschool } = user;

    useEffect(() => {
        if (isPowerschool && ! initialized) {
            setInitialized(true);
            if (accessToken) {
                handleAction(action);
            } else {
                navigate('/');
            }
        }
    }, [isPowerschool, initialized]);
    
    const handleAction = async action => {
        switch (action) {
            case unconfirmedEmail:
                () => resendPowerschoolEmail(user.account.portalUserId);
                handleOpen();
                break;

            case newUser:
                () => resendPowerschoolEmail(user.account.portalUserId);
                setMessageDisplay('newUser');
                break;

            case newEmail:
                () => resendPowerschoolEmail(user.account.portalUserId);
                setMessageDisplay('newEmail');
                break;

            case confirmedEmail:
                navigate('/inbox');
                break;

            default:
                navigate('/inbox');
                break;
        }
    };

    return (
        <Layout>
            <UnconfirmedEmail open={open} handleClose={handleClose} user={user} />
            <Box
                sx={{
                    ...styles.container,
                    ...{
                        paddingLeft: matches ? '10px' : '190px',
                        paddingRight: matches ? '10px' : '190px',
                    },
                }}
            >
                <Title
                    sx={{
                        ...{
                            fontSize: matches ? '20px' : '40px',
                            textAlign: matches ? 'center' : 'left',
                            marginLeft: matches ? '0px' : '50px',
                        },
                        ...styles.title,
                    }}
                >
                    {strings.SIGN_UP_TITLE}
                </Title>
                <Grid container sx={styles.grid}>
                    <Grid item lg={6} md={12} sx={styles.illustrationContainer} xs={12}>
                        <Box alt={strings.SIGN_UP_ILLUSTRATION} component="img" src={signUpIllustra} sx={styles.illustration} />
                    </Grid>
                    {messageDisplay === 'confirmedEmail' && <ConfirmedEmail />}
                    {messageDisplay === 'newEmail' && <NewEmailOrNewUser userEmail={user.email} messageDisplay={messageDisplay} />}
                    {messageDisplay === 'newUser' && <NewEmailOrNewUser userEmail={user.email} messageDisplay={messageDisplay} />}
                    {!messageDisplay && <SmLogoLinks />}
                </Grid>
                <Typography sx={styles.copy} variant="body2">
                    {copyRightText}. {strings.ALL_RIGHTS_RESERVED} | {' '}
                    <Link sx={styles.link} href={env.TERMS_OF_SERVICE_URL} target={'_blank'}>
                        {strings.TERMS_OF_SERVICE}
                    </Link>
                    {' '} | {' '}
                    <Link sx={styles.link} href={env.PRIVACY_POLICY_URL} target={'_blank'}>
                        {strings.PRIVACY_POLICY}
                    </Link>
                </Typography>
            </Box>
        </Layout>
    );
}

export default Powerschool;
