/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Box,
    Accordion,
    AccordionDetails,
} from "@mui/material";

import { utils } from 'comm-recipientapp-shared';
import GroupListItem from './listItems/GroupListItem';
import SectionListItem from './listItems/SectionListItem';
import DirectMessageListItem from './listItems/DirectMessageListItem';
import ListItemSkeleton from './listItems/ListItemSkeleton';
import Emptycomponent from './utils/Emptycomponent';
import GroupAccordionSummary from './GroupAccordionSummary';
import styles from './styles';

function Conversations(props) {
    const strings = utils.useStrings();

    const {
        setShowJoinModal,
        setShowNewDirectMessageModal,
        setShowPanel,
        fetchingMemberOf
    } = props;
    
    const [ activeDirectMessages, setActiveDirectMessages ] = useState([]);
    const [ activeSections, setActiveSections ] = useState([]);

    const [ sectionExpand, setSectionExpand ] = useState(true);
    const [ groupExpand, setGroupExpand ] = useState(true);
    const [ dmExpand, setDmExpand ] = useState(true);

    const {
        discussionGroups,
        directMessages,
        sections,
        currentChatUser,
        rcInitialized,
    } = useSelector(state => state.chat);

    const filterConversationItems = (items) => {
        return items.filter(item => {
            const activeFlagExists = typeof item.active !== 'undefined';
            return (! activeFlagExists) ? true : item.active;
        });
    };

    useEffect(() => {
        if (directMessages.length && currentChatUser) {
            const activeDMs = directMessages.filter((dm) => {
                const {members}  = dm;
                const currentUserMember = members.find(member => member.contact?.id === currentChatUser.personid);
                return (currentUserMember && currentUserMember.active) || false;
            });
            setActiveDirectMessages(activeDMs);
        }
    }, [directMessages, currentChatUser]);

    useEffect(() => {
        if (sections.length) {
            setActiveSections(filterConversationItems(sections));
        }
    }, [sections]);

    const { dependents }  = useSelector(state => state.initialize);
    const hasNewDirectMessageIcon = dependents.some(dependent => dependent.dependent?.hasParentsInitiatedChat);
    const listItemsDisabled = ! rcInitialized;

    const newDirectMessageIcon = {
        name: strings.NEW_DIRECT_MESSAGE,
        iconLabel: strings.ARIA_LABEL_START_DIRECT_MESSAGE_BUTTON,
        accordionLabel: "Direct Messages: heading and toggle",
        clickHandler: event => {
            event.stopPropagation();
            setShowNewDirectMessageModal(true);
        },
    };

    const joinGroupIcon = {
        name: strings.JOIN_GROUP,
        iconLabel: strings.ARIA_LABEL_JOIN_GROUP_BUTTON,
        accordionLabel: "Groups: heading and toggle",
        clickHandler: event => {
            event.stopPropagation();
            setShowJoinModal(true);
        },
    };

    const getSkeletonListItems = () => {
        return (
            <>
                <ListItemSkeleton />
                <ListItemSkeleton />
                <ListItemSkeleton />
            </>
        );
    };

    const toggleSectionAccordion = () => {
        setSectionExpand((prev) => !prev);
    }
    const toggleGroupAccordion = () => {
        setGroupExpand((prev) => !prev);
    }
    const toggleDmAccordion = () => {
        setDmExpand((prev) => !prev);
    }

    return (
        <Box>
            <Accordion
                aria-label={strings.CHAT_ARIA_LABEL_SECTIONS_ACCORDIAN}
                defaultExpanded
                disableGutters
                elevation={0}
                expanded={sectionExpand}
                sx={styles.chatAccordion}
            >
                <Box>
                    <GroupAccordionSummary 
                        aria-label={strings.CHAT_ARIA_LABEL_CLICK_TO_TOGGLE_DISPLAYED_SECTION_CONVERSATIONS}
                        title={strings.SECTIONS}
                        messagePresent={activeSections.length > 0}
                        toggleAccordion={toggleSectionAccordion}
                    />
                </Box>

                <AccordionDetails
                    aria-label={strings.CHAT_ARIA_LABEL_SECTION_CONVERSATIONS_LIST}
                    sx={styles.chatAccordionDetails}>
                    {fetchingMemberOf && getSkeletonListItems()}
                    {(!fetchingMemberOf && activeSections.length > 0) && activeSections.map(
                        (sectionItem) => (
                            <SectionListItem
                                key={sectionItem.id}
                                conversationItem={sectionItem}
                                setShowPanel={setShowPanel}
                                disabled={listItemsDisabled}
                                chatUser={currentChatUser}
                            />
                        )
                    )}
                    {(! fetchingMemberOf && ! activeSections.length) && (
                        <Emptycomponent message={strings.NO_CONVERSATIONS} />
                    )}
                </AccordionDetails>
            </Accordion>

            <Accordion
                aria-label={strings.CHAT_ARIA_LABEL_GROUP_CONVERSATIONS_ACCORDIAN}
                defaultExpanded
                disableGutters
                elevation={0}
                expanded={groupExpand}
                sx={styles.chatAccordion}
            >
                <Box>
                    <GroupAccordionSummary
                        title={strings.GROUPS}
                        modalIcon={joinGroupIcon}
                        messagePresent={discussionGroups.length > 0}
                        toggleAccordion={toggleGroupAccordion}
                    />
                </Box>

                <AccordionDetails sx={styles.chatAccordionDetails}>
                    {fetchingMemberOf && getSkeletonListItems()}
                    {(! fetchingMemberOf && discussionGroups.length > 0) && discussionGroups.map(
                        (discussionGroupItem) => (
                            <GroupListItem
                                key={discussionGroupItem.id}
                                conversationItem={discussionGroupItem}
                                personId={currentChatUser.personid}
                                disabled={listItemsDisabled}
                                setShowPanel={setShowPanel}
                                chatUser={currentChatUser}
                            />
                        )
                    )}
                    {(! fetchingMemberOf && ! discussionGroups.length) && (
                        <Emptycomponent message={strings.NO_CONVERSATIONS} />
                    )}
                </AccordionDetails>
            </Accordion>
            <Accordion
                aria-label={strings.CHAT_ARIA_LABEL_DIRECT_MESSAGE_CONVERSATIONS_ACCORDIAN}
                defaultExpanded
                disableGutters
                elevation={0}
                expanded={dmExpand}
                sx={styles.chatAccordion}
            >
                <Box>
                    <GroupAccordionSummary
                        title={strings.DIRECT_MESSAGES}
                        modalIcon={hasNewDirectMessageIcon && newDirectMessageIcon}
                        messagePresent={activeDirectMessages.length > 0}
                        toggleAccordion={toggleDmAccordion}
                    />
                </Box>

                <AccordionDetails sx={styles.chatAccordionDetails}>
                    {fetchingMemberOf && getSkeletonListItems()}
                    {(!fetchingMemberOf && activeDirectMessages.length > 0) && activeDirectMessages.map(
                        (directMessage) => (
                            <DirectMessageListItem
                                key={directMessage.id}
                                conversationItem={directMessage}
                                setShowPanel={setShowPanel}
                                chatUser={currentChatUser}
                                disabled={listItemsDisabled}
                            />
                        )
                    )}
                    {(! fetchingMemberOf && ! activeDirectMessages.length) && (
                        <Emptycomponent message={strings.NO_CONVERSATIONS} />
                    )}
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}

export default Conversations;

