const centerVertically = {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column',
};

const styles = {
    mainHead: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        marginTop: 5,
        marginBottom: 5,
        boxShadow: '0px 2px 3px #E5EFFA',
    },
    mainTitle: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        fontFamily: 'Hornbill',
        fontSize: {
            xs: '25px',
            sm: '40px',
            md: '40px',
            lg: '40px'
        },
        color: '#33366A',
        textAlign: 'center',
    },
    mainTabs: {
        alignSelf: 'center',
        width: '100%',
        marginTop: 2,
    },
    default_tabStyle:{
        fontFamily: 'Inter',
        fontSize: '18px',
        color: '#33366A',
        fontWeight: 500,
        marginRight: '2px',
        backgroundColor: '#ededed',
        borderRadius: '20px 20px 0px 0px',
    },
    active_tabStyle:{
        fontFamily: 'Inter',
        fontSize: '18px',
        color: '#33366A',
        fontWeight: 700,
        marginRight: '2px',
        backgroundColor: '#AED6F1', 
        borderRadius: '20px 20px 0px 0px',
    },
    sender: {
        fontWeight: '500',
        fontSize: '15px',
        lineHeight: '150%',
        color: '#33366A',
        overflow: 'hidden',
        whiteSpace: 'wrap',
        textOverflow: 'ellipsis',
    },
    fromInfo: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'row',
        gap: '8px',
        marginTop: 1,
    },
    chip_and_sender_container: {
        display: 'flex',
        gap: '5px',
        alignItems: 'center',
    },
    sender_text_container: {
        marginTop: '5px',
        display: 'flex',
        gap: '5px',
        alignItems: 'center',
    },
    initials_chip_container: {
        marginTop: '10px',
    },
    initials_box: {
        ...centerVertically,
        width: '32px',
        height: '32px',
        borderRadius: '8px',
        textAlign: 'center',
        backgroundColor: '#33366A',
        marginLeft: '3px',
        marginRight: '3px',
    },
    initials: {
        fontWeight: '700',
        fontSize: '16px',
        lineHeight: '120%',
        color: 'white',
    },
    chip: {
        fontSize: '15px',
        fontWeight: '500',
        lineHeight: '150%',
        color: '#33366A',
        borderRadius: '4px',
        backgroundColor: '#EFF0F4',
    },
    chip_emergency: {
        fontSize: '15px',
        fontWeight: '500',
        lineHeight: '150%',
        color: '#FFFFFF',
        borderRadius: '4px',
        backgroundColor: '#D12222',
    },
    normalFont: {
        fontSize: '15px',
        display: {
            xs: 'flex',
            sm: 'flex',
            md: 'flex',
            lg: 'flex'
        },
    },
    chip_icon: {
        transform: 'scale(1.1)',
        marginLeft: '7px',
    },
    chip_icon_dimension: {
        width: '14px',
        height: '14px',
    },
    chip_name: {
        transform: 'scale(1.0)',
        marginRight: '3px',
        marginBottom: '4px',
    },
};

export default styles;
