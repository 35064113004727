import HTTP from '../HTTP';

export const getCustomerInfo = (customerId) => {
    return HTTP.get(`unsubscribe/customers/${customerId}`);
};

export const getEmailPreferences = (customerId, email) => {
    return HTTP.get(`unsubscribe/customers/${customerId}/emails/${email}/preferences`);
};

export const updateEmailPreferences = (customerId, email, preferences) => {
    return HTTP.put(`unsubscribe/customers/${customerId}/emails/${email}/preferences`, preferences);
};

export const blockEmail = (customerId, email) => {
    return HTTP.post(`unsubscribe/customers/${customerId}/emails/${email}/block`);
};

export const getEmailBlocked = (customerId, email) => {
    return HTTP.get(`unsubscribe/customers/${customerId}/emails/${email}/blocked`);
};
