import React, { useState } from 'react';
import { utils } from 'comm-recipientapp-shared';
import { palette } from 'comm-recipientapp-shared';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import styles from './styles';


export default function DeleteDialog({ open, handleClose, handleArchive }) {

    const strings = utils.useStrings();

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="delete-dialog-title"
            aria-describedby="delete-dialog-description"
        >
            <DialogTitle id="delete-dialog-title">
                {strings.DELETE_DIALOG_TITLE}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="delete-dialog-description">
                    {strings.DELETE_DIALOG_DESCRIPTION}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button sx={styles.cancel_button} onClick={handleClose}>{strings.CANCEL}</Button>
                <Button sx={styles.delete_button} onClick={handleArchive} autoFocus>
                    {strings.DELETE}
                </Button>
            </DialogActions>
        </Dialog>
    )
}


