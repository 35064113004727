export const isEmailFormatValid = email => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);

export const isFieldValidLength = (fieldVal, maxLength) => {
    return fieldVal.length <= maxLength;
};

export const onlyNumbers = /^[0-9]+$/;
export const alphaOnly = /^[a-zA-Z0-9]+$/;
export const atLeastOneLowerCaseLetterRegex = /[a-z]/;
export const atLeastOneUpperCaseLetterRegex = /[A-Z]/;
export const atLeastOneNumberRegex = /\d/;
export const between6And255LettersRegex = /^.{6,255}$/;
