import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { useStrings } from 'comm-recipientapp-shared/src/utils';
import Absence from './Absence';
import UnexplainedAbsencesPopup from './UnexplainedAbsencesPopup';
import styles from './styles';

export const OPEN_UNEXPLAINED_ABSENCE_POPUP = 'open_unexplained_absence_popup';

/**
 * @typedef {object} UnexplainedAbsence
 * @property {string} reason
 * @property {string} reasonCode
 * @property {string} typeOfAbsence
 * @property {string} absenceType
 * @property {number} id
 * @property {{ isDeletable: boolean; isEditable: boolean; isUnexplained: boolean }} status
 * @property {string | null} comment
 * @property {{
 *     handle: string;
 *     url: string;
 *     filename: string;
 *     mimetype: string;
 *     size: number;
 * } | null} attachment
 * @property {{
 *     eventStartDate: { date: string; inTime?: string; outTime?: string };
 *     start: { date: string; inTime?: string; outTime?: string };
 *     end: { date: string; inTime?: string; outTime?: string };
 * }} date
 * @property {{}} student
 * @property {string} assignedColor
 */

/**
 * @typedef {object} UnexplainedAbsencesProps
 * @property {UnexplainedAbsence[]} absences
 * @param {React.PropsWithChildren<UnexplainedAbsencesProps>} props
 */
export default function UnexplainedAbsences(props = { absences: [] }) {
    const { absences } = props;

    if (props.absences.length === 0) return null;

    const { UNEXPLAINED_ABSENCE_SURFACE_TITLE } = useStrings();

    const [isOpen, setIsOpen] = useState(absences.length > 0);

    const handlePopupClose = () => setIsOpen(false);

    const handlePopupOpen = () => setIsOpen(true);

    useEffect(() => {
        window.addEventListener(OPEN_UNEXPLAINED_ABSENCE_POPUP, () => {
            handlePopupOpen();
        });

        return () => {
            window.removeEventListener(OPEN_UNEXPLAINED_ABSENCE_POPUP, () => {});
        };
    }, []);

    return (
        <>
            <Paper sx={styles.surface}>
                <Box sx={styles.surface_title_container}>
                    <ErrorIcon htmlColor={styles.attention_icon_color.color} />

                    <Typography sx={styles.unexplained_message}>{UNEXPLAINED_ABSENCE_SURFACE_TITLE}</Typography>
                </Box>

                <Box sx={styles.absences_cards_container}>
                    {absences.map((absence, idx) => (
                        <Box sx={{ marginBottom: '10px' }} key={`absence-${idx}`}>
                            <Absence absenceData={absence} onClick={handlePopupOpen} />
                        </Box>
                    ))}
                </Box>
            </Paper>
            {absences.length > 0 && <UnexplainedAbsencesPopup absenceData={absences} isOpen={isOpen} handleClose={handlePopupClose} />}
        </>
    );
}
