
const styles = {
    logoContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginTop: {
            xs: 5,
            sm: 5,
            md: 3,
            lg: 8,
        },
    },
    logoSm: {
        alignSelf: 'center',
        width: {
            xs: "300px",
            sm: "300px",
            md: "420px",
            lg: "420px",
        }
    },
    logoStore: {
        maxWidth: {
            xs: '140px',
            sm: '300px',
            md: '100%',
        },
        height: {
            xs: '40px',
            sm: '40px',
            md: '40px',
            lg: 'auto',
        },
    },
    storeContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: "30px",
        width: '100%',
    },
    linkContainer: {
        marginTop: "10px",
        textAlign: 'center',
    },
    link: {
        cursor: 'pointer',
        margin: "10px",
        textDecoration: 'none',
        fontWeight: '500'
    },
};

export default styles;


