import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { formatPhoneNumber } from 'comm-recipientapp-shared/src/utils/helpers';
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { YES, NO } from 'comm-recipientapp-shared/src/utils/constants';
import { useStrings } from 'comm-recipientapp-shared/src/utils';
import styles from './styles';

const UNSELECTED_VALUE = 'unselected';

/**
 * @typedef {object} ConsentPendingPhoneProps
 * @property {string} phone
 * @property {object} school
 * @property {(newPhoneConsent: { newValue: string; phone: string; school: {} }) => void} [onChange]
 * @param {React.PropsWithChildren<ConsentPendingPhoneProps>} props
 */
export default function ConsentPendingPhone({ phone, onChange, school }) {
    const strings = useStrings();

    const language = useSelector(state => state.user.language);

    const [defaultOptionChanged, setDefaultOptionChanged] = useState(false);

    const [currentOption, setCurrentOption] = useState(UNSELECTED_VALUE);

    const availableOptions = useMemo(() => {
        const consentOptions = [
            { value: YES, text: strings.YES_ITS_OK_TO_CALL_ME_AT_THIS_NUMBER },
            { value: NO, text: strings.NO_CALL_ME_FOR_EMERGENCIES_ONLY },
        ];

        if (defaultOptionChanged) {
            return consentOptions;
        }

        // when the select has received changes, we show first this message
        return [...consentOptions, { value: UNSELECTED_VALUE, text: strings.CONSETN_POPUP_SELECT_DEFAULT_OPTION }];
    }, [defaultOptionChanged, language]);

    const handleChange = e => {
        setDefaultOptionChanged(true);
        setCurrentOption(e.target.value);
        if (onChange) onChange({ newValue: e.target.value, phone, school });
    };

    return (
        <Box sx={styles.field_container}>
            <Typography sx={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{formatPhoneNumber(phone)}</Typography>
            <FormControl fullWidth>
                <InputLabel id="consent-select-label">Consent</InputLabel>
                <Select labelId="consent-select-label" id="consent-select" label="Consent" value={currentOption} onChange={handleChange}>
                    {availableOptions.map(({ value, text }) => {
                        return (
                            <MenuItem key={value} value={value} sx={value === UNSELECTED_VALUE ? { display: 'none' } : {}}>
                                {text}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </Box>
    );
}
