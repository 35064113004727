import React from "react";
import { Chip, Button } from '@mui/material';
import { getPersonBackgroundColor, getPersonName } from "../../utils";
import styles from './styles';

export default function RecipientAvatar({person, selectedPersonId, handleSelectPerson}) {

    const handleClick = () => {
        handleSelectPerson(person);
    };

    const fullName = getPersonName(person);
    const backgroundColor = getPersonBackgroundColor(person);
    const style = (person?.id === selectedPersonId) ? styles.activeChip : styles.chip;

    return (
        <Button 
            data-testid="recipientTestId" 
            sx={styles.button}
            onClick={handleClick} 
            underline='none'>
            <Chip sx={{...style, backgroundColor}} label={fullName} />
        </Button>
    );
}

