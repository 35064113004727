import theme from '../../../config/style/muiTheme';

const styles = {
    alertSpan: {
        fontWeight: 500,
    },
    buttonContent: {
        margin: '0 auto',
        width: '100%',
        textAlign: 'center',
    },
    button: {
        padding: '10px',
    },
    error: {
        background: theme.palette.error.main,
        color: theme.palette.common.white,
        fontFamily: 'Roboto',
        fontWeight: 300,
        marginBottom: '15px',
        textAlign: 'center',
    },
    flagImage: {
        paddingRight: '14px',
        width: '40px',
    },
    forgotContent: {
        bottom: -26,
        position: 'absolute',
        right: 0,
    },
    forgotLink: {
        color: theme.palette.primary.main,
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 500,
        margin: 0,
        paddingTop: '5px',
        textAlign: 'right',
        textDecoration: 'none',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: {
            xs: '80%',
            lg: '70%',
        },
    },
    formGroup: {
        marginBottom: '55px',
        position: 'relative',
    },
    label: {
        color: theme.palette.purple['80'],
        fontFamily: 'Roboto',
        fontSize: '20px',
        fontWeight: 500,
    },
    locationContent: {
        paddingTop: '5px',
    },
    notRegisterContent: {
        paddingTop: '8px',
        textAlign: 'center',
    },
    schoolLocation: {
        color: theme.palette.purple['80'],
        fontFamily: 'Roboto',
        fontWeight: 500,
        paddingRight: '12px',
    },
    title: {
        marginBottom: 4,
        color: theme.palette.purple['80'],
        fontFamily: 'Hornbill',
        fontSize: '40px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '112%',
        letterSpacing: '-1.2px',
    },
};

export default styles;
