import { palette } from 'comm-recipientapp-shared';

const styles = {
    organizations: {
        color: palette.grey['700'],
        fontSize: '12px',
    },
    avatar: {
        marginRight: '15px',
    },
    student: {
        fontWeight: '400',
        fontSize: '18px',
    },
    header_container: {
        display: 'flex',
        alignItems: 'center',
    },
    info_item: {
        margin: '15px 5px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    action: {
        display: 'flex',
        justifyContent: 'center',
    },
    info_item_title: {
        color: palette.grey['900'],
        fontSize: '18px',
        fontWeight: '200',
    },
    info_item_value: {
        color: palette.grey['900'],
        fontWeight: '200',
        fontSize: '13px',
        textAlign: 'right'
    },
    action_button: {
        fontSize: '16px',
    },
    attendance_value: {
        textAlign: 'center',
        margin: '0 5px',
    },
    info_item_abse_tar: {
        display: 'flex'
    }
};

export default styles;
