/* eslint-disable import/no-cycle */
import HTTP, { CHAT_URL } from '../HTTP';

export const fetchUserAccount = date => {
    return HTTP.get(`my?timestamp=${date}`);
};

export const fetchUser = date => {
    return HTTP.get(`oauth/user?timestamp=${date}`);
};

export const forgotPassword = email => {
    return HTTP.post(`portal/users/reset/password`, {
        username: email,
    });
};

export const deleteAccount = () => HTTP.delete(`deleteinformation/account`);

export const addAdditionalEmailAddress = emailAddres => {
    return CHAT_URL.post('portal/users/email', { username: emailAddres });
};

export const confirmAdditionalEmail = (code, username) => CHAT_URL.put('portal/users/email', { code, username });
