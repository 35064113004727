/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable prettier/prettier */
import React from 'react';
import OtpInput from 'react-otp-input';
import { utils } from 'comm-recipientapp-shared';
import Styles, { StyledInput } from './styles';

export default function PinInput({handleOnChangeInputCode, codeValue}) {
    const strings = utils.useStrings();
    return (
        <OtpInput
            value={codeValue}
            onChange={handleOnChangeInputCode}
            numInputs={4}
            containerStyle={Styles.OtpInputContainer}
            inputType='tel'
            renderInput={
                (props,idx) => {
                    const modifiedProps = {...props, style: {}}
                    return(<StyledInput {...modifiedProps } aria-label= {`${strings.PLEASE_FILL_CHARACTER} ${idx+1}`} />)
                }
            }
        />
    );
}
