import { useState, useEffect } from 'react';

const getWidth = () => window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

function useWindowWidth() {
    const [width, setWidth] = useState(getWidth());

    useEffect(() => {
        let timeoutId = null;
        const resizeListener = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => setWidth(getWidth()), 50);
        };
        window.addEventListener('resize', resizeListener);

        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    }, [])

    return width;
}

export default useWindowWidth;
