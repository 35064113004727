import theme from '../../../config/style/muiTheme';

const styles = {
    alertSpan: {
        fontWeight: 500,
        textAlign: 'center',
    },
    alertSpanBold: {
        fontWeight: 600,
        textAlign: 'center',
    },
    errorTitle: {
        fontWeight: 600,
        fontSize: 16,
    },
    error: {
        textAlign: 'center',
        backgroundColor: theme.palette.error.main,
        color: theme.palette.common.white,
        mb: 2,
    },
};

export default styles;
