import theme from '../../../../config/style/muiTheme';

const styles = {
    banner_text_container: {
        justifyContent: 'center',
        backgroundColor: theme.palette.warning.main,
        height: '60px',
        display: 'flex',
        alignItems: 'center',
    },
    banner_text: {
        color: theme.palette.warning.contrastText,
        fontSize: '1.3rem',
        fontWeight: '300',
    },
    description_container: {
        textAlign: 'center',
        marginTop: '30px',
    },
    buttons_container: {
        display: 'flex',
        justifyContent: 'center',
        gap: '15px',
        marginTop: '30px',
        marginBottom: '20px',
    },
    cancel_button: {
        color: theme.palette.primary.main,
        background: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.common.black,
        },
    },
    delete_button: {
        backgroundColor: theme.palette.warning.main,
        border: `1px solid ${theme.palette.warning.main}`,
        color: theme.palette.warning.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.warning.main,
            color: theme.palette.warning.contrastText,
        },
    },
};

export default styles;
