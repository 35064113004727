import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';
import { utils } from 'comm-recipientapp-shared';
import styles from './styles';

function ModalError({ visible, handleClose }) {
    const strings = utils.useStrings();

    return (
        <Dialog maxWidth="lg" open={visible} onClose={handleClose}>
            <DialogTitle sx={styles.dialogHeader}>
                <WarningIcon />
                <Typography sx={styles.dialogTitle}>{strings.WARNING}</Typography>
                <Typography>Te</Typography>
            </DialogTitle>
            <DialogContent style={styles.modalContent}>
                <Typography style={styles.errorLegend}>{strings.AT_LEAST_ONE_PHONE_IS_REQUIRED_FOR_THIS_NOTIFICATION_TYPE}</Typography>
                <Button color="primary" data-testid="button-login" fullWidth sx={styles.button_ok} onClick={handleClose} variant="contained">
                    {strings.OK}
                </Button>
            </DialogContent>
        </Dialog>
    );
}

export default ModalError;
