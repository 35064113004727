import theme from "../../../../config/style/muiTheme";

const styles = {
    dialog: {
        borderRadius: 0
    },
    dialog_title: {
        color: '#fff',
        fontSize: '20px',
        fontWeight: 300
    },
    dialog_subtitle: {
        color: '#fff',
        fontSize: '14px',
        opacity: '0.7',
        fontWeight: 300
    },
    dialog_header: {
       backgroundColor: 'primary.main',
       height: 100,
       display: 'flex',
       alignItems: 'flex-start',
       justifyContent: 'center',
       flexDirection: 'column',
       padding: '0px 20px'
    },
    dialog_content: {
        padding: '0px',
    },
    dialogHeader: {
        background: '#ff7978',
        padding: '25px 25px 15px',
        color: '#fff',
        textAlign: 'center',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    dialogTitle: {
        paddingLeft: '4px',
        fontSize: '1.3rem',
        fontWeight: 300,
    },
    modalContent: {
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    errorLegend: {
        fontWeight: 300,
        margin: '20px 0px 25px',
    },
    button_ok: {
        textTransform: 'uppercase',
        backgroundColor: '#ff7978',
        width: '87px',
        borderRadius: '2px',
        fontWeight: '300',
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: '#ff7978',
        },
    },
    error: {
        background: '#f2dede',
        borderColor: '#ebccd1',
        padding: '10px 15px',
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'row',
    },
    error_icon: {
        color: '#a94442',
        paddingRight: '6px'
    },
    error_value: {
        color: '#a94442',
        fontWeight: 500
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    phoneListItem: {
        display: "inline-flex", 
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '42px',
    },
    doNotCallPhoneContainer: { 
        display: "inline-flex", 
        ml: "-2px", 
        mb: 1, 
        paddingTop: "7px"
    },
    doNotDisturbIcon: { 
        mr: "9px" 
    },
    formattedPhone: { 
        mr: "16px" 
    },
    icon_circle: {
        width: '42px',
        height: '42px',
        border: `1px ${theme.palette.grey['450']} solid`,
        borderRadius: 21,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    icon_color: {
        color: theme.palette.grey['450'],
    },
    content_item: {
        padding: '10px 20px',
        borderBottom: `1px ${theme.palette.grey['450']} solid`,
    },
    content_item_name: {
        paddingLeft: '15px',
    },
    content_radios: {
        width: '79%',
        margin: '0 auto'
    },
    actions_button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '10px',
        marginTop: '20px',
        marginBottom: '20px'
    },
    cancel_btn: {
        background: 'white',
        color: theme.palette.primary.main,
    },
    phone_status: {
        fontSize: '12px',
        color: theme.palette.grey.A700,
    },
};

export default styles;
