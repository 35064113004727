/* eslint-disable no-useless-return */
import { Pagination as PaginationMaterialUI, Box } from '@mui/material';
import React, { useMemo } from 'react';
import styles from './styles';

export default function Pagination(props) {
    const {
        numberOfItems = 0,
        itemsPerPage = 6,
        page = 1,
        handleChange = () => {
            return;
        },
        hideNextButton = false,
        hidePrevButton = false,
        disabled = false,
    } = props;

    const count = useMemo(() => Math.ceil(numberOfItems / itemsPerPage), [numberOfItems, itemsPerPage]);

    return (
        <Box>
            <PaginationMaterialUI
                count={count}
                page={page}
                onChange={handleChange}
                hideNextButton={hideNextButton}
                hidePrevButton={hidePrevButton}
                sx={styles.pagination}
                disabled={disabled}
            />
        </Box>
    );
}
