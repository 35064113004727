const centerVertically = {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column',
};

const alertColor = '#bd3127';

const styles = {
    surface: {
        padding: '1rem',
        marginBottom: '0.5rem',
        marginTop: '1rem',
    },
    surface_title_container: { display: 'flex', gap: '0.5rem', marginBottom: '1rem' },
    absences_cards_container: {
        maxHeight: '300px',
        overflow: 'auto',
    },
    absence_container: {
        backgroundColor: '#FFF7F8',
        '&:hover': {
            backgroundColor: '#FFF2F2',
        },
        cursor: 'pointer',
    },
    absence_data_container: {
        padding: '0.5rem 2rem 0.5rem 2rem',
        display: 'flex',
        justifyContent: 'space-between',
        gap: '2rem',
        width: "100%",
    },
    absence_date_container: {
        ...centerVertically,
        textAlign: 'center',
    },
    absence_date_day: {
        color: alertColor,
        fontSize: '22px',
        fontWeight: '500',
    },
    absence_date_month: {
        color: alertColor,
        fontSize: '12px',
        fontWeight: '100',
    },
    absence_student_initial: {
        display: 'flex',
        justifyContent: 'center',
        width: '25px',
        height: '25px',
        marginRight: '0.5rem',
        borderRadius: '50%',
    },
    absence_student_initial_text: {
        color: 'white',
        marginTop: '1px',
    },
    absence_initial_and_name_container: {
        display: 'flex',
    },
    absence_data_section: { maxWidth: '150px', display: 'flex', justifyContent: 'space-between', width: '100%', gap: '1.5rem' },
    attention_message_container: {
        display: 'flex',
        gap: '0.5rem',
    },
    attention_message: {
        color: alertColor,
    },
    student_name: {
        color: alertColor,
    },
    unexplained_message: {
        color: alertColor,
    },
    attention_icon_color: {
        color: alertColor,
    },
    initial_container: {
        marginTop: '20px',
    },
    initial_and_name_container: {
        ...centerVertically,
    },
    reason_message: {
        ...centerVertically,
    },
    popup_message: {
        textAlign: 'center',
        opacity: 0.7,
    },
    buttons_container: { display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '20px' },
    popup_styles: { borderRadius: '8px', margin: '0px', padding: '0px', width: '90%', scrollBehavior: 'smooth' },
    popup_title: { textAlign: 'center', fontSize: '18px', fontWeight: '500', color: 'white' },
    popup_subtitle: { textAlign: 'center', fontSize: '14px', color: 'white' },
    popup_close_button: {
        position: 'absolute',
        zIndex: 999,
        marginRight: {
            xs: '0px',
            sm: '0px',
            md: '10px',
            lg: '10px',
            xl: '10px',
        },
        marginTop: {
            xs: '-15px',
            sm: '-15px',
        },
    },
    popup_header: {
        backgroundColor: alertColor,
        padding: {
            xs: '20px 10px 20px 10px',
            sm: '20px 10px 20px 10px',
            md: '20px 0px 20px 0px',
            lg: '20px 0px 20px 0px',
            xl: '20px 0px 20px 0px',
        },
        top: 0,
        width: '100%',
        position: 'sticky',
        opacity: 1,
        zIndex: 1,
    },
    popup_absences_container: {
        margin: {
            xs: '0px 10px 10px 10px',
            sm: '0px 15px 15px 15px',
            md: '0px 40px 15px 40px',
            lg: '0px 40px 15px 40px',
            xl: '0px 40px 15px 40px',
        },
    },
    popup_message_container: { marginTop: '30px' },
    popup_cancel_button: {
        '&:hover': {
            backgroundColor: alertColor,
            color: '#fff',
        },
    },
    popup_save_button: {
        backgroundColor: '#EAEFF4',
        color: '#535BE5',
    },
    popup_close_button_container: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginLeft: {
            xs: '20px',
            sm: '20px',
        },
    },
    popup_text_and_buttons_container: { position: 'sticky', backgroundColor: 'white', paddingBottom: '20px', bottom: 0 },
    absence_form_student_initial: {
        borderRadius: '50%',
        height: '2.8rem',
        width: '2.8rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    absence_form_container: { marginTop: '20px' },
    absence_form_data_container: { display: 'flex', alignItems: 'center', gap: '0.7rem' },
    absence_form_student_initial_text: { color: 'white', fontSize: '20px' },
    absence_form_student_name_and_organization_container: { display: 'flex', gap: '6px' },
    absence_form_student_name: { 
        fontSize: '18px', 
        color: 'black',
        opacity: "0.7",
    },
    absence_form_organization_name: { 
        fontSize: '13px', 
        color: 'black', 
        opacity: "0.7",
        textAlign: 'center', 
        marginTop: '5px' 
    },
};

export default styles;
