/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import InformationCard from './InformationCard';
import styles from './styles';

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * @typedef {object} CalendarListProps
 * @property {{
 *     date: {
 *         eventStartDate: { date: string; inTime?: string; outTime?: string };
 *         start: { date: string; inTime?: string; outTime?: string };
 *         end: { date: string; inTime?: string; outTime?: string };
 *     };
 *     reason: string;
 *     reasonCode: string;
 *     student: {};
 *     typeOfAbsence: string;
 *     title: eventTitle;
 *     assignedColor: string;
 *     isMultiDay: boolean;
 *     absenceType: string;
 *     id: string;
 *     attachment?: {
 *         handle: string;
 *         url: string;
 *         filename: string;
 *         mimetype: string;
 *         size: number;
 *     };
 *     comment?: string;
 * }[]} events
 * @property {(popupData) => void} handlePopupOpen
 * @param {React.PropsWithChildren<CalendarListProps>} props
 */
export default function CalendarList({ events, handlePopupOpen }) {
    const { language } = useSelector(state => state.user);

    /** Returns a structured array of months with events, sorted by month eg: ['january', {eventId:1}, {eventId: 2}, 'february', {eventId: 3}] */
    const eventListWithMonths = useMemo(() => {
        const eventsWithMonthsArraySorted = events
            .sort((a, b) => {
                const aDate = new Date(a.date.start.date);
                const bDate = new Date(b.date.start.date);

                return bDate.getTime() - aDate.getTime();
            })
            .reduce((acc, event) => {
                const month = new Date(event.date.start.date)
                    .toLocaleString(language, {
                        month: 'long',
                        timeZone: 'UTC',
                    })
                    .toLowerCase();

                if (acc[month]) {
                    acc[month].push(event);
                } else {
                    acc[month] = [event];
                }

                return acc;
            }, []);

        const finalArray = [];

        Object.keys(eventsWithMonthsArraySorted).forEach(month => {
            finalArray.push(capitalizeFirstLetter(month));

            eventsWithMonthsArraySorted[month].forEach(event => {
                finalArray.push(event);
            });
        });

        return finalArray;
    }, [events, language]);

    return (
        <Box style={styles.main_card_container}>
            {eventListWithMonths.map((event, index) => {
                if (typeof event === 'string') {
                    return (
                        <Typography sx={styles.month} key={`event-month-${event}`}>
                            {event}
                        </Typography>
                    );
                }
                return <InformationCard key={`info-card-${index}`} handlePopupOpen={handlePopupOpen} event={event} />;
            })}
        </Box>
    );
}
