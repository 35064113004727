import React from 'react';
import { Box } from '@mui/material';
import EllipseUnreadMessage from 'comm-recipientapp-shared/src/assets/Ellipse_unread_message.svg';
import { utils } from 'comm-recipientapp-shared';

export default function ActivityBadge() {
    const strings = utils.useStrings();
    
    return (
        <Box 
            aria-label={strings.CHAT_ARIA_LABEL_THIS_CONVERSATION_HAS_UNREAD_MESSAGES}
            alt={strings.ACTIVITY_BADGE} 
            component="img" 
            src={EllipseUnreadMessage} 
            sx={{ mr: 1 }} 
            data-testid="ActivityBadge" 
        />
    );
}
