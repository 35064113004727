import { constants } from '../utils';
import {
    isEmailFormatValid,
    isFieldValidLength,
    atLeastOneLowerCaseLetterRegex,
    atLeastOneUpperCaseLetterRegex,
    atLeastOneNumberRegex,
    between6And255LettersRegex,
} from './common';

export const isForgotPasswordEmailValid = email => isFieldValidLength(email, constants.MAX_EMAIL_LENGTH) && isEmailFormatValid(email);
export const testHasOneLowerCaseLetter = text => atLeastOneLowerCaseLetterRegex.test(text);
export const testHasOneNumber = text => atLeastOneNumberRegex.test(text);
export const testHasOneUpperCaseLetter = text => atLeastOneUpperCaseLetterRegex.test(text);
export const testHasBetween6And255Characters = text => between6And255LettersRegex.test(text);
