// eslint-disable-next-line import/no-cycle
import { CHAT_URL } from '../HTTP';

export const getConversationMemberOf = (customerId, personId, filter = 'all', start = 0, limit = 100) =>
    CHAT_URL.get(
        `conversation/memberof?customerid=${customerId}&personid=${personId}&filter=${filter}&start=${start}&limit=${limit}`
    );

export const getConversationLogin = (customerId, personId) =>
    CHAT_URL.get(`conversation/login?customerid=${customerId}&personid=${personId}`);

export const joinDiscussionGroup = data => {
    return CHAT_URL.post(`group/join`, data);
};

export const getTargets = (customerid, personid) => {
    return CHAT_URL.get(`conversation/targets?customerid=${customerid}&personid=${personid}`);
};

export const createPrivateConversation = data => {
    return CHAT_URL.post(`conversation/createprivate`, data);
};

export const activateConversation = (conversationId, data) => {
    return CHAT_URL.post(`conversation/${conversationId}/activate`, data);
};

export const getConversationsActivity = ({ customerid, personid, start = 0, limit = 100 }) => {
    return CHAT_URL.get(`conversation/activity?customerid=${customerid}&personid=${personid}&start=${start}&limit=${limit}`);
};

export const leaveGroup = ({ conversationId, customerid, personid }) =>
    CHAT_URL.post(`conversation/${conversationId}/leave`, {
        customerid,
        personid,
    });
