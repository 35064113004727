/* eslint-disable prettier/prettier */
import React from 'react';
import {
    ListItemIcon,
    ListItemText,
    Avatar,
    Typography
} from "@mui/material";

import { utils } from 'comm-recipientapp-shared';

import styles from "./styles";

function ContactListItem({contact}){
    const { stringAvatar, getConsistentColorFromString } = utils.avatar;
    return(
        <>
            <ListItemIcon>
                <Avatar 
                    aria-hidden
                    sx ={{...styles.contactListItem, bgcolor:`${getConsistentColorFromString(contact.name)}`}} variant="rounded">
                    {stringAvatar(contact.name).children}
                </Avatar>
            </ListItemIcon>
            <ListItemText
                primary={
                    <Typography sx={styles.ListItemButtonTypography}>
                        {contact.name}
                    </Typography>
                }
                secondary='Staff'
            />
        </>
    )
}

export default ContactListItem;
