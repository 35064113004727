import React from 'react';
import { userAPI } from '../../api';

export default function useUserLogins() {
    const [userLogins, setUserLogins] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [userLoginInErrorState, setUserLoginInErrorState] = React.useState(null);

    React.useEffect(() => {
        const getUserLogins = async () => {
            const date = new Date().getTime();
            try {
                const response = await userAPI.fetchUser(date);
                // Include only local (i.e.; email) logins.  Exclude powerschool and other logins.
                const localLogins = response?.data?.logins?.filter(login => login.type === 'local');
                setUserLogins(localLogins || []);
            } catch (error) {
                setUserLoginInErrorState(error);
            } finally {
                setLoading(false);
            }
        };
        getUserLogins();
    }, []);

    return {
        userLogins,
        userLoginInErrorState,
        loading,
    };
}
