import React from 'react';
import { Alert, Snackbar } from '@mui/material';
import { useStrings } from 'comm-recipientapp-shared/src/utils';
import styles from '../styles';

export default function ServerErrorSnackbar({ isOpen, handleClose }) {
    const { FATAL_SERVER_ERROR_SNACKBAR } = useStrings();

    return (
        <Snackbar
            open={isOpen}
            onClose={handleClose}
            autoHideDuration={4000}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            sx={styles.snackbar_position}
        >
            <Alert severity="warning">{FATAL_SERVER_ERROR_SNACKBAR}</Alert>
        </Snackbar>
    );
}
