import React from 'react';
import { IconButton, Dialog, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styles from './styles';

/**
 * @typedef {object} PopupProps
 * @property {() => void} handleClose
 * @property {boolean} isOpen
 * @property {object} customStyles
 * @property {object} dialogCustomStyles
 * @property {boolean} hideCloseButton
 * @property {boolean} fullScreen
 * @param {React.PropsWithChildren<PopupProps>} props
 * @returns
 */
export default function Popup(props) {
    const {
        children,
        customStyles = {},
        dialogCustomStyles = {},
        fullScreen = false,
        handleClose,
        hideCloseButton = false,
        isOpen,
        hideBackdrop= false,
        disableEscapeKeyDown = false
    } = props;

    return (
        <Dialog
            fullWidth
            onClose={() => handleClose()}
            open={isOpen}
            sx={dialogCustomStyles}
            PaperProps={{ sx: { ...styles.dialog_paper_styles, ...customStyles } }}
            fullScreen={fullScreen}
            hideBackdrop={hideBackdrop}
            disableEscapeKeyDown={disableEscapeKeyDown}
        >
            {!hideCloseButton && (
                <Box sx={styles.popup_close_button_container}>
                    <IconButton onClick={() => handleClose()} data-testid='close-popup-button'>
                        <CloseIcon sx={styles.popup_close_button} />
                    </IconButton>
                </Box>
            )}
            {children}
        </Dialog>
    );
}
