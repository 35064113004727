import React from 'react';
import Dialog from '@mui/material/Dialog';
import { Box, Button, Typography, CircularProgress } from '@mui/material';
import { utils } from 'comm-recipientapp-shared';
import styles from './styles';

function ChangePhone({ handleVisible, open, phone, ...props }) {
    const strings = utils.useStrings();
    const [selectValue, setSelectValue] = React.useState(
        props.consent === utils.constants.PreferencePhoneConsent.PENDING ? utils.constants.PreferencePhoneConsent.YES : props.consent
    );
    return (
        <Dialog open={open} onClose={handleVisible} sx={styles.dialog}>
            <Box sx={styles.banner}>
                <Typography>
                    {strings.PERMISSION_SETTINGS_FOR} {utils.helpers.formatPhoneNumber(phone)}
                </Typography>
            </Box>
            <Box sx={styles.modal_content}>
                <p>
                    {
                        strings.WOULD_YOU_LIKE_TO_RECEIVE_OTHER_PRE_RECORDED_MESSAGES_FROM_YOUR_SCHOOL_OR_DISTRICT_FOR_TOPICS_THAT_YOU_SELECT_SUCH_AS_SCHOOL_AND_ATTENDANCE_NOTIFICATIONS
                    }
                </p>
                <select style={styles.select} value={selectValue} onChange={e => setSelectValue(e.target.value)}>
                    <option value={utils.constants.YES}>{strings.YES_ITS_OK_TO_CALL_ME_AT_THIS_NUMBER}</option>
                    <option value={utils.constants.NO}>{strings.NO_CALL_ME_FOR_EMERGENCIES_ONLY}</option>
                </select>
                <p>{strings.BY_SELECTING_YES_AND_SAVE_I_CONSENT_TO_RECEIVE_CALLS_CONTAINING_PRE_RECORDED_VOICE_MESSAGES}</p>
            </Box>
            <Box sx={styles.actions}>
                <Button onClick={handleVisible} style={{ marginRight: 4 }}>
                    {strings.CANCEL}
                </Button>
                <Box sx={styles.ButttonBox}>
                    <Button variant="contained" onClick={() => props.handleSavePreferences(selectValue)}>
                        {strings.SAVE}
                    </Button>
                    {props.startLoadingSaveButtonOnModal && (
                        <CircularProgress role="status" aria-live="assertive" aria-label={strings.LOADING} size={24} sx={styles.CircularProgress} />
                    )}
                </Box>
            </Box>
        </Dialog>
    );
}

export default ChangePhone;
