/* eslint-disable operator-linebreak */
import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Tab, Tabs, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { utils, actions, validations } from 'comm-recipientapp-shared';
import { setHasManageYourPinConsentShowed } from 'comm-recipientapp-shared/src/store/consent/consentPopupSlice';
import StudentList from './StudentList';
import Feedback from './Feedback';
import styles from './styles';

export default function ConsentManageYourPin() {
    const [completed, setCompleted] = useState(false);
    const { enabledStudents } = useSelector(state => state.absence);
    const schools = useSelector(state => state.preferences.schools);
    const hasManageYourPinConsentShowed = useSelector(state => state.consent.hasManageYourPinConsentShowed);
    const [visible, setVisible] = useState(false);
    const [students, setStudents] = useState({});
    const [pinValue, setPinValue] = useState('');
    const [validPin, setValidPin] = useState(false);
    const [started, setStarted] = useState(false);
    const [customersWithStudents, setCustomersWithStudents] = useState([]);
    const [currentOrganizationSelected, setCurrentOrganizationSelected] = useState(0);
    const strings = utils.useStrings();
    const dispatch = useDispatch();
    const handleClose = () => setVisible(false);
    const currentOrgName = useMemo(() => {
        const orgFound = customersWithStudents.find(org => org.customerId === currentOrganizationSelected);

        return orgFound?.customerName;
    }, [currentOrganizationSelected]);

    useEffect(() => {
        if (enabledStudents.length === 0 || hasManageYourPinConsentShowed) {
            return;
        }

        const customers = enabledStudents.map(student => student.customerId);

        const uniqueCustomers = [...new Set(customers)];

        const orgsArray = uniqueCustomers
            .map(customer => {
                const customerStudents = enabledStudents.filter(st => {
                    return st.customerId === customer && st.safearrivalPinEnabled && !st?.pinValue;
                });

                const customerName = schools.find(school => school.id === customer)?.name;
                return {
                    customerId: customer,
                    students: customerStudents,
                    customerName,
                };
            })
            .filter(orgFormatted => orgFormatted.students.length > 0);

        setCustomersWithStudents(orgsArray);
        if (orgsArray.length) {
            setVisible(true);
            setCurrentOrganizationSelected(orgsArray[0].customerId);
            dispatch(setHasManageYourPinConsentShowed(true));
        }
    }, [enabledStudents]);

    useEffect(() => {
        const studentByOrgId = customersWithStudents.reduce((acc, obj) => {
            if (acc[obj.customerId] === undefined) {
                acc[obj.customerId] = [];
            }
            acc[obj.customerId] = obj.students.map(student => ({ ...student }));
            return acc;
        }, {});
        setStudents(studentByOrgId);
    }, [customersWithStudents]);

    useEffect(() => {
        if (started) {
            const allStudents = Object.values(students).reduce((acc, student) => {
                return [...acc, ...student];
            }, []);

            const studentsSelected = allStudents.filter(student => student.safearrivalPinEnabled);
            const totalSelected = studentsSelected.length;

            // No students selected, no PIN value required
            if (totalSelected === 0) {
                setValidPin(true);
                return;
            }

            setValidPin(pinValue.length === utils.constants.MAX_MANAGE_PIN_LENGTH && validations.common.onlyNumbers.test(pinValue));
        }
    }, [pinValue, students]);

    const handleClick = index => {
        setStarted(true);

        const modifiedDistric = students[currentOrganizationSelected].map((studentToEdit, StudentIndex) => {
            if (index === StudentIndex) {
                return { ...studentToEdit, safearrivalPinEnabled: !studentToEdit.safearrivalPinEnabled };
            }
            return studentToEdit;
        });

        setStudents({ ...students, [currentOrganizationSelected]: modifiedDistric });
    };

    const handleSubmit = async () => {
        const allStudents = Object.values(students).reduce((acc, student) => {
            return [...acc, ...student];
        }, []);

        await Promise.all(
            allStudents.map(async student => {
                dispatch(
                    actions.absence.updateAttendanceStudent(student.customerId, student.personId, {
                        pinEnabled: student.safearrivalPinEnabled,
                        pinValue: !student.safearrivalPinEnabled ? null : pinValue,
                    })
                );
            })
        );
        dispatch(actions.absence.getData());
        setCompleted(true);
    };

    const handlePinChange = e => {
        const { value } = e.target;
        setStarted(true);
        setPinValue(value);
    };

    const handleComplete = () => {
        handleClose();
    };

    return (
        visible && (
            <Dialog maxWidth="sm" open={visible} onClose={handleClose}>
                <Box sx={styles.headerContainer}>
                    <DialogTitle sx={styles.dialogHeader}>{strings.MODAL_TITLE_ATTENDANCE_PIN}</DialogTitle>
                    <IconButton disableRipple sx={styles.headerCloseButton} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                <DialogContent
                    style={{
                        ...styles.modalContent,
                        minHeight: styles.heightAuto,
                    }}
                >
                    {completed ? (
                        <Feedback sx={styles.modalContent} handleComplete={handleComplete} />
                    ) : (
                        <>
                            <Box>
                                <Typography>{strings.MESSAGE_TO_REQUIRE_PIN_TO_EXPLAIN_ABSENCES_MODAL}</Typography>
                            </Box>
                            <Box>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={currentOrganizationSelected} aria-label="organization-tabs">
                                        {customersWithStudents.map((customer, index) => (
                                            <Tab
                                                key={customer.customerId}
                                                label={customer.customerName}
                                                value={customer.customerId}
                                                onClick={() => {
                                                    setCurrentOrganizationSelected(customer.customerId);
                                                }}
                                                id={`simple-tab-${index}`}
                                                aria-controls={`simple-tabpanel-${index}`}
                                            />
                                        ))}
                                    </Tabs>
                                </Box>
                                <StudentList
                                    handleClick={handleClick}
                                    handleClose={handleClose}
                                    currentOrgName={currentOrgName}
                                    handlePinChange={handlePinChange}
                                    handleSubmit={handleSubmit}
                                    pinValue={pinValue}
                                    validPin={validPin}
                                    students={students[currentOrganizationSelected] || []}
                                />
                            </Box>
                        </>
                    )}
                </DialogContent>
            </Dialog>
        )
    );
}
