import { useCallback, useState, useMemo, useEffect } from 'react';
import { FilterChangeActions, MessageFilterSectionsTypes } from '../../utils/constants';

export const initialFilterOptionsState = {
    ALL_MESSAGES: true,
    [MessageFilterSectionsTypes.STUDENT]: [],
    [MessageFilterSectionsTypes.FROM]: [],
    [MessageFilterSectionsTypes.CATEGORIES]: [],
    [MessageFilterSectionsTypes.STATUS]: [],
};

/**
 * @param {typeof initialFilterOptionsState} initialState - Initial state of the filter options.
 * @param {(newFilterOptions: typeof initialFilterOptionsState) => void} onChange - Callback function to handle the filter options state changes.
 *
 *   Hook to manage the filter options state of the inbox, you can see an example of how to use this hook at: packages/web/src/components/Inbox/index.js
 */
export default function useFilterOptions(initialState, onChange = () => {}) {
    const [currentFilterOptions, setCurrentFilterOptions] = useState(initialState || initialFilterOptionsState);

    // check if we have active filtes in the options with arrays
    const optionsHasActiveFilters = useMemo(
        () => Object.values(currentFilterOptions).some(value => Array.isArray(value) && value.length > 0),
        [currentFilterOptions]
    );

    const handleFilterOptionsChange = useCallback(
        /**
         * @param {'ADD' | 'REMOVE'} typeOfAction
         * @param {any} filterData - The object to be added or removed from the filter options category, this object will be used to filter messages.
         * @returns
         */
        (typeOfAction, filterData) => {
            const { section, filter } = filterData;

            // if the state is ALL_MESSAGES, we need to reset the filter options.
            if (section === 'ALL_MESSAGES') {
                if (typeOfAction === FilterChangeActions.ADD) {
                    setCurrentFilterOptions(initialFilterOptionsState);
                } else {
                    setCurrentFilterOptions(prevState => ({
                        ...prevState,
                        [section]: false,
                    }));
                }

                return;
            }

            if (typeOfAction === FilterChangeActions.ADD) {
                setCurrentFilterOptions(prevState => ({
                    ...prevState,
                    [section]: [...prevState[section], filter],
                    ALL_MESSAGES: false,
                }));
            } else if (typeOfAction === FilterChangeActions.REMOVE) {
                setCurrentFilterOptions(prevState => ({
                    ...prevState,
                    [section]: (() => {
                        if (typeof filter === 'object') {
                            return prevState[section].filter(item => JSON.stringify(item) !== JSON.stringify(filter));
                        }

                        return prevState[section].filter(item => item !== filter);
                    })(),
                }));
            }
        },
        []
    );

    const replaceCurrentFilterOptions = useCallback(filterOptions => {
        setCurrentFilterOptions(filterOptions);
    }, []);

    const resetFilterOptions = useCallback(() => {
        setCurrentFilterOptions(initialFilterOptionsState);
    }, []);

    useEffect(() => {
        if (!optionsHasActiveFilters) {
            setCurrentFilterOptions(initialFilterOptionsState);
        }
    }, [optionsHasActiveFilters]);

    useEffect(() => {
        onChange(currentFilterOptions);
    }, [currentFilterOptions]);

    return {
        currentFilterOptions,
        handleFilterOptionsChange,
        optionsHasActiveFilters,
        resetFilterOptions,
        replaceCurrentFilterOptions,
    };
}
