/* eslint-disable prettier/prettier */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { utils } from 'comm-recipientapp-shared';
import { Grid } from '@mui/material';
import Loading from '../Loading';
import styles, { StyledIframe } from './styles';

import { setRcInitialized } from 'comm-recipientapp-shared/src/store/chat/chatSlice';

function RCIframe(props) {
    const dispatch = useDispatch();

    const { 
        currentChatUser, 
        currentChat, 
        showOverlay, 
        rcInitialized,
        iframePermissions,
        iframeUrl,
        firstChatRoomName,
    } = props;

    const {
        SANDBOX_ATTRIBUTE_FOR_RC_IFRAME,
        RC_IFRAME_LAYOUT,
        RC_COMMANDS,
        RC_DISCUSSION_GROUP_TYPES,
        RC_MESSAGE_EVENTS,
    } = utils.constants;

    const [iframeInitialized, setIframeInitialized] = React.useState(false);
    const [userLoggedIn, setUserLoggedIn] = React.useState(false);
    const [loginAttempts, setLoginAttempts] = React.useState(5);

    const IframeRef = React.useRef(null);
    const strings = utils.useStrings();

    // this hack is used to set the last place the user has visited to the inbox and
    // is fired each time the conversation changes. This way whenever the user navigates "Back"
    // they will end up at the inbox
    const navigationHack = () => {
        window.history.replaceState(null, null, '/#/inbox');
        window.history.pushState(null, null, '/#/chat');
    };

    const attachChatEventListener = () => {
        window.addEventListener('message', (e) => {
            if (e.data?.eventName === RC_MESSAGE_EVENTS.CUSTOM_SCRIPT_LOGGED_IN) {
                setUserLoggedIn(true);
            }
            else if (e.data?.eventName === RC_MESSAGE_EVENTS.ROOM_OPENED) {
                dispatch(setRcInitialized(true));
                navigationHack();
            }
        });
    };

    useEffect(() => {
        dispatch(setRcInitialized(false));
        attachChatEventListener();
    }, []);

    useEffect(() => {
        if (
            iframeInitialized && 
            loginAttempts > 0 &&
            ! userLoggedIn
        ) setTimeout(() => loginUser(currentChatUser), 2000);
        
    }, [iframeInitialized, loginAttempts, userLoggedIn]);

    useEffect(() => {
        if (currentChat && userLoggedIn) {
            IframeRef.current.contentWindow.postMessage({
                externalCommand: RC_COMMANDS.GO,
                path: `/${RC_DISCUSSION_GROUP_TYPES.GROUP}/${currentChat.rcroomname}/${RC_IFRAME_LAYOUT}`,
            }, iframeUrl);
        }
    }, [currentChat, userLoggedIn]);

    const loginUser = (chatUser) => {
        IframeRef.current.contentWindow.postMessage({
            externalCommand: RC_COMMANDS.LOGIN_WITH_TOKEN,
            token: chatUser.rcauthtoken
        }, '*');
        setLoginAttempts(loginAttempts - 1);
    };

    const updateIframeInitialized = () => {
        setIframeInitialized(true);
    }

    return (
        <>
            {(! rcInitialized) && (
                <Grid sx={styles.loadingGrid}>
                    <Loading />
                </Grid>
            )}
            <StyledIframe
                title={strings.ROCKET_CHAT_IFRAME}
                ref={IframeRef}
                sandbox={SANDBOX_ATTRIBUTE_FOR_RC_IFRAME}
                onLoad={updateIframeInitialized}
                src={`${iframeUrl}/${RC_DISCUSSION_GROUP_TYPES.GROUP}/${firstChatRoomName}/${RC_IFRAME_LAYOUT}`}
                hidden={(! rcInitialized) || showOverlay}
                allow={iframePermissions}
            />
        </>
    );
}

export default RCIframe;
