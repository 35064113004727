import theme from '../../config/style/muiTheme';

const styles = {
    container: {
        maxWidth: "1400px !important"
    },
    userInfo: {
        display: "block",
        width: "100%",
        marginTop: "30px",
        header: {
            fontWeight: "500"
        },
        info: {
            display: "block",
            width: "100%",
            color: theme.palette.grey["700"]
        },
        skeleton: {
            marginTop: "8px",
            width: "300px", 
            height: "16px",
        }
    },
    userInfoContainer: { 
        display: "block", 
        width: "100%" 
    },
    mainActionArea: { 
        backgroundColor: "white", 
        marginTop: "30px", 
        position: "relative", 
        minHeight: "340px" 
    },
    errorContainer: { 
        display: "flex", 
        alignItems: "center", 
        width: "100%", 
        minHeight: "340px", 
        justifyContent: "center" 
    },
    preferencesContainer: {
        padding: "20px",
        display: 'grid',
        gridTemplateColumns: {
            lg: 'repeat(2, 1fr)',
            md: 'repeat(3, 1fr)',
        }
    },
    overlayIcon: { 
        fontSize: "54px" 
    },
    overlayText: {
        marginTop: "8px"
    },
    overlayContainer: { 
        textAlign: "center"
    },
    selectPrefText: {
        display: "inline",
        color: theme.palette.grey["700"],
        subText: { 
            display: "inline", 
            fontWeight: "600" 
        }
    },
    prefError: { 
        padding: '20px', 
        paddingBottom: "0" 
    },
    preferenceItem: {
        display: "inline-flex",
    },
    blockContainer: {
        padding: "20px",
    },
    unsubscribeFromAll: {
        color: theme.palette.primary.main,
        fontWeight: "500"
    },
    thisWillBlockText: {
        fontSize: "15px",
        marginLeft: "30px" ,
        color: theme.palette.grey["700"],
    },
    submitContainer: {
        display: "flex",
        padding: "20px",
        justifyContent: "center",
        submitPreferencesContainer: { 
            width: "100%", 
            maxWidth: "340px" 
        },
        submitButton: { 
            width: "100%", 
            height: "50px", 
            marginTop: "20px" 
        }
    },
    updatesWillApplyText: {
        color: theme.palette.grey["700"],
        marginTop: "10px",
        fontSize: "15px",
        subText: { 
            fontWeight: "800", 
            display: "inline" 
        }
    },
    youCanAlsoText: { 
        color: theme.palette.grey["700"],
        fontSize: "15px",
        margin: "40px", 
        display: { 
            xs: "none", 
            lg: "flex" 
        }
    },
    submitSkeletonContainer: {
        marginTop: "12px"
    },
    submitSkeleton: { 
        height: "14px", 
        marginTop: "8px" 
    },
    loadingContainer: {
        width: "100%",
        height: "100%",
        background: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        left: "0",
        top: "0"
    }
};

export default styles;
