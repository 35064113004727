/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { authAPI } from '../../api';
import { resetState } from '../resetStore';
import { getAccount } from '../user/userSlice';
import { getAbsences } from '../../api/absence';
import { resendPowerschoolConfirmEmailRequest } from '../../api/authApi';
import { getData, setAbsences, setIsFetched } from '../absence/absenceSlice';
import { setSchools, setIsFetched as setIsPreferencesFetched } from 'comm-recipientapp-shared/src/store/preferences/preferenceSlide';
import { getCustomers } from '../../api/preferences';
import { handlePhonePriorityEnforcement } from '../../utils/preferencesUtils';

const initialState = {
    isLoading: false,
    isError: false,
    auth: null,
    isSuccess: false,
    errorMessage: '',
    error: {},
    platform: null,
};

const authSlice = createSlice({
    name: 'authSlice',
    initialState,
    reducers: {
        setIsLoading: (state, { payload }) => {
            state.isLoading = payload;
        },
        setAuth: (state, { payload }) => {
            state.auth = payload;
        },
        setLogout: (state, { payload }) => {
            state.auth = payload;
            state.isLoading = false;
        },
        setErrorAuth: (state, { payload }) => {
            state.isError = payload;
        },
        resetErrorAuth: state => {
            state.isError = false;
        },
        setIsSuccess: (state, { payload }) => {
            state.isSuccess = payload;
        },
        setError: (state, { payload }) => {
            state.error = payload;
        },
        setPlatform: (state, { payload }) => {
            state.platform = payload;
        },
        resetError: state => {
            state.error = {};
            state.isError = false;
            state.isSuccess = false;
        },
    },
    extraReducers: builder => builder.addCase(resetState, () => initialState),
});

export const { 
    resetErrorAuth, 
    setAuth, 
    setPlatform, 
    setErrorAuth, 
    setIsLoading, 
    setIsSuccess, 
    setLogout, 
    setError, 
    resetError 
} = authSlice.actions;

export const login = (values, platform) => async dispatch => {
    let response = { error: true, message: 'auth error' };

    try {
        dispatch(setIsLoading(true));
        if (values.access_token) {
            dispatch(setAuth(values));
            dispatch(setErrorAuth(false));
            response = await dispatch(getAccount(values.access_token));
        } else {
            const { data } = await authAPI.login(
                {
                    username: values.email,
                    password: values.password,
                    grant_type: 'password',
                },
                platform
            );
            dispatch(setAuth(data));
            dispatch(setPlatform(platform));
            dispatch(setErrorAuth(false));
            response = await dispatch(getAccount());
        }

        if (response.error) {
            const isErrorMessage = typeof response.message !== 'number';
            if (isErrorMessage || !response.confirmed) {
                dispatch(setIsLoading(false));
                dispatch(setErrorAuth(true));
                return response;
            }
        }

        const currentYear = new Date().getFullYear();

        const [absencesResponse, schools] = await Promise.all([
            getAbsences(`${currentYear}-01-01`, `${currentYear}-12-31`),
            getCustomers(),
            dispatch(getData()),
        ]);

        dispatch(setAbsences(absencesResponse.data.absences));

        dispatch(setIsFetched(true));

        dispatch(setSchools(schools.data || []));

        dispatch(setIsPreferencesFetched(true));

        handlePhonePriorityEnforcement(schools.data, 'web');
    } catch (error) {
        dispatch(setErrorAuth(true));
        dispatch(setError(error.response));
    }

    dispatch(setIsLoading(false));
    return response;
};

export const resendEmailRequest = (userId, requestBody) => async () => {
    try {
        await authAPI.resendConfirmEmailRequest(userId, requestBody);
        return {
            error: false,
            message: '',
        };
    } catch (error) {
        return {
            error: true,
            message: error?.response?.data?.error || error,
        };
    }
};

export const resendPowerschoolEmail = async userId => {
    try {
        await resendPowerschoolConfirmEmailRequest(userId);
        return {
            error: false,
            message: '',
        };
    } catch (error) {
        return {
            error: true,
            message: error?.response?.data?.error || error,
        };
    }
};

export const signUp = values => async dispatch => {
    try {
        const data = {
            username: values.email,
            password: values.password,
        };
        dispatch(setIsLoading(true));
        await authAPI.signup(data);
        dispatch(setIsSuccess(true));
    } catch (error) {
        dispatch(setErrorAuth(true));
        dispatch(setError(error?.response));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export default authSlice.reducer;
