import { deleteAbsence, updateAbsence } from 'comm-recipientapp-shared/src/api/absence';

export default function useAttendanceService() {
    /**
     * @param {number} customerId
     * @param {number} absenceId
     * @param {Record<string, any>} absence
     * @param {string} language
     */
    const _updateAbsence = async (customerId, absenceId, absence, language) => updateAbsence(customerId, absenceId, absence, language);

    /**
     * @param {number} customerId
     * @param {number} absenceId
     * @returns
     */
    const _deleteAbsence = async (customerId, absenceId) => deleteAbsence(customerId, absenceId);

    return {
        updateAbsence: _updateAbsence,
        deleteAbsence: _deleteAbsence,
    };
}
