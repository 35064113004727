import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import * as validations from 'comm-recipientapp-shared/src/validations';
import { useUserLogins } from 'comm-recipientapp-shared/src/hooks/user';
import { userAPI } from 'comm-recipientapp-shared/src/api';
import { utils } from 'comm-recipientapp-shared';

const { isEmailFormatValid } = validations.common;

const useValidateEmail = () => {
    const strings = utils.useStrings();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { userLogins, loading } = useUserLogins();

    const [newEmail, setNewEmail] = React.useState('');
    const [emailError, setEmailError] = React.useState('');
    const [disableButton, setDisableButton] = React.useState(true);
    const [buttonLoading, setButtonLoading] = React.useState(false);

    const userEmailsAlreadyRegistered = userLogins.map(userEmail => userEmail.username);

    const goToValidateEmail = () => {
        navigate('/validate-email', { state: { newEmail, goBackTo: pathname } });
    };

    const handleEmailChange = e => {
        const emailValue = e.target.value;
        if (emailValue === '') {
            setEmailError(strings.EMAIL_IS_REQUIRED);
            setDisableButton(true);
        } else if (!isEmailFormatValid(emailValue)) {
            setEmailError(strings.PLEASE_ENTER_A_PROPERLY_FORMATTED_EMAIL_ADDRESS);
            setDisableButton(true);
        } else if (userEmailsAlreadyRegistered.includes(emailValue)) {
            setEmailError(strings.OOOPS_IT_LOOKS_LIKE_YOU_HAVE_ALREADY_USED_THAT_EMAIL_ADDRESS);
            setDisableButton(true);
        } else {
            setEmailError('');
            setDisableButton(false);
        }
        setNewEmail(emailValue);
    };

    const handleSendRequestForNewEmail = async () => {
        try {
            setButtonLoading(true);
            setDisableButton(true);
            await userAPI.addAdditionalEmailAddress(newEmail);
            goToValidateEmail();
        } catch (error) {
            setDisableButton(false);
            if (error?.response?.status === 409) {
                if (error.response.data.error === 'taken') {
                    setEmailError(strings.THIS_EMAIL_ADDRESS_IS_ALREADY_IN_USE);
                } else if (error.response.data.error === 'maxReached') {
                    setEmailError(strings.PLEASE_CONTACT_YOUR_SCHOOL_TO_UPDATE_EMAIL_ADDRESSES_ON_FILE);
                } else {
                    setEmailError(strings.AN_INTERNAL_ERROR_HAS_HAPPENED_PLEASE_TRY_AGAIN);
                }
            } else {
                setEmailError(strings.AN_INTERNAL_ERROR_HAS_HAPPENED_PLEASE_TRY_AGAIN);
            }
        } finally {
            setButtonLoading(false);
        }
    };

    return {
        newEmail,
        emailError,
        disableButton,
        loading,
        buttonLoading,
        setNewEmail,
        handleEmailChange,
        handleSendRequestForNewEmail,
    };
};

export default useValidateEmail;
