import React from 'react';
import { Box, Grid, useMediaQuery, Container, Paper } from '@mui/material';
import SignUpForm from './SignUpForm';
import Layout from '../Layout';
import SplashScreen from '../Shared/SplashScreen';
import styles from './styles';

function SignUp() {
    const isSmallScreen = useMediaQuery('(max-width:600px)');

    return (
        <Layout>
            <Container maxWidth="xl" disableGutters={isSmallScreen} sx={styles.content}>
                <Grid container component="main" sx={styles.griMain}>
                    <Grid item xs={12} sm={12} md={6} component={Paper} elevation={6} square>
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                            <SignUpForm />
                        </Box>
                    </Grid>
                    <Grid item md={6} sx={styles.gridSplash} aria-hidden>
                        <SplashScreen />
                    </Grid>
                </Grid>
            </Container>
        </Layout>
    );
}

export default SignUp;
