import theme from '../../../config/style/muiTheme';

const styles = {
    modal: {
        '.MuiDialog-container': {
            alignItems: 'flex-start',
        },
    },
    dialogHeader: {
        backgroundColor: theme.palette.error.main,
        color: '#ffffff',
        fontWeight: 300,
        textAlign: 'center',
    },
    modalContent: {
        maxWidth: 600,
        paddingBottom: 0,
    },
    content: {
        padding: '15px 35px',
    },
    warning_content: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-start',
        margin: '10px 0 20px',
    },
    warning: {
        paddingLeft: '5px',
    },
    check: {
        color: theme.palette.grey['900'],
        fontWeight: 300,
        fontSize: 14,
    },
    button_content: {
        margin: '10px 0',
    },
    button: {
        paddingLeft: '0px'
    },
    note_content: {
        borderTop: '1px solid #ccc',
        padding: '20px 0px 0px 0px',
    },
    note: {
        color: theme.palette.grey['900'],
        fontWeight: 300,
        fontSize: 14,
    },
    saveActions: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        margin: '30px auto 0px',
        width: {
            xs: '80%',
            sm: '80%',
            md: '40%',
            lg: '40%',
        },
    },
    button_ok: {
        textTransform: 'uppercase',
        backgroundColor: theme.palette.error.main,
        width: '138px',
        borderRadius: '2px',
        fontWeight: '300',
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: theme.palette.error.main,
        }
    },
    error: {
        backgroundColor: theme.palette.error.main,
        padding: '10px 15px',
        borderRadius: '4px',
        color: '#fff',
        fontSize: 14,
        marginTop: '10px',
        marginBottom: '15px'
    },
    success: {
        color: '#43ac6a',
        fontSize: 14,
        marginTop: '10px',
        marginBottom: '15px',
        display: 'flex',
        alignItems: 'center',
    },
    resend: {
        fontSize: 14,
        lineHeight: '17px',
        paddingLeft: '5px',
    },
    resend_icon: {
        position: 'relative',
        top: '2px'
    },
    resend_wait: {
        fontWeight: 300,
        fontSize: 14,
        marginTop: '3px',
        marginBottom: '5px'
    }
};

export default styles;
