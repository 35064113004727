/* eslint-disable import/no-cycle */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import { createSlice } from '@reduxjs/toolkit';
import { resetState } from '../resetStore';

export const initialState = {
    discussionGroups: [],
    directMessages: [],
    contactsList: [],
    favorites: [],
    sections: [],
    firstChat: null,
    currentChat: null,
    rcCurrentChat: null,
    rcInitialized: false,
    currentChatUser: null,
    hasUnreadChat: false,
    totalUnreadCount: 0,
    userActive: false,
};

const chatSlice = createSlice({
    name: 'chatSlice',
    initialState,
    reducers: {
        setDiscussionGroups: (state, { payload }) => {
            state.discussionGroups = payload;
        },
        updateDirectMessages: (state, { payload }) => {
            state.directMessages = payload;
        },
        setContactList: (state, { payload }) => {
            state.contactsList = createSimpleContacts(payload);
        },
        setCurrentChat: (state, { payload }) => {
            state.currentChat = payload;
        },
        setRcInitialized: (state, { payload }) => {
            state.rcInitialized = payload;
        },
        setRcCurrentChat: (state, { payload }) => {
            state.rcCurrentChat = payload;
        },
        setCurrentChatUser: (state, { payload }) => {
            state.currentChatUser = payload;
        },
        setSections: (state, { payload }) => {
            state.sections = payload;
        },
        setFirstChat: (state, { payload }) => {
            state.firstChat = payload;
        },
        setTotalUnreadCount: (state, { payload }) => {
            state.totalUnreadCount = payload;
        },
        setHasUnreadMessages: (state, { payload }) => {
            const {conversationId, hasUnreadMessages} = payload;
            setConversationHasUnreadMessages(state, conversationId, hasUnreadMessages);
        },
        resetChatState: (state) => {
            state = initialState;
        },
        setHasUnreadChat: (state, { payload }) => {
            state.hasUnreadChat = payload;
        },
        setUserActive: (state, { payload }) => {
            state.userActive = payload;
        },
        
    },
    extraReducers: builder => builder.addCase(resetState, () => initialState),
});

// eslint-disable-next-line prettier/prettier
export const {
    setDiscussionGroups,
    updateDirectMessages,
    setContactList,
    setCurrentChat,
    setRcCurrentChat,
    setRcInitialized,
    setCurrentChatUser,
    setSections,
    setFirstChat,
    setHasUnreadMessages,
    resetChatState,
    setTotalUnreadCount,
    setHasUnreadChat,
    setUserActive,
} = chatSlice.actions;

const setConversationHasUnreadMessages = (state, conversationId, hasUnreadMessages) => {
    const currentChatUser = state.currentChatUser;
    const conversations = getAllConversations(state);
    const conversation = conversations.find(item => item.id === conversationId);
    if (conversation && currentChatUser) {
        const personId = currentChatUser.personid;
        conversation.hasUnreadMessages = hasUnreadMessages;
        const foundChatUser = conversation.members.find(member => member.contact.id === personId);
        if (foundChatUser){
            foundChatUser.active = true;
        }
    }
};

const getAllConversations = (state) => {
    return state.discussionGroups.concat(state.sections.concat(state.directMessages));
}

export const setStateFromGetMemberOf = (conversationItems, currentChat, conversationId) => dispatch => {

    const filteredItems = conversationItems.filter(item => {
        return item.type === 'SECTION' ? item.active : true;
    });

    const sections = filteredItems.filter(item => item.type === 'SECTION');
    const groups = filteredItems.filter(item => item.type === 'GROUP');
    const directMessages = filteredItems.filter(item => item.type === 'PRIVATE');
    const currentChatIsDeleted = currentChat ? (conversationId === currentChat.id) : null;

    dispatch(setSections(sections)); // to fill sections
    dispatch(setDiscussionGroups(groups)); // to fill groups section
    dispatch(updateDirectMessages(directMessages)); // to fill direct messages
    dispatch(setContactList(filteredItems));
   
    if (!currentChat && filteredItems.length > 0 || currentChatIsDeleted) {
        dispatch(setFirstChat(filteredItems[0]));
    }
};

export const createSimpleContacts = conversationItems => {
    return conversationItems.map(({ id, name, description, type, rcroomname, members }) => {
        return { id, name, description, type, rcroomname, members };
    });
};

export default chatSlice.reducer;
