import theme from '../../config/style/muiTheme';

const styles = {
    actions: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    content: {
        paddingLeft: {
            lg: '0px',
            xs: '16px',
        },
        paddingRight: {
            lg: '0px',
            xs: '16px',
        },
        marginTop: '0px',
        marginBottom: '50px',
    },
    title_container: {
        marginBottom: '15px',
        display: 'flex',
        gap: '5px',
    },
    icon_container: {
        marginTop: '32px',
    },
    icon_title: {
        width: '3rem',
    },
    header_title: {
        color: '#2C5871',
        fontSize: 20,
    },
    page: {
        marginTop: '0px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    card: {
        background: '#ffffff',
        minHeight: 500,
        marginTop: 5,
        borderRadius: 2,
    },
    report_title: {
        display: {
            xs: 'none',
            sm: 'block',
        },
        fontWeight: 400,
        marginRight: '8px',
    },
    report_button: {
        padding: '7px 16px 9px',
        borderRadius: '10px',
        fontWeight: '500',
        fontSize: '16px',
        backgroundColor: theme.palette.purple['45'],
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.purple['42'],
        },
    },
    calendar_container: {
        marginTop: '30px',
        marginBottom: '30px',
    },
    manage_pin: {
        color: '#2C5871',
        margin: '15px auto 0',
        width: '85%',
    },
    manage_button: {
        marginTop: '1px',
        padding: 0,
    },
    show_absences: {
        fontSize: '18px',
        fontWeight: '500',
        width: 'fit-content',
    },
};

export default styles;
