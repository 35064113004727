import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CloseIcon from '@mui/icons-material/Close';
import MailIcon from '@mui/icons-material/Mail';
import { IconButton } from '@mui/material';
import { utils } from 'comm-recipientapp-shared';
import { handleEnterKeyPress } from 'comm-recipientapp-shared/src/utils/helpers';
import styles from './styles';

function EmailItem({ item, type, index, handleClick, ...props }) {
    const strings = utils.useStrings();
    const iconActive = item.notificationTypes.length > 0;

    const emailStatus = iconActive ? strings.ENABLED_TO_RECEIVE_EMAILS : strings.NOT_ENABLED_TO_RECEIVE_EMAILS;
    const arialLabel = `${strings.EMAIL}: ${item.email}, ${strings.STATUS}: ${emailStatus}, ${strings.PRESS_ENTER_TO_CHANGE_THESE_OPTIONS}`;
    return (
        <Card
            sx={styles.contact_card}
            data-testid={`contact_email_${index}`}
            onClick={() => handleClick(index, type)}
            onKeyDown={handleEnterKeyPress(() => handleClick(index, type))}
        >
            <CardContent sx={styles.contact_card_single} tabIndex="0" aria-label={arialLabel}>
                <div style={styles.content_circles} aria-hidden>
                    <div style={iconActive ? styles.contact_circle : styles.contact_circle_inactive}>
                        <MailIcon fontSize="small" sx={iconActive ? styles.circle_icon : styles.circle_icon_inactive} />
                    </div>
                </div>
                <div style={styles.contact_info} aria-hidden>
                    <span style={styles.contact_info_title}>{item.email}</span>
                </div>
                <IconButton
                    onClick={e => {
                        e.stopPropagation();
                        props.handleOnDelete(item.email);
                    }}
                    onKeyDown={handleEnterKeyPress(e => {
                        e.stopPropagation();
                        props.handleOnDelete(item.email);
                    })}
                    data-testid={`contact_delete_email_${index}`}
                    aria-label={strings.DELETE_EMAIL}
                >
                    <CloseIcon fontSize="small" aria-hidden />
                </IconButton>
            </CardContent>
        </Card>
    );
}

export default EmailItem;
