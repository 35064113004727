import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CheckIcon from '@mui/icons-material/Check';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';
import { utils } from 'comm-recipientapp-shared';
import styles from './styles';

function InactiveAccount({ visible, handleClose, handleResendVerification, ...props }) {
    const strings = utils.useStrings();

    return (
        <Dialog maxWidth="md" open={visible} onClose={handleClose} sx={styles.modal}>
            <DialogTitle sx={styles.dialogHeader}>{strings.UNCONFIRMED_ACCOUNT}</DialogTitle>
            <DialogContent sx={styles.modalContent}>
                <Box sx={styles.content}>
                    <Box sx={styles.warning_content}>
                        <WarningIcon />
                        <Typography sx={styles.warning}>{strings.YOUR_SCHOOLMESSENGER_ACCOUNT_HAS_NOT_BEEN_CONFIRMED}</Typography>
                    </Box>
                    <Typography sx={styles.check}>{strings.PLEASE_CHECK_YOUR_EMAIL_FOR_A_CONFIRMATION_LINK_TO_ACTIVATE_YOUR_ACCOUNT_DIDNT_RECEIVE_A_CONFIRMATION_EMAIL}</Typography>
                    <Box sx={styles.button_content}>
                        <Button sx={styles.button} onClick={handleResendVerification}>{strings.RESEND_ACCOUNT_CONFIRMATION_EMAIL}</Button>
                        {
                            props.status === utils.constants.NotificationType.SUCCESS &&
                            <Box sx={styles.success}>
                                <CheckIcon />
                                <Typography sx={styles.resend}>{strings.A_VERIFICATION_EMAIL_HAS_BEEN_RESEND_TO} {props.loadingText}.</Typography>
                            </Box>
                        }
                        {
                            props.status === utils.constants.NotificationType.ERROR &&
                            <Box sx={styles.error}>
                                <Typography sx={styles.resend}><WarningIcon fontSize="small" style={styles.resend_icon} /> {strings.VERIFICATION_EMAILS_ARE_ONLY_ALLOWED_TO_BE_SEND_ONCE_EVERY_10_MINUTES}</Typography>
                                <Typography sx={styles.resend_wait}>{strings.PLEASE_WAIT_AT_LEAST_10_MINUTES_BEFORE_TRYING_TO_RESEND}</Typography>
                            </Box>
                        }
                    </Box>
                    <Box sx={styles.note_content}>
                        <Typography sx={styles.note}>{strings.NOTE_YOU_WILL_NOT_BE_ABLE_TO_LOGIN_INTO_YOUR_SCHOOLMESSENGER_ACCOUNT_UNTIL_YOU_HAVE_VERIFIED_YOUR_EMAIL}</Typography>
                    </Box>
                    <Box sx={styles.saveActions}>
                        <Button
                            color="primary"
                            sx={styles.button_ok}
                            onClick={handleClose}
                            variant="contained">
                            {strings.OK}
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default InactiveAccount;
