const styles = {
    error_boundary_container: {
        margin: '40px',
    },
    error_details: {
        whiteSpace: 'pre-wrap',
        marginTop: '20px',
    },
};

export default styles;
