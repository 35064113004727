/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Box, Typography, IconButton, Alert } from '@mui/material';
import { debounce } from 'comm-recipientapp-shared/src/utils/inboxUtils';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import { useStrings } from 'comm-recipientapp-shared/src/utils';
import { updateAbsence } from 'comm-recipientapp-shared/src/api/absence';
import Popup from '../Popup';
import UnexplainedAbsenceForm from './UnexplainedAbsenceForm';
import Button from '../../../Shared/NewUI/Button';
import useObservableRef from './hooks/useObservableRef';
import styles from './styles';

/**
 * @typedef {object} UnexplainedAbsencesPopupProps
 * @property {import('./index').UnexplainedAbsence[]} absenceData
 * @property {boolean} isOpen
 * @property {() => void} handleClose
 * @param {React.PropsWithChildren<UnexplainedAbsencesPopupProps>} props
 */
export default function UnexplainedAbsencesPopup(props = { absenceData: [] }) {
    const { isOpen, handleClose, absenceData } = props;

    if (!isOpen) return null;

    const {
        UNEXPLAINED_ABSENCE_POPUP_TITLE,
        UNEXPLAINED_ABSENCE_POPUP_DESCRIPTION,
        UNEXPLAINED_ABSENCE_POPUP_SAVE_BUTTON,
        UNEXPLAINED_ABSENCE_POPUP_CANCEL_BUTTON,
        UNEXPLAINED_ABSENCE_POPUP_FOOTER_DESCRIPTION,
    } = useStrings();

    const footerDescriptionSplitted = UNEXPLAINED_ABSENCE_POPUP_FOOTER_DESCRIPTION.split(',');

    const [isFormReady, setIsFormReady] = useState(false);
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    const initialAbsencesMapped = useMemo(() => {
        return absenceData.reduce((acc, absence) => {
            acc[absence.id] = {};

            return acc;
        }, {});
    }, [absenceData]);

    // we use a ref to prevent re-renders when the state changes of any of the forms changes,
    // this can be slow due to the amount of forms we have to render if we have multiple absences
    const absencesStatusMap = useObservableRef(initialAbsencesMapped);

    const onAbsenceChange = newData => {
        // keep track of the changes for each absence, so we can save them later once each absence is completed
        absencesStatusMap.set({
            ...absencesStatusMap.getRefValue(),
            [newData.id]: newData,
        });
    };

    const onChangeEvent = useCallback(debounce(onAbsenceChange, 300), []);

    useEffect(() => {
        if (isOpen) {
            absencesStatusMap.set(initialAbsencesMapped);
        }
    }, [isOpen]);

    useEffect(() => {
        const unsubscribe = absencesStatusMap.subscribe(() => {
            // the unexplained absences can only be sent if all the required fields from the forms are completed.
            const formHasErrors = Object.values(absencesStatusMap.getRefValue()).some(
                absence => absence?.absenceType === undefined || absence?.reasonCode === undefined
            );

            setIsFormReady(!formHasErrors);
        });

        return unsubscribe;
    }, []);

    const submitAbsences = async () => {
        try {
            const absences = Object.values(absencesStatusMap.getRefValue());
            const absencesPromises = [];

            absences.forEach(absence => {
                const customerId = absenceData.find(_absence => _absence.id === absence.id)?.student?.customerId;

                absencesPromises.push(updateAbsence(customerId, absence.id, absence));
            });

            await Promise.all(absencesPromises);

            handleClose();
            window.dispatchEvent(new Event('refetchAbsences'));
        } catch (error) {
            const _errorMessages = error.response.data;

            setErrorMessages(_errorMessages);

            setIsSnackbarOpen(true);
        }
    };

    return (
        <Popup isOpen={isOpen} customStyles={styles.popup_styles} hideCloseButton handleClose={handleClose}>
            <>
                <Snackbar open={isSnackbarOpen} autoHideDuration={6000} onClose={() => setIsSnackbarOpen(false)}>
                    <Alert severity="error">{errorMessages.map((err, idx) => `${idx + 1}. ${err?.message}`).toString()}</Alert>
                </Snackbar>

                <Box sx={styles.popup_header}>
                    <Box sx={styles.popup_close_button_container}>
                        <IconButton onClick={handleClose} sx={styles.popup_close_button}>
                            <CloseIcon htmlColor="white" />
                        </IconButton>
                    </Box>
                    <Box>
                        <Typography sx={styles.popup_title}>{UNEXPLAINED_ABSENCE_POPUP_TITLE}</Typography>
                        <Typography sx={styles.popup_subtitle}>{UNEXPLAINED_ABSENCE_POPUP_DESCRIPTION}</Typography>
                    </Box>
                </Box>
                <Box sx={styles.popup_absences_container}>
                    {absenceData.map((absence, idx) => (
                        <Box key={`absence-${idx}`}>
                            <UnexplainedAbsenceForm absence={absence} onAbsenceChange={onChangeEvent} />
                        </Box>
                    ))}

                    <Box sx={styles.popup_text_and_buttons_container}>
                        <Box sx={styles.popup_message_container}>
                            <Typography sx={styles.popup_message}>{footerDescriptionSplitted[0]},</Typography>
                            <Typography sx={styles.popup_message}>{footerDescriptionSplitted[1]}</Typography>
                        </Box>
                        <Box sx={styles.buttons_container}>
                            <Button sx={styles.popup_cancel_button} onClick={handleClose}>
                                {UNEXPLAINED_ABSENCE_POPUP_CANCEL_BUTTON}
                            </Button>
                            <Button disabled={!isFormReady} autoFocus sx={styles.popup_save_button} onClick={submitAbsences}>
                                {UNEXPLAINED_ABSENCE_POPUP_SAVE_BUTTON}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </>
        </Popup>
    );
}
