/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hooks } from 'comm-recipientapp-shared';
import { setHasUnreadChat } from 'comm-recipientapp-shared/src/store/chat/chatSlice';

function ActivityRefresh({ userIsActive, setUserIsActive }) {

    const dispatch = useDispatch();
    const { 
        getLogInFirstChatUser, 
        activityApiCalls, 
        conversationItems,
    } = hooks.chat.useChat(); 

    const { totalUnreadCount, currentChatUser } = useSelector(state => state.chat);

    const TWENTY_SECONDS = 20000;
    const TEN_MINUTES = 60000 * 10;

    const [intervalMillis, setIntervalMillis] = useState(TWENTY_SECONDS);
    const [refreshingActivity, setRefreshingActivity] = useState(false);
    const [timer, setTimer] = useState(null);

    useEffect(() => {
        getLogInFirstChatUser();
    }, [])

    useEffect(() => {
        dispatch(setHasUnreadChat(totalUnreadCount > 0));
    }, [totalUnreadCount]);

    useEffect(() => {
        if (conversationItems.length > 0) {
            const unreadConversationCount = conversationItems.filter(item => item.hasUnreadMessages).length;
            dispatch(setHasUnreadChat(unreadConversationCount > 0));
        }
    }, [conversationItems])

    useEffect(() => {
        clearTimeout(timer);
        userIsActive ? 
            setIntervalMillis(TWENTY_SECONDS) :
            setIntervalMillis(TEN_MINUTES);
        setRefreshingActivity(false);
    },[userIsActive])
    
    useEffect(() => {
        if (! refreshingActivity && currentChatUser) {    
            setRefreshingActivity(true);
            const timeout = setTimeout(() => {
                activityApiCalls(currentChatUser);
                setRefreshingActivity(false);
                dispatch(setUserIsActive(false));
            }, intervalMillis);
            setTimer(timeout);
        }
    }, [refreshingActivity, currentChatUser]);

    return null;
}

export default ActivityRefresh;

