import React from 'react';
import { Button, Box, Typography } from '@mui/material';
import { useStrings } from 'comm-recipientapp-shared/src/utils';
import ChevronLeftSVG from 'comm-recipientapp-shared/src/assets/chevron_left.svg';
import styles from './styles';

export default function BackButton({ handleClickBackButton }) {
    const strings = useStrings();
    return (
        <Box sx={styles.boxForButton}>
            <Button
                variant="text"
                onClick={handleClickBackButton}
                startIcon={<Box alt={strings.CHEVRON_RIGHT_ICON} component="img" src={ChevronLeftSVG} sx={styles.cardChevronRightIcon} />}
                sx={styles.button}
                aria-label={strings.BACK}
            >
                <Typography sx={styles.typographyFooter}>{strings.BACK}</Typography>
            </Button>
        </Box>
    );
}
