import { styled, Typography } from '@mui/material';

const Title = styled(Typography)({
    fontSize: '48px',
    color: '#33366A',
    fontWeight: '700',
    lineHeight: '110px',
    letterSpacing: '0.5px',
    marginLeft: 2,
    fontFamily: 'Hornbill',
});

export default Title;
