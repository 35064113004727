import React, { useRef, useMemo } from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import EditAbsenceForm from '../Popup/Absence/EditAbsence';
import styles from './styles';

/**
 * @typedef {object} UnexplainedAbsencesFormProps
 * @property {import('./index').UnexplainedAbsence} absence
 * @property {(newData) => void} onAbsenceChange
 * @param {React.PropsWithChildren<UnexplainedAbsencesFormProps>} props
 */
export default function UnexplainedAbsenceForm(props) {
    const { absence, onAbsenceChange } = props;

    /** @type {import('../Popup/Absence/EditAbsence').EditAbsenceProps['editAbsenceFormRef']} */
    const formRef = useRef();

    const onChange = () => {
        onAbsenceChange(formRef.current.getFormData());
    };

    const studentInitial = useMemo(() => absence.student?.firstName?.toUpperCase().charAt(0), [absence]);

    return (
        <Box sx={styles.absence_form_container}>
            <Box sx={styles.absence_form_data_container}>
                <Box
                    sx={{
                        ...styles.absence_form_student_initial,
                        backgroundColor: absence.assignedColor,
                    }}
                >
                    <Typography sx={styles.absence_form_student_initial_text}>{studentInitial}</Typography>
                </Box>
                <Box sx={styles.absence_form_student_name_and_organization_container}>
                    <>
                        <Typography sx={styles.absence_form_student_name}>{absence.student?.firstName}</Typography>
                        <Typography sx={styles.absence_form_student_name}>{absence.student.lastName}</Typography>
                    </>
                    <Typography sx={styles.absence_form_organization_name}>- {absence.student?.organizationName}</Typography>
                </Box>
            </Box>
            <EditAbsenceForm popupData={absence} editAbsenceFormRef={formRef} onChange={onChange} />
        </Box>
    );
}
