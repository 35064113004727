import * as React from 'react';

const Inbox = props => (
    <svg width="1em" height="1em" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.925 1.99.868 5.378a2.742 2.742 0 0 0-.344.977l-.102.678a24.426 24.426 0 0 0 .11 7.93 2.414 2.414 0 0 0 2.137 1.978l1.23.122a44.86 44.86 0 0 0 8.863 0l1.23-.122a2.414 2.414 0 0 0 2.139-1.978 24.43 24.43 0 0 0 .11-7.93l-.103-.678a2.743 2.743 0 0 0-.365-1.013l-1.037-3.347A2.342 2.342 0 0 0 12.822.368a32.595 32.595 0 0 0-8.983 0A2.343 2.343 0 0 0 1.925 1.99Zm10.69-.137a31.094 31.094 0 0 0-8.57 0 .843.843 0 0 0-.688.584l-.501 1.606a2.77 2.77 0 0 1 .113-.013l.979-.097c2.915-.29 5.851-.29 8.766 0l.979.097c.036.003.071.008.107.012l-.497-1.604a.842.842 0 0 0-.688-.585Zm-.284 6.897a.75.75 0 0 0 0-1.5h-8a.75.75 0 0 0 0 1.5h8Z"
            fill="currentColor"
        />
    </svg>
);

export default Inbox;
