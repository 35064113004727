import { store } from '../store';
import { updatePreferences } from '../store/preferences/preferenceSlide';

export const stringContainsOnlyDigits = string => /^\d+$/.test(string);

/**
 * US phones all use one single format: (NXX) NXX-XXXX, where N is a number between 2–9 and X is a number between is 0–9. This is true for all 25 or
 * so countries participating in the North American Numbering Plan (country code +1)
 *
 * @param {string} phone A plain phone string, e.g: 3054509857
 */
export const isUSPhoneNumberValid = phone => {
    const isInNRange = number => number >= 2 && number <= 9;

    return isInNRange(phone.charAt(0)) && isInNRange(phone.charAt(3));
};

export const handlePhonePriorityEnforcement = (data, platform) => {
    if (Array.isArray(data)) {
        data.forEach(school => {
            const currentPreferences = school?.destinationPreferences;
            const phones = school?.destinationPreferences?.phones;
            const phonePriorityEnforcement = school?.settings?.phonePriorityEnforcement;

            let updatedPhones = phones;
            const allFilteredIds = [];

            if (phonePriorityEnforcement?.length > 0) {
                phonePriorityEnforcement?.forEach(id => {
                    const filteredIds = school?.notificationTypes
                        .filter(notification => notification?.priority === id)
                        .map(notification => notification?.id);

                    allFilteredIds.push(...filteredIds);
                });

                const violatingPhones = phones.filter(phone => {
                    return allFilteredIds.some(id => !phone.notificationTypes.includes(id));
                });

                if (violatingPhones?.length > 0) {
                    // Find the IDs missing in all phones
                    const missingIds = allFilteredIds.filter(id => phones.every(phone => !phone.notificationTypes.includes(id)));

                    // Add the missing IDs to all phones
                    updatedPhones = phones.map(phone => {
                        const updatedNotificationTypes = [...phone.notificationTypes, ...missingIds];
                        const uniqueUpdatedNotificationTypes = Array.from(new Set(updatedNotificationTypes));
                        return { ...phone, notificationTypes: uniqueUpdatedNotificationTypes };
                    });
                }
            }

            // dispatch only if array was updated
            if (JSON.stringify(updatedPhones) !== JSON.stringify(phones)) {
                const newDestinationPreferences = { ...currentPreferences, phones: updatedPhones };
                store?.dispatch(updatePreferences(school?.id, newDestinationPreferences, platform));
            }
        });
    }
};
