import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Typography } from '@mui/material';
import { utils, actions } from 'comm-recipientapp-shared';
import AttendanceSvgFocused from 'comm-recipientapp-shared/src/assets/attendance_focused.svg';
import ManageYourPin from './ManageYourPin';
import Layout from '../Layout';
import AttendanceCalendar from './Calendar';
import AbsenceForm from '../AbsenceForm';
import ErrorBoundary from '../Error/ErrorBoundary';
import ErrorComponent from '../Error/ErrorComponent';
import SubTitle from '../Shared/SubTitle';
import Title from '../Shared/NewUI/Title';
import styles from './styles';

const { setIsNewAbsenceFormVisible } = actions.absence;

function Attendance() {
    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const strings = utils.useStrings();
    const { isNewAbsenceFormVisible } = useSelector(state => state.absence);
    const userRoles = useSelector(state => state.user?.account?.roles);

    const toggleModalPin = () => setVisible(!visible);

    const handleComplete = () => visible(!visible);

    useEffect(() => {
        if (! userRoles.includes(utils.constants.Roles.PARENT)) {
            navigate('/inbox');
        }
    }, []);

    return (
        <ErrorBoundary fallbackComponent={ErrorComponent}>
            <Layout>
                {isNewAbsenceFormVisible && (
                    <AbsenceForm open={isNewAbsenceFormVisible} handleClose={() => dispatch(setIsNewAbsenceFormVisible(false))} />
                )}
                <Container disableGutters maxWidth="lg" sx={styles.content}>
                    <Box sx={styles.title_container}>
                        <Box alt={strings.ATTENDANCE} component="img" src={AttendanceSvgFocused} sx={styles.icon_title} />
                        <Title variant="h1">{strings.ATTENDANCE}</Title>
                    </Box>
                    <Box sx={styles.page}>
                        <SubTitle sx={styles.show_absences}>{strings.STUDENT_ABSENCES}</SubTitle>
                        <Button
                            sx={styles.report_button}
                            onClick={() => dispatch(setIsNewAbsenceFormVisible(true))}
                            aria-label={strings.REPORT_AN_ABSENCE}
                        >
                            {strings.REPORT_AN_ABSENCE} +
                        </Button>
                    </Box>
                    <Box sx={styles.calendar_container}>
                        <AttendanceCalendar />
                    </Box>
                    <Box sx={styles.manage_pin}>
                        <Typography variant="subtitle2">
                            {
                                strings.DID_YOU_KNOW_THAT_YOU_CAN_REQUIRE_A_PIN_TO_BE_ENTERED_BEFORE_YOUR_STUDENTS_ABSENCE_CAN_BE_REPORTED_OR_EXPLAINED_THROUGH_THE_SAFEARRIVAL_PHONE_SYSTEM
                            }
                        </Typography>
                        <Button sx={styles.manage_button} onClick={toggleModalPin}>
                            {strings.MANAGE_YOUR_PIN_SETTINGS}
                        </Button>
                    </Box>
                </Container>

                {visible && <ManageYourPin visible={visible} handleClose={toggleModalPin} handleComplete={handleComplete} />}
            </Layout>
        </ErrorBoundary>
    );
}

export default Attendance;
