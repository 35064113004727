import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Box, Button } from '@mui/material';
import { useStrings } from 'comm-recipientapp-shared/src/utils';
import styles from './styles';

export default function SyncPopup({ handleClose }) {
    const { language } = useSelector(state => state.user);

    const userEmail = useSelector(state => state?.auth?.auth?.sub || '');

    const {
        ABSENCE_SYNC_COMPLETE_MESSAGE,
        ABSENCE_SYNC_COMPLETE_BANNER_TITLE,
        ABSENCE_SYNC_COMPLETE_OK_BUTTON,
    } = useStrings();

    const splittedMessage = useMemo(() => ABSENCE_SYNC_COMPLETE_MESSAGE.split('/'), [language]);

    return (
        <Box>
            <Box sx={styles.banner}>
                <Typography sx={styles.banner_text}>
                    {ABSENCE_SYNC_COMPLETE_BANNER_TITLE}
                </Typography>
            </Box>
            <Box sx={styles.text_container}>
                <Typography sx={styles.message}>
                    {splittedMessage[0]} {`<<${userEmail}>>`} {splittedMessage[1]}.
                </Typography>
            </Box>

            <Box sx={styles.button_container}>
                <Button onClick={handleClose} sx={styles.button}>
                    {ABSENCE_SYNC_COMPLETE_OK_BUTTON}
                </Button>
            </Box>
        </Box>
    );
}
