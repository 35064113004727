import React, { useState } from 'react';
import { Box, IconButton, Card, Button } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import { utils } from 'comm-recipientapp-shared';
import StudentButton from './StudentButton';
import styles from './styles';
import './styles.css';

const { StudentButtonStyleStates } = utils.constants;

/**
 * @typedef {object} FilterBarProps
 * @property {() => void} resetSelectedStudent
 * @property {(personId: string, customerId: string, organizationName: string, organizationId: number) => void} handleStudentFilterChange
 * @property {{
 *     personId: string;
 *     customerId: string;
 *     organizationName: string;
 *     organizationId: number;
 * }} currentStudentSelected
 * @property {Array} students
 * @property {Map<string, string>} studentAssignedColors
 * @property {() => Promise<void>} refetchData
 * @param {React.PropsWithChildren<FilterBarProps>} props
 */
export default function FilterBar(props) {
    const { currentStudentSelected, handleStudentFilterChange, refetchData, resetSelectedStudent, studentAssignedColors, students } = props;

    const [isFetching, setIsFetching] = useState(false);

    const strings = utils.useStrings();

    return (
        <Card sx={styles.cardContainer}>
            <Box sx={styles.flexContainer}>
                <Box sx={styles.buttonsContainer}>
                    <Button
                        variant={currentStudentSelected === null ? StudentButtonStyleStates.SELECTED : StudentButtonStyleStates.UNSELECTED}
                        onClick={resetSelectedStudent}
                        sx={currentStudentSelected === null ? { ...styles.studentButton, ...styles.studentButtonResetFilter } : styles.studentButton}
                        data-testid="reset-student-selected-button"
                    >
                        {strings.ALL_FILTER_BUTTON}
                    </Button>
                    {students.map((student, i) => (
                        <StudentButton
                            currentStudentSelected={currentStudentSelected}
                            handleStudentFilterChange={handleStudentFilterChange}
                            key={`student-button-${i}`}
                            student={student}
                            studentAssignedColors={studentAssignedColors}
                            students={students}
                        />
                    ))}
                </Box>
                <IconButton
                    onClick={async () => {
                        try {
                            setIsFetching(true);
                            await refetchData();
                            setIsFetching(false);
                        } catch (err) {
                            setIsFetching(false);
                        }
                    }}
                    sx={styles.reFetchIcon}
                    data-testid="refetch-data-button"
                >
                    <CachedIcon className={isFetching ? 'refetch_icon' : ''} />
                </IconButton>
            </Box>
        </Card>
    );
}
