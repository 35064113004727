import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as utils from '../../utils';
import * as validations from '../../validations';
import { actions } from '../../store';
import dayjs from 'dayjs';
import { getAbsences } from '../../api/absence';
import { setAbsencesUnexplained } from '../../store/absence/absenceSlice';

const { absenceTypes } = utils.absenceUtils;

const useNewAbsence = () => {
    const dispatch = useDispatch();
    const strings = utils.useStrings();
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [absenceType, setAbsenceType] = useState(null);
    const [reason, setReason] = useState(null);
    const [dateOfAbsence, setDateOfAbsence] = useState(null);
    const [expectedTimeOfArrival, setExpectedTimeOfArrival] = useState(null);
    const [timeOfDeparture, setTimeOfDeparture] = useState(null);
    const [returningToSchoolAt, setReturningToSchoolAt] = useState(null);
    const [firstDayOfAbsence, setFirstDayOfAbsence] = useState(null);
    const [lastDayOfAbsence, setLastDayOfAbsence] = useState(null);
    const [comment, setComment] = useState('');
    const [attachment, setAttachment] = useState(null);
    const [commentError, setCommentError] = useState('');

    const { validateNewAbsenceForm, isCommentValid, COMMENT_MAX_LENGTH } = validations.absence;
    const { submitAbsence } = actions.absence;

    const formatDate = date => dayjs(date).format('YYYY-MM-DD');

    const formatTime = time => dayjs(time).format('HH:mm');

    const generateDates = (startDate = dayjs(), endDate = dayjs()) => {
        const dateArray = [];
        let currentDate = dayjs(startDate);
        const stopDate = dayjs(endDate);
        while (currentDate <= stopDate) {
            dateArray.push({ date: dayjs(currentDate).format('YYYY-MM-DD') });
            currentDate = dayjs(currentDate).add(1, 'day');
        }
        return dateArray;
    };

    const handleAbsenceDates = () => {
        if (absenceType.type === absenceTypes.FULL_DAY) return [{ date: formatDate(dateOfAbsence), inTime: null, outTime: null }];
        if (absenceType.type === absenceTypes.LATE) return [{ date: formatDate(dateOfAbsence), inTime: formatTime(expectedTimeOfArrival), outTime: null }];
        if (absenceType.type === absenceTypes.EARLY_DEPARTURE) return [{ date: formatDate(dateOfAbsence), inTime: null, outTime: formatTime(timeOfDeparture) }];
        if (absenceType.type === absenceTypes.PARTIAL_DAY) return [{ date: formatDate(dateOfAbsence), inTime: formatTime(returningToSchoolAt), outTime: formatTime(timeOfDeparture) }];
        if (absenceType.type === absenceTypes.MULTI_DAY) return generateDates(firstDayOfAbsence, lastDayOfAbsence);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            student: {
                customerId: selectedStudent.customerId,
                personId: selectedStudent.personId,
                organizationId: selectedStudent.organizationId,
            },
            source: 'user',
            client: 'web',
            absenceType: absenceType.type,
            reasonCode: reason.code,
            absenceDates: handleAbsenceDates(),
            comment,
            attachment,
        };
        dispatch(submitAbsence(data));
    };

    const handleSelectedStudent = value => {
        setSelectedStudent(value);
    };

    const handleAbsenceType = value => {
        setAbsenceType(value);
    };

    const handleReason = value => {
        setReason(value);
    };

    const handleDateOfAbsence = value => {
        setDateOfAbsence(value);
    };

    const handleExpectedTimeOfArrival = value => {
        setExpectedTimeOfArrival(value);
    };

    const handleTimeOfDeparture = value => {
        setTimeOfDeparture(value);
    };

    const handleReturningToSchoolAt = value => {
        setReturningToSchoolAt(value);
    };

    const handleFirstDayOfAbsence = value => {
        setFirstDayOfAbsence(value);
    };

    const handleLastDayOfAbsence = value => {
        setLastDayOfAbsence(value);
    };

    const handleComment = value => {
        setComment(value);
    };

    const handleAttachment = value => {
        setAttachment(value);
    };

    const isNewAbsenceValid = validateNewAbsenceForm({
        selectedStudent,
        absenceType,
        reason,
        dateOfAbsence,
        expectedTimeOfArrival,
        timeOfDeparture,
        returningToSchoolAt,
        firstDayOfAbsence,
        lastDayOfAbsence,
        comment
    });

    const clearValues = () => {
        setAbsenceType(null);
        setReason(null);
    }

    const getAbsencesUnexplained = async () => {
        try {
            const currentYear = new Date().getFullYear();
            const absencesResponse = await getAbsences(`${currentYear}-01-01`, `${currentYear}-12-31`);
            const dataAbsences = absencesResponse?.data?.absences || [];
            const hasUnexplained = dataAbsences.some(obj => {
                const status = obj?.status;
                return status?.isUnexplained === true && status?.isEditable === true;
            });
            return dispatch(setAbsencesUnexplained(hasUnexplained));
        } catch (error) {
            return false;
        }
    }

    useEffect(() => {
        if(!isCommentValid(comment)){
            setCommentError(`${strings.COMMENTS_CANNOT_EXCEED} ${COMMENT_MAX_LENGTH} ${strings.CHARACTERS}`);
        }else{
            setCommentError("")
        }
    }, [comment]);

    useEffect(() => {
        clearValues();
    }, [selectedStudent])

    return {
        values: {
            absenceType,
            comment,
            dateOfAbsence,
            expectedTimeOfArrival,
            firstDayOfAbsence,
            lastDayOfAbsence,
            reason,
            returningToSchoolAt,
            selectedStudent,
            timeOfDeparture,
            attachment,
        },
        errors: {
            commentError
        },
        handlers: {
            handleAbsenceType,
            handleComment,
            handleDateOfAbsence,
            handleExpectedTimeOfArrival,
            handleFirstDayOfAbsence,
            handleLastDayOfAbsence,
            handleReason,
            handleReturningToSchoolAt,
            handleSelectedStudent,
            handleTimeOfDeparture,
            handleAttachment,
        },
        isNewAbsenceValid,
        handleSubmit,
        getAbsencesUnexplained
    };
};

export default useNewAbsence;
