import theme from '../../config/style/muiTheme';

const styles = {
    chatContainer: {
        display: 'flex',
        overflow: 'hidden',
        top: {
            xs: '58px',
            sm: '70px',
            md: '70px',
            lg: '70px',
            xl: '70px',
        },
        left: '0',
        right: '0',
        bottom: '0px',
        height: {
            xs: 'calc(100vh - 60px)',
            sm: 'calc(100vh - 72px)',
            md: 'calc(100vh - 72px)',
            lg: 'calc(100vh - 72px)',
            xl: 'calc(100vh - 72px)',
        },
        background: theme.palette.common.white,
        maxHeight: "100%",
    },
    leftPanel: {
        top: {
            height: '165px',
            padding: '16px',
            borderRight: '1px solid',
            borderBottom: '1px solid',
            borderColor: `${theme.palette.grey[120]}`,
        },
        bottom: {
            padding: '10px',
            height: '100%',
            borderRight: '1px solid',
            borderColor: `${theme.palette.grey[120]}`,
        },
    },
    rightPanel: {
        top: {
            height: '165px',
            paddingTop: '30px',
            borderBottom: '1px solid',
            borderColor: `${theme.palette.grey[120]}`,
        },
        navBar: {
            borderBottom: '1px solid',
            width: '100%',
            borderColor: `${theme.palette.grey[120]}`,
            padding: '5px',
            backButton: {
                fontSize: '16px',
            },
        },
    },
};

export default styles;
