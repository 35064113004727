const styles = {
    pagination: {
        '& .MuiPaginationItem-root': {
            color: '#33366A',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '150%',
            '&.Mui-selected': {
                backgroundColor: '#e4e4e4',
                borderRadius: '9px',
                border: '1px solid #545BE5',
            },
            '&:hover': {
                backgroundColor: 'transparent',
                borderRadius: '9px',
                border: '1px solid #545BE5',
            }
        },
    },
};

export default styles;
