import HTTP from '../HTTP';

export const getAbsenceForm = () => HTTP.get(`attendance`);

/**
 * @param {number} customerId
 * @param {number} absenceId
 * @param {Record<string, any>} absence
 */
export const updateAbsence = (customerId, absenceId, body, language = 'en') =>
    HTTP.patch(`attendance/${customerId}/absences/${absenceId}`, body, { headers: { 'Accept-Language': language } });

/**
 * Receive a range of dates with the following format YYYY-MM-DD
 *
 * @param {string} startDate
 * @param {string} endDate
 * @returns
 */
export const getAbsences = (startDate, endDate) =>
    HTTP.get(`attendance/absences/${startDate}/${endDate}?timestamp=${new Date().getTime()}`);

/**
 * @param {number} customerId
 * @param {number} studentId
 * @param {Record<string, any>} requestBody
 */
export const attendanceEnableStudents = (customerId, studentId, requestBody) =>
    HTTP.put(`attendance/attendanceEnabledStudents/customers/${customerId}/students/${studentId}`, requestBody);

/**
 * @param {number} customerId
 * @param {number} absenceId
 */
export const deleteAbsence = (customerId, absenceId) => {
    return HTTP.delete(`attendance/${customerId}/absences/${absenceId}`);
};

export const submitAbsence = data => {
    return HTTP.post(`attendance/absences`, data);
};
