import React, { useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, Chip, Divider, IconButton, Stack, Popover, Tooltip, Button } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DraftsIcon from '@mui/icons-material/Drafts';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import SmsIcon from '@mui/icons-material/Sms';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import { utils } from 'comm-recipientapp-shared';
import { setCurrentMessageDetails } from 'comm-recipientapp-shared/src/store/inbox/inboxSlice';
import { getConsistentColorFromString } from 'comm-recipientapp-shared/src/utils/avatar';
import UnReadIcon from '../../Shared/NewUI/UnReadIcon';
import DeleteDialog from '../DeleteDialog';
import { toUpperCaseFirstLetter } from '../utils';
import {
    getMessageTitle,
    getMessageContent,
    getName,
    getDateString,
    getMessageTypeString,
    getFromString,
    getTooltipContent,
    isMobileSize,
} from './utils';
import InitialsAvatar from './InitialsAvatar';
import styles from './styles';

const { ContactTypes } = utils.constants;

export default function Message({
    title = '',
    sender = '',
    messageContent = '',
    date = new Date(),
    customerId = 0,
    read = false,
    toggleReadStatus,
    needsDivider = true,
    messageId = 0,
    people = [],
    messageType = '',
    archiveItem,
    hasHtml,
    hasSms,
    hasVoice,
    smsContent,
    windowWidth = window.innerWidth,
    feedFilters,
    jobId,
}) {
    const strings = utils.useStrings();

    const _people = useMemo(() => {
        const formattedContacts = people.map(person => {
            const foundPerson = feedFilters.contacts.find(contact => contact.personId === person.personId);

            return {
                firstName: foundPerson?.firstName,
                lastName: foundPerson?.lastName,
                id: foundPerson?.personId,
            };
        });

        return formattedContacts;
    }, [people]);

    const _messageType = useMemo(() => {
        const foundMessageType = feedFilters.broadcastTypes.find(type => type.typeId === messageType);

        return foundMessageType?.name || '';
    }, [messageType]);

    const priority = useMemo(() => {
        const foundMessageType = feedFilters.broadcastTypes.find(type => type.typeId === messageType);

        return foundMessageType?.priority || 0;
    }, [messageType]);

    const { stringAvatar } = utils.avatar;

    const { language } = useSelector(state => state.user);

    const { schools } = useSelector(state => state.preferences);

    const dispatch = useDispatch();

    const [isMoreOptionsOpen, setIsMoreOptionsOpen] = useState(false);

    const [openDialog, setOpenDialog] = useState(false);

    const handleDialogOpen = () => {
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const moreOptionsButtonRef = useRef();

    const navigate = useNavigate();

    const formattedDate = useMemo(() => {
        moment.locale(language);
        const weekDay = date.toLocaleDateString(language, { weekday: 'long' });
        const weekDayStr = toUpperCaseFirstLetter(weekDay.slice(0, 3));
        const formattedStr = moment(date).format('MM/DD/YY hh:mm a');
        return `${weekDayStr}, ${formattedStr}`;
    }, [language]);

    const customerName = useMemo(() => {
        const foundSchool = schools.find(school => {
            return school.id === customerId;
        });

        if (foundSchool) {
            return foundSchool.name;
        }

        return 'Unknown';
    }, [customerId]);

    const initials = useMemo(() => {
        return stringAvatar(sender).children;
    }, [sender]);

    const availableBackgroundColorForInitials = useMemo(() => {
        return getConsistentColorFromString(initials);
    }, [initials]);

    const handleReadStatus = () => {
        toggleReadStatus(messageId);
        setIsMoreOptionsOpen(false);
    };

    const handleArchive = () => {
        archiveItem(messageId);
        setIsMoreOptionsOpen(false);
        handleDialogClose();
    };

    const getReadStatusMenuIcon = () => {
        return read ? (
            <EmailIcon color="#1E90FF" sx={styles.more_options_text_container} />
        ) : (
            <DraftsIcon color="#84B1F6" sx={styles.more_options_text_container} />
        );
    };

    const openMessageDetail = (personId, selectedType) => {
        dispatch(
            setCurrentMessageDetails({
                title,
                messageType: _messageType,
                customerName,
                sender,
                initials,
                date,
                messageContent,
                hasHtml,
                hasSms,
                hasVoice,
                messageId,
                customerId,
                people: _people,
                selectedPersonId: personId,
                selectedMessageType: selectedType,
                priority,
                windowWidth,
                jobId,
            })
        );
        navigate('/inbox/message-details');
    };

    const getReadStatusMenuText = () => {
        return read ? strings.MARK_AS_UNREAD : strings.MARK_AS_READ;
    };

    const handleOpenMessageDetail = () => {
        openMessageDetail(_people[0].id, null);
    };

    const handleOpenMessageType = selectedType => {
        openMessageDetail(_people[0].id, selectedType);
    };

    const handleEnterKeyDown = (e) => {
        if (e.key === 'Enter' && e.target.className.includes("Message")) {
            handleOpenMessageDetail();
        }
    }

    // Layout for mobile devices
    if (isMobileSize(windowWidth)) {
        return (
            <Box className={"Message"} tabIndex={0} onKeyDown={handleEnterKeyDown}>
                {/* Date Time row */}
                <Box sx={styles.message_date_container_date} data-testid="messagePreview">
                    <Box>
                        <Typography sx={styles.message_date}>{getDateString(formattedDate, windowWidth)}</Typography>
                    </Box>
                </Box>
                {/* read/unread, initial, title, content, action */}
                <Box sx={styles.container} data-testid="messagePreview" id={messageId}>
                    <Box sx={styles.avatar_container}>
                        <UnReadIcon read={read.toString()} />
                    </Box>
                    <Box sx={styles.initials_container}>
                        <Box
                            sx={{
                                ...styles.initials_box,
                                backgroundColor: availableBackgroundColorForInitials,
                            }}
                        >
                            <Typography sx={styles.initials}>{initials}</Typography>
                        </Box>
                    </Box>
                    <Box sx={styles.message_data_container} onClick={handleOpenMessageDetail}>
                        <Box>
                            <Typography sx={read ? styles.message_title : styles.message_title_bold}>
                                {getMessageTitle(title, windowWidth)}
                            </Typography>
                        </Box>
                        <Box>
                            <Box sx={{ display: 'flex', gap: '10px' }}>
                                <Typography sx={styles.message_body}>{getMessageContent(messageContent, windowWidth)}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={styles.more_options_button_container}>
                        <Tooltip title={strings.MORE_ACTIONS}>
                            <IconButton onClick={() => setIsMoreOptionsOpen(true)}>
                                <MoreHorizIcon ref={moreOptionsButtonRef} />
                            </IconButton>
                        </Tooltip>
                        <Popover
                            open={isMoreOptionsOpen}
                            anchorEl={moreOptionsButtonRef.current}
                            onClose={() => setIsMoreOptionsOpen(false)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <Box>
                                <Stack direction="column" sx={styles.more_options_stack}>
                                    <Button sx={styles.more_options_button} onClick={handleReadStatus}>
                                        {getReadStatusMenuIcon()}
                                        <Box sx={styles.more_options_text_container}>
                                            <Typography sx={styles.more_options_text}>{getReadStatusMenuText()}</Typography>
                                        </Box>
                                    </Button>
                                    <Button sx={styles.more_options_button} onClick={handleDialogOpen}>
                                        <DeleteIcon color="#535BE5" sx={styles.more_options_text_container} />
                                        <Box sx={styles.more_options_text_container}>
                                            <Typography sx={styles.more_options_text}>{strings.DELETE}</Typography>
                                        </Box>
                                    </Button>
                                    <DeleteDialog open={openDialog} handleClose={handleDialogClose} handleArchive={handleArchive} />
                                </Stack>
                            </Box>
                        </Popover>
                    </Box>
                </Box>
                {/* messageType, from, sender, from,  customerName */}
                <Box sx={styles.container_nextrow} data-testid="messagePreview2">
                    <Box sx={styles.message_details_container} onClick={handleOpenMessageDetail}>
                        <>
                            <Box sx={styles.chip_and_sender_container}>
                                <Chip
                                    sx={priority === 1 ? styles.chip_emergency : styles.chip}
                                    label={getMessageTypeString(_messageType, windowWidth)}
                                    variant="filled"
                                    size="small"
                                />{' '}
                                <Box sx={styles.sender_text_container}>
                                    <Typography sx={styles.from_connector_large_variant}>{getFromString(strings.FROM, windowWidth)}</Typography>{' '}
                                    <Typography sx={styles.sender}>{getName(sender, windowWidth)}</Typography>
                                </Box>
                            </Box>
                            <Box sx={styles.message_student_and_school_container}>
                                <Box sx={styles.from_connector_container}>
                                    <Typography sx={styles.from_connector_large_variant}>{getFromString(strings.FROM, windowWidth)}</Typography>
                                </Box>
                                <Typography sx={styles.customer_name}>{getName(customerName, windowWidth)}</Typography>
                            </Box>
                        </>
                    </Box>
                </Box>
                {/* Recipient row */}
                <Box sx={styles.container_nextrow} data-testid="messagePreview2">
                    <Box sx={styles.message_date_container}>
                        <Box>
                            {_people.map((person, i) => (
                                <InitialsAvatar
                                    person={person}
                                    totalPeople={_people.length}
                                    openMessageDetail={openMessageDetail}
                                    count={i + 1}
                                    key={i}
                                />
                            ))}
                        </Box>
                    </Box>
                </Box>

                {needsDivider && <Divider variant="fullWidth" sx={styles.divider} />}
            </Box>
        );
    }
    // Layout for non-mobile devices
    return (
        <Box className={"Message"} tabIndex={0} onKeyDown={handleEnterKeyDown}>
            <Box sx={styles.container} data-testid="messagePreview" id={messageId}>
                <Box sx={styles.avatar_container}>
                    <UnReadIcon read={read.toString()} />
                </Box>
                <Box sx={styles.initials_container}>
                    <Box
                        sx={{
                            ...styles.initials_box,
                            backgroundColor: availableBackgroundColorForInitials,
                        }}
                    >
                        <Typography sx={styles.initials}>{initials}</Typography>
                    </Box>
                </Box>
                <Box sx={styles.message_data_container} onClick={handleOpenMessageDetail}>
                    <Box>
                        <Typography sx={read ? styles.message_title : styles.message_title_bold}>{getMessageTitle(title, windowWidth)}</Typography>
                        <Box sx={{ display: 'flex', gap: '10px' }}>
                            <Typography sx={styles.message_body}>{getMessageContent(messageContent, windowWidth)}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box sx={styles.messages_icon_container}>
                    <Box sx={styles.messages_icon}>
                        {hasHtml && (
                            <Tooltip title={strings.EMAIL + ': ' + getTooltipContent(messageContent)}>
                                <EmailIcon sx={styles.msg_type_icon} onClick={() => handleOpenMessageType(ContactTypes.EMAIL)} />
                            </Tooltip>
                        )}
                        {hasVoice && (
                            <Tooltip title={strings.PHONE}>
                                <PhoneIcon sx={styles.msg_type_icon} onClick={() => handleOpenMessageType(ContactTypes.PHONE)} />
                            </Tooltip>
                        )}
                        {hasSms && (
                            <Tooltip title={strings.SMS + ': ' + getTooltipContent(smsContent)}>
                                <SmsIcon sx={styles.msg_type_icon} onClick={() => handleOpenMessageType(ContactTypes.SMS)} />
                            </Tooltip>
                        )}
                    </Box>
                </Box>
                <Box sx={styles.message_details_container} onClick={handleOpenMessageDetail}>
                    <>
                        <Box sx={styles.chip_and_sender_container}>
                            <Chip
                                sx={priority === 1 ? styles.chip_emergency : styles.chip}
                                label={getMessageTypeString(_messageType, windowWidth)}
                                variant="filled"
                                size="small"
                            />{' '}
                            <Box sx={styles.sender_text_container}>
                                <Typography sx={styles.from_connector_large_variant}>{getFromString(strings.FROM, windowWidth)}</Typography>{' '}
                                <Typography sx={styles.sender}>{getName(sender, windowWidth)}</Typography>
                            </Box>
                        </Box>
                        <Box sx={styles.message_student_and_school_container}>
                            <Box sx={styles.from_connector_container}>
                                <Typography sx={styles.from_connector_large_variant}>{getFromString(strings.FROM, windowWidth)}</Typography>
                            </Box>
                            <Typography sx={styles.customer_name}>{getName(customerName, windowWidth)}</Typography>
                        </Box>
                    </>
                </Box>
                <Box sx={styles.message_date_container}>
                    <Typography sx={styles.message_date}>{getDateString(formattedDate, windowWidth)}</Typography>
                    <Box>
                        {_people.map((person, i) => (
                            <InitialsAvatar
                                person={person}
                                totalPeople={_people.length}
                                openMessageDetail={openMessageDetail}
                                count={i + 1}
                                key={i}
                            />
                        ))}
                    </Box>
                </Box>
                <Box sx={styles.more_options_button_container}>
                    <Tooltip title={strings.MORE_ACTIONS}>
                        <IconButton onClick={() => setIsMoreOptionsOpen(true)}>
                            <MoreHorizIcon ref={moreOptionsButtonRef} />
                        </IconButton>
                    </Tooltip>
                    <Popover
                        open={isMoreOptionsOpen}
                        anchorEl={moreOptionsButtonRef.current}
                        onClose={() => setIsMoreOptionsOpen(false)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <Box>
                            <Stack direction="column" sx={styles.more_options_stack}>
                                <Button sx={styles.more_options_button} onClick={handleReadStatus}>
                                    {getReadStatusMenuIcon()}
                                    <Box sx={styles.more_options_text_container}>
                                        <Typography sx={styles.more_options_text}>{getReadStatusMenuText()}</Typography>
                                    </Box>
                                </Button>
                                <Button sx={styles.more_options_button} onClick={handleDialogOpen}>
                                    <DeleteIcon color="#535BE5" sx={styles.more_options_text_container} />
                                    <Box sx={styles.more_options_text_container}>
                                        <Typography sx={styles.more_options_text}>{strings.DELETE}</Typography>
                                    </Box>
                                </Button>
                                <DeleteDialog open={openDialog} handleClose={handleDialogClose} handleArchive={handleArchive} />
                            </Stack>
                        </Box>
                    </Popover>
                </Box>
            </Box>
            {needsDivider && <Divider variant="fullWidth" sx={styles.divider} />}
        </Box>
    );
}
