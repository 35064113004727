import React, { useMemo } from 'react';
import { Typography, Box, Divider } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { getConsistentColorFromString } from 'comm-recipientapp-shared/src/utils/avatar';
import { useStrings, helpers } from 'comm-recipientapp-shared/src/utils';
import { useUserLogins } from 'comm-recipientapp-shared/src/hooks/user';
import { resetChangedSinceLastTime } from 'comm-recipientapp-shared/src/store/initialize/initializeSlice';
import { refreshAccountInfo } from 'comm-recipientapp-shared/src/store/user/userSlice';
import { getData } from 'comm-recipientapp-shared/src/store/preferences/preferenceSlide';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../Loader';
import Button from '../Shared/NewUI/Button';
import Title from '../Shared/NewUI/Title';
import Styles from './styles';

const title = {
    fontSize: '20px',
    lineHeight: '28px',
    textAlign: 'center',
};

export default function StudentsToClaim() {
    const { customers } = useSelector(state => state.customers);
    const strings = useStrings();
    const { pathname } = useLocation();
    const { userLogins, loading } = useUserLogins();
    const dispatch = useDispatch();

    /** @type {{ dependents: [] }} */
    const initializeData = useSelector(state => state.initialize);

    const userName = useSelector(state => state.user?.account?.profile?.firstName);

    const navigate = useNavigate();

    const goToValidateEmail = () => {
        navigate('/add-new-email', { state: { goBackTo: pathname } });
    };
    const updateFlag = () => {
        dispatch(resetChangedSinceLastTime());
    };
    const handleClickYesButton = () => {
        updateFlag();
        dispatch(refreshAccountInfo());
        dispatch(getData());
        navigate('/inbox');
    };

    return (
        <>
            {loading && <Loader visible={loading} />}
            <Box>
                <Box sx={{ padding: '25px' }}>
                    <Box sx={Styles.titleBox}>
                        <Box>
                            <Title sx={title}>
                                {strings.STUDENT_CLAIMING_HEY} {userName}, {initializeData?.dependents?.length}{' '}
                                {strings.STUDENT_CLAIMING_CONTACTS_WERE_FOUND}
                            </Title>
                            <Typography sx={Styles.title}>{strings.STUDENT_CLAIMING_RECORDS}</Typography>
                        </Box>
                    </Box>
                    <Box sx={Styles.emailsBox}>
                        {userLogins.map(userLogin => (
                            <Typography key={userLogin.username} sx={Styles.emailItem}>
                                {helpers.getEmailString(userLogin.username)}
                            </Typography>
                        ))}
                    </Box>
                    <Box sx={Styles.contactList}>
                        {initializeData?.dependents?.map(({ dependent }) => {
                            const customerName = useMemo(() => {
                                return customers.find(customer => customer?.id === dependent?.customerId)?.name;
                            }, [dependent]);

                            const organizations = useMemo(() => {
                                return dependent?.organizations?.map(org => org.name).join(', ');
                            }, [dependent]);

                            const dependentInitial = useMemo(() => {
                                return dependent?.firstName.charAt(0);
                            }, [dependent]);

                            const dependentColor = useMemo(() => {
                                return getConsistentColorFromString(dependent?.firstName);
                            }, [dependent]);

                            return (
                                <Box sx={Styles.contactItem} key={dependent.personId}>
                                    <Box
                                        sx={{
                                            backgroundColor: dependentColor,
                                            width: '35px',
                                            height: '35px',
                                            display: 'flex',
                                            marginRight: '10px',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            borderRadius: '30%',
                                        }}
                                    >
                                        <Typography sx={Styles.dependentInitial}>{dependentInitial}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography sx={Styles.fullName}>
                                            {dependent.firstName} {dependent.lastName}
                                        </Typography>
                                        <Typography sx={Styles.organization}>
                                            {organizations} {organizations?.length > 0 && '|'} {customerName}
                                        </Typography>
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>

                    <Divider variant="fullWidth" sx={Styles.divider} />

                    <Typography sx={Styles.optionsText}>{strings.ARE_YOU_ASSOCIATED_WITH_THE_CONTACTS_LISTED_ABOVE}</Typography>
                    <Box sx={Styles.buttonsBox}>
                        <Box sx={Styles.buttonSecundaryBox}>
                            <Button onClick={handleClickYesButton} sx={Styles.yesButton}>
                                {strings.YES}
                            </Button>
                            <Button
                                sx={Styles.missingButton}
                                onClick={() => {
                                    updateFlag();
                                    goToValidateEmail();
                                }}
                            >
                                {strings.STUDENT_CLAIMING_MISSING_CONTACTS}
                            </Button>
                            <Button
                                sx={Styles.notMyContactsButton}
                                onClick={() => navigate('/get-in-touch-with-the-school', { state: { goBackTo: pathname } })}
                            >
                                {strings.STUDENT_CLAIMING_NOT_MY_CONTACTS}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
}
