import { Button as MaterialUIButton, styled } from '@mui/material';
import theme from '../../../config/style/muiTheme';

const Button = styled(MaterialUIButton)({
    backgroundColor: theme.palette.primary.main,
    fontWeight: '300',
    fontSize: '16px',
    textAlign: 'center',
    verticalAlign: 'top',
    padding: '8px 32px 7px 32px',
    borderRadius: '3px',
    color: 'white',
    boxShadow: 'none',
    border: `1px solid ${theme.palette.primary.main}`,
    '&:disabled': {
        border: `1px solid ${theme.palette.grey[200]}`,
        color: theme.palette.grey[500],
    },
    '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
    },
});

export default Button;
