import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import Typography from '@mui/material/Typography';
import { utils } from 'comm-recipientapp-shared';
import { handleEnterKeyPress } from 'comm-recipientapp-shared/src/utils/helpers';
import styles from './styles';

function MessageCard({ activeSchool, notification, handleMessageDetail, ...props }) {
    const strings = utils.useStrings();
    const showPhoneIcon = utils.helpers.showPhoneIcon(activeSchool);
    const showSmsIcon = utils.helpers.showSmsIcon(activeSchool, notification);
    const showEmailIcon = utils.helpers.showEmailIcon(activeSchool);

    const isPhoneActive = utils.helpers.isPhoneActive(activeSchool, notification.id);
    const isSMSActive = utils.helpers.isSMSActive(activeSchool, notification.id);
    const isEmailActive = utils.helpers.isEmailActive(activeSchool, notification.id);

    return (
        <Card
            sx={styles.message_card}
            data-testid={`message_detail_${props.index}`}
            onClick={() => handleMessageDetail(notification)}
            onKeyDown={handleEnterKeyPress(() => handleMessageDetail(notification))}
            tabIndex="0"
            aria-label={`${notification.name}, ${strings.PRESS_ENTER_TO_CHANGE_THESE_OPTIONS}`}
        >
            <Box sx={styles.message_card_bg}>
                <Typography sx={styles.message_card_title} data-testid="notification-name" variant="h5">
                    {notification.name}
                </Typography>
                <div style={styles.message_card_actions}>
                    {showPhoneIcon && (
                        // eslint-disable-next-line max-len
                        <div style={isPhoneActive ? styles.circle_active : styles.circle_inactive}>
                            <PhoneIcon fontSize="small" sx={isPhoneActive ? styles.circle_icon_active : styles.circle_icon_inactive} />
                        </div>
                    )}
                    {showSmsIcon && (
                        <div style={isSMSActive ? styles.circle_active : styles.circle_inactive}>
                            <ChatBubbleIcon fontSize="small" sx={isSMSActive ? styles.circle_icon_active : styles.circle_icon_inactive} />
                        </div>
                    )}
                    {showEmailIcon && (
                        // eslint-disable-next-line max-len
                        <div style={isEmailActive ? styles.circle_active : styles.circle_inactive}>
                            <MailIcon fontSize="small" sx={isEmailActive ? styles.circle_icon_active : styles.circle_icon_inactive} />
                        </div>
                    )}
                </div>
            </Box>
            <Box sx={styles.message_card_bottom}>
                {notification.type === utils.constants.SURVEY && (
                    <Typography sx={styles.bottom_title} variant="subtitle2">
                        {strings.SURVEYS_ARE_NOT_DISPLAYED_IN_SCHOOLMESSENGER_MESSAGES}
                    </Typography>
                )}
            </Box>
        </Card>
    );
}

export default MessageCard;
