import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, Paper, Typography, Button } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { useStrings } from 'comm-recipientapp-shared/src/utils';
import styles from './styles';

/**
 * @typedef {object} UnexplainedAbsenceProps
 * @property {import('./index').UnexplainedAbsence} absenceData
 * @property {() => void} onClick
 * @param {React.PropsWithChildren<UnexplainedAbsenceProps>} props
 */
export default function Absence(props) {
    const { absenceData, onClick } = props;

    const language = useSelector(state => state.user.language);

    const { UNEXPLAINED_ABSENCE_ABSENCE_TYPE, UNEXPLAINED_ABSENCE_ABSENCE_REASON } = useStrings();

    const dayOfTheMonth = useMemo(() => {
        return absenceData.date.start.date.split('-')[2];
    }, [absenceData]);

    const month = useMemo(() => {
        return new Date(absenceData.date.start.date).toLocaleString(language, { month: 'short', timeZone: 'UTC' });
    }, [absenceData, language]);

    return (
        <Paper sx={styles.absence_container}>
            <Button sx={styles.absence_data_container} tabIndex={0} onClick={onClick}>
                <Box sx={styles.absence_data_section}>
                    <Box sx={styles.absence_date_container}>
                        <Typography sx={styles.absence_date_day}>{dayOfTheMonth}</Typography>
                        <Typography sx={styles.absence_date_month}>{month}</Typography>
                    </Box>
                    <Box sx={styles.initial_and_name_container}>
                        <Box sx={styles.absence_initial_and_name_container}>
                            <Box
                                sx={{
                                    ...styles.absence_student_initial,
                                    backgroundColor: absenceData.assignedColor,
                                }}
                            >
                                <Typography sx={styles.absence_student_initial_text}>{absenceData.student.firstName.charAt(0)}</Typography>
                            </Box>
                            <Box>
                                <Typography sx={styles.student_name}>{absenceData.student.firstName}</Typography>
                                <Typography sx={styles.unexplained_message}>{UNEXPLAINED_ABSENCE_ABSENCE_TYPE}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Box sx={styles.reason_message}>
                    <Box sx={styles.attention_message_container}>
                        <ErrorIcon htmlColor={styles.attention_icon_color.color} />
                        <Typography sx={styles.attention_message}>{UNEXPLAINED_ABSENCE_ABSENCE_REASON}</Typography>
                    </Box>
                </Box>
            </Button>
        </Paper>
    );
}
