/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable prettier/prettier */
/* eslint-disable operator-linebreak */
import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';
import { utils } from 'comm-recipientapp-shared';
import styles from './styles';

const { ContactOrigin } = utils.constants;

function ModalErrorNotAllowToDelete({ visible, handleClose }) {
    const strings = utils.useStrings();

    return (
        <Dialog maxWidth="sm" open={Boolean(visible)} onClose={handleClose}>
            <DialogTitle sx={styles.dialogHeader}>
                <WarningIcon />
                <Typography sx={styles.dialogTitle}>{strings.WARNING}</Typography>
            </DialogTitle>
            <DialogContent style={styles.modalContent}>
                <Typography style={styles.errorLegend}>
                    {visible === ContactOrigin.IMPORTED
                        ? strings.CANNOT_DELETE_IMPORTED_CONTACT_INFORMATION
                        : strings.CANNOT_DELETE_NON_IMPORTED_CONTACT_INFORMATION}
                </Typography>
               <Button
                    color="primary"
                    data-testid="button-login"
                    fullWidth
                    sx={styles.button_ok}
                    onClick={handleClose}
                    variant="contained"
                >
                    {strings.OK}
                </Button>
            </DialogContent>
        </Dialog>
    )
}

export default ModalErrorNotAllowToDelete;
