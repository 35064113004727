import React from 'react';

export default function Read(props) {
    return (
        <svg data-icon="read" xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
            <path
                d="M12 5.25C4.5 5.25 1.5 12 1.5 12s3 6.75 10.5 6.75S22.5 12 22.5 12s-3-6.75-10.5-6.75Z"
                fill="#A1A4F1"
                stroke="#33366A"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 15.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5Z"
                fill="#fff"
                stroke="#33366A"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
