/* eslint-disable import/prefer-default-export */
import { store } from '../../store';
import { WEB_CLIENT_HEADER, MOBILE_CLIENT_HEADER } from '../../utils/constants';
import HTTP, { HTTPPhoneVerificationServer } from '../HTTP';
import env from '../../env';

import { GET_RECIPIENT_SERVICE_PHONE_VERIFICATION } from '../constants';

import { environments } from '../../utils/environments';

const isMobile = env.IS_MOBILE !== 'false';

export const updateUserContactPreferences = (newPreferences, customerId) =>
    HTTP.put(`customers/${customerId}/destinations/preferences`, newPreferences, {
        headers: {
            'X-App-Client': WEB_CLIENT_HEADER,
        },
    });

/**
 * @param {string} phoneNumber
 * @param {number} customer
 * @param {'call' | 'sms'} channel
 */
export const sendCodeForVerififcation = async (phone, customerId, channel) => {
    let url = '';
    if (isMobile) {
        const state = store?.getState();
        const userLocation = state?.user?.location;
        const baseURL = GET_RECIPIENT_SERVICE_PHONE_VERIFICATION(userLocation.toLowerCase());
        url = `${baseURL}/verifications/phone`;
    } else {
        url = 'verifications/phone';
    }

    return HTTPPhoneVerificationServer.post(url, { customerId, channel, phone });
};

/**
 * @param {string} phoneNumber
 * @param {number} customer
 * @param {string} code
 */
export const verifyCode = async (phone, customerId, code) => {
    let url = '';
    if (isMobile) {
        const state = store?.getState();
        const userLocation = state?.user?.location;
        const baseURL = GET_RECIPIENT_SERVICE_PHONE_VERIFICATION(userLocation.toLowerCase());
        url = `${baseURL}/verifications/phone`;
    } else {
        url = 'verifications/phone';
    }

    return HTTPPhoneVerificationServer.put(url, { customerId, code, phone });
};

/**
 * Receive a range of dates with the following format YYYY-MM-DD
 *
 * @param {string} startDate
 * @param {string} endDate
 * @returns
 */
// eslint-disable-next-line import/prefer-default-export
export const getCustomers = () =>
    HTTP.get(`customers/?expansions=settings,notificationtypes,destinationpreferences&timestamp=${new Date().getTime()}`);

export const updatePreferences = (customerId, requestBody, platform) => {
    const clientHeader = isMobile ? MOBILE_CLIENT_HEADER[platform] : WEB_CLIENT_HEADER;
    return HTTP.put(`customers/${customerId}/destinations/preferences`, requestBody, {
        headers: {
            'X-App-Client': clientHeader,
        },
    });
};

/** @param {number} customerId */
export const getVerifiedEmails = async customerId => {
    let url = '';
    if (isMobile) {
        const state = store?.getState();
        const userLocation = state?.user?.location;
        const baseURL = GET_RECIPIENT_SERVICE_PHONE_VERIFICATION(userLocation.toLowerCase());
        url = `${baseURL}/verifications/email?customerId=${customerId}`;
    } else {
        url = `verifications/email?customerId=${customerId}`;
    }

    return HTTPPhoneVerificationServer.get(url);
};

/**
 * @param {string} email
 * @param {number} customerId
 */
export const sendEmailVerificationCode = async (email, customerId) => {
    let url = '';
    if (isMobile) {
        const state = store?.getState();
        const userLocation = state?.user?.location;
        const baseURL = GET_RECIPIENT_SERVICE_PHONE_VERIFICATION(userLocation.toLowerCase());
        url = `${baseURL}/verifications/email`;
    } else {
        url = `verifications/email`;
    }

    return HTTPPhoneVerificationServer.post(url, { customerId, email });
};

/**
 * @param {string} email
 * @param {number} customerId
 * @param {string} code
 */
export const verifyEmailCode = async (email, customerId, code) => {
    let url = '';
    if (isMobile) {
        const state = store?.getState();
        const userLocation = state?.user?.location;
        const baseURL = GET_RECIPIENT_SERVICE_PHONE_VERIFICATION(userLocation.toLowerCase());
        url = `${baseURL}/verifications/email`;
    } else {
        url = `verifications/email`;
    }

    return HTTPPhoneVerificationServer.put(url, { customerId, code, email });
};

/** @param {string} username */
export const sendMultiEmailVerificationCode = async username => {
    let url = '';
    if (isMobile) {
        const state = store?.getState();
        const userLocation = state?.user?.location;
        const baseURL = GET_RECIPIENT_SERVICE_PHONE_VERIFICATION(userLocation.toLowerCase());
        url = `${baseURL}/portal/users/email`;
    } else {
        url = `portal/users/email`;
    }

    return HTTPPhoneVerificationServer.post(url, { username });
};

/** @param {string} code */
export const sendCodeMultiEmailVerificationCode = async code => {
    let url = '';
    if (isMobile) {
        const state = store?.getState();
        const userLocation = state?.user?.location;
        const baseURL = GET_RECIPIENT_SERVICE_PHONE_VERIFICATION(userLocation.toLowerCase());
        url = `${baseURL}/portal/users/email`;
    } else {
        url = `portal/users/email`;
    }

    return HTTPPhoneVerificationServer.put(url, { code });
};

export const getSchoolById = id => HTTP.get(`customers/${id}?expansions=settings,notificationtypes,destinationpreferences`);

export const smsOptOut = ({ phone, countrycode }) => HTTPPhoneVerificationServer.post('sms/optout', { phone, countrycode });

export const getSMSActions = language => HTTPPhoneVerificationServer.get(`sms/actions?language=${language}`);

export const updateOneWaySMSSupportPreferences = ({ language, accessCode, action }) =>
    HTTPPhoneVerificationServer.post(`sms/action`, { accesscode: accessCode, action, language });
