import theme from '../../../config/style/muiTheme';

export default {
    container: {
        position: "relative",
        gap: "12px",
    },
    button: {
        background: '#f9f9f9',
        gap: "6px",
        "&:hover": {
            outline: `1px solid ${theme.palette.grey['300']}`,
        },
        
    },
    activeButton: {
        background: '#E5EFFA',
        color: theme.palette.purple['80'],
        "&:hover": {
            outline: `1px solid ${theme.palette.grey['300']}`,
            background: '#E5EFFA',
        }
    },
    typography: {
        alignSelf: 'center',
        textDecoration: 'none',
        display: {
            xs: 'none',
            sm: 'none',
            md: 'block',
            lg: 'block',
        },
        fontSize: {
            xs: '12px',
            sm: '14px',
            md: '16px',
            lg: '16px',
        },
        fontWeight: 500,
        color: theme.palette.purple['80'],
        fontStyle: 'normal',
        fontFamily: 'Inter',
    },
    badge: {
        margin: '0',
        padding: '0',
        content: '""',
        position: 'absolute',
        top: '-5px',
        right: '-5px',
        width: '13.5px',
        height: '13.5px',
        borderRadius: '50%',
        background: 'red',
        
    }
}
