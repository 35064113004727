/* eslint-disable prettier/prettier */
import React from 'react';
import {
    Box,
    Typography,
} from "@mui/material";
import chatLogo from 'comm-recipientapp-shared/src/assets/images/conversation.svg';
import { utils } from 'comm-recipientapp-shared';
import styles from './styles';

function DefaultOverlay () {
    const strings = utils.useStrings();

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="60vh"
            sx={styles.containerBox}
            >
            <Box
                component="img"
                src={chatLogo}
                sx={styles.logoBox}
                alt={strings.SORRY_NO_CONVERSATIONS_WERE_FOUND}
            />
            <Box>
                <Typography
                    variant="h6"
                    sx={styles.description}>
                    {strings.SORRY_NO_CONVERSATIONS_WERE_FOUND}
                </Typography>
            </Box>
        </Box>
    );
}

export default DefaultOverlay;

