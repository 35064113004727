/* eslint-disable import/prefer-default-export */
import env from '../env';

export const environments = {
    us: {
        recipientService: {
            devsa: env.RECIPIENT_SERVICE_URL_DEVSA,
            stage: env.RECIPIENT_SERVICE_URL_STAGEUS,
            prod: env.RECIPIENT_SERVICE_URL_PRODUS,
            label: 'recipientService',
            apiBaseUrl: env.RECIPIENT_SERVICE_URL,
        },
        rocketChatUrl: {
            apiBaseUrl: env.ROCKET_CHAT_IFRAME_URL_DEVSA,
            devsa: env.ROCKET_CHAT_IFRAME_URL_DEVSA,
            stage: env.ROCKET_CHAT_IFRAME_URL_STAGEUS,
            prod: env.ROCKET_CHAT_IFRAME_URL_PRODUS,
        },
    },
    ca: {
        recipientService: {
            devsa: env.RECIPIENT_SERVICE_URL_DEVSA,
            stage: env.RECIPIENT_SERVICE_URL_STAGECA,
            prod: env.RECIPIENT_SERVICE_URL_PRODCA,
            label: 'recipientService',
            apiBaseUrl: env.RECIPIENT_SERVICE_URL,
        },
        rocketChatUrl: {
            devsa: env.ROCKET_CHAT_IFRAME_URL_DEVSA,
            stage: env.ROCKET_CHAT_IFRAME_URL_STAGEUS,
            prod: env.ROCKET_CHAT_IFRAME_URL_PRODCA,
        },
    },
};
