import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { utils } from 'comm-recipientapp-shared';
import styles from '../styles';

/**
 * @typedef {object} ExamOrHolidayPopupProps
 * @property {{ reason: string; students: Array; type: string }} popupData
 * @property {string} popupType
 * @param {React.PropsWithChildren<ExamOrHolidayPopupProps>} props
 */
export default function ExamOrHolidayPopup({ popupData, popupType, handleClose }) {
    const { ABSENCE_CONNECTOR_FOR } = utils.useStrings();
    const { stringAvatar } = utils.avatar;

    return (
        <Box style={styles.popup_exam_or_holiday_container}>
            <Box sx={styles.holiday_or_exam__title_container}>
                <Typography style={styles.popup_exam_or_holiday_reason}>
                    {popupData.reason} {ABSENCE_CONNECTOR_FOR}:
                </Typography>
                <IconButton sx={styles.close_icon} onClick={handleClose}>
                    <CloseIcon sx={styles.popup_close_button} />
                </IconButton>
            </Box>

            <Box style={styles.popup_exam_or_holiday_content}>
                {popupData.students.map((student, i) => {
                    return (
                        <Box key={`student-data-${popupType}-${i}`} style={styles.popup_exam_or_holiday_students_container}>
                            <Box sx={styles.student_names_container}>
                                <Box
                                    sx={{
                                        ...styles.student_initial,
                                        backgroundColor: student.assignedColor,
                                    }}
                                >
                                    {stringAvatar(`${student?.firstName || ' '} ${student?.lastName || ' '}`).children}
                                </Box>
                                <Box>
                                    <Typography sx={styles.student_names}>
                                        {student.firstName} {student.lastName}
                                    </Typography>
                                    <Typography sx={styles.student_org_name}>{student.organizationName}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
}
