import theme from '../../config/style/muiTheme'
const styles = {
    main_container: {
        padding: '0 10px'
    },
    text: {
        fontWeight: '200'
    },
    container: {
        margin: '50px auto',
        maxWidth: '1200px',
        background: '#fff',
        border: '1px solid #ccc',
        boxShadow: '0 2px 3px rgb(0 0 0 / 15%)',
        padding: '50px',
    },
    section: {
        margin: '20px 0',
    },
    divider: {
        margin: '8px 0',
    },
    title: {
        fontSize: '20px',
        fontWeight: '500',
        lineHeight: '28px',
        color: theme.palette.primary.dark,
        margin: '0px',
        marginBottom: '10px',
    },
};

export default styles;
