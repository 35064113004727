import React, { useMemo } from 'react';
import { Button, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { utils } from 'comm-recipientapp-shared';
import styles from './styles';

const { StudentButtonStyleStates } = utils.constants;

/**
 * @typedef {object} StudentButtonProps
 * @property {(personId: string, customerId: string, organizationName: string, organizationId: number) => void} handleStudentFilterChange
 * @property {{ personId: string; customerId: string; organizationName: string }} currentStudentSelected
 * @property {{
 *     personId: string;
 *     customerId: string;
 *     organizationName: string;
 *     firstName: string;
 * }} student
 * @property {Map<string, string>} studentAssignedColors
 * @property {Array} students
 * @param {React.PropsWithChildren<StudentButtonProps>} props
 */
export default function StudentButton(props) {
    const { currentStudentSelected, handleStudentFilterChange, student, students, studentAssignedColors } = props;

    // if the button state is equal to 'contained' that means that is the current student selected
    const buttonState = useMemo(
        () =>
            currentStudentSelected?.personId === student.personId &&
            currentStudentSelected.organizationName === student.organizationName &&
            currentStudentSelected?.customerId === student.customerId
                ? StudentButtonStyleStates.SELECTED
                : StudentButtonStyleStates.UNSELECTED,
        [currentStudentSelected, student]
    );

    const isDuplicated = useMemo(() => {
        // check if the current student exist with a different organization name
        const duplicatedStudent = students.find(
            studentToCompare =>
                studentToCompare.personId === student.personId &&
                studentToCompare.organizationName !== student.organizationName &&
                studentToCompare.customerId === student.customerId
        );

        return duplicatedStudent !== undefined;
    }, [student, students]);

    const studentColor = useMemo(() => studentAssignedColors.get(`${student.personId}-${student.customerId}`), [student, studentAssignedColors]);

    return (
        <Button
            onClick={() => {
                handleStudentFilterChange(student.personId, student.customerId, student.organizationName, student.organizationId);
            }}
            variant={buttonState}
            sx={{
                ...styles.studentButton,
                backgroundColor:
                    buttonState === StudentButtonStyleStates.SELECTED ? studentColor : StudentButtonStyleStates.UNSELECTED_BACKGROUND_COLOR,
                color: buttonState === StudentButtonStyleStates.SELECTED ? StudentButtonStyleStates.SELECTED_TEXT_COLOR : styles.studentButton.color,
                '&:hover': {
                    backgroundColor:
                        buttonState === StudentButtonStyleStates.SELECTED ? studentColor : StudentButtonStyleStates.UNSELECTED_BACKGROUND_COLOR,
                },
            }}
            data-testid="student-button"
        >
            {student.firstName}
            {isDuplicated && buttonState === StudentButtonStyleStates.UNSELECTED && <InfoIcon sx={styles.studentButtonInfoIcon} />}
            {isDuplicated && buttonState === StudentButtonStyleStates.SELECTED && (
                <Typography sx={styles.studentButtonTypography}>{student.organizationName}</Typography>
            )}
        </Button>
    );
}
