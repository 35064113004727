/* eslint-disable no-restricted-syntax */
import React, { useMemo, useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Dialog, Typography } from '@mui/material';
import { PENDING } from 'comm-recipientapp-shared/src/utils/constants';
import { useStrings } from 'comm-recipientapp-shared/src/utils';
import { updatePreferences } from 'comm-recipientapp-shared/src/api/preferences';
import { setConsentPopupShowed } from 'comm-recipientapp-shared/src/store/consent/consentPopupSlice';
import { getData } from 'comm-recipientapp-shared/src/store/preferences/preferenceSlide';
import { handlePhonePriorityEnforcement } from 'comm-recipientapp-shared/src/utils/preferencesUtils';
import Button, { WithLoader } from '../Shared/NewUI/Button';
import ConsentPendingPhone from './ConsentPendingPhone';
import styles from './styles';

/** @typedef {'yes' | 'no' | 'pending'} Consent */

/**
 * @typedef {{
 *     canDelete: boolean;
 *     isImported: boolean;
 *     notificationTypes: number[];
 *     phone: string;
 *     registry: { consent: Consent; active: boolean };
 * }} Phone
 */

export default function ConsentPeding({ setShowedConsentPeding }) {
    const schools = useSelector(state => state.preferences?.schools);

    const hasContentPopupShowed = useSelector(state => state.consent.hasContentPopupShowed);

    const dispatch = useDispatch();

    /** @type {React.MutableRefObject<Map<string, { newValue: string; school: {} }>>} */
    const newConsentForNumbers = useRef(new Map());

    const strings = useStrings();

    const [loading, setLoading] = useState(false);

    const phonesWihtoutConsent = useMemo(() => {
        const numbersFromAllSchools = [];

        if (schools?.length < 1) {
            return [];
        }

        for (const school of schools) {
            for (const phone of school?.destinationPreferences?.phones) {
                if (phone.registry?.consent === PENDING) {
                    numbersFromAllSchools.push({ phone, school });
                }
            }
        }

        return numbersFromAllSchools;
    }, [schools]);

    const [isDialogOpen, setIsDialogOpen] = useState(phonesWihtoutConsent.length > 0 && !hasContentPopupShowed);

    useEffect(() => {
        if (!isDialogOpen) {
            setShowedConsentPeding(true);
        }
        setTimeout(() => {
            if (isDialogOpen) {
                dispatch(setConsentPopupShowed(true));
            }
        }, 1000);
    }, []);

    if (phonesWihtoutConsent.length < 1) {
        return null;
    }

    /** @type {(newPhoneConsent: { newValue: string; phone: string; school: {} }) => void} */
    const onChange = ({ newValue, phone, school }) => {
        newConsentForNumbers.current.set(phone, { newValue, school });
    };

    const updateConsentForNumbers = async () => {
        try {
            setLoading(true);
            const promises = [];

            /**
             * A map that will contain the customer(school) id as key and an array of numbers to update the consent as value
             *
             * @type {Map<number, Phone[]>}
             */
            const customersMap = new Map();

            // map the changed consent to a phone object and add it to the customers map
            newConsentForNumbers.current.forEach((v, k) => {
                const phonesArray = v.school.destinationPreferences.phones;

                const phone = phonesArray.find(({ phone: phoneFound }) => phoneFound === k);

                /** @type Phone */
                const newPhone = { ...phone, registry: { ...phone.registry, consent: v.newValue } };

                const schoolId = v.school.id;

                if (customersMap.has(schoolId) && Array.isArray(customersMap.get(schoolId))) {
                    customersMap.get(schoolId).push(newPhone);
                } else {
                    customersMap.set(schoolId, []);
                    customersMap.get(schoolId).push(newPhone);
                }
            });

            // assign the new phone data to a new array with the old phones as well
            Array.from(customersMap.keys()).forEach(customerId => {
                // eslint-disable-next-line radix
                const schoolFound = schools.find(school => school.id === parseInt(customerId));

                const phonesFromSchool = schoolFound.destinationPreferences.phones;

                const phonesToUpdate = customersMap.get(customerId);

                const updatedPhonesArray = [...phonesFromSchool];

                phonesToUpdate.forEach(phone => {
                    const phoneIndex = updatedPhonesArray.findIndex(p => p.phone === phone.phone);

                    updatedPhonesArray[phoneIndex] = phone;
                });

                customersMap.set(customerId, updatedPhonesArray);

                const currentPreferences = schoolFound.destinationPreferences;

                const newPreferences = { ...currentPreferences, phones: customersMap.get(customerId) };

                promises.push(updatePreferences(customerId, newPreferences));
            });

            await Promise.all(promises);
            setLoading(false);
            setIsDialogOpen(false);
            setShowedConsentPeding(true);
            const { data } = await dispatch(getData());
            handlePhonePriorityEnforcement(data, 'web');
        } catch (error) {
            setLoading(false);
        }
    };

    return (
        <Dialog open={isDialogOpen} fullWidth>
            <Box sx={styles.popup_header}>
                <Typography>{strings.CONSENT_POPUP_HEADER}</Typography>
            </Box>

            <Box sx={{ padding: '20px' }}>
                <Typography sx={{ ...styles.text, textAlign: 'left' }}>{strings.CONSENT_POPUP_DESCRIPTION}</Typography>

                {phonesWihtoutConsent.map(({ phone, school }, i) => {
                    // eslint-disable-next-line react/no-array-index-key
                    return <ConsentPendingPhone key={`phone-${i}`} phone={phone.phone} school={school} onChange={onChange} />;
                })}

                <Typography variant="body2" sx={styles.text}>
                    {strings.CONSENT_POPUP_CONFIRM_MESSAGE}
                </Typography>

                <Box sx={styles.buttons_container}>
                    <Button
                        sx={styles.cancel_button}
                        onClick={() => {
                            newConsentForNumbers.current.clear();
                            setShowedConsentPeding(true);
                            setIsDialogOpen(false);
                        }}
                    >
                        {strings.CLOSE}
                    </Button>
                    <WithLoader sx={styles.save_button} onClick={updateConsentForNumbers} loading={loading}>
                        {strings.SAVE}
                    </WithLoader>
                </Box>
            </Box>
        </Dialog>
    );
}
