import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ListIcon from '@mui/icons-material/List';
import { utils } from 'comm-recipientapp-shared';
import styles from '../styles';

const { Views } = utils.constants;

/**
 * @typedef {object} ChangeViewSectionProps
 * @property {(viewType: string) => void} handleViewChange
 * @param {React.PropsWithChildren<ChangeViewSectionProps>} props
 */
export default function ChangeViewSection({ handleViewChange }) {
    const [selectedButton, setSelectedButton] = useState(Views.MONTH);

    return (
        <Box sx={styles.change_calendar_view_container}>
            <Box sx={styles.change_calendar_view_buttons_container}>
                <Button
                    sx={styles.change_calendar_view_button_element}
                    onClick={() => {
                        handleViewChange(Views.MONTH);
                        setSelectedButton(Views.MONTH);
                    }}
                    disableTouchRipple
                    data-testid='calendar-button'
                >
                    <CalendarTodayIcon
                        htmlColor={
                            selectedButton === Views.MONTH
                                ? styles.change_calendar_view_buttons_state.unselected
                                : styles.change_calendar_view_buttons_state.selected
                        }
                        sx={
                            selectedButton === Views.MONTH
                                ? {
                                    ...styles.change_calendar_view_buttons,
                                    ...styles.change_calendar_view_buttons_selected,
                                    ...{
                                        '&:hover': styles.change_calendar_view_buttons_selected,
                                    },
                                }
                                : styles.change_calendar_view_buttons
                        }
                    />
                </Button>
                <Button
                    sx={styles.change_calendar_view_button_element}
                    onClick={() => {
                        handleViewChange(Views.LIST);
                        setSelectedButton(Views.LIST);
                    }}
                    disableTouchRipple
                    data-testid='list-button'
                >
                    <ListIcon
                        htmlColor={
                            selectedButton === Views.LIST
                                ? styles.change_calendar_view_buttons_state.unselected
                                : styles.change_calendar_view_buttons_state.selected
                        }
                        sx={
                            selectedButton === Views.LIST
                                ? {
                                    ...styles.change_calendar_view_buttons,
                                    ...styles.change_calendar_view_buttons_selected,
                                    ...{
                                        '&:hover': styles.change_calendar_view_buttons_selected,
                                    },
                                }
                                : styles.change_calendar_view_buttons
                        }
                    />
                </Button>
            </Box>
        </Box>
    );
}
