import { styled } from '@mui/material';
import theme from '../../../../config/style/muiTheme';

const Styles = {
    OtpInputContainer: {
        margin: '12px',
        justifyContent: 'center',
    },
    OtpInputInput: {
        marginRight: '16px',
        width: '48px',
        height: '48px',
        padding: '8px 16px 10px 16px',
        textAlign: 'center',
        '&:focus-visible': {
            border: `2px solid ${theme.palette.purple['55']}`,
            backgroundColor: theme.palette.grey['30'],
        },
        borderRadius: '6px',
        border: `1px solid ${theme.palette.purple['32']}`,
        backgroundColor: theme.palette.common.white,
        color: theme.palette.purple['80'],
        fontFamily: 'Inter',
        fontSize: '18px;0',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '140%',
        letterSpacing: '-0.36px',
    },
};

const StyledInput = styled('input')(Styles.OtpInputInput);

export default Styles;

export { StyledInput };
