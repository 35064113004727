import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Box,
    Typography,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useStrings } from 'comm-recipientapp-shared/src/utils';
import { PhoneNumberVerificationMethods } from 'comm-recipientapp-shared/src/utils/constants';
import phoneSearchImage from 'comm-recipientapp-shared/src/assets/phone-search.png';
import Button from '../../Shared/Button';
import styles, { MaskedStyledInput } from './styles';


export default function MissingStudent({ handleClose }) {
    const [phone, setPhone] = useState('');
    const [verificationMethod, setVerificationMethod] = useState('');

    const {
        PREFERENCES_VERIFY_NUMBER_CALL_OPTION,
        PREFERENCES_VERIFY_NUMBER_SMS_OPTION,
        PREFERENCES_VERIFY_NUMBER_SEND_CODE_BUTTON,
        PREFERENCES_VERIFY_NUMBER_CANCEL_BUTTON,
        CONTACTS_MISSING_SECTION_MAIN_DESCRIPTION,
        CONTACTS_MISSING_SECTION_SECONDARY_DESCRIPTION,
        CONTACTS_MISSING_SECTION_BANNER_TITLE,
    } = useStrings();

    const matches = useMediaQuery('(max-width:420px)');

    const { language } = useSelector(state => state.user);

    const userEmail = useSelector(state => state.auth.auth.sub);

    const mainDescriptionSplitted = useMemo(
        () => CONTACTS_MISSING_SECTION_MAIN_DESCRIPTION.split('/'),
        [language]
    );

    return (
        <>
            <Box sx={styles.banner}>
                <Box sx={styles.image_and_banner_title_container}>
                    <img
                        src={phoneSearchImage}
                        alt="phone_search_img"
                        style={{
                            ...{
                                marginTop: matches ? '25px' : '12px',
                                marginLeft: matches ? '15px' : '10px',
                            },
                            ...styles.banner_image,
                        }}
                    />
                    <Typography sx={styles.banner_text}>
                        {CONTACTS_MISSING_SECTION_BANNER_TITLE}
                    </Typography>
                </Box>
            </Box>

            <Box sx={styles.description_container}>
                <Typography sx={styles.description}>
                    {mainDescriptionSplitted[0]} <b>{userEmail}</b>. {mainDescriptionSplitted[1]}.
                </Typography>
                <br />
                <Typography sx={styles.description}>
                    {CONTACTS_MISSING_SECTION_SECONDARY_DESCRIPTION}
                </Typography>
            </Box>
            <Box sx={styles.input_container}>
                <MaskedStyledInput
                    mask="(#00) 000-0000"
                    definitions={{
                        '#': /[0-9]/,
                    }}
                    placeholderChar="_"
                    onAccept={(_, imaskRef) => {
                        setPhone(imaskRef.unmaskedValue);
                    }}
                    lazy={false}
                    sx={{ fontSize: '22px' }}
                />
            </Box>
            <Box sx={styles.verification_radio_container}>
                <FormControl onChange={e => setVerificationMethod(e.target.value)}>
                    <RadioGroup name="verification-group">
                        <FormControlLabel
                            value={PhoneNumberVerificationMethods.CALL}
                            control={<Radio />}
                            label={
                                <Typography sx={styles.verification_radio_label}>
                                    {PREFERENCES_VERIFY_NUMBER_CALL_OPTION}
                                </Typography>
                            }
                        />
                        <FormControlLabel
                            value={PhoneNumberVerificationMethods.SMS}
                            control={<Radio />}
                            label={
                                <Typography sx={styles.verification_radio_label}>
                                    {PREFERENCES_VERIFY_NUMBER_SMS_OPTION}
                                </Typography>
                            }
                        />
                    </RadioGroup>
                </FormControl>
            </Box>
            <Box sx={styles.buttons_container}>
                <Button
                    sx={styles.cancel_button}
                    onClick={handleClose}
                    variant="contained"
                >
                    {PREFERENCES_VERIFY_NUMBER_CANCEL_BUTTON}
                </Button>
                <Button disabled={!(verificationMethod.length > 1)} variant="contained">
                    {PREFERENCES_VERIFY_NUMBER_SEND_CODE_BUTTON}
                </Button>
            </Box>
        </>
    );
}
