import { getFeedFilters } from 'comm-recipientapp-shared/src/api/feed';
import { useQuery } from 'react-query';

export default function useFeedFilters() {
    const feedFilters = useQuery({
        queryKey: 'feedFilters',
        queryFn: getFeedFilters,
        initialData: {
            broadcastTypes: [],
            contacts: [],
            from: [],
        },
    });

    return feedFilters;
}
