import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Typography, Container, Stack, Button } from '@mui/material';
import { getTokenInfo, confirmEmail } from 'comm-recipientapp-shared/src/api/token';
import { useStrings, constants } from 'comm-recipientapp-shared/src/utils';
import Layout from '../Layout';
import Styles from './styles';

export default function AccountActivation() {
    const navigate = useNavigate();
    const location = useLocation();
    const strings = useStrings();
    const [showInitialMessage, setShowInitialMessage] = React.useState(true);
    const [errorMessage, setErroMessage] = React.useState(null);
    const [showConfirmMessage, setShowConfirmMessage] = React.useState(false);

    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    const goToLogin = () => {
        navigate('/login');
    };
    const goToHome = () => {
        navigate('/');
    };

    const goToResetPassword = () => {
        navigate(`/account/resetpassword?token=${token}`);
    };

    const handleConfirmEmailFromToken = async tokenRetrievedFromAPI => {
        try {
            await confirmEmail(tokenRetrievedFromAPI);
            setShowConfirmMessage(true);
        } catch (error) {
            if (error?.response?.status === 400) {
                setErroMessage(strings.TOKEN_IS_INVALID);
            } else {
                setErroMessage(strings.UNEXPECTED_ERROR);
            }
            setShowConfirmMessage(false);
        } finally {
            setShowInitialMessage(false);
        }
    };

    const handleTokenInfoResponse = response => {
        if (response.data.purpose === constants.EMAIL_CONFIRMATION) {
            handleConfirmEmailFromToken(response.data.token);
        }
        if (response.data.purpose === constants.FORGOT_PASSWORD) {
            goToResetPassword();
        } else {
            goToHome();
        }
    };

    const processTokenWithAPIService = async () => {
        try {
            const response = await getTokenInfo(token);
            handleTokenInfoResponse(response);
        } catch (error) {
            setShowInitialMessage(false);
            setShowConfirmMessage(false);
            if (error?.response?.status === 404) {
                setErroMessage(strings.TOKEN_IS_INVALID);
            } else {
                setErroMessage(strings.UNEXPECTED_ERROR);
            }
        }
    };

    React.useEffect(() => {
        if (!token) {
            goToHome();
            return;
        }
        processTokenWithAPIService();
    }, []);

    return (
        <Layout>
            <Container maxWidth="md" sx={Styles.content}>
                {showInitialMessage && <Typography sx={Styles.TypographyProcessingToken}>{strings.PROCESS_TOKEN}</Typography>}
                {errorMessage && <Typography sx={Styles.TypographyErrorMessage}>{errorMessage}</Typography>}
                {showConfirmMessage && (
                    <Stack>
                        <Typography sx={Styles.TypographyProcessingToken}>{strings.VERIFICATION_COMPLETED}</Typography>
                        <Button variant="contained" onClick={goToLogin} sx={Styles.goToLoginButton}>
                            {strings.GO_TO_LOGIN}
                        </Button>
                    </Stack>
                )}
            </Container>
        </Layout>
    );
}
