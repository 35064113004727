import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactGA from 'react-ga4';
import publicRoutes from '../../config/routes/public';
import privateRoutes from '../../config/routes/private';
import { env } from 'comm-recipientapp-shared';

const initializeGA = () => {
    ReactGA.initialize(env.GA_MEASUREMENT_ID);
};

const sendPageView = (hashLocation, title) => {
    ReactGA.send({
        hitType: "pageview",
        page: hashLocation,
        title
    });
};

export default function GoogleAnalytics() {
    const hashLocation = useSelector(state => state.user.hashLocation);
    
    const createSimplePath = (path) => {
        const pathString = path || '';
        const pathParts = pathString.split('/');
        return pathParts.length > 1 ? pathParts[1] : '';
    };

    const createSimpleRoutes = () => {
        const allRoutes = publicRoutes.concat(privateRoutes);
        const simpleRoutes = allRoutes.map(route => {
            const simplePath = createSimplePath(route.path);
            return {
                path: simplePath,
                title: route.title
            };    
        });
        return simpleRoutes;
    };

    let simpleRoutes = createSimpleRoutes();

    useEffect(() => {
        initializeGA();
    }, []);

    useEffect(() => {
        const simpleHashLocation = createSimplePath(hashLocation)
        const foundRoute = simpleRoutes.find(route => route.path === simpleHashLocation);
        if (foundRoute) 
            sendPageView(hashLocation, foundRoute.title);
        
    }, [hashLocation])

    return null;
}
