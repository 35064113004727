/* eslint-disable prettier/prettier */
/* eslint-disable import/prefer-default-export */
const primaryPurple = '#33366A';
const primaryBlue = '#00B5E2';
const primaryGreen = '#8EC853';
const secondary = '#F57F29';
const silver = '#AFC5CD';

// see the default palette and exposed props here:
// https://material-ui.com/customization/default-theme/?expand-path=$.palette
export const palette = {
    common: {
        black: "#000000",
        white: "#ffffff",
        gray: "#F2F7FA",
    },
    primary: {
        main: primaryPurple,
        light: `${primaryPurple}50`,
        lights: {
            '10': `${primaryPurple}10`,
            '20': `${primaryPurple}20`,
            '30': `${primaryPurple}30`
        },
        dark: "#461b71",
        blue: primaryBlue,
        green: primaryGreen,
        greenDark: '#6fa23a',
        contrastText: "#fff"
    },
    secondary: {
        main: secondary,
        light: `${secondary}50`,
        dark: secondary,
        contrastText: "#fff",
        blue: `${primaryBlue}80`,
        green: `${primaryGreen}80`,
        silver
    },
    error: {
        light: "#e57373",
        main: "#d32f2f",
        dark: "#d32f2f",
        contrastText: "#fff",
        translucent: "#e5737320",
        background: '#f4433610',
        text: '#611a15',
        iconColor: '#f44336',
    },
    warning: {
        light: "#ffb74d",
        main: "#ff9800",
        dark: "#f57c00",
        background: '#ff980010',
        contrastText: "rgba(0, 0, 0, 0.87)"
    },
    info: {
        light: "#64b5f6",
        main: "#2196f3",
        dark: "#1976d2",
        contrastText: "#fff"
    },
    success: {
        light: "#81c784",
        main: "#4caf50",
        dark: "#1669BB",
        contrastText: "rgba(0, 0, 0, 0.87)",
        translucent:'#4caf5020',
        background: '#4caf5010',
        text: '#611a15',
    },
    grey: {
        "20": "#fcfcfc",
        "30": "#F4F5F9",
        "40":"#E5EFFA",
        "50": "#fafafa",
        "70": "#f7f7f7",
        "100": "#f5f5f5",
        "110": "#f0f0f0",
        "120":"#eae9f0",
        "200": "#eeeeee",
        "230": "#f7f7f9",
        "250": "#ececec",
        "260": "#D8E5F2",
        "270": "#dfdbeb",
        "300": "#e0e0e0",
        "400": "#bdbdbd",
        "450": "#63707E",
        "500": "#9e9e9e",
        "600": "#757575",
        "700": "#616161",
        "800": "#424242",
        "900": "#212121",
        "A100": "#d5d5d5",
        "A150": "#cccccc",
        "A200": "#aaaaaa",
        "A400": "#303030",
        "A700": "#616161",
    },
    green:{
        "100": "#22929b",
        "120": "#1d8189",
    },
    purple: {
        "30":"#bfb6d7",
        "32": "#797AA0",
        "40": "#585988",
        "42": "#878CEC",
        "45":"#545BE5",
        "50":"#7a73a7",
        "55": "#4349b7",
        "60":"#4e467b",
        "70": "#3d3666",
        "80": "#33366A",
        "100": "#1e1943",
    },
    blue: {
        "20": "#9894b1",
        "30": "#0A6FC2",
        "100":"#3d3666",
        "130": "#2C5871",
        "140": "#16303E",
        "150": "#1E1F41",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    text: {
        primary: "rgba(0, 0, 0, 0.87)",
        secondary: "rgba(0, 0, 0, 0.54)",
        disabled: "rgba(0, 0, 0, 0.38)",
        hint: "rgba(0, 0, 0, 0.38)"
    },
    divider: "rgba(0, 0, 0, 0.12)",
    background: {
        paper: "#fff",
        default: "#fff",
        level2: "#f5f5f5",
        level1: "#fff"
    },
    action: {
        active: "rgba(0, 0, 0, 0.54)",
        hover: "rgba(0, 0, 0, 0.04)",
        hoverOpacity: 0.04,
        selected: "rgba(0, 0, 0, 0.08)",
        selectedOpacity: 0.08,
        disabled: "rgba(0, 0, 0, 0.7)",
        disabledBackground: "rgba(0, 0, 0, 0.08)",
        disabledOpacity: 0.38,
        focus: "rgba(0, 0, 0, 0.12)",
        focusOpacity: 0.12,
        activatedOpacity: 0.12
    }

};
