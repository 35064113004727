import theme from '../../config/style/muiTheme';

const styles = {
    content: {
        height: {
            xs: 'calc(100vh - 60px)',
            sm: 'calc(100vh - 72px)',
            md: 'calc(100vh - 68px)',
            lg: 'calc(100vh - 68px)',
            xl: 'calc(100vh - 68px)',
        },
        mt: '4px',
    },
    title: {
        color: theme.palette.blue['130'],
        fontWeight: '400',
    },
    grid: {
        margin: '50px auto 0',
        maxWidth: '1200px',
    },
    illustrationContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    illustration: {
        maxWidth: {
            xs: '120px',
            sm: '300px',
            md: '400px',
        },
    },
    griMain: {
        height: '100%',
    },
    gridSplash: { display: { xs: 'none', sm: 'none', md: 'block' } },
};

export default styles;
