import React, {useRef, useState} from 'react';
import Dialog from "@mui/material/Dialog";
import Checkbox from "@mui/material/Checkbox";
import LanguageIcon from '@mui/icons-material/Language';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import Box from "@mui/material/Box";
import { utils } from 'comm-recipientapp-shared';
import LanguageSearch from './LanguageSearch';
import styles from './styles';

export default function LanguagesSelector({
    language = 'en',
    languagesList = [],
    handleClose = () => {},
    handleConfirm = () => {},
}) {
    const strings = utils.useStrings();
    const searchFieldRef = useRef();
    const [searchString, setSearchString] = useState('');
    const [languageSelected, setLanguageSelected] = useState(language);
    const [languageSearchList, setLanguageSearchList] = useState(languagesList);
    const hasLanguages = languageSearchList.length > 0;

    const handleSearch = (text) => {
        searchFieldRef.current.value = text;
        setSearchString(text);

        let tempList = [];
        languagesList.map((item, key) => {
            const {label} = item;
            const textLowerCase = text.toLowerCase();
            const labelLowerCase = label.toLowerCase();
            if (labelLowerCase.includes(textLowerCase)) {
                tempList.push(item);
            }
        });

        setLanguageSearchList(tempList);
    };

    const handleClearSearch = () => {
        handleSearch('');
    };

    const handleSearchChange = (e) => {
        handleSearch(e.target.value);
    };

    const handleButtonConfirm = () => {
        handleConfirm(languageSelected);
    };

    const handleToggle = code => () => {
        setLanguageSelected(code);
    };

    return (
        <Dialog open={true} onClose={handleClose}>
            <Box>
                <Box sx={styles.dialogHeader}>
                    <Box sx={styles.dialogIcon}>
                        <LanguageIcon aria-hidden={true} sx={styles.menuIcon} />
                    </Box>
                </Box>
                <Box sx={styles.dialogHeader}>
                    <Box sx={styles.dialogTitle}>
                        <Typography sx={styles.dialogTitle}>{strings.SELECT_LANGUAGE}</Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={styles.dialogSearch}>
                <LanguageSearch
                    onChange={handleSearchChange}
                    onClear={handleClearSearch}
                    searchBarRef={searchFieldRef}
                    debounceTime={100}
                />
            </Box>
            <Box style={styles.dialogContent}>
                {hasLanguages && (
                    <List style={styles.dialogList}>
                        {languageSearchList.map((item, key) => {
                            const { value, label } = item;
                            const isChecked = (languageSelected === value);
                            const isAutoFocus = isChecked && (searchString.length === 0);
                            return (
                                <ListItem disablePadding key={key}>
                                    <ListItemButton selected={isChecked} onClick={handleToggle(value)} autoFocus={isAutoFocus}>
                                        <ListItemIcon>
                                            <Checkbox checked={isChecked} tabIndex={-1} disableRipple/>
                                        </ListItemIcon>
                                        <ListItemText id={value} primary={label}  style={styles.listItemText}/>
                                    </ListItemButton>
                                </ListItem>
                            );
                        })}
                    </List>
                )}
                {!hasLanguages && (
                    <Box style={styles.message_error_content}>
                        <Typography aria-label={''} sx={styles.message_error}>
                            {strings.NO_LANGUAGES_FOUND}
                        </Typography>
                    </Box>
                )}
            </Box>

            <Box style={styles.buttonContent}>
                <Button style={styles.dialogButton} onClick={handleClose} variant="contained">
                    {strings.CANCEL}
                </Button>
                <Button style={styles.dialogButton} disabled={!hasLanguages} onClick={handleButtonConfirm} variant="contained">
                    {strings.SELECT}
                </Button>
            </Box>

        </Dialog>
    );

}
