/* eslint-disable camelcase */
const from_connector = {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.03em',
    display: {
        xs: 'none',
        sm: 'flex',
        md: 'flex',
        lg: 'flex'
    },
};

const message_details = {
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '150%',
    whiteSpace: 'nowrap',
    letterSpacing: '0.03em',
    color: '#33366A',
};

const centerVertically = {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column',
};

const styles = {
    container: {
        display: 'flex',
        paddingTop: '5px',
        paddingBottom: '5px',
    },
    container_nextrow: {
        display: 'flex',
        marginBottom: '10px',
        marginLeft: '100px',
    },
    avatar_container: {
        ...centerVertically,
        width: '45px',
        padding: "15px"
    },
    avatar: {
        marginRight: '20px',
        marginLeft: '20px',
    },
    message_title: {
        fontWeight: '400',
        fontSize: '18px',
        lineHeight: '130%',
        color: '#33366A',
        marginRight: '10px',
        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
            color: '#0037FA',
        },
    },
    message_title_bold: {
        fontWeight: '580',
        fontSize: '18px',
        lineHeight: '130%',
        color: '#33366A',
        marginRight: '10px',
        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
            color: '#0037FA',
        },
    },
    message_data_container: {
        ...centerVertically,
        width: '90%',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    message_body: {
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '150%',
        letterSpacing: '0.03em',
        marginTop: '4px',
        marginRight: '10px',
    },
    from_connector,
    from_connector_large_variant: {
        ...from_connector,
        fontSize: '16px',
        lineHeight: '150%',
        display: {
            xs: 'none',
            sm: 'flex',
            md: 'flex',
            lg: 'flex'
        },
    },
    sender: {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '150%',
        color: '#33366A',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        width: {
            xs: 'none',
            sm: '85px',
            md: '110px',
            lg: '110px',
        },
    },
    chip: {
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '150%',
        color: '#33366A',
        borderRadius: '4px',
        marginTop: '5px',
        backgroundColor: '#EFF0F4',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    chip_emergency: {
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '150%',
        color: '#FFFFFF',
        borderRadius: '4px',
        marginTop: '5px',
        backgroundColor: '#D12222',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    sender_text_container: {
        marginTop: '5px',
        display: 'flex',
        gap: '5px',
    },
    message_details_container: {
        ...centerVertically,
        marginRight: '0px',
        width: '100%',
        maxWidth: '290px',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    message_details,
    message_details_shorted: {
        ...message_details,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: '74px',
    },
    customer_name: {
        ...message_details,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: '100%',
    },
    message_date: {
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '150%',
        whiteSpace: 'nowrap',
    },
    message_date_container: {
        ...centerVertically,
        marginRight: '20px',
        width: '140px',
    },
    message_date_container_date: {
        ...centerVertically,
        textAlign: 'right',
        marginTop: '10px',
        marginRight: '20px',
        marginBottom: '-10px',
    },
    save_message_button_container: {
        ...centerVertically,
        marginRight: '30px',
    },
    more_options_button_container: {
        ...centerVertically,
        marginRight: '20px',
    },
    chip_and_sender_container: {
        display: 'flex',
        gap: '5px',
    },
    chip_icon: {
        transform: 'scale(1.1)',
        marginLeft: '7px',
    },
    chip_name: {
        transform: 'scale(0.9)',
        marginRight: '0px',
    },
    message_student_and_school_container: {
        display: 'flex',
        gap: '7px',
        marginTop: '2px',
    },
    arrow_icon: {
        transform: 'scale(1.2)',
        marginTop: '7px',
    },
    chip_icon_dimension: {
        width: '14px',
        height: '14px',
    },
    from_connector_container: {
        marginTop: '1px',
    },
    arrow_dimensions: {
        width: '5px',
        height: '7px',
    },
    more_options_button: {
        '&:hover': {
            backgroundColor: '#F8F8FA',
        },
        display: 'flex',
        gap: '10px',
        cursor: 'pointer',
        padding: '10px 15px 10px 15px',
    },
    more_options_stack: {
        backgroundColor: 'white',
        borderRadius: '8px',
    },
    more_options_text: {
        fontWeight: '500',
        opacity: '0.6',
        fontSize: '13px',
        width: '100px',
        textAlign: 'left',
    },
    more_options_text_container: {
        ...centerVertically,
        color: '#535BE5',
    },
    initials: {
        fontWeight: '700',
        fontSize: '16px',
        lineHeight: '120%',
        color: 'white',
    },
    initials_container: {
        ...centerVertically,
        marginRight: '20px',
    },
    initials_box: {
        ...centerVertically,
        width: '40px',
        height: '40px',
        borderRadius: '10px',
        textAlign: 'center',
        backgroundColor: '#33366A',
        '&:hover': {
            cursor: 'default',
        },
    },
    divider: {
        background: '#E5EFFA',
    },
    link_button: {
        marginRight: '20px',
        marginLeft: '20px',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    msg_type_icon: {
        ...centerVertically,
        transform: 'scale(0.8)',
        color: '#1E90FF',
        marginLeft: '-10px',
    },
    messages_icon_container: {
        ...centerVertically,
        marginRight: '20px',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    messages_icon: {
        display: {
            xs: 'block',
            sm: 'block',
            md: 'block',
            lg: 'flex'
        },
        gap: '10px',
    },
};

export default styles;

