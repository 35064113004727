import HTTP from '../HTTP';
import { store } from '../../store';

export const getCustomer = () => {
    const state = store.getState();
    const token = state.auth.auth.access_token;
    return HTTP.get('customers', {
        headers: {
            'X-Authorization': `Bearer ${token}`,
        },
    });
};
