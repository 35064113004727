import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, useMediaQuery } from '@mui/material';
import { setChangedSinceLastTime } from 'comm-recipientapp-shared/src/store/initialize/initializeSlice';
import Layout from '../Layout';
import StudentsToClaim from './StudentsToClaim';

export const DISPLAY_MODE = {
    CONTACT_SCHOOL: 'CONTACT_SCHOOL',
    SHOW_DEPENDENTS: 'SHOW_DEPENDENTS',
};
function StudentsClaiming() {
    const changedSinceLastTime = useSelector(state => state.initialize.changedSinceLastTime);

    const navigate = useNavigate();

    useEffect(() => {
        if (!changedSinceLastTime) {
            navigate('/inbox');
        }
    }, []);

    if (!changedSinceLastTime) {
        return null;
    }

    return <StudentsToClaim />;
}

export default function Wrapper() {
    const matches = useMediaQuery('(max-width:700px)');

    return (
        <Layout>
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <Box sx={{ maxWidth: matches ? '100%' : '50%' }}>
                    <StudentsClaiming />
                </Box>
            </Box>
        </Layout>
    );
}
