import { React }from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { 
    Box, 
    Grid, 
    Link, 
    Typography, 
} from '@mui/material';
import { utils } from 'comm-recipientapp-shared';
import styles from './styles';

function ConfirmedEmail({userEmail, messageDisplay}) {
    const strings = utils.useStrings();
    return (
        <Grid item lg={6} md={12} xs={12} sx={styles.grid_container}>
            <Box sx={styles.box_container}>
                <Typography sx={styles.header} variant="h5">{strings.WELCOME_BACK_TO_SCHOOLMESSENGER}!</Typography>
                <Typography sx={styles.text}>{strings.THANK_YOU_FOR_CONFIRMING_YOUR_EMAIL_ADDRESS}.</Typography>
                <Typography sx={styles.text}>
                    {strings.ON_YOUR_NEXT_LOGIN_YOU_WILL_BE_TAKEN_DIRECTLY_TO}
                    <Link component={RouterLink} sx={styles.link} underline="none" to="/inbox"> {strings.MESSAGES}</Link>.
                </Typography>
            </Box>
        </Grid>
    )
}

export default ConfirmedEmail;


