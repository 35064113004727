/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { initialFilterOptionsState } from '../../hooks/inbox/useFilterOptions';
import { resetState } from '../resetStore';
import { inboxAPI } from '../../api';
import { formatInboxData, extractBroadcastTypes } from '../../utils/inboxDataHelper';

const initialState = {
    currentMessageDetails: {},
    currentSearchString: '',
    currentPagination: 1,
    totalUnreadInboxCount: 0,
    currentFilters: initialFilterOptionsState,
    messages: [],
    isLoading: false,
    archivedMessageId: null,
    broadcastTypes: [],
    messageDetail: {},
    isStopAudio: true,
    isLoadingInboxMessages: false,
};

const inboxSlice = createSlice({
    name: 'inboxSlice',
    initialState,
    reducers: {
        setIsLoading: (state, { payload }) => {
            state.isLoading = payload;
        },
        setIsLoadingInboxMessages: (state, { payload }) => {
            state.isLoadingInboxMessages = payload;
        },
        setCurrentFilters: (state, { payload }) => {
            state.currentFilters = payload;
        },
        setCurrentPagination: (state, { payload }) => {
            state.currentPagination = payload;
        },
        setCurrentMessageDetails: (state, { payload }) => {
            state.currentMessageDetails = payload;
        },
        setMessageOnStore: (state, { payload }) => {
            state.messages = payload;
        },
        setCurrentSearchString: (state, { payload }) => {
            state.currentSearchString = payload;
        },
        setTotalUnreadInboxCount: (state, { payload }) => {
            state.totalUnreadInboxCount = payload;
        },
        setArchivedMessageId: (state, { payload }) => {
            state.archivedMessageId = payload;
        },
        setStopAudio: (state, { payload }) => {
            state.isStopAudio = payload;
        },
        archiveMessage: (state, { payload }) => {
            const messageId = payload;
            const prevData = state.messages;
            state.messages = prevData?.filter(item => item.messageId !== messageId);
        },
        toggleReadStatus: (state, { payload }) => {
            const messageId = payload;
            const prevData = state.messages;
            const index = prevData?.findIndex(msg => msg?.messageId === messageId);
            const msgToUpdate = prevData[index];
            const updatedItem = {
                ...msgToUpdate,
                read: !msgToUpdate?.read,
            };
            const updatedData = [...prevData];
            updatedData[index] = updatedItem;
            state.messages = updatedData;
        },
        setReadStatus: (state, { payload }) => {
            const { messageId, status } = payload;
            const prevData = state.messages;
            const index = prevData?.findIndex(msg => msg?.messageId === messageId);
            const msgToUpdate = prevData[index];
            const updatedItem = {
                ...msgToUpdate,
                read: status,
            };
            const updatedData = [...prevData];
            updatedData[index] = updatedItem;
            state.messages = updatedData;
        },
        markAllAsRead: state => {
            const prevData = state.messages;
            state.messages = prevData?.map(msg => {
                return {
                    ...msg,
                    read: true,
                };
            });
        },
        setBroadcastTypes: (state, { payload }) => {
            state.broadcastTypes = payload;
        },
    },
    extraReducers: builder => builder.addCase(resetState, () => initialState),
});

export const {
    setIsLoading,
    setIsLoadingInboxMessages,
    setCurrentFilters,
    setCurrentPagination,
    setCurrentMessageDetails,
    setMessageOnStore,
    setCurrentSearchString,
    setTotalUnreadInboxCount,
    setArchivedMessageId,
    archiveMessage,
    toggleReadStatus,
    setReadStatus,
    markAllAsRead,
    setBroadcastTypes,
    setStopAudio,
} = inboxSlice.actions;

export const getMessages = () => async dispatch => {
    try {
        dispatch(setIsLoading(true));
        dispatch(setIsLoadingInboxMessages(true));
        const { data } = await inboxAPI.getMessages();
        dispatch(setIsLoading(false));
        dispatch(setIsLoadingInboxMessages(false));
        dispatch(setMessageOnStore(formatInboxData(data)));
        return {
            error: false,
            message: '',
        };
    } catch (error) {
        dispatch(setIsLoading(false));
        dispatch(setIsLoadingInboxMessages(false));
        return {
            error: true,
            message: error?.response?.data?.error || error,
        };
    } finally {
        dispatch(setIsLoading(false));
        dispatch(setIsLoadingInboxMessages(false));
    }
};

export const getBroadcastTypes = () => async dispatch => {
    try {
        const { data } = await inboxAPI.getCustomerSettings('notificationtypes');
        dispatch(setBroadcastTypes(extractBroadcastTypes(data)));
    } catch (error) {}
};

export const getMessageDetail =
    ({ customerId, messageId, personId }) =>
    async dispatch => {
        try {
            dispatch(setIsLoading(true));
            const { data } = await inboxAPI.getMessageDetail(customerId, messageId, personId);
            dispatch(setIsLoading(false));
            dispatch(setCurrentMessageDetails(data));
            return {
                error: false,
                message: '',
            };
        } catch (error) {
            dispatch(setIsLoading(false));
            return {
                error: true,
                message: error?.response?.data?.error || error,
            };
        } finally {
            dispatch(setIsLoading(false));
        }
    };

export default inboxSlice.reducer;
