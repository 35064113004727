import theme from '../../../config/style/muiTheme';

const styles = {
    Autocomplete: {
        borderRadius: '6px',
        backgroundColor: theme.palette.common.white,
        marginBottom: 0,
        marginTop: 1,
        '& .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.palette.common.black}`,
            borderRadius: '6px',
            bottom: '12px',
            top: '2px',
        },
        '& .MuiAutocomplete-popupIndicator': {
            transform: 'none',
        },
        '& .MuiFormLabel-root': {
            color: theme.palette.common.black,
        },
    },
};

export default styles;
