import React, { useEffect, useMemo, useRef, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { utils } from 'comm-recipientapp-shared';
import { isUSPhoneNumberValid } from 'comm-recipientapp-shared/src/utils/preferencesUtils';
import Button from '../../Shared/Button';
import styles from '../styles';
import { StyledInput } from '../../Preferences/ContactInformation/AddEmail/styles';
import VerifyInformation from '../VerifyInformation';

function OptOutForm() {
    const schoolInputRef = useRef();

    const strings = utils.useStrings();
    const matches = useMediaQuery('(max-width:600px)');
    const [school, setSchool] = useState('');
    const [phone, setPhone] = useState({ value: '', countryCode: '', phone: '' });
    const [isOpen, setIsOpen] = useState(false);
    const [error, setError] = useState(false);

    const isNumberValid = useMemo(() => phone?.phone?.length === 10 && isUSPhoneNumberValid(phone?.phone), [phone?.phone]);

    useEffect(() => {
        if (phone?.phone?.length === 10 && !isNumberValid) {
            setError(true);
        } else {
            setError(false);
        }
    }, [phone?.phone]);

    return (
        <Box sx={styles.form_container}>
            <Typography sx={styles.form_title}>{strings.FILL_OUT_THIS_FORM_TO_UNSUBSCRIBE}</Typography>
            <Box sx={styles.input_section}>
                <Typography sx={styles.input_label}>* {strings.SCHOOL_OR_DISTRICT}</Typography>
                <StyledInput
                    style={styles.input_field}
                    value={school}
                    onChange={e => setSchool(e.target.value)}
                    className="text_field"
                    ref={schoolInputRef}
                />
            </Box>

            <Box sx={styles.input_section}>
                <Typography sx={styles.input_label}>* {strings.MOBILE_PHONE_NUMBER_TO_OPT_OUT}</Typography>
                <PhoneInput
                    inputStyle={{ height: '40px', width: '100%', maxWidth: '400px' }}
                    country="us"
                    enableSearch
                    value={phone.value}
                    onChange={(value, data) => {
                        setPhone({ value, countryCode: data?.countryCode, phone: value.replace(data?.dialCode, '') });
                    }}
                    countryCodeEditable={false}
                />
                {error && <Typography sx={styles.error}>{strings.PREFERENCES_INVALID_PHONE}</Typography>}
            </Box>

            <Box sx={{ ...styles.buttonContent, ...{ width: matches ? '100%' : '20%' } }}>
                <Button
                    color="primary"
                    disabled={!(phone.value && school && isNumberValid)}
                    fullWidth
                    type="button"
                    onClick={() => setIsOpen(true)}
                    variant="contained"
                    tabIndex={0}
                >
                    {strings.SUBMIT}
                </Button>
            </Box>
            <VerifyInformation school={school} countryCode={phone.countryCode} phone={phone.phone} isOpen={isOpen} onClose={() => setIsOpen(false)} />
        </Box>
    );
}

export default OptOutForm;
