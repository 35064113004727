import theme from '../../../config/style/muiTheme';

const styles = {
    cardChevronRightIcon: {
        width: '24px',
        height: '24px',
        mt: 'auto',
        mb: 'auto',
    },
    typographyFooter: {
        color: theme.palette.purple['45'],
        fontFamily: 'Inter',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '140%',
        letterSpacing: '-0.36px',
    },
    boxForButton: {
        display: 'flex',
    },
    button: {
        margin: 2,
    },
};

export default styles;
