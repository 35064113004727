import React from 'react';
import { useSelector } from 'react-redux';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MessageIcon from '@mui/icons-material/Message';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { utils } from 'comm-recipientapp-shared';
import { handleEnterKeyPress } from 'comm-recipientapp-shared/src/utils/helpers';
import styles from './styles';

function SmsItem({ item, type, handleClick, ...props }) {
    const strings = utils.useStrings();
    const activeSchool = useSelector(state => state.preferences.activeSchool);
    const verified = utils.helpers.getPhoneStatus(activeSchool, item, strings);

    const isIconActive = () => {
        const hasNotificationTypes = item.notificationTypes.length > 0;
        return hasNotificationTypes && verified;
    };
    const iconActive = isIconActive();
    const phoneformated = utils.helpers.formatPhoneNumber(item.sms);
    const emailStatus = iconActive ? strings.ENABLED_TO_RECEIVE_SMS : strings.NOT_ENABLED_TO_RECEIVE_SMS;
    const arialLabel = `${strings.SMS}: ${phoneformated}, ${strings.STATUS}: ${emailStatus}, ${strings.PRESS_ENTER_TO_CHANGE_THESE_OPTIONS}`;

    return (
        <Card
            sx={verified ? styles.contact_card : styles.contact_card_inactive}
            data-testid={`contact_sms_${props.index}`}
            onClick={() => handleClick(item.sms, type)}
            onKeyDown={handleEnterKeyPress(() => handleClick(item.sms, type))}
        >
            <CardContent sx={styles.contact_card_multiple} tabIndex="0" aria-label={arialLabel}>
                <div style={styles.content_phone} aria-hidden>
                    <div style={styles.content_circles}>
                        <div style={iconActive ? styles.contact_circle : styles.contact_circle_inactive}>
                            <MessageIcon fontSize="small" sx={iconActive ? styles.circle_icon : styles.circle_icon_inactive} />
                        </div>
                    </div>
                    <div style={styles.contact_info} aria-hidden>
                        <span style={styles.contact_info_title}>{phoneformated}</span>
                        <span style={styles.contact_info_subtitle}>{verified}</span>
                    </div>
                </div>
                <IconButton
                    onClick={e => {
                        e.stopPropagation();
                        props.handleOnDelete(item.sms);
                    }}
                    data-testid={`contact_delete_sms_${props.index}`}
                    aria-label={strings.DELETE_PHONE}
                >
                    <CloseIcon fontSize="small" />
                </IconButton>
            </CardContent>
        </Card>
    );
}

export default SmsItem;
