/* eslint-disable prettier/prettier */
import React from 'react';
import {
    Box,
    Typography,
    Container,
    Divider,
} from "@mui/material";
import Title from '../../Shared/NewUI/Title';
import conversationSvg from 'comm-recipientapp-shared/src/assets/images/conversation.svg';
import chatLogo from 'comm-recipientapp-shared/src/assets/chat_logo.svg';
import { utils } from 'comm-recipientapp-shared';
import styles from './styles';

function DisabledOverlay () {
    const strings = utils.useStrings();

    return (
        <Container>
            <Box sx={styles.titleContainer}>
                <Box sx={styles.logoContainer}>
                    <Box 
                        component="img" 
                        src={chatLogo}
                        sx={styles.logo}>
                    </Box>
                </Box>
                <Title variant="h1" sx={styles.title}>
                    {strings.CHAT}
                </Title>
            </Box>
            <Divider />
            <Box sx={styles.no_data_message_container}>
                <Box sx={styles.svgContainer}>
                    <Box 
                        component="img" 
                        src={conversationSvg} 
                        sx={styles.logo}>
                    </Box>
                </Box>
                <Box>
                    <Title sx={styles.no_data_title}>
                        {strings.CHAT_FEATURE_UNAVAILABLE}
                    </Title>
                    <Typography sx={styles.no_data_subtitle}>
                        {strings.PLEASE_CONTACT_SCHOOL_CHAT}
                    </Typography>
                </Box>
            </Box>
        </Container>
    );
}

export default DisabledOverlay;

