import { useMemo, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAbsences } from 'comm-recipientapp-shared/src/api/absence';
import { getData, setAbsences, setIsFetched } from 'comm-recipientapp-shared/src/store/absence/absenceSlice';
import { Roles, AllowedURLWithoutDependants } from 'comm-recipientapp-shared/src/utils/constants';

export default function useMenuItems() {
    const { pathname } = useLocation();
    const absenceData = useSelector(state => state.absence);

    const auth = useSelector(state => state.auth);

    const userRoles = useSelector(state => state.user?.account?.roles);

    const profile = useSelector(state => state.user?.account?.profile);

    const initializeData = useSelector(state => state.initialize);

    const isPowerschool = useSelector(state => state.user.isPowerschool);   // this is from powerschool plugin

    const { inlineMode } = useSelector(state => state.initialize);         // this is from MyPowerSchool

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const userCustomers = useSelector(state => state.preferences.schools);

    const hasStudentRole = useMemo(() => (userRoles ? userRoles.includes(Roles.STUDENT) : false), [userRoles]);

    const hasParentRole = useMemo(() => (userRoles ? userRoles.includes(Roles.PARENT) : false), [userRoles]);

    const hasAttendanceEnabled = useMemo(() => (absenceData.enabledStudents.length > 0 && userCustomers.length > 0) || false, [absenceData]);

    const hasSafeArrivalEnabled = useMemo(() => (userCustomers?.length > 0 && userCustomers.filter(item => item.settings.hasSafeArrival === true).length > 0) || false, [userCustomers]);

    const hasPreferencesEnabled = useMemo(() => userCustomers?.length > 0 || false, [userCustomers]);

    const accountHasRecordsAssociated = useMemo(() => userRoles?.length > 0, [userRoles]);

    const validationToRedirectToClaimingProcess = route => {
        if (!AllowedURLWithoutDependants.includes(pathname)) {
            window.location.href = route;
        }
    };

    useEffect(() => {
        if (auth?.auth?.access_token && absenceData?.absences?.length === 0) {
            (async () => {
                const currentYear = new Date().getFullYear();

                const [absencesResponse] = await Promise.all([getAbsences(`${currentYear}-01-01`, `${currentYear}-12-31`), dispatch(getData())]);

                if (absencesResponse && absencesResponse.data) dispatch(setAbsences(absencesResponse.data.absences));

                dispatch(setIsFetched(true));
            })();
        }
    }, []);

    useEffect(() => {
        if (auth?.auth?.access_token && userRoles !== undefined) {
            if ((!userRoles?.length || !profile) && !initializeData.dependents.length) {
                // no profilem or no role, and no dependants  -> go to "not records found"
                validationToRedirectToClaimingProcess(`/#${AllowedURLWithoutDependants[0]}`); // not-found-records page
            } else if (userRoles.includes(Roles.PARENT) && !initializeData.dependents.length) {
                // has a  profile, is a parent, has no dependants -> go to "not records found"
                validationToRedirectToClaimingProcess(`/#${AllowedURLWithoutDependants[0]}`); // not-found-records page
            } else if (initializeData?.changedSinceLastTime && userRoles.includes(Roles.PARENT)) {
                navigate('/students-to-claim');
            }
        }
    }, [userRoles, profile]);

    return {
        hasSafeArrivalEnabled,
        hasAttendanceEnabled,
        hasPreferencesEnabled,
        accountHasRecordsAssociated,
        hasStudentRole,
        hasParentRole,
    };
}
