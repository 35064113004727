import React from 'react';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import { debounce } from 'comm-recipientapp-shared/src/utils/inboxUtils';
import { useStrings } from 'comm-recipientapp-shared/src/utils/useStrings';
import styles from './styles';

function noop() {
    return;
}

export default function SearchBar({ onChange = () => {}, onClear = () => {}, searchBarRef = null, debounceTime = 0 }) {
    const onChangeFunc = debounceTime > 0 ? debounce(onChange, debounceTime) : onChange;

    const onClearFunc = onClear;

    const { NEW_INBOX_SEARCH_BAR_PLACEHOLDER } = useStrings();

    const isClearEnabled = (searchBarRef?.current?.value?.length > 0) ? true : false;

    return (
        <Input
            startAdornment={

                <InputAdornment sx={styles.input_adornment} position="end">
                    {isClearEnabled && (
                    <IconButton sx={styles.input_button} onClick={onClear ? onClearFunc : noop}>
                        <ClearIcon sx={styles.clear_icon}/>
                    </IconButton>
                    )}
                </InputAdornment>

            }
            onChange={onChange ? onChangeFunc : noop}
            placeholder={`${NEW_INBOX_SEARCH_BAR_PLACEHOLDER}...`}
            sx={styles.input}
            disableUnderline
            inputProps={{ 'data-testid': 'search-bar' }}
            inputRef={searchBarRef}
        />
    );
}
