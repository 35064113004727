import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { useStrings } from 'comm-recipientapp-shared/src/utils';
import { ContactDestinationTypes } from 'comm-recipientapp-shared/src/utils/constants';
import { stringContainsOnlyDigits } from 'comm-recipientapp-shared/src/utils/preferencesUtils';
import Button from '../../../Shared/Button';
import styles from './styles';


export default function DeleteContactInformation({
    contactToDelete,
    handleCancel,
    handleDeleteContact,
}) {
    const {
        PREFERENCES_DELETE_CONTACT_BANNER_TITLE,
        PREFERENCES_DELETE_CONTACT_DESCRIPTION,
        PREFERENCES_DELETE_CONTACT_CANCEL_BUTTON,
        PREFERENCES_DELETE_CONTACT_DELETE_BUTTON,
    } = useStrings();
    return (
        <Box>
            <Box sx={styles.banner_text_container}>
                <Typography sx={styles.banner_text}>
                    {PREFERENCES_DELETE_CONTACT_BANNER_TITLE}
                </Typography>
            </Box>

            <Box sx={styles.description_container}>
                <Typography>
                    {PREFERENCES_DELETE_CONTACT_DESCRIPTION}{' '}
                    <b>
                        {stringContainsOnlyDigits(contactToDelete)
                            ? contactToDelete.replace(/^(\d{3})(\d{3})(\d{4}).*/, '($1) $2-$3')
                            : contactToDelete}
                    </b>{' '}
                    ?
                </Typography>
            </Box>

            <Box sx={styles.buttons_container}>
                <Button onClick={handleCancel} variant="outlined" sx={styles.cancel_button}>
                    {PREFERENCES_DELETE_CONTACT_CANCEL_BUTTON}
                </Button>
                <Button
                    variant="contained"
                    sx={styles.delete_button}
                    onClick={() =>
                        handleDeleteContact(
                            stringContainsOnlyDigits(contactToDelete)
                                ? ContactDestinationTypes.PHONE
                                : ContactDestinationTypes.EMAIL,
                            contactToDelete
                        )
                    }
                >
                    {PREFERENCES_DELETE_CONTACT_DELETE_BUTTON}
                </Button>
            </Box>
        </Box>
    );
}
