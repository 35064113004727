import React from 'react';

export default function Trash(props) {
    return (
        <svg data-icon="trash" xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
            <g clipPath="url(#a)">
                <path d="M15.625 4.375V16.25a.624.624 0 0 1-.625.625H5a.625.625 0 0 1-.625-.625V4.375h11.25Z" fill="#fff" />
                <path d="M16.875 4.375H3.125" stroke="#fff" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8.125 8.125v5M11.875 8.125v5" stroke="#D12222" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
                <path
                    d="M15.625 4.375V16.25a.624.624 0 0 1-.625.625H5a.625.625 0 0 1-.625-.625V4.375"
                    stroke="#fff"
                    strokeWidth={1.5}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M13.125 4.375v-1.25a1.25 1.25 0 0 0-1.25-1.25h-3.75a1.25 1.25 0 0 0-1.25 1.25v1.25" fill="#D12222" />
                <path
                    d="M13.125 4.375v-1.25a1.25 1.25 0 0 0-1.25-1.25h-3.75a1.25 1.25 0 0 0-1.25 1.25v1.25"
                    stroke="#fff"
                    strokeWidth={1.5}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 0h20v20H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}
