/* eslint-disable prettier/prettier */
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    Container,
    Stack,
    Box,
    Typography,
    InputBase,
    Button,
    FormHelperText,
    CircularProgress,
} from '@mui/material';
import { utils } from 'comm-recipientapp-shared';
import ChevronRightWhiteSVG from 'comm-recipientapp-shared/src/assets/chevron_right_white.svg'
import Layout from '../Layout';
import BackButton from '../Shared/BackButton';
import Loader from '../Loader';
import useValidateEmail from './customHooks';
import styles from './styles';

export default function AddNewEmail() {
    const strings = utils.useStrings();
    const navigate = useNavigate();
    const location = useLocation();

    const goBackTo  = location.state?.goBackTo || utils.constants.AllowedURLWithoutDependants[0]; // not-found-records page

    const {
        newEmail,
        emailError,
        disableButton,
        loading,
        buttonLoading,
        handleEmailChange,
        handleSendRequestForNewEmail,
    } = useValidateEmail();

    const handleClickBackButton = () => {
        navigate(goBackTo);
    }

    return (
        <Layout>
            <Container maxWidth="xl" disableGutters sx={styles.mainContainer}>
                <Box sx={styles.mainBox}>
                    <BackButton handleClickBackButton={handleClickBackButton}/>
                    <Stack sx={styles.stackBody}  alignItems="center" >
                        <Typography sx={styles.typographyTitle}>{strings.TRY_A_DIFFERENT_EMAIL}</Typography>
                        <Box sx={styles.boxForInput}>
                            <InputBase
                                value={newEmail}
                                fullWidth
                                inputProps={{sx: styles.input}}
                                placeholder={`${strings.ENTER_A_DIFFERENT_EMAIL}...`}
                                onChange={handleEmailChange}
                            />
                            {emailError ? <FormHelperText error>{emailError}</FormHelperText> : null}
                        </Box>
                        <Box sx={styles.ButttonBox}>
                            <Button
                                fullWidth
                                disabled={disableButton}
                                onClick={handleSendRequestForNewEmail}
                                variant="contained"
                                endIcon={
                                    <Box
                                        alt={strings.CHEVRON_RIGHT_ICON}
                                        component="img"
                                        src={ChevronRightWhiteSVG}
                                        sx={styles.cardChevronRightIcon}
                                    />
                                }
                                sx={styles.basicButton}
                                aria-label={strings.CONTINUE}
                            >
                                <Typography sx={styles.buttonLabel}>{strings.CONTINUE}</Typography>
                            </Button>
                            {buttonLoading && <CircularProgress role="status" aria-live="assertive" aria-label={strings.LOADING} size={24} sx={styles.CircularProgress} />}
                        </Box>
                        <Typography sx={styles.leyend}>{strings.USE_ANOTHER_EMAIL_ADDRESS_THAT_YOU_MAY_HAVE_PROVIDED_TO_THE_SCHOOL}</Typography>
                    </Stack>
                </Box>
            </Container>
            {loading && <Loader data-testid="loader" visible={loading}/>}
        </Layout>
    );
}
