import { stripHtml } from 'string-strip-html';
import { getConsistentColorFromString } from 'comm-recipientapp-shared/src/utils/avatar';

const MESSAGES_PER_PAGE_SMALL_HEIGHT = 8;
const MESSAGES_PER_PAGE_BIG_HEIGHT = 12;

const READ = 'READ';
const UNREAD = 'UNREAD';
const ARCHIVED = 'ARCHIVED';
const ALL_MESSAGES = 'ALL_MESSAGES';
const FILTERED_MESSAGES = 'FILTERED_MESSAGES';
const SEARCHED_MESSAGES = 'SEARCHED_MESSAGES';

export { MESSAGES_PER_PAGE_SMALL_HEIGHT, MESSAGES_PER_PAGE_BIG_HEIGHT, READ, UNREAD, ARCHIVED, ALL_MESSAGES, FILTERED_MESSAGES, SEARCHED_MESSAGES };

export const MAX_PREVIEW_CONTENT_LENGTH = 40;
export const MAX_MESSAGE_TYPE_LENGTH = 15;

export const processNotifications = data => {
    const types = data.map(customer => {
        return customer.notificationTypes;
    });

    const flatten = types.flat(1);
    const sorted = flatten.sort((a, b) => a.priority - b.priority);
    const notificationNames = sorted.map(each => each.name);
    const uniqueNames = [...new Set(notificationNames)];
    const uniqueCategoriesArr = [];
    uniqueNames.forEach(type => {
        uniqueCategoriesArr.push(sorted.find(category => category.name === type));
    });

    return uniqueCategoriesArr;
};

/* eslint-disable no-restricted-globals */
export const formatMessageDataFromApi = payload => {
    const messagesMapped = payload.map((msg, index) => {
        const message = msg;

        const contentData = message.expand?.emails[0];

        const formattedMessageContent = contentData?.isHtml ? stripHtml(contentData?.htmlBody).result : message.expand?.sms[0]?.content || '';

        // Why do we assign the id as index? since the text search index the id of the message as results,
        // we use the id as index of the message in the array of initial messages to construct the new array,
        // in this case the messagesStore is the array that will store the initial messages result.
        // We do this to prevent a find operation on the array of messages for each result,
        // which is not efficient due to the amount of results that we generate per query.
        return {
            id: index,
            messageId: message.id,
            sender: `${message.sender.firstName} ${message.sender.lastName}`,
            read: message.status.includes(READ),
            archived: message.status.includes(ARCHIVED),
            customerId: message.customerId,
            people: message.people,
            peopleNames: message.people.map(person => {
                return { name: `${person.firstName} ${person.lastName}` };
            }),
            title: message.name,
            sentTimestampMs: message.sentTimestampMs,
            type: message.jobType.name,
            messageContent: formattedMessageContent,
            smsContent: message.expand?.sms[0]?.content || '',
            indexableContent: formattedMessageContent,
            attachment: contentData?.attachment?.map(attachment => {
                return { name: attachment };
            }),
            hasHtml: message.expand.emails?.length > 0,
            hasSms: message.expand.sms?.length > 0,
            hasVoice: message.expand.phones?.length > 0,
            priority: message.jobType.priority,
        };
    });

    return messagesMapped;
};

export const toUpperCaseFirstLetter = string => {
    if (string.length > 0) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return string;
};

export const getPerson = (index, people) => {
    return people[index];
};

export const getPersonName = person => {
    return person ? `${person.firstName} ${person.lastName}` : ' ';
};

export const getPersonFirstName = person => {
    return person ? person.firstName : '';
};

export const getPersonLastName = person => {
    return person ? person.lastName : '';
};

export const getPersonInitials = person => {
    return `${person?.firstName?.charAt(0).toUpperCase() || ''}${person?.lastName?.charAt(0).toUpperCase() || ''}`;
};

export const getPersonBackgroundColor = person => {
    return getConsistentColorFromString(`${person?.id}${person?.firstName}${person?.lastName}`);
};

export const getPeopleInitials = people => {
    return people.map(person => getPersonInitials(person));
};

export const getPeopleNames = people => {
    return people.map(person => getPersonName(person));
};
