/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { resetState } from '../resetStore';

const initialState = {
    hasContentPopupShowed: false,
    hasManageYourPinConsentShowed: false,
};

const consentPopupSlice = createSlice({
    name: 'consentPopupSlice',
    initialState,
    reducers: {
        setConsentPopupShowed: (state, { payload }) => {
            state.hasContentPopupShowed = payload;
        },
        setHasManageYourPinConsentShowed: (state, { payload }) => {
            state.hasManageYourPinConsentShowed = payload;
        },
    },
    extraReducers: builder => builder.addCase(resetState, () => initialState),
});

export const { setConsentPopupShowed, setHasManageYourPinConsentShowed } = consentPopupSlice.actions;

export default consentPopupSlice.reducer;
