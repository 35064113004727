import { useState, useEffect } from 'react';
import { ResetPasswordService } from 'comm-recipientapp-shared/src/api/token';
import { forgotPassword } from 'comm-recipientapp-shared/src/validations';

export default function useValidationsForNewPassword(token) {
    const [newPasswordButtonEnabled, setNewPasswordButtonEnabled] = useState(true);
    const [hasOneLowerCaseLetter, SetHasOneLowerCaseLetter] = useState(false);
    const [hasOneNumber, SetHasOneNumber] = useState(false);
    const [hasOneUpperCaseLetter, SetHasOneUpperCaseLetter] = useState(false);
    const [hasBetween6And255Characters, SetHasBetween6And255Characters] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showConfirmMessage, setShowConfirmMessage] = useState(false);
    const [showUnexpectedErrorMessage, setShowUnexpectedErrorMessage] = useState(false);
    const [show404ErrorMessage, setShow404ErrorMessage] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [newPasswordText, setNewPasswordText] = useState('');

    const handleChangePassword = async () => {
        try {
            setLoading(true);
            await ResetPasswordService(newPasswordText, token);
            setShowConfirmMessage(true);
        } catch (error) {
            if (error?.response?.status === 404) {
                setShow404ErrorMessage(true);
            } else {
                setShowUnexpectedErrorMessage(true);
            }
            setShowConfirmMessage(false);
        } finally {
            setLoading(false);
        }
    };

    const handleClickShowPassword = () => setShowPassword(show => !show);

    useEffect(() => {
        SetHasOneLowerCaseLetter(forgotPassword.testHasOneLowerCaseLetter(newPasswordText));
        SetHasOneNumber(forgotPassword.testHasOneNumber(newPasswordText));
        SetHasOneUpperCaseLetter(forgotPassword.testHasOneUpperCaseLetter(newPasswordText));
        SetHasBetween6And255Characters(forgotPassword.testHasBetween6And255Characters(newPasswordText));
    }, [newPasswordText]);

    useEffect(() => {
        if (!hasOneLowerCaseLetter || !hasOneNumber || !hasOneUpperCaseLetter || !hasBetween6And255Characters) {
            setNewPasswordButtonEnabled(true);
        } else {
            setNewPasswordButtonEnabled(false);
        }
    }, [hasOneLowerCaseLetter, hasOneNumber, hasOneUpperCaseLetter, hasBetween6And255Characters]);

    return {
        handleChangePassword,
        handleClickShowPassword,
        setNewPasswordText,
        newPasswordButtonEnabled,
        hasOneLowerCaseLetter,
        hasOneNumber,
        hasOneUpperCaseLetter,
        hasBetween6And255Characters,
        loading,
        showConfirmMessage,
        showUnexpectedErrorMessage,
        show404ErrorMessage,
        showPassword,
        newPasswordText,
    };
}
