/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Checkbox, Divider, IconButton, Stack, Typography } from '@mui/material';
import { utils } from 'comm-recipientapp-shared';
import { FilterChangeActions, FilterSections, MessageFilterSectionsTypes } from 'comm-recipientapp-shared/src/utils/constants';
import useFilterOptions from 'comm-recipientapp-shared/src/hooks/inbox/useFilterOptions';
import Inbox from '../Icons/Inbox';
import { capitilizeFirstLetter, capitilizeEachFirstLetter, GetIcon, getLabel, SECTIONS_WITH_EXTERNAL_DATA } from './utils';
import Button from '../../Shared/NewUI/Button';
import StudentItem from './StudentItem';
import SchoolItem from './SchoolItem';
import CategoryItem from './CategoryItem';
import styles from './styles';

// todo: missing translations
export default function FilterSettings({
    currentFilterOptionsFromParent = {},
    handleClose = () => {},
    replaceCurrentFilterOptions = () => {},
    categories,
    feedFilters,
}) {
    const strings = utils.useStrings();
    const currentStudents = useSelector(state => state.initialize.dependents);
    const currentSchools = useSelector(state => state.preferences.schools);

    const {
        currentFilterOptions,
        optionsHasActiveFilters: hasActiveFilterOptions,
        handleFilterOptionsChange: onFilterOptionsChange,
    } = useFilterOptions(currentFilterOptionsFromParent);

    const [hasResultsBeenCommitted, setHasResultsBeenCommitted] = useState(hasActiveFilterOptions);

    const messageShow = /* isButtonDisabled ? `${strings.NO_MESSAGES}` : */ `${strings.SHOW} ${strings.MESSAGES}`;

    return (
        <Box sx={styles.container}>
            <Box sx={styles.header_container}>
                <Box sx={styles.header_content_container}>
                    <Typography sx={styles.header_title} tabIndex={0}>
                        {strings.FILTER}
                    </Typography>
                    <Box sx={styles.header_title_container} label={strings.CLOSE}>
                        <IconButton sx={styles.header_close_button} onClick={handleClose}>
                            X
                        </IconButton>
                    </Box>
                </Box>
            </Box>

            <Box sx={styles.filter_box_spacing}>
                <Box 
                    tabIndex={0} 
                    sx={styles.filter_box} 
                    style={styles.all_messages_filter_section}>
                    <Box sx={styles.all_messages_icon_box}>
                        <Inbox style={styles.all_messages_icon} />
                        <Box sx={styles.filter_container}>
                            <Typography sx={styles.filter_title}>{strings.ALL_MESSAGES}</Typography>
                        </Box>
                    </Box>
                    <Checkbox
                        sx={styles.checkbox}
                        checked={currentFilterOptions.ALL_MESSAGES}
                        disabled={currentFilterOptions.ALL_MESSAGES}
                        onChange={event => {
                            if (event.target.checked) {
                                onFilterOptionsChange(FilterChangeActions.ADD, {
                                    section: 'ALL_MESSAGES',
                                    filter: 'ALL_MESSAGES',
                                });
                            } else {
                                onFilterOptionsChange(FilterChangeActions.REMOVE, {
                                    section: 'ALL_MESSAGES',
                                    filter: 'ALL_MESSAGES',
                                });
                            }
                        }}
                    />
                </Box>
            </Box>

            <Box sx={styles.filter_box_spacing}>
                <Typography sx={styles.description_title}>{strings.OR_ONLY_SHOW}...</Typography>
                <Typography sx={styles.description_sub_title}>{strings.SELECT_AS_MANY_OPTIONS}.</Typography>
            </Box>

            {Object.keys(FilterSections).map((section, index) => (
                <Box key={`filter-section-${index}`} sx={styles.filter_box_spacing}>
                    <Typography sx={styles.section_title} tabIndex={0} role="heading">
                        {getLabel(index, strings)}
                    </Typography>
                    <Stack direction="column" sx={styles.data_stack}>
                        {FilterSections[section].map((filter, filterSectionIndex) => (
                            <Stack direction="column" key={`stack-filter-${filterSectionIndex}`} spacing={2}>
                                <Box>
                                    {SECTIONS_WITH_EXTERNAL_DATA.includes(section) ? (
                                        <Stack direction="column" sx={{ ...styles.data_stack }} maxHeight="130px" overflow="auto">
                                            {section === MessageFilterSectionsTypes.CATEGORIES && (
                                                <>
                                                    {feedFilters.data.broadcastTypes.map((broadcast, idx) => (
                                                        <Box key={`filter-box-category-${idx}`}>
                                                            <Box sx={styles.filter_box} tabIndex={0}>
                                                                <Box sx={styles.filter_container}>
                                                                    <Box sx={styles.filter_content_container}>
                                                                        <CategoryItem category={broadcast} />
                                                                        <Box sx={styles.category_names_container}>
                                                                            <Typography sx={styles.filter_title}>
                                                                                {capitilizeEachFirstLetter(broadcast.name)}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                                <Checkbox
                                                                    sx={styles.checkbox}
                                                                    checked={
                                                                        currentFilterOptions[section].find(
                                                                            categoryItem => categoryItem.typeId === broadcast.typeId
                                                                        ) !== undefined
                                                                    }
                                                                    onChange={event => {
                                                                        const filterData = {
                                                                            typeId: broadcast.typeId,
                                                                            customerId: broadcast.customerId,
                                                                        };
                                                                        if (event.target.checked) {
                                                                            onFilterOptionsChange(FilterChangeActions.ADD, {
                                                                                section,
                                                                                filter: filterData,
                                                                            });
                                                                        } else {
                                                                            onFilterOptionsChange(FilterChangeActions.REMOVE, {
                                                                                section,
                                                                                filter: filterData,
                                                                            });
                                                                        }
                                                                    }}
                                                                />
                                                            </Box>
                                                            {idx < categories.length - 1 && <Divider orientation="horizontal" sx={styles.divider} />}
                                                        </Box>
                                                    ))}
                                                </>
                                            )}
                                            {section === MessageFilterSectionsTypes.STUDENT && (
                                                <>
                                                    {feedFilters.data.contacts.map((student, idx) => {
                                                        return (
                                                            <Box key={`filter-box-student-${idx}`}>
                                                                <Box sx={styles.filter_box} tabIndex={0}>
                                                                    <Box sx={styles.filter_container}>
                                                                        <Box sx={styles.filter_content_container}>
                                                                            <StudentItem student={student} />
                                                                            <Box sx={styles.student_names_container}>
                                                                                <Typography sx={styles.filter_title}>
                                                                                    {student.firstName} {student.lastName}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                    <Checkbox
                                                                        sx={styles.checkbox}
                                                                        checked={
                                                                            currentFilterOptions[section].find(
                                                                                studentItem =>
                                                                                    JSON.stringify({
                                                                                        personId: studentItem.personId,
                                                                                        customerId: studentItem.customerId,
                                                                                    }) ===
                                                                                    JSON.stringify({
                                                                                        personId: student.personId,
                                                                                        customerId: student.customerId,
                                                                                    })
                                                                            ) !== undefined
                                                                        }
                                                                        onChange={event => {
                                                                            const filterData = {
                                                                                personId: student.personId,
                                                                                customerId: student.customerId,
                                                                            };

                                                                            if (event.target.checked) {
                                                                                onFilterOptionsChange(FilterChangeActions.ADD, {
                                                                                    section,
                                                                                    filter: filterData,
                                                                                });
                                                                            } else {
                                                                                onFilterOptionsChange(FilterChangeActions.REMOVE, {
                                                                                    section,
                                                                                    filter: filterData,
                                                                                });
                                                                            }
                                                                        }}
                                                                    />
                                                                </Box>
                                                                {idx < currentStudents.length - 1 && (
                                                                    <Divider orientation="horizontal" sx={styles.divider} />
                                                                )}
                                                            </Box>
                                                        );
                                                    })}
                                                </>
                                            )}

                                            {section === MessageFilterSectionsTypes.FROM && (
                                                <>
                                                    {feedFilters.data.from.map((school, idx) => (
                                                        <Box key={`filter-box-school-${idx}`}>
                                                            <Box sx={styles.filter_box} tabIndex={0}>
                                                                <Box sx={styles.filter_container}>
                                                                    <Box sx={styles.filter_content_container}>
                                                                        <SchoolItem school={school} />
                                                                        <Box sx={styles.school_initials_container}>
                                                                            <Typography sx={styles.filter_title} key={`school-${index}`}>
                                                                                {school.name}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                                <Checkbox
                                                                    sx={styles.checkbox}
                                                                    checked={
                                                                        currentFilterOptions[section].find(
                                                                            schoolItem => schoolItem === school.customerId
                                                                        ) !== undefined
                                                                    }
                                                                    onChange={event => {
                                                                        if (event.target.checked) {
                                                                            onFilterOptionsChange(FilterChangeActions.ADD, {
                                                                                section,
                                                                                filter: school.customerId,
                                                                            });
                                                                        } else {
                                                                            onFilterOptionsChange(FilterChangeActions.REMOVE, {
                                                                                section,
                                                                                filter: school.customerId,
                                                                            });
                                                                        }
                                                                    }}
                                                                />
                                                            </Box>
                                                            {idx < currentSchools.length - 1 && (
                                                                <Divider orientation="horizontal" sx={styles.divider} />
                                                            )}
                                                        </Box>
                                                    ))}
                                                </>
                                            )}
                                        </Stack>
                                    ) : (
                                        <Box sx={styles.filter_box} tabIndex={0}>
                                            <Box sx={styles.filter_section_box}>
                                                <GetIcon section={section} filter={filter} />
                                                <Box sx={styles.filter_container}>
                                                    <Typography sx={styles.filter_title}>{capitilizeFirstLetter(filter.toLowerCase())}</Typography>
                                                </Box>
                                            </Box>
                                            <Checkbox
                                                sx={styles.checkbox}
                                                checked={currentFilterOptions[section].includes(filter)}
                                                onChange={event => {
                                                    if (event.target.checked) {
                                                        onFilterOptionsChange(FilterChangeActions.ADD, {
                                                            section,
                                                            filter,
                                                        });
                                                    } else {
                                                        onFilterOptionsChange(FilterChangeActions.REMOVE, {
                                                            section,
                                                            filter,
                                                        });
                                                    }
                                                }}
                                            />
                                        </Box>
                                    )}

                                    {filterSectionIndex < FilterSections[section].length - 1 && (
                                        <Divider orientation="horizontal" sx={styles.divider} />
                                    )}
                                </Box>
                            </Stack>
                        ))}
                    </Stack>
                </Box>
            ))}
            <Box sx={styles.filter_button_container}>
                <Button
                    sx={styles.cancel_button}
                    onClick={() => {
                        handleClose();
                        if (!hasResultsBeenCommitted) {
                            // if the user doesn't commit the results, we reset the filter options to the default
                            // we skip this if the user has already setted filter options.
                            onFilterOptionsChange(FilterChangeActions.ADD, {
                                section: 'ALL_MESSAGES',
                                filter: 'ALL_MESSAGES',
                            });
                        }
                    }}
                >
                    {strings.CANCEL}
                </Button>
                <Button
                    sx={styles.filter_button}
                    onClick={() => {
                        replaceCurrentFilterOptions(currentFilterOptions);
                        handleClose();
                        setHasResultsBeenCommitted(true);
                    }}
                    //disabled={isButtonDisabled}
                >
                    {messageShow}
                </Button>
            </Box>
        </Box>
    );
}
