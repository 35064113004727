import HTTP from '../HTTP';

export const getTokenInfo = token => HTTP.get(`/tokens/${token}`);

export const confirmEmail = token =>
    HTTP.post(`/tokens/confirm/email`, {
        token,
    });

export const ResetPasswordService = (password, token) => HTTP.post('/tokens/reset/password', { password, token });
