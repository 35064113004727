import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Typography, Box, CircularProgress, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getMessageDetail } from 'comm-recipientapp-shared/src/api/inbox';
import { setCurrentMessageDetails, setArchivedMessageId } from 'comm-recipientapp-shared/src/store/inbox/inboxSlice';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { utils } from 'comm-recipientapp-shared';
import ActionButton from './ActionButton';
import Trash from '../Icons/Trash';
import MainHead from './MainHead';
import TabsContent from './TabsContent';
import DeleteDialog from '../DeleteDialog';
import styles from './styles';

export default function MessageDetails() {
    const { ContactTypes } = utils.constants;
    let { currentMessageDetails } = useSelector(state => state.inbox);
    const [selectedPersonId, setSelectedPersonId] = useState(currentMessageDetails.selectedPersonId);
    const [isLoading, setIsLoading] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);

    const getTabValue = tabsOrder => {
        let value = 0;
        if (tabsOrder) {
            if (tabsOrder.email && currentMessageDetails.selectedMessageType === ContactTypes.EMAIL) value = tabsOrder.email;
            if (tabsOrder.phone && currentMessageDetails.selectedMessageType === ContactTypes.PHONE) value = tabsOrder.phone;
            if (tabsOrder.sms && currentMessageDetails.selectedMessageType === ContactTypes.SMS) value = tabsOrder.sms;
        }
        return value ? value : 0;
    };
    const [tabValue, setTabValue] = useState(-1);

    const handleDialogOpen = () => {
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const dispatch = useDispatch();
    const strings = utils.useStrings();

    const validateTabsOrder = data => {
        const order = {};
        if (data.email) {
            order.email = 0;
            if (data.phone) {
                order.phone = 1;
                if (data.sms) {
                    order.sms = 2;
                }
            } else if (data.sms) {
                order.sms = 1;
            }
        } else if (data.phone) {
            order.phone = 0;
            if (data.sms) {
                order.sms = 1;
            }
        } else {
            order.sms = 0;
        }
        return order;
    };

    const getMessageContent = async personId => {
        const { customerId, jobId } = currentMessageDetails;
        const { data, status } = await getMessageDetail(customerId, jobId, personId);

        if (status === 200) {
            const tabsOrder = validateTabsOrder(data);
            if (tabValue < 0) setTabValue(getTabValue(tabsOrder));

            dispatch(
                setCurrentMessageDetails({
                    ...currentMessageDetails,
                    emailContent: data.email,
                    smsContent: data.sms,
                    phoneContent: data.phone,
                    tabsOrder,
                })
            );
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        document.body.style.backgroundColor = styles.msgDetailsBackgroundColor;
        dispatch(setArchivedMessageId(null));
    }, []);

    useEffect(() => {
        if (selectedPersonId) {
            getMessageContent(selectedPersonId);
            localStorage.setItem('currentMessageDetails', JSON.stringify(currentMessageDetails));
        }
        else {
            const localItem = localStorage.getItem('currentMessageDetails');
            currentMessageDetails = JSON.parse(localItem);
            getMessageContent(currentMessageDetails.selectedPersonId);
        }
    }, [selectedPersonId]);

    const navigate = useNavigate();
    const loginPath = '/inbox';

    const changeTabValue = newTabValue => {
        setTabValue(newTabValue);
    };

    const handleArchive = () => {
        dispatch(setArchivedMessageId(currentMessageDetails.messageId));
        navigate(loginPath);
        handleDialogClose();
    };

    return (
        <Box>
            <Box sx={styles.container}>
                <Box sx={styles.title_container}>
                    <Box sx={styles.title_box}>
                        <Button onClick={() => navigate(loginPath)}>
                            <ArrowBackIosNewIcon sx={styles.left_arrow} fontSize="small" />
                            <Typography sx={styles.inbox_button} onClick={() => navigate(loginPath)}>
                                {strings.BACK}
                            </Typography>
                        </Button>
                    </Box>
                </Box>
                <Box sx={styles.buttons_container}>
                    <ActionButton
                        Icon={() => <Trash style={styles.common_icon_button_styles} />}
                        textColor={styles.trash_button.color}
                        backgroundColor={styles.trash_button.backgroundColor}
                        onClick={handleDialogOpen}
                    >
                        {strings.DELETE}
                    </ActionButton>
                    <DeleteDialog open={openDialog} handleClose={handleDialogClose} handleArchive={handleArchive} />
                </Box>
            </Box>
            <Container maxWidth="md" sx={styles.mainContainer}>
                {isLoading ? (
                    <Box sx={styles.loader_container}>
                        <Box>
                            <Box sx={styles.loader_box}>
                                <CircularProgress sx={styles.loader} />
                            </Box>
                            <Typography>{strings.LOADING}</Typography>
                        </Box>
                    </Box>
                ) : (
                    <>
                        <MainHead
                            initialTabValue={tabValue}
                            mainTabHandler={changeTabValue}
                            selectedPersonId={selectedPersonId}
                            setSelectedPersonId={setSelectedPersonId}
                        />
                        <TabsContent tabValue={tabValue} />
                    </>
                )}
            </Container>
        </Box>
    );
}
