import theme from '../../../config/style/muiTheme';

const styles = {
    form: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '70%',
    },
    alert: {
        margin: '20px 0 10px',
    },
    field: {
        margin: '20px 0',
    },
    title: {
        marginBottom: 4,
        color: theme.palette.purple['80'],
        fontFamily: 'Hornbill',
        fontSize: '40px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '112%',
        letterSpacing: '-1.2px',
        mt:2,
    },
    submit: {
        margin: '10px auto',
        display: 'block',
        padding: '14px',
        width: '260px',
    },
    loginLink: {
        marginTop: '10px',
        textAlign: 'center',
    },
    usaLogo: {
        height: '16px',
        marginRight: '5px',
        width: '20px',
    },
    indicator: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '16px',
        fontWeight: 100,
        minHeight: '620px',
        textAlign: 'center',
    },
    indicator_value: {
        paddingTop: '25px',
    },
    auto: {
        margin: '100px auto 0',
    },
    fieldValid: {
        fontWeight: 'bold',
    },
    fieldInvalid: {
        fontWeight: 'initial',
    },
    log_in_link: {
        textDecoration: 'none',
        fontWeight: '500',
    },
    haveAccount: {
        fontWeight: 'bold',
        color: theme.palette.purple['80'],
    },
    locationTypography: { color: '#33366A', fontFamily: 'Roboto', fontWeight: 500, paddingRight: '12px' },
    locationButton: { margin: 0, padding: 0, '&:hover': { backgroundColor: 'transparent' } },
    infoIcon: {
        color: theme.palette.blue['30'],
    },
};

export default styles;
