import React from 'react';
import { env, utils } from 'comm-recipientapp-shared';
import EmailItem from './email';
import PhoneItem from './phone';
import OldPhoneItem from './OldPhoneItem';
import SmsItem from './sms';

const isPhoneVerificationEnabled = env.IS_PHONE_VERIFICATION_ENABLED === 'true';

function ContactItem({ type, item, handleClick, ...props }) {
    const renderItem = ItemType => {
        switch (ItemType) {
            case utils.constants.PHONE:
                return isPhoneVerificationEnabled ? (
                    <PhoneItem item={item} type={type} handleClick={handleClick} index={props.index} handleOnDelete={props.handleOnDelete} />
                ) : (
                    <OldPhoneItem item={item} type={type} handleClick={handleClick} index={props.index} handleOnDelete={props.handleOnDelete} />
                );
            case utils.constants.SMS:
                return <SmsItem item={item} type={type} handleClick={handleClick} index={props.index} handleOnDelete={props.handleOnDelete} />;
            case utils.constants.EMAIL:
                return <EmailItem index={props.index} type={type} item={item} handleClick={handleClick} handleOnDelete={props.handleOnDelete} />;
            default:
                return <></>;
        }
    };

    return <>{renderItem(type)}</>;
}

export default ContactItem;
