import * as React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Box, Autocomplete, TextField } from '@mui/material';

import { setCurrentChat } from 'comm-recipientapp-shared/src/store/chat/chatSlice';
import { utils, hooks } from 'comm-recipientapp-shared';
import chatIconSearch from 'comm-recipientapp-shared/src/assets/chat_icon_search.svg';
import styles from './styles';

export default function SearchBar() {
    const dispatch = useDispatch();
    const { contactsList } = useSelector(state => state.chat);
    const strings = utils.useStrings();

    const { getNameToDisplayForConversations } = hooks.chat.useChat();

    const handleOnchangeAutocomplete = (event, value) => {
        if (value !== null) {
            dispatch(setCurrentChat({...value}));
        }
    };

    return (
        <Autocomplete
            aria-label="conversation search text-area."
            data-testid={'searchbar'}
            disablePortal
            options={contactsList}
            getOptionLabel={option => getNameToDisplayForConversations(option)}
            popupIcon={<Box aria-hidden alt="chat icon search" component="img" src={chatIconSearch} />}
            fullWidth
            onChange={handleOnchangeAutocomplete}
            sx={styles.Autocomplete}
            renderInput={params => <TextField {...params} label={strings.SEARCH_DOTS} />}
        />
    );
}
