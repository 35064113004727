import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Alert, Box, Button, CircularProgress, FormHelperText, Grid, Link, TextField, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { utils, validations, actions, env } from 'comm-recipientapp-shared';
import USAFlag from 'comm-recipientapp-shared/src/assets/united_states_flag.svg';
import CanadaFlag from 'comm-recipientapp-shared/src/assets/canada_flag.svg';
import PasswordField from './PasswordField';
import styles from './styles';

function SignUpForm() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const strings = utils.useStrings();
    const { isPasswordValid, isSignUpFormValid, validatePassword, isEmailFormatValid } = validations.signup;
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [passwordValidations, setPasswordValidations] = useState({});
    const [isSubmitted, setSubmitted] = useState(false);

    // extract the location from the domain, if the domain ends in .ca, then the location is Canada
    const [currentLocation, setCurrentLocation] = useState('United States');

    const { setEmail } = actions.user;
    const { resetErrorAuth, setIsLoading, setIsSuccess } = actions.auth;
    const email = useSelector(state => state.user.email);

    const { characters, oneLowerCase, oneNumber, oneUpperCase } = passwordValidations;

    const state = useSelector(state => state.auth);

    const isLoading = state.isLoading;
    const isSuccess = state.isSuccess;
    const showErrorMessage = state.isError;

    useEffect(() => {
        dispatch(resetErrorAuth());
        return () => {
            dispatch(setIsLoading(false));
            dispatch(setIsSuccess(false));
        };
    }, []);

    useEffect(() => {
        setPasswordValidations(validatePassword(password));
    }, [password]);

    useEffect(() => {
        if (isSubmitted) {
            const emailValid = isEmailFormatValid(email);
            const passwordValid = isPasswordValid(password);

            setEmailError(!emailValid ? strings.ENTER_VALID_EMAIL_FORMAT : '');
            setPasswordError(!passwordValid ? strings.MAKE_SURE_YOUR_PASSWORD_MATCH_THE_REQUIREMENTS : '');
        }
    }, [email, password, isSubmitted]);

    const handleChangePassword = event => setPassword(event.target.value);
    const handleChangeEmail = event => dispatch(setEmail(event.target.value));

    const formHasValues = useMemo(() => email.length >= 1 && password.length >= 1, [email, password]);

    const handleSubmit = async e => {
        e.preventDefault();
        setSubmitted(true);
        if (isSignUpFormValid(email, password) && isEmailFormatValid(email) && isPasswordValid(password)) {
            await dispatch(actions.auth.signUp({ email, password }));
        }
    };

    const goToLogin = () => {
        navigate('/login');
    };

    const redirectToNewDomain = () => {
        if (env.IS_DEV) {
            return;
        }

        if (currentLocation === 'Canada') {
            // get the current domain and replace the .ca with .com

            const domain = window.location.hostname;

            const domainParts = domain.split('.');

            domainParts[domainParts.length - 1] = 'com';

            const newDomain = domainParts.join('.');

            window.location.href = `https://${newDomain}/#/signup`;

            return;
        }

        if (currentLocation === 'United States') {
            const domain = window.location.hostname;

            const domainParts = domain.split('.');

            domainParts[domainParts.length - 1] = 'ca';

            const newDomain = domainParts.join('.');

            window.location.href = `https://${newDomain}/#/signup`;
        }
    };

    useEffect(() => {
        const domain = window.location.hostname;
        const domainParts = domain.split('.');
        const domainExtension = domainParts[domainParts.length - 1];
        setCurrentLocation(domainExtension === 'ca' ? 'Canada' : 'United States');
    }, []);

    if (!isLoading && isSuccess) {
        return (
            <Box sx={styles.indicator}>
                <Typography sx={{ margin: '100px 0 30px 0' }} variant="h4">
                    {strings.YOU_ARE_ALMOST_SET}!
                </Typography>
                <Typography variant="">
                    {strings.PLEASE_VERIFY_YOUR_ACCOUNT_BY_FOLLOWING_INSTRUCTIONS_WE_SENT_TO}{' '}
                    <Typography component="span" sx={{ fontWeight: 'bold' }}>
                        {email}
                    </Typography>
                </Typography>
                <Button onClick={goToLogin} sx={{ margin: '20px 80px' }} variant="contained">
                    {strings.BACK_TO_LOGIN}
                </Button>
                <Typography component="p" sx={styles.loginLink}>
                    {strings.HAVE_AN_ACCOUNT}{' '}
                    <Typography sx={styles.haveAccount} component="a" onClick={goToLogin}>
                        {strings.LOG_IN}
                    </Typography>
                </Typography>
            </Box>
        );
    }

    return (
        <>
            <Box sx={{ ...styles.indicator, display: isLoading ? 'flex' : 'none' }}>
                <CircularProgress role="status" aria-live="assertive" aria-label={strings.LOADING} style={styles.auto} size={30} />
                <Typography style={styles.indicator_value} variant="span">
                    {`${strings.CREATING_ACCOUNT_FOR} `} {email}
                </Typography>
            </Box>
            <Box
                autoComplete="off"
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ ...styles.form, display: state.isLoading ? 'none' : 'flex' }}
            >
                <Typography tabIndex={0} sx={styles.title}>
                    {strings.SIGN_UP}
                </Typography>
                <Alert icon={<InfoOutlinedIcon sx={styles.infoIcon} />} tabIndex={0} sx={styles.alert} severity="info">
                    {strings.EMAIL_ADDRESS_ALERT}.
                </Alert>
                <TextField
                    error={!!emailError}
                    id="email-field"
                    helperText={emailError}
                    label={strings.EMAIL}
                    onChange={handleChangeEmail}
                    sx={styles.field}
                    variant="standard"
                    value={email}
                />
                <PasswordField
                    error={!!passwordError}
                    id="password-field"
                    helperText={<span aria-live="assertive">{passwordError}</span>}
                    label={strings.PASSWORD}
                    onChange={handleChangePassword}
                    sx={styles.field}
                    value={password}
                />
                <Grid container spacing={0}>
                    <Grid item xs={6} sm={4}>
                        <FormHelperText
                            sx={oneLowerCase ? styles.fieldValid : styles.fieldInvalid}
                            aria-label={oneLowerCase ? strings.ONE_LOWERCASE_LETTER_ALREADY_ADDED : strings.ONE_LOWERCASE_LETTER_REQUIRED}
                        >
                            {strings.ONE_LOWERCASE_LETTER}
                        </FormHelperText>
                        <FormHelperText
                            aria-label={oneUpperCase ? strings.ONE_UPPERCASE_LETTER_ALREADY_INCLUDED : strings.ONE_UPPERCASE_LETTER_REQUIRED}
                            sx={oneUpperCase ? styles.fieldValid : styles.fieldInvalid}
                        >
                            {strings.ONE_UPPERCASE_LETTER}
                        </FormHelperText>
                    </Grid>
                    <Grid item sm={4} xs={6}>
                        <FormHelperText
                            aria-label={oneNumber ? strings.YOU_ALREADY_HAVE_AT_LEAST_ONE_NUMBER : strings.AT_LEAST_ONE_NUMBER_IS_REQUIRED}
                            sx={oneNumber ? styles.fieldValid : styles.fieldInvalid}
                        >
                            {strings.ONE_NUMBER}
                        </FormHelperText>
                        <FormHelperText
                            aria-label={
                                characters ? strings.YOU_ALREADY_HAVE_BETWEEN_6_AND_255_CHARACTERS : strings.MUST_BE_BETWEEN_6_AND_255_CHARACTERS
                            }
                            sx={characters ? styles.fieldValid : styles.fieldInvalid}
                        >
                            {strings.SIGN_UP_CHARACTERS}
                        </FormHelperText>
                    </Grid>
                </Grid>
                <TextField
                    InputProps={{
                        startAdornment: <Box alt="" component="img" src={currentLocation === 'Canada' ? CanadaFlag : USAFlag} sx={styles.usaLogo} />,
                        readOnly: true,
                    }}
                    id="location-field"
                    label={strings.LOCATION}
                    sx={styles.field}
                    variant="standard"
                    value={currentLocation === 'United States' ? strings.UNITED_STATES : 'Canada'}
                />
                <Box sx={{ display: 'flex', gap: '10px' }}>
                    <Typography sx={styles.locationTypography}>
                        {strings.SIGN_UP_LOCATION_TEXT} {currentLocation === 'Canada' ? strings.SIGN_UP_LOCATION_US : 'Canada'}?
                    </Typography>{' '}
                    <Button onClick={redirectToNewDomain} sx={styles.locationButton}>
                        {strings.SIGN_UP_LOCATION_BUTTON}
                    </Button>
                </Box>
                <Button disabled={!formHasValues} sx={styles.submit} type="submit" variant="contained">
                    {strings.SIGN_UP}
                </Button>
                {showErrorMessage && (
                    <Alert tabIndex={0} aria-live="assertive" sx={{ marginTop: '30px' }} severity="error">
                        {strings.THIS_USER_ALREADY_EXISTS}
                    </Alert>
                )}
                <Typography component="p" sx={styles.loginLink}>
                    {strings.HAVE_AN_ACCOUNT}{' '}
                    <Link component={RouterLink} to="/login" sx={styles.log_in_link}>
                        {strings.LOG_IN}
                    </Link>
                </Typography>
            </Box>
        </>
    );
}

export default SignUpForm;
