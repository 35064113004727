/* eslint-disable prettier/prettier */
/* eslint-disable import/prefer-default-export */
import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import { actions, validations } from '../../index';

export const useForgotPasswordFormFields = () => {
    const dispatch = useDispatch();
    const { isLoading, isSuccess, errorMessage } = useSelector(state => state.user);
    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const onEmailChange = event => {
        setEmail(event.target.value);
    };

    const isDuplicatedRequest = useMemo(() => {
        return errorMessage?.status === 409;
    }, [errorMessage]);
    useEffect(() => {
        setIsEmailValid(validations.forgotPassword.isForgotPasswordEmailValid(email));
    }, [email]);

    useEffect(() => {
        return () => dispatch(actions.user.clearError());
    }, []);

    const handleForgotpasswordSubmit = event => {
        event.preventDefault();
        setIsSubmitted(true);
        dispatch(actions.user.clearError());
        dispatch(actions.user.resetPasswordRequest(email));
    };

    return {
        email,
        handleForgotpasswordSubmit,
        isEmailValid,
        isForgotPasswordSuccess: isSuccess,
        isForgotPasswordSubmitted: isSubmitted,
        isForgotPasswordLoading: isLoading,
        onEmailChange,
        isDuplicatedRequest,
    };
};
