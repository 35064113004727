import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { utils } from 'comm-recipientapp-shared';
import { handleEnterKeyPress } from 'comm-recipientapp-shared/src/utils/helpers';
import SubTitle from '../../Shared/SubTitle';
import styles from '../styles';

function Schools({ activeSchool, schools, handleChangeSchool }) {
    const strings = utils.useStrings();
    return (
        <Box sx={styles.page_item}>
            <SubTitle sx={styles.header_title}>{strings.SCHOOL_DISTRICT}</SubTitle>
            <Box sx={styles.school_list} aria-label={strings.MY_DISTRICTS}>
                {schools?.map((school, index) => (
                    <Box
                        key={school.id}
                        sx={activeSchool.id === school.id ? styles.school_box : styles.school_box_inactive}
                        data-testid={`change_school_${index}`}
                        onClick={() => handleChangeSchool(school)}
                        onKeyDown={handleEnterKeyPress(() => handleChangeSchool(school))}
                        tabIndex="0"
                        aria-label={`${strings.SELECT_DISTRICT}: ${school.name} ${activeSchool.id === school.id ? strings.CURRENTLY_SELECTED : ''}`}
                    >
                        <Typography variant="subtitle" color={activeSchool.id === school.id ? styles.white : 'primary'} aria-hidden>
                            {school.name}
                        </Typography>
                    </Box>
                ))}
            </Box>
        </Box>
    );
}

export default Schools;
