import HTTP from '../HTTP';
import { WEB_CLIENT_HEADER, MOBILE_CLIENT_HEADER } from '../../utils/constants';
import env from '../../env';

const isMobile = env.IS_MOBILE !== 'false';

export const login = (data, platform) => {
    const clientHeader = isMobile ? MOBILE_CLIENT_HEADER[platform] : WEB_CLIENT_HEADER;
    return HTTP.post('oauth/token', data, {
        headers: {
            Authorization: 'Basic anNvbi1jbGllbnQ6c2VjcmV0',
            'Content-Type': 'application/json',
            'X-App-Client': clientHeader,
        },
    });
};

export const resendConfirmEmailRequest = (userId, data) => {
    return HTTP.post(`portal/users/${userId}/emailConfirmRequest`, { username: data.username, password: data.password });
};

export const resendPowerschoolConfirmEmailRequest = userId => {
    return HTTP.post(`powerschoolusers/${userId}/emailConfirmRequest`);
};

export const signup = data => {
    return HTTP.post('portal/users', data);
};

export const logout = () => {
    return HTTP.delete('oauth/token');
};
