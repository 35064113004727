/* eslint-disable prettier/prettier */
import React from 'react';
import {
    Box,
    Typography,
    Avatar
} from "@mui/material";
import { utils } from 'comm-recipientapp-shared';
import styles from '../styles';

function Dependent ({dependent, index}) {
    const { stringAvatar, getConsistentColorFromString } = utils.avatar;
    const { firstName, lastName } = dependent;
    const dependentFullName = `${firstName} ${lastName}`;
    const dependentAvatarColor = getConsistentColorFromString(dependentFullName);

    return (
        <Box 
            sx={styles.avatarLineItem} 
            key={index}>
            <Avatar
                sx={{...styles.dependentIcon, bgcolor: dependentAvatarColor}}
                variant="rounded">
                    {stringAvatar(dependentFullName).children}
            </Avatar>
            <Typography noWrap>
                {dependentFullName}
            </Typography>
        </Box>
    )
}
export default Dependent;
