import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';
import { utils } from 'comm-recipientapp-shared';
import styles from "./styles";


function ModalError({ visible, handleClose, selectedPhone }) {
    const strings = utils.useStrings();

    return (
        <Dialog maxWidth="sm" open={visible} onClose={handleClose}>
            <DialogTitle sx={styles.dialogHeader}>
                <WarningIcon />
                <Typography sx={styles.dialogTitle}>{strings.WARNING}</Typography>
            </DialogTitle>
            <DialogContent style={styles.modalContent}>
               <Typography style={styles.errorLegend}>{strings.THIS_PHONE} { utils.helpers.formatPhoneNumber(selectedPhone) } {strings.CANT_BE_DELETED_AS_YOUR_ORGANIZATION_REQUIRES_AT_LEAST_1_PHONE_TO_BE_AVAILABLE_FOR_CONTACT}</Typography>
               <Button
                    color="primary"
                    data-testid="button-login"
                    fullWidth
                    sx={styles.button_ok}
                    onClick={handleClose}
                    variant="contained"
                >
                    {strings.OK}
                </Button>
            </DialogContent>
        </Dialog>
    )
}

export default ModalError;
