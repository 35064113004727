/* eslint-disable prettier/prettier */
import React from 'react';
import { NavLink } from 'react-router-dom';
import {
    Box,
    Button,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Typography,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import InboxIcon from '../../Utils/Icons/Inbox';
import conversationSvg from 'comm-recipientapp-shared/src/assets/images/conversation.svg';
import styles from './styles';
import { utils } from 'comm-recipientapp-shared';

function LoginErrorDialog ({loginFailed}) {
    const strings = utils.useStrings();

    return (
        <Dialog 
            open={loginFailed}
            data-testid="login-failed-dialog">
            <DialogTitle>
                <NavLink to="/inbox">
                    <IconButton sx={styles.closeButton}>
                        <CloseIcon />
                    </IconButton>
                </NavLink>
            </DialogTitle>
            <DialogContent sx={styles.dialogContent}>
                <Box
                    sx={styles.logo}
                    component="img"
                    src={conversationSvg}
                />
                <DialogContentText sx={styles.title}>
                    {strings.CHAT_FEATURE_UNAVAILABLE}
                </DialogContentText>
                <DialogContentText>
                    {strings.SORRY_CHAT_UNAVAILABLE}
                </DialogContentText>
                <NavLink to="/inbox">
                    <Button 
                        variant="contained" 
                        color="primary" 
                        sx={styles.returnButton}>
                        <InboxIcon style={styles.inboxButton} />
                        <Typography sx={styles.inboxText}>
                            {strings.RETURN_TO_INBOX}
                        </Typography>
                    </Button>
                </NavLink>
            </DialogContent>
        </Dialog>
    );
}
export default LoginErrorDialog;

