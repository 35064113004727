import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { utils, validations } from 'comm-recipientapp-shared';
import StudentItem from './Item';
import styles from '../styles';

function StudentList({ students, handleClick, handleClose, currentOrgName, ...props }) {
    const [showPassword, setShowPassword] = useState(false);
    const [pinValue, setPinValue] = useState(props.pinValue?.toString());
    const strings = utils.useStrings();

    const handleChangePinValue = e => {
        setPinValue(e.target.value);
        props.handlePinChange(e);
    };

    React.useEffect(() => {
        setPinValue(props.pinValue?.toString());
    }, [props.pinValue]);

    return (
        <>
            <Box sx={styles.studentList}>
                {students.map((student, i) => (
                    <StudentItem
                        key={`${student.customerId}_${student.organizationId}_${student.personId}`}
                        index={i}
                        student={student}
                        handleClick={handleClick}
                    />
                ))}
            </Box>
            <Box sx={styles.show_pin}>
                <Typography sx={styles.yourPin}>{strings.YOUR_4_DIGIT_PIN}</Typography>
                <Box sx={styles.content_pin}>
                    <input
                        onChange={handleChangePinValue}
                        style={{
                            ...styles.input,
                            border: props.validPin ? styles.inputValid : styles.inputInValid,
                        }}
                        autoComplete="new-password"
                        type={showPassword ? 'text' : 'password'}
                        maxLength="4"
                        value={pinValue}
                        data-testid="password"
                        onKeyPress={event => {
                            if (!validations.common.onlyNumbers.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                    />
                </Box>
                <Tooltip title={strings.TOGGLE_PASSWORD_VISIBILITY}>
                    <IconButton aria-label={strings.TOGGLE_PASSWORD_VISIBILITY} onClick={() => setShowPassword(!showPassword)} data-testid="show-pin">
                        {showPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                    </IconButton>
                </Tooltip>
            </Box>
            <Box sx={styles.saveActions}>
                <Button color="primary" data-testid="button-login" fullWidth sx={styles.cancel_button} variant="outlined" onClick={handleClose}>
                    {strings.CANCEL}
                </Button>
                <Button
                    color="primary"
                    data-testid="button-login"
                    fullWidth
                    sx={styles.button}
                    disabled={!props.validPin}
                    onClick={props.handleSubmit}
                    variant="contained"
                >
                    {strings.SAVE}
                </Button>
            </Box>
        </>
    );
}

export default StudentList;
