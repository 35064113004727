import theme from '../../../config/style/muiTheme';

const styles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        background: 'white',
        height: '50px',
        boxShadow: '1px 2px 3px rgb(0 0 0 / 15%)',
    },
    month_and_year_typography: {
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '50px',
        color: '#2C5871',
        textTransform: 'capitalize',
    },
    change_calendar_view_container: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '10px',
    },
    change_calendar_view_buttons_container: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    change_calendar_view_button_element: {
        background: 'transparent',
        '&:hover': {
            background: 'transparent',
        },
    },
    change_calendar_view_buttons: {
        backgroundColor: '#FFFFFF',
        fontSize: '40px',
        borderRadius: '50%',
        padding: '10px',
        boxShadow: '0 1px 2px rgb(0 0 0 / 15%)',
        '&:hover': {
            border: `1px solid ${theme.palette.primary.main}`,
        },
        overflow: 'visible',
    },
    change_calendar_view_buttons_selected: {
        backgroundColor: theme.palette.primary.main,
    },
    calendar_icon: {
        background: theme.palette.primary.main,
    },
    list_icon: {
        background: '#FFFFFF',
    },
    popup_close_button_container: {
        display: 'flex',
        justifyContent: 'end',
    },
    change_calendar_view_buttons_state: {
        selected: theme.palette.primary.main,
        unselected: '#FFFFFF',
    },
    dialog: {
        marginTop: '0px',
    },
    popup_custom_styles: {
        width: '500px',
    },
    absence_popup_edit_state: {
        width: '100%',
        maxWidth: '650px',
    },
    loader_popup_styles: {
        maxWidth: '300px',
        width: '100%',
        maxHeight: '100px',
        backgroundColor: 'transparent',
        boxShadow: 'none',
    },
    loader_popup_dialog_styles: {
        backgroundColor: 'white',
        opacity: '0.6',
    },
    loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
};

export default styles;
