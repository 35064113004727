// sm --- Small devices (landscape phones, 576px and up)
// @media (min-width: 360px) { ... }
const EXTRA_SMALL_WINDOW_WIDTH  = 360;

// sm --- Small devices (landscape phones, 576px and up)
// @media (min-width: 576px) { ... }
const SMALL_WINDOW_WIDTH        = 576;

// md --- Medium devices (tablets, 768px and up)
// @media (min-width: 768px) { ... }
const MEDIUM_WINDOW_WIDTH       = 768;

// lg --- Large devices (desktops, 992px and up)
// @media (min-width: 992px) { ... }
const LARGE_WINDOW_WIDTH        = 992;

const MAX_TOOLTIP_LENGTH = 255;

// message title window size settings
const titleLimitValues = {
    extra_small: 5,
    small: 20,
    medium: 20,
    large:50,
}

// message content window size settings
const contentLimitValues = {
    extra_small: 5,
    small: 15,
    medium: 20,
    large:50,
}

// sender name and location name window size settings
const nameLimitValues = {
    extra_small: 5,
    small: 20,
    medium: 20,
    large:50,
}

// message date window size settings
const dateLimitValues = {
    extra_small: 20,
    small: 30,
    medium: 30,
    large:30,
}

// broadcast type window size settings
const typeLimitValues = {
    extra_small: 5,
    small: 20,
    medium: 20,
    large:20,
}

// from text window size settings
const fromValues = {
    extra_small: 4,
    small: 4,
    medium: 4,
    large:4,
}

export const isMobileSize = windowWidth => {
    return (windowWidth < MEDIUM_WINDOW_WIDTH);
};

export const getWindowWidthInfo = windowWidth => {
    let size = '';
    if (windowWidth < MEDIUM_WINDOW_WIDTH) size = 'Small devices (phones)';
    else if (windowWidth < LARGE_WINDOW_WIDTH) size = 'Medium devices (tablets)';
         else size = 'Large devices (desktops)';

    return size + ' - Window size: ' + windowWidth + ' px';
};

export const buildValueString = (strValue, objInfo, windowWidth) => {
    if (windowWidth < EXTRA_SMALL_WINDOW_WIDTH)
        return strValue.length > objInfo.extra_small ? `${strValue.slice(0, objInfo.extra_small)}...` : strValue;

    if (windowWidth < MEDIUM_WINDOW_WIDTH)
        return strValue.length > objInfo.small ? `${strValue.slice(0, objInfo.small)}...` : strValue;

    if (windowWidth < LARGE_WINDOW_WIDTH)
        return strValue.length > objInfo.medium ? `${strValue.slice(0, objInfo.medium)}...` : strValue;

    return strValue.length > objInfo.large ? `${strValue.slice(0, objInfo.large)}...` : strValue;
};

export const getMessageTitle = (title, windowWidth) => {
    return buildValueString(title, titleLimitValues, windowWidth);
};

export const getMessageContent = (content, windowWidth) => {
    return buildValueString(content, contentLimitValues, windowWidth);
};

export const getName = (name, windowWidth) => {
    return buildValueString(name, nameLimitValues, windowWidth);
};

export const getDateString = (dateString, windowWidth) => {
    return buildValueString(dateString, dateLimitValues, windowWidth);
};

export const getMessageTypeString = (typeString, windowWidth) => {
    return buildValueString(typeString, typeLimitValues, windowWidth);
};

export const getFromString = (fromString, windowWidth) => {
    return buildValueString(fromString, fromValues, windowWidth);
};

export const getTooltipContent = (content) => {
    return content.length > MAX_TOOLTIP_LENGTH ? `${content.slice(0, MAX_TOOLTIP_LENGTH)}...` : content;
};
