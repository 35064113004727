import { palette } from 'comm-recipientapp-shared';


const styles = {
    info_container: {
        width: '400px'
    },
    header: {
        background: palette.primary.dark,
        color: 'white',
        textAlign: 'center',
    },
    content: {
        margin: '10px 0',
    },
    iconBtnContainer: {
        display: 'flex',
        justifyContent: 'end',
    },
    itemContainer: {
        margin: '10px 0',
    },
    itemTitle: {
        fontWeight: '500',
        fontSize: '19.2px',
    },
    itemInfo: {
        color: palette.secondary.silver,
    },
    actionsContainer: {
        display: 'flex',
        justifyContent: 'center',
        margin: '10px 0',
    },
    ok_button: { background: 'white', color: palette.primary.main },
};

export default styles;
