import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { customersAPI } from '../../api';
import { resetState } from '../resetStore';

const initialState = {
    customers: [],
};

const customersSlice = createSlice({
    name: 'customersSlice',
    initialState,
    reducers: {
        setCustomer: (state, { payload }) => {
            state.customers = payload;
        },
    },
    extraReducers: (builder) => builder.addCase(resetState, () => initialState),
});

export const { setCustomer } = customersSlice.actions;

export const getCustomer = () => async dispatch => {
    const response = await customersAPI.getCustomer();
    dispatch(setCustomer(response.data));
};
export default customersSlice.reducer;
