/* eslint-disable prefer-destructuring */
/* eslint-disable dot-notation */
import { useState, useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { getFeedItems } from 'comm-recipientapp-shared/src/api/feed/index';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentPagination } from 'comm-recipientapp-shared/src/store/inbox/inboxSlice';
import { initialFilterOptionsState } from 'comm-recipientapp-shared/src/hooks/inbox/useFilterOptions';

export default function useFeedMessages(page = 1, itemsPerPage = 8, onSettled = () => { }) {
    const { currentFilters: activeFilters, currentSearchString } = useSelector(state => state.inbox);
    const [currentPage, setCurrentPage] = useState(page);

    const dispatch = useDispatch();

    const getOpts = () => {
        /** @type {typeof initialFilterOptionsState} */
        const currentFilters = activeFilters;

        const DEFAULT_STATUS = 'unarchived';

        const statusString = currentFilters.STATUS.join(',').toLowerCase();

        const status = `${statusString},${DEFAULT_STATUS}`;

        const opts = {
            page: currentPage,
            size: itemsPerPage,
            status: currentFilters.ALL_MESSAGES || currentFilters.STATUS.length < 1 ? DEFAULT_STATUS : status,
            search: currentSearchString,
        };

        if (currentFilters?.STUDENT?.length > 0) {
            opts['contacts'] = currentFilters.STUDENT;
        }

        if (currentFilters?.CATEGORIES?.length > 0) {
            opts['types'] = currentFilters.CATEGORIES;
        }

        if (currentFilters?.FROM?.length > 0) {
            opts['from'] = currentFilters.FROM[0];
        }
        return opts;
    };

    const currentOpts = getOpts();

    const queryData = useQuery({
        queryKey: ['feedMessages', currentOpts],
        queryFn: () => getFeedItems(currentOpts),
        initialData: {
            data: {
                items: [],
                total: 0,
            },
        },
        keepPreviousData: true,
        onSettled,
    });

    const totalItems = useMemo(() => queryData?.data?.total || 0, [queryData.data]);

    const jumpToPage = useCallback(
        /** @param {number} newPage */
        newPage => {
            const pageNumber = Math.max(1, newPage);
            setCurrentPage(pageNumber);
            dispatch(setCurrentPagination(pageNumber));
        },
        []
    );

    const resetPagination = useCallback(() => {
        setCurrentPage(1);
        dispatch(setCurrentPagination(1));
    }, []);

    return {
        ...queryData,
        jumpToPage,
        currentPage,
        resetPagination,
        currentData: queryData.data.items || [],
        totalItems
    };
}
