const styles = {
    leaveGroup: {
        outlineColor: '#33366A',
        padding: '10px',
        margin: '10px',
        color: '#BB1313',
        bgcolor: '#F2F7FA',
        width: '150px',
    },
    CircularProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-12px',
        marginLeft: '-12px',
    },
    ButttonBox: { m: 1, position: 'relative' },
};

export default styles;
