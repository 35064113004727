import theme from '../../../../config/style/muiTheme';

const styles = {
    banner_text_container: {
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
        height: '60px',
        display: 'flex',
        alignItems: 'center',
    },
    banner_text: {
        color: 'white',
        fontSize: '1.3rem',
        fontWeight: '300',
    },
    button: {
        margin: '0 5px',
        fontWeight: '300',
        lineHeight: '5px',
        borderRadius: '3px',
        height: '40px',
        color: 'white',
        boxShadow: 'none',
        textAlign: 'center',
        fontSize: '1rem',
    },
    button_container: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px',
        marginBottom: '20px',
    },
    description: {
        marginTop: '20px',
        textAlign: 'center',
        paddingLeft: '20px',
        paddingRight: '20px',
    },
};

export default styles;
