import { utils } from 'comm-recipientapp-shared';

const { EventClassNames, EventTypes, Views, EventColors } = utils.constants;

const { getConsistentColorFromString } = utils.avatar;

/**
 * @typedef {object} Absence
 * @property {string} id
 * @property {boolean} isMarked
 * @property {{ customerId: number; personId: number; organizationId: number }} student
 * @property {string} client
 * @property {{ type: string; text: string }} absenceType
 * @property {{ code: string; text: string }} reason
 * @property {{ date: string; inTime?: string; outTime?: string }[]} absenceDates
 * @property {{ isDeletable: boolean; isEditable: boolean; isUnexplained: boolean }} status
 * @property {number} createdTimestampMs
 * @property {string} createdBy
 * @property {string} comment
 * @property {{ handle: string; url: string; filename: string; mimetype: string; size: number }} attachment
 */

/**
 * @typedef {object} Student
 * @property {number} personId
 * @property {number} customerId
 * @property {number} organizationId
 * @property {string} organizationName
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} customerName
 * @property {boolean} hasSafearrivalPin
 * @property {string} pinValue
 * @property {boolean} safearrivalPinEnabled
 */

/**
 * @typedef {object} HolidayOrExamEvent
 * @property {string} date
 * @property {{ customerId: number; personId: number }[]} students
 * @property {string} type
 */

/** @typedef {'MONTH' | 'LIST'} ViewType */

/** @classdesc Class service that maps the API events to the calendar events */
class EventsService {
    /** @type {Absence[]} */
    #absenceCollection = [];

    /** @type {HolidayOrExamEvent[]} */
    #holidayAndExamCollection = [];

    /** @type {Student[]} */
    #studentCollection = [];

    /** @type {Map<string, string>} */
    #studentsColorsMap;

    /** @type {string} */
    #currentLanguage;

    /** @type {(translateString: string, options: { lng: string }) => string} */
    #translatorFunction;

    /**
     * @param {Absence[]} absenceCollection
     * @param {HolidayOrExamEvent[]} holidayAndExamCollection
     * @param {Student[]} studentCollection
     * @param {string} currentLanguage
     * @param {(translateString: string, options: { lng: string }) => string} translatorFunction
     */
    constructor(absenceCollection, holidayAndExamCollection, studentCollection, currentLanguage = 'en', translatorFunction = string => string) {
        this.#absenceCollection = absenceCollection;
        this.#holidayAndExamCollection = holidayAndExamCollection;
        this.#studentCollection = studentCollection;
        this.#currentLanguage = currentLanguage;
        this.#translatorFunction = translatorFunction;
        this.#assignRandomColorsToStudents();
    }

    get studentAssignedColors() {
        return this.#studentsColorsMap;
    }

    get allStudents() {
        return this.#studentCollection;
    }

    /**
     * Translates the given string with the i18n engine, receives a key with the path declared on the translations.json files.
     *
     * @param {string} string
     */
    #translate(string) {
        return this.#translatorFunction(string, { lng: this.#currentLanguage });
    }

    #assignRandomColorsToStudents() {
        if (this.#studentCollection?.length > 0) {
            this.#studentsColorsMap = new Map();

            this.#studentCollection.forEach(student => {
                this.#studentsColorsMap.set(
                    `${student.personId}-${student.customerId}`,
                    getConsistentColorFromString(`${student.personId}${student.firstName}${student.lastName}`)
                );
            });
        }
    }

    /**
     * @param {Absence} absence
     * @param {{ firstName: string; lastName: string }} studentNames
     */
    #generateEventTitleForAbsence(absence, studentNames) {
        const { isUnexplained } = absence.status;

        if (isUnexplained) {
            return `${studentNames.firstName} - Unexplained absence`;
        }

        return `${studentNames.firstName} - ${absence.reason.text}${isUnexplained ? '' : ` - ${absence.absenceType.text}`}`;
    }

    /** @param {Absence[]} events */
    #mapAbsencesToListEvents(events) {
        const eventsArray = [];

        // Map the absences to the calendar events but when we have a multiday absence,
        // create an event for each day of the absence
        events.forEach(_absence => {
            const absence = structuredClone(_absence);

            const studentData = this.#findStudent(absence.student.personId, absence.student.customerId, absence.student.organizationId);

            const sortedDates = absence.absenceDates.sort((a, b) => new Date(a.date) - new Date(b.date));

            absence.absenceDates.forEach(absenceDate => {
                const event = {
                    date: {
                        start: absenceDate,
                        end: sortedDates.length > 1 ? sortedDates[sortedDates.length - 1] : null,
                        eventStartDate: absence.absenceType.type === 'multiDay' ? sortedDates[0] : null,
                    },
                    reason: absence.reason.text,
                    reasonCode: absence.reason.code,
                    typeOfAbsence: absence.absenceType.text || 'Unkown',
                    student: studentData || {},
                    assignedColor: this.#studentsColorsMap.get(`${absence.student.personId}-${absence.student.customerId}`),
                    isMultiDay: absence.absenceType.type === 'multiDay',
                    absenceType: absence.absenceType.type,
                    id: absence.id,
                    status: absence.status,
                };

                if (absence.comment) {
                    event.comment = absence.comment;
                }

                if (absence.attachment) {
                    event.attachment = absence.attachment;
                }

                eventsArray.push(event);
            });
        });

        return eventsArray;
    }

    /** @param {Absence[]} events */
    #mapAbsencesToCalendarEvents(events) {
        const absencesMapped = events.map(_absence => {
            const absence = structuredClone(_absence);

            const { status } = _absence;

            const sortedDates = absence.absenceDates.sort((a, b) => new Date(a.date) - new Date(b.date));

            const studentData = this.#findStudent(absence.student.personId, absence.student.customerId, absence.student.organizationId);

            const studentColor = this.#studentsColorsMap?.get(`${absence.student.personId}-${absence.student.customerId}`);

            const endDate = sortedDates.length > 1 ? new Date(sortedDates[sortedDates.length - 1].date) : null;

            // workaround for end date bug in react fullCalendar
            // see more: https://stackoverflow.com/questions/27407052/fullcalendar-end-date-wrong-by-one-day
            // https://github.com/fullcalendar/fullcalendar/issues/3679
            if (endDate !== null) {
                endDate.setUTCHours(23, 59);
            }

            const event = {
                start: sortedDates[0].date,
                end: endDate,
                customProps: {
                    date: {
                        start: sortedDates.length >= 1 ? sortedDates[0] : null,
                        end: sortedDates.length > 1 ? sortedDates[sortedDates.length - 1] : null,
                    },
                    reason: status.isUnexplained ? 'Unexplained' : absence.reason.text,
                    reasonCode: absence.reason.code,
                    typeOfAbsence: status.isUnexplained
                        ? this.#translate('AttendanceConfiguration.unexplainedAbsences.ABSENCE_CALENDAR_TYPE')
                        : absence.absenceType.text,
                    student: studentData || {},
                    assignedColor: studentColor,
                    absenceType: absence.absenceType.type,
                    id: absence.id,
                    status: absence.status,
                },
                title: this.#generateEventTitleForAbsence(absence, {
                    firstName: studentData?.firstName,
                    lastName: studentData?.lastName,
                }),
                classNames: [EventClassNames.BASE],
                backgroundColor: studentColor,
            };

            if (absence.comment) {
                event.customProps.comment = absence.comment;
            }

            if (absence.attachment) {
                event.customProps.attachment = absence.attachment;
            }

            return event;
        });

        return absencesMapped;
    }

    /** @param {HolidayOrExamEvent[]} events */
    #mapHolidaysToCalendarEvents(events) {
        const holidays = events.filter(holiday => holiday.type === EventTypes.HOLIDAY);

        const holidaysMapped = holidays.map(holiday => {
            const studentData = [];

            holiday.students.forEach(student => {
                const studentFound = this.#findStudent(student.personId, student.customerId);

                const studentColor = this.#studentsColorsMap?.get(`${studentFound.personId}-${studentFound.customerId}`);

                if (studentFound) {
                    studentData.push({ ...studentFound, assignedColor: studentColor });
                }
            });

            const eventTitle = this.#translate('AttendanceConfiguration.holidaysConfiguration.types.HOLIDAY');

            const isOldHoliday = new Date(holiday.date) < new Date();

            return {
                start: holiday.date,
                end: holiday.date,
                customProps: {
                    reason: eventTitle,
                    students: studentData,
                    type: holiday.type,
                },
                title: eventTitle,
                classNames: [EventClassNames.BASE, isOldHoliday ? '' : EventClassNames.FUTURE_EXAM_OR_HOLIDAY],
                textColor: EventColors.EXAM_OR_HOLIDAY_TEXT_COLOR,
                backgroundColor: isOldHoliday ? EventColors.PAST_EXAM : EventColors.FUTURE_EXAM,
            };
        });

        return holidaysMapped;
    }

    /** @param {HolidayOrExamEvent[]} events */
    #mapExamsToCalendarEvents(events) {
        const exams = events.filter(exam => exam.type === EventTypes.EXAM);

        const exampsMapped = exams.map(exam => {
            const studentData = [];

            exam.students.forEach(student => {
                const studentFound = this.#findStudent(student.personId, student.customerId);

                const studentColor = this.#studentsColorsMap?.get(`${studentFound.personId}-${studentFound.customerId}`);

                if (studentFound) {
                    studentData.push({ ...studentFound, assignedColor: studentColor });
                }
            });

            const eventTitle = this.#translate('AttendanceConfiguration.holidaysConfiguration.types.EXAM');

            const isOldExam = new Date(exam.date) < new Date();

            return {
                start: exam.date,
                end: exam.date,
                customProps: {
                    reason: eventTitle,
                    students: studentData,
                    type: exam.type,
                },
                title: eventTitle,
                classNames: [EventClassNames.BASE, isOldExam ? '' : EventClassNames.FUTURE_EXAM_OR_HOLIDAY],
                textColor: EventColors.EXAM_OR_HOLIDAY_TEXT_COLOR,
                backgroundColor: isOldExam ? EventColors.PAST_EXAM : EventColors.FUTURE_EXAM,
            };
        });

        return exampsMapped;
    }

    /**
     * @param {number} personId
     * @param {number} customerId
     * @param {number} [organizationId]
     * @returns {Student}
     */
    #findStudent(personId, customerId, organizationId) {
        const student = this.#studentCollection.find(student => {
            if (organizationId) {
                return student.personId === personId && student.customerId === customerId && student.organizationId === organizationId;
            }

            return student.personId === personId && student.customerId === customerId;
        });

        return student || null;
    }

    /**
     * Main mehtod that returns the calendar events from all the students
     *
     * @param {ViewType} viewType
     */
    getApiEventsMappedToCalendarEvents(viewType = Views.MONTH) {
        if (viewType === Views.MONTH) {
            const absences = this.#mapAbsencesToCalendarEvents(this.#absenceCollection);
            const holidays = this.#mapHolidaysToCalendarEvents(this.#holidayAndExamCollection);
            const exams = this.#mapExamsToCalendarEvents(this.#holidayAndExamCollection);

            return absences.concat(holidays).concat(exams);
        }

        const absences = this.#mapAbsencesToListEvents(this.#absenceCollection);

        return absences;
    }

    /**
     * Method to get the calendar events for a specific student
     *
     * @param {number} personId
     * @param {number} customerId
     * @param {number} organizationId
     * @param {ViewType} viewType
     */
    getStudentEventsByIds(personId, customerId, organizationId, viewType = Views.MONTH) {
        const studentAbsences = this.#absenceCollection.filter(
            absence =>
                absence.student.personId === personId &&
                absence.student.customerId === customerId &&
                absence.student.organizationId === organizationId
        );

        if (viewType === Views.MONTH) {
            const studentHolidaysAndExams = this.#holidayAndExamCollection.filter(holiday =>
                holiday.students.some(student => student.personId === personId && student.customerId === customerId)
            );

            const absences = this.#mapAbsencesToCalendarEvents(studentAbsences);
            const holidays = this.#mapHolidaysToCalendarEvents(studentHolidaysAndExams);
            const exams = this.#mapExamsToCalendarEvents(studentHolidaysAndExams);

            return absences.concat(holidays).concat(exams);
        }

        const absences = this.#mapAbsencesToListEvents(studentAbsences);

        return absences;
    }

    /** @param {string} language */
    changeTranslationLanguage(language) {
        this.#currentLanguage = language;
    }
}

export default EventsService;
