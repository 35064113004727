import React, { useMemo } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { utils } from 'comm-recipientapp-shared';
import GenericError from '../Error';
import styles from '../../styles';

export default function DeleteAbsence({ studentFirstName, errorMessage, handleClose }) {
    const { ABSENCE_DELETE_TITLE, ABSENCE_DELETE_DELETE_MESSAGE } = utils.useStrings();

    const [firstSentence, secondSentence] = useMemo(
        () => ABSENCE_DELETE_DELETE_MESSAGE.split('/'),
        []
    );

    return (
        <>
            <Box sx={styles.delete_absence_container}>
                <Box sx={styles.delete_header_container}>
                    <Typography sx={styles.delete_absence_title}>
                        {ABSENCE_DELETE_TITLE}?
                    </Typography>
                    <IconButton sx={styles.close_icon} onClick={handleClose}>
                        <CloseIcon sx={styles.popup_close_button} />
                    </IconButton>
                </Box>
                <Typography sx={styles.delete_absence_message}>
                    {firstSentence} {studentFirstName} {secondSentence}.
                </Typography>
            </Box>
            {errorMessage && <GenericError title="Error" errorMessage={errorMessage} />}
        </>
    );
}
