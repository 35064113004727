import React from 'react'
import { Box, Button, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { utils } from 'comm-recipientapp-shared';
import styles from '../styles';

const { Navigate } = utils.constants;

/**
 * @typedef {object} ToolbarProps
 * @property {(targetNavigation: string) => void} handleNavigation
 * @property {string} currentMonth
 * @property {string} currentYear
 * @param {React.PropsWithChildren<ToolbarProps>} props
 */
export default function Toolbar(props) {
    const { handleNavigation, currentMonth, currentYear } = props;

    return (
        <Box sx={styles.toolbar}>
            <Button disableTouchRipple onClick={() => handleNavigation(Navigate.PREVIOUS)}>
                <KeyboardArrowLeftIcon />
            </Button>
            <Box sx={styles.month_container}>
                <Box>
                    <Typography variant='h2' sx={styles.month_and_year_typography}>
                        {currentMonth} {currentYear}
                    </Typography>
                </Box>
            </Box>
            <Button disableTouchRipple onClick={() => handleNavigation(Navigate.NEXT)}>
                <KeyboardArrowRightIcon />
            </Button>
        </Box>
    );
}
