import theme from '../../../config/style/muiTheme';

const styles = {
    rootCard: {
        display: 'flex',
        backgroundColor: 'background.light',
        flexFlow: 'column',
        left: '50%',
        height: '70vh',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        [theme.breakpoints.up('xl')]: {
            width: '30%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '50%',
        },
        [theme.breakpoints.up('sm')]: {
            width: '50%',
        },
        [theme.breakpoints.up('sm')]: {
            width: '70%',
            transform: 'translate(-50%, -50%)',
            top: '50%',
            left: '50%',
            borderRadius: '12px',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
            borderRadius: '0',
        },
    },
    cardHeader: {
        borderBottom: '2px solid #F0F0F0',
        padding: theme.spacing(4, 3, 0, 3),
    },
    closeButtonGrid: {
        padding: theme.spacing(1, 1, 0, 0),
    },
    closeButton: {
        background: theme.palette.grey[270],
        justifyContent: 'flex-end',
    },
    headerTitle: {
        fontWeight: 700,
        fontSize: '1.5rem',
        color: '#283056',
    },
    contactList: {
        marginBottom: -2,
    },
    listItem: {
        padding: "0",
    },
    contactListItem: {
        justifyContent: 'flex-start',
    },
    closeIcon: {
        justifyContent: 'flex-end',
    },
    cardContent: {
        overflow: 'auto',
    },
    headerSubtitle: {
        fontSize: '1rem',
        marginBottom: 2,
    },
    searchContainer: {
        marginBottom: 2,
    },
    buttonGroup: {
        width: '100%',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.grey[230],
        padding: '12px 16px',
        boxSizing: 'border-box',
    },
    cancelButton: {
        background: theme.palette.common.white,
        border: `2px solid ${theme.palette.grey[230]}`,
        borderRadius: '10px',
        fontWeight: 500,
        fontSize: '16px',
        padding: '7px 12px 9px',
        color: theme.palette.purple[45],
    },
    startChatButton: {
        border: `2px solid ${theme.palette.grey[230]}`,
        borderRadius: '10px',
        fontWeight: 500,
        fontSize: '16px',
        padding: '7px 12px 9px',
        color: theme.palette.common.white,
        background: theme.palette.purple[45],
        '&:hover': {
            background: theme.palette.purple[42],
        },
    },
    BoxButtonGroup: {
        mt: 'auto',
    },
    ButttonBox: { position: 'relative' },
    CircularProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-12px',
        marginLeft: '-12px',
    },
};

export default styles;
