import React from 'react';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { utils } from 'comm-recipientapp-shared';
import styles from '../styles';

function Loader() {
    const strings = utils.useStrings();
    return (
        <div style={styles.textCenter}>
            <Typography sx={styles.titleLoading}>{strings.PLEASE_WAIT}</Typography>
            <Typography sx={styles.titleDescription}>{strings.WE_ARE_SAVING_YOUR_CHANGES}</Typography>
            <CircularProgress />
        </div>
    );
}

export default Loader;
