import theme from '../../../config/style/muiTheme';

const styles = {
    containerBox: {
        flexDirection: 'column',
    },
    logoBox: {
        width: '32vh',
    },
    description: {
        color: theme.palette.purple[100],
    },
};

export default styles;
