import React from 'react';
import { Box, Typography } from '@mui/material';
import { useStrings } from 'comm-recipientapp-shared/src/utils';
import WelcomeBackgroundSVG from 'comm-recipientapp-shared/src/assets/images/bg.svg';
import WelcomeComputerImageSVG from 'comm-recipientapp-shared/src/assets/images/welcome_computer_image.svg';
import WelcomeLineSVG from 'comm-recipientapp-shared/src/assets/images/welcome_line.svg';
import styles from './styles';

export default function splashScreen() {
    const strings = useStrings();
    return (
        <Box sx={{ ...styles.splashBox }}>
            <Box sx={styles.splashTopBox}>
                <Box alt={strings.SPLASH_ALT_WElCOME_IMAGE} component="img" src={WelcomeComputerImageSVG} sx={styles.welcomeComputerImage} />
            </Box>
            <Box sx={{ ...styles.splashBottomBox, backgroundImage: `url(${WelcomeBackgroundSVG})` }}>
                <Typography sx={{ ...styles.welcomeText, mt: 18 }}>{strings.SPLASH_WELCOME_TO("SchoolMessenger")}</Typography>
                <Box alt={strings.DECORATIVE_LINE_IMAGE} component="img" src={WelcomeLineSVG} sx={styles.WelcomeLine} />
                <Typography sx={styles.welcomeTextBody}>{strings.SPLASH_BODY_MESSAGE_PART_ONE}</Typography>
                <Typography sx={{ ...styles.welcomeTextBody, ...styles.mbForWelcomeBodyText }}>{strings.SPLASH_BODY_MESSAGE_PART_TWO}</Typography>
            </Box>
            {/*
             */}
        </Box>
    );
}
