const styles = {
    content: {
        height: 'calc(100vh - 64px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    indicator_value: {
        paddingTop: '25px'
    },
    auto: {
        margin: '0 auto'
    }
}

export default styles;