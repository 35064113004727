import theme from "../../../config/style/muiTheme";

const styles = {
    dialogHeader: {
        background: '#ffffff',
        padding: '10px 0px 0px 0px',
        textAlign: 'center',
        width: '100%',
    },
    dialogContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px 0px 10px 0px',
    },
    dialogSearch: {
        background: '#ffffff',
        padding: '20px 0px 0px 20px',
        textAlign: 'center',
        width: '100%',
    },
    dialogList: {
        background: '#ffffff',
        width: '100%',
        maxWidth: '300px',
        bgcolor: 'background.paper',
        position: 'relative',
        overflow: 'auto',
        maxHeight: '300px',
        textAlign: 'center',
        border: `1px solid ${theme.palette.grey['400']}`,
    },
    dialogIcon: {
        background: '#ffffff',
        padding: '20px 0px 0px 0px',
        textAlign: 'center',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    dialogTitle: {
        background: '#ffffff',
        padding: '0px 50px 0px 50px',
        fontSize: '1.3rem',
        fontWeight: 500,
        textAlign: 'center',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px 0px 10px 0px',
    },
    dialogButton: {
        margin: '15px',
        width: '120px',
        borderRadius: '2px',
        fontWeight: '500',
        fontSize: '1rem',
        backgroundColor: theme.palette.primary.main,
    },
    input_adornment: {
        right: '10px',
        position: 'absolute',
    },
    input_button: {
        left: '10px',
    },
    clear_icon: {
        color: '#797E8E',
    },
    input: {
        marginLeft: '-20px',
        border: 'none',
        padding: '5px 12px 5px 16px',
        borderRadius: '2px',
        backgroundColor: '#E5EFFA',
        color: '#545BE5',
        fontWeight: '500',
        fontSize: '18px',
        width: '300px',
    },
    message_error: {
        color: '#a94442',
        fontWeight: 500
    },
    message_error_content: {
        background: '#ffffff',
        width: '100%',
        maxWidth: '300px',
        bgcolor: 'background.paper',
        height: '80px',
        textAlign: 'center',
        border: `1px solid ${theme.palette.grey['400']}`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },

};

export default styles;
