import { useInfiniteQuery, useQuery, useMutation, useQueryClient } from 'react-query';
import { last } from 'ramda';
import HTTP from '../HTTP';
import { formatFeedData } from '../../utils/inboxDataHelper';
import { MessageStatusTypes, QUERY_KEY } from '../../utils/constants';

export const getMessages = () => {
    return HTTP.get('jobs', {
        params: {
            expand: 'all',
        },
    });
};

export const setJobStatus = requestBody => {
    return HTTP.post('jobs/status', requestBody);
};

export const getMessageDetail = (customerId, jobId, personId) => {
    return HTTP.get(`customers/${customerId}/jobs/${jobId}/people/${personId}/messages`);
};

export const getCustomerSettings = expansions => {
    return HTTP.get(`customers?expansions=${expansions}`);
};

export const useFeedFilters = () => {
    const { data, ...rest } = useQuery({
        queryKey: [QUERY_KEY.FEED_FILTERS],
        queryFn: () => HTTP.get('feed/filters'),
    });
    const feedFilters = data?.data;
    return { feedFilters, ...rest };
};

/**
 * @param {string} searchText - The text to search in the feed.
 * @param {Object} filters - Filters to apply to the feed.
 * @param {number} filters.contacts[0].cid - CustomerID.
 * @param {number} filters.contacts[0].pid - PersonID.
 * @param {number} filters.from
 * @param {string} filters.status - Feed status ('read', 'unread', etc.).
 * @param {number} filters.types[0].cid - BroadcastType customerId
 * @param {number} filters.types[0].tid - BroadcastTypeId
 * @param {number} pageSize - Number of items per page.
 */

export const useFeed = (searchText, filters = {}, pageSize = 10) => {
    const { feedFilters } = useFeedFilters();

    // By default status should be 'unarchived'
    // If read OR unread is selected it should be 'unarchived,read/unread'
    // If read AND unread are selected, status should be 'unarchived'
    const statusArray = filters?.status ? filters?.status?.split(',') : [];

    let status = MessageStatusTypes.UNARCHIVED.toLowerCase();
    if (statusArray.length === 1) {
        status = `${status},${statusArray[0]}`;
    }

    const { data, ...rest } = useInfiniteQuery({
        queryKey: [QUERY_KEY.FEED, searchText, filters],
        queryFn: ({ pageParam = 1 }) =>
            HTTP.get('feed', {
                params: {
                    page: pageParam,
                    search: searchText || null,
                    ...filters,
                    status,
                },
            }),
        getNextPageParam: (lastPage, pages) => {
            if (lastPage?.data?.total > pages.length * pageSize) {
                return pages.length + 1;
            }
            return undefined;
        },
        select: resp => {
            let totalFeedCount;
            const lastPage = last(resp?.pages || []);
            if (lastPage) {
                totalFeedCount = lastPage.data.total;
            }

            return {
                feed: formatFeedData(
                    resp?.pages?.flatMap(page => page?.data?.items),
                    feedFilters
                ),
                totalFeedCount,
            };
        },
    });

    return { ...data, ...rest };
};

export const useFeedDetails = ({ customerId, messageId, personId }) => {
    const { data, ...rest } = useQuery({
        queryKey: [QUERY_KEY.FEED_DETAILS, customerId, messageId, personId],
        queryFn: () => getMessageDetail(customerId, messageId, personId),
    });
    const feedDetails = data?.data;
    return { feedDetails, ...rest };
};

export const useSetFeedStatus = () => {
    const queryClient = useQueryClient();

    const { mutate, mutateAsync, ...rest } = useMutation({
        mutationFn: requestBody => {
            return HTTP.post('feed/status', requestBody);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FEED] });
        },
    });

    return { mutate, mutateAsync, ...rest };
};

export const useFeedItem = ({ customerId, messageId }) => {
    const { feedFilters } = useFeedFilters();
    const { data, ...rest } = useQuery({
        queryKey: [QUERY_KEY.FEED_ITEM, customerId, messageId],
        queryFn: () =>
            HTTP.get('feed/item', {
                params: {
                    customerId,
                    jobId: messageId,
                },
            }),
        select: resp => {
            return {
                feedItem: formatFeedData([resp.data], feedFilters),
            };
        },
    });

    return { ...data, ...rest };
};
