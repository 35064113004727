export const splitAbsencesBasedOnMultiday = data => {
    const absences = [];
    data?.absences?.forEach(item => {
        item?.absenceDates?.forEach(absenceDate => {
            const newItem = { absenceDate: absenceDate?.date, ...item };
            absences.push(newItem);
        });
    });
    return absences;
};

const groupDataByAbsenceDate = absences => {
    const tempAbsences = [];
    absences?.forEach(item => {
        const isFindItem = tempAbsences.filter(a => {
            return a.sameDayAbsence === item.absenceDate;
        });
        if (isFindItem.length === 0) {
            const sameDayItem = absences.filter(dataItem => {
                return dataItem.absenceDate === item.absenceDate;
            });
            const sameDayArray = [];
            sameDayItem.forEach(val => {
                sameDayArray.push(val);
            });
            tempAbsences.push({ sameDayAbsence: item.absenceDate, data: sameDayArray });
        }
    });
    return tempAbsences;
};

// eslint-disable-next-line import/prefer-default-export
export const getAbsencesByDate = data => {
    const absences = groupDataByAbsenceDate(splitAbsencesBasedOnMultiday(data));
    return absences.sort((a, b) => new Date(b.sameDayAbsence) - new Date(a.sameDayAbsence));
};
