/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { userAPI } from '../../api';
import { setLogout } from '../auth/authSlice';
import { languages } from '../../utils';
import { resetState } from '../resetStore';
import { getData } from '../absence/absenceSlice';
import { getData as getPrefrenceData } from '../preferences/preferenceSlide';
import env from '../../env';
import { getInitialize } from '../initialize/initializeSlice';
import { getCustomer } from '../customers/customersSlice';

const isMobile = env.IS_MOBILE !== 'false';

const initialState = {
    defaultLanguage: languages.en.value,
    language: languages.en.value,
    isLoading: false,
    showErrorMessage: false,
    errorMessage: null,
    isSuccess: false,
    account: null,
    user: null,
    isError: false,
    email: '',
    location: '',
    environment: '',
    isClaimScreenShown: {},
    uuid: '',
    isPowerschool: false,
    hashLocation: '',
};

const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {
        setIsLoading: (state, { payload }) => {
            state.isLoading = payload;
        },
        setUserAccount: (state, { payload }) => {
            state.account = payload;
        },
        setError: (state, { payload }) => {
            state.showErrorMessage = true;
            state.errorMessage = payload;
            state.isLoading = false;
        },
        clearError: state => {
            state.showErrorMessage = false;
            state.errorMessage = false;
        },
        setEmail: (state, { payload }) => {
            state.email = payload;
        },
        setLanguage: (state, { payload }) => {
            state.language = payload;
        },
        setUserLocation: (state, { payload }) => {
            state.location = payload;
        },
        setUserEnvironment: (state, { payload }) => {
            state.environment = payload;
        },
        setIsSuccess: (state, { payload }) => {
            state.isSuccess = payload;
        },
        setIsClaimScreenShown: (state, { payload }) => {
            state.isClaimScreenShown = { ...state.isClaimScreenShown, ...payload };
        },
        setUUID: (state, { payload }) => {
            state.uuid = payload;
        },
        setIsPowerschool: (state, { payload }) => {
            state.isPowerschool = payload;
        },
        setHashLocation: (state, { payload }) => {
            state.hashLocation = payload;
        },
    },
    extraReducers: builder =>
        builder.addCase(resetState, state => {
            return isMobile
                ? { ...initialState, isClaimScreenShown: state?.isClaimScreenShown, location: state?.location, uuid: state?.uuid, language: state?.language }
                : { ...initialState, language: state.language };
        }),
});

export const {
    setEmail,
    setError,
    clearError,
    setIsLoading,
    setIsSuccess,
    setLanguage,
    setUserAccount,
    setUserLocation,
    setUserEnvironment,
    setIsClaimScreenShown,
    setUUID,
    setIsPowerschool,
    setHashLocation,
} = userSlice.actions;

export const updateLang = language => async dispatch => dispatch(setLanguage(language));

export const clearStateAndLogout = () => async dispatch => {
    dispatch(setLogout(null));
    dispatch(setUserAccount(null));
    localStorage.clear();
};
export const getAccount =
    (accessToken = null) =>
    async dispatch => {
        let errorResponse = true;
        let isConfirmed = false;
        let returnMessage = '';

        try {
            const date = new Date().getTime();
            dispatch(setIsLoading(true));
            const userResponse = await userAPI.fetchUser(date);
            dispatch(setEmail(userResponse.data.logins[0].username));

            returnMessage = userResponse.data.id;

            const isUserEnabled = userResponse.data.logins?.length > 0 && userResponse.data.logins[0].confirmed;

            if (isUserEnabled || accessToken) {
                await dispatch(getInitialize());
                try {
                    const { data } = await userAPI.fetchUserAccount(date);
                    await dispatch(setUserAccount(data));
                    await dispatch(getCustomer());
                    if (isMobile) {
                        await dispatch(getPrefrenceData());
                        await dispatch(getData());
                    }
                    errorResponse = false;
                    isConfirmed = true;
                } catch (error) {
                    isConfirmed = true;
                    errorResponse = true;
                    returnMessage = 'auth error';
                }
            } else {
                errorResponse = true;
                dispatch(clearStateAndLogout());
            }
        } catch (error) {
            errorResponse = true;
            returnMessage = error?.response || error;

            dispatch(setError(returnMessage)); // Just technical errors
        } finally {
            dispatch(setIsLoading(false));
        }

        return {
            confirmed: isConfirmed,
            error: errorResponse,
            message: returnMessage,
        };
    };

export const refreshAccountInfo = () => async dispatch => {
    const date = new Date().getTime();
    const { data } = await userAPI.fetchUserAccount(date);
    await dispatch(setUserAccount(data));
};

export const logout = () => async dispatch => {
    dispatch(setLogout(null));
    dispatch(setUserAccount(null));
    dispatch(resetState());
    localStorage.clear();
};

export const resetPasswordRequest = email => async dispatch => {
    try {
        dispatch(setIsLoading(true));
        await userAPI.forgotPassword(email);
        dispatch(setIsSuccess(true));
    } catch (error) {
        dispatch(setIsSuccess(false));
        dispatch(setError(error?.response || error)); // Just technical errors
    } finally {
        dispatch(setIsLoading(false));
    }
};

export default userSlice.reducer;
