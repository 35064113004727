const styles = {
    input_adornment: {
        right: '10px',
        position: 'absolute',
    },
    input_button: {
        left: '10px',
    },
    clear_icon: {
        color: '#797E8E',
    },
    input: {
        border: 'none',
        padding: '5px 12px 5px 16px',
        borderRadius: '2px',
        maxWidth: '500px',
        backgroundColor: '#E5EFFA',
        color: '#545BE5',
        fontWeight: '500',
        fontSize: '18px',
        width: {
            xs: '200px',
            sm: '260px',
            md: '400px',
            lg: '500px',
        },
        marginRight: '10px',
    },
};

export default styles;
