import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import translations  from 'comm-recipientapp-shared/src/i18n/translations';

let isInitial = false;

function I18NConfig () {
    const { language, defaultLanguage } = useSelector(state => state.user);

    if (!isInitial) {
        i18next
        .use(initReactI18next)
        .init({
            lng: language,
            fallbackLng: defaultLanguage,
            nsSeparator: ':::',
            keySeparator: '.',
            resources: translations,
            interpolation: {
                escapeValue: false,
            },
        });
        isInitial = true;
    }

    const asyncLoadTranslations = async (language) => {
        try {
            const translations = await import(`comm-recipientapp-shared/src/i18n/${language}/translations.json`);
            i18next.addResourceBundle(language, 'translation', translations);
            i18next.changeLanguage(language);
        }
        catch (error) {
            const translations = await import(`comm-recipientapp-shared/src/i18n/${defaultLanguage}/translations.json`);
            i18next.addResourceBundle(defaultLanguage, 'translation', translations);
            i18next.changeLanguage(defaultLanguage);
        }
    }

    useEffect(() => {
        if (language) {
            if (! i18next.hasResourceBundle(language, 'translation')) {
                asyncLoadTranslations(language);
            } else {
                i18next.changeLanguage(language);
            }
        }
    }, [language]);

    return null;
}

export default I18NConfig;
