/* eslint-disable import/prefer-default-export */
import { useState } from 'react';
import { ApiInterceptorKeys } from '../../api/constants';

export function useHandleSessionExpiredSnackbar() {
    const [isSessionExpired, setIsSessionExpired] = useState(
        localStorage.getItem(ApiInterceptorKeys.EXPIRED_SESSION) === 'true',
    );

    const handleSnackbarClose = () => {
        setIsSessionExpired(false);
        localStorage.removeItem(ApiInterceptorKeys.EXPIRED_SESSION);
    };

    return { isSessionExpired, handleSnackbarClose };
}
