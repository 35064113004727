import React from 'react';
import { useHandleSessionExpiredSnackbar } from 'comm-recipientapp-shared/src/hooks/auth';
import { useHandleServerErrorSnackbar } from 'comm-recipientapp-shared/src/hooks/errors/useHandleServerError';
import ServerErrorSnackbar from './ServerErrorSnackbar';
import SessionExpiredSnackbar from './SessionExpiredSnackbar';

export default function AppSnackbars() {
    const { isSessionExpired, handleSnackbarClose } = useHandleSessionExpiredSnackbar();

    const { hasServerError, handleErrorSnackbarClose } = useHandleServerErrorSnackbar();

    if (isSessionExpired) {
        return (
            <SessionExpiredSnackbar isOpen={isSessionExpired} handleClose={handleSnackbarClose} />
        );
    }

    if (hasServerError) {
        return (
            <ServerErrorSnackbar isOpen={hasServerError} handleClose={handleErrorSnackbarClose} />
        );
    }

    return null;
}
