import theme from '../../config/style/muiTheme';

const styles = {
    main_container: {
        mt: '4px',
        height: {
            sm: 'calc(100vh - 70px)',
        },
    },
    mainStack: {
        backgroundColor: theme.palette.common.white,
        height: '100%',
        justifyContent: 'flex-start',
        pb: 2,
    },
    container: {
        padding: '50px 0px 23px 0px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
    },
    emailText: {
        fontFamily: 'Helvetica Neue',
        color: '#54565B',
        fontSize: '18px',
    },
    bodyText: {
        fontFamily: 'Helvetica Neue',
        color: '#484C59',
        fontSize: '20px',
        mx: 1,
    },
    bodyLongText: {
        fontFamily: 'Helvetica Neue',
        color: '#484C59',
        fontSize: '20px',
        width: '60%',
        mx: 2,
    },
    bodyTitle: {
        color: theme.palette.purple['80'],
        textAlign: 'center',
        fontFamily: 'Hornbill',
        fontSize: '40px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '112%',
        letterSpacing: '-1.2px',
    },
    card: {
        borderRadius: '8px',
        border: `1px solid ${theme.palette.grey['260']}`,
        background: theme.palette.common.white,
        display: 'flex',
        justifyContent: 'space-between',
        mx: 1,
    },
    cardBody: {
        padding: '18px 20px 17px 20px',
    },
    boxCard: { minWidth: 275, cursor: 'pointer' },
    cardTitleTypography: {
        color: theme.palette.purple['80'],
        fontFamily: 'Inter',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '140%',
        letterSpacing: '-0.36px',
    },
    cardBodyTypography: {
        color: theme.palette.purple['80'],
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '150%',
        letterSpacing: '-0.32px',
    },
    cardReloadIcon: {
        width: '32px',
        height: '32px',
    },
    cardChevronRightIcon: {
        width: '20px',
        height: '20px',
        mt: 'auto',
        mb: 'auto',
        mr: '20px',
    },
    bodyTextBold: {
        fontFamily: 'Helvetica Neue',
        color: '#484C59',
        fontSize: '20px',
        fontWeight: 700,
    },
    icon_container: { display: 'flex', justifyContent: 'center', marginBottom: '40px' },
    buttonContent: {
        margin: '0 auto',
        width: '100%',
        padding: '0px 0px 60px 0px',
    },
    button: {
        padding: '10px',
    },

};

export default styles;
