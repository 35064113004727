const languages = {
    "af": { "value": "af", "label": "Afrikaans" },
    "ak": { "value": "ak", "label": "Akan" },
    "am": { "value": "am", "label": "Amharic" },
    "ar": { "value": "ar", "label": "Arabic" },
    "as": { "value": "as", "label": "Assamese" },
    "ay": { "value": "ay", "label": "Aymara" },
    "az": { "value": "az", "label": "Azerbaijani" },
    "be": { "value": "be", "label": "Belarusian" },
    "bg": { "value": "bg", "label": "Bulgarian" },
    "bm": { "value": "bm", "label": "Bambara" },
    "bn": { "value": "bn", "label": "Bengali" },
    "bs": { "value": "bs", "label": "Bosnian" },
    "ca": { "value": "ca", "label": "Catalan" },
    "ceb": { "value": "ceb", "label": "Cebuano" },
    "ckb": { "value": "ckb", "label": "Kurdish (Sorani)" },
    "co": { "value": "co", "label": "Corsican" },
    "cs": { "value": "cs", "label": "Czech" },
    "cy": { "value": "cy", "label": "Welsh" },
    "da": { "value": "da", "label": "Danish" },
    "de": { "value": "de", "label": "German" },
    "doi": { "value": "doi", "label": "Dogri" },
    "dv": { "value": "dv", "label": "Maldivian" },
    "ee": { "value": "ee", "label": "Ewe" },
    "el": { "value": "el", "label": "Greek" },
    "en": { "value": "en", "label": "English" },
    "en-US": { "value": "en-US", "label": "English (United States)" },
    "eo": { "value": "eo", "label": "Esperanto" },
    "es": { "value": "es", "label": "Spanish" },
    "et": { "value": "et", "label": "Estonian" },
    "eu": { "value": "eu", "label": "Basque" },
    "fa": { "value": "fa", "label": "Persian" },
    "fi": { "value": "fi", "label": "Finnish" },
    "fr": { "value": "fr", "label": "French" },
    "fr-CA": { "value": "fr-CA", "label": "French (Canada)" },
    "fy": { "value": "fy", "label": "Western Frisian" },
    "ga": { "value": "ga", "label": "Irish" },
    "gd": { "value": "gd", "label": "Scottish Gaelic" },
    "gl": { "value": "gl", "label": "Galician" },
    "gn": { "value": "gn", "label": "Guarani" },
    "gom": { "value": "gom", "label": "Goan Konkani" },
    "gu": { "value": "gu", "label": "Gujarati" },
    "ha": { "value": "ha", "label": "Hausa" },
    "haw": { "value": "haw", "label": "Hawaiian" },
    "he": { "value": "he", "label": "Hebrew" },
    "hi": { "value": "hi", "label": "Hindi" },
    "hmn": { "value": "hmn", "label": "Hmong" },
    "hr": { "value": "hr", "label": "Croatian" },
    "ht": { "value": "ht", "label": "Haitian Creole" },
    "hu": { "value": "hu", "label": "Hungarian" },
    "hy": { "value": "hy", "label": "Armenian" },
    "id": { "value": "id", "label": "Indonesian" },
    "ig": { "value": "ig", "label": "Igbo" },
    "ilo": { "value": "ilo", "label": "Ilokano" },
    "is": { "value": "is", "label": "Icelandic" },
    "it": { "value": "it", "label": "Italian" },
    "ja": { "value": "ja", "label": "Japanese" },
    "jv": { "value": "jv", "label": "Javanese" },
    "ka": { "value": "ka", "label": "Georgian" },
    "kk": { "value": "kk", "label": "Kazakh" },
    "km": { "value": "km", "label": "Khmer" },
    "kn": { "value": "kn", "label": "Kannada" },
    "ko": { "value": "ko", "label": "Korean" },
    "kri": { "value": "kri", "label": "Krio" },
    "ku": { "value": "ku", "label": "Kurdish" },
    "ky": { "value": "ky", "label": "Kyrgyz" },
    "la": { "value": "la", "label": "Latin" },
    "lb": { "value": "lb", "label": "Luxembourgish" },
    "lg": { "value": "lg", "label": "Luganda" },
    "ln": { "value": "ln", "label": "Lingala" },
    "lo": { "value": "lo", "label": "Lao" },
    "lt": { "value": "lt", "label": "Lithuanian" },
    "lus": { "value": "lus", "label": "Mizo" },
    "lv": { "value": "lv", "label": "Latvian" },
    "mai": { "value": "mai", "label": "Maithili" },
    "mg": { "value": "mg", "label": "Malagasy" },
    "mi": { "value": "mi", "label": "Maori" },
    "mk": { "value": "mk", "label": "Macedonian" },
    "ml": { "value": "ml", "label": "Malayalam" },
    "mn": { "value": "mn", "label": "Mongolian" },
    "mr": { "value": "mr", "label": "Marathi" },
    "ms": { "value": "ms", "label": "Malay" },
    "mt": { "value": "mt", "label": "Maltese" },
    "my": { "value": "my", "label": "Burmese" },
    "ne": { "value": "ne", "label": "Nepali" },
    "nl": { "value": "nl", "label": "Dutch" },
    "no": { "value": "no", "label": "Norwegian" },
    "nr": { "value": "nr", "label": "Southern Ndebele" },
    "nso": { "value": "nso", "label": "Northern Sotho" },
    "ny": { "value": "ny", "label": "Nyanja" },
    "om": { "value": "om", "label": "Oromo" },
    "or": { "value": "or", "label": "Odia" },
    "pa": { "value": "pa", "label": "Punjabi" },
    "pl": { "value": "pl", "label": "Polish" },
    "ps": { "value": "ps", "label": "Pashto" },
    "pt": { "value": "pt", "label": "Portuguese" },
    "qu": { "value": "qu", "label": "Quechua" },
    "ro": { "value": "ro", "label": "Romanian" },
    "ru": { "value": "ru", "label": "Russian" },
    "rw": { "value": "rw", "label": "Kinyarwanda" },
    "sa": { "value": "sa", "label": "Sanskrit" },
    "sd": { "value": "sd", "label": "Sindhi" },
    "si": { "value": "si", "label": "Sinhala" },
    "sk": { "value": "sk", "label": "Slovak" },
    "sl": { "value": "sl", "label": "Slovenian" },
    "sm": { "value": "sm", "label": "Samoan" },
    "sn": { "value": "sn", "label": "Shona" },
    "so": { "value": "so", "label": "Somali" },
    "sq": { "value": "sq", "label": "Albanian" },
    "sr": { "value": "sr", "label": "Serbian" },
    "st": { "value": "st", "label": "Southern Sotho" },
    "su": { "value": "su", "label": "Sundanese" },
    "sv": { "value": "sv", "label": "Swedish" },
    "sw": { "value": "sw", "label": "Swahili" },
    "ta": { "value": "ta", "label": "Tamil" },
    "te": { "value": "te", "label": "Telugu" },
    "tg": { "value": "tg", "label": "Tajik" },
    "th": { "value": "th", "label": "Thai" },
    "ti": { "value": "ti", "label": "Tigrinya" },
    "tk": { "value": "tk", "label": "Turkmen" },
    "tl": { "value": "tl", "label": "Tagalog" },
    "tr": { "value": "tr", "label": "Turkish" },
    "ts": { "value": "ts", "label": "Tsonga" },
    "tt": { "value": "tt", "label": "Tatar" },
    "tw": { "value": "tw", "label": "Twi" },
    "ug": { "value": "ug", "label": "Uyghur" },
    "uk": { "value": "uk", "label": "Ukrainian" },
    "ur": { "value": "ur", "label": "Urdu" },
    "uz": { "value": "uz", "label": "Uzbek" },
    "vi": { "value": "vi", "label": "Vietnamese" },
    "xh": { "value": "xh", "label": "Xhosa" },
    "yi": { "value": "yi", "label": "Yiddish" },
    "yo": { "value": "yo", "label": "Yoruba" },
    "zh": { "value": "zh", "label": "Chinese (Simplified)" },
    "zh-TW": { "value": "zh-TW", "label": "Chinese (Traditional)" },
};

export default languages;
