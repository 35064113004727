import theme from '../../config/style/muiTheme';

const styles = {
    container: {
        marginTop: '40px',
    },
    title: {
        color: '#2C5871',
        fontWeight: '400',
    },
    grid: {
        margin: "60px auto 0",
        maxWidth: "1200px",
    },
    illustrationContainer: {
        display: 'flex',
        justifyContent: {
            xs: 'center',
            sm: 'center',
            md: 'center',
            lg: 'flex-start',
        },
    },
    illustration: {
        maxWidth: {
            xs: "150px",
            sm: "250px",
            md: "450px",
        },
        marginLeft: {
            xs: '0px',
            sm: '0px',
            md: '0px',
            lg: '120px'
        }
    },
    logoContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginTop: {
            xs: 5,
            sm: 5,
            md: 3,
            lg: 8,
        },
    },
    logoSm: {
        alignSelf: 'center',
        width: {
            xs: "300px",
            sm: "300px",
            md: "420px",
            lg: "420px",
        }
    },
    logoStore: {
        maxWidth: {
            xs: '140px',
            sm: '300px',
            md: '100%',
        },
        height: {
            xs: '40px',
            sm: '40px',
            md: '40px',
            lg: 'auto',
        },
    },
    storeContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        marginTop: 4,
        width: '100%',
    },
    linkContainer: {
        marginTop: 6,
        textAlign: 'center',
    },
    link: {
        cursor: 'pointer',
        textDecoration: 'none',
        fontWeight: '500'
    },
    copy: {
        bottom: 10,
        fontSize: {
            xs: '0.8rem',
            sm: '0.8rem',
            md: '0.8rem',
        },
        marginTop: 3,
        padding: '10px 30px',
        position: {
            xs: 'static',
            sm: 'static',
            md: 'static',
            lg: 'absolute',
        },
        textAlign: 'center',
        width: {
            xs: '90%',
            sm: '90%',
            md: '90%',
            lg: '70%',
        },
    },
};

export default styles;
