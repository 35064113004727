const styles = {
    button: {
        padding: "0 3px"
    },
    chip: { 
        marginBottom: '3px',
         fontWeight: '400', 
         color: '#ffffff', 
         border: '0px', 
         cursor: 'pointer'
    },
    activeChip: { 
        marginBottom: '3px', 
        fontWeight: '600', 
        color: '#ffffff', 
        border: '2px solid black', 
        cursor: 'pointer'
    }
};

export default styles;
