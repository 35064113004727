import React, { useMemo } from 'react';
import { TextField } from '@mui/material';
import styles from '../../styles';

/**
 * @typedef {object} DatePickerTextFieldProps
 * @property {string} language
 * @property {{}} params
 * @property {(date: string, language: string) => string} formatDate
 * @param {React.PropsWithChildren<DatePickerTextFieldProps>} props
 * @returns
 */
export default function DatePickerTextField({ language, params, formatDate }) {
    const formattedDate = useMemo(
        () => formatDate(params.inputProps.value, language),
        [formatDate, language, params.inputProps.value]
    );

    return (
        <TextField
            variant="standard"
            {...{
                ...params,
                inputProps: {
                    ...params.inputProps,
                    value: `${formattedDate}`,
                    disabled: true,
                    style: styles.edit_popup_calendar_date_text,
                },
            }}
            sx={styles.edit_popup_calendar_text_field}
            helperText={null}
        />
    );
}
