import React from 'react';
import { Box, Typography } from '@mui/material';
import styles from '../../styles';

export default function GenericError({ title, errorMessage }) {
    return (
        <Box sx={styles.generic_error_container}>
            <Box sx={styles.generic_error_title_container}>
                <Typography sx={styles.generic_error_title}>{title}</Typography>
            </Box>
            <Box sx={styles.generic_error_message_container}>
                <Typography sx={styles.generic_error_message}>{errorMessage}</Typography>
            </Box>
        </Box>
    );
}
