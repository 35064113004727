import theme from '../../../../config/style/muiTheme';

const styles = {
    accordionSummary: {
        '&.MuiAccordionSummary-root:hover': {
            cursor: 'default',
        }
    },
    AccordionSummaryTypography: {
        color: theme.palette.purple[70],
        '& span': {
            backgroundColor: theme.palette.grey[20],
            pr: 1,
        },
        fontWeight: '500',
        fontSize: '18px',
    },
    addChatSVGIcon: {
        width: '1.4rem',
        cursor: 'pointer',
    },
    expandless: {
        cursor: 'pointer',
    },
};

export default styles;
