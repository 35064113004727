/* eslint-disable prettier/prettier */
import React from 'react';
import {
    Box,
    Typography,
} from "@mui/material";
import AnimatedLogo from './AnimatedLogo/AnimatedLogo';
import { utils } from 'comm-recipientapp-shared';
import styles from './styles';

function Loading () {
    const strings = utils.useStrings();

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="60vh"
            sx={styles.containerBox}
            >
            <Box sx={styles.logoContainer}>
                <AnimatedLogo />
            </Box>
            <Box>
                <Typography
                    variant="h6"
                    sx={styles.loadingText}>
                    {strings.LOADING_CONVERSATIONS}
                </Typography>
                <Typography
                    variant="body2"
                    sx={styles.loadingText}>
                    {strings.THIS_WILL_ONLY_TAKE_A_MOMENT_NO_NEED_TO_REFRESH_THE_PAGE}
                </Typography>
            </Box>
        </Box>
    );
}

export default Loading;

