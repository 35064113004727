import theme from "../../config/style/muiTheme";


const styles = {
    content: {
        marginTop: '20px',
        paddingLeft: {
            lg: '0px',
            xs: '16px'
        },
        paddingRight: {
            lg: '0px',
            xs: '16px'
        },
    },
    title_container: {
        marginBottom: '15px',
        display: 'flex',
        gap: '5px',
    },
    icon_container: {
        marginTop: '30px',
    },
    icon_title: {
        fontSize: '2.5rem',
        color: '#33366A',
    },
    header_title: {
        color: '#2C5871',
        fontSize: 20,
        padding: '10px 0px 0px 0px',
    },
    contact_features: {
        display: "flex", 
        flexDirection: "row", 
        justifyContent: "space-between", 
        alignItems: "center", 
        marginTop: 5
    },
    favorites: {
        width: '100%',
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    contacts: {
        width: '100%',
    },

};

export default styles;
