import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Card, Box, Typography, useMediaQuery } from '@mui/material';
import { utils } from 'comm-recipientapp-shared';
import styles from './styles';

const { formatEventHour } = utils.attendanceUtils;

/**
 * @typedef {object} InformationCardProps
 * @property {{
 *     date: {
 *         eventStartDate: { date: string; inTime?: string; outTime?: string };
 *         start: { date: string; inTime?: string; outTime?: string };
 *         end: { date: string; inTime?: string; outTime?: string };
 *     };
 *     reason: string;
 *     reasonCode: string;
 *     student: { firstName: string };
 *     typeOfAbsence: string;
 *     title: eventTitle;
 *     assignedColor: string;
 *     isMultiDay: boolean;
 *     absenceType: string;
 *     id: string;
 *     attachment?: {
 *         handle: string;
 *         url: string;
 *         filename: string;
 *         mimetype: string;
 *         size: number;
 *     };
 *     comment?: string;
 * }} event
 * @property {(popupData) => void} handlePopupOpen
 * @param {React.PropsWithChildren<InformationCardProps>} props
 */
export default function InformationCard({ event, handlePopupOpen }) {
    const { language } = useSelector(state => state.user);

    const { stringAvatar } = utils.avatar;

    const matches = useMediaQuery('(min-width: 600px)');

    const dayOfTheMonth = useMemo(() => new Date(event.date.start.date).getUTCDate(), [event]);

    const dayOfTheWeek = useMemo(
        () =>
            new Date(event.date.start.date).toLocaleString(language, {
                weekday: 'long',
                timeZone: 'UTC',
            }),
        [event, language]
    );

    const studentInitial = useMemo(() => stringAvatar(`${event.student?.firstName || ' '} ${event.student?.lastName || ' '}`).children, [event]);

    const hourFormatted = useMemo(() => formatEventHour(event, language), [event, language]);

    const isFutureDate = useMemo(() => new Date(event.date.start.date) > new Date(), [event]);

    return (
        <Card
            data-testid="info-card"
            data-date={event.date.start.date}
            data-test
            sx={styles.card_container}
            onClick={() => handlePopupOpen(event)}
        >
            <Box style={styles.content_layout}>
                <Box style={styles.layout}>
                    <Box>
                        <Typography
                            sx={
                                isFutureDate
                                    ? {
                                          ...styles.day_of_the_month,
                                          ...styles.future_date_day_of_the_month,
                                      }
                                    : styles.day_of_the_month
                            }
                        >
                            {dayOfTheMonth}
                        </Typography>
                        <Typography
                            sx={
                                isFutureDate
                                    ? {
                                          ...styles.day_of_the_week,
                                          ...styles.future_date_day_of_the_week,
                                      }
                                    : styles.day_of_the_week
                            }
                        >
                            {dayOfTheWeek.slice(0, 3)}
                        </Typography>
                    </Box>

                    <Box sx={styles.name_and_event_data_container}>
                        <Box
                            sx={{
                                ...styles.student_initial,
                                backgroundColor: event.assignedColor,
                            }}
                        >
                            {studentInitial}
                        </Box>
                        <Box sx={{ width: matches ? '100%' : '220px' }}>
                            <Typography sx={styles.student_first_name}>
                                {event.student.firstName}
                            </Typography>
                            <Typography sx={styles.event_data}>
                                {`${event.reason} - ${event.typeOfAbsence} ${
                                    hourFormatted.length > 0 ? ' - ' : ''
                                } ${hourFormatted}`}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Card>
    );
}
