const centerVertically = {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column',
};


const styles = {
    tabContentManager: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
    },
    tabContent: {
        width: '100%',
        marginBottom: 4,
        ...centerVertically,
    },
    textContent: {
        fontFamily: 'Inter',
        fontSize: '18px',
        color: '#585988',
        lineHeight: '140%',
        textAlign: 'justify',
    },
    imgMessage: {
        marginTop: 3,
        marginBottom: 3,
    },
};

export default styles;
