import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Notification({ open, handleClose, type, ...props }) {
    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            aria-live="polite"
        >
            <Alert onClose={handleClose} severity={type}>
                {props.message}
            </Alert>
        </Snackbar>
    );
}

export default Notification;
