import React from 'react';
import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import Avatar from '../../../Avatar';
import styles from './styles';

function StudentItem({ student, index, handleClick }) {
    return (
        <Box sx={styles.item}>
            <Box sx={styles.item_info}>
                <Avatar
                    label={`${student.firstName} ${student.lastName}`}
                    stringForColor={`${student.personId}${student.firstName}${student.lastName}`}
                />
                <Typography sx={styles.studentName}>{`${student.firstName} ${student.lastName}`}</Typography>
            </Box>
            <Radio
                value={student.safearrivalPinEnabled}
                checked={student.safearrivalPinEnabled}
                name="radio-buttons"
                inputProps={{ 'aria-label': 'A' }}
                onClick={() => handleClick(index)}
            />
        </Box>
    );
}

export default StudentItem;
