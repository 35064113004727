import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Download } from '../icons';
import Popup from '../../index';
import styles from '../../styles';

/**
 * @typedef {object} AttachmentPreviewPopupProps
 * @property {(popupState?: boolean) => boolean} handleClose
 * @property {() => void} downloadAttachment
 * @property {string} attachmentUrl
 * @property {string} attachmentName
 * @property {string} attachmentMimeType
 * @property {boolean} isOpen
 * @property {number} attachmentSize
 * @property {() => JSX.Element} AttachmentIcon
 * @param {React.PropsWithChildren<AttachmentPreviewPopupProps>} props
 */
export default function AttachmentPreviewPopup(props) {
    const {
        AttachmentIcon,
        attachmentMimeType,
        attachmentName,
        attachmentSize,
        attachmentUrl,
        downloadAttachment,
        handleClose,
        isOpen,
    } = props;

    return (
        <Popup
            isOpen={isOpen}
            handleClose={() => handleClose()}
            customStyles={styles.popup_attachment_preview}
            hideCloseButton
            fullScreen
        >
            <Box sx={styles.popup_attachment_content_container}>
                <Box sx={styles.popup_attachment_content}>
                    <Box sx={styles.popup_attachment_preview_bar_container}>
                        <AttachmentIcon
                            height={styles.popup_attachment_preview_icon.height}
                            width={styles.popup_attachment_preview_icon.width}
                            fillColor={styles.popup_attachment_preview_icon.color}
                        />
                        <Typography sx={styles.popup_attachment_preview_filename}>
                            {attachmentName}
                        </Typography>
                        <Typography sx={styles.popup_attachment_preview_size}>
                            ({attachmentSize}
                            MB)
                        </Typography>
                    </Box>
                    <Box sx={styles.popup_attachment_preview_download_button_container}>
                        <IconButton onClick={downloadAttachment} data-testid='attachment-preview-download'>
                            <Download
                                height={styles.popup_attachment_preview_download_icon.height}
                                width={styles.popup_attachment_preview_download_icon.width}
                                fillColor={styles.popup_attachment_preview_download_icon.color}
                            />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    ...styles.popup_close_button_container,
                    ...styles.popup_close_button_preview_container,
                }}
            >
                <IconButton onClick={() => handleClose(false)} data-testid="close-attachment-preview-btn">
                    <CloseIcon sx={styles.popup_attachment_preview_close_icon} />
                </IconButton>
            </Box>
            <Box
                data-testid="close-attachment-preview-el"
                onClick={event => {
                    if (event.target.tagName !== 'IMG') {
                        handleClose(false);
                    }
                }}
                sx={styles.attachment_preview_image_container}
            >
                <Box sx={styles.popup_attachment_preview_image_container}>
                    {attachmentMimeType.startsWith('image/') ? (
                        <img
                            src={attachmentUrl}
                            alt="attachment_file_preview"
                            style={styles.popup_attachment_preview_image}
                        />
                    ) : (
                        <Box>
                            <AttachmentIcon
                                height={styles.popup_attachment_preview_icon_no_image.height}
                                width={styles.popup_attachment_preview_icon_no_image.width}
                                fillColor={styles.popup_attachment_preview_icon_no_image.color}
                            />
                            <Typography sx={styles.popup_attachment_preview_file_size_no_image}>
                                {attachmentName}
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Box>
        </Popup>
    );
}
