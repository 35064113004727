import { ThemeContext } from '@emotion/react';
import theme from '../../../config/style/muiTheme';

const styles = {
    formControl: { 
        marginLeft: '1%', 
        width: '98%',
        maxWidth: "230px",
    },
    avatarIcon: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        width: "23px",
        height: "23px",
        borderRadius: "50%",
        padding: "2px 3px",                                  
    },
    dependentIcon: {
        width: "23px",
        height: "23px",
        borderRadius: "50%",
        fontSize: "12px",
        mr: 1
    },
    avatarLineItem: { 
        display: "inline-flex", 
        marginTop: "5px" 
    },
    avatarBackground: {
        color: theme.palette.primary.dark,
    },
    lightPurpleText: {
        color: theme.palette.primary.light,
    },
    purpleText: {
        color: theme.palette.primary.main,
    },
    personIcon: {
        fontSize: "17px"
    }
};

export default styles;
