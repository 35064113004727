/* eslint-disable prettier/prettier */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    Container,
    Stack,
    Box,
    Typography,
    Button,
    FormHelperText,
    CircularProgress,
} from '@mui/material';
import { utils, actions } from 'comm-recipientapp-shared';
import { userAPI } from 'comm-recipientapp-shared/src/api';
import { getEmailString } from 'comm-recipientapp-shared/src/utils/helpers'
import ChevronRightWhiteSVG from 'comm-recipientapp-shared/src/assets/chevron_right_white.svg'
import Layout from '../../Layout';
import BackButton from '../../Shared/BackButton';
import PinInput from './PinInput';
import styles from './styles';

const { getInitialize } = actions.initialize;

export default function ValidateEmail() {
    const strings = utils.useStrings();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [codeError, setCodeError] = React.useState('');
    const [PinInputValue, setPinInputValue] = React.useState('');
    const [disabledVerify, setDisableVerify] = React.useState(true);
    const [buttonLoading, setButtonLoading] = React.useState(false);
    const [disabledRetry, setDisableRetry] = React.useState(true);
    const [displayResentCodeIndication, setDisplayResentCodeIndication] = React.useState(false);
    const [countdown, setCountdown] = React.useState(60);

    const location = useLocation();
    const newEmail  = location.state?.newEmail || null;
    const goBackTo  = location.state?.goBackTo || -1;

    const handleClickBackButton = () => {
        navigate(goBackTo);
    }
    const goToClaimMyStudents = () => {
        navigate('/students-to-claim');
    }

    const handleOnChangeInputCode = (value) => {
        setDisplayResentCodeIndication(false);
        setPinInputValue(value)
        const valueToString = `${value}`;

        if(valueToString.length === 4){
            setDisableVerify(false);
        }else{
            setDisableVerify(true);
        }
    }

    const handleNotReceiveCodeButtonClick = () => {
        setDisableRetry(true);
        setCountdown(60);
        setDisplayResentCodeIndication(true);
        try {
            userAPI.addAdditionalEmailAddress(newEmail);
        } catch (error) { /* empty */ }
    }

    const handleVerifyButtonClick = async () => {
        try {
            setButtonLoading(true);
            setDisableVerify(true);
            await userAPI.confirmAdditionalEmail(PinInputValue, newEmail);
            await dispatch(getInitialize());
            goToClaimMyStudents();
        } catch (error) {
            setDisableVerify(false)
            if (error?.response?.status === 400) {
                setCodeError(strings.WRONG_VERIFICATION_CODE);
            } else {
                setCodeError(strings.AN_INTERNAL_ERROR_HAS_HAPPENED_PLEASE_TRY_AGAIN);
            }
        } finally {
            setButtonLoading(false);
        }
    }


  React.useEffect(() => {
    let timer;
    if (disabledRetry && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
        setDisableRetry(false)
    }
    return () => clearInterval(timer);
  }, [countdown]);

  React.useEffect(() => {
    if(!newEmail) {
        navigate('/add-new-email');
    }
  },[]);


    return (
        <Layout>
            <Container maxWidth="xl" disableGutters sx={styles.mainContainer}>
                <Box sx={styles.mainBox}>
                    <BackButton handleClickBackButton={handleClickBackButton}/>
                    <Stack sx={styles.stackBody}  alignItems="center" >
                        <Typography sx={styles.typographyTitle}>{strings.CONFIRMATION}</Typography>
                        <Typography sx={styles.typographySubtitle}>{strings.PLEASE_ENTER_THE_4_DIGIT_CODE_SENT_TO_YOU_AT}:</Typography>
                        <Typography sx={styles.typographyEmail}>{getEmailString(newEmail)}</Typography>
                        <Box sx={styles.boxForInput}>
                            <PinInput handleOnChangeInputCode = {handleOnChangeInputCode} codeValue = {PinInputValue} />
                            {codeError ? <FormHelperText sx={styles.errorMessage} error>{codeError}</FormHelperText> : null}
                            {displayResentCodeIndication ? <FormHelperText sx={styles.errorMessage}>{strings.A_NEW_CODE_HAS_BEEN_SENT_TO_THIS_EMAIL}</FormHelperText> : null}
                        </Box>
                        <Box sx={styles.ButttonBox}>
                            <Button
                                fullWidth
                                disabled={disabledVerify}
                                onClick={handleVerifyButtonClick}
                                variant="contained"
                                endIcon={
                                    <Box
                                        alt={strings.CHEVRON_RIGHT_ICON}
                                        component="img"
                                        src={ChevronRightWhiteSVG}
                                        sx={styles.cardChevronRightIcon}
        />
    }
                                sx={styles.verifyButton}
                                aria-label={strings.VERIFY}
                                accessibilityRole="button"
                                accessibilityState={{ disabled: disabledVerify }}
>
                                <Typography sx={styles.labelVerifyButton}>{strings.VERIFY}</Typography>
                            </Button>
                            {buttonLoading && <CircularProgress role="status" aria-live="assertive" aria-label={strings.LOADING} size={24} sx={styles.CircularProgress} />}
                        </Box>
                        <Button
                            fullWidth
                            disabled={disabledRetry}
                            variant="contained"
                            sx={styles.notReceiveCodeButton}
                            onClick={handleNotReceiveCodeButtonClick}
                        >
                            <Typography component='span' sx={styles.labelNotReceiveCodeButton}>
                                {strings.I_DIDNT_RECEIVE_A_CODE}
                                {countdown>0 && <Typography sx={styles.labelNotReceiveCodeButton} component='span'> ({countdown})</Typography>}
                            </Typography>
                        </Button>
                    </Stack>
                </Box>
            </Container>
        </Layout>
    );
}
