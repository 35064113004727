/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import {
    Alert,
    Box,
    CircularProgress,
    Container,
    Link,
    TextField,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { utils, hooks } from 'comm-recipientapp-shared';
import Layout from '../Layout';
import Button from '../Shared/Button';
import styles from './styles';

const { useForgotPasswordFormFields } = hooks.user;

function ForgotPassword() {
    const [isEmailBlur, setIsEmailBlur] = useState(false);
    const strings = utils.useStrings();
    const {
        email,
        handleForgotpasswordSubmit,
        isEmailValid,
        isForgotPasswordLoading,
        isForgotPasswordSubmitted,
        isForgotPasswordSuccess,
        onEmailChange,
        isDuplicatedRequest,
    } = useForgotPasswordFormFields();
    const matches = useMediaQuery('(max-width:600px)');

    if (isForgotPasswordLoading) {
        return (
            <Layout>
                <Container maxWidth="sm" sx={{ ...styles.container, ...styles.containerCentered }}>
                    <CircularProgress role="status" aria-live="assertive" aria-label={strings.LOADING}/>
                </Container>
            </Layout>
        );
    }

    return (
        <Layout>
            <Container maxWidth="sm" sx={styles.container}>
                <Typography sx={styles.title}>{strings.FORGOT_PASSWORD}</Typography>
                {isForgotPasswordSuccess && isForgotPasswordSubmitted ? (
                    <p aria-live='assertive'>
                        <Typography>
                            {strings.IF} {email}{' '}
                            {strings.IS_REGISTERED_WITH_SCHOOL_MESSENGER_YOU_WILL_RECEIVE_INSTRUCTIONS_TO_RESET_YOUR_PASSWORD_WITHIN_A_FEW_MINUTES}
                        </Typography>
                        <br />
                        <Typography>
                            {
                                strings.IF_YOU_DON_T_RECEIVE_AN_EMAIL_FIRST_CHECK_YOUR_SPAM_FOLDER_OR_OTHER_FILTERING_TOOLS_YOU_MAY_ALSO_WANT_TO_TRY_ANOTHER_EMAIL_ADDRESS_TO_ENSURE_THE_RIGHT_ONE_IS_REGISTERED
                            }
                        </Typography>
                        <br />
                        <Box sx={styles.link}>
                            <Link
                                component={RouterLink}
                                sx={styles.return_to_login}
                                underline="none"
                                onClick={()=> window.location.reload()}
                                to="/forgotpassword"
                            >
                                {strings.TRY_ANOTHER_EMAIL_ADDRESS}
                            </Link>
                        </Box>
                    </p>
                ) : (
                    <>
                        <Alert severity="info">
                            {strings.PLEASE_ENTER_YOUR_EMAIL_ADDRESS_WE_WILL_SEND_YOU_AN_EMAIL_WITH_INSTRUCTIONS_TO_RESET_YOUR_PASSWORD}
                        </Alert>
                        <Box
                            component="form"
                            sx={styles.form}
                            onSubmit={handleForgotpasswordSubmit}
                        >
                            <TextField
                                fullWidth
                                error={isEmailBlur && !isEmailValid}
                                helperText={
                                    (isEmailBlur && !isEmailValid) ? <Typography aria-live="assertive">{strings.FORGOT_PASSOWRD_INPUT_FIELD_ERROR}</Typography> : ''
                                }
                                label={strings.EMAIL}
                                onChange={onEmailChange}
                                value={email}
                                variant="standard"
                                onBlur={() => setIsEmailBlur(true)}
                            />
                            {isDuplicatedRequest &&<Alert severity="error" aria-live='assertive'>
                                <Typography align="center" sx={styles.duplicatedRequest}>{strings.REQUESTS_FOR_PASSWORD_RESETS_ARE_ONLY_ALLOWED_ONCE_EVERY_TEN_MINUTES}</Typography>
                                <Typography align="center">{strings.PLEASE_WAIT_AT_LEAST_10_MINUTES_BEFORE_TRYING_AGAIN}</Typography>
                            </Alert>}
                            <Box sx={styles.submitButtonContainer}>
                                <Button
                                    sx={{
                                        ...styles.submitButton,
                                        ...{ width: matches ? '100%' : '30%' },
                                    }}
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    disabled={!isEmailValid}
                                >
                                    {strings.SUBMIT}
                                </Button>
                            </Box>
                            <Box sx={styles.link}>
                                <Link
                                    component={RouterLink}
                                    sx={styles.return_to_login}
                                    underline="none"
                                    to="/login"
                                >
                                    {strings.RETURN_TO_LOG_IN}
                                </Link>
                            </Box>
                        </Box>
                    </>
                )}
            </Container>
        </Layout>
    );
}

export default ForgotPassword;
