import { useState, useEffect } from 'react';

const useIsLoading = () => {
    const [isLoading, setIsLoading] = useState(false);

    const startLoading = () => setIsLoading(true);
    const stopLoading = () => setIsLoading(false);

    useEffect(() => {
        window.addEventListener('startLoading', startLoading);
        window.addEventListener('stopLoading', stopLoading);

        return () => {
            window.removeEventListener('startLoading', startLoading);
            window.removeEventListener('stopLoading', stopLoading);
        };
    }, []);

    return isLoading
};

export default useIsLoading;
