import React from 'react';
import { Typography, AccordionSummary, Box } from '@mui/material';
import { ExpandLess } from '@mui/icons-material';
import newConversationIcon from 'comm-recipientapp-shared/src/assets/start_direct_message_icon.svg';
import styles from './styles';

function GroupAccordionSummary({ title, modalIcon = null, messagePresent, toggleAccordion }) {
    return (
        <AccordionSummary 
            onClick={() => { messagePresent && toggleAccordion()}}
            expandIcon={messagePresent ? <ExpandLess sx={styles.expandless} /> : null} 
            aria-controls="panel1a-content" 
            id="panel1a-header" 
            sx={styles.accordionSummary}>
            <Typography component="h2" sx={styles.AccordionSummaryTypography}>
                <span aria-label={title}>
                    {title}
                </span>
            </Typography>
            {modalIcon && (
                <Box
                    aria-label={modalIcon.iconLabel}
                    alt={modalIcon.name}
                    component="img"
                    src={newConversationIcon}
                    onClick={modalIcon.clickHandler}
                    onKeyPress={event => {
                        if (event.key === 'Enter') {
                            modalIcon.clickHandler(event);
                        }
                    }}
                    tabIndex={0}
                    role="button"
                    sx={styles.addChatSVGIcon}
                />
            )}
        </AccordionSummary>
    );
}

export default GroupAccordionSummary;
