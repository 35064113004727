import React from "react";
import { Alert, Grid } from "@mui/material";

function NotFound() {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} sm={6} md={4} >
          <Alert severity="error">Oops! The page you are looking for does not exist!</Alert>
      </Grid>
    </Grid>
  );
}

export default NotFound;