import { alpha } from '@mui/material/styles';
import theme from '../../../config/style/muiTheme';

const styles = {
    actions: {
        alignItems: 'center',
        display: 'flex',
        gap: '10px',
        justifyContent: 'space-between',
    },
    circle_icon: {
        color: '#ffffff',
    },
    circle_icon_inactive: {
        color: theme.palette.grey['450'],
    },
    contact_card_multiple: {
        alignItems: 'center',
        display: 'flex',
        gap: '5px',
        height: '73px',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        borderRadius: '0px',
    },
    contact_card_single: {
        alignItems: 'center',
        display: 'grid',
        gridTemplateColumns: '12% 80% 7%',
        height: '73px',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        borderRadius: '0px',
    },
    contact_card_inactive: {
        borderRadius: '0px',
        boxShadow: '0 1px 4px rgb(0 0 0 / 10%)',
        backgroundColor: '#fff8f8',
        border: '1px red solid',
        cursor: 'pointer',
    },
    contact_card: {
        borderRadius: '0px',
        boxShadow: '0 1px 4px rgb(0 0 0 / 10%)',
        cursor: 'pointer',
    },
    contact_circle_unverified: {
        alignItems: 'center',
        backgroundColor: theme.palette.error.dark,
        borderRadius: '20px',
        display: 'flex',
        height: '40px',
        justifyContent: 'center',
        width: '40px',
        color: 'white',
    },
    contact_circle_inactive: {
        alignItems: 'center',
        backgroundColor: '#fff',
        border: `1px ${theme.palette.grey['450']} solid`,
        borderRadius: '20px',
        display: 'flex',
        height: '40px',
        justifyContent: 'center',
        width: '40px',
    },
    contact_card_unverified: {
        border: `1px solid ${theme.palette.error.dark}`,
        backgroundColor: '#fff8f8',
    },
    contact_circle: {
        alignItems: 'center',
        backgroundColor: '#43AC6A',
        borderRadius: '20px',
        display: 'flex',
        height: '40px',
        justifyContent: 'center',
        width: '40px',
    },
    contact_info_subtitle: {
        fontSize: '12px',
        marginTop: '3px',
        fontWeight: '600',
        color: '#2C5871',
    },
    contact_info_title: {
        fontSize: '14px',
    },
    contact_info: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '5px',
        textAlign: 'left',
        cursor: 'pointer',
        overflow: 'hidden',
    },
    contact_information: {
        marginTop: '40px',
    },
    contact_list: {
        marginTop: '15px',
    },
    content_phone: {
        alignItems: 'center',
        display: 'flex',
        gap: '4px',
        justifyContent: 'flex-start',
    },
    content_circles: {
        alignItems: 'center',
        display: 'flex',
        gap: '4px',
        justifyContent: 'space-between',
    },
    header_title: {
        color: '#2C5871',
        fontSize: 22,
        fontWeight: 300,
    },
    header: {
        alignItems: 'center',
        borderBottom: '1px solid #ddd',
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: '10px',
    },
    school_box: {
        backgroundColor: 'primary.main',
        color: '#ffffff',
        cursor: 'pointer',
        padding: '15px',
        textAlign: 'center',
    },
    menu: {
        '& .MuiPaper-root': {
            borderRadius: '6px',
            marginTop: theme.spacing(1),
            minWidth: 180,
            backgroundColor: theme.palette.primary.main,
            color: '#ffffff',
            boxShadow:
                'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            '& .MuiMenu-list': {
                padding: '4px 0',
            },
            '& .MuiMenuItem-root': {
                '& .MuiSvgIcon-root': {
                    fontSize: 18,
                    color: theme.palette.text.secondary,
                    marginRight: theme.spacing(1.5),
                },
                '&:active': {
                    backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
                },
            },
        },
    },
    modal_styles: {
        width: '100%',
        maxWidth: '640px',
    },
    item_icon_button: {
        backgroundColor: 'transparent',
        width: '25px',
        height: '25px',
        '&:hover': {
            backgroundColor: '#FF7978',
        },
    },
    item_close_icon: {
        color: 'gray',
        '&:hover': { color: 'white' },
    },
    add_preferences_for_number_header_text: { color: 'white' },
    contact_info_error_container: {
        backgroundColor: '#fcf8e3',
        color: '#8a6d3b',
        padding: '25px',
        border: '1px solid #ddd',
    },
    contact_info_error_message: {
        fontWeight: '600',
        justifyContent: 'top',
    },
    contact_info_error_instruction: {
        fontWeight: '500',
    },
    contact_info_error_image: {
        paddingRight: '10px',
    },
    focusByKeyboarNavegation: {
        '&:focus': {
            border: `1px solid ${theme.palette.common.white}`,
        },
    },
};

export default styles;
