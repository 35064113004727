import React from 'react';
import { Box, Button, Container, Grid, InputAdornment, TextField, Typography, List } from '@mui/material';
import { utils } from 'comm-recipientapp-shared';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import ContactsIcon from '@mui/icons-material/Contacts';
import Title from '../Shared/NewUI/Title';
import FavoriteCard from './FavoriteCard';
import ContactItem from './ContactItem';
import Layout from '../Layout';
import styles from './styles';

function MyContacts() {
    const strings = utils.useStrings();
    const dummyContacts = [
        {
            id: 13261346,
            name: 'Bob Loblaw',
            school: 'Galt Collegiate',
            student: 'Andrew Physics 101 Music 101 Math 101',
            chat: true,
            favorite: false,
        },
        {
            id: 1231241,
            name: 'Carl Winslow',
            school: 'Galt Collegiate',
            student: 'Music 101 David',
            chat: false,
            favorite: false,
        },
        {
            id: 12341241,
            name: 'Dunlop Pick',
            school: 'Galt Collegiate',
            student: 'David argument physics street sarah class type ',
            chat: true,
            favorite: false,
        },
        {
            id: 1666241,
            name: 'Electric Guitar',
            school: 'Galt Collegiate',
            student: 'David Physics 101',
            chat: false,
            favorite: false,
        },
        {
            id: 55281241,
            name: 'Drum Set',
            school: 'Galt Collegiate',
            student: 'David sarah class type argument physics street',
            chat: true,
            favorite: false,
        },
    ];

    return (
        <Layout>
            <Container disableGutters maxWidth="lg" sx={styles.content}>
                <Box sx={styles.title_container}>
                    <Box sx={styles.icon_container}>
                        <ContactsIcon sx={styles.icon_title} />
                    </Box>
                    <Title variant="h1">{strings.MY_CONTACTS}</Title>
                </Box>
                <Box sx={styles.contact_features}>
                    <Box sx={{ width: '40%' }}>
                        {/* TO DO: Handle translation and text */}
                        <TextField
                            label="Search..."
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            size="small"
                            sx={{ width: '100%' }}
                        />
                    </Box>
                    <Box>
                        {/* TO DO: Handle translation and text */}
                        <Button margin="normal" variant="outlined" role={'link'} startIcon={<TuneIcon />}>
                            Filter
                        </Button>
                    </Box>
                </Box>

                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} container>
                        <Box sx={styles.favorites}>
                            {dummyContacts.map(contact =>
                                contact.favorite === true ? (
                                    <FavoriteCard
                                        key={contact.id}
                                        name={contact.name}
                                        school={contact.school}
                                        student={contact.student}
                                        favorite={contact.favorite}
                                        chat={contact.chat}
                                    />
                                ) : null
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box sx={styles.contacts}>
                            <List sx={styles.list}>
                                {dummyContacts.map(contact => {
                                    return (
                                        <ContactItem
                                            key={contact.id}
                                            name={contact.name}
                                            school={contact.school}
                                            student={contact.student}
                                            favorite={contact.favorite}
                                            chat={contact.chat}
                                        />
                                    );
                                })}
                            </List>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Layout>
    );
}

export default MyContacts;
