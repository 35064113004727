const styles = {
    main_card_container: {
        display: 'flex',
        flexDirection: 'column',
        gap: '7px',
        marginTop: '-30px',
    },
    card_container: {
        paddingLeft: '35px',
        height: '80px',
        cursor: 'pointer',
        '&:hover': { backgroundColor: '#F1FAFE' },
        borderRadius: '0px',
        boxShadow: '0 1px 2px rgb(0 0 0 / 5%)',
    },
    content_layout: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginTop: '15px',
    },
    layout: { display: 'flex', justifyContent: 'space-between', gap: '20px' },
    day_of_the_month: {
        color: '#2C5871',
        fontWeight: '400',
        fontSize: '24px',
        lineHeight: '24px',
        textAlign: 'center',
    },
    day_of_the_week: {
        lineHeight: '24px',
        fontSize: '14px',
        fontWeight: '200',
        color: '#2C5871',
        textAlign: 'center',
    },
    name_and_event_data_container: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '10px',
        alignItems: 'center',
    },
    student_initial: {
        borderRadius: '50%',
        color: 'white',
        fontSize: '14px',
        height: '30px',
        width: '30px',
        lineHeight: '30px',
        textAlign: 'center',
        minWidth: '30px',
    },
    student_first_name: {
        color: '#2C5871',
        lineHeight: '24px',
    },
    event_data: { fontSize: '14px', lineHeight: '24px', color: '#2C5871', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' },
    month: {
        color: '#2C5871',
        fontSize: '20px',
        fontWeight: '200',
        padding: '15px',
    },
    future_date_day_of_the_month: {
        color: '#2C5871',
    },
    future_date_day_of_the_week: {
        color: '#2C5871',
        opacity: '1.0',
    },
};

export default styles;
