import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import FullScreenLoader from '../Utils/FullScreenLoader';

function CustomRoute() {

    const { isLoading } = useSelector(state => state.user);

    const [loadingState, setLoadingState] = useState('');
    
    useEffect(() => {
        if (isLoading) {
            setLoadingState('loading');
        }
    }, [isLoading]);

    useEffect(() => {
        if(loadingState === 'loading' && ! isLoading) {
            setLoadingState('complete');
        }
    }, [loadingState, isLoading]);

    return (
        <Fragment>
            {loadingState !== 'complete' && (
                <FullScreenLoader hideHeader={true} />
            )}
            {loadingState === 'complete' && (
                <Outlet />
            )}
        </Fragment>
    );
}

export default CustomRoute;
