import React from 'react';

import {
    Box,
    Typography,
    Stack,
} from '@mui/material';

import ChatSvgFocused from 'comm-recipientapp-shared/src/assets/chat_focused.svg';
import { utils } from 'comm-recipientapp-shared';
import styles from './styles';

export default function Logo() {

    const strings = utils.useStrings();

    return(
        <Stack 
            spacing={0} 
            aria-label={strings.CHAT_ARIA_LABEL_CHAT_ICON_AND_LOGO}>
            <Box sx={styles.logoContainer}>
                <Box 
                    tabIndex={0}
                    alt={strings.CHAT_LOGO}
                    component="img"
                    src={ChatSvgFocused} 
                    sx={styles.logoImg}
                    />  
                <Box>
                    <Typography
                        tabIndex={0}
                        variant="h1" 
                        sx={styles.chatLogoText}>
                        {strings.CHAT}
                    </Typography>
                </Box>
            </Box>
        </Stack>
    );
}
