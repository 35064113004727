import React from 'react';

export default function Unread(props) {
    return (
        <svg data-icon="unread" xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
            <g clipPath="url(#a)" stroke="#33366A" strokeWidth={1.5}>
                <path
                    d="m22.482 11.963.008.02.007.017-.007.016-.008.02-.002.006c-.051.109-.799 1.713-2.413 3.337C17.85 17.586 15.068 18.75 12 18.75c-3.068 0-5.85-1.165-8.066-3.37-1.615-1.625-2.363-3.23-2.413-3.338l-.003-.005-.008-.02L1.503 12l.007-.016.008-.02.003-.006c.05-.109.798-1.713 2.413-3.337C6.149 6.414 8.932 5.25 12 5.25c3.068 0 5.85 1.165 8.067 3.37 1.614 1.625 2.362 3.229 2.413 3.338l.002.005Zm-8.19-3.393a4.125 4.125 0 1 0-4.584 6.86 4.125 4.125 0 0 0 4.584-6.86Z"
                    fill="#A1A4F1"
                />
                <path strokeLinecap="round" d="m5.186 3.033 13.7 17.848" />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 0h24v24H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}
