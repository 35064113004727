export const EMAIL = 'email';
export const PASSWORD = 'password';
export const PHONE = 'phone';
export const SMS = 'sms';

export const SCHOOLS = Object.freeze({
    PEPPER: 'Pepperheart USD',
    MANGO: 'Mangoheart',
    KIWI: 'Kiwiheart',
});

export const WEB_CLIENT_HEADER = 1;
export const MOBILE_CLIENT_HEADER = Object.freeze({
    ios: 2,
    android: 3,
});
export const MIN_TEXT_LENGTH = 1;
export const MAX_MANAGE_PIN_LENGTH = 4;
export const MAX_EMAIL_LENGTH = 255;

// Notifications values
export const EMERGENCY_KEY = 1;
export const ATTENDANCE_KEY = 2;
export const MESSAGES_KEY = 7;

export const ASCENDING = 'asc';
export const DESCENDING = 'desc';

export const ALREADY_EXISTS = 'alreadyExists';

export const AUTH_ERROR = 'auth error';

export const ERROR_AT_LEAST_ONE = 'error_at_least_one';

export const JOIN_GROUP_ITEM_SELECTED = 'joinGroupItemSelected';
export const NEW_DIRECT_MESSAGE_SELECTED = 'newDirectMessageSelected';
export const SANDBOX_ATTRIBUTE_FOR_RC_IFRAME = 'allow-scripts allow-same-origin allow-forms allow-downloads allow-popups';
export const RC_IFRAME_ALLOW_PERMISSIONS = 'microphone; camera;';
export const RC_IFRAME_LAYOUT = '?layout=embedded';

export const ALPHANUMERIC_ERROR = 'alphaNumericError';
export const NOT_FOUND_ERROR = 'noFoundError';
export const NO_PERMISSION_ERROR = 'noPerimissionError';
export const EMAIL_CONFIRMATION = 'emailConfirmation';
export const FORGOT_PASSWORD = 'forgotPassword';

export const RC_COMMANDS = Object.freeze({
    GO: 'go',
    LOGOUT: 'logout',
    LOGIN_WITH_TOKEN: 'login-with-token',
});
export const RC_DISCUSSION_GROUP_TYPES = Object.freeze({
    GROUP: 'GROUP',
    DIRECT: 'direct',
    CHANNEL: 'channel',
    SECTION: 'section',
    PRIVATE: 'PRIVATE',
});
export const RC_MESSAGE_EVENTS = Object.freeze({
    CUSTOM_SCRIPT_LOGGED_IN: 'Custom_Script_Logged_In',
    CUSTOM_SCRIPT_LOGGED_OUT: 'Custom_Script_Logged_Out',
    ROOM_OPENED: 'room-opened',
});

export const Views = Object.freeze({
    MONTH: 'MONTH',
    LIST: 'LIST',
});

export const Navigate = Object.freeze({
    PREVIOUS: 'PREV',
    NEXT: 'NEXT',
});

export const EventTypes = Object.freeze({
    EXAM: 'EXAM',
    HOLIDAY: 'HOLIDAY',
    ABSENCE: 'ABSENCE',
});

export const EventClassNames = Object.freeze({
    BASE: 'custom_event_base',
    FUTURE_EXAM_OR_HOLIDAY: 'future_exam_or_holiday',
});

export const EventColors = Object.freeze({
    PAST_EXAM: '#F8F8F8',
    FUTURE_EXAM: '#FFFFFF',
    EXAM_OR_HOLIDAY_TEXT_COLOR: '#000000',
});

export const StudentButtonStyleStates = Object.freeze({
    SELECTED: 'contained',
    SELECTED_TEXT_COLOR: 'white',
    UNSELECTED: 'text',
    UNSELECTED_BACKGROUND_COLOR: 'transparent',
});

export const AbsencePopupStates = Object.freeze({
    EDIT: 'EDIT',
    DELETE: 'DELETE',
    DEFAULT: 'DEFAULT',
});

export const AbsenceTypes = Object.freeze(['fullDay', 'late', 'earlyDeparture', 'partialDay', 'multiDay']);

export const AbsenceReasonCodes = Object.freeze(['ILL', 'APT', 'VAC', 'REL', 'BER', 'WEA', 'MSB', 'OTH']);

export const ContactTypes = Object.freeze({
    PHONE: 'PHONE',
    EMAIL: 'EMAIL',
    SMS: 'SMS',
});

export const AddPhoneNumberModes = Object.freeze({
    ADD: 'ADD',
    VERIFY: 'VERIFY',
    ENTER_CODE: 'ENTER_CODE',
});

export const PhoneNumberVerificationMethods = Object.freeze({
    SMS: 'SMS',
    CALL: 'CALL',
});

export const AddEmailSteps = Object.freeze({
    ADD: 'ADD',
    SETUP_PREFERENCE: 'SETUP_PREFERENCE',
    VERIFY: 'VERIFY',
});

export const ContactDestinationTypes = Object.freeze({
    SMS: 'smses',
    PHONE: 'phones',
    EMAIL: 'emails',
    DEVICES: 'devices',
});

export const ContactOrigin = Object.freeze({
    IMPORTED: 'imported',
    NON_IMPORTED: 'non-imported',
});

export const PENDING = 'pending';
export const YES = 'yes';
export const NO = 'no';
export const SURVEY = 'survey';

export const PreferencePhoneConsent = Object.freeze({
    YES: 'yes',
    NO: 'no',
    PENDING: 'pending',
    UNKNOWN: 'unknown',
    NO_REGISTRY: 'noRegistry',
    SMS_ONLY: 'smsOnly',
});

export const NotificationType = Object.freeze({
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
    INFO: 'info',
});

export const IMAGE_TYPES = ['image/gif', 'image/svg+xml', 'image/png', 'image/jpeg'];

export const NotificationPreferences = Object.freeze({
    EMERGENCY: 'Emergency',
    ATTENDANCE: 'Attendance',
    CLASSROOM_MESSAGE: 'Classroom Message',
    GENERAL: 'General',
    SURVEY: 'Survey',
});

// Inbox Filter constants
export const MessageStatusTypes = Object.freeze({
    UNREAD: 'UNREAD',
    READ: 'READ',
    ARCHIVED: 'ARCHIVED',
    UNARCHIVED: 'UNARCHIVED',
});

export const MessageCategoriesTypes = Object.freeze({
    EMERGENCY: 'EMERGENCY',
    ATTENDANCE: 'ATTENDANCE',
    GENERAL: 'GENERAL',
});

export const MessageFilterSectionsTypes = Object.freeze({
    STATUS: 'STATUS',
    CATEGORIES: 'CATEGORIES',
    STUDENT: 'STUDENT',
    FROM: 'FROM',
});

export const Roles = Object.freeze({
    STUDENT: 'STUDENT',
    PARENT: 'PARENT',
});

export const FilterSections = Object.freeze({
    [MessageFilterSectionsTypes.STATUS]: [MessageStatusTypes.READ, MessageStatusTypes.UNREAD],
    [MessageFilterSectionsTypes.CATEGORIES]: [MessageFilterSectionsTypes.CATEGORIES],
    [MessageFilterSectionsTypes.STUDENT]: [MessageFilterSectionsTypes.STUDENT],
    [MessageFilterSectionsTypes.FROM]: [MessageFilterSectionsTypes.FROM],
});

export const FilterChangeActions = Object.freeze({
    ADD: 'ADD',
    REMOVE: 'REMOVE',
});

export const AllowedURLWithoutDependants = [
    '/not-found-records',
    '/add-new-email',
    '/validate-email',
    '/get-in-touch-with-the-school',
    '/students-to-claim',
];

export const QUERY_KEY = {
    FEED_FILTERS: 'feedFilters',
    FEED: 'feed',
    FEED_DETAILS: 'feedDetails',
    FEED_ITEM: 'feedItem',
};
