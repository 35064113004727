import axios from 'axios';
import i18next from 'i18next';
import jwtDecode from 'jwt-decode';
import { store } from '../../store';
import { logout } from '../../store/user/userSlice';
import { ApiInterceptorKeys, GET_RECIPIENT_SERVICE } from '../constants';
import env from '../../env';
import { setAuth } from '../../store/auth/authSlice';
import { MOBILE_CLIENT_HEADER } from '../../utils/constants';

const isMobile = env.IS_MOBILE !== 'false';

const pendingRefresh = axios.create(); // Axios instance for handling token refresh

export const handleToken = async request => {
    const state = store?.getState();

    const authData = state?.auth?.auth;
    request.headers = request.headers || {};

    const locale = isMobile ? i18next.language : state?.user?.language;
    if (locale) {
        request.headers['Accept-Language'] = locale;
    }

    if (authData?.access_token) {
        if (isMobile) {
            const decodedToken = jwtDecode(authData?.access_token);
            const currentTimestamp = Date.now();
            const isAccessTokenExpired = decodedToken && decodedToken.exp * 1000 <= currentTimestamp;
            const platform = state?.auth?.platform;
            const userLocation = state?.user?.location;

            pendingRefresh.defaults.baseURL = GET_RECIPIENT_SERVICE(userLocation?.toLowerCase());

            if (isAccessTokenExpired) {
                try {
                    const response = await pendingRefresh.post(
                        'oauth/token',
                        {
                            refresh_token: authData?.refresh_token,
                            grant_type: 'refresh_token',
                        },
                        {
                            headers: {
                                Authorization: 'Basic anNvbi1jbGllbnQ6c2VjcmV0',
                                'Content-Type': 'application/json',
                                'X-App-Client': MOBILE_CLIENT_HEADER[platform],
                            },
                        }
                    );
                    store.dispatch(setAuth(response?.data));
                    const newAccessToken = response?.data?.access_token;

                    request.headers.Authorization = `Bearer ${newAccessToken}`;
                } catch (error) {
                    store.dispatch(logout());
                }
            } else {
                request.headers.Authorization = `Bearer ${authData.access_token}`;
            }
        } else {
            request.headers.Authorization = `Bearer ${authData.access_token}`;
        }
    }

    return request;
};

export const handleExpiredToken = async axiosErr => {
    if (axiosErr?.response?.status === 401) {
        store.dispatch(logout());
        //localStorage.setItem(ApiInterceptorKeys.EXPIRED_SESSION, 'true');
    }

    throw axiosErr;
};

export const handleServerFatalError = async axiosErr => {
    if (axiosErr?.response?.status >= 500 && axiosErr?.response?.status <= 599 && !isMobile) {
        localStorage.setItem(ApiInterceptorKeys.FATAL_SERVER_ERROR, 'true');
    }

    throw axiosErr;
};
