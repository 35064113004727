import * as utils from '../utils';
import { isFieldValidLength } from './common';

const { absenceTypes } = utils.absenceUtils;

export const COMMENT_MAX_LENGTH = 220; 

export const validateNewAbsenceForm = ({
    selectedStudent,
    absenceType,
    reason,
    dateOfAbsence,
    expectedTimeOfArrival,
    timeOfDeparture,
    returningToSchoolAt,
    firstDayOfAbsence,
    lastDayOfAbsence,
    comment = ''
}) => {
    const basicValidation = selectedStudent && absenceType && reason && isCommentValid(comment);
    if (absenceType && absenceType.type !== absenceTypes.MULTI_DAY) {
        if (absenceType && absenceType.type === absenceTypes.FULL_DAY) return basicValidation && dateOfAbsence;
        if (absenceType && absenceType.type === absenceTypes.LATE) return basicValidation && dateOfAbsence && expectedTimeOfArrival;
        if (absenceType && absenceType.type === absenceTypes.EARLY_DEPARTURE) return basicValidation && dateOfAbsence && timeOfDeparture;
        if (absenceType && absenceType.type === absenceTypes.PARTIAL_DAY) return basicValidation && dateOfAbsence && timeOfDeparture && returningToSchoolAt;
    } else if (absenceType && absenceType.type === absenceTypes.MULTI_DAY) {
        return basicValidation && firstDayOfAbsence && lastDayOfAbsence;
    }
    return false;
};

export const isCommentValid = value => {
    return isFieldValidLength(value, COMMENT_MAX_LENGTH);
}
