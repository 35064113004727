import React from 'react';
import { Avatar as MuiAvatar } from '@mui/material';
import { utils } from 'comm-recipientapp-shared';

const { stringAvatar, getConsistentColorFromString } = utils.avatar;

function Avatar ({ label, sx, stringForColor, ...props }) {
    return <MuiAvatar {...stringAvatar(label)} sx={{...sx, bgcolor: getConsistentColorFromString(stringForColor) }} {...props} />;
}

export default Avatar;
