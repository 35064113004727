import { styled } from '@mui/material';
import theme from '../../../config/style/muiTheme';

const styles = {
    chatIframe: {
        border: 'none',
        width: '100%',
        height: '100%',
    },
    loadingGrid: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
};

export default styles;

export const StyledIframe = styled('iframe')(styles.chatIframe);
