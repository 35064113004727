import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export default function useMenuItem() {
    const absenceData = useSelector(state => state.absence);

    const userCustomers = useSelector(state => state.preferences.schools);

    const hasAttendanceData = useMemo(() => (absenceData.enabledStudents.length > 0 && userCustomers.length > 0) || false, [absenceData]);

    const persons = useSelector(state => state.initialize?.persons);

    const hasSafeArrival = useMemo(
        () => (userCustomers?.length > 0 && userCustomers.filter(item => item.settings.hasSafeArrival === true).length > 0) || false,
        [userCustomers]
    );

    const hasChat = useMemo(() => (persons?.length > 0 && persons.filter(item => item.hasChat === true).length > 0) || false, [persons]);

    const hasPreferencesEnabled = useMemo(() => userCustomers?.length > 0 || false, [userCustomers]);

    return {
        hasSafeArrival,
        hasAttendanceData,
        hasPreferencesEnabled,
        hasChat,
    };
}
