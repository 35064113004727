import React from 'react';

function Inbox(props) {
    return (
        <svg data-icon="inbox" xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
            <path
                d="M7.191 15a.749.749 0 0 1 .525.216l1.818 1.818a.739.739 0 0 0 .525.216h3.882a.739.739 0 0 0 .525-.216l1.818-1.818a.753.753 0 0 1 .525-.216h3.441V4.5a.747.747 0 0 0-.22-.53.747.747 0 0 0-.53-.22h-15a.747.747 0 0 0-.53.22.747.747 0 0 0-.22.53V15h3.441Z"
                fill="#A1A4F1"
            />
            <path d="M4 20v-5h3.5l2.5 2.5h4l2.5-2.5H20v5H4Z" fill="#fff" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.5 3h-15A1.5 1.5 0 0 0 3 4.5v15A1.5 1.5 0 0 0 4.5 21h15a1.5 1.5 0 0 0 1.5-1.5v-15A1.5 1.5 0 0 0 19.5 3Zm-15 1.5h15v15h-15v-15Z"
                fill="#33366A"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.939 16.5h-3.878l-1.815-1.815-.001-.001a1.49 1.49 0 0 0-1.057-.434H3.75a.75.75 0 0 0 0 1.5h3.439l1.815 1.815.001.001a1.49 1.49 0 0 0 1.057.434h3.879a1.49 1.49 0 0 0 1.054-.434l1.816-1.816h3.439a.75.75 0 0 0 0-1.5h-3.441a1.49 1.49 0 0 0-1.054.434L13.939 16.5Z"
                fill="#33366A"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.25 11.814 9.405 9.97a.749.749 0 1 0-1.06 1.06l3.125 3.125a.749.749 0 0 0 1.06 0l3.125-3.125a.749.749 0 1 0-1.06-1.06l-1.845 1.844V7.5a.75.75 0 0 0-1.5 0v4.314Z"
                fill="#33366A"
            />
        </svg>
    );
}

export default Inbox;
