import { createSlice } from '@reduxjs/toolkit';
import { absenceAPI } from '../../api';
import { AbsencePopupStates } from '../../utils/constants';
import { getAbsencesByDate } from '../../utils/absenceDataHelper';
import { resetState } from '../resetStore';

const initialState = {
    isFetched: false,
    enabledStudents: [],
    holidays: [],
    organizationSettings: [],
    isNewAbsenceFormVisible: false,
    newAbsenceError: '',
    calendarPopup: {
        mode: AbsencePopupStates.DEFAULT,
    },
    absences: [],
    filteredAbsences: [],
    absencesUnexplained: false,
};

const absenceSlice = createSlice({
    name: 'absenceSlice',
    initialState,
    reducers: {
        setIsFetched: (state, { payload }) => {
            state.isFetched = payload;
        },
        setEnabledStudents: (state, { payload }) => {
            state.enabledStudents = payload;
        },
        setOrganizationSettings: (state, { payload }) => {
            state.organizationSettings = payload;
        },
        setHolidays: (state, { payload }) => {
            state.holidays = payload;
        },
        setCalendarPopupMode: (state, { payload }) => {
            state.calendarPopup.mode = payload;
        },
        setIsNewAbsenceFormVisible: (state, { payload }) => {
            state.isNewAbsenceFormVisible = payload;
        },
        setNewAbsenceError: (state, { payload }) => {
            state.newAbsenceError = payload;
        },
        setIsNewAbsenceCreated: (state, { payload }) => {
            state.isNewAbsenceCreated = payload;
        },
        setAbsences: (state, { payload }) => {
            state.absences = payload;
        },
        setFilterAbsences: (state, { payload }) => {
            const newAbsences = [];
            const { sortAbsences, studentsId } = payload;
            sortAbsences.forEach(item => {
                const newData = item.data.filter(i => studentsId.includes(i.student.personId));
                if (newData.length > 0) {
                    newAbsences.push({ ...item, data: newData });
                }
            });

            state.filteredAbsences = newAbsences;
        },
        setAbsencesUnexplained: (state, { payload }) => {
            state.absencesUnexplained = payload;
        },
    },
    extraReducers: builder => builder.addCase(resetState, () => initialState),
});

export const {
    setIsFetched,
    setCalendarPopupMode,
    setEnabledStudents,
    setHolidays,
    setOrganizationSettings,
    setIsNewAbsenceFormVisible,
    setNewAbsenceError,
    setIsNewAbsenceCreated,
    setAbsences,
    setFilterAbsences,
    setAbsencesUnexplained,
} = absenceSlice.actions;

export const getData = () => async dispatch => {
    try {
        const { data } = await absenceAPI.getAbsenceForm();
        dispatch(setEnabledStudents(data?.attendanceEnabledStudents || []));
        dispatch(setOrganizationSettings(data?.organizationSettings || []));
        dispatch(setHolidays(data?.holidays || []));
        return {
            attendanceEnabledStudents: data?.attendanceEnabledStudents || [],
            holidays: data?.holidays || [],
            organizationSettings: data?.organizationSettings || [],
        };
    } catch (error) {
        return false;
    }
};

export const submitAbsence = data => async dispatch => {
    try {
        await absenceAPI.submitAbsence(data);
        dispatch(setIsNewAbsenceFormVisible(false));
        window.dispatchEvent(new Event('refetchAbsences'));
    } catch ({ response }) {
        dispatch(setNewAbsenceError(response?.data[0]?.message || response));
    }
};

export const updateAttendanceStudent = (customerId, studentId, values) => async dispatch => {
    try {
        await absenceAPI.attendanceEnableStudents(customerId, studentId, values);

        return true;
    } catch (error) {
        return false;
    }
};

export const getAbsences =
    (startDate, endDate, studentsId = []) =>
    async dispatch => {
        try {
            const { data } = await absenceAPI.getAbsences(startDate, endDate);
            const sortAbsences = getAbsencesByDate(data);
            dispatch(setAbsences(sortAbsences));
            dispatch(setFilterAbsences({ studentsId, sortAbsences }));
            return { sortAbsences };
        } catch (error) {
            return false;
        }
    };

export const createAbsence = data => async () => {
    try {
        const res = await absenceAPI.submitAbsence(data);
        return res;
    } catch (error) {
        return false;
    }
};

export default absenceSlice.reducer;
