import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { persistor } from 'comm-recipientapp-shared/src/store/store';
import { logout } from 'comm-recipientapp-shared/src/store/user/userSlice';
import { useStrings } from 'comm-recipientapp-shared/src/utils';
import Layout from '../../Layout';
import styles from './styles';

export default function ErrorComponent(props) {
    const { errorInfo, error } = props;

    const { ERROR_COMPONENT_LOGOUT_BUTTON, ERROR_COMPONENT_RELOAD_BUTTON } = useStrings();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const refreshPage = () => {
        navigate(0);
    };

    const _logout = () => {
        dispatch(logout());
        navigate('/');
    };

    useEffect(() => {
        persistor.flush();
    }, []);

    return (
        <Layout>
            <Box sx={styles.container}>
                <Typography variant="h5">Something went wrong.</Typography>
                {process.env.NODE_ENV === 'development' && (
                    <details style={styles.details_container}>
                        {error && error.toString()}
                        <br />
                        {errorInfo.componentStack}
                    </details>
                )}
                <Box sx={styles.buttons_container}>
                    <Button variant="contained" onClick={refreshPage}>
                        {ERROR_COMPONENT_RELOAD_BUTTON}
                    </Button>
                    <Button variant="contained" onClick={_logout}>
                        {ERROR_COMPONENT_LOGOUT_BUTTON}
                    </Button>
                </Box>
            </Box>
        </Layout>
    );
}
