import { styled } from '@mui/material';
import theme from '../../../../config/style/muiTheme';

const styles = {
    buttons_container: {
        display: 'flex',
        justifyContent: 'center',
        gap: '15px',
    },
    button: {
        margin: '0 5px',
        fontWeight: '300',
        lineHeight: '5px',
        borderRadius: '3px',
        backgroundColor: theme.palette.primary.main,
        height: '40px',
        border: `1px solid ${theme.palette.primary.main}`,
        color: 'white',
        '&:disabled': {
            border: '1px solid #E0E0E0',
        },
        boxShadow: 'none',
        textAlign: 'center',
        fontSize: '1rem',
    },
    cancel_button: {
        color: theme.palette.primary.main,
        backgroundColor: 'white',
        border: `1px solid ${theme.palette.primary.main}`,
    },
    banner: {
        backgroundColor: theme.palette.primary.main,
        maxHeight: '80px',
        height: '100%',
    },
    banner_text: {
        fontSize: '1.3rem',
        color: 'white',
        fontWeight: '300',
        lineHeight: '1.4',
        textAlign: 'center',
        paddingTop: '20px',
        paddingBottom: '20px',
    },
    input_label: {
        marginBottom: '7px',
        fontSize: '16px',
        fontWeight: '300',
        color: '#2C5871',
    },
    content_container: {
        marginTop: '40px',
        marginBottom: '30px',
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '30px',
    },
    input_section: {
        width: '100%',
        maxWidth: '400px',
        marginLeft: '30px',
        marginRight: '50px',
    },
    set_message_preference_section: {
        width: '100%',
        maxWidth: '400px',
        marginLeft: '30px',
        marginRight: '50px',
    },
    set_message_preference_description: {
        fontWeight: '300',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#222222',
        textAlign: 'center',
    },
    set_message_preference_email: {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#222222',
    },
    error_message_container: {
        backgroundColor: '#F2DEDD',
        padding: '10px',
        display: 'flex',
        gap: '10px',
    },
    error_message: {
        color: '#a94442',
        fontSize: '16px',
        lineHeight: '24px',
    },
    error_message_icon: {
        color: '#a94442',
    },
    input: {
        width: '100%',
        height: '22px',
        maxWidth: '400px',
        border: '1px solid #cccccc',
        boxShadow: 'inset 0 1px 2px rgba(0 0 0 / 10%)',
        fontSize: '15px',
        padding: '0.5rem',
        '&:focus': {
            outline: 'none',
            boxShadow: '0 0 5px #999999',
            borderColor: '#999999',
            backgroundColor: '#fafafa',
        },
    },
    CircularProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-12px',
        marginLeft: '-12px',
    },
    ButttonBox: { position: 'relative' },
    saveButton: { '&:disabled': { color: theme.palette.grey['700'] } },
};

export default styles;

export const StyledInput = styled('input')(styles.input);
