import React, { Fragment, useState } from "react";
import { 
    Avatar, 
    Box,
    Button,
    Card,
    CardHeader, 
    Typography 
} from "@mui/material";
import SmsIcon from '@mui/icons-material/Sms';
import styles from "./styles";

function FavoriteCard (props) {

    const { 
        name, 
        school, 
        student, 
        favorite 
    } = props;

    return (
        <Fragment>
            <Card variant="outlined" sx={ styles.cardStyle }>
                <CardHeader 
                    avatar={<Avatar sx={ styles.avatarStyle }>R</Avatar>} 
                    title={name}
                    subheader={student.name}
                    sx={ styles.cardHeader }
                >
                </CardHeader>
                <Box>
                    {/* TO DO: Handle translation and text */}
                    <Button variant="outlined" startIcon={<SmsIcon />}>Chat</Button>
                </Box>
                <Box>
                    <Typography variant="caption">{school}</Typography>
                </Box>
            </Card>
            {/* <Divider variant="inset" component="li" /> */}
        </Fragment>
    )

}

export default FavoriteCard;
