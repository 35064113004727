import React, { useState } from 'react';
import { Box, Typography, Dialog, CircularProgress } from '@mui/material';
import { utils } from 'comm-recipientapp-shared';
import { useNavigate } from 'react-router-dom';
import { smsOptOut } from 'comm-recipientapp-shared/src/api/preferences';
import { NotificationType } from 'comm-recipientapp-shared/src/utils/constants';
import Button from '../../Shared/Button';
import styles from '../styles';
import Notification from '../../Utils/Notification';

function VerifyInformation({ isOpen, onClose, school, phone, countryCode }) {
    const strings = utils.useStrings();
    const navigate = useNavigate();
    const [hasUnsubscribed, setHasUnsubscribed] = React.useState(false);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const onYes = async () => {
        setIsLoading(true);
        try {
            await smsOptOut({ phone, countrycode : countryCode });
            setHasUnsubscribed(true);
        } catch (error) {
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <>
            <Dialog open={isOpen} onClose={onClose} PaperProps={{ sx: styles.modal_styles }}>
                {hasUnsubscribed ? (
                    <Box sx={styles.unsubscribed_contianer}>
                        <Typography sx={styles.success_text} aria-live="polite">
                            {strings.YOU_HAVE_UNSUBSCRIBED_SUCCESSFULLY}
                        </Typography>
                    </Box>
                ) : (
                    <Box sx={styles.banner}>
                        <Typography sx={styles.banner_text} aria-live="polite">
                            {strings.IS_THE_INFORRMATION_YOU_HAVE_ENTERED_CORRECT}
                        </Typography>
                    </Box>
                )}
                {hasUnsubscribed ? (
                    <Box sx={styles.unsubscribed_contianer}>
                        <Box sx={styles.buttton_box}>
                            <Button onClick={() => navigate(-1)} sx={styles.save_button} variant="contained">
                                {strings.CLOSE}
                            </Button>
                        </Box>
                    </Box>
                ) : (
                    <>
                        <Box sx={styles.pop_up_text_container}>
                            <Typography sx={styles.text} aria-live="polite">
                                {strings.SCHOOL_OR_DISTRICT} : {school}
                            </Typography>
                            <Typography sx={styles.text} aria-live="polite">
                                {strings.MOBILE_PHONE_NUMBER_TO_OPT_OUT} : {phone}
                            </Typography>
                        </Box>

                        <Box sx={styles.buttons_container}>
                            <Button onClick={onClose} variant="contained" sx={styles.cancel_button}>
                                {strings.NO}
                            </Button>
                            <Box sx={styles.buttton_box}>
                                <Button onClick={onYes} sx={styles.save_button} variant="contained">
                                    {strings.YES}
                                </Button>
                                {isLoading && (
                                    <CircularProgress
                                        role="status"
                                        aria-live="assertive"
                                        aria-label={strings.LOADING}
                                        size={24}
                                        sx={styles.circular_progress}
                                    />
                                )}
                            </Box>
                        </Box>
                    </>
                )}
            </Dialog>
            <Notification
                open={isError}
                handleClose={() => setIsError(false)}
                type={NotificationType.WARNING}
                message={strings.SOMETHING_WENT_WRONG_TRY_LATER}
            />
        </>
    );
}

export default VerifyInformation;
