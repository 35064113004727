import theme from '../../../config/style/muiTheme';

const styles = {
    ModalJoinGroupBox: {
        position: 'absolute',
        border: `1px solid ${theme.palette.grey[110]}`,
        boxShadow: `0px 4px 12px ${theme.palette.common.black}26`,
        [theme.breakpoints.up('xl')]: {
            width: '30%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '50%',
        },
        [theme.breakpoints.up('sm')]: {
            width: '50%',
        },
        [theme.breakpoints.up('sm')]: {
            width: '70%',
            transform: 'translate(-50%, -50%)',
            top: '50%',
            left: '50%',
            borderRadius: '12px',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
        },
        bgcolor: theme.palette.common.white,
        pt: 1,
    },
    ModalJoinGroupBoxStack: { alignItems: 'center', height: '100%' },
    ModalJoinGroupCloseButton: {
        background: theme.palette.grey[270],
        alignSelf: 'end',
        marginRight: '12px',
    },
    ModalJoinGroupCloseButtonIcon: { color: theme.palette.blue[100] },
    ModalJoinGroupTypoTittleOne: {
        fontWeight: '500',
        fontSize: '24px',
        lineHeight: '24px',
        letterSpacing: '-0.02em',
        color: theme.palette.purple[100],
        fontFamily: 'Hornbill',
    },
    ModalJoinGroupTypoCommText: {
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        color: theme.palette.blue[100],
    },
    marginTop12px: {
        marginTop: '12px',
    },
    ErrorField: {
        mt: '-12px',
        fontSize: '14px',
    },
    marginTop24px: {
        marginTop: '24px',
    },
    padding15px: {
        padding: '15px',
    },
    ModalJoinGroupTypoTittleTwo: {
        fontWeight: 600,
        fontsize: '16px',
        lineHeight: '120%',
        color: theme.palette.blue[100],
        marginTop: '12px',
    },
    marginTop0px: {
        marginTop: 0,
    },
    marginBottom23px: {
        marginBottom: '23px',
    },
    ModalJoinGroupButtonGroup: {
        width: '100%',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.grey[230],
        marginTop: 'auto',
        padding: '12px 16px',
        boxSizing: 'border-box',
        borderBottomRightRadius: '12px',
        borderBottomLeftRadius: '12px',
    },
    ModalJoinGroupCancelButton: {
        background: theme.palette.common.white,
        border: `2px solid ${theme.palette.grey[230]}`,
        borderRadius: '10px',
        fontWeight: 500,
        fontSize: '16px',
        padding: '7px 12px 9px',
        color: theme.palette.purple[45],
    },
    ModalJoinGroupJoinButton: {
        border: `2px solid ${theme.palette.grey[230]}`,
        borderRadius: '10px',
        fontWeight: 500,
        fontSize: '16px',
        padding: '7px 12px 9px',
        color: theme.palette.common.white,
        background: theme.palette.purple[45],
        '&:hover': {
            background: theme.palette.purple[42],
        },
    },
    codeTextField: {
        margin: "14px 0"
    },
    codeTextFieldInputProps: {
        min: 0,
        maxLength: 6,
        style: {
            height: "10px",
            textAlign: 'center',
            textTransform: "uppercase",
            letterSpacing: "2px",
            fontWeight: "500",
            color: theme.palette.purple[70]
        },
    },
    ButttonBox: { 
        position: 'relative' 
    },
    CircularProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-12px',
        marginLeft: '-12px',
    },
};

export default styles;
