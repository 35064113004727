import React, { useCallback, useMemo, useState } from 'react';
import { utils } from 'comm-recipientapp-shared';
import AbsencePopup from './Absence';
import ExamOrHolidayPopup from './ExamOrHoliday';

const { EventTypes } = utils.constants;

/**
 * @typedef {object} PopupContentProps
 * @property {{ reason: string; students: Array; type: string } | import('./Absence').Absence} popupData
 * @property {(newData) => void} handleLocalAbsenceUpdate
 * @property {() => void} handleClose
 * @param {React.PropsWithChildren<PopupContentProps>} props
 */
export default function PopupContent({ popupData, handleClose, handleLocalAbsenceUpdate }) {
    const [isAttachmentPreviewOpen, setIsAttachmentPreviewOpen] = useState(false);

    const popupType = useMemo(() => (popupData.typeOfAbsence ? EventTypes.ABSENCE : popupData.type), [popupData]);

    /** @type {(openState: boolean) => void} */
    const handlePopupOpenState = useCallback(openState => {
        if (typeof openState === 'boolean') {
            setIsAttachmentPreviewOpen(openState);
        } else {
            setIsAttachmentPreviewOpen(prevState => !prevState);
        }
    }, []);

    return (
        <>
            {popupType === EventTypes.ABSENCE && (
                <AbsencePopup
                    popupData={popupData}
                    setIsAttachmentPreviewOpen={handlePopupOpenState}
                    isAttachmentPreviewOpen={isAttachmentPreviewOpen}
                    handleClose={handleClose}
                    handleLocalAbsenceUpdate={handleLocalAbsenceUpdate}
                />
            )}

            {(popupType === EventTypes.EXAM || popupType === EventTypes.HOLIDAY) && (
                <ExamOrHolidayPopup popupData={popupData} popupType={popupType} handleClose={handleClose} />
            )}
        </>
    );
}
