import { palette } from 'comm-recipientapp-shared';
import theme from '../../config/style/muiTheme';

const styles = {
    avatar: {
        mr: 1,
    },
    popup: {
        maxWidth: '800px',
        minHeight: '400px',
    },
    titleContainer: {
        padding: '20px',
        backgroundColor: palette.primary.main,
        display: 'flex',
        justifyContent: 'space-between'
    },
    close_btn: {
        fontSize: '20px',
        color: 'white'
    },
    title: {
        textAlign: 'center',
        color: 'white',
        fontWeight: '500',
        fontSize: '22px',
        margin: '0 18px',
    },
    subtitle: {
        textAlign: 'center',
        color: 'white',
    },
    form: {
        padding: '20px 30px',
        marginBottom: '20px',
    },
    autocompleteOption: {
        display: 'inline-block',
        color: theme.palette.primary.main,
        fontSize: '12px',
        marginLeft: '10px',
    },
    actions: {
        margin: '0 auto',
        marginTop: '90px',
        marginBottom: '-30px',
        display: 'flex',
        justifyContent: 'space-between',
        gap: '5px',
    },
    cancelBtn: {
        fontWeight: "400",
        marginRight: '10px',
        color: theme.palette.primary.main,
        backgroundColor: 'white',
    },
    sendBtn: {
        fontWeight: "400",
        "&.Mui-disabled": {
            color: theme.palette.action.disabled,
            backgroundColor: theme.palette.action.disabledBackground
        }
    },
    attachments_icon: {
        color: 'white',
        transform: 'rotate(45deg)',
        height: '20px',
        width: '20px',
    },
    preview_attachement_container: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    attachment_add_button: {
        display: 'flex',
        alignItems: 'center',
    },
    attachements_add_text: {
        marginRight: '10px',
    },
    add_attachments_button_text_active: {
        color: '#4CACE6',
    },
    attachement_label: {
        display: 'block',
    },
    add_attachments_button_container: {
        backgroundColor: '#CCCCCC',
        borderRadius: '50%',
        height: '30px',
        width: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    add_attachments_button_container_active: {
        backgroundColor: '#FC8684',
    },
    edit_popup_field_label: {
        fontSize: '16px',
        color: '#2C5871',
        fontWeight: '300',
    },
    attachments_details_container: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    attachment_container: {
        display: 'flex',
        justifyContent: 'space-between',
        maxWidth: '175px',
        marginTop: '5px',
    },
    edit_popup_attachments_details_preview_img: {
        cursor: 'pointer',
    },
    edit_popup_attachments_metadata_container: {
        marginLeft: '20px',
    },
    attachment_name: {
        fontSize: '13px',
        fontWeight: '500',
    },
    attachment_size: {
        fontSize: '11px',
        color: '#999999',
        fontWeight: '300',
    },
    edit_popup_attachment_clear_button: {
        height: '20px',
        width: '20px',
        color: palette.primary.main,
        fontWeight: '700',
        marginRight: '5px',
    },
};

export default styles;
