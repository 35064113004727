import theme from '../../../config/style/muiTheme';

const styles = {
    chatDataContainer: {
        [theme.breakpoints.up('sm')]: {
            width: '60%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
        textAlign: 'center',
        margin: "0 auto",
    },
    loading: {
        opacity: 0.4,
    },
    avatarText: {
        bgcolor: theme.palette.grey[400],
        borderRadius: '10px',
    },
    chatDataText: {
        color: theme.palette.purple[200],
        maxWidth: '80vw',
        mt: 1,
    },
    skeletonText: {
        textAlign: 'center',
        margin: 'auto',
        mt: 1,
    },
    PopOver: {
        [theme.breakpoints.up('xl')]: {
            width: '30%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '40%',
        },
        [theme.breakpoints.up('sm')]: {
            width: '50%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        /* width: '30%', */
        borderRadius: '12px',
        fontWeight: 700,
    },
    PopOverButton: { alignSelf: 'end' },
    PopOverTypography: { pl: 2, pr: 2, pb: 2 },
    SeeMore: {
        minWidth: 'fit-content',
        lineHeight: 2,
        fontWeight: 400,
    },
    BoxDescription: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    BoxAvatar: {
        justifyContent: 'center',
        display: 'flex',
    },
    TitleTypograpy: {
        fontSize: '22px',
    },
};

export default styles;
