import React from 'react';
import { Box, Grid, Link, Typography, useMediaQuery } from '@mui/material';
import { utils, env } from 'comm-recipientapp-shared';
import signUpIllustra from 'comm-recipientapp-shared/src/assets/signupillustra.svg';
import logoSm from 'comm-recipientapp-shared/src/assets/Logo.svg';
import gpLogo from 'comm-recipientapp-shared/src/assets/btn_google_play.png';
import asLogo from 'comm-recipientapp-shared/src/assets/btn_itunes_store.png';
import Layout from '../Layout';
import Title from '../Shared/SecondaryTitle';
import styles from './styles';

function Landing() {
    const strings = utils.useStrings();
    const copyRightText = '\u00A9 ' + new Date().getFullYear() + ' SchoolMessenger Corporation';

    const matches = useMediaQuery('(max-width:900px)');

    return (
        <Layout>
            <Box
                sx={{
                    ...styles.container,
                    ...{
                        paddingLeft: matches ? '10px' : '190px',
                        paddingRight: matches ? '10px' : '190px',
                    },
                }}
            >
                <Title
                    tabIndex={0} 
                    sx={{
                        ...{
                            fontSize: matches ? '20px' : '40px',
                            textAlign: matches ? 'center' : 'left',
                            marginLeft: matches ? '0px' : '50px',
                        },
                        ...styles.title,
                    }}
                >
                    {strings.SIGN_UP_TITLE}
                </Title>
                <Grid container sx={styles.grid}>
                    <Grid item tabIndex={0} lg={6} md={12} sx={styles.illustrationContainer} xs={12}>
                        <Box alt={strings.SIGN_UP_ILLUSTRATION} component="img" src={signUpIllustra} sx={styles.illustration} />
                    </Grid>
                    <Grid item lg={6} md={12} sx={styles.logoContainer} xs={12}>
                        <Box 
                            tabIndex={0} 
                            component="img" 
                            sx={styles.logoSm} 
                            src={logoSm} 
                            alt={strings.SCHOOLMESSENGER_LOGO}
                        />
                        <Box sx={styles.storeContainer}>
                            <Link sx={styles.link} href={env.APPLE_STORE_URL} target={'_blank'}>
                                <Box 
                                    component="img"
                                    src={asLogo} 
                                    sx={styles.logoStore} 
                                    alt={strings.APPLE_APP_STORE_LOGO}
                                />
                            </Link>
                            <Link sx={styles.link} href={env.GOOGLE_PLAY_URL} target={'_blank'}>
                                <Box 
                                    component="img" 
                                    src={gpLogo} 
                                    sx={styles.logoStore} 
                                    alt={strings.GOOGLE_PLAY_STORE_LOGO}
                                />
                            </Link>
                        </Box>
                    </Grid>
                </Grid>
                <Typography sx={styles.copy} variant="body2">
                    <Box tabIndex={0} sx={{display:'inline'}}>
                        {copyRightText}. {strings.ALL_RIGHTS_RESERVED} | {' '}
                    </Box>
                    <Link 
                        sx={styles.link} 
                        label={strings.TERMS_OF_SERVICE}
                        href={env.TERMS_OF_SERVICE_URL} 
                        target={'_blank'}>
                        {strings.TERMS_OF_SERVICE}
                    </Link>
                    {' '} | {' '}
                    <Link 
                        sx={styles.link} 
                        label={strings.TERMS_OF_SERVICE}
                        href={env.PRIVACY_POLICY_URL} 
                        target={'_blank'}>
                        {strings.PRIVACY_POLICY}
                    </Link>
                </Typography>
            </Box>
        </Layout>
    );
}

export default Landing;
