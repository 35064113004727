import React from 'react';

export default function Emergency(props) {
    return (
        <svg data-icon="emergency" xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
            <path
                d="M10.239 3.273c.962-1.667 3.368-1.667 4.33 0l7.322 12.682c.962 1.667-.24 3.75-2.165 3.75H5.082c-1.925 0-3.127-2.083-2.165-3.75l7.322-12.682Z"
                fill="#A1A4F1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.59 2.898c1.25-2.167 4.378-2.167 5.629 0L22.54 15.58c1.25 2.167-.313 4.875-2.815 4.875H5.082c-2.502 0-4.066-2.708-2.815-4.875L9.59 2.898Zm4.33.75c-.674-1.167-2.358-1.167-3.031 0L3.566 16.33c-.673 1.167.169 2.625 1.516 2.625h14.644c1.347 0 2.19-1.458 1.516-2.625L13.92 3.648Z"
                fill="#33366A"
            />
            <path
                d="M11.39 11.874a1.041 1.041 0 1 1 2.083 0v3.793a1.041 1.041 0 1 1-2.082 0v-3.793ZM11.39 8.75a1.041 1.041 0 1 1 2.083 0 1.041 1.041 0 0 1-2.082 0Z"
                fill="#33366A"
            />
        </svg>
    );
}
