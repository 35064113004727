/* eslint-disable react/jsx-props-no-spreading */
import { React, useState } from "react";
import { InputAdornment, IconButton, TextField } from "@mui/material";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { utils } from "comm-recipientapp-shared";

function PasswordField(props) {
    const [showPassword, setShowPassword] = useState(false);
    const strings = utils.useStrings();

    const handleClickShowPassword = () => {
        setShowPassword((prev) => !prev);
    };

    const handleMouseDownPassword = event => {
        event.preventDefault();
    };

    return (
        <TextField
            variant="standard"
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label={showPassword ? strings.HIDE_PASSWORD : strings.SHOW_PASSWORD}
                            aria-live="polite"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            data-testid="passVisibility"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                ),
                type: showPassword ? 'text' : 'password',
            }}
            {...props}
        />
    );
}

export default PasswordField;
