import React, { useEffect, useRef, useState, useMemo, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InboxIcon from '@mui/icons-material/Inbox';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Typography, CircularProgress, Container, Box, Divider } from '@mui/material';
import { utils } from 'comm-recipientapp-shared';
import useFilterOptions from 'comm-recipientapp-shared/src/hooks/inbox/useFilterOptions';
import noMessagesImage from 'comm-recipientapp-shared/src/assets/images/no-messages-inbox.svg';
import {
    setCurrentFilters,
    setCurrentSearchString,
    setArchivedMessageId,
    setCurrentMessageDetails,
} from 'comm-recipientapp-shared/src/store/inbox/inboxSlice';
import Title from '../Shared/NewUI/Title';
import SearchBar from './SearchBar';
import Message from './Message';
import TitleSeparator from './TitleSeparator';
import Pagination from './Pagination';
import Layout from '../Layout';
import Button from '../Shared/NewUI/Button';
import Settings from './Icons/Settings';
import FilterSettings from './FilterSettings';
import Popup from '../Attendance/Calendar/Popup';
import { capitilizeFirstLetter } from './FilterSettings/utils';
import NewInboxIcon from './Icons/NewInbox';
import useMessageActions from './hooks/useMessageActions';
import useWindowWidth from '../../hooks/useWindowWidth';
import { MESSAGES_PER_PAGE_SMALL_HEIGHT, MESSAGES_PER_PAGE_BIG_HEIGHT, ALL_MESSAGES, FILTERED_MESSAGES, SEARCHED_MESSAGES, READ } from './utils';
import ConsentPeding from '../ConsentPendingPhones';
import ConsentManageYourPin from '../ConsentManageYourPin';
import useFeedMessages from './hooks/useFeedMessages';
import useFeedFilters from './hooks/useFeedFilters';
import { isMobileSize } from './Message/utils';
import styles from './styles';

function Inbox() {
    const windowWidth = useWindowWidth();

    const strings = utils.useStrings();

    const [needsRefresh, setNeedsRefresh] = useState(true);

    const {
        currentFilters: initialFilters,
        currentSearchString,
        currentMessageDetails,
        archivedMessageId,
        broadcastTypes: broadcastStore,
        currentPagination,
    } = useSelector(state => state.inbox);

    const { inlineMode } = useSelector(state => state.initialize);

    const dispatch = useDispatch();

    const [messageHeader, setMessageHeader] = useState(ALL_MESSAGES);

    const [isFilterSettingsOpen, setIsFilterSettingsOpen] = useState(false);

    const [messagesPerPage, setMessagesPerPage] = useState(MESSAGES_PER_PAGE_SMALL_HEIGHT);

    const [showedConsentPeding, setShowedConsentPeding] = useState(false);

    const getMessageHeader = () => {
        switch (messageHeader) {
            case ALL_MESSAGES:
                return strings.ALL_MESSAGES;
            case FILTERED_MESSAGES:
                return strings.FILTERED_MESSAGES;
            case SEARCHED_MESSAGES:
                return strings.SEARCHED_MESSAGES;
            default:
                return '';
        }
    };

    const searchFieldRef = useRef();

    const feedFilters = useFeedFilters();

    const {
        currentData,
        currentPage,
        jumpToPage,
        totalItems,
        refetch: refetchFeed,
        isFetched,
        isFetching,
        resetPagination,
    } = useFeedMessages(currentPagination, messagesPerPage, () => setNeedsRefresh(false));

    const updateFiltersStore = updatedFilters => {
        dispatch(setCurrentFilters(updatedFilters));
        if (!currentMessageDetails.messageId) {
            resetPagination();
        }
    };

    const { currentFilterOptions, optionsHasActiveFilters, replaceCurrentFilterOptions, resetFilterOptions } = useFilterOptions(
        initialFilters,
        updateFiltersStore
    );

    const refetchData = async () => {
        await refetchFeed();
        await feedFilters.refetch();
        setNeedsRefresh(false);
    };

    const {
        archiveItem,
        handleMarkAllReadStatus,
        toggleMessageReadStatus,
        setMessageReadStatus
    } = useMessageActions(refetchData, currentData);

    const messageCountInPage = currentData.length;

    const handlePaginationChange = (_, value) => {
        jumpToPage(value);
    };

    const handleOpenFilterSettings = () => {
        setIsFilterSettingsOpen(true);
    };

    const handleCloseFilterSettings = () => {
        setIsFilterSettingsOpen(false);
    };

    const toggleReadStatus = async messageId => {
        await toggleMessageReadStatus(messageId);
    };

    const setReadStatus = async messageId => {
        await setMessageReadStatus(messageId);
    };

    const archiveMessage = async messageId => {
        await archiveItem(messageId);
    };

    const handleSearchBarChange = async e => {
        dispatch(setCurrentSearchString(e.target.value));
        // if the filter options have active filters, we need to reset them.
        if (optionsHasActiveFilters) {
            resetFilterOptions();
        }

        if (e.target.value.length < 1) {
            resetPagination();
            return;
        }

        resetPagination();
    };

    const handleClearSearch = () => {
        if (searchFieldRef.current?.value?.length > 0) {
            searchFieldRef.current.value = '';
        }
        handleSearchBarChange({ target: { value: '' } });
    };

    const handleCommitSearch = () => {
        searchFieldRef.current.value = currentSearchString;
        handleSearchBarChange({ target: { value: currentSearchString } });
    };

    const userHasMessages = (
        feedFilters?.data?.contacts?.length > 0 &&
        feedFilters?.data?.contacts?.some(contact => contact.hasItems)
    );

    const activeFiltersStrings = useMemo(() => {
        if (optionsHasActiveFilters) {
            setMessageHeader(FILTERED_MESSAGES);

            const arrayOfStrigs = [];

            const queryData = feedFilters.data;

            const { contacts } = queryData;
            const { from } = queryData;
            const { broadcastTypes } = queryData;

            Object.keys(currentFilterOptions).forEach(section => {
                if (Array.isArray(currentFilterOptions[section]) && currentFilterOptions[section].length > 0) {
                    currentFilterOptions[section].forEach(data => {
                        if (typeof data === 'object') {
                            if (Object.hasOwn(data, 'personId')) {
                                arrayOfStrigs.push(
                                    capitilizeFirstLetter(contacts.find(contact => contact.personId === data.personId)?.firstName?.toLowerCase())
                                );
                                return;
                            }

                            if (Object.hasOwn(data, 'typeId')) {
                                arrayOfStrigs.push(
                                    capitilizeFirstLetter(broadcastTypes.find(broadcast => broadcast.typeId === data.typeId)?.name?.toLowerCase())
                                );
                                return;
                            }
                        }

                        // if the type of data is a string, that means that is a status filter
                        if (typeof data === 'string') {
                            arrayOfStrigs.push(capitilizeFirstLetter(data.toLowerCase()));
                        }

                        // if the type of data is a number, that means that is a school id filter
                        if (typeof data === 'number') {
                            arrayOfStrigs.push(capitilizeFirstLetter(from.find(school => school.customerId === data)?.name?.toLowerCase()));
                        }
                    });
                }
            });
            return arrayOfStrigs.toString().split(',').join(', ');
        }

        return '';
    }, [currentFilterOptions, optionsHasActiveFilters]);

    useEffect(() => {
        if (!optionsHasActiveFilters && currentSearchString.length < 1) {
            setMessageHeader(ALL_MESSAGES);
            return;
        }
        if (optionsHasActiveFilters) {
            setMessageHeader(FILTERED_MESSAGES);
            return;
        }
        if (currentSearchString.length > 0) {
            setMessageHeader(SEARCHED_MESSAGES);
        }
    }, [optionsHasActiveFilters, currentSearchString]);

    useEffect(() => {
        if (currentMessageDetails.messageId) {
            setReadStatus(currentMessageDetails.messageId);

            if (archivedMessageId) {
                archiveMessage(archivedMessageId);
                dispatch(setArchivedMessageId(null));
            }

            dispatch(setCurrentMessageDetails({}));
        }
    }, [currentMessageDetails.messageId]);

    useEffect(() => {
        setMessagesPerPage(window.innerHeight < 1000 ? MESSAGES_PER_PAGE_SMALL_HEIGHT : MESSAGES_PER_PAGE_BIG_HEIGHT);

        if (currentSearchString.length > 0) {
            handleCommitSearch();
        }
    }, []);

    if (!userHasMessages && feedFilters.isFetched) {
        return (
            <Layout hideHeader={inlineMode}>
                {!inlineMode && (
                    <>
                        <ConsentPeding setShowedConsentPeding={setShowedConsentPeding} />
                        {showedConsentPeding && <ConsentManageYourPin />}
                    </>
                )}
                <Container sx={styles.container}>
                    <Box sx={styles.title_container}>
                        <Box sx={styles.icon_container}>
                            <NewInboxIcon sx={styles.icon_title} />
                        </Box>
                        <Title variant="h1" sx={styles.title}>
                            {strings.NEW_INBOX_TITLE}
                        </Title>
                    </Box>

                    <Divider />

                    <Box sx={styles.no_data_message_container}>
                        <img src={noMessagesImage} alt="no-messages-inbox" />
                        <Box>
                            <Title sx={styles.no_data_title}>{strings.INBOX_NO_MESSSAGES_TITLE}</Title>
                            <Typography sx={styles.no_data_subtitle}>{strings.INBOX_NO_MESSSAGES_DESCRIPTION}</Typography>
                        </Box>
                    </Box>
                </Container>
            </Layout>
        );
    }

    const paginationContainerStyle = inlineMode ? styles.pagination_inlineMode_container : styles.pagination_container;

    return (
        <Layout hideHeader={inlineMode}>
            {!inlineMode && (
                <>
                    <ConsentPeding setShowedConsentPeding={setShowedConsentPeding} />
                    {showedConsentPeding && <ConsentManageYourPin />}
                </>
            )}
            <Container sx={styles.container}>
                <Box tabIndex={0} aria-label={strings.INBOX} sx={styles.title_container}>
                    <Box sx={styles.icon_container}>
                        <NewInboxIcon sx={styles.icon_title} />
                    </Box>
                    <Title variant="h1" sx={styles.title}>
                        {strings.NEW_INBOX_TITLE}
                    </Title>
                </Box>

                <>
                    <Box sx={styles.search_bar_container}>
                        <SearchBar onChange={handleSearchBarChange} onClear={handleClearSearch} searchBarRef={searchFieldRef} debounceTime={500} />
                        <Box sx={styles.buttons_search_bar_container}>
                            <Button
                                onClick={handleOpenFilterSettings}
                                sx={styles.filter_button}
                                aria-describedby="filter_button_settings"
                                startIcon={<Settings />}
                            >
                                {strings.FILTER}
                            </Button>
                            <Button
                                onClick={async () => {
                                    setNeedsRefresh(true);
                                    handleClearSearch();
                                    resetPagination();
                                    resetFilterOptions();
                                    await refetchData();
                                }}
                                sx={styles.filter_button}
                                aria-describedby="filter_button_settings"
                                startIcon={<RefreshIcon />}
                            >
                                {strings.REFRESH}
                            </Button>
                            <Popup
                                isOpen={isFilterSettingsOpen}
                                handleClose={handleCloseFilterSettings}
                                customStyles={styles.filter_options_popup}
                                fullScreen={isMobileSize(windowWidth)}
                                hideCloseButton
                            >
                                <FilterSettings
                                    handleClose={handleCloseFilterSettings}
                                    currentFilterOptionsFromParent={currentFilterOptions}
                                    replaceCurrentFilterOptions={replaceCurrentFilterOptions}
                                    categories={broadcastStore}
                                    feedFilters={feedFilters}
                                />
                            </Popup>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            ...styles.filter_settings_container,
                            display: optionsHasActiveFilters ? 'flex' : 'none',
                        }}
                    >
                        {optionsHasActiveFilters && (
                            <Typography sx={styles.active_filters_text}>
                                ({strings.FILTERED_BY}: {activeFiltersStrings})
                            </Typography>
                        )}
                    </Box>
                </>

                {needsRefresh ? (
                    <Box sx={styles.loader_container}>
                        <Box>
                            <Box sx={styles.loader_box}>
                                <CircularProgress sx={styles.loader} />
                            </Box>
                            <Typography>{strings.LOADING_MESSAGES}</Typography>
                        </Box>
                    </Box>
                ) : (
                    <Box sx={styles.new_for_you}>
                        <TitleSeparator
                            title={getMessageHeader()}
                            badgeCount={totalItems}
                            handleMarkAllReadStatus={handleMarkAllReadStatus}
                            windowWidth={windowWidth}
                        />
                        {messageCountInPage === 0 && isFetched && (
                            <Box data-testid="noMsgContainer" sx={styles.noMsgContainer}>
                                <Box sx={styles.icon_container}>
                                    <InboxIcon data-testid="noMsgIcon" sx={styles.noMsgIcon} />
                                </Box>
                                <Title variant="h1" sx={styles.noMsgTitle} data-testid="noMsgTitle">
                                    {strings.THERE_ARE_NO_MESSAGES_FOUND}
                                </Title>
                            </Box>
                        )}
                        {messageCountInPage > 0 && feedFilters.isFetched && (
                            <Box sx={styles.messages_container}>
                                {currentData.map((message, i) => {
                                    return (
                                        <Message
                                            // eslint-disable-next-line react/no-array-index-key
                                            key={`message-${message.broadcast.jobId}-${i}`}
                                            title={message.name}
                                            sender={message.broadcast.from}
                                            date={new Date(message.publishTimestamp)}
                                            read={message.status.length > 0 && message.status.includes(READ.toLowerCase())}
                                            toggleReadStatus={toggleReadStatus}
                                            customerId={message.customerId}
                                            messageId={message.id}
                                            needsDivider={i !== currentData.length - 1}
                                            currenPage={currentPage}
                                            people={message.broadcast.recipients}
                                            archiveItem={archiveMessage}
                                            messageType={message.broadcast.jobTypeId}
                                            messageContent={message.openingSentence}
                                            hasHtml={message.broadcast.channels.email}
                                            hasVoice={message.broadcast.channels.call}
                                            hasSms={message.broadcast.channels.sms}
                                            smsContent={message.openingSentence}
                                            windowWidth={windowWidth}
                                            feedFilters={feedFilters.data}
                                            jobId={message.broadcast.jobId}
                                        />
                                    );
                                })}
                            </Box>
                        )}

                        {messagesPerPage < totalItems && (
                            <Box sx={paginationContainerStyle}>
                                <Pagination
                                    numberOfItems={totalItems}
                                    handleChange={handlePaginationChange}
                                    itemsPerPage={messagesPerPage}
                                    page={currentPage}
                                    hideNextButton
                                    hidePrevButton
                                    disabled={isFetching}
                                />
                            </Box>
                        )}
                    </Box>
                )}
            </Container>
        </Layout>
    );
}

export default memo(Inbox);
