import { React }from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { 
    Box, 
    Grid, 
    Link, 
    Typography, 
} from '@mui/material';
import { utils } from 'comm-recipientapp-shared';
import styles from './styles';

function NewEmailOrNewUser({userEmail, messageDisplay}) {
    const strings = utils.useStrings();
    return (
        <Grid 
            item 
            lg={6} 
            md={12} 
            xs={12} 
            sx={styles.grid_container}
            data-testid="new-email-or-new-user"
        >
            <Box sx={styles.box_container}>
                {(messageDisplay === "newEmail") ? 
                    <Typography sx={styles.header} variant="h5">{strings.WELCOME_BACK_TO_SCHOOLMESSENGER}!</Typography>
                    : <Typography sx={styles.header} variant="h5">{strings.WELCOME_TO_SCHOOLMESSENGER}!</Typography>
                }
                <Typography sx={styles.text}>
                    {strings.PLEASE_VERIFY_YOUR_ACCOUNT_BY_FOLLOWING_INSTRUCTIONS_WE_SENT_TO}      
                    <Box component='span' sx={styles.email}> {userEmail}</Box>
                </Typography>
                <Typography sx={styles.text}>
                    {strings.THIS_IS_THE_EMAIL_ADDRESS_YOUR_SCHOOL_HAS_ON_RECORD_IF_YOU_WISH_TO_USE_A_DIFFERENT_ONE_PLEASE_CONTACT_AND_ASK_THEM_TO_UPDATE_YOUR_EMAIL_ADDRESS}
                </Typography>
                <Typography sx={styles.text}>
                    {strings.EMAIL_VERIFICATION_IS_NOT_REQUIRED_IN_ORDER_TO_USE_SCHOOLMESSENGER_HOWEVER_YOU_MAY_SEE_INCOMPLETE} 
                    <Link component={RouterLink} sx={styles.link} underline="none" to="/inbox"> {strings.MESSAGES} </Link> 
                    {strings.AND} 
                    <Link component={RouterLink} sx={styles.link} underline="none" to="/my-students"> {strings.CONTACTS} </Link> 
                    {strings.VIEWS}.
                </Typography>
            </Box>
        </Grid>
    )
}

export default NewEmailOrNewUser;


