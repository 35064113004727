import { palette } from 'comm-recipientapp-shared';
import theme from '../../../config/style/muiTheme';
const styles = {
    alert_text: {
        fontSize: '16px',
        color: '#444',
        fontWeight:' 400',
        marginBottom: '1.25rem',
    },
    button: {
        marginRight: '10px',
        color: theme.palette.primary.main,
        backgroundColor: 'white',
    },
    button_container: {
        display: 'flex', 
        justifyContent: 'center', 
        marginBottom: '1.25rem' ,
    },
    dialog:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0',
        "& .MuiDialog-container": {
            "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "640px",
            }
        }
    },
    dialog_box: {
        display: 'flex',
        width: '80%',
        margin: 'auto'
    },
    dialog_content: {
        display: 'flex', 
        flexDirection: 'column', 
        padding: '0'
    },
    divider: {
        alignItems: 'center', 
        justifyContent: 'center', 
        marginTop: '0', 
        marginBottom: '1.25rem',
    },
    icon: {
        marginRight: '6px', 
        verticalAlign: 'text-bottom',
    },
    resend_link: {
        textDecoration: 'none',
        cursor: 'pointer',
    },
    resend_confirm_text: {
        color: palette.success.dark,
        marginBottom: '1.25rem', 
        marginTop: '0',
        lineHeight: '1.6', 
        fontSize: '14px', 
        fontWeight: '400', 
    },
    resend_link_text: {
        color: theme.palette.primary.main,
        marginBottom: '1.25rem', 
        marginTop: '0',
        lineHeight: '1.6', 
        fontSize: '14px', 
        fontWeight: '400', 
    },
    text: {
        marginBottom: '1.25rem', 
        lineHeight: '1.6', 
        fontSize: '14px', 
        fontWeight: '300', 
        color: '#444',
    },
    title: {
        textAlign: 'center',
        color: '#ffff',
        bgcolor: palette.primary.main,
        padding: '25px',
        marginBottom: '2.5rem',
    }
};

export default styles;
