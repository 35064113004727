import axios from 'axios';
import { handleExpiredToken, handleServerFatalError, handleToken } from './interceptors';
import { CHAT_RECIPIENT_SERVICE, RECIPIENT_SERVICE, RECIPIENT_SERVICE_PHONE_VERIFICATION } from './constants';

const createInspectedAxiosInstance = baseURL => {
    const axiosInstance = axios.create({ baseURL });
    axiosInstance.interceptors.request.use(handleToken);
    axiosInstance.interceptors.response.use(request => request, handleExpiredToken);
    axiosInstance.interceptors.response.use(request => request, handleServerFatalError);
    return axiosInstance;
};

const HTTP = createInspectedAxiosInstance(RECIPIENT_SERVICE);

export const HTTPPhoneVerificationServer = createInspectedAxiosInstance(RECIPIENT_SERVICE_PHONE_VERIFICATION);

export const HTTPFeed = createInspectedAxiosInstance(RECIPIENT_SERVICE);

export const CHAT_URL = createInspectedAxiosInstance(CHAT_RECIPIENT_SERVICE);
export default HTTP;
