import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import Box from '@mui/material/Box';
import { utils, actions } from 'comm-recipientapp-shared';
import Layout from '../../../Layout';
import BackButton from '../../../Shared/BackButton';
import Notification from '../../../Utils/Notification';
import Item from './item';
import ChangePhone from './change';
import styles from './styles';
import Button from '../../../Shared/Button';
import ModalError from './modalError';

export const EMERGENCY_ID = 1;
export const ATTENDANCE_ID = 2;

function EditPhonePreferences() {

    const [visible, setVisible] = useState(false);
    const [showModalError, setShowModalError] = useState(false);
    const [preferences, setPreferences] = useState({});
    const [currentPhone, setCurrentPhone] = useState({});
    const [consent, setConsent] = useState('');
    const [notification, setNotification] = useState({
        open: false,
        type: '',
        message: '',
    });
    const [startLoading, setStartLoading] = useState(false);
    const [startLoadingSaveButtonOnModal, setStartLoadingSaveButtonOnModal] = useState(false);

    const {
        state: { type },
    } = useLocation();

    const activeSchool = useSelector(state => state.preferences.activeSchool);
    const schools = useSelector(state => state.preferences.schools);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const strings = utils.useStrings();

    const { phone } = useParams();

    /** @type {Array} */
    const notifications = activeSchool?.notificationTypes;
    const goBackTo = location.state?.goBackTo || -1;

    const data = useMemo(() => {
        return notifications.concat().sort(utils.helpers.orderBy(['priority', 'name'], [utils.constants.ASCENDING, utils.constants.ASCENDING]));
    }, [notifications]);

    const emergencyPhones = useMemo(() => {
        const emergencyNotifications = activeSchool.destinationPreferences.phones.filter(p => p.notificationTypes.includes(EMERGENCY_ID));

        return emergencyNotifications;
    }, [activeSchool]);

    const attendancePhones = useMemo(() => {
        const attendanceNotifications = activeSchool.destinationPreferences.phones.filter(p => p.notificationTypes.includes(ATTENDANCE_ID));

        return attendanceNotifications;
    }, [activeSchool]);

    useEffect(() => {
        if (activeSchool) {
            setPreferences(activeSchool.destinationPreferences || []);
            const objectPhone = activeSchool?.destinationPreferences?.phones?.find(value => value.phone === phone);
            setConsent(objectPhone?.registry?.consent);
            setCurrentPhone(objectPhone);
        }
    }, [activeSchool]);

    useEffect(() => {
        if (schools.length > 0) {
            const schoolUpdatedData = schools.find(school => school.id === activeSchool.id) || schools[0];
            dispatch(actions.preferences.setActiveSchool(schoolUpdatedData));
        }
    }, [schools]);

    useEffect(() => {
        dispatch(actions.preferences.getData());
    }, []);

    const handleVisible = () => setVisible(!visible);

    const handleUpdatePhone = notificationId => {
        const phoneIndex = preferences.phones.findIndex(val => val.phone === phone);

        const currentNotifications = preferences.phones[phoneIndex].notificationTypes || [];

        const newNotifications = utils.helpers.toggleArrayValue(currentNotifications, notificationId);

        setPreferences(preference => ({
            ...preference,
            phones: preference.phones.map((obj, i) => (i === phoneIndex ? { ...obj, notificationTypes: newNotifications } : obj)),
        }));
    };

    const handleUpdateSMS = notificationId => {
        const smsIndex = preferences.smses.findIndex(val => val.sms === phone);

        if (smsIndex === -1) {
            const currentNotifications = preferences.smses[smsIndex]?.notificationTypes || [];
            const newNotifications = utils.helpers.toggleArrayValue(currentNotifications, notificationId);

            setPreferences(preference => ({
                ...preference,
                smses: [
                    ...preference.smses,
                    {
                        sms: currentPhone.phone,
                        notificationTypes: newNotifications,
                        canDelete: activeSchool.settings.canAddDeleteManualDestinations,
                        isImported: false,
                    },
                ],
            }));

            return;
        }

        const currentNotifications = preferences.smses[smsIndex]?.notificationTypes || [];
        const newNotifications = utils.helpers.toggleArrayValue(currentNotifications, notificationId);

        setPreferences(preference => ({
            ...preference,
            smses: preference.smses.map((obj, i) => (i === smsIndex ? { ...obj, notificationTypes: newNotifications } : obj)),
        }));
    };

    const handleClickBackButton = () => {
        navigate(goBackTo);
    };

    const handleSavePreferences = async () => {
        let response;
        setStartLoading(true);
        try {
            const payload = {
                ...preferences,
            };
            response = await dispatch(actions.preferences.updatePreferences(activeSchool?.id, payload));

            if (visible) setVisible(false);
            if (response) {
                setNotification({
                    open: true,
                    type: utils.constants.NotificationType.INFO,
                    message: strings.SUCCESSFULLY_SAVED_RETURNING_TO_THE_PREFERENCES_PAGE,
                });
                setTimeout(() => {
                    handleClickBackButton();
                }, 4000);
            }
        } catch (error) {
            response = false;
        } finally {
            setStartLoading(false);
        }

        if (!response) {
            setNotification({
                open: true,
                type: utils.constants.NotificationType.ERROR,
                message: strings.SOMETHING_WENT_WRONG_TRY_LATER,
            });
        }
    };

    const handleSavePreferenceModal = async selection => {

        const phoneIndex = preferences.phones.findIndex(val => val.phone === phone);

        let response;
        setStartLoadingSaveButtonOnModal(true);
        try {
            const payload = {
                ...preferences,
                phones: preferences.phones.map((obj, i) => (i === phoneIndex ? { ...obj, registry: { consent: selection } } : obj)),
            };
            response = await dispatch(actions.preferences.updatePreferences(activeSchool?.id, payload));
            // It's necesary to update the value of ActiveSchool, to recalculate all the others states
            dispatch(actions.preferences.setActiveSchool({ ...activeSchool, destinationPreferences: payload }));

            if (response) {
                if (visible) setVisible(false);
                setNotification({
                    open: true,
                    type: utils.constants.NotificationType.INFO,
                    message: strings.DONE,
                });
            }
        } catch (error) {
            response = false;
        } finally {
            setStartLoadingSaveButtonOnModal(false);
        }

        if (!response) {
            setNotification({
                open: true,
                type: utils.constants.NotificationType.ERROR,
                message: strings.SOMETHING_WENT_WRONG_TRY_LATER,
            });
        }
    };


    const selected = useMemo(() => {
        return utils.helpers.hasSelectedNotifications(activeSchool, currentPhone);
    }, [activeSchool, currentPhone]);

    const status = useMemo(() => {
        return utils.helpers.getDetailPhoneStatus(activeSchool, currentPhone, strings);
    }, [activeSchool, currentPhone]);

    const dataFiltered = useMemo(() => {
        return type === utils.constants.SMS ? data.filter(val => val.type !== utils.constants.SURVEY) : data;
    }, [type, data]);

    return (
        <Layout>
            <BackButton handleClickBackButton={handleClickBackButton} />
            <Container disableGutters maxWidth="lg" sx={styles.content}>
                <div style={styles.content_title}>
                    <Typography style={styles.title} component="h1">
                        {strings.MY_PHONE}
                    </Typography>
                </div>
                <Box sx={styles.card}>
                    <Box sx={styles.card_info}>
                        <Box sx={selected ? styles.card_status_unavailable : styles.card_status}>
                            <div style={styles.card_circle}>
                                {type === utils.constants.PHONE ? (
                                    <LocalPhoneIcon style={styles.icon_color} />
                                ) : (
                                    <ChatBubbleIcon style={styles.icon_color} />
                                )}
                            </div>
                        </Box>
                        <Typography sx={styles.value}>{utils.helpers.formatPhoneNumber(phone)}</Typography>
                    </Box>
                    <Box sx={styles.card_information}>
                        {type === utils.constants.PHONE ? (
                            <>
                                <div style={styles.card_information_list}>
                                    <Typography sx={styles.ok_call_phone}>{status?.label}</Typography> <span style={styles.spacer}>|</span>
                                    <Button size="small" sx={styles.card_button} onClick={() => setVisible(!visible)}>
                                        {strings.CHANGE}
                                    </Button>
                                </div>
                                <div style={styles.info_bellow}>
                                    <Typography sx={styles.consent}>{status?.description}</Typography>
                                </div>
                            </>
                        ) : (
                            <div style={styles.info_bellow}>
                                <Typography sx={styles.ok_call}>{strings.THIS_NUMBER_WILL_ONLY_RECEIVE_TEXT_MESSAGES}</Typography>
                                <Typography sx={styles.consent}>
                                    {strings.PLEASE_CONTACT_YOUR_SCHOOL_ADMINISTRATOR_IF_YOU_WOULD_LIKE_TO_RECEIVE_CALLS_ON_THIS_NUMBER}
                                </Typography>
                            </div>
                        )}
                    </Box>
                </Box>
                <Box sx={styles.content}>
                    <Typography sx={styles.titles}>
                        {strings.CHOOSE_THE_MESSAGES_YOU_ARE_INTERESTED_IN_RECEIVED_FROM} {activeSchool?.name},{' '}
                        {strings.AND_HOW_YOU_WANT_TO_RECEIVE_THEM}
                    </Typography>
                    <Box sx={styles.box_list}>
                        <Grid container rowSpacing={2} columnSpacing={2} sx={styles.message_list}>
                            {dataFiltered?.map((value, index) => (
                                <Grid key={value.id} xs={12} sm={12} md={3}>
                                    <Item
                                        activePhone={phone}
                                        handleUpdatePhone={handleUpdatePhone}
                                        handleUpdateSMS={handleUpdateSMS}
                                        item={value}
                                        index={index}
                                        preferences={preferences}
                                        type={type}
                                        showError={setShowModalError}
                                        emergencyPhones={emergencyPhones}
                                        attendancePhones={attendancePhones}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Box>
                <Box sx={styles.actions_button}>
                    <Button sx={styles.btn} onClick={handleClickBackButton} data-testid="cancel_btn">
                        {strings.CANCEL}
                    </Button>
                    <Box sx={styles.ButttonBox}>
                        <Button onClick={handleSavePreferences} data-testid="save_btn">
                            {strings.SAVE}
                        </Button>
                        {startLoading && (
                            <CircularProgress
                                role="status"
                                aria-live="assertive"
                                aria-label={strings.LOADING}
                                size={24}
                                sx={styles.CircularProgress}
                            />
                        )}
                    </Box>
                </Box>
            </Container>
            {visible && (
                <ChangePhone
                    consent={consent}
                    handleSavePreferences={handleSavePreferenceModal}
                    handleVisible={handleVisible}
                    open={visible}
                    phone={phone}
                    startLoadingSaveButtonOnModal={startLoadingSaveButtonOnModal}
                />
            )}
            <Notification
                open={notification.open}
                handleClose={() => setNotification({ ...notification, open: !notification.open })}
                type={notification.type}
                message={notification.message}
            />
            <ModalError visible={showModalError} handleClose={() => setShowModalError(false)} />
        </Layout>
    );
}

export default EditPhonePreferences;
