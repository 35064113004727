/* eslint-disable import/prefer-default-export */
import { HTTPFeed } from '../HTTP';

/**
 * @param {{
 *     page: number;
 *     from: number[];
 *     contacts: { personId: number; customerId: number }[];
 *     types: { typeId: number; customerId: number }[];
 *     size: number;
 *     status: 'read' | 'unread';
 *     search: string;
 * }} opts
 */
export const getFeedItems = async (opts = { size: 8, page: 1, status: '', contacts: [], types: [], from: [], search: '' }) => {
    const qs = new URLSearchParams();

    qs.append('page', opts?.page || 1);
    qs.append('size', opts?.size || 8);
    qs.append('status', opts?.status || '');

    if (opts?.search?.length > 0) {
        qs.append('search', opts.search);
    }

    if (opts?.from) {
        qs.append('from', opts.from);
    }

    let queryString = qs.toString();

    if (opts?.contacts?.length > 0) {
        opts.contacts.forEach((contact, idx) => {
            queryString += `&contacts[${idx}].pid=${contact.personId}&contacts[${idx}].cid=${contact.customerId}`;
        });
    }

    if (opts?.types?.length > 0) {
        opts.types.forEach((type, idx) => {
            queryString += `&types[${idx}].tid=${type.typeId}&types[${idx}].cid=${type.customerId}`;
        });
    }

    const res = await HTTPFeed.get(`feed?${queryString}`);
    return res.data;
};

export const getFeedFilters = async () => {
    const res = await HTTPFeed.get(`feed/filters`);
    return res.data;
};

/**
 * @param {number[]} messages
 * @param {'READ' | 'UNREAD' | 'ARCHIVED'} status
 * @param {timestamp} sentBeforeOrAt
 */
export const updateMessagesStatus = async (messages, status, sentBeforeOrAt ) => {
    const res = await HTTPFeed.post(`feed/status`, {
        status: [status],
        items: {
            ids: messages,
            sentBeforeOrAt: sentBeforeOrAt,
        },
    });
    return res.data;
};
