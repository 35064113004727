import { uniq } from 'ramda';
import { stripHtml } from 'string-strip-html';

export const formatInboxData = data => {
    let formattedData = [];
    if (data?.jobs) {
        formattedData = data?.jobs?.map((msg, index) => {
            const message = msg;
            const hasContent = message?.expand ? message?.expand : false;
            const contentData = message?.expand?.emails[0];

            const formattedMessageContent = contentData?.isHtml ? stripHtml(contentData?.htmlBody).result : '';

            return {
                id: index,
                messageId: message?.id,
                sender: `${message?.sender?.firstName} ${message?.sender?.lastName}`,
                read: message?.status?.length > 0,
                customerId: message?.customerId,
                people: message?.people,
                title: message?.name,
                sentTimestampMs: message?.sentTimestampMs,
                type: message?.jobType?.name,
                messageContent: formattedMessageContent,
                attachment: contentData?.attachment?.map(attachment => {
                    return { name: attachment };
                }),
                hasHtml: hasContent?.emails?.length > 0,
                hasSms: hasContent?.sms,
                hasVoice: hasContent?.voice,
                messageHtml: contentData && contentData?.isHtml ? contentData?.htmlBody : '',
            };
        });
    } else {
        formattedData = [];
    }
    return formattedData;
};

export const formatFeedData = (feedData, feedFilters) => {
    if (feedData && feedFilters) {
        return feedData?.map(feed => {
            // Extract Receipient/People Names
            const people = feed?.broadcast.recipients.map(recipient => {
                return feedFilters?.contacts?.find(contact => contact.customerId === feed.customerId && contact.personId === recipient.personId);
            });
            const jobType = feedFilters.broadcastTypes.find(
                broadCastType => broadCastType.typeId === feed.broadcast.jobTypeId && broadCastType.customerId === feed.customerId
            );

            // eslint-disable-next-line consistent-return
            return {
                id: feed.id,
                messageId: feed?.broadcast.jobId,
                sender: feed?.broadcast?.from,
                read: feed?.status?.length > 0,
                customerId: feed?.customerId,
                people,
                title: feed?.name,
                sentTimestampMs: feed?.publishTimestamp,
                type: `${jobType?.name?.charAt(0)?.toUpperCase()}${jobType?.name?.slice(1)}`,
                messageContent: feed.openingSentence,
                hasHtml: feed.broadcast.channels.email,
                hasSms: feed.broadcast.channels.sms,
                hasVoice: feed.broadcast.channels.call,
            };
        });
    }
};

export const extractBroadcastTypes = data => {
    let broadcastTypes = [];
    if (Array.isArray(data)) {
        const types = data?.map(customer => {
            return customer?.notificationTypes;
        });
        const flatten = types?.flat(1);

        const sorted = flatten?.sort((a, b) => a.priority - b.priority);

        broadcastTypes = uniq(sorted?.map(each => each?.name));
    } else {
        broadcastTypes = [];
    }
    return broadcastTypes;
};
