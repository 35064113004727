import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery, Grid, Container, Paper, Typography, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation, useNavigate } from 'react-router-dom';
import { actions, utils } from 'comm-recipientapp-shared';
import Layout from '../Layout';
import LoginForm from './LoginForm';
import InactiveAccount from './Inactive';
import SplashScreen from '../Shared/SplashScreen';
import styles from './styles';
import { ONE_WAY_SMS_SUPPORT } from '../../config/routes/private';

function Login() {
    const [loadingText, setLoadingText] = useState('');
    const [password, setPassword] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [status, setStatus] = useState('');
    const [userId, setUserId] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const strings = utils.useStrings();
    const auth = useSelector(state => state.auth);
    const user = useSelector(state => state.user);
    const absence = useSelector(state => state.absence);
    const preferences = useSelector(state => state.preferences);
    const { resetErrorAuth } = actions.auth;
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const alreadyLoggedIn = user.account && absence.isFetched && preferences.isFetched;
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);

    const handleSubmit = async values => {
        setLoadingText(values.email);
        setPassword(values.password);
        setIsModalVisible(false);
        const response = await dispatch(actions.auth.login(values));
        if (response.message !== utils.constants.AUTH_ERROR) {
            if (response.confirmed) {
                setIsModalVisible(false);
            } else {
                setIsModalVisible(true);
                dispatch(resetErrorAuth());
            }
            setUserId(response.message);
        }
    };

    const handleResendVerification = async () => {
        const payload = {
            username: loadingText,
            password,
        };

        const response = await dispatch(actions.auth.resendEmailRequest(userId, payload));

        if (response.error || response.message === utils.constants.ALREADY_EXISTS) {
            setStatus(utils.constants.NotificationType.ERROR);
        } else {
            setStatus(utils.constants.NotificationType.SUCCESS);
        }
    };

    const handleClose = () => {
        setStatus('');
        setIsModalVisible(!isModalVisible);
    };

    useEffect(() => {
        dispatch(resetErrorAuth());
    }, []);

    useEffect(() => {
        if (alreadyLoggedIn) {
            navigate(urlParams.has('c') ? `${ONE_WAY_SMS_SUPPORT}${location.search}` : '/inbox');
        }
    }, [user, absence, preferences]);

    if (alreadyLoggedIn) {
        return <></>;
    }

    return (
        <Layout>
            <Container maxWidth="xl" disableGutters={isSmallScreen} sx={styles.content}>
                <Grid container component="main" sx={styles.griMain}>
                    <Grid item xs={12} sm={12} md={6} component={Paper} elevation={6} square>
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                            <div style={{ ...styles.indicator, display: auth.isLoading ? 'flex' : 'none' }}>
                                <CircularProgress role="status" aria-live="assertive" aria-label={strings.LOADING} size={30} style={styles.auto} />
                                <Typography variant="span" style={styles.indicator_value}>
                                    {strings.LOADING} {loadingText}
                                </Typography>
                            </div>
                            <LoginForm handleSubmit={handleSubmit} />
                        </Box>
                    </Grid>
                    <Grid item md={6} sx={styles.gridSplashScreen} aria-hidden>
                        <SplashScreen />
                    </Grid>
                </Grid>
            </Container>
            <InactiveAccount
                handleClose={handleClose}
                handleResendVerification={handleResendVerification}
                loadingText={loadingText}
                status={status}
                visible={isModalVisible}
            />
        </Layout>
    );
}

export default Login;
