// eslint-disable-next-line camelcase
import * as constants from './constants';

export const getTypeOfInformation = object => {
    if (object.hasOwnProperty(constants.PHONE)) {
        return constants.PHONE;
    }

    if (object.hasOwnProperty(constants.SMS)) {
        return constants.SMS;
    }

    return constants.EMAIL;
};

/**
 * Check if a variable is empty, null or undefined
 *
 * @param value
 * @returns {boolean}
 */
export const isEmpty = value => {
    return (
        // null or undefined
        value == null ||
        // has length and it's zero
        (value.hasOwnProperty('length') && value.length === 0) ||
        // is an Object and has no keys
        (value.constructor === Object && Object.keys(value).length === 0)
    );
};

export const formatPhoneNumber = phoneNumberString => {
    const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
    }

    return phoneNumberString;
};

/* Preferences */

export const showPhoneIcon = activeSchool => activeSchool && activeSchool.destinationPreferences.phones.length > 0;

// eslint-disable-next-line consistent-return
export const showSmsIcon = (activeSchool, notification) => {
    if (activeSchool) {
        // eslint-disable-next-line max-len
        return activeSchool.settings.maxSms > 0 && activeSchool.destinationPreferences.smses.length > 0 && notification.type !== constants.SURVEY;
    }
};

export const showEmailIcon = activeSchool => activeSchool && activeSchool.destinationPreferences.emails.length > 0;

// eslint-disable-next-line max-len
export const isEveryPhoneInactive = phones => phones.every(phone => phone.isVerified ? phone.isVerified : false);

// eslint-disable-next-line max-len
export const isPhoneActive = (activeSchool, notificationId) => {
    if (isEmpty(activeSchool?.destinationPreferences?.phones[0]?.phone) || isEmpty(notificationId)) {
        return false;
    }

    const phones = activeSchool && activeSchool.destinationPreferences.phones;
    // eslint-disable-next-line max-len
    return phones.some(phone => phone.notificationTypes.includes(notificationId)) && !isEveryPhoneInactive(phones);
};

// eslint-disable-next-line consistent-return, max-len
export const isSMSActive = (activeSchool, notificationId) =>
    activeSchool && activeSchool.destinationPreferences.smses.some(sms => sms.notificationTypes.includes(notificationId));

// eslint-disable-next-line max-len
export const isEmailActive = (activeSchool, notificationId) =>
    activeSchool && activeSchool.destinationPreferences.emails.some(email => email.notificationTypes.includes(notificationId));

const sortByAsc = (key, cb) => {
    // eslint-disable-next-line no-nested-ternary
    return (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : cb(a, b));
};

const sortByDesc = (key, cb) => {
    // eslint-disable-next-line no-nested-ternary
    return (b, a) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : cb(b, a));
};

export const orderBy = (keys, orders) => {
    let cb = () => 0;
    keys.reverse();
    orders.reverse();
    // eslint-disable-next-line no-restricted-syntax
    for (const [i, key] of keys.entries()) {
        const order = orders[i];
        if (order === constants.ASCENDING) cb = sortByAsc(key, cb);
        else if (order === constants.DESCENDING) cb = sortByDesc(key, cb);
    }
    return cb;
};

export const haveEmergencySelected = phone => {
    if (isEmpty(phone?.notificationTypes)) {
        return false;
    }
    return phone.notificationTypes?.includes(constants.EMERGENCY_KEY);
};

export const haveAttendanceSelected = phone => {
    if (isEmpty(phone?.notificationTypes)) {
        return false;
    }
    return phone.notificationTypes?.includes(constants.ATTENDANCE_KEY);
};

export const isLastPhone = (value, phonePriorityEnforcement, key, fn, phones = []) => {
    if (phonePriorityEnforcement.includes(key)) {
        let countUnSelectedPhones = 0;
        let currentPhone = {};

        phones.forEach(phone => {
            if (fn(phone)) {
                countUnSelectedPhones += 1;
                currentPhone = phone;
            }
        });

        return countUnSelectedPhones === 1 && currentPhone?.phone === value && haveEmergencySelected(currentPhone);
    }

    return false;
};

export const getConsentPhone = (consent, hasEmergency, strings) => {
    switch (consent) {
        case constants.YES:
            return strings.OK_TO_CALL;
        case constants.NO:
            return hasEmergency ? strings.EMERGENCY_CALLS_ONLY : strings.DO_NOT_CALL;
        case constants.PENDING:
            return strings.PENDING_CONSENT_TO_CALL;
        default:
            return strings.UNKNOWN;
    }
};

export const isPhoneVerified = (val, strings) => val === strings.INACTIVE_UNTIL_VERIFIED;

export const phoneHasMessagesActive = (activeSchool, activePhoneNumberString) => {
    const currentPhone = activeSchool?.destinationPreferences?.smses.find(val => val.sms === activePhoneNumberString);
    return currentPhone?.notificationTypes.length > 0;
};

export const isImportedPhone = (school, phoneNumber) => {
    const phone = school?.destinationPreferences?.phones.find(val => val.phone === phoneNumber);
    return phone?.isImported || false;
};

export const isImportedEmail = (school, emailToVerify) => {
    const emailFound = school?.destinationPreferences?.emails.find(val => val.email === emailToVerify);
    return emailFound?.isImported || false;
};

export const getPhoneStatus = (school, phone, strings) => {
    if (phone.sms && !phone.phone) {
        return strings.TEXT_ONLY;
    }

    if (!phone.registry?.consent) {
        return strings.PHONE_NUMBER_STATUS_UNAVAILABLE;
    }

    if (phone.isImported === false) {
        if (phone?.isVerified === false) {
            return strings.INACTIVE_UNTIL_VERIFIED;
        }
    }
    const hasEmergency = haveEmergencySelected(phone);

    return getConsentPhone(phone.registry?.consent, hasEmergency, strings);
};

export const toggleArrayValue = (arrayList, arrayValue) =>
    arrayList?.includes(arrayValue) ? arrayList.filter(el => el !== arrayValue) : [...arrayList, arrayValue];

export const isOnlyEmergency = phone => {
    return phone?.notificationTypes?.includes(constants.EMERGENCY_KEY);
};

/**
 * Check icon color details phone
 *
 * @param activeSchool
 * @param phone
 * @returns {boolean}
 */
export const hasSelectedNotifications = (activeSchool, phone) => {
    if (
        isEmpty(activeSchool?.notificationTypes) ||
        isEmpty(activeSchool?.notificationTypes[0]?.priority) ||
        isEmpty(activeSchool?.settings?.requireConsent) ||
        isEmpty(activeSchool?.destinationPreferences) ||
        isEmpty(phone?.registry?.consent)
    ) {
        return false;
    }

    try {
        const phoneConsent = phone?.registry?.consent;
        const phones = !isEmpty(activeSchool.destinationPreferences.phones);
        const hasSelectedSMS = !isEmpty(activeSchool.destinationPreferences.smses);
        let hasSelectedPhone = false;

        if (activeSchool.settings.requireConsent) {
            const consentStateWithAnySelected = phoneConsent === constants.YES || !phoneConsent;

            activeSchool?.notificationTypes?.some(value => {
                hasSelectedPhone =
                    (phones && consentStateWithAnySelected) ||
                    (phoneConsent === constants.NO && value.priority === 1 && value.phones) ||
                    (phoneConsent === constants.PENDING && value.priority === 1 && phones);
                return hasSelectedPhone; // A 'true' breaks the loop
            });
        } else {
            const consentPending = phoneConsent === constants.PENDING || phoneConsent === constants.YES || !phoneConsent;

            activeSchool?.notificationTypes?.some(value => {
                hasSelectedPhone = (phones && consentPending) || (phoneConsent === constants.NO && value.priority === 1 && phones);
                return hasSelectedPhone; // A 'true' breaks the loop
            });
        }

        return hasSelectedPhone || hasSelectedSMS;
    } catch (error) {
        return false;
    }
};

export const getDetailPhoneStatus = (activeSchool, phone, strings) => {
    const status = phone?.registry?.consent;
    const isEmergencyPhoneSelected = isOnlyEmergency(phone);

    switch (status) {
        case constants.PreferencePhoneConsent.YES:
            return {
                label: strings.ITS_OK_TO_CALL_THIS_PHONE,
                description: strings.I_CONSENT_TO_RECEIVE_CALLS_CONTAINING_PRE_RECORDED_VOICE_MESSAGES,
            };
        case constants.PreferencePhoneConsent.NO:
            return {
                label: isEmergencyPhoneSelected ? strings.EMERGENCY_CALLS_ONLY : strings.DO_NOT_CALL,
                description: isEmergencyPhoneSelected
                    ? strings.YOU_WILL_BE_CALLED_AT_THIS_NUMBER_ONLY_IN_THE_EVENT_OF_AN_EMERGENCY
                    : strings.YOU_WILL_NOT_BE_CALLED_ON_THIS_PHONE,
            };
        case constants.PreferencePhoneConsent.PENDING:
            return {
                label: strings.PENDING_CONSENT,
                description: activeSchool.settings.requireConsent
                    ? strings.THIS_PHONE_WILL_REMAIN_DISABLED_FOR_ALL_NON_EMERGENCY_CALLS_UNTIL_YOU_PROVIDE_YOUR_CONSENT_TO_RECEIVE_PRE_RECORDED_MESSAGES
                    : strings.YOUR_PHONE_NUMBER_IS_CURRENTLY_PENDING_PLEASE_PROVIDE_OR_REVOKE_CONSENT_YOUR_PREFERENCES_WILL_NOT_BE_AFFECTED,
            };
        case constants.PreferencePhoneConsent.NO_REGISTRY:
            return {
                label: strings.SORRY_YOUR_PHONE_CONSENT_STATUS_IS_UNAVAILABLE_AT_THIS_TIME_PLEASE_CHECK_AGAIN_LATER,
                description:
                    strings.EMERGENCY_CALLS_MAY_BE_PLACED_TO_THIS_PHONE_AT_ANY_TIME_BUT_NON_EMERGENCY_CALLS_WILL_ONLY_BE_PLACED_IF_YOU_HAVE_GIVEN_CONSENT,
            };
        case constants.PreferencePhoneConsent.SMS_ONLY:
            return {
                label: strings.THIS_NUMBER_WILL_ONLY_RECEIVE_TEXT_MESSAGES,
                description: strings.PLEASE_CONTACT_YOUR_SCHOOL_ADMINISTRATOR_IF_YOU_WOULD_LIKE_TO_RECEIVE_CALLS_ON_THIS_NUMBER,
            };
        default:
            return {
                label: strings.SORRY_YOUR_PHONE_CONSENT_STATUS_IS_UNAVAILABLE_AT_THIS_TIME,
                description:
                    strings.YOU_WONT_BE_ABLE_TO_CHANGE_YOUR_CONSENT_STATUS_OR_UPDATE_YOUR_MESSAGE_PREFERENCES_TO_CALL_THIS_PHONE_UNTIL_YOUR_CONSENT_STATUS_BECOMES_AVAILABLE_AGAIN,
            };
    }
};

export const isCallMeDisabled = (activeSchool, notification, phone) => {
    if (isEmpty(activeSchool?.settings?.requireConsent) || isEmpty(notification?.priority) || isEmpty(phone?.registry?.consent)) {
        return true;
    }
    return (
        notification.priority !== 1 &&
        (phone.registry.consent === constants.NO || (activeSchool.settings.requireConsent && phone.registry.consent === constants.PENDING))
    );
};

export const allowSMS = (activeSchool, phoneNumberString) => {
    if (!activeSchool) {
        return false;
    }

    const maxSms = parseInt(activeSchool.settings.maxSms, 10);
    const currentSmsCount = activeSchool.destinationPreferences.smses.length;
    const smsEnabled = maxSms > 0;
    const hasAvailableSmsSlots = currentSmsCount < maxSms;

    const smsAlreadyEnabled = activeSchool?.destinationPreferences.smses.some(val => val.sms === phoneNumberString);

    const smsCanBeEnabled = activeSchool?.settings.canAddDeleteManualDestinations && hasAvailableSmsSlots;

    return !(!smsEnabled || (!smsAlreadyEnabled && !smsCanBeEnabled));
};

export const isPhoneSmsOnly = (activeSchool, phoneNumberString) => {
    const isSms = !!activeSchool?.destinationPreferences.smses.find(val => val.sms === phoneNumberString);
    const isPhone = !!activeSchool?.destinationPreferences.phones.find(val => val.phone === phoneNumberString);
    return isSms && !isPhone;
};

export const showPhone = (type, activeSchool, phoneNumberString) => {
    return type === constants.PHONE && !isPhoneSmsOnly(activeSchool, phoneNumberString);
};

export const showSMS = (type, activeSchool, phoneNumberString, notification) => {
    return (
        (type === constants.PHONE || type === constants.SMS) && allowSMS(activeSchool, phoneNumberString) && notification.type !== constants.SURVEY
    );
};

export const getConsentStatusLabel = (phone, selected, strings) => {
    const status = phone?.registry?.consent;

    if (!status) {
        return strings.PHONE_NUMBER_STATUS_UNAVAILABLE;
    }

    switch (status) {
        case constants.YES:
            return strings.OK_TO_CALL;
        case constants.NO:
            return selected ? strings.EMERGENCY_CALLS_ONLY : strings.DO_NOT_CALL;
        case constants.PENDING:
            return strings.PENDING_CONSENT_TO_CALL;
        default:
            return strings.UNKNOWN;
    }
};

export const getPhoneStatusEmergency = (haveEmergency, activeSchool, phone) => {
    if (isEmpty(haveEmergency) || isEmpty(activeSchool?.settings?.requireConsent) || isEmpty(phone?.registry?.consent)) {
        return false;
    }

    const status = phone.registry.consent;

    // eslint-disable-next-line max-len, no-mixed-operators
    if ((haveEmergency && status === constants.YES) || (activeSchool.settings.requireConsent && status === constants.PENDING)) {
        return true;
    }

    return false;
};

export const sleep = ms =>
    new Promise(res => {
        setTimeout(res, ms);
    });

export const getEmailString = userEmail => {
    if (!userEmail) {
        return '';
    }
    const index = userEmail.indexOf('@');
    const firstChar = userEmail.charAt(0);
    const lastChar = userEmail.charAt(index - 1);
    const domain = userEmail.substr(index, userEmail.length);
    return `${firstChar}********${lastChar}${domain}`;
};

export const handleEnterKeyPress = callBack => {
    return e => {
        if (e.key === 'Enter') {
            callBack(e);
        }
    };
};
