import theme from '../../config/style/muiTheme';

const styles = {
    container: {
        marginBottom: '40px',
        backgroundColor: theme.palette.common.gray,
        mt: 1,
    },
    title_container: {
        display: 'inline-flex',
        gap: '10px',
    },
    icon_container: {
        marginTop: '36px',
    },
    icon_title: {
        fontSize: '3.5rem',
        color: '#33366A',
        marginTop: '37px',
    },
    messages_container: {
        marginTop: '20px',
        border: '1px solid #E5EFFA',
        borderRadius: '8px',
        backgroundColor: 'white',
    },
    new_for_you: {
        marginTop: '20px',
    },
    previously_read: {
        marginTop: '60px',
    },
    new_for_you_divider_styles: {
        width: '100%',
        marginBottom: '16px',
    },
    search_bar_container: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    buttons_search_bar_container: {
        display: 'flex',
        gap: '15px',
    },
    pagination_container: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px',
        marginBottom: '20px',
    },
    pagination_inlineMode_container: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px',
        marginBottom: '160px',
    },
    filter_options_popup: {
        width: '100%',
    },
    active_filters_text: {
        whiteSpace: 'nowrap',
        fontWeight: '500',
    },
    title: {
        color: '#3D3666',
    },
    filter_button: {
        backgroundColor: '#E5EFFA',
        color: '#545BE5',
        width: {
            xs: '90px',
            sm: '110px',
            md: '120px',
            lg: '120px',
        },
    },
    filter_settings_container: {
        justifyContent: 'end',
        marginTop: '10px',
    },
    loader_container: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '60px',
    },
    loader_box: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '20px',
    },
    loader: {
        color: '#545BE5',
    },
    noMsgContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        marginTop: '2rem',
        marginBottom: '2rem',
        backgroundColor: theme.palette.background.paper,
        paddingBottom: 3,
        boxShadow: theme.shadows[1],
    },
    noMsgIcon: {
        color: theme.palette.text.disabled,
        fontSize: '3.8rem',
    },
    noMsgTitle: {
        color: theme.palette.text.disabled,
        fontSize: 16,
        fontFamily: 'Inter',
        lineHeight: '50px',
    },
    inBoxBackgroundColor: '#F2F7FA',
    no_data_title: { fontWeight: '700', fontSize: '24px', lineHeight: '25.44px', textAlign: 'center', color: '#585988' },
    no_data_subtitle: {
        fontWeight: '400',
        fontSize: '18px',
        lineHeight: '25.2px',
        textAlign: 'center',
        color: '#585988',
        marginTop: '20px',
    },
    no_data_message_container: { display: 'flex', justifyContent: 'center', marginTop: '60px', flexDirection: 'column' },
};

export default styles;
