import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';

import styles from './styles';

export default function Loader({ visible }) {
    return (
        <Dialog
            open={visible}
            sx={styles.loader_container_styles}
            PaperProps={{ sx: styles.custom_styles }}
            fullScreen
            fullWidth
            hideBackdrop
        >
            <Box sx={styles.loader}>
                <CircularProgress />
            </Box>
        </Dialog>
    );
}
