/* eslint-disable no-unused-vars */
import { SxProps, Theme } from '@mui/system';
import theme from '../../config/style/muiTheme';

/** @type {Record<string, SxProps<Theme>>} */
const styles = {
    popup_header: {
        display: 'flex',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        height: '60px',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cancel_button: {
        ':hover': {
            backgroundColor: theme.palette.error.main,
            color: 'white',
        },
    },
    save_button: {
        color: '#545BE5',
        ':hover': {
            backgroundColor: '#E5EFFA',
        },
    },
    buttons_container: { display: 'flex', gap: '20px', justifyContent: 'center', marginTop: '20px' },
    field_container: { display: 'flex', gap: '40px', marginTop: '20px', alignItems: 'center', marginBottom: '10px' },
    text: {
        padding: '30px',
        textAlign: 'center',
    },
};
export default styles;
