/* eslint-disable prettier/prettier */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Typography, CircularProgress, TextField, Link } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { AddPhoneNumberModes, ContactDestinationTypes, PhoneNumberVerificationMethods } from 'comm-recipientapp-shared/src/utils/constants';
import { useStrings } from 'comm-recipientapp-shared/src/utils/useStrings';
import { isUSPhoneNumberValid } from 'comm-recipientapp-shared/src/utils/preferencesUtils';
import { sendCodeForVerififcation, verifyCode } from 'comm-recipientapp-shared/src/api/preferences';
import { env } from 'comm-recipientapp-shared';
import AddNumber from './AddNumber';
import NumberAlreadyExistError from '../Errors/NumberAlreadyExist';
import MaxContactInfoReachedError from '../Errors/MaxContactInfoReachedError';
import Button from '../../../Shared/Button';
import VerifyNumber from './VerifyNumber';
import styles from './styles';

const US_AND_CA_PHONE_LENGTH = 10;

const isPhoneVerificationEnabled = env.IS_PHONE_VERIFICATION_ENABLED === 'true';

/** @type {Map<number, string>} */
const errorsMap = new Map([
    [400, 'Wrong phone number or verification code.'],
    [403, 'We are sorry. Your school district does not use SMS. Please select phone verification instead.'],
    [429, ' You have attempted to verify too many times. Please try again in an hour.'],
    [500, 'Oops! Something went wrong. Please try again later.'],
]);

const MAX_ERROR_DURATION = 5000;

/**
 * @typedef {object} AddPhoneProps
 * @property {() => void} handleClose
 * @property {(type: string, newData: string) => Promise<any>} handleAddNumber
 * @property {string} mode
 * @property {string | null} numberToVerify
 * @property {(mode: string) => void} handleModeChange
 * @property {(phone: string) => void} openSmsOptionsPopup
 * @property {() => void} refetchData
 * @property {number} customerId
 * @param {React.PropsWithChildren<AddPhoneProps>} props
 */
export default function AddPhone({
    handleClose,
    handleAddNumber,
    mode = AddPhoneNumberModes.ADD,
    numberToVerify = null,
    handleModeChange = () => {},
    customerId = 0,
    openSmsOptionsPopup = () => {},
    refetchData = () => {},
}) {
    const {
        PREFERENCES_ADD_NUMBER_BANNER_TITLE,
        PREFERENCES_ADD_NUMBER_SAVE_BUTTON,
        PREFERENCES_ADD_NUMBER_CANCEL_BUTTON,
        LOADING,
        PREFERENCES_VERIFY_NUMBER_BANNER_TITLE,
        PREFERENCES_VERIFY_NUMBER_SEND_CODE_BUTTON,
    } = useStrings();

    const [phone, setPhone] = useState('');

    const strings = useStrings();

    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [startLoading, setStartLoading] = useState(false);

    const [currentCode, setCurrentCode] = useState('');

    const [verificationMethod, setVerificationMethod] = useState(PhoneNumberVerificationMethods.SMS);

    const maskedPhoneNumberFormRef = useRef();

    const isNumberComplete = useMemo(() => phone.length === US_AND_CA_PHONE_LENGTH && isUSPhoneNumberValid(phone), [phone]);

    const handlePhoneChange = newPhone => {
        setPhone(newPhone);
    };

    const cleanErrors = () => {
        setHasError(false);
        setErrorMessage('');
    };

    /** @param {string} message */
    const setError = message => {
        setHasError(true);
        setErrorMessage(message);
    };

    const handleContinue = async () => {
        if (mode === AddPhoneNumberModes.VERIFY && isPhoneVerificationEnabled) {
            try {
                await sendCodeForVerififcation(
                    numberToVerify,
                    customerId,
                    verificationMethod === PhoneNumberVerificationMethods.SMS ? 'sms' : 'call'
                );
                handleModeChange(AddPhoneNumberModes.ENTER_CODE);
                return;
            } catch (err) {
                const possibleStatusCodes = errorsMap.keys();

                const statusCode = err?.response?.status;

                const isValidStatusCode = Number.isInteger(statusCode);

                if (isValidStatusCode && Array.from(possibleStatusCodes).includes(statusCode)) {
                    setError(errorsMap.get(statusCode));
                    setTimeout(() => {
                        cleanErrors();
                    }, MAX_ERROR_DURATION);
                    return;
                }
            }
        }

        if (mode === AddPhoneNumberModes.ENTER_CODE && isPhoneVerificationEnabled) {
            try {
                await verifyCode(numberToVerify, customerId, currentCode);
                handleClose();
                openSmsOptionsPopup(numberToVerify);
                refetchData();
                return;
            } catch (err) {
                const statusCode = err?.response?.status;

                const isValidStatusCode = Number.isInteger(statusCode);

                if (isValidStatusCode) {
                    setError(errorsMap.get(statusCode));
                    setTimeout(() => {
                        cleanErrors();
                    }, MAX_ERROR_DURATION);
                    return;
                }
            }
        }

        setStartLoading(true);

        try {
            await handleAddNumber(ContactDestinationTypes.PHONE, phone);
            setStartLoading(false);
        } catch (error) {
            if (error instanceof NumberAlreadyExistError || error instanceof MaxContactInfoReachedError) {
                setHasError(true);
                setErrorMessage(error.message);
                setStartLoading(false);
                return; // avoiding close the modal in case of error adding a new phone
            }
        }

        handleClose();
    };

    useEffect(() => {
        if (phone.length > 0 && hasError) {
            setHasError(false);
            setErrorMessage('');
        }
    }, [phone]);

    const maskedNumber = useMemo(() => {
        // in case that we want just to verify a number,
        // we format it to be displayed in the following format (XXX) XXX-XXXX
        if (numberToVerify) {
            return numberToVerify.replace(/^(\d{3})(\d{3})(\d{4}).*/, '($1) $2-$3');
        }

        return null;
    }, [numberToVerify]);

    if (mode === AddPhoneNumberModes.ENTER_CODE) {
        return (
            <Box sx={{ marginBottom: '20px' }}>
                <Box sx={styles.banner}>
                    <Typography sx={styles.banner_text}>
                        {`${PREFERENCES_VERIFY_NUMBER_BANNER_TITLE} ${maskedNumber || maskedPhoneNumberFormRef.current}`}
                    </Typography>
                </Box>
                {hasError && (
                    <Box sx={styles.error_message_container}>
                        <WarningIcon sx={styles.error_message_icon} />
                        <Typography aria-live="assertive" sx={styles.error_message}>
                            {errorMessage}
                        </Typography>
                    </Box>
                )}
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px', alignItems: 'center' }}>
                    <Box sx={{ marginTop: '10px', marginBottom: '10px', textAlign: 'center' }}>
                        <Typography sx={{ fontWeight: '500' }}>{strings.ENTER_RECEIVED_CODE}</Typography>
                        <br />
                        <TextField onChange={e => setCurrentCode(e.target.value)} />
                    </Box>
                    <Box>
                        <Typography sx={{ textAlign: 'center', margin: '20px' }}>
                            {strings.IF_YOU_HAVE_NO_CODE}
                            {'. '}
                            <Link
                                onClick={() => {
                                    handleModeChange(AddPhoneNumberModes.VERIFY);
                                }}
                                textAlign="center"
                                style={{ cursor: 'pointer' }}
                            >
                                {strings.CLICK_HERE}
                            </Link>{' '}
                            {strings.TO_SEND_NEW_CODE}
                        </Typography>
                    </Box>
                    <Box sx={styles.buttons_container}>
                        <Button onClick={handleClose} variant="contained" sx={styles.cancel_button}>
                            {strings.CANCEL}
                        </Button>
                        <Box sx={styles.ButttonBox}>
                            <Button onClick={handleContinue} data-testid="add_phone_btn" variant="contained">
                                {strings.SUBMIT}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    }

    return (
        <>
            <Box sx={styles.banner}>
                <Typography sx={styles.banner_text}>
                    {mode === AddPhoneNumberModes.ADD
                        ? PREFERENCES_ADD_NUMBER_BANNER_TITLE
                        : `${strings.SEND_CODE_TO} ${maskedNumber || maskedPhoneNumberFormRef.current}`}
                </Typography>
            </Box>
            {hasError && (
                <Box sx={styles.error_message_container}>
                    <WarningIcon sx={styles.error_message_icon} />
                    <Typography aria-live="assertive" sx={styles.error_message}>
                        {errorMessage}
                    </Typography>
                </Box>
            )}
            <Box sx={styles.content_container}>
                <Box sx={styles.content}>
                    {mode === AddPhoneNumberModes.ADD && (
                        <AddNumber
                            phone={phone}
                            isNumberComplete={isNumberComplete}
                            handlePhoneChange={handlePhoneChange}
                            maskedPhoneNumberRef={maskedPhoneNumberFormRef}
                        />
                    )}

                    {mode === AddPhoneNumberModes.VERIFY && (
                        <VerifyNumber
                            handleVerificationMethodChange={_verificationMethod => {
                                setVerificationMethod(_verificationMethod);
                            }}
                            currentMethod={verificationMethod}
                        />
                    )}
                </Box>

                <Box>
                    <Box sx={styles.buttons_container}>
                        <Button onClick={handleClose} variant="contained" sx={styles.cancel_button}>
                            {PREFERENCES_ADD_NUMBER_CANCEL_BUTTON}
                        </Button>
                        <Box sx={styles.ButttonBox}>
                            <Button
                                onClick={handleContinue}
                                data-testid="add_phone_btn"
                                variant="contained"
                                disabled={mode === AddPhoneNumberModes.ADD ? startLoading || !isNumberComplete : !verificationMethod}
                                sx={styles.saveButton}
                                aria-disabled={mode === AddPhoneNumberModes.ADD ? startLoading || !isNumberComplete : !verificationMethod}
                            >
                                <Typography>
                                    {mode === AddPhoneNumberModes.ADD ? PREFERENCES_ADD_NUMBER_SAVE_BUTTON : PREFERENCES_VERIFY_NUMBER_SEND_CODE_BUTTON}
                                </Typography>
                            </Button>
                            {startLoading && (
                                <CircularProgress role="status" aria-live="assertive" aria-label={LOADING} size={24} sx={styles.CircularProgress} />
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
}
