/* eslint-disable prettier/prettier */
import React from "react";
import {
    Button,
    Popover,
    Box,
    CircularProgress,
} from "@mui/material";
import { utils, hooks } from 'comm-recipientapp-shared';
import{leaveGroup} from 'comm-recipientapp-shared/src/api/chat';
import styles from "./styles";

function PopoverModal (props) {
    const {
        conversationId,
        customerid,
        personid,
        chatUser,
        popover,
        open,
        onClose,
        anchorEl,
        currentChat,
    } = props;

    const strings = utils.useStrings();

    const { getMemberOfByChatUser } = hooks.chat.useChat();
    const [disableLeaveGroupButton, setDisableLeaveGroupButton] = React.useState(false);

    const handleLeaveGroup = async e => {
        e.stopPropagation();
        setDisableLeaveGroupButton(true);
        try {
            const {status} = await leaveGroup({conversationId, customerid, personid});
            if (status === 200){
                await getMemberOfByChatUser(chatUser, currentChat, conversationId); // Reload side bar and set default chat
            }
        } catch (error) {/* empty */ }
        setDisableLeaveGroupButton(false);
        onClose(e);
    }
    return(
        <Popover
            data-popover={popover}
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}>
            <Box sx={styles.ButttonBox}>
                <Button
                    sx={styles.leaveGroup}
                    disabled={disableLeaveGroupButton}
                    onClick={handleLeaveGroup}
                >
                    {strings.LEAVE_GROUP_CHAT}
                </Button>
                {disableLeaveGroupButton && (
                <CircularProgress
                    size={24}
                    sx={styles.CircularProgress}
                />
                )}
            </Box>
        </Popover>
    )
}

export default PopoverModal;
