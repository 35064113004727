import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { utils } from 'comm-recipientapp-shared';
import confirmationImage from 'comm-recipientapp-shared/src/assets/images/confirmation.png';
import styles from '../styles';

function Feedback({ handleComplete }) {
    const strings = utils.useStrings();
    return (
        <div style={styles.textCenter}>
            <Typography sx={styles.titleLoading}>{strings.THANK_YOU}</Typography>
            <Typography sx={styles.titleDescription}>{strings.YOUR_PIN_SETTINGS_HAVE_BEEN_SAVED}.</Typography>
            <img src={confirmationImage} alt="Confirmation" />

            <div style={{ marginTop: 22 }}>
                <Button
                    color="primary"
                    data-testid="button-login"
                    sx={styles.button}
                    onClick={handleComplete}
                    variant="contained"
                >
                    {strings.CLOSE}
                </Button>
            </div>
        </div>
    );
}

export default Feedback;
