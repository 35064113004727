/* eslint-disable camelcase */
const styles = {
    cancel_button: {
        padding: "8px, 16px, 10px, 10px", 
        color: "#FFFFFF", 
        backgroundColor: "#545BE5",
        '&:hover': {
            backgroundColor: "#545BE5"
        }
    },
    delete_button: {
        padding: "8px, 16px, 10px, 10px", 
        color: "#FFFFFF", 
        backgroundColor: "#D12222",
        '&:hover': {
            backgroundColor: "#D12222"
        }
    }
};

export default styles;
