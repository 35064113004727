import React from "react";
import { useSelector } from 'react-redux';
import { Box, Typography } from "@mui/material";
import styles from './styles';

export default function TabsContent({ tabValue }) {

    const { currentMessageDetails } = useSelector(state => state.inbox);
    const { tabsOrder } = currentMessageDetails;

    return (
        <Box 
            sx={styles.tabContentManager}
            tabIndex={0}
            data-testid="contenttabs">
            {tabValue === tabsOrder.email && <Box sx={styles.tabContent}>
                <Box dangerouslySetInnerHTML={{ __html: currentMessageDetails?.emailContent?.body}} />
            </Box>
            }
            {tabValue === tabsOrder.phone && <Box sx={styles.tabContent}>
                <audio controls data-testid="audioContent" src={currentMessageDetails?.phoneContent?.combined} />
            </Box>
            }
            {tabValue === tabsOrder.sms && <Box sx={styles.tabContent}>
                <Typography data-testid="smsContent" sx={styles.textContent}>
                    {currentMessageDetails?.smsContent?.body}
                </Typography>
            </Box>
            }
        </Box>
    );
}
