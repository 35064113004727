import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { useTranslation } from 'react-i18next';
import { utils, actions } from 'comm-recipientapp-shared';
import { getAbsences } from 'comm-recipientapp-shared/src/api/absence';
import { getData, setAbsences } from 'comm-recipientapp-shared/src/store/absence/absenceSlice';
import Toolbar from './Toolbar';
import FilterBar from './FilterBar';
import ChangeViewSection from './ChangeViewSection';
import CalendarList from './CalendarList';
import EventsService from './EventsService';
import Popup from './Popup';
import PopupContent from './Popup/PopupContent';
import SyncPopup from './SyncPopup';
import Loader from './Loader';
import UnexplainedAbsences, { OPEN_UNEXPLAINED_ABSENCE_POPUP } from './UnexplainedAbsences';
import styles from './styles';
import './styles.css';

const { setCalendarPopupMode } = actions.absence;
const { Navigate, Views, AbsencePopupStates } = utils.constants;
const { getCurrentYear, getCurrentMonth } = utils.attendanceUtils;

function AttendanceCalendar() {
    const { language } = useSelector(state => state.user);
    const { mode } = useSelector(state => state.absence.calendarPopup);

    const { enabledStudents, absences, holidays } = useSelector(state => state.absence);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [currentMonth, setCurrentMonth] = useState(getCurrentMonth(language));

    const [currentView, setcurrentView] = useState(Views.MONTH);

    const [events, setEvents] = useState([]);

    const [students, setStudents] = useState([]);

    const [currentStudentSelected, setCurrentStudentSelected] = useState(null);

    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const [isSyncPopupOpen, setIsSyncPopupOpen] = useState(false);

    const [isApiDataLoading, setIsApiDataLoading] = useState(false);

    const currentPopupData = useRef({});

    /** @type {React.MutableRefObject<FullCalendar>} */
    const calendarRef = useRef();

    /** @type {React.MutableRefObject<EventsService>} */
    const eventsServiceRef = useRef(null);

    const studentAssignedColors = useRef(new Map());

    const currentYear = useMemo(() => calendarRef.current?.getApi().getDate().getFullYear() || getCurrentYear(), [currentMonth]);

    const unexplainedAbsences = useMemo(() => {
        const _unexplainedAbsences = events
            .filter(event => event.customProps?.status?.isUnexplained && event.customProps?.status?.isEditable)
            .map(event => event.customProps);

        return _unexplainedAbsences;
    }, [events]);

    const listEvents = useMemo(() => {
        if (eventsServiceRef.current !== null && currentView === Views.LIST) {
            eventsServiceRef.current.changeTranslationLanguage(language);

            if (currentStudentSelected === null) {
                return eventsServiceRef.current.getApiEventsMappedToCalendarEvents(Views.LIST);
            }

            return eventsServiceRef.current.getStudentEventsByIds(
                currentStudentSelected.personId,
                currentStudentSelected.customerId,
                currentStudentSelected.organizationId,
                Views.LIST
            );
        }

        return [];
    }, [currentView, currentStudentSelected, eventsServiceRef.current, language]);

    const popupStyles = useMemo(() => {
        const stylesByState = {
            [AbsencePopupStates.EDIT]: styles.absence_popup_edit_state,
            [AbsencePopupStates.DEFAULT]: styles.popup_custom_styles,
            [AbsencePopupStates.DELETE]: styles.absence_popup_delete_state,
        };

        return stylesByState;
    }, []);

    const getMonthFromCalendarRef = () => {
        return calendarRef.current?.getApi().getDate().toLocaleString(language, { month: 'long' });
    };

    const handleViewChange = useCallback(view => {
        switch (view) {
            case Views.MONTH:
                setcurrentView(Views.MONTH);
                break;
            case Views.LIST:
                setcurrentView(Views.LIST);
                break;
            default:
                break;
        }

        setCurrentMonth(getMonthFromCalendarRef() || getCurrentMonth(language));
    }, []);

    const handleStudentFilterChange = useCallback((personId, customerId, organizationName, organizationId) => {
        setCurrentStudentSelected({
            personId,
            customerId,
            organizationName,
            organizationId,
        });
    }, []);

    const handleResetCurrentStudentSelected = useCallback(() => {
        setCurrentStudentSelected(null);
    }, []);

    const handleNavigation = useCallback(
        action => {
            switch (action) {
                case Navigate.PREVIOUS:
                    calendarRef.current?.getApi().prev();
                    break;
                case Navigate.NEXT:
                    calendarRef.current?.getApi().next();
                    break;
                default:
                    break;
            }

            setCurrentMonth(getMonthFromCalendarRef());
        },
        [language]
    );

    const handlePopupOpenOrClose = useCallback(popupData => {
        if (popupData) {
            currentPopupData.current = popupData;
        }

        if (popupData?.status?.isUnexplained && popupData?.status?.isEditable) {
            window.dispatchEvent(new Event(OPEN_UNEXPLAINED_ABSENCE_POPUP));
            return;
        }

        setIsPopupOpen(prevState => !prevState);
        dispatch(setCalendarPopupMode(AbsencePopupStates.DEFAULT));
    }, []);

    const fetchData = async () => {
        const [absencesResponse, holidaysAndStudentsResponse] = await Promise.all([
            getAbsences(`${currentYear}-01-01`, `${currentYear}-12-31`),
            dispatch(getData()),
        ]);

        return {
            absences: absencesResponse.data.absences,
            holidays: holidaysAndStudentsResponse.holidays,
            attendanceEnabledStudents: holidaysAndStudentsResponse.attendanceEnabledStudents,
        };
    };

    const refreshData = useCallback(async () => {
        try {
            setIsApiDataLoading(true);

            const response = await fetchData();

            eventsServiceRef.current = new EventsService(response.absences, response.holidays, response.attendanceEnabledStudents, language, t);

            const _events = eventsServiceRef.current.getApiEventsMappedToCalendarEvents();

            studentAssignedColors.current = eventsServiceRef.current.studentAssignedColors;
            setEvents(_events);
            setStudents(eventsServiceRef.current.allStudents);
            handleResetCurrentStudentSelected();
            setIsApiDataLoading(false);
            dispatch(setAbsences(response.absences));
        } catch (error) {
            setIsApiDataLoading(false);
        }
    }, []);

    const startEventMapping = async () => {
        try {
            if (absences.length < 1) {
                refreshData();
            } else {
                eventsServiceRef.current = new EventsService(absences, holidays, enabledStudents, language, t);

                const _events = eventsServiceRef.current.getApiEventsMappedToCalendarEvents();

                studentAssignedColors.current = eventsServiceRef.current.studentAssignedColors;
                setEvents(_events);
                setStudents(eventsServiceRef.current.allStudents);
                handleResetCurrentStudentSelected();
                setIsApiDataLoading(false);
            }
        } catch (error) {
            setIsApiDataLoading(false);
        }
    };

    useEffect(() => {
        setCurrentMonth(getMonthFromCalendarRef());
        startEventMapping();

        window.addEventListener('refetchAbsences', refreshData);

        return () => {
            window.removeEventListener('refetchAbsences', refreshData);
        };
    }, []);

    useEffect(() => {
        if (eventsServiceRef.current !== null) {
            if (currentStudentSelected !== null) {
                const _events = eventsServiceRef.current.getStudentEventsByIds(
                    currentStudentSelected.personId,
                    currentStudentSelected.customerId,
                    currentStudentSelected.organizationId
                );

                setEvents(_events);
                return;
            }

            setEvents(eventsServiceRef.current.getApiEventsMappedToCalendarEvents());
        }
    }, [currentStudentSelected]);

    useEffect(() => {
        setCurrentMonth(getMonthFromCalendarRef());

        if (eventsServiceRef.current !== null) {
            refreshData().then(() => {
                eventsServiceRef.current.changeTranslationLanguage(language);

                if (currentStudentSelected !== null) {
                    const _events = eventsServiceRef.current.getStudentEventsByIds(
                        currentStudentSelected.personId,
                        currentStudentSelected.customerId,
                        currentStudentSelected.organizationId
                    );

                    setEvents(_events);
                    return;
                }

                setEvents(eventsServiceRef.current.getApiEventsMappedToCalendarEvents());
            });
        }
    }, [language]);

    return (
        <>
            {isApiDataLoading && (
                <Popup
                    customStyles={styles.loader_popup_styles}
                    dialogCustomStyles={styles.loader_popup_dialog_styles}
                    isOpen={isApiDataLoading}
                    hideCloseButton
                    fullScreen
                    hideBackdrop
                >
                    <Loader />
                </Popup>
            )}

            {isSyncPopupOpen && (
                <Popup isOpen={isSyncPopupOpen} customStyles={popupStyles[mode]} handleClose={() => setIsSyncPopupOpen(false)} hideCloseButton>
                    <SyncPopup handleClose={() => setIsSyncPopupOpen(false)} />
                </Popup>
            )}

            {isPopupOpen && (
                <Popup
                    handleClose={handlePopupOpenOrClose}
                    isOpen={isPopupOpen}
                    customStyles={popupStyles[mode]}
                    dialogCustomStyles={styles.dialog}
                    hideCloseButton
                >
                    <PopupContent popupData={currentPopupData.current} handleClose={handlePopupOpenOrClose} handleLocalAbsenceUpdate={refreshData} />
                </Popup>
            )}

            <FilterBar
                currentStudentSelected={currentStudentSelected}
                handleStudentFilterChange={handleStudentFilterChange}
                refetchData={async () => {
                    await refreshData();
                    setIsSyncPopupOpen(true);
                }}
                resetSelectedStudent={handleResetCurrentStudentSelected}
                studentAssignedColors={studentAssignedColors.current}
                students={students}
            />

            {unexplainedAbsences.length > 0 && (
                <UnexplainedAbsences absences={unexplainedAbsences} />
            )}

            <ChangeViewSection handleViewChange={handleViewChange} />

            {currentView === Views.MONTH && (
                <>
                    <Toolbar currentMonth={currentMonth} currentYear={currentYear} handleNavigation={handleNavigation} />
                    <FullCalendar
                        events={events}
                        headerToolbar={false}
                        locale={language}
                        plugins={[dayGridPlugin]}
                        ref={calendarRef}
                        views={['dayGridMonth']}
                        weekends={false}
                        contentHeight="auto"
                        fixedWeekCount={false}
                        eventClick={({ event }) => {
                            if (event.extendedProps.customProps.status?.isUnexplained && event.extendedProps.customProps.status?.isEditable) {
                                window.dispatchEvent(new Event(OPEN_UNEXPLAINED_ABSENCE_POPUP));
                                return;
                            }

                            handlePopupOpenOrClose(event.extendedProps.customProps);
                        }}
                    />
                </>
            )}
            {currentView === Views.LIST && <CalendarList handlePopupOpen={handlePopupOpenOrClose} events={listEvents} />}
        </>
    );
}

export default memo(AttendanceCalendar);
