import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Routes, Route, useLocation } from 'react-router-dom';
import PrivateRoute from '../../components/PrivateRoute';
import CustomRoute from '../../components/CustomRoute';
import publicRoutes from '../../config/routes/public';
import privateRoutes from '../../config/routes/private';
import customRoutes from '../../config/routes/custom';
import { setHashLocation } from 'comm-recipientapp-shared/src/store/user/userSlice';

function RoutesContainer() {
    const dispatch = useDispatch();
    const location = useLocation();
    
    const { inlineMode } = useSelector(state => state.initialize);

    useEffect(() => {
        dispatch(setHashLocation(location.pathname));
    }, [location.pathname])

    const createPublicRoutes = () => {
        return (
            publicRoutes.map((route, i) => (
                <Route {...route} key={i} />
            ))
        )
    }

    const createPrivateRoutes = () => {
        return (
            privateRoutes.map((route, i) => (
                <Route key={i} element={<PrivateRoute />}>
                    <Route {...route} />
                </Route>
            ))
        )
    };

    const createCustomRoutes = () => {
        return (
            customRoutes.map((route, i) => (
                <Route key={i} element={<CustomRoute />}>
                    <Route {...route} />
                </Route>
            ))
        )
    };

    return (
        <Routes>
            {! inlineMode && createPublicRoutes()}
            {! inlineMode && createPrivateRoutes()}
            {inlineMode && createCustomRoutes()}
        </Routes>
    );
}

export default RoutesContainer;
