import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { AppBar, Box, Link, ListItemButton, ListItemIcon, Toolbar, Typography, useMediaQuery } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import LoginIcon from '@mui/icons-material/Login';
import GoogleIcon from '@mui/icons-material/Google';
import AppleIcon from '@mui/icons-material/Apple';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import InfoIcon from '@mui/icons-material/Info';
import LanguageIcon from '@mui/icons-material/Language';
import { actions, utils, env } from 'comm-recipientapp-shared';
import { getConsistentColorFromString } from 'comm-recipientapp-shared/src/utils/avatar';
import MainLogo from 'comm-recipientapp-shared/src/assets/Logo.svg';
import mobileLogo from 'comm-recipientapp-shared/src/assets/brandMark_full_color.svg';
import { logout } from 'comm-recipientapp-shared/src/api/authApi';
import { setUserActive } from 'comm-recipientapp-shared/src/store/chat/chatSlice';
import AttendanceSvgFocused from 'comm-recipientapp-shared/src/assets/attendance_focused.svg';
import AttendanceSvg from 'comm-recipientapp-shared/src/assets/attendance.svg';
import ChatSvg from 'comm-recipientapp-shared/src/assets/chat.svg';
import ChatSvgFocused from 'comm-recipientapp-shared/src/assets/chat_focused.svg';
import InboxSvg from 'comm-recipientapp-shared/src/assets/inbox.svg';
import InboxSvgFocused from 'comm-recipientapp-shared/src/assets/inbox_focused.svg';
import PreferencesSvg from 'comm-recipientapp-shared/src/assets/preferences.svg';
import ContactsSvg from 'comm-recipientapp-shared/src/assets/contacts.svg';
import { getPersonInitials, getPersonName, getPersonFirstName, getPersonLastName } from '../Inbox/utils';
import useMenuItems from '../LeftMenu/hooks/useMenuItems';
import BadgeNavButton from './BadgeNavButton';
import ActivityRefresh from './ActivityRefresh';
import LanguagesSelector from './LanguagesSelector';
import styles from './styles';
import { SMS_OPT_OUT } from '../../config/routes/public';

function NewHeader() {
    const strings = utils.useStrings();
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const userLanguage = user.language ? user.language : user.defaultLanguage;
    const browserLanguage = window.navigator.language;
    const auth = useSelector(state => state.auth);
    const conf = useSelector(state => state.preferences);
    const absence = useSelector(state => state.absence);
    const { dependents } = useSelector(state => state.initialize);
    const { hasSafeArrivalEnabled, hasPreferencesEnabled, accountHasRecordsAssociated, hasAttendanceEnabled } = useMenuItems();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setIsOpen] = useState(Boolean(anchorEl));
    const matches = useMediaQuery('(max-width: 600px)');
    const hasChat = dependents.some(dependent => dependent.dependent.hasChat === true);
    const initials = user.account ? getPersonInitials(user.account.profile) : '';
    const fullName = user.account ? getPersonName(user.account.profile) : '';
    const firstName = user.account ? getPersonFirstName(user.account.profile) : '';
    const lastName = user.account ? getPersonLastName(user.account.profile) : '';
    const hasUnreadChat = useSelector(state => state.chat.hasUnreadChat);
    const { userActive } = useSelector(state => state.chat);

    const navigate = useNavigate();

    const messagesStore = useSelector(state => state.inbox.messages);
    const absencesStore = useSelector(state => state.absence.absences);

    const hasUnreadMessages = useMemo(() => {
        return messagesStore.some(message => !message.read);
    }, [messagesStore]);

    const { AllowedURLWithoutDependants } = utils.constants;

    const IsInClaimingEmailUrl = AllowedURLWithoutDependants.includes(pathname);

    const languagesSelectMenuItemRef = useRef(null);

    const [isLanguagesSelectOpen, setIsLanguagesSelectOpen] = useState(false);

    const hasUnexplainedAbsences = useMemo(() => {
        return Array.isArray(absencesStore) ? absencesStore.some(_absence => _absence?.status?.isUnexplained && _absence?.status?.isEditable) : false;
    }, [absencesStore]);

    const activeNavLinkStyles = path => ({ ...styles.boxItem, ...(pathname === path && styles.activeLink) });

    const handleKeyPress = event => {
        const { id } = event.target;
        const { key } = event;
        if (id && key && key === 'Enter') {
            if (id === 'login') navigate('/login');
            if (id === 'signup') navigate('/signup');
        }
    };

    const handleAvatarClick = event => {
        setAnchorEl(event.target);
        setIsOpen(true);
    };

    const handleCloseUserMenu = () => {
        setAnchorEl(null);
        setIsOpen(false);
    };

    const handleLogout = () => {
        logout().then(() => {
            dispatch(actions.user.logout());
        });
    };

    const openLanguageSelector = () => {
        handleCloseUserMenu();
        setIsLanguagesSelectOpen(true);
    };

    const handleCloseLanguageSelector = () => {
        setIsLanguagesSelectOpen(false);
    };

    const handleSelectLanguage = language => {
        dispatch(actions.user.updateLang(language));
        setIsLanguagesSelectOpen(false);
        localStorage.setItem('userLanguageChanged', 'true');
    };

    const languagesList = React.useMemo(() => {
        const { languages } = utils;
        const tempLng = [];
        Object.keys(languages).forEach(key => {
            tempLng.push(languages[key]);
        });
        return tempLng;
    }, []);

    const helpUrl = useMemo(() => {
        const language = user?.language;

        if ((language === 'fr') || (language === 'fr-CA')) {
            return env.HELP_SMAPP_FR;
        }
        else
        if (language === 'es') {
            return env.HELP_SMAPP_ES;
        }
        else
        {
            const originalUrl = env.HELP_SMAPP;
            // extract only the domain and add the new path
            const url = new URL(originalUrl);
            // new url
            url.pathname = '/help/Home-Desktop/index.htm';
            return url.toString();
        }
    }, [user?.language]);

    const findLanguage = (language, languageList) => {
        let selectLanguage = null;
        Object.keys(languageList).forEach(key => {
            if (language === languageList[key].value) {
                selectLanguage = language; // found language in supported list
            }
        });
        return selectLanguage;
    };

    const loadPrefrenceData = async () => {
        await dispatch(actions.preferences.getData());
        await dispatch(actions.preferences.setIsFetched(true));
    };

    useEffect(() => {
        const userLanguageChanged = localStorage.getItem('userLanguageChanged');

        if (!userLanguageChanged && browserLanguage !== userLanguage) {
            const { languages } = utils;
            let selectLanguage = findLanguage(browserLanguage, languages);
            if (!selectLanguage) {
                const subTexts = browserLanguage.split('-');
                if (subTexts && subTexts.length > 0) {
                    selectLanguage = findLanguage(subTexts[0], languages);
                }
            }
            if (! selectLanguage)
                selectLanguage = user.defaultLanguage;
            handleSelectLanguage(selectLanguage);
        }
    }, [userLanguage, browserLanguage]);


    useEffect(() => {
        if (conf && (conf.schools.length === 0)) {
            loadPrefrenceData();
        }
    }, [conf.schools.length]);

    return (
        <AppBar position="sticky">
            {user.account && <ActivityRefresh userIsActive={userActive} setUserIsActive={setUserActive} />}
            <Toolbar sx={styles.toolbar}>
                <Box sx={matches ? styles.mobileFlex : styles.flex}>
                    <Link aria-hidden component={NavLink} sx={styles.menuItem} to={user.account ? '/landing' : '/'}>
                        {matches ? (
                            <Box alt="SchoolMessenger" component="img" src={`${mobileLogo}`} sx={styles.logoMobileBox} />
                        ) : (
                            <Box alt="SchoolMessenger" component="img" src={`${MainLogo}`} sx={styles.logoBox} />
                        )}
                    </Link>
                </Box>
                {isLanguagesSelectOpen && (
                    <LanguagesSelector
                        language={user.language}
                        languagesList={languagesList}
                        handleClose={handleCloseLanguageSelector}
                        handleConfirm={handleSelectLanguage}
                    />
                )}
                <Box sx={user.account ? styles.boxMenu : styles.rightBoxMenu}>
                    {!user.account && (
                        <>
                            <Box id="login" tabIndex={0} sx={activeNavLinkStyles('/login')} onKeyPress={handleKeyPress}>
                                <LoginIcon aria-hidden sx={styles.menuIcon} />
                                <Link tabIndex={-1} component={NavLink} sx={styles.menuItem} to="/login">
                                    {strings.LOG_IN}
                                </Link>
                            </Box>
                            <Box id="signup" tabIndex={0} sx={activeNavLinkStyles('/signup')} onKeyPress={handleKeyPress}>
                                <PersonAddIcon aria-hidden sx={styles.menuIcon} />
                                <Link tabIndex={-1} component={NavLink} sx={styles.menuItem} to="/signup">
                                    {strings.SIGN_UP}
                                </Link>
                            </Box>
                        </>
                    )}
                    {user.account && conf?.isFetched && absence?.isFetched && !IsInClaimingEmailUrl && (
                        <Box sx={styles.menu_button_container}>
                            {accountHasRecordsAssociated && (
                                <BadgeNavButton
                                    name={strings.INBOX}
                                    showBadge={hasUnreadMessages}
                                    linkTo="/inbox"
                                    isActive={pathname == '/inbox'}
                                    icon={InboxSvg}
                                    iconFocused={InboxSvgFocused}
                                />
                            )}
                            {hasChat && (
                                <BadgeNavButton
                                    name={strings.CHAT}
                                    showBadge={hasUnreadChat}
                                    linkTo="/chat"
                                    isActive={pathname == '/chat'}
                                    icon={ChatSvg}
                                    iconFocused={ChatSvgFocused}
                                />
                            )}
                            {hasSafeArrivalEnabled && hasPreferencesEnabled && hasAttendanceEnabled && (
                                <BadgeNavButton
                                    name={strings.ATTENDANCE}
                                    showBadge={hasUnexplainedAbsences}
                                    linkTo="/attendance"
                                    isActive={pathname == '/attendance'}
                                    icon={AttendanceSvg}
                                    iconFocused={AttendanceSvgFocused}
                                />
                            )}
                        </Box>
                    )}
                </Box>
                {user.account && (
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleCloseUserMenu}
                        variant="menu"
                        PaperProps={{
                            elevation: 0,
                            sx: styles.userMenu,
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem>
                            <Box>
                                {user.account.profile && <Typography aria-label="" variant="subtitle2">{`${firstName} ${lastName}`}</Typography>}
                                <Typography aria-label="" sx={styles.userEmail} variant="caption">
                                    {auth.auth?.sub ? auth.auth.sub : user.email}
                                </Typography>
                            </Box>
                        </MenuItem>

                        {accountHasRecordsAssociated && !IsInClaimingEmailUrl && (
                            <MenuItem component={NavLink} to="/my-students">
                                <Box alt="" component="img" src={ContactsSvg} />
                                <Typography aria-label="" sx={styles.floatMenuItem}>
                                    {strings.MY_CONTACTS}
                                </Typography>
                            </MenuItem>
                        )}
                        <Divider aria-hidden />
                        {hasPreferencesEnabled && !IsInClaimingEmailUrl && (
                            <MenuItem aria-label="" component={NavLink} to="/preferences">
                                <Box alt="" component="img" src={PreferencesSvg} />
                                <Typography aria-label="" sx={styles.floatMenuItem}>
                                    {strings.PREFERENCES}
                                </Typography>
                            </MenuItem>
                        )}
                        <Divider aria-hidden />
                        <MenuItem onClick={handleLogout} aria-label="">
                            <LoginIcon sx={styles.menuIcon} />{' '}
                            <Typography aria-label="" sx={styles.floatMenuItem}>
                                {strings.LOG_OUT}
                            </Typography>
                        </MenuItem>
                        <Divider aria-hidden />
                        <MenuItem onClick={openLanguageSelector} aria-label="">
                            <LanguageIcon sx={styles.menuIcon} />{' '}
                            <Typography aria-label="" sx={styles.floatMenuItem}>
                                {strings.LANGUAGE}
                            </Typography>
                        </MenuItem>
                        <Divider aria-hidden />
                        <MenuItem sx={styles.helpMenuItems} component={NavLink} to={env.APPLE_STORE_URL} aria-label={strings.APPLE_ITUNES}>
                            <ListItemButton
                                role="link"
                                target="_blank"
                                sx={{ ...styles.removeHoverEffect, ...styles.pl0 }}
                                href={env.APPLE_STORE_URL}
                            >
                                <ListItemIcon>
                                    <AppleIcon sx={styles.AppleGoogleIcon} />
                                </ListItemIcon>
                                <Typography sx={styles.floatMenuItem}>
                                    {strings.APPLE_ITUNES}
                                </Typography>
                            </ListItemButton>
                        </MenuItem>
                        <MenuItem sx={styles.helpMenuItems} component={NavLink} to={env.GOOGLE_PLAY_URL} aria-label={strings.GOOGLE_PLAY}>
                            <ListItemButton
                                role="link"
                                target="_blank"
                                sx={{ ...styles.removeHoverEffect, ...styles.pl0 }}
                                href={env.GOOGLE_PLAY_URL}
                            >
                                <ListItemIcon>
                                    <GoogleIcon sx={styles.AppleGoogleIcon} />
                                </ListItemIcon>
                                <Typography aria-label="" sx={styles.floatMenuItem}>
                                    {strings.GOOGLE_PLAY}
                                </Typography>
                            </ListItemButton>
                        </MenuItem>
                        <Divider aria-hidden />
                        <MenuItem aria-label={strings.SMS_OPT_OUT}>
                            <ListItemButton component={NavLink} to={SMS_OPT_OUT}>
                                <Typography aria-label="" variant="caption" sx={styles.captionLeft}>
                                    {strings.SMS_OPT_OUT}
                                </Typography>
                            </ListItemButton>
                        </MenuItem>

                        {user.account && (
                            <MenuItem sx={styles.helpMenuItems} component={NavLink} to={helpUrl} aria-label={strings.HELP}>
                                <ListItemButton role="link" target="_blank" sx={styles.removeHoverEffect} href={helpUrl}>
                                    <Typography aria-label="" variant="caption" sx={styles.captionLeft}>
                                        {strings.HELP}
                                    </Typography>
                                </ListItemButton>
                            </MenuItem>
                        )}
                        <MenuItem sx={styles.helpMenuItems} component={NavLink} to={env.TERMS_OF_SERVICE_URL} aria-label={strings.TERMS_OF_SERVICE}>
                            <ListItemButton role="link" target="_blank" sx={styles.removeHoverEffect} href={env.TERMS_OF_SERVICE_URL}>
                                <Typography aria-label="" variant="caption" sx={styles.captionLeft}>
                                    {strings.TERMS_OF_SERVICE}
                                </Typography>
                            </ListItemButton>
                        </MenuItem>
                        <MenuItem sx={styles.helpMenuItems} component={NavLink} to={env.PRIVACY_POLICY_URL} aria-label={strings.PRIVACY_POLICY}>
                            <ListItemButton role="link" target="_blank" sx={styles.removeHoverEffect} href={env.PRIVACY_POLICY_URL}>
                                <Typography aria-label="" variant="caption" sx={styles.captionLeft}>
                                    {strings.PRIVACY_POLICY}
                                </Typography>
                            </ListItemButton>
                        </MenuItem>
                    </Menu>
                )}
                {!user.account && (
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleCloseUserMenu}
                        onClick={handleCloseUserMenu}
                        PaperProps={{
                            elevation: 0,
                            sx: styles.userMenu,
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        alt={strings.MY_PROFILE}
                    >
                        <MenuItem>
                            <Box>
                                <Typography aria-label="" variant="subtitle2">
                                    {strings.WELCOME}
                                </Typography>
                                <Typography aria-label="" sx={styles.userEmail} variant="caption">
                                    {strings.LOG_IN_SIGN_UP_BEFORE}
                                </Typography>
                            </Box>
                        </MenuItem>
                        <Divider aria-hidden />
                        <MenuItem component={NavLink} to="/login">
                            <LoginIcon sx={styles.menuIcon} />
                            <Typography aria-label="" sx={styles.floatMenuItem}>
                                {strings.LOG_IN}
                            </Typography>
                        </MenuItem>
                        <MenuItem component={NavLink} to="/signup">
                            <PersonAddIcon sx={styles.menuIcon} />
                            <Typography aria-label="" sx={styles.floatMenuItem}>
                                {strings.SIGN_UP}
                            </Typography>
                        </MenuItem>
                        <MenuItem component={NavLink} to="/learn">
                            <InfoIcon sx={styles.menuIcon} />
                            <Typography aria-label="" sx={styles.floatMenuItem}>
                                {strings.LEARN_MORE}
                            </Typography>
                        </MenuItem>
                        <Divider aria-hidden />
                        <MenuItem onClick={openLanguageSelector} aria-label="">
                            <LanguageIcon sx={styles.menuIcon} />{' '}
                            <Typography aria-label="" sx={styles.floatMenuItem}>
                                {strings.LANGUAGE}
                            </Typography>
                        </MenuItem>
                        <Divider aria-hidden />
                        <MenuItem sx={styles.helpMenuItems} component={NavLink} to={env.APPLE_STORE_URL} aria-label={strings.APPLE_ITUNES}>
                            <ListItemButton
                                    role="link"
                                    target="_blank"
                                    sx={{ ...styles.removeHoverEffect, ...styles.pl0 }}
                                    href={env.APPLE_STORE_URL}
                            >
                                <ListItemIcon>
                                    <AppleIcon sx={styles.AppleGoogleIcon} />
                                </ListItemIcon>
                                <Typography sx={styles.floatMenuItem}>
                                    {strings.APPLE_ITUNES}
                                </Typography>
                            </ListItemButton>
                        </MenuItem>
                        <MenuItem sx={styles.helpMenuItems} component={NavLink} to={env.GOOGLE_PLAY_URL} aria-label={strings.GOOGLE_PLAY}>
                            <ListItemButton
                                    role="link"
                                    target="_blank"
                                    sx={{ ...styles.removeHoverEffect, ...styles.pl0 }}
                                    href={env.GOOGLE_PLAY_URL}
                            >
                                <ListItemIcon>
                                    <GoogleIcon sx={styles.AppleGoogleIcon} />
                                </ListItemIcon>
                                <Typography aria-label="" sx={styles.floatMenuItem}>
                                    {strings.GOOGLE_PLAY}
                                </Typography>
                            </ListItemButton>
                        </MenuItem>
                        <Divider aria-hidden />
                        <MenuItem>
                            <ListItemButton component={NavLink} to={SMS_OPT_OUT}>
                                <Typography aria-label="" variant="caption" sx={styles.captionLeft}>
                                    {strings.SMS_OPT_OUT}
                                </Typography>
                            </ListItemButton>
                        </MenuItem>
                        {user.account && (
                            <MenuItem component={NavLink} to={helpUrl}>
                                <ListItemButton role="link" target="_blank" href={helpUrl}>
                                    <Typography aria-label="" variant="caption" sx={styles.captionLeft}>
                                        {strings.HELP}
                                    </Typography>
                                </ListItemButton>
                            </MenuItem>
                        )}
                        <MenuItem component={NavLink} to={env.TERMS_OF_SERVICE_URL}>
                            <ListItemButton role="link" target="_blank" href={env.TERMS_OF_SERVICE_URL}>
                                <Typography aria-label="" variant="caption" sx={styles.captionLeft}>
                                    {strings.TERMS_OF_SERVICE}
                                </Typography>
                            </ListItemButton>
                        </MenuItem>
                        <MenuItem component={NavLink} to={env.PRIVACY_POLICY_URL}>
                            <ListItemButton role="link" target="_blank" href={env.PRIVACY_POLICY_URL}>
                                <Typography aria-label="" variant="caption" sx={styles.captionLeft}>
                                    {strings.PRIVACY_POLICY}
                                </Typography>
                            </ListItemButton>
                        </MenuItem>
                    </Menu>
                )}
                <Box sx={user.account ? styles.flex : styles.notUserFlex}>
                    {!user.account && (
                        <Box
                            onClick={handleAvatarClick}
                            onKeyPress={handleAvatarClick}
                            sx={{ ...styles.initialsUser, backgroundColor: getConsistentColorFromString('UserNotLoggedIn') }}
                            data-testid="popupMenu"
                            tabIndex={0}
                            aria-label=""
                        >
                            <Typography aria-label="" sx={styles.initials} />
                        </Box>
                    )}
                    {user.account && (
                        <Tooltip title={`${firstName} ${lastName}`} aria-label="">
                            <Box
                                onClick={handleAvatarClick}
                                onKeyPress={handleAvatarClick}
                                sx={{ ...styles.initialsUser, backgroundColor: getConsistentColorFromString(fullName) }}
                                data-testid="popupMenu"
                                tabIndex={0}
                                aria-label=""
                            >
                                <Typography aria-label="" sx={styles.initials}>
                                    {initials}
                                </Typography>
                            </Box>
                        </Tooltip>
                    )}
                </Box>
            </Toolbar>
        </AppBar>
    );
}

export default NewHeader;
