import React, { Fragment, useState } from "react";
import { 
    Avatar, 
    Button,
    Divider,
    Grid,
    ListItem,
    Popover,
    Typography 
} from "@mui/material";
import SmsIcon from '@mui/icons-material/Sms';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import styles from "./styles";

function ContactItem (props) {

    const { 
        name, 
        school, 
        student, 
        favorite, 
        chat
    } = props;

    const [anchorElTruncate, setAnchorElTruncate] = React.useState(null);
    const [anchorElChat, setAnchorElChat] = React.useState(null);

    // Popover
    const truncatePopoverOpen = event => {
        setAnchorElTruncate(event.currentTarget);
    };

    const chatPopoverOpen = event => {
        setAnchorElChat(event.currentTarget);
    };

    const truncatePopoverClose = () => {
      setAnchorElTruncate(null);
    };

    const chatPopoverClose = () => {
        setAnchorElChat(null);
    };

    const openTruncate = Boolean(anchorElTruncate);
    const openChat = Boolean(anchorElChat);
  
    // Text truncation
    const truncate = (str, n) => {
        return (str.length > n) ? str.slice(0, n-1) + ' ...' : str;
    };



    return (
        <Fragment>
            <ListItem sx={ styles.listItem }>
                <Grid container spacing={5} sx={ styles.boxHandler }>
                    <Grid item xs={2.5} sx={ styles.flexStart }>

                            <Avatar src="/broken-image.jpg" sx={{marginRight: 2} }></Avatar>
                            <Typography>{name}</Typography>

                    </Grid>
                    <Grid item xs={3.5} sx={ styles.flexStart }>
                        <Typography variant="caption">{school}</Typography>
                    </Grid>
                    <Grid item xs={3.5} sx={ styles.flexEnd}>
                        <Typography variant="caption">{truncate(student, 30)}</Typography>
                        
                    </Grid>
                    <Grid item xs={1} sx={ styles.flexStart }>
                        <Typography 
                            variant="caption"
                            aria-owns={open ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onMouseEnter={truncatePopoverOpen}
                            onMouseLeave={truncatePopoverClose}
                            sx={{ cursor: "default" }}
                        >
                            {(student.length >= 30) ? "+ More" : null }
                        </Typography>
                        <Popover
                            id="mouse-over-popover"
                            sx={{
                                pointerEvents: 'none'
                            }}
                            open={openTruncate}
                            anchorEl={anchorElTruncate}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            onClose={truncatePopoverClose}
                            disableRestoreFocus
                        >
                            <Typography sx={{ p: 1 }}>{student}</Typography>
                        </Popover>
                    </Grid>
                    <Grid item xs={1.5} sx={ styles.flexEnd }>
                        <Button variant="outlined" role={'link'} href="/chat" startIcon={<SmsIcon />} disabled={chat === false ? true : false}>Chat</Button>
                        {
                            chat === false 
                            ? 
                                <HelpOutlineIcon sx={{ position: "absolute", right: "-38px", color: "#797E8E" }} 
                                    aria-owns={open ? 'mouse-over-popover' : undefined}
                                    aria-haspopup="true"
                                    onMouseEnter={chatPopoverOpen}
                                    onMouseLeave={chatPopoverClose} 
                                />

                            : 
                            null
                        }
                        <Popover
                            id="mouse-over-popover"
                            sx={{
                                pointerEvents: 'none',
                            }}
                            open={openChat}
                            anchorEl={anchorElChat}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            onClose={chatPopoverClose}
                            disableRestoreFocus
                        >
                            {/* TO DO: Handle translation and proper text */}
                            <Typography sx={{ p: 1}}>Unable to chat</Typography>
                        </Popover>
                        
                    </Grid>

                </Grid>
               
            </ListItem>
            <Divider  component="li" />
        </Fragment>
    )

}

export default ContactItem;
