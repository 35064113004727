import {React, useState}from 'react';
import { 
    Box, 
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Link, 
} from '@mui/material';

import { useNavigate } from 'react-router-dom'
import { utils } from 'comm-recipientapp-shared';
import { resendPowerschoolEmail } from 'comm-recipientapp-shared/src/store/auth/authSlice';
import Button from '../../Shared/Button';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import styles from './styles';

function UnconfirmedEmail({open, handleClose, user}) {
    const strings = utils.useStrings();
    const navigate = useNavigate();
    
    const [resendConfirm, setResendConfirm]= useState(null);

    const resendEmail =  async () => {
        try {
            const response = await resendPowerschoolEmail(user.account.portalUserId);
            if (!response.error) {
                setResendConfirm(true);
            }
        }
        catch (e) {
            setResendConfirm(false);
        }
    }

    const redirect = () => {
        navigate('/inbox');
    }

return(
    
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        data-testid="unconfirmed-email-dialog"
        sx={styles.dialog}
    >
        <DialogTitle id="alert-dialog-title" sx={styles.title}>
            <Box>
                {strings.UNCONFIRMED_EMAIL_ADDRESS}
            </Box>
        </DialogTitle>
        <Box sx={styles.dialog_box}>
            <DialogContent sx={styles.dialog_content}>
                <DialogContentText id="alert-dialog-description" sx={styles.alert_text}>
                    <WarningIcon sx={styles.icon}></WarningIcon>{strings.YOUR_SCHOOLMESSENGER_ACCOUNT_EMAIL_ADDRESS}{user.email}{strings.HAS_NOT_BEEN_CONFIRMED}
                </DialogContentText>
                <DialogContentText sx={styles.text}>
                    {strings.PLEASE_CHECK_YOUR_EMAIL_FOR_A_CONFIRMATION_LINK_TO_ACTIVATE_YOUR_ACCOUNT_DIDNT_RECEIVE_A_CONFIRMATION_EMAIL}
                </DialogContentText>
                    <Link sx={styles.resend_link} onClick={resendEmail}><DialogContentText sx={styles.resend_link_text}>{strings.RESEND_ACCOUNT_CONFIRMATION_EMAIL}</DialogContentText></Link>
                <DialogContentText sx={styles.text}>
                    {strings.THIS_IS_THE_EMAIL_ADDRESS_YOUR_SCHOOL_HAS_ON_RECORD_IF_YOU_WISH_TO_USE_A_DIFFERENT_ONE_PLEASE_CONTACT_AND_ASK_THEM_TO_UPDATE_YOUR_EMAIL_ADDRESS}
                </DialogContentText>
                {(resendConfirm) ?
                    <DialogContentText sx={styles.resend_confirm_text}>
                        <CheckIcon sx={styles.icon}></CheckIcon>{strings.A_VERIFICATION_EMAIL_HAS_BEEN_SENT_TO}{user.email}
                    </DialogContentText>
                    : null
                }
                <Divider component="div" role="presentation" sx={styles.divider}>
                </Divider>
                <DialogContentText sx={styles.text}>
                    {strings.NOTE_EMAIL_VERIFICATION_IS_NOT_REQUIRED_IN_ORDER_TO_USE_SCHOOLMESSENGER_HOWEVER_YOU_MAY_SEE_INCOMPLETE_MESSAGES_AND_CONTACTS_VIEWS}
                </DialogContentText>
            </DialogContent>
        </Box>
        <DialogActions sx={styles.button_container}>
            <Button onClick={redirect} sx={styles.button}>
                {strings.OK_UPPERCASE}
            </Button>
        </DialogActions>
    </Dialog>
    )
}

export default UnconfirmedEmail;
