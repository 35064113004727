/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MessageIcon from '@mui/icons-material/Message';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import PhoneIcon from '@mui/icons-material/Phone';
import { utils } from 'comm-recipientapp-shared';
import { handleEnterKeyPress } from 'comm-recipientapp-shared/src/utils/helpers';
import styles from './styles';

function PhoneItem({ item, type, handleClick, ...props }) {
    const strings = utils.useStrings();

    const activeSchool = useSelector(state => state.preferences.activeSchool);
    const verified = utils.helpers.getPhoneStatus(activeSchool, item, strings);

    const isVerified = verified !== strings.INACTIVE_UNTIL_VERIFIED;
    const isMessageActive = utils.helpers.phoneHasMessagesActive(activeSchool, item.phone);

    const hasAddedAnySmsNumber = useMemo(() => {
        return activeSchool.destinationPreferences.smses.filter(sms => sms?.sms === item.phone).length > 0;
    }, [activeSchool]);

    const phoneformated = utils.helpers.formatPhoneNumber(item.phone);
    const smsStatus = hasAddedAnySmsNumber
        ? `${strings.AND_SMS} ${isMessageActive ? strings.ENABLED_TO_RECEIVE_SMS : strings.NOT_ENABLED_TO_RECEIVE_SMS}`
        : '';
    const ariaLabel = `${strings.PHONE}: ${phoneformated},
    ${strings.STATUS}: ${verified},
    ${strings.CONTACT_MEANS}: ${strings.CALL}
    ${smsStatus}, ${strings.PRESS_ENTER_TO_CHANGE_THESE_OPTIONS}`;
    return (
        <Card sx={isVerified ? styles.contact_card : styles.contact_card_unverified}>
            <CardContent
                sx={styles.contact_card_multiple}
                data-testid={`contact_phone_${props.index}`}
                onClick={() => handleClick(item.phone, type, isVerified)}
                onKeyDown={handleEnterKeyPress(() => handleClick(item.phone, type, isVerified))}
                tabIndex="0"
                aria-label={ariaLabel}
            >
                <div style={styles.content_phone} aria-hidden>
                    <div style={styles.content_circles} aria-hidden>
                        <div style={isVerified ? styles.contact_circle : styles.contact_circle_unverified}>
                            <PhoneIcon fontSize="small" sx={verified ? styles.circle_icon : styles.contact_circle_unverified} />
                        </div>

                        {hasAddedAnySmsNumber && (
                            <div style={isMessageActive ? styles.contact_circle : styles.contact_circle_inactive}>
                                <MessageIcon fontSize="small" sx={isMessageActive ? styles.circle_icon : styles.circle_icon_inactive} />
                            </div>
                        )}
                    </div>
                    <div style={styles.contact_info} aria-hidden>
                        <span style={styles.contact_info_title}>{phoneformated}</span>
                        <span style={styles.contact_info_subtitle}>{verified}</span>
                    </div>
                </div>
                <IconButton
                    onClick={e => {
                        e.stopPropagation();
                        props.handleOnDelete(item.phone);
                    }}
                    onKeyDown={handleEnterKeyPress(e => {
                        e.stopPropagation();
                        props.handleOnDelete(item.phone);
                    })}
                    data-testid={`contact_delete_phone_${props.index}`}
                    aria-label={strings.DELETE_PHONE}
                >
                    <CloseIcon fontSize="small" aria-hidden />
                </IconButton>
            </CardContent>
        </Card>
    );
}

export default PhoneItem;
