import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export default function useOrganizationConfig(organizationId, customerId) {
    const { organizationSettings } = useSelector(state => state.absence);

    const orgSettings = useMemo(
        () => organizationSettings.find(org => org.organizationId === organizationId && org.customerId === customerId),
        [organizationId, customerId, organizationSettings]
    );

    const allowedAbsenceTypes = useMemo(() => {
        const _allowedAbsenceTypes = [];

        orgSettings.absenceTypes.forEach(absenceType => {
            _allowedAbsenceTypes.push(absenceType.type);
        });

        return _allowedAbsenceTypes;
    }, [orgSettings]);

    const allowedAbsenceReasonCodes = useMemo(() => {
        const _allowedReasonCodes = {};

        orgSettings.absenceTypes.forEach(absenceType => {
            _allowedReasonCodes[absenceType.type] = absenceType.reasons.map(reason => reason.code);
        });

        return _allowedReasonCodes;
    }, [orgSettings]);

    const getAbsence = absenceType => {
        return orgSettings.absenceTypes.find(item => item.type === absenceType);
    }

    const getReasonText = (absenceType, reasonCode) => {
        const absence = getAbsence(absenceType);
        if (absence) {
            const reason = absence.reasons.find(item => item.code === reasonCode);
            return reason ? reason.text : '';
        }
        return '';
    }

    return {
        allowedAbsenceTypes,
        allowedAbsenceReasonCodes,
        commentsEnabled: orgSettings.commentsEnabled,
        attachmentsEnabled: orgSettings.attachmentsEnabled,
        getAbsence,
        getReasonText,
        orgSettings
    };
}
