/* eslint-disable prettier/prettier */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line prettier/prettier
import {
    ListItemButton,
    ListItemIcon,
    Avatar,
    ListItemText,
    Typography
} from "@mui/material";
import { setCurrentChat, setHasUnreadMessages, setUserActive } from 'comm-recipientapp-shared/src/store/chat/chatSlice';
import { utils } from 'comm-recipientapp-shared';
import ActivityBadge from '../../utils/ActivityBadge';
import styles from '../styles';

function SectionListItem(props) {

    const {
        conversationItem,
        setShowPanel,
        disabled,
    } = props;

    const { 
        name,
        hasUnreadMessages, 
    } = conversationItem;

    const { currentChat } = useSelector(state => state.chat);

    const { stringAvatar, getConsistentColorFromString } = utils.avatar;
    const dispatch = useDispatch();

    const handleclick = () => {
        dispatch(setHasUnreadMessages({
            conversationId: conversationItem.id,
            hasUnreadMessages: false
        }));
        dispatch(setCurrentChat({ ...conversationItem }));
        dispatch(setUserActive(true));
        setShowPanel('chat');
    };

    return (
        <ListItemButton
            aria-label={name}
            onClick={handleclick}
            disabled={disabled}
            sx={{
                ...(hasUnreadMessages ? { pl: 0 } : { pl: 2 }),
                ...(currentChat.id === conversationItem.id? styles.bgColorForSelectedConversation: {}),
            }}
        >
            {hasUnreadMessages && (
                <ActivityBadge  />
            )}
            <ListItemIcon aria-hidden >
                <Avatar aria-hidden sx={{ ...styles.sideBarListItem, bgcolor: `${getConsistentColorFromString(name)}` }} variant="rounded">
                    {stringAvatar(name).children}
                </Avatar>
            </ListItemIcon>
            <ListItemText aria-hidden primary={<Typography sx={styles.ListItemButtonTypography}>{name}</Typography>} />
        </ListItemButton>
    );
}

export default SectionListItem;
