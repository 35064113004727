import React, { useState, Fragment } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { utils } from 'comm-recipientapp-shared';
import ClassScheduleModal from '../ClassScheduleModal';
import Avatar from '../../Avatar';
import styles from './styles';

function StudentAccordion({ dependent, customers }) {
    const [isClassScheduleOpen, setIsClassScheduleOpen] = useState(false);
    const strings = utils.useStrings();
    const [expanded, setExpanded] = useState(false);
    const customer = customers.find(customer => customer.id === dependent.customerId);

    const handleClassScheduleClose = () => {
        setIsClassScheduleOpen(false);
    };

    const handleClassScheduleOpen = () => {
        setIsClassScheduleOpen(true);
    };

    return (
        <>
            <ClassScheduleModal
                open={isClassScheduleOpen}
                onClose={handleClassScheduleClose}
                studentName={dependent.firstName}
                enrollment={dependent.enrollment ? dependent.enrollment : []}
            />
            <Accordion aria-label="" data-testid='student' expanded={expanded} elevation={3} onChange={() => setExpanded(prev => !prev)}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Box aria-label="" sx={styles.header_container}>
                        <Avatar
                            aria-hidden={true}
                            sx={styles.avatar}
                            stringForColor={`${dependent.personId}${dependent.firstName}${dependent.lastName}`}
                            label={`${dependent.firstName} ${dependent.lastName}`}
                        />
                        <Box>
                            <Typography sx={styles.student}>
                                {`${dependent.firstName} ${dependent.lastName}`}
                            </Typography>
                            {expanded &&
                                dependent.organizations.map(organization => (
                                    <Typography key={organization.id} sx={styles.organizations}>
                                        {`${organization.name} | ${customer.name}`}
                                    </Typography>
                                ))}
                            {!expanded && (
                                <Fragment>
                                    <Typography sx={styles.organizations}>
                                        {dependent.organizations[0]
                                            ? `${dependent.organizations[0].name} |`
                                            : ''}{' '}
                                        {customer.name}
                                    </Typography>
                                    {dependent.organizations.length > 1 && (
                                        <Typography sx={styles.organizations}>...</Typography>
                                    )}
                                </Fragment>
                            )}
                        </Box>
                    </Box>
                </AccordionSummary>
                <AccordionDetails aria-label="" >
                    <Box>
                        {dependent.lunchBalance && (
                            <>
                                <Divider aria-hidden={true} />
                                <Box sx={styles.info_item}>
                                    <Typography sx={styles.info_item_title}>
                                        {strings.MEAL_BALANCE}
                                    </Typography>
                                    <Box sx={styles.info_item_title}>{dependent.lunchBalance}</Box>
                                </Box>
                            </>
                        )}
                        {(dependent.absenceCount || dependent.tardyCount) && (
                            <>
                                <Divider aria-hidden={true} />
                                <Box sx={styles.info_item}>
                                    <Typography sx={styles.info_item_title}>
                                        {strings.ATTENDANCE}
                                    </Typography>
                                    <Box sx={styles.info_item_abse_tar}>
                                        {dependent.absenceCount && (
                                            <Box sx={styles.attendance_value}>
                                                <Typography sx={styles.info_item_title}>
                                                    {dependent.absenceCount}
                                                </Typography>
                                                <Typography sx={styles.info_item_value}>
                                                    {strings.ABSENCE}
                                                </Typography>
                                            </Box>
                                        )}
                                        {dependent.tardyCount && (
                                            <Box sx={styles.attendance_value}>
                                                <Typography sx={styles.info_item_title}>
                                                    {dependent.tardyCount}
                                                </Typography>
                                                <Typography sx={styles.info_item_value}>
                                                    {strings.TARDY}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            </>
                        )}
                        <Divider aria-hidden={true} />
                        <Box sx={styles.info_item}>
                            <Typography sx={styles.info_item_title}>{strings.AM_BUS}</Typography>
                            <Box>
                                {dependent.amBusRoute && dependent.amBusRoute.includes('|') ? (
                                    dependent.amBusRoute.split('|').map(amBus => (
                                        <Box key={amBus}  sx={styles.info_item_value}>
                                            {amBus}
                                        </Box>
                                    ))
                                ) : (
                                    <Box sx={styles.info_item_value}>{dependent.amBusRoute}</Box>
                                )}
                            </Box>
                        </Box>
                        <Divider aria-hidden={true} />
                        <Box sx={styles.info_item}>
                            <Typography sx={styles.info_item_title}>{strings.PM_BUS}</Typography>
                            <Box sx={styles.info_item_value}>
                                {dependent.pmBusRoute && dependent.pmBusRoute.includes('|') ? (
                                    dependent.pmBusRoute.split('|').map(pmBus => (
                                        <Box key={pmBus} sx={styles.info_item_value}>
                                            {pmBus}
                                        </Box>
                                    ))
                                ) : (
                                    <Box sx={styles.info_item_value}>{dependent.pmBusRoute}</Box>
                                )}
                            </Box>
                        </Box>
                        <Box sx={styles.action}>
                            <Button
                                sx={styles.action_button}
                                variant="text"
                                onClick={handleClassScheduleOpen}
                            >
                                {strings.VIEW_CLASS_SCHEDULE}
                            </Button>
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </>
    );
}

export default StudentAccordion;
