const styles = {
    titleBox: { marginBottom: '20px', display: 'flex', justifyContent: 'center' },
    title: { color: '#33366A', fontWeight: '500', fontSize: '14px', textAlign: 'center' },
    emailsBox: { border: '1px solid lightgray', borderRadius: '5px', marginBottom: '10px', padding: '5px 12px 5px 12px' },
    emailItem: { color: 'gray', fontSize: '13.5px', letterSpacing: '1px' },
    contactList: { border: '1px solid lightgray', borderRadius: '5px' },
    contactItem: { display: 'flex', alignItems: 'center', margin: '10px' },
    dependentInitial: { color: 'white' },
    fullName: { color: '#33366A', fontSize: '15px', fontWeight: '700' },
    organization: { color: 'gray', fontSize: '13px', whiteSpace: 'nowrap' },
    divider: { marginTop: '20px', marginBottom: '20px' },
    optionsText: { color: '#33366A', fontWeight: '500', fontSize: '16px', textAlign: 'center' },
    buttonBox: { display: 'flex', justifyContent: 'center', width: '100%' },
    buttonSecundaryBox: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        gap: '10px',
        marginTop: '20px',
        padding: '0px 6.5rem 0px 6.5rem',
    },
    yesButton: { width: '100%', backgroundColor: '#545BE6', '&:hover': { backgroundColor: '#545BE6' }, color: 'white' },
    missingButton: { width: '100%', color: '#545BE6', backgroundColor: '#E5EFFA', '&:hover': { backgroundColor: '#E5EFFA' } },
    notMyContactsButton: { width: '100%', color: '#545BE6' },
};

export default styles;
