const styles = {
    loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%'
    },
    custom_styles: {
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
        opacity: '0.6',
    }
}

export default styles;
