import theme from '../../config/style/muiTheme';

const styles = {
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: 1,
        borderColor: 'rgb(239, 240, 244)',
        paddingTop: '20px',
        paddingBottom: '20px',
        paddingLeft: '24px',
        paddingRight: '24px',
    },
    headerCloseButton: {
        height: '20px',
        width: '20px',
        fontSize: '20px',
        color: '#555BE5',
        backgroundColor: '#F2F7FA',
        borderRadius: '2px',
        transform: 'scale(1.2, 1)',
        fontWeight: '100',
    },
    dialogHeader: {
        color: 'rgb(40, 40, 40)',
        margin: '0',
        padding: '0'
    },
    studentList: {
        marginTop: '10px',
    },
    title: {
        marginTop: '10px',
        display: 'block',
        marginBottom: '10px',
        color: 'rgb(51, 54, 106)'
    },
    titleLoading: {
        marginTop: '10px',
        display: 'block',
        marginBottom: '10px',
        fontSize: '18px',
    },
    titleDescription: {
        marginTop: '10px',
        display: 'block',
        marginBottom: '10px',
        fontSize: '16px',
    },
    description: {
        color: '#2C5871',
        marginBottom: '15px',
        fontWeight: 300,
    },
    content_pin: {
        paddingLeft: '10px',
        paddingRight: '10px',
    },
    content_pin_value: {
        fontSize: '16px',
        color: '#2C5871',
        fontWeight: 300,
    },
    show_pin: {
        justifyContent: 'flex-start',
        alignItems: 'center',
        display: 'flex',
    },
    yourPin: {
        color: '#2C5871',
        fontWeight: 300,
        fontSize: '16px',
    },
    input: {
        color: '#2C5871',
        fontSize: '16px',
        letterSpacing: '5px',
        outline: 0,
        padding: '5px',
        textAlign: 'center',
        width: '100%',
    },
    inputValid: '1px solid #cccccc',
    inputInValid: '1px solid red',
    contentLegend: {
        margin: '0 auto',
    },
    contentLegendValue: {
        maxWidth: 500,
        color: '#2C5871',
        fontSize: '14px',
        fontWeight: '300',
        lineHeight: '145%'
    },
    saveActions: {
        backgroundColor: 'rgb(242, 247, 250)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '10px',
        margin: '0 auto',
        padding: '10px',
        width: {
            xs: "100%",
            sm: "100%",
            md: "100%",
            lg: "100%"
        }
    },
    button: {
        backgroundColor: '#545BE5',
        color: 'white',
        borderRadius: '8px',
        '&:hover': { backgroundColor: '#545BE5' },
        whiteSpace: 'nowrap',
        width: '80px'
    },
    cancel_button: {
        width: '80px',
        color: 'gray',
        borderRadius: '8px',
        background: '#FFFFFF',
        '&:hover': { backgroundColor: theme.palette.error.main, color: 'white', border: '0' },
        whiteSpace: 'nowrap',
        border: '0'
    },
    textCenter: {
        textAlign: 'center'
    },
    modalContent: {
        maxWidth: 500,
        color: 'rgb(88, 89, 136)',
        fontSize: '14px',
        fontWeight: '300',
        lineHeight: '145%'
    },
    heightAuto: 'auto',
    heightDefault: 200
};

export default styles;
