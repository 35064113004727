import React from 'react';

export default function Settings(props) {
    return (
        <svg data-icon="settings" xmlns="http://www.w3.org/2000/svg" width={24} height={25} fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 9.875a.75.75 0 0 1 .75.75V20.75a.75.75 0 0 1-1.5 0V10.625a.75.75 0 0 1 .75-.75ZM12 3.5a.75.75 0 0 1 .75.75v2.625a.75.75 0 0 1-1.5 0V4.25A.75.75 0 0 1 12 3.5Z"
                fill="#545BE5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 7.625a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25ZM9.375 8.75a2.625 2.625 0 1 1 5.25 0 2.625 2.625 0 0 1-5.25 0ZM18.75 17.375a.75.75 0 0 1 .75.75v2.625a.75.75 0 0 1-1.5 0v-2.625a.75.75 0 0 1 .75-.75ZM18.75 3.5a.75.75 0 0 1 .75.75v10.125a.75.75 0 0 1-1.5 0V4.25a.75.75 0 0 1 .75-.75Z"
                fill="#545BE5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.75 15.125a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25Zm-2.625 1.125a2.625 2.625 0 1 1 5.25 0 2.625 2.625 0 0 1-5.25 0ZM5.25 14.375a.75.75 0 0 1 .75.75v5.625a.75.75 0 0 1-1.5 0v-5.625a.75.75 0 0 1 .75-.75ZM5.25 3.5a.75.75 0 0 1 .75.75v7.125a.75.75 0 0 1-1.5 0V4.25a.75.75 0 0 1 .75-.75Z"
                fill="#545BE5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.25 12.125a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25ZM2.625 13.25a2.625 2.625 0 1 1 5.25 0 2.625 2.625 0 0 1-5.25 0Z"
                fill="#545BE5"
            />
        </svg>
    );
}
