import { isEmpty } from 'ramda';
import { isEmailFormatValid, isFieldValidLength } from './common';
import { constants } from '../utils';

export const isLoginValid = (email, password) => {
    const trimedEmail = email.trim();
    return !isEmpty(trimedEmail) && isEmailFormatValid(trimedEmail) && isFieldValid(trimedEmail, constants.MAX_EMAIL_LENGTH) && !isEmpty(password.trim())
}

export const isFieldValid = (fieldVal, maxLength) => (
    !isEmpty(fieldVal.trim()) && fieldVal.length <= maxLength
    );

export {
    isFieldValidLength
};

