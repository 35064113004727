/* eslint-disable no-bitwise */
/* eslint-disable prettier/prettier */

import SafeColor from './safeColor';

export function stringAvatar(name) {
    return {
        children: name
            .split(' ')
            .slice(0, 2)
            .map(n => (n[0] ? n[0].toUpperCase() : ''))
            .join(''),
    };
}

export const getConsistentColorFromString = string => {
    if (!string) return '#ffffff';

    if (string === 'UserNotLoggedIn') return '#8D919F';

    const safeColor = new SafeColor({
        color: [255, 255, 255],
        contrast: 5.5,
    })

    return safeColor.random(string);
};
