const API_BASE_URL_ENV_GO_DEVSA = process.env.REACT_APP_API_BASE_URL_ENV_GO_DEVSA;
const API_BASE_URL_ENV_US_DEVSA = process.env.REACT_APP_API_BASE_URL_ENV_US_DEVSA;
const API_BASE_URL_ENV_US_IM = process.env.REACT_APP_API_BASE_URL_ENV_US_IM;
const APPLE_STORE_URL = process.env.REACT_APP_APPLE_STORE_URL;
const FILESTACK_CDN_URL = process.env.REACT_APP_FILESTACK_CDN_URL;
const FILESTACK_IMG_PROCESSING_URL = process.env.REACT_APP_FILESTACK_IMG_PROCESSING_URL;
const { GIT_BRANCH } = process.env;
const GOOGLE_PLAY_URL = process.env.REACT_APP_GOOGLE_PLAY_URL;
const HELP_SMAPP = process.env.REACT_APP_HELP_SMAPP;
const HELP_SMAPP_ES = process.env.REACT_APP_HELP_SMAPP_ES;
const HELP_SMAPP_FR = process.env.REACT_APP_HELP_SMAPP_FR;
const HELP_URL = process.env.REACT_APP_HELP_URL;
const IS_MOBILE = process.env.REACT_APP_IS_MOBILE;
const PRIVACY_POLICY_URL = process.env.REACT_APP_PRIVACY_POLICY_URL;
const ROCKET_CHAT_IFRAME_URL = process.env.REACT_APP_ROCKET_CHAT_IFRAME_URL;
const SMAPP_VIDEO = process.env.REACT_APP_SMAPP_VIDEO;
const SUPPORT_SITE_URL = process.env.REACT_APP_SUPPORT_SITE_URL;
const TERMS_OF_SERVICE_URL = process.env.REACT_APP_TERMS_OF_SERVICE_URL;
const RECIPIENT_SERVICE_URL = process.env.REACT_APP_RECIPIENT_SERVICE_URL;
const IS_DEV = process.env.REACT_APP_ENVIRONMENT === 'development';
const NEW_RELIC_IOS_TOKEN = process.env.REACT_APP_NEW_RELIC_IOS_TOKEN;
const NEW_RELIC_ANDROID_TOKEN = process.env.REACT_APP_NEW_RELIC_ANDROID_TOKEN;

// Google Analytics
const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;

// New Relic
const WEB_NEW_RELIC_ACCOUNT_ID = process.env.REACT_APP_NEW_RELIC_ACCOUNT_ID;
const WEB_NEW_RELIC_TRUST_KEY = process.env.REACT_APP_NEW_RELIC_TRUST_KEY;
const WEB_NEW_RELIC_AGENT_ID = process.env.REACT_APP_NEW_RELIC_AGENT_ID;
const WEB_NEW_RELIC_LICENSE_KEY = process.env.REACT_APP_NEW_RELIC_LICENSE_KEY;
const WEB_NEW_RELIC_APP_ID = process.env.REACT_APP_NEW_RELIC_APP_ID;

// For Mobile to swicth endpoints
const RECIPIENT_SERVICE_URL_STAGEUS = process.env.REACT_APP_RECIPIENT_SERVICE_URL_STAGEUS;
const RECIPIENT_SERVICE_URL_STAGECA = process.env.REACT_APP_RECIPIENT_SERVICE_URL_STAGECA;
const RECIPIENT_SERVICE_URL_PRODUS = process.env.REACT_APP_RECIPIENT_SERVICE_URL_PRODUS;
const RECIPIENT_SERVICE_URL_PRODCA = process.env.REACT_APP_RECIPIENT_SERVICE_URL_PRODCA;
const RECIPIENT_SERVICE_URL_DEVSA = process.env.REACT_APP_RECIPIENT_SERVICE_URL_DEVSA;

const ROCKET_CHAT_IFRAME_URL_DEVSA = process.env.REACT_APP_ROCKET_CHAT_IFRAME_URL_DEVSA;
const ROCKET_CHAT_IFRAME_URL_STAGEUS = process.env.REACT_APP_ROCKET_CHAT_IFRAME_URL_STAGEUS;
const ROCKET_CHAT_IFRAME_URL_STAGECA = process.env.REACT_APP_ROCKET_CHAT_IFRAME_URL_STAGECA;
const ROCKET_CHAT_IFRAME_URL_PRODUS = process.env.REACT_APP_ROCKET_CHAT_IFRAME_URL_PRODUS;
const ROCKET_CHAT_IFRAME_URL_PRODCA = process.env.REACT_APP_ROCKET_CHAT_IFRAME_URL_PRODCA;
const REACT_APP_ENVIRONMENT_KEY = process.env.REACT_APP_ENVIRONMENT_KEY;
// Mobile - Adds env var to show/hide list of endpoints screen
const IS_SHOW_ENVIRONMENTS = process.env.REACT_APP_IS_SHOW_ENVIRONMENTS;
const IS_PHONE_VERIFICATION_ENABLED = process.env.REACT_APP_IS_PHONE_VERIFICATION_ENABLED;
const IS_EMAIL_VERIFICATION_ENABLED = process.env.REACT_APP_IS_EMAIL_VERIFICATION_ENABLED;

const env = {
    API_BASE_URL_ENV_GO_DEVSA,
    API_BASE_URL_ENV_US_DEVSA,
    API_BASE_URL_ENV_US_IM,
    APPLE_STORE_URL,
    FILESTACK_CDN_URL,
    FILESTACK_IMG_PROCESSING_URL,
    GIT_BRANCH,
    GOOGLE_PLAY_URL,
    HELP_SMAPP,
    HELP_SMAPP_ES,
    HELP_SMAPP_FR,
    HELP_URL,
    IS_MOBILE,
    IS_SHOW_ENVIRONMENTS,
    PRIVACY_POLICY_URL,
    ROCKET_CHAT_IFRAME_URL,
    SMAPP_VIDEO,
    SUPPORT_SITE_URL,
    TERMS_OF_SERVICE_URL,
    RECIPIENT_SERVICE_URL,
    IS_DEV,
    NEW_RELIC_IOS_TOKEN,
    NEW_RELIC_ANDROID_TOKEN,
    RECIPIENT_SERVICE_URL_DEVSA,
    RECIPIENT_SERVICE_URL_STAGEUS,
    RECIPIENT_SERVICE_URL_STAGECA,
    RECIPIENT_SERVICE_URL_PRODUS,
    RECIPIENT_SERVICE_URL_PRODCA,
    ROCKET_CHAT_IFRAME_URL_DEVSA,
    ROCKET_CHAT_IFRAME_URL_STAGEUS,
    ROCKET_CHAT_IFRAME_URL_STAGECA,
    ROCKET_CHAT_IFRAME_URL_PRODUS,
    ROCKET_CHAT_IFRAME_URL_PRODCA,
    REACT_APP_ENVIRONMENT_KEY,
    GA_MEASUREMENT_ID,
    WEB_NEW_RELIC_ACCOUNT_ID,
    WEB_NEW_RELIC_TRUST_KEY,
    WEB_NEW_RELIC_AGENT_ID,
    WEB_NEW_RELIC_LICENSE_KEY,
    WEB_NEW_RELIC_APP_ID,
    IS_PHONE_VERIFICATION_ENABLED,
    IS_EMAIL_VERIFICATION_ENABLED,
};

export default env;
