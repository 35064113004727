import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { utils } from 'comm-recipientapp-shared';
import styles from './styles';

const { stringAvatar } = utils.avatar;

export default function SchoolItem({ school }) {
    const { getConsistentColorFromString } = utils.avatar;
    const backgroundColor = useMemo(() => {
        return getConsistentColorFromString(`${school.customerId}${school.name}`);
    }, [school]);

    return (
        <Box
            sx={{
                ...styles.initials_container,
                backgroundColor,
            }}
        >
            <Typography sx={styles.initials_text}>{stringAvatar(school.name).children}</Typography>
        </Box>
    );
}
