import Moment from 'moment';

export const absenceTypesMapping = {
    fullDay: 'FULL_DAY',
    late: 'LATE',
    earlyDeparture: 'EARLY_DEPARTURE',
    partialDay: 'PARTIAL_DAY',
    multiDay: 'MULTI_DAY',
};

export const absenceTypes = {
    FULL_DAY: 'fullDay',
    LATE: 'late',
    EARLY_DEPARTURE: 'earlyDeparture',
    PARTIAL_DAY: 'partialDay',
    MULTI_DAY: 'multiDay',
};

export const absenceDates = {
    SINGLE_DATE: 'singleDate',
    START_DATE: 'startDate',
    END_DATE: 'endDate',
    IN_TIME: 'inTime',
    OUT_TIME: 'outTime',
};

export const getAbsenceDates = (dates, isEdit, isUnexplained, type) => {
    if (!isEdit && !isUnexplained) return {};
    if (type === absenceTypes.MULTI_DAY) {
        const lastDate = dates.length && dates.length > 1 ? dates.length - 1 : null;

        return {
            startDate: dates[0] ? dates[0].date : null,
            endDate: lastDate ? dates[lastDate].date : null,
        };
    }
    const initialDate = { singleDate: dates[0].date };
    if (type === absenceTypes.FULL_DAY) {
        return initialDate;
    }
    if (type === absenceTypes.LATE) {
        return { ...initialDate, inTime: dates[0].inTime ? `01-01-1970 ${dates[0].inTime}:00` : null };
    }
    if (type === absenceTypes.EARLY_DEPARTURE) {
        return { ...initialDate, outTime: dates[0].outTime ? `01-01-1970 ${dates[0].outTime}:00` : null };
    }
    if (type === absenceTypes.PARTIAL_DAY) {
        return {
            ...initialDate,
            outTime: dates[0].outTime ? `01-01-1970 ${dates[0].outTime}:00` : null,
            inTime: dates[0].inTime ? `01-01-1970 ${dates[0].inTime}:00` : null,
        };
    }
    return {};
};

/**
 * Returns all dates in between the start and end dates, including start and end.
 *
 * @param {string} start
 * @param {string} end
 * @returns {{ date: string }[]}
 */
const getDatesInBetween = (start, end) => {
    const startDate = Moment(start).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
    });
    const endDate = Moment(end).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
    });

    const now = startDate.clone();
    const dates = [];

    while (now.isSameOrBefore(endDate)) {
        dates.push(now.format('YYYY-MM-DD'));
        now.add(1, 'days');
    }

    return dates.map(date => ({ date }));
};

/**
 * @param {Object} dates
 * @param {string} type
 * @param {function} formatTime
 * @param {function} [formatDate]
 * @returns {Object}
 */

export const absenceDatesMapper = (dates, type, formatTime, formatDate) => {
    if (type === absenceTypes.MULTI_DAY) {
        return getDatesInBetween(dates.startDate, dates.endDate);
    }
    const initialDate = [
        {
            date: formatDate ? formatDate(dates.singleDate) : dates.singleDate,
            inTime: null,
            outTime: null,
        },
    ];

    if (type === absenceTypes.LATE) {
        initialDate[0].inTime = formatTime(dates.inTime);
    }
    if (type === absenceTypes.EARLY_DEPARTURE) {
        initialDate[0].outTime = formatTime(dates.outTime);
    }
    if (type === absenceTypes.PARTIAL_DAY) {
        initialDate[0].inTime = formatTime(dates.inTime);
        initialDate[0].outTime = formatTime(dates.outTime);
    }
    return initialDate;
};

export const holidayTypes = {
    EXAM: 'EXAM',
    HOLIDAY: 'HOLIDAY',
};
