import React from 'react';

export default function SecondaryCornet(props) {
    return (
        <svg data-icon="secondaryCornet" xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
            <path fill="#fff" d="M13 15h4v4h-4z" />
            <path
                d="M3 4.753v12.994a.75.75 0 0 0 .956.722l16.5-4.81a.75.75 0 0 0 .544-.722V9.563a.75.75 0 0 0-.544-.721l-16.5-4.81A.75.75 0 0 0 3 4.753Z"
                fill="#A1A4F1"
                stroke="#33366A"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.25 14.597V18a.75.75 0 0 1-.75.75h-3a.75.75 0 0 1-.75-.75V6.59"
                stroke="#33366A"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
