import theme from '../../../../config/style/muiTheme';

const styles = {
    banner: {
        backgroundColor: theme.palette.primary.main,
        maxHeight: '80px',
        height: '100%',
    },
    banner_text: {
        fontSize: '1.3rem',
        color: 'white',
        fontWeight: '300',
        lineHeight: '1.4',
        textAlign: 'center',
        paddingTop: '20px',
        paddingBottom: '20px',
    },
    button: {
        margin: '0 5px',
        fontWeight: '300',
        lineHeight: '5px',
        borderRadius: '3px',
        backgroundColor: theme.palette.primary.main,
        height: '40px',
        border: `1px solid ${theme.palette.primary.main}`,
        color: 'white',
        '&:disabled': {
            border: '1px solid #E0E0E0',
        },
        boxShadow: 'none',
        textAlign: 'center',
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
    },
    button_container: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '20px',
    },
    text_container: {
        marginTop: '20px',
        marginBottom: '20px',
        paddingLeft: '30px',
        paddingRight: '30px',
    },
    message: {
        textAlign: 'center',
    },
};

export default styles;
