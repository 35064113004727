/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { IMaskMixin } from 'react-imask';
import { styled } from '@mui/material';
import theme from '../../../config/style/muiTheme';

const styles = {
    button: {
        margin: '0 5px',
        fontWeight: '300',
        lineHeight: '5px',
        borderRadius: '3px',
        backgroundColor: theme.palette.primary.main,
        height: '40px',
        border: `1px solid ${theme.palette.primary.main}`,
        color: 'white',
        '&:disabled': {
            border: '1px solid #E0E0E0',
        },
        boxShadow: 'none',
        textAlign: 'center',
        fontSize: '1rem',
    },
    cancel_button: {
        color: theme.palette.primary.main,
        backgroundColor: 'white',
        border: `1px solid ${theme.palette.primary.main}`,
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    buttons_container: {
        display: 'flex',
        justifyContent: 'center',
        gap: '20px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    banner: {
        backgroundColor: theme.palette.primary.main,
        minHeight: '99px',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: '0px 10px'
    },
    banner_text: {
        fontSize: '1.1rem',
        color: 'white',
        fontWeight: '300',
        lineHeight: '1.4',
        paddingTop: '20px',
        paddingBottom: '20px',
    },
    input: {
        width: '100%',
        height: '22px',
        maxWidth: '400px',
        border: 'none',
        boxShadow: 'none',
        padding: '0.5rem',
        textAlign: 'center',
        letterSpacing: '15px',
        fontWeight: '100',
        '&:focus': {
            boxShadow: 'none',
            backgroundColor: 'white',
            outline: 'none',
        },
    },
    input_container: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    description: {
        fontWeight: '100',
        lineHeight: '25px',
        fontSize: '16px',
    },
    description_container: {
        paddingLeft: '70px',
        paddingRight: '70px',
        marginTop: theme.spacing(4),
    },
    verification_radio_container: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
    },
    verification_radio_label: {
        fontSize: '0.875rem',
        color: '#444444',
        lineHeight: '30px',
        fontWeight: 'normal',
        textAlign: 'center',
    },
    image_and_banner_title_container: {
        display: 'flex',
        gap: '20px'
    },
    banner_image: {
        height: '40px',
        width: '40px'
    }
};

export default styles;

const StyledInput = styled('input')(styles.input);

export const MaskedStyledInput = IMaskMixin(({ inputRef, ...props }) => (
    <StyledInput ref={inputRef} {...props} />
));
