const styles = {
    listItemContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    contactListItem: {
        borderRadius: '10px',
    },
    ListItemButtonTypography: {
        fontWeight: '500',
        fontSize: '16px',
        color: 'purple',
    },
};

export default styles;
