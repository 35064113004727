import React from "react";
import CircularProgress from '@mui/material/CircularProgress';

import Layout from "../../Layout";
import styles from "./styles";

function FullScreenLoader ({hideHeader = false}) {
    return(
        <Layout hideHeader={hideHeader}>
            <div style={styles.content}>
                <CircularProgress style={styles.auto} />
            </div>
        </Layout>
    )
}

export default FullScreenLoader;
