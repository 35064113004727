import { borderTop, display, height } from '@mui/system';
import theme from '../../config/style/muiTheme';

const styles = {
    container: { display: 'flex', padding: '50px' },
    container_mobile: { padding: '50px' },
    text: {
        fontWeight: '200',
    },
    subtext: {
        fontWeight: '200',
        fontStyle: 'italic',
        display: 'inline',
    },
    main_container: {
        margin: '50px auto',
        maxWidth: '1500px',
        background: '#fff',
        border: '1px solid #ccc',
        boxShadow: '0 2px 3px rgb(0 0 0 / 15%)',
    },
    buttonContent: {
        margin: '0 auto',
        width: '100%',
        textAlign: 'center',
    },
    section: {
        margin: '20px 0',
        whiteSpace: 'pre-wrap',
    },
    divider: {
        margin: '8px 0 32px',
    },
    title: {
        fontSize: '20px',
        fontWeight: '500',
        lineHeight: '28px',
        color: theme.palette.primary.dark,
        margin: '0px',
        marginBottom: '10px',
    },
    form_title: {
        fontSize: '20px',
        fontWeight: '500',
        lineHeight: '28px',
        color: 'white',
        margin: '0px',
        marginBottom: '10px',
        textAlign: 'center',
    },
    bold: {
        fontWeight: '500',
    },
    link: {
        cursor: 'pointer',
        textDecoration: 'none',
        fontWeight: '200',
        fontStyle: 'italic',
    },
    disclaimer_text: {
        fontSize: '14px',
        fontWeight: '200',
        fontStyle: 'italic',
    },
    input_label: {
        marginBottom: '7px',
        fontSize: '16px',
        fontWeight: '300',
        color: 'white',
    },
    input_section: {
        width: '100%',
        maxWidth: '400px',
        marginBottom: '20px',
    },
    input_field: {
        height: '40px',
    },
    column1: {
        maxWidth: '1100px',
    },
    column2: {
        maxWidth: '350px',
        marginLeft: '50px',
    },
    column2_mobile: {
        maxWidth: '350px',
    },
    form_container: {
        backgroundColor: '#1d6aa9',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    banner: {
        backgroundColor: theme.palette.primary.main,
        maxHeight: '80px',
        height: '100%',
    },
    banner_text: {
        fontSize: '1.3rem',
        color: 'white',
        fontWeight: '300',
        lineHeight: '1.4',
        textAlign: 'center',
        paddingTop: '20px',
        paddingBottom: '20px',
    },
    modal_styles: {
        width: '100%',
        maxWidth: '640px',
    },
    buttons_container: {
        display: 'flex',
        justifyContent: 'center',
        gap: '15px',
        margin: '20px 0',
    },
    cancel_button: {
        color: theme.palette.primary.main,
        backgroundColor: 'white',
        border: `1px solid ${theme.palette.primary.main}`,
    },
    buttton_box: { position: 'relative' },
    save_button: { '&:disabled': { color: theme.palette.grey['700'] } },
    pop_up_text_container: {
        margin: '20px 0 0 20px',
    },
    success_text: { fontSize: '20px', fontWeight: '400' },
    success_button: { alignSelf: 'center', marginBottom: '20px' },
    unsubscribed_contianer: { alignSelf: 'center', margin: '20px' },
    circular_progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-12px',
        marginLeft: '-12px',
        color: 'white',
    },
    error: {
        color: theme.palette.error.light,
    },
    actions_box: {
        display: 'flex',
        flexDirection: 'column',
        height: '250px',
        borderTop: '1px solid #ccc',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
};

export default styles;
