import theme from "../../../../config/style/muiTheme";

const styles = {
    content_title: {
        paddingBottom: 10,
        borderBottom: '1px solid #ddd',
        marginTop: 20,
        marginBottom: 15
    },
    title: {
        color: '#2C5871',
        fontWeight: 300,
        fontSize: 22
    },
    card: {
        background: '#fff',
        display: 'flex',
        flexDirection: 'row',
        height: 100,
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    card_info: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        alignItems: 'center',
    },
    card_information: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '15px',
        borderLeft: '1px solid #eee',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'flex-start',
        marginLeft: '20px',
        color: '#2C5871',
        
    },
    card_information_list: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '7px'
    },
    ok_call: {
        paddingRight: '12px',
        fontSize: '14px'
    },
    info_bellow: {
        margintop: '15px',
        display: 'block',
    },
    contsent: {
        fontSize: '14px',
    },
    spacer: {
        color: '#999'
    },
    card_button: {
        marginLeft: '5px',
        position: 'relative',
        top: '3px',
        padding: 0
    },
    card_status: {
        background: '#43AC6A',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100px',
        height: '100%'
    },
    card_circle: {
        width: 42,
        height: 42,
        borderRadius: 21,
        border: '1px #fff solid',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    icon_color: {
        color: '#fff'
    },
    value: {
        paddingLeft: '10px',
        fontSize: '18px',
        color: '#2C5871',
        fontWeight: 300,
    },
    content: {
        marginTop: '30px'
    },
    titles: {
        fontWeight: 300,
    },
    box_list: {
        marginTop: '25px'
    },
    message_card: {
        borderRadius: '0px',
        boxShadow: '0 1px 4px rgb(0 0 0 / 10%)',
        position: 'relative',
        width: '100%'
    },
    message_card_bg: {
        alignItems: 'center',
        backgroundColor: 'primary.main',
        display: 'flex',
        height: '99px',
        justifyContent: 'center',
        position: 'relative',
    },
    message_card_title: {
        color: '#fff',
        fontSize: 20,
        fontWeight: 300,
    },
    message_card_bottom: {
        alignItems: 'center',
        backgroundColor: '#ffffff',
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '10px 15px 30px',
    },
    actions_button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '10px',
        marginTop: '20px'
    },
    btn: {
        background: 'white',
        color: theme.palette.primary.main,
    },
    survey_title: {
        color: theme.palette.blue['140'],
        fontSize: 11,
        fontWeight: 300,
    },
    ButttonBox: { position: 'relative' },
    CircularProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-12px',
        marginLeft: '-12px',
    },
};

export default styles;
