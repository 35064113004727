/* eslint-disable prettier/prettier */
import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';
import { utils } from 'comm-recipientapp-shared';
import styles from "./styles";


function ModalError({ visible, handleClose }) {
    const strings = utils.useStrings();

    return (
        <Dialog  open={visible} onClose={handleClose}>
            <DialogTitle sx={styles.dialogHeader}>
                <WarningIcon />
                <Typography sx={styles.dialogTitle}>{strings.WARNING}</Typography>
            </DialogTitle>
            <DialogContent sx={styles.modalContent}>
                <Typography sx={styles.errorLegend}>
                    {strings.YOUR_DISTRICT_REQUIRES_CONSENT_TO_ENABLE_THIS_NUMBER_YOU_CAN_CHANGE_YOUR_CONSENT_STATUS_IN_THE_CONTACT_PREFERENCE_SETTINGS}
                </Typography>
                <Button
                    color="primary"
                    data-testid="button-login"
                    fullWidth
                    sx={styles.button_ok}
                    onClick={handleClose}
                    variant="contained"
                >
                    {strings.OK}
                </Button>
            </DialogContent>
        </Dialog>
    )
}

export default ModalError;
