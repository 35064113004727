import theme from '../../../config/style/muiTheme';

const centerVertically = {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column',
};

const styles = {
    section_title: {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '150%',
        color: '#8D919F',
        marginBottom: '5px',
    },
    container: {},
    filter_container: {
        ...centerVertically,
    },
    filter_title: {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '140%',
        color: '#585988',
    },
    filter_box: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: '10px',
    },
    data_stack: {
        borderRadius: '8px',
        backgroundColor: '#F2F7FA',
    },
    divider: {
        width: '100%',
        background: '#EFF0F4',
        borderBottomWidth: '0.5px',
        opacity: '0.5',
    },
    checkbox: {
        color: '#D6DAE5',
        '&.Mui-checked': {
            color: '#545BE5',
        },
    },
    header_title_container: {
        ...centerVertically,
        marginTop: '2px',
    },
    header_title: {
        fontWeight: '700',
        fontSize: '12px',
        lineHeight: '140%',
        letterSpacing: '0.04em',
        color: '#282828',
        marginTop: '10px',
        marginLeft: '4px',
    },
    header_close_button: {
        height: '20px',
        width: '20px',
        fontSize: '16px',
        color: '#555BE5',
        backgroundColor: '#F2F7FA',
        borderRadius: '50%',
        transform: 'scale(1.2, 1)',
        fontWeight: '100',
    },
    header_container: {
        borderBottom: '2px solid #EFF0F4',
    },
    header_content_container: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 15px 10px 15px',
    },
    filter_button_container: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 15px 10px 15px',
        backgroundColor: '#F2F7FA',
        marginTop: '10px',
        bottom: '0',
    },
    filter_box_spacing: {
        padding: '10px 20px 10px 20px',
    },
    filter_button: {
        backgroundColor: '#545BE5',
        color: 'white',
        borderRadius: '8px',
        '&:hover': { backgroundColor: '#545BE5' },
        whiteSpace: 'nowrap',
        width: '200px',
    },
    filter_button_disabled: {
        backgroundColor: '#ddd',
        color: 'white',
        borderRadius: '8px',
        whiteSpace: 'nowrap',
        width: '200px',
    },
    cancel_button: {
        width: '80px',
        color: 'gray',
        borderRadius: '8px',
        background: '#FFFFFF',
        '&:hover': { backgroundColor: theme.palette.error.main, color: 'white' },
        whiteSpace: 'nowrap',
    },
    filter_section_box: { display: 'flex', gap: '10px' },
    filter_content_container: {
        display: 'flex',
        gap: '5px',
        alignItems: 'center',
    },
    all_messages_filter_section: { backgroundColor: '#F2F7FA', borderRadius: '8px' },
    all_messages_icon_box: { display: 'flex', gap: '1px' },
    all_messages_icon: { marginTop: '8px', marginRight: '8px' },
    initials_container: {
        ...centerVertically,
        borderRadius: '8px',
        textAlign: 'center',
        width: '32px',
        height: '32px',
        marginRight: '2px',
        minWidth: '32px',
    },
    student_names_container: {
        ...centerVertically,
    },
    initials_text: {
        fontWeight: '500',
        fontSize: '13px',
        lineHeight: '150%',
        color: 'white',
    },
    school_initials_container: {
        ...centerVertically,
    },
    category_names_container: {
        ...centerVertically,
    },
    filter_icon_styles: { marginTop: '9px', transform: 'scale(0.9)' },
    description_title: {
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '140%',
        color: '#33366A',
    },
    description_sub_title: {
        fontSize: '14px',
        fontWeight: '300',
        lineHeight: '145%',
        color: '#585988',
    },
};

export default styles;
