/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Box,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    Stack,
    Typography
} from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';

import { hooks, utils } from 'comm-recipientapp-shared';
import Dependent from './Dependent';
import styles from './styles';

function CustomerSelect () {
    const strings = utils.useStrings();

    const {account: { chatUsers }} = useSelector(state => state.user) || [];
    const { customers } = useSelector(state => state.customers);
    const { dependents } = useSelector(state => state.initialize);
    const { changeChatUserSequence } = hooks.chat.useChat();

    const customerEntries = chatUsers.map((chatUser, index) => {
        const customer = customers.find(customer => customer.id === chatUser.customerId);

        const chatUserPerson = dependents.find(person => (
            person.dependent.personId === chatUser.personId &&
            person.dependent.customerId === chatUser.customerId
        ));

        const userDependents = chatUserPerson.dependent.dependents.filter(person => (
            person.dependent.customerId === chatUser.customerId &&
            person.dependent.hasChat
        ));
            
        return {
            rcauthtoken: chatUser.credentials.rcauthtoken,
            personId: chatUser.personId,
            customerId: customer.id,
            customerName: customer.name,
            chatUserIndex: index,
            chatUserPerson,
            userDependents,
        }
    });

    const [displayedCustomer, setDisplayedCustomer] = useState(customerEntries[0])

    const handleCustomerChange = (event) => {
        const { target: { value } } = event;
        const rcauthtoken = value;
        const customerEntry = customerEntries.find(entry => entry.rcauthtoken === rcauthtoken);
        setDisplayedCustomer(customerEntry);
        changeChatUserSequence(customerEntry);
    };

    return (
        <Box aria-label={strings.CHAT_ARIA_LABEL_CUSTOMER_SELECT}>
            <FormControl 
                aria-label={strings.CHAT_ARIA_LABEL_CUSTOMER_SELECT_CONTROLS}
                variant="standard" 
                size="small" 
                sx={styles.formControl}>
                <InputLabel 
                    tabIndex={0}
                    id="customer-select"
                    aria-label={strings.CHAT_ARIA_LABEL_CUSTOMER_SELECT_LABEL}>
                    {strings.SWITCH_DISTRICT}
                </InputLabel>
                <Select
                    value={displayedCustomer.rcauthtoken}
                    aria-labelledby='customer-select'
                    labelId="cutomer-select"
                    onChange={handleCustomerChange}
                    sx={styles.purpleText}
                    disableUnderline
                    data-testid="customer-select"
                    renderValue={() => displayedCustomer.customerName}
                >
                {customerEntries.map((entry, index) => {
                    const { firstName, lastName } = entry.chatUserPerson.dependent;
                    const chatUserFullName = `${firstName} ${lastName}`;
                    return (
                        <MenuItem
                            aria-label={entry.customerName}
                            key={index}
                            value={entry.rcauthtoken}
                            sx={{ background: "white" }}
                            data-testid="customer-select-menu-item">
                            <Stack>
                                <Box
                                    sx={styles.avatarLineItem}>
                                    <Typography noWrap sx={styles.purpleText}>
                                        {entry.customerName}
                                    </Typography>
                                </Box>
                                <Box sx={styles.avatarLineItem}>
                                    <Box sx={styles.avatarIcon}>
                                        <PersonIcon sx={styles.personIcon} />
                                    </Box>
                                    <Typography noWrap sx={{ ml: 1 }}>
                                        {chatUserFullName}
                                    </Typography>
                                </Box>
                                {entry.userDependents.map(({ dependent }, index) => {
                                    return <Dependent key={index} dependent={dependent} index={index} />
                                })}
                            </Stack>
                        </MenuItem>
                    )
                })}
                </Select>
            </FormControl>
        </Box>
    )
}
export default CustomerSelect;
