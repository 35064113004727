/* eslint-disable import/prefer-default-export */
import React, { useState, useEffect } from 'react';
import { useStrings, constants } from '../../utils';
import * as validations from '../../validations';

const { isLoginValid, isFieldValidLength } = validations.login;
const { isEmailFormatValid } = validations.common;

export const useLogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);
    const strings = useStrings();

    useEffect(() => {
        // form valid
        const valid = isLoginValid(email, password);
        setIsFormValid(valid);
        if (valid) {
            setEmailError('');
            setPasswordError('');
        }

        // watch field
        if (isFieldValidLength(email, constants.MIN_TEXT_LENGTH)) setEmailError('');
        if (isFieldValidLength(password, constants.MIN_TEXT_LENGTH)) setPasswordError('');
    }, [email, password]);

    useEffect(() => {
        // email validation
        if (isEmailFormatValid(email)) setEmailError('');

        if (!isFieldValidLength(email, constants.MAX_EMAIL_LENGTH)) {
            setEmailError(`${strings.MAX_LENGTH_IS} ${constants.MAX_EMAIL_LENGTH}`);
        }
    }, [email]);

    const handleEmailBlur = e => {
        if (email === '') {
            setEmailError(strings.EMAIL_IS_REQUIRED);
        } else if (!isEmailFormatValid(email)) {
            setEmailError(strings.ENTER_VALID_EMAIL_FORMAT);
        }
    };

    const handlePasswordBlur = e => {
        if (password === '') setPasswordError(strings.PASSWORD_IS_REQUIRED);
    };

    const handleEmailChange = event => setEmail(event.target.value);

    const handlePasswordChange = event => setPassword(event.target.value);

    return {
        email,
        emailError,
        handleEmailBlur,
        handleEmailChange,
        handlePasswordBlur,
        handlePasswordChange,
        isFormValid,
        password,
        passwordError,
    };
};
