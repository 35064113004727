import theme from "../../../config/style/muiTheme";

const centerVertically = {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column',
};

const styles = {
    badge_container: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column',
    },
    badge: {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '150%',
        textAlign: 'center',
        backgroundColor: '#E5EFFA',
        padding: '0.5px 6px 1px',
        borderRadius: '4px',
        color: '#3D3666',
    },
    title: {
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: '150%',
        whiteSpace: 'nowrap',
        color: '#33366A',
    },
    container: { display: 'flex', gap: '10px' },
    divider_container: {
        ...centerVertically,
        width: '100%',
        marginTop: '12px',
    },
    divider: { color: 'gray', width: '100%', marginBottom: '13px' },
    title_container: {
        ...centerVertically,
    },
    button: {
        color: '#0037FA',
        fontSize: '16px',
        lineHeight: '150%',
        whiteSpace: 'nowrap',
    },
    buttonDisabled: {
        color: theme.palette.text.disabled,
        fontSize: '16px',
        lineHeight: '150%',
        whiteSpace: 'nowrap',
        cursor: 'default',
    },
    title_box: { display: 'flex', gap: '10px' },
    link_button: {
        color: '#545BE5',
        fontSize: "16px",
        fontWeight: "400",
        '&:hover': {
            cursor: 'pointer',
        },
    },
};

export default styles;
