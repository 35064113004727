const styles = {
    leaveGroup: {
        outlineColor: '#33366A',
        margin: '10px',
        padding: '10px',
        color: '#BB1313',
        bgcolor: '#F2F7FA',
        width: '150px',
    },
    modalIcons: {
        color: '#A1A4F1',
        outlineColor: '#33366A',
        marginRight: '8px',
    },
    CircularProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-12px',
        marginLeft: '-12px',
    },
};

export default styles;
