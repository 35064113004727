import theme from '../../../config/style/muiTheme';

const styles = {
    chatAccordion: {
        backgroundColor: 'inherit',
        '&::before': {
            content: 'none',
        },
    },
    chatAccordionDetails: {
        padding: '0px 2px',
    }
};

export default styles;
