/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, Select, MenuItem, TextField, InputLabel, IconButton } from '@mui/material';
import { DesktopDatePicker as DatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import ErrorIcon from '@mui/icons-material/Error';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import { utils } from 'comm-recipientapp-shared';
import TimePicker from './TimePicker';
import AttachmentPreviewPopup from '../AttachmentPreview';
import AttachmentUpload from '../AttachmentUpload';
import useAttachment from '../hooks/useAttachment';
import useEditAbsenceForm from '../hooks/useEditAbsenceForm';
import useOrganizationConfig from '../hooks/useOrganizationConfig';
import DatePickerTextField from './DatePickerTextField';
import styles from '../../styles';
import GenericError from '../Error';

const { getFieldsForAbsenceType } = utils.attendanceUtils;
const { useStrings } = utils;
const MAX_NUMBER_OF_CHARS_ALLOWED = 220;

const shouldDisableDate = calendarDate => {
    // if a date is a not a week day, disable it
    const date = calendarDate.$d;

    return date.getDay() === 0 || date.getDay() === 6;
};

/**
 * @typedef {object} EditAbsenceProps
 * @property {import('../index').Absence} popupData
 * @property {React.MutableRefObject<{ getFormData: () => Record<string, any>; handleEditError: (err: string) => void }>} editAbsenceFormRef
 * @property {(canBeSent) => void} handleFormReadyToBeSent
 * @property {(newData) => void} onChange
 * @param {React.PropsWithChildren<EditAbsenceProps>} props
 */
export default function EditAbsenceForm({ popupData = {}, editAbsenceFormRef, handleFormReadyToBeSent, handleClose, onChange }) {
    const { language } = useSelector(state => state.user);
    const [absenceType, setAbsenceType] = useState(popupData.absenceType);
    const [absenceText, setAbsenceText] = useState(popupData.typeOfAbsence);
    const [isAttachmentPreviewOpen, setIsAttachmentPreviewOpen] = useState(false);
    const [isAttachmentUploadOpen, setIsAttachmentUploadOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const isMultiDayAbsenceFromPastTime = useMemo(() => {
        const eventStartDate = popupData?.date?.eventStartDate ? popupData.date.eventStartDate.date : null;
        if (absenceType === 'multiDay' && eventStartDate) {
            const parts = eventStartDate.split('-');
            if (parts.length === 3) {
                const absenceDate = new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]));
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                absenceDate.setHours(0, 0, 0, 0);
                if (absenceDate < today) {
                    return true;
                }
            }
        }
        return false;
    }, [popupData]);

    const {
        ABSENCE_POPUP_ATTACHMENTS,
        ABSENCE_POPUP_TYPE_OF_ABSENCE,
        ABSENCE_POPUP_REASON,
        ABSENCE_POPUP_COMMENTS,
        ABSENCE_EDIT_FIELD_DATE_OF_ABSENCE,
        ABSENCE_EDIT_FIELD_LAST_DAY_OF_ABSENCE,
        ABSENCE_EDIT_FIELD_RETURNING_AT_SCHOOL_AT,
        ABSENCE_EDIT_FIELD_TIME_OF_DEPARTURE,
        ABSENCE_GENERIC_ERROR_TITLE,
        ABSENCE_EDIT_TITLE_ATTACHMENTS,
        ABSENCE_EDIT_COMMENTS_PLACEHOLDER,
    } = useStrings();

    const { allowedAbsenceReasonCodes, attachmentsEnabled, commentsEnabled, orgSettings, getReasonText } = useOrganizationConfig(
        popupData.student.organizationId,
        popupData.student.customerId
    );

    const reasonCodes = useMemo(() => allowedAbsenceReasonCodes[absenceType] || [], [absenceType, allowedAbsenceReasonCodes]);

    const alertHtmlColor = '#BD3127';

    // map the current values to the form fields
    const mappedFormFields = useMemo(() => {
        const fields = getFieldsForAbsenceType(absenceType);

        fields.date = popupData.date.eventStartDate?.date || popupData.date.start?.date;
        fields.absenceType = absenceType;
        fields.reasonCode = popupData.reasonCode;

        if (popupData.date?.start?.inTime || popupData.date?.eventStartDate?.inTime) {
            fields.inTime = popupData.date.start?.inTime || popupData.date.eventStartDate?.inTime;
        }

        if (popupData.date?.start?.outTime || popupData.date?.eventStartDate?.outTime) {
            fields.outTime = popupData.date.start?.outTime || popupData.date.eventStartDate?.outTime;
        }

        if ((popupData.date?.end || fields.hasOwnProperty('endDate')) && absenceType === 'multiDay') {
            const datePlusOneDay = new Date();
            datePlusOneDay.setDate(new Date(fields.date).getUTCDate() + 1);
            fields.endDate =
                popupData.date?.end?.date || `${datePlusOneDay.getUTCFullYear()}-${datePlusOneDay.getUTCMonth() + 1}-${datePlusOneDay.getUTCDate()}`;
        }

        if (popupData.attachment) {
            fields.attachment = popupData.attachment;
        }

        if (popupData.comment) {
            fields.comment = popupData.comment;
        }

        if (absenceType === 'multiDay' || absenceType === 'late' || absenceType === 'fullDay') {
            delete fields.outTime;
        }

        if (absenceType === 'multiDay' || absenceType === 'earlyDeparture' || absenceType === 'fullDay') {
            delete fields.inTime;
        }

        return fields;
    }, [popupData, absenceType]);

    const { onValueChange, reasonCode, date, endDate, comment, attachment, inTime, outTime, canBeSent, formatDate, getFormValues } =
        useEditAbsenceForm(mappedFormFields, popupData.absenceType, popupData.id, popupData.student);

    /** @param {string} errorMessage */
    const handleEditError = _errorMessage => {
        setErrorMessage(_errorMessage);
    };

    useImperativeHandle(
        editAbsenceFormRef,
        () => ({
            getFormData: getFormValues,
            handleEditError,
        }),
        [getFormValues]
    );

    useEffect(() => {
        if (onChange) {
            onChange({
                absenceType,
                reasonCode,
                comment,
                attachment,
                date,
                id: popupData.id,
            });
        }
    }, [date, endDate, comment, attachment, reasonCode, inTime, outTime, absenceType]);

    useEffect(() => {
        if (!popupData.status.isUnexplained) {
            handleFormReadyToBeSent(canBeSent);
        }
    }, [canBeSent, handleFormReadyToBeSent]);

    const { attachmentUrl, AttachmentIcon, downloadAttachment, attachmentSizeInMb, AttachmentType } = useAttachment(attachment);

    const handleAttachmentPreviewOpen = useCallback(openState => {
        if (typeof openState === 'boolean') {
            setIsAttachmentPreviewOpen(openState);
        } else {
            setIsAttachmentPreviewOpen(prevOpenState => !prevOpenState);
        }
    }, []);

    const handleAttachmentUploadResult = useCallback(
        result => {
            const filesUploaded = result?.filesUploaded || [];

            if (filesUploaded.length > 0) {
                const file = filesUploaded[0];

                const attachmentData = {
                    filename: file.filename,
                    handle: file.handle,
                    mimetype: file.mimetype,
                    size: file.size,
                    url: file.url,
                };

                onValueChange('attachment', attachmentData);
            }
        },
        [onValueChange]
    );

    return (
        <Box>
            {isAttachmentPreviewOpen && attachment && (
                <AttachmentPreviewPopup
                    AttachmentIcon={AttachmentIcon}
                    attachmentMimeType={attachment.mimetype}
                    attachmentName={attachment.filename}
                    attachmentSize={attachmentSizeInMb}
                    attachmentUrl={attachmentUrl}
                    handleClose={handleAttachmentPreviewOpen}
                    isOpen={isAttachmentPreviewOpen}
                    downloadAttachment={downloadAttachment}
                />
            )}
            {isAttachmentUploadOpen && (
                <AttachmentUpload
                    handleUploadDone={handleAttachmentUploadResult}
                    handleClose={() => setIsAttachmentUploadOpen(prevState => !prevState)}
                />
            )}
            {!popupData.status.isUnexplained && (
                <Box sx={styles.edit_popup_container}>
                    <Box />
                    <Typography variant="h1" sx={styles.edit_popup_student_name}>
                        {popupData.student.firstName} {popupData.student.lastName}
                    </Typography>
                    <IconButton sx={styles.close_icon} onClick={handleClose} data-testid="close-edit-absence-btn">
                        <CloseIcon sx={styles.edit_popup_close_button} />
                    </IconButton>
                </Box>
            )}
            <Box sx={styles.edit_popup_form_fields_container}>
                {Object.keys(mappedFormFields).map(formValue => {
                    if (formValue === 'absenceType') {
                        return (
                            <Box key={`form-field-${formValue}`} sx={popupData.status.isUnexplained && { width: '100%', marginLeft: '-5px' }}>
                                <Box sx={{ display: 'flex', gap: '5px' }}>
                                    <InputLabel sx={styles.edit_popup_field_label}>{ABSENCE_POPUP_TYPE_OF_ABSENCE}</InputLabel>
                                    {!absenceType && popupData.status.isUnexplained && (
                                        <ErrorIcon htmlColor={alertHtmlColor} sx={{ fontSize: '20px' }} />
                                    )}
                                </Box>
                                <Select
                                    variant="standard"
                                    value={absenceText}
                                    sx={
                                        popupData.status.isUnexplained
                                            ? { ...styles.edit_popup_select_field, width: '100%' }
                                            : styles.edit_popup_select_field
                                    }
                                    disabled={isMultiDayAbsenceFromPastTime}
                                >
                                    {orgSettings.absenceTypes.map((_absenceType, i) => {
                                        return (
                                            <MenuItem
                                                onClick={() => {
                                                    setAbsenceText(_absenceType.text);
                                                    setAbsenceType(_absenceType.type);
                                                    onValueChange('absenceType', _absenceType.type);
                                                }}
                                                key={`absence-type-${i}`}
                                                value={_absenceType.text}
                                                sx={styles.edit_popup_select_item}
                                            >
                                                {_absenceType.text}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </Box>
                        );
                    }

                    if (formValue === 'reasonCode') {
                        return (
                            <Box
                                key={`form-field-${formValue}`}
                                sx={popupData.status.isUnexplained && { width: '100%', marginTop: '20px', marginLeft: '-5px' }}
                            >
                                <Box sx={{ display: 'flex', gap: '10px' }}>
                                    <InputLabel sx={styles.edit_popup_field_label}>{ABSENCE_POPUP_REASON}</InputLabel>
                                    {!reasonCode && popupData.status.isUnexplained && (
                                        <ErrorIcon htmlColor={alertHtmlColor} sx={{ fontSize: '20px' }} />
                                    )}
                                </Box>

                                <Select
                                    variant="standard"
                                    value={reasonCode}
                                    sx={
                                        popupData.status.isUnexplained
                                            ? { ...styles.edit_popup_select_field, width: '100%' }
                                            : styles.edit_popup_select_field
                                    }
                                    disabled={isMultiDayAbsenceFromPastTime}
                                >
                                    {reasonCodes.map((_reasonCode, i) => {
                                        const _reasonText = getReasonText(absenceType, _reasonCode);
                                        return (
                                            <MenuItem
                                                onClick={() => onValueChange('reasonCode', _reasonCode)}
                                                key={`absence-reason-${i}`}
                                                value={_reasonCode}
                                                sx={styles.edit_popup_select_item}
                                            >
                                                {_reasonText}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </Box>
                        );
                    }

                    if (formValue === 'date' && !popupData.status.isUnexplained) {
                        return (
                            <Box key={`form-field-${formValue}`} sx={styles.edit_popup_calendar_field_container}>
                                <InputLabel
                                    sx={{
                                        ...styles.edit_popup_field_label,
                                        ...styles.edit_popup_calendar_field_label,
                                    }}
                                >
                                    {ABSENCE_EDIT_FIELD_DATE_OF_ABSENCE}
                                </InputLabel>
                                <DatePicker
                                    value={date}
                                    onChange={({ $y, $D, $M }) => {
                                        onValueChange('date', `${$y}-${$M + 1}-${$D}`);
                                    }}
                                    renderInput={params => <DatePickerTextField params={params} language={language} formatDate={formatDate} />}
                                    shouldDisableDate={shouldDisableDate}
                                    disabled={isMultiDayAbsenceFromPastTime}
                                />
                            </Box>
                        );
                    }

                    if (formValue === 'endDate') {
                        return (
                            <Box key={`form-field-${formValue}`} sx={styles.edit_popup_calendar_field_container}>
                                <InputLabel
                                    sx={{
                                        ...styles.edit_popup_field_label,
                                        ...styles.edit_popup_calendar_field_label,
                                    }}
                                >
                                    {ABSENCE_EDIT_FIELD_LAST_DAY_OF_ABSENCE}
                                </InputLabel>
                                <DatePicker
                                    value={endDate}
                                    onChange={({ $y, $D, $M }) => onValueChange('endDate', `${$y}-${$M + 1}-${$D}`)}
                                    renderInput={params => <DatePickerTextField params={params} language={language} formatDate={formatDate} />}
                                    shouldDisableDate={shouldDisableDate}
                                />
                            </Box>
                        );
                    }

                    if (formValue === 'outTime') {
                        return (
                            <Box key={`form-field-${formValue}`} sx={styles.edit_popup_timepicker_field_container}>
                                <InputLabel
                                    sx={{
                                        ...styles.edit_popup_field_label,
                                        ...styles.edit_popup_timepicker_field_label,
                                    }}
                                >
                                    {ABSENCE_EDIT_FIELD_TIME_OF_DEPARTURE}
                                </InputLabel>
                                <TimePicker
                                    ampm
                                    onTimeChange={newTime => onValueChange('outTime', newTime)}
                                    defaultTime={outTime || '01:00'}
                                    fullWidth={popupData.status.isUnexplained}
                                />
                            </Box>
                        );
                    }

                    if (formValue === 'inTime') {
                        return (
                            <Box key={`form-field-${formValue}`} sx={styles.edit_popup_timepicker_field_container}>
                                <InputLabel
                                    sx={{
                                        ...styles.edit_popup_field_label,
                                        ...styles.edit_popup_timepicker_field_label,
                                    }}
                                >
                                    {ABSENCE_EDIT_FIELD_RETURNING_AT_SCHOOL_AT}
                                </InputLabel>
                                <TimePicker
                                    ampm
                                    onTimeChange={newTime => onValueChange('inTime', newTime)}
                                    defaultTime={inTime || '01:00'}
                                    fullWidth={popupData.status.isUnexplained}
                                />
                            </Box>
                        );
                    }

                    return null;
                })}
            </Box>
            <Box
                sx={
                    popupData.status.isUnexplained
                        ? { margin: { xs: '17px', sm: '15px 20px 15px 15px', md: '15px 20px 15px 15px', lg: '15px 20px 15px 15px' } }
                        : styles.edit_popup_comments_and_attachment_section
                }
            >
                {commentsEnabled && (
                    <Box>
                        <InputLabel sx={styles.edit_popup_field_label}>{ABSENCE_POPUP_COMMENTS}</InputLabel>
                        <TextField
                            fullWidth
                            variant="standard"
                            value={comment || ''}
                            placeholder={ABSENCE_EDIT_COMMENTS_PLACEHOLDER}
                            onChange={e => {
                                onValueChange('comment', e.target.value);
                            }}
                            sx={styles.edit_popup_comments_text_field}
                            inputProps={{ maxLength: MAX_NUMBER_OF_CHARS_ALLOWED }}
                            multiline
                        />
                    </Box>
                )}
                {attachmentsEnabled && (
                    <Box sx={styles.edit_popup_attachments_container}>
                        <Box sx={styles.edit_popup_comments_and_attachment_header}>
                            <Typography sx={styles.edit_popup_field_label}>{ABSENCE_POPUP_ATTACHMENTS}</Typography>
                            <Box
                                sx={{
                                    ...styles.edit_popup_attachments_title_container,
                                    cursor: attachment ? 'default' : 'pointer',
                                }}
                                onClick={() => {
                                    if (!attachment) {
                                        setIsAttachmentUploadOpen(true);
                                    }
                                }}
                                data-testid="open-upload-attachement-btn"
                            >
                                <Typography
                                    sx={
                                        attachment
                                            ? styles.edit_popup_add_attachments_button_text
                                            : {
                                                  ...styles.edit_popup_add_attachments_button_text,
                                                  ...styles.edit_popup_add_attachments_button_text_active,
                                              }
                                    }
                                >
                                    {ABSENCE_EDIT_TITLE_ATTACHMENTS}
                                </Typography>
                                <Box
                                    sx={
                                        attachment
                                            ? styles.edit_popup_add_attachments_button_container
                                            : {
                                                  ...styles.edit_popup_add_attachments_button_container,
                                                  ...styles.edit_popup_add_attachments_button_container_active,
                                              }
                                    }
                                >
                                    <IconButton disabled={attachment}>
                                        <AttachFileIcon sx={styles.edit_popup_add_attachments_button_icon} />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Box>

                        {attachment && (
                            <Box sx={styles.edit_popup_attachments_details_container}>
                                <Box sx={styles.popup_text_container}>
                                    <Box sx={styles.attachment_container}>
                                        {AttachmentType.startsWith('image/') ? (
                                            <img
                                                src={attachmentUrl}
                                                data-testid="open-upload-attachement-preview-btn"
                                                alt="attachment_file_preview"
                                                style={styles.edit_popup_attachments_details_preview_img}
                                                onClick={() => handleAttachmentPreviewOpen()}
                                            />
                                        ) : (
                                            <Box onClick={() => handleAttachmentPreviewOpen()}>
                                                <AttachmentIcon
                                                    height={styles.popup_attachment_no_img.height}
                                                    width={styles.popup_attachment_no_img.width}
                                                    fillColor={styles.popup_attachment_no_img.color}
                                                />
                                            </Box>
                                        )}

                                        <Box sx={styles.edit_popup_attachments_metadata_container}>
                                            <Typography
                                                sx={{
                                                    ...styles.popup_text,
                                                    ...styles.attachment_name,
                                                }}
                                            >
                                                {attachment?.filename}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    ...styles.popup_text_description,
                                                    ...styles.attachment_size,
                                                }}
                                            >
                                                ({attachmentSizeInMb} MB)
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <IconButton sx={styles.edit_popup_attachment_clear_button} onClick={() => onValueChange('attachment', null)}>
                                    x
                                </IconButton>
                            </Box>
                        )}
                    </Box>
                )}
            </Box>
            <Box>{errorMessage && <GenericError errorMessage={errorMessage} title={ABSENCE_GENERIC_ERROR_TITLE} />}</Box>
        </Box>
    );
}
