import { React, useMemo } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

import { env, utils } from 'comm-recipientapp-shared';
import { 
    Excel, 
    Image, 
    Pdf, 
    PowerPoint, 
    TextPlain, 
    Word 
} from '../icons';

const { attachmentSizeToMb } = utils.attendanceUtils;

/**
 * @typedef {{
 *     handle: string;
 *     url: string;
 *     filename: string;
 *     mimetype: string;
 *     size: number;
 * }} Attachment
 */

/**
 * @param {Attachment} attachment
 * @param {number} previewWidth
 */
export default function useAttachment(attachment, previewWidth = 60) {
    const { account } = useSelector(state => state.user);

    /** @returns {Attachment} */
    const ensureUrlPolicySignature = () => {
        const fileStackCredentials = account.configuration.attachments;

        const policySignatureQueryString = `?policy=${fileStackCredentials.policy}&signature=${fileStackCredentials.signature}`;

        const attachmentClone = structuredClone(attachment);

        // does the url already have the policy/signature query string?
        const urlHasPolicySignature =
            attachmentClone.url.split(policySignatureQueryString).length > 1;

        if (!urlHasPolicySignature) {
            attachmentClone.url += policySignatureQueryString;
        }

        return attachmentClone;
    };

    /** @param {string} mimeType */
    const getIconAttachmentPreview = mimeType => {
        switch (mimeType) {
        case 'image/gif':
        case 'image/svg+xml':
        case 'image/png':
        case 'image/jpeg':
            return Image;

        case 'text/plain':
            return TextPlain;

        case 'application/pdf':
            return Pdf;

        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return Word;

        case 'application/vnd.ms-excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return Excel;

        case 'application/vnd.ms-powerpoint':
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            return PowerPoint;

        default:
            return TextPlain;
        }
    };

    const downloadAttachment = async () => {
        const attachmentWithPolicySignature = ensureUrlPolicySignature();

        const { data } = await axios.get(attachmentWithPolicySignature.url, {
            responseType: 'blob',
        });

        const objectBlob = data;

        const url = URL.createObjectURL(objectBlob);

        const aTag = document.createElement('a');
        aTag.style.display = 'none';
        aTag.href = url;
        aTag.download = attachmentWithPolicySignature.filename;
        document.body.appendChild(aTag);
        aTag.click();
        URL.revokeObjectURL(url);
        aTag.remove();
    };

    const AttachmentIcon = useMemo(() => {
        if (attachment) {
            return getIconAttachmentPreview(attachment.mimetype);
        }

        return () => <></>;
    }, [attachment]);

    const attachmentSizeInMb = useMemo(() => {
        if (attachment) {
            return attachmentSizeToMb(attachment.size);
        }

        return 0;
    }, [attachment]);

    const attachmentUrl = useMemo(() => {
        const fileStackCredentials = account.configuration.attachments;

        if (attachment) {
            return `${env.FILESTACK_IMG_PROCESSING_URL}/security=policy:${fileStackCredentials.policy},signature:${fileStackCredentials.signature}/rotate=deg:exif/resize=width:${previewWidth},fit:max/${env.FILESTACK_CDN_URL}/${attachment.handle}`;
        }

        return '';
    }, [attachment, account.configuration, previewWidth]);

    return {
        AttachmentIcon,
        attachmentSizeInMb,
        attachmentUrl,
        downloadAttachment,
        AttachmentType: attachment?.mimetype
    };
}
