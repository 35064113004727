/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import styles from './styles';

class ErrorBoundary extends Component {
    /**
     * @typedef {object} ErrorBoundaryProps
     * @property {(props: { error: object; errorInfo: object }) => JSX.Element} fallbackComponent
     * @param {React.PropsWithChildren<ErrorBoundaryProps>} props
     */
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error,
            errorInfo,
        });
    }

    render() {
        if (this.state.errorInfo) {
            if (this.props.fallbackComponent) {
                const Fallback = this.props.fallbackComponent;

                return <Fallback {...this.state} />;
            }

            return (
                <div style={styles.error_boundary_container}>
                    <h4>Something went wrong.</h4>
                    {process.env.NODE_ENV === 'development' && (
                        <details style={styles.error_details}>
                            {this.state.error && this.state.error.toString()}
                            <br />
                            {this.state.errorInfo.componentStack}
                        </details>
                    )}
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
