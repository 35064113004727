import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Tab, Tabs } from '@mui/material';
import { utils, actions } from 'comm-recipientapp-shared';
import StudentList from './StudentList';
import Loader from './Loader';
import Feedback from './Feedback';
import styles from './styles';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function ManageYourPin({ visible, handleClose }) {
    const [isValid, setIsValid] = useState(false);
    const [requesting, setRequesting] = useState(false);
    const [completed, setCompleted] = useState(false);
    const { enabledStudents } = useSelector(state => state.absence);
    const schools = useSelector(state => state.preferences.schools);
    const [students, setStudents] = useState([]);
    const [selectedStudentCount, setSelectedStudentCount] = useState(0);
    const [pinValue, setPinValue] = useState('');
    const [validPin, setValidPin] = useState(false);
    const [started, setStarted] = useState(false);
    const strings = utils.useStrings();
    const dispatch = useDispatch();

    const customersWithStudents = useMemo(() => {
        // school districts
        const customers = enabledStudents.map(student => student.customerId);

        const uniqueCustomers = [...new Set(customers)];
        if (uniqueCustomers.length === 0) return [];

        const orgsArray = uniqueCustomers.map(customer => {
            const customerStudents = enabledStudents.filter(student => student.customerId === customer);

            const customerName = schools.find(school => school.id === customer)?.name;

            return {
                customerId: customer,
                students: customerStudents,
                customerName,
            };
        });

        return orgsArray;
    }, [visible, enabledStudents]);

    const [currentOrganizationSelected, setCurrentOrganizationSelected] = useState(customersWithStudents[0]?.customerId);

    const currentOrgName = useMemo(() => {
        const orgFound = customersWithStudents.find(org => org.customerId === currentOrganizationSelected);

        return orgFound?.customerName;
    }, [currentOrganizationSelected]);

    useEffect(() => {
        const studentByOrgId = customersWithStudents.find(org => org?.customerId === currentOrganizationSelected);
        setStudents(studentByOrgId?.students.map(student => ({ ...student, selected: false })));
    }, [currentOrganizationSelected]);

    useEffect(() => {
        setPinValue(enabledStudents[0]?.pinValue);
        if (enabledStudents[0]?.pinValue === undefined || enabledStudents[0]?.pinValue?.length > 0) {
            setValidPin(true);
        }
    }, [enabledStudents]);

    useEffect(() => {
        if (started) {
            const studentSelected = students.filter(student => student.safearrivalPinEnabled);
            const totalSelected = studentSelected.length;
            setSelectedStudentCount(totalSelected);
            // all selected
            if (totalSelected === students.length) {
                // eslint-disable-next-line max-len
                if (pinValue === undefined && pinValue?.length === utils.constants.MAX_MANAGE_PIN_LENGTH) {
                    setValidPin(true);
                    setIsValid(true);
                } else {
                    setValidPin(false);
                    setIsValid(false);
                }
            }

            if (totalSelected === 0) {
                if (pinValue === undefined || pinValue?.length > 0) {
                    setValidPin(true);
                    setIsValid(true);
                } else if (pinValue?.length === utils.constants.MAX_MANAGE_PIN_LENGTH) {
                    setValidPin(true);
                    setIsValid(true);
                }
            }

            if (totalSelected > 0) {
                if (pinValue?.length === utils.constants.MAX_MANAGE_PIN_LENGTH) {
                    setValidPin(true);
                    setIsValid(true);
                } else {
                    setValidPin(false);
                    setIsValid(false);
                }
            }
        }
    }, [pinValue, students]);

    const handleClick = index => {
        setStarted(true);
        setStudents(current =>
            current.map((obj, i) => {
                if (i === index) {
                    return {
                        ...obj,
                        safearrivalPinEnabled: !obj.safearrivalPinEnabled,
                        selected: !obj.selected,
                    };
                }

                return obj;
            })
        );
    };

    const handleSubmit = async () => {
        const data = [];

        if (selectedStudentCount === 0) {
            setPinValue('');
        }

        students.forEach(student => {
            if (student.selected) {
                data.push({
                    customerId: student.customerId,
                    personId: student.personId,
                    pinEnabled: student.safearrivalPinEnabled,
                    pinValue: selectedStudentCount === 0 ? '' : pinValue,
                });
            }
        });

        if (data.length === 0) {
            const firstStudent = students[0];
            data.push({
                customerId: firstStudent.customerId,
                personId: firstStudent.personId,
                pinEnabled: firstStudent.safearrivalPinEnabled,
                pinValue: selectedStudentCount === 0 ? '' : pinValue,
            });
        }

        setRequesting(true);
        await Promise.all(
            data.map(async value => {
                await dispatch(
                    actions.absence.updateAttendanceStudent(value.customerId, value.personId, {
                        pinEnabled: value.pinEnabled,
                        pinValue: value.pinValue,
                    })
                );
            })
        );
        await dispatch(actions.absence.getData());
        setRequesting(false);
        setCompleted(true);
    };

    const handlePinChange = e => {
        const { value } = e.target;
        setStarted(true);
        setPinValue(value);
    };

    const handleComplete = () => {
        handleClose();
    };

    if (requesting) {
        return <Loader sx={styles.modalContent} />;
    }

    return (
        <Dialog maxWidth="sm" open={visible} onClose={handleClose}>
            <Box sx={styles.headerContainer}>
                <DialogTitle sx={styles.dialogHeader}>{strings.SAFE_ARRIVAL_PIN_SETTINGS}</DialogTitle>
                <IconButton disableRipple sx={styles.headerCloseButton} onClick={handleClose}>
                    X
                </IconButton>
            </Box>

            <DialogContent
                style={{
                    ...styles.modalContent,
                    minHeight: requesting ? styles.heightDefault : styles.heightAuto,
                }}
            >
                {completed ? (
                    <Feedback sx={styles.modalContent} handleComplete={handleComplete} />
                ) : (
                    <Box>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={currentOrganizationSelected} aria-label="organization-tabs">
                                {customersWithStudents.map((customer, index) => (
                                    <Tab
                                        key={customer.customerId}
                                        label={customer.customerName}
                                        value={customer.customerId}
                                        onClick={() => {
                                            setCurrentOrganizationSelected(customer.customerId);
                                        }}
                                        {...a11yProps(index)}
                                    />
                                ))}
                            </Tabs>
                        </Box>
                        <StudentList
                            handleClick={handleClick}
                            handleClose={handleClose}
                            currentOrgName={currentOrgName}
                            handlePinChange={handlePinChange}
                            handleSubmit={handleSubmit}
                            isValid={isValid}
                            pinValue={pinValue}
                            validPin={validPin}
                            setPinValue={setPinValue}
                            students={students}
                        />
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    );
}

export default ManageYourPin;
