import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch} from 'react-redux';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Typography from '@mui/material/Typography';
import MaterialButton from '@mui/material/Button';
import { Box, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import { utils, hooks, env, actions } from 'comm-recipientapp-shared';
import { handleEnterKeyPress } from 'comm-recipientapp-shared/src/utils/helpers';
import unitedStateFlag from 'comm-recipientapp-shared/src/assets/images/united_states_flag.svg';
import CanadaFlag from 'comm-recipientapp-shared/src/assets/canada_flag.svg';
import Button from '../../Shared/Button';
import ShowError from '../ShowError';
import styles from './styles';

function LoginForm(props) {
    const dispatch = useDispatch();
    const strings = utils.useStrings();
    const state = useSelector(store => store.auth);
    const { resetErrorAuth } = actions.auth;
    const [showPassword, setShowPassword] = useState(false);
    const useLogin = hooks.auth.useLogin();
    const matches = useMediaQuery('(max-width:600px)');
    const [initialLaunch, setInitialLaunch] = useState(false);

    const handleSubmit = e => {
        e.preventDefault();
        e.stopPropagation();
        props.handleSubmit({
            email: useLogin.email,
            password: useLogin.password,
        });
    };

    // extract the location from the domain, if the domain ends in .ca, then the location is Canada
    const [currentLocation, setCurrentLocation] = useState('United States');

    const redirectToNewDomain = () => {
        if (env.IS_DEV) {
            return;
        }

        if (currentLocation === 'Canada') {
            // get the current domain and replace the .ca with .com

            const domain = window.location.hostname;

            const domainParts = domain.split('.');

            domainParts[domainParts.length - 1] = 'com';

            const newDomain = domainParts.join('.');

            window.location.href = `https://${newDomain}/#/login`;

            return;
        }

        if (currentLocation === 'United States') {
            const domain = window.location.hostname;

            const domainParts = domain.split('.');

            domainParts[domainParts.length - 1] = 'ca';

            const newDomain = domainParts.join('.');

            window.location.href = `https://${newDomain}/#/login`;
        }
    };

    const setLoginButtonFocus = () => {
        const buttonLogin = document.getElementById('button-login-id');
        if (buttonLogin && useLogin.isFormValid) {
            buttonLogin.focus();
        }
    };

    useEffect(() => {
        const domain = window.location.hostname;
        const domainParts = domain.split('.');
        const domainExtension = domainParts[domainParts.length - 1];
        setCurrentLocation(domainExtension === 'ca' ? 'Canada' : 'United States');
        if (window.navigator.userAgent.includes('Chrome')) {
            setInitialLaunch(true);
        }
    }, []);

    useEffect(() => {
        if ((useLogin.email.length > 0) || (useLogin.password.length > 0)) {
            setInitialLaunch(false);
        }
    }, [useLogin.email, useLogin.password]);

    const loginEnabled = useLogin.isFormValid || initialLaunch;

    return (
        <Box
            autoComplete="off"
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ ...styles.form, display: state.isLoading ? 'none' : 'flex' }}
        >
            <Typography sx={styles.title}>{strings.LOG_IN}</Typography>
            <FormControl fullWidth sx={styles.formGroup} variant="standard">
                <InputLabel htmlFor="email" sx={styles.label}>
                    {strings.EMAIL}
                </InputLabel>
                <Input
                    error={useLogin.emailError !== ''}
                    id="email"
                    name="email"
                    onBlur={() => {
                        dispatch(resetErrorAuth());
                        useLogin.handleEmailBlur();
                        setLoginButtonFocus();
                    }}
                    onChange={useLogin.handleEmailChange}
                    required
                    startAdornment={<></>}
                    type="text"
                    value={useLogin.email}
                    autoComplete="on"
                />
                {useLogin.emailError ? (
                    <FormHelperText aria-live="assertive" error>
                        {useLogin.emailError}
                    </FormHelperText>
                ) : null}
            </FormControl>
            <FormControl fullWidth sx={styles.formGroup} variant="standard">
                <InputLabel htmlFor="password" sx={styles.label}>
                    {strings.PASSWORD}
                </InputLabel>
                <Input
                    endAdornment={
                        <InputAdornment position="end">
                            <Tooltip title={strings.TOGGLE_PASSWORD_VISIBILITY}>
                                <IconButton
                                    aria-label={showPassword ? strings.HIDE_PASSWORD : strings.SHOW_PASSWORD}
                                    aria-live="polite"
                                    onClick={() => setShowPassword(!showPassword)}
                                    data-testid="show-password"
                                >
                                    {showPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                                </IconButton>
                            </Tooltip>
                        </InputAdornment>
                    }
                    error={useLogin.passwordError !== ''}
                    id="password"
                    inputProps={{
                        'data-testid': 'password',
                    }}
                    name="password"
                    onBlur={() => {
                        useLogin.handlePasswordBlur();
                        setLoginButtonFocus();
                    }}
                    onChange={useLogin.handlePasswordChange}
                    required
                    startAdornment={<></>}
                    type={showPassword ? 'text' : 'password'}
                    value={useLogin.password}
                    autoComplete="pass-login-form"
                />
                {useLogin.passwordError ? (
                    <FormHelperText aria-live="assertive" error>
                        {useLogin.passwordError}
                    </FormHelperText>
                ) : null}
                <div style={styles.forgotContent}>
                    <Link to="/forgotpassword" style={styles.forgotLink}>
                        <Typography variant="strong">{strings.FORGOT_YOUR_PASSWORD}</Typography>
                    </Link>
                </div>
            </FormControl>
            <FormControl fullWidth sx={styles.formGroup} variant="standard">
                <InputLabel htmlFor="location" sx={styles.label}>
                    {strings.LOCATION}
                </InputLabel>
                <Input
                    id="location"
                    placeholder={currentLocation === 'Canada' ? 'Canada' : strings.UNITED_STATES}
                    readOnly
                    startAdornment={
                        <img
                            src={currentLocation === 'Canada' ? CanadaFlag : unitedStateFlag}
                            style={styles.flagImage}
                            alt={strings.UNITED_STATES}
                            aria-hidden
                        />
                    }
                    type="email"
                    inputProps={{ tabIndex: '-1' }}
                />
                <div style={styles.locationContent}>
                    <Typography sx={styles.schoolLocation} variant="span">
                        {strings.SIGN_UP_LOCATION_TEXT} {currentLocation === 'Canada' ? strings.SIGN_UP_LOCATION_US : 'Canada'}?
                    </Typography>
                    <MaterialButton onClick={redirectToNewDomain} sx={{ margin: 0, padding: 0, '&:hover': { backgroundColor: 'transparent' } }}>
                        {strings.SWITCH_LOCATION}
                    </MaterialButton>
                </div>
            </FormControl>
            {state.isError ? <ShowError errorCode={state.error.status} /> : null}
            <Box style={{ ...styles.buttonContent, ...{ width: matches ? '100%' : '50%' } }}>
                <Button
                    id="button-login-id"
                    color="primary"
                    data-testid="button-login"
                    disabled={!loginEnabled}
                    fullWidth
                    sx={styles.button}
                    type="button"
                    onClick={handleSubmit}
                    onKeyDown={handleEnterKeyPress(e => handleSubmit(e))}
                    variant="contained"
                    tabIndex={0}
                    autoFocus={true}
                >
                    {strings.LOG_IN}
                </Button>
                <div style={styles.notRegisterContent}>
                    <Typography sx={styles.schoolLocation} variant="span">
                        {strings.NOT_REGISTERED_YET}
                    </Typography>
                    <Link to="/signup" style={styles.forgotLink}>
                        <Typography variant="strong">{strings.SIGN_UP}</Typography>
                    </Link>
                </div>
            </Box>
        </Box>
    );
}

export default LoginForm;
