import theme from '../../../../config/style/muiTheme';

const styles = {
    dialog_paper_styles: {
        maxWidth: '500px',
    },
    delete_header_container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '10px 0px',
    },
    close_icon: {
        marginRight: '10px',
    },
    not_editable_close_icon: {
        color: 'white',
    },
    not_editable_close_btn_contanier: {
        marginRight: '10px',
    },
    student_detail_close_btn: {
        marginRight: '10px',
        marginBottom: '-10px',
    },
    student_detail_container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    popup_not_editable_absence_text_container: {
        backgroundColor: '#d32f2f',
        height: '63px',
        marginBottom: '15px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    popup_not_editable_absence_text: {
        color: 'white',
    },
    popup_close_button_container: {
        display: 'flex',
        justifyContent: 'end',
        marginTop: '10px',
        marginRight: '10px',
    },
    popup_close_button_preview_container: {
        marginTop: '20px',
        marginRight: '5px',
    },
    popup_attachment_preview: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        width: '100%',
        maxWidth: '100%',
        margin: '0px',
    },
    popup_attachment_content_container: {
        backgroundColor: 'black',
        height: '60px',
    },
    popup_attachment_content: {
        display: 'flex',
        height: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    popup_attachment_preview_bar_container: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '15px',
        paddingLeft: '10px',
    },
    popup_attachment_preview_filename: {
        color: 'white',
        fontSize: '14px',
        wordSpacing: '0px',
    },
    popup_attachment_preview_size: {
        fontSize: '12px',
        marginTop: '2px',
    },
    popup_attachment_preview_icon: {
        height: '20px',
        width: '20px',
        color: 'white',
    },
    popup_attachment_no_img: {
        height: '80px',
        width: '80px',
        color: 'gray',
    },
    popup_attachment_preview_download_icon: {
        height: '18px',
        width: '18px',
        color: 'white',
    },
    popup_attachment_preview_icon_no_image: {
        height: '150px',
        width: '150px',
        color: 'white',
        secondaryColor: 'gray',
    },
    popup_attachment_preview_file_size_no_image: {
        color: 'white',
        fontSize: '14px',
        wordSpacing: '0px',
        textAlign: 'center',
        marginTop: '20px',
    },
    popup_attachment_preview_close_icon: {
        fontSize: '40px',
        color: 'white',
    },
    popup_attachment_preview_download_button_container: {
        paddingRight: '15px',
    },
    popup_attachment_preview_image_container: {
        display: 'flex',
        justifyContent: 'center',
    },
    popup_attachment_preview_image: {
        width: '170px',
        height: '260px',
    },
    popup_exam_or_holiday_container: {
        marginTop: '20px',
        marginBottom: '10px',
    },
    popup_exam_or_holiday_reason: {
        marginLeft: '70px',
        color: '#2C5871',
        fontSize: '1.2em',
    },
    popup_exam_or_holiday_content: {
        marginBottom: '50px',
    },
    popup_exam_or_holiday_students_container: {
        marginBottom: '20px',
        marginLeft: '60px',
    },
    popup_close_button: {
        fontSize: '20px',
    },
    edit_popup_close_button: {
        fontSize: '20px',
        color: 'white',
    },
    popup_text_container: {
        marginBottom: '10px',
    },
    popup_text: {
        fontWeight: '500',
    },
    popup_text_description: {
        fontWeight: '300',
    },
    holiday_popup_container: {
        marginTop: '20px',
        marginBottom: '10px',
    },
    holiday_popup_reason: {
        marginLeft: '70px',
        marginBottom: '30px',
        color: '#2C5871',
        fontSize: '1.2em',
    },
    student_names_container: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '20px',
        maxWidth: '145px',
        paddingLeft: '20px',
        marginTop: '20px',
    },
    student_initial: {
        width: '55px',
        height: '55px',
        minWidth: '55px',
        borderRadius: '50%',
        fontSize: '1.5em',
        lineHeight: '54px',
        color: 'white',
        textAlign: 'center',
        marginTop: '-4px',
    },
    student_names: {
        fontSize: '1.2em',
        color: '#2C5871',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    student_org_name: {
        fontSize: '0.7em',
        lineHeight: '1.5em',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        marginTop: '-2px',
    },
    divider_container: {
        marginTop: '10px',
        marginBottom: '20px',
    },
    pop_up_data_container: {
        paddingLeft: '90px',
    },
    buttons_container: {
        display: 'flex',
        justifyContent: 'center',
        gap: '15px',
        marginBottom: '15px',
        marginTop: '25px',
    },
    button: {
        width: '138px',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '2px',
        fontWeight: '300',
        fontSize: '1rem',
    },
    attachment_container: {
        display: 'flex',
        justifyContent: 'space-between',
        maxWidth: '175px',
        marginTop: '5px',
    },
    attachment_name: {
        fontSize: '13px',
    },
    attachment_size: {
        fontSize: '11px',
    },
    edit_popup_container: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.primary.main,
        minHeight: '69px',
        alignItems: 'center',
    },
    edit_popup_student_name: {
        color: 'white',
        fontSize: '1.3em',
        fontWeight: '300',
        textAlign: 'center',
    },
    edit_popup_form_fields_container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
        margin: '20px',
        justifyItems: 'center',
    },
    edit_popup_select_field: {
        width: '250px',
        marginTop: '1em',
        color: '#2C5871',
        fontWeight: '500',
    },
    edit_popup_field_label: {
        color: "black",
        opacity: "0.7",
    },
    edit_popup_calendar_field_label: {
        marginLeft: '25px',
        marginBottom: '10px',
    },
    edit_popup_timepicker_field_label: {
        marginBottom: '-15px',
    },
    edit_popup_timepicker_field_container: {
        marginTop: '29px',
    },
    edit_popup_calendar_field_container: {
        marginTop: '30px',
        width: '100%',
    },
    edit_popup_calendar_text_field: {
        maxWidth: '255px',
        width: '100%',
        marginLeft: '25px',
    },
    edit_popup_calendar_date_text: {
        color: '#2C5871',
        fontWeight: '500',
    },
    edit_popup_select_item: {
        fontSize: '14px',
        lineHeight: '24px',
    },
    edit_popup_attachments_title_container: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '-5px',
        gap: '5px',
    },
    edit_popup_add_attachments_button_text: {
        marginTop: '5px',
        color: '#999',
        fontSize: '14px',
    },
    edit_popup_add_attachments_button_text_active: {
        color: theme.palette.primary.main,
    },
    edit_popup_add_attachments_button_container: {
        backgroundColor: '#CCCCCC',
        borderRadius: '50%',
        height: '30px',
        width: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    edit_popup_add_attachments_button_container_active: {
        backgroundColor: '#33366A',
    },
    edit_popup_add_attachments_button_icon: {
        color: 'white',
        transform: 'rotate(45deg)',
        height: '20px',
        width: '20px',
    },
    edit_popup_comments_and_attachment_section: {
        margin: '45px',
    },
    edit_popup_comments_and_attachment_header: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '30px',
        marginBottom: '20px',
    },
    edit_popup_attachments_container: {
        marginTop: '25px',
    },
    edit_popup_attachments_details_container: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    edit_popup_attachments_details_preview_img: { cursor: 'pointer', width: '100%', height: '100%' },
    edit_popup_attachments_metadata_container: {
        marginLeft: '20px',
    },
    edit_popup_attachment_clear_button: {
        height: '20px',
        width: '20px',
        color: theme.palette.primary.main,
        fontWeight: '700',
        marginRight: '4px',
        "&.MuiTouchRipple-root": {
            top: "3px"
        }
    },
    edit_popup_comments_text_field: {
        'input[type="text"]': {
            color: '#2C5871',
            fontWeight: '300',
            fontSize: '16px',
        },
        '& .MuiInput-underline': {
            // Remove the ripple effect on text field border
            '&:after': {
                borderBottom: 'initial',
            },
        },
    },
    time_picker_container: {
        height: '100%',
        marginTop: '25px',
    },
    time_picker_select_container: {
        display: 'flex',
        justifyContent: 'space-between',
        maxWidth: '70%',
    },
    time_picker_column: {
        marginTop: '5px',
        marginLeft: '-5px',
    },
    time_picker_divider: {
        backgroundColor: 'darkgray',
        width: '255px',
        marginTop: '0px',
    },
    attachment_preview_image_container: {
        height: '100vh',
    },
    generic_error_container: {
        backgroundColor: '#FF7978',
        height: '100%',
        maxHeight: '77px',
        padding: '15px',
        margin: '35px',
        borderRadius: '3px',
    },
    generic_error_title_container: {
        display: 'flex',
        justifyContent: 'center',
    },
    generic_error_title: {
        fontWeight: '500',
        color: 'white',
        lineHeight: '20px',
        fontSize: '14px',
    },
    generic_error_message_container: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',
    },
    generic_error_message: {
        color: 'white',
        fontWeight: '200',
        fontSize: '14px',
        lineHeight: '20px',
    },
    delete_absence_container: {
        marginBottom: '40px',
    },
    holiday_or_exam__title_container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    delete_absence_title: {
        fontSize: '16px',
        marginLeft: '60px',
        color: '#444444',
        fontWeight: '400',
    },
    delete_absence_message: {
        fontWeight: '200',
        marginLeft: '60px',
        marginRight: '60px',
        color: '#999999',
        fontSize: '14px',
        marginTop: '30px',
    },
    delete_absence_cancel: {
        backgroundColor: 'white',
        border: `1px solid ${theme.palette.primary.main}`,
        '&:hover': { backgroundColor: 'white', color: theme.palette.primary.main },
        color: theme.palette.primary.main,
    },
};

export default styles;
