import React from 'react';
import { Chip, Link, Tooltip } from '@mui/material';
import { utils } from 'comm-recipientapp-shared';
import { getPersonName, getPersonInitials, getPersonBackgroundColor } from '../../utils';

export default function InitialsAvatar({ person, totalPeople, openMessageDetail, count }) {
    const strings = utils.useStrings();
    const MAX_INITIALS_SHOW = 3;
    const fullName = getPersonName(person);
    const initials = getPersonInitials(person);
    const backgroundColor = count > MAX_INITIALS_SHOW ? '#666' : getPersonBackgroundColor(person);
    const style = { marginRight: '3px', marginBottom: '3px', backgroundColor: { backgroundColor }, color: '#ffffff', fontWeight: 'bold'};
    const label = count > MAX_INITIALS_SHOW ? '. . .' : initials;
    const toolTipLabel = count > MAX_INITIALS_SHOW ? `(${totalPeople - MAX_INITIALS_SHOW + 1}) ${strings.MORE_RECIPIENTS}` : fullName;
    const isShow = count < MAX_INITIALS_SHOW || count === totalPeople;

    const handleClick = () => {
        openMessageDetail(person.id);
    };

    return (
        <>
            {isShow && (
                <Link data-testid="recipientTestId" onClick={handleClick} underline="none">
                    <Tooltip title={toolTipLabel}>
                        <Chip data-testid="initialsTestId" sx={style} style={{ cursor: 'pointer' }} label={label} />
                    </Tooltip>
                </Link>
            )}
        </>
    );
}
