import { palette } from 'comm-recipientapp-shared';

const styles = {
    organizations: {
        color: palette.grey['700'],
        fontSize: '12px',
    },
    avatar: {
        marginRight: '15px',
    },
    student: {
        fontWeight: '400',
        fontSize: '18px',
    },
    header_container: {
        display: 'flex',
        alignItems: 'center',
    },
    contact_container: {
        background: 'white',
        padding: '16px',
        borderRadius: '4px',
        boxShadow:
            '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    },
};

export default styles;
