import React, { useEffect } from 'react';
import newRelic from './newrelic';
import { env } from 'comm-recipientapp-shared';

function NewRelic () {
    const newRelicProps = {
        accountID: env.WEB_NEW_RELIC_ACCOUNT_ID,
        trustKey: env.WEB_NEW_RELIC_TRUST_KEY,
        agentID: env.WEB_NEW_RELIC_AGENT_ID,
        licenseKey: env.WEB_NEW_RELIC_LICENSE_KEY,
        applicationID: env.WEB_NEW_RELIC_APP_ID,
    }

    useEffect(() => {
        newRelic(newRelicProps);
    }, [])

    return null;
}

export default NewRelic;
