import React from 'react';

import { ListItem, ListItemText } from '@mui/material';

import Styles from './styles';

export default function Emptycomponent({ message }) {

    return (
        <ListItem>
            <ListItemText primary={message} sx={Styles.EmptycomponentListItem} />
        </ListItem>
    );
}
