import React from 'react';

export default function NewInbox(props) {
    return (
        <svg data-icon="newInbox" xmlns="http://www.w3.org/2000/svg" width={36} height={37} fill="none" {...props}>
            <path
                d="M8.382 24.5a1.498 1.498 0 0 1 1.05.432l3.636 3.636a1.478 1.478 0 0 0 1.05.432h7.764a1.478 1.478 0 0 0 1.05-.432l3.636-3.636c.138-.138.302-.248.482-.32.182-.074.374-.112.568-.112H34.5v-21c0-.398-.158-.78-.44-1.06A1.494 1.494 0 0 0 33 2H3c-.398 0-.78.158-1.06.44-.282.28-.44.662-.44 1.06v21h6.882Z"
                fill="#A1A4F1"
            />
            <path d="M2 34.5v-10h7l5 5h8l5-5h7v10H2Z" fill="#fff" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33 .5H3c-1.656 0-3 1.344-3 3v30c0 1.656 1.344 3 3 3h30c1.656 0 3-1.344 3-3v-30c0-1.656-1.344-3-3-3Zm-30 3h30v30H3v-30Z"
                fill="#33366A"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.878 27.5h-7.756l-3.63-3.63-.002-.002A2.98 2.98 0 0 0 8.376 23H1.5a1.5 1.5 0 0 0 0 3h6.878l3.63 3.63.002.002a2.98 2.98 0 0 0 2.114.868h7.758a2.98 2.98 0 0 0 2.108-.868L27.622 26H34.5a1.5 1.5 0 0 0 0-3h-6.882a2.98 2.98 0 0 0-2.108.868L21.878 27.5Z"
                fill="#33366A"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m16.5 18.128-3.69-3.688a1.498 1.498 0 1 0-2.12 2.12l6.25 6.25a1.498 1.498 0 0 0 2.12 0l6.25-6.25a1.498 1.498 0 1 0-2.12-2.12l-3.69 3.688V9.5a1.5 1.5 0 0 0-3 0v8.628Z"
                fill="#33366A"
            />
        </svg>
    );
}
