/* eslint-disable prettier/prettier */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line prettier/prettier
import {
    ListItemButton,
    ListItemIcon,
    Avatar,
    ListItemText,
    Typography,
    IconButton,
} from "@mui/material";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { setCurrentChat, setHasUnreadMessages, setUserActive } from 'comm-recipientapp-shared/src/store/chat/chatSlice';
import { utils, hooks } from 'comm-recipientapp-shared';
import PopoverModal from './PopoverModal';
import ActivityBadge from '../../utils/ActivityBadge';
import styles from '../styles';

function GroupListItem (props) {
    const {
        conversationItem,
        setShowPanel,
        personId,
        chatUser,
        disabled
    } = props;

    const { 
        type, 
        id, 
        customerid, 
        name,
        hasUnreadMessages,
    } = conversationItem;

    const { stringAvatar, getConsistentColorFromString } = utils.avatar;
    const strings = utils.useStrings();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);
    const popover = open ? 'simple-popover' : undefined;

    const dispatch = useDispatch();
    const {currentChat} = useSelector(state => state.chat);

    const handlePopOver = event => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handlePopOverClose = event => {
        setAnchorEl(null);
        event.stopPropagation();
    };

    const handleclick = () => {
        dispatch(setHasUnreadMessages({
            conversationId: conversationItem.id,
            hasUnreadMessages: false
        }));
        dispatch(setCurrentChat({ ...conversationItem }));
        dispatch(setUserActive(true));
        setShowPanel('chat');
    };

    return (
        <ListItemButton
            aria-label={name}
            onClick={handleclick}
            disabled={disabled}
            sx={{
                ...(hasUnreadMessages ? { pl: 0 } : { pl: 2 }),
                ...(currentChat.id === conversationItem.id? styles.bgColorForSelectedConversation: {}),
            }}
        >
            {hasUnreadMessages && <ActivityBadge/>}
            <ListItemIcon aria-hidden>
                <Avatar aria-hidden sx={{ ...styles.sideBarListItem, bgcolor: `${getConsistentColorFromString(name)}` }} variant="rounded">
                    {stringAvatar(name).children}
                </Avatar>
            </ListItemIcon>
            <ListItemText aria-hidden primary={<Typography sx={styles.ListItemButtonTypography}>{name}</Typography>} />
            <IconButton onClick={handlePopOver} aria-label={strings.CONVERSATION_OPTIONS}>
                <MoreHorizIcon fontSize="small"  data-testid="ellipsis" />
            </IconButton>
            <PopoverModal 
                aria-hidden
                type={type}
                conversationId={id} 
                customerid={customerid} 
                personid={personId}
                chatUser={chatUser}
                data-popover={popover} 
                open={open} 
                anchorEl={anchorEl} 
                onClose={handlePopOverClose} 
                currentChat={currentChat}
            />
        </ListItemButton>
    );
}

export default GroupListItem;
