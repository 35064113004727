const styles = {
    container: {
        marginTop: '80px'
    },
    containerCentered: {
        display: 'flex',
        justifyContent: 'center'
    },
    title: {
        color: '#2C5871',
        fontFamily: 'Roboto',
        fontSize: '25px',
        fontWeight: 500,
        marginBottom: 4,
    },
    form: {
        marginTop: '40px'
    },
    submitButtonContainer : {
        display: 'flex',
        justifyContent: 'center'
    },
    submitButton: {
        margin: '30px auto',
        width: '30%'
    },
    link: {
        textAlign: 'center',
    },
    return_to_login: {
        fontWeight: '500',
    },
    duplicatedRequest: { fontSize: '15px', fontWeight: 'bold' },
};

export default styles;
