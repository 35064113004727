/* eslint-disable prettier/prettier */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    Box,
    Container,
    Typography,
    Stack,
    Button,
} from '@mui/material';
import {logout} from "comm-recipientapp-shared/src/store/user/userSlice";
import { useStrings } from 'comm-recipientapp-shared/src/utils';
import Layout from '../../Layout';
import BackButton from '../../Shared/BackButton';
import styles from './styles';



export default function NotFoundRecords() {
    const strings = useStrings();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const goBackTo  = location.state?.goBackTo || -1;

    const handleLogoutButton = () => {
        dispatch(logout());
        navigate('/login');
    };

    const handleClickBackButton = () => {
        navigate(goBackTo);
    }

    return (
        <Layout>
            <Container maxWidth="xl" disableGutters sx={styles.mainContainer}>
                <Box sx={styles.mainBox}>
                    <BackButton handleClickBackButton={handleClickBackButton}/>
                    <Stack sx={styles.stackBody}  alignItems="center" >
                        <Typography sx={styles.typographyTitle}>{strings.PLEASE_CONTACT_THE_SCHOOL}</Typography>
                        <Typography sx={styles.typographyBody}>
                            {strings.PLEASE_CONTACT_THE_SCHOOL_FOR_EMAIL}
                        </Typography>
                        <Button
                            fullWidth
                            variant="contained"
                            sx={styles.basicButton}
                            onClick={handleLogoutButton}
                        >
                            <Typography sx={styles.buttonLabel}>{strings.LOG_OUT}</Typography>
                        </Button>
                    </Stack>
                </Box>
            </Container>
        </Layout>
    );
}
