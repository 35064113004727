import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import absenceReducer from './absence/absenceSlice';
import authReducer from './auth/authSlice';
import CustomersReducers from './customers/customersSlice';
import preferenceReducer from './preferences/preferenceSlide';
import userReducer from './user/userSlice';
import chatReducer from './chat/chatSlice';
import inboxReducer from './inbox/inboxSlice';
import initializeReducer from './initialize/initializeSlice';
import consentPopupReducer from './consent/consentPopupSlice';
import env from '../env';

const isMobile = env.IS_MOBILE !== 'false';

// setStore sets the storage type for mobile(AsyncStorage) and web(redux-persist storage)
const storage = isMobile ? require('@react-native-async-storage/async-storage').default : require('redux-persist/lib/storage').default;

const persistConfig = {
    key: 'root-state',
    storage,
    blacklist: ['auth', 'chat', 'inbox', 'absence', 'consent', 'initialize'],
};

const initializePersistConfig = {
    key: 'initialize',
    storage,
    blacklist: ['inlineMode'],
};

const authPersistConfig = {
    key: 'auth',
    storage,
    blacklist: ['isLoading', 'isError', 'isSuccess', 'errorMessage'],
};

const absencePersistConfig = {
    key: 'absence',
    storage,
    whitelist: ['enabledStudents'],
};

const chatPersistConfig = {
    key: 'chat',
    storage,
    whitelist: ['currentChatUser'],
};

const reducers = combineReducers({
    absence: isMobile ? persistReducer(absencePersistConfig, absenceReducer) : absenceReducer,
    auth: persistReducer(authPersistConfig, authReducer),
    user: userReducer,
    preferences: preferenceReducer,
    customers: CustomersReducers,
    chat: isMobile ? persistReducer(chatPersistConfig, chatReducer) : chatReducer,
    inbox: inboxReducer,
    initialize: persistReducer(initializePersistConfig, initializeReducer),
    consent: consentPopupReducer,
});

const persistedReducers = persistReducer(persistConfig, reducers);

export const configureStoreWithMiddlewares = (initialState = {}) =>
    configureStore({
        reducer: persistedReducers,
        devTools: process.env.NODE_ENV === 'development',
        middleware: [thunk],
        preloadedState: initialState,
    });

export const store = configureStoreWithMiddlewares();

export const persistor = persistStore(store);
