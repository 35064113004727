import React, { useState, Fragment, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import Avatar from '../../Avatar';
import styles from './styles';

function Student({ dependent, customers }) {
    const [expanded, setExpanded] = useState(false);
    const customer = customers.find(customer => customer.id === dependent.customerId);
    return (
        <Box data-testid='student' sx={styles.contact_container}>
            <Box sx={styles.header_container}>
                <Avatar
                    aria-hidden={true}
                    sx={styles.avatar}
                    stringForColor={`${dependent.personId}${dependent.firstName}${dependent.lastName}`}
                    label={`${dependent.firstName} ${dependent.lastName}`}
                />
                <Box
                    onClick={
                        dependent.organizations.length > 1 ? () => setExpanded(prev => !prev) : null
                    }
                >
                    <Typography sx={styles.student}>
                        {`${dependent.firstName} ${dependent.lastName}`}
                    </Typography>
                    {expanded &&
                        dependent.organizations.map(organization => (
                            <Typography key={organization.id} sx={styles.organizations}>
                                {`${organization.name} | ${customer.name}`}
                            </Typography>
                        ))}
                    {!expanded && (
                        <Fragment>
                            <Typography sx={styles.organizations}>
                                {dependent.organizations[0]
                                    ? `${dependent.organizations[0].name} |`
                                    : ''}{' '}
                                {customer.name}
                            </Typography>
                            {dependent.organizations.length > 1 && (
                                <Typography sx={styles.organizations}>...</Typography>
                            )}
                        </Fragment>
                    )}
                </Box>
            </Box>
        </Box>
    );
}

export default Student;
