import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, Chip, Tabs, Tab, Button} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import SmsIcon from '@mui/icons-material/Sms';
import { utils } from 'comm-recipientapp-shared';
import { getConsistentColorFromString } from 'comm-recipientapp-shared/src/utils/avatar';
import RecipientAvatar from '../RecipientAvatar';
import useWindowWidth from "../../../../hooks/useWindowWidth";
import styles from './styles';

export default function MainHead({ initialTabValue, mainTabHandler, selectedPersonId, setSelectedPersonId }) {
    const windowWidth = useWindowWidth();
    const strings = utils.useStrings();
    const { currentMessageDetails } = useSelector(state => state.inbox);
    const { tabsOrder } = currentMessageDetails;

    const [tabValue, setTabValue] = useState(initialTabValue);

    const handleTabValue = (event, newTabValue) => {
        setTabValue(newTabValue);
        mainTabHandler(newTabValue);
    };

    const handleSelectPerson = person => {
        setSelectedPersonId(person.id);
    };

    return (
        <Box sx={styles.mainHead}>
            <Typography sx={styles.mainTitle} tabIndex={0}>
                {currentMessageDetails.title}
            </Typography>
            <Box sx={styles.chip_and_sender_container} tabIndex={0}>
                <Chip
                    sx={currentMessageDetails.priority === 1 ? styles.chip_emergency : styles.chip}
                    label={currentMessageDetails.messageType}
                    variant="filled"
                    size="small"
                />
                <Typography sx={styles.normalFont}>{strings.FROM}</Typography>{' '}
                <Box sx={styles.initials_container}>
                    <Box sx={{ ...styles.initials_box, backgroundColor: getConsistentColorFromString(currentMessageDetails.initials) }}>
                        <Typography sx={styles.initials}>{currentMessageDetails.initials}</Typography>
                    </Box>
                </Box>
                <Typography sx={styles.sender}>{currentMessageDetails.sender}</Typography>{' '}
                <Typography sx={styles.normalFont}>{strings.FROM} </Typography>{' '}
                <Typography sx={styles.sender}>{currentMessageDetails.customerName}</Typography>
            </Box>

            <Box sx={styles.initials_chip_container}>
                {currentMessageDetails.people.map((person, i) => (
                    <RecipientAvatar person={person} selectedPersonId={selectedPersonId} handleSelectPerson={handleSelectPerson} key={i} />
                ))}
            </Box>

            <Tabs 
                centered 
                sx={styles.mainTabs} 
                variant="fullWidth"
                value={tabValue} 
                onChange={handleTabValue} 
                data-testid="tabscontent">
                {currentMessageDetails?.emailContent && (
                    <Tab
                        tabIndex={0}
                        sx={tabValue === tabsOrder.email ? styles.active_tabStyle : styles.default_tabStyle}
                        label={strings.EMAIL}
                        icon={<EmailIcon />}
                        iconPosition="start"
                    />
                )}
                {currentMessageDetails?.phoneContent && (
                    <Tab
                        tabIndex={0}
                        sx={tabValue === tabsOrder.phone ? styles.active_tabStyle : styles.default_tabStyle}
                        label={strings.VOICE}
                        icon={<PhoneIcon />}
                        iconPosition="start"
                    />
                )}
                {currentMessageDetails?.smsContent && (
                    <Tab
                        tabIndex={0}
                        sx={tabValue === tabsOrder.sms ? styles.active_tabStyle : styles.default_tabStyle}
                        label={strings.SMS}
                        icon={<SmsIcon />}
                        iconPosition="start"
                    />
                )}
            </Tabs>
        </Box>
    );
}
