/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Autocomplete,
    Box,
    Card,
    CardHeader,
    CardContent,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    Modal,
    TextField,
    Typography,
    ButtonGroup,
    Button,
    CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { utils, hooks } from 'comm-recipientapp-shared';
import { getTargets, createPrivateConversation, activateConversation } from 'comm-recipientapp-shared/src/api/chat';
import { setCurrentChat } from 'comm-recipientapp-shared/src/store/chat/chatSlice';
import ContactListItem from './ContactListItem';
import styles from './styles';

export default function NewDirectMessageModal({ open, closeModal }) {
    const strings = utils.useStrings();
    const dispatch = useDispatch();

    const { getMemberOfByChatUser } = hooks.chat.useChat();

    const { currentChatUser } = useSelector(state => state.chat);
    const [selectedContact, setSelectedContact] = React.useState(null);
    const [disableStartChatButton, setDisableStartChatButton] = React.useState(true);
    const [startLoading, setStartLoading] = React.useState(false);
    const [targetContactList, setTargetContactList] = React.useState([]);

    const selectContact = contact => {
        setSelectedContact(contact);
        setDisableStartChatButton(!contact);
    };

    const getTargetContacts = async () => {
        try {
            const { customerid, personid } = currentChatUser;
            const { status, data } = await getTargets(customerid, personid);
            if (status === 200) {
                setTargetContactList(data?.result?.items || []);
            }
        } catch (e) {
            setTargetContactList([]);
        }
    };

    const handleCloseModal = (_, reason) => {
        if (reason === 'backdropClick') return; // Closing modal just on event over close button.

        setStartLoading(false);
        setSelectedContact(null);
        setDisableStartChatButton(true);
        closeModal();
    };

    const handleStartCoversation = async () => {
        setDisableStartChatButton(true);
        setStartLoading(true);
        try {
            const { customerid, personid } = currentChatUser;
            const { status, data } = await createPrivateConversation({
                customerid,
                sourcepersonid: personid,
                destuserid: selectedContact.id,
            });
            if (status === 200) {
                const activateResult = await activateConversation(data.result.id, {
                    customerid,
                    personid,
                    activate: true,
                });
                if (activateResult.status === 200) {
                    await getMemberOfByChatUser(currentChatUser);
                    dispatch(setCurrentChat({ ...data.result }));
                }
            }
        } catch (error) {
            /* empty */
        } finally {
            selectContact(null);
            setStartLoading(false);
            handleCloseModal();
        }
    };

    useEffect(() => {
        if (currentChatUser) {
            getTargetContacts();
        }
    }, [currentChatUser]);

    // aria-label={strings.CHAT_ARIA_LABEL_HEADING_TITLE}
    // aria-label={strings.CHAT_ARIA_LABEL_HEADING_DESCRIPTION}
    

    return (
        <Modal aria-label={strings.NEW_DIRECT_MESSAGE} open={open} onClose={handleCloseModal}>
            <Card sx={styles.rootCard}>
                <Grid container justifyContent="flex-end" sx={styles.closeButtonGrid}>
                    <IconButton onClick={handleCloseModal} size="small" sx={styles.closeButton} aria-label={strings.CLOSE}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
                <CardHeader
                    sx={styles.cardHeader}
                    title={
                        <Typography tabIndex={0} sx={styles.headerTitle}>
                            {strings.NEW_DIRECT_MESSAGE}
                        </Typography>
                    }
                    subheader={
                        <Box sx={styles.searchContainer}>
                            <Typography tabIndex={0} sx={styles.headerSubtitle}>
                                {strings.SELECT_YOUR_MESSAGE_RECIPIENT}
                            </Typography>
                            <Box aria-label={strings.SEARCH_FOR_A_CONTACT} role="combobox">
                                <Autocomplete
                                    options={targetContactList}
                                    getOptionLabel={option => `${option.name}`}
                                    onChange={(event, contact) => {
                                        selectContact(contact);
                                    }}
                                    renderInput={params => 
                                        <TextField 
                                            {...params} 
                                            label={strings.SEARCH_FOR_A_CONTACT} 
                                        />
                                    }
                                    disablePortal
                                    fullWidth
                                />
                            </Box>
                            {selectedContact && (
                                <List sx={styles.contactList}>
                                    <ListItem sx={styles.listItem} key={selectedContact.id}>
                                        <ListItemButton disabled={startLoading}>
                                            <ContactListItem sx={styles.contactListItem} contact={selectedContact} />
                                            <IconButton
                                                sx={styles.closeButton}
                                                onClick={() => {
                                                    selectContact(null);
                                                }}
                                                size="small"
                                            >
                                                <CloseIcon sx={styles.closeIcon} />
                                            </IconButton>
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                            )}
                        </Box>
                    }
                />
                <CardContent 
                    aria-label={strings.CHAT_ARIA_LABEL_AVAILABLE_CONTACTS}
                    sx={styles.cardContent}>
                    <List aria-label={strings.CHAT_ARIA_LABEL_CONTACTS_LIST}>
                        <Typography>{strings.CONTACTS}</Typography>
                        {targetContactList.map(contact => (
                            <ListItem sx={styles.listItem} key={contact.id}>
                                <ListItemButton
                                    onClick={() => {selectContact(contact);}}
                                    disabled={startLoading}
                                >
                                    <ContactListItem sx={styles.contactListItem} contact={contact} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </CardContent>
                <Box 
                    aria-label={strings.CHAT_ARIA_LABEL_FOOTER_CONTROLS}
                    sx={styles.BoxButtonGroup}>
                    <ButtonGroup disableElevation sx={styles.buttonGroup} aria-label="buttons">
                        <Button 
                            aria-label={strings.CANCEL}
                            onClick={handleCloseModal} 
                            sx={styles.cancelButton}>
                            {strings.CANCEL}
                        </Button>
                        <Box sx={styles.ButttonBox} aria-label={strings.START_CHAT} role="button">
                            <Button
                                sx={styles.startChatButton}
                                disabled={disableStartChatButton}
                                onClick={handleStartCoversation}
                                variant="contained"
                            >
                                {strings.START_CHAT}
                            </Button>
                            {startLoading && <CircularProgress size={24} sx={styles.CircularProgress} />}
                        </Box>
                    </ButtonGroup>
                </Box>
            </Card>
        </Modal>
    );
}
