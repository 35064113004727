/* eslint-disable operator-linebreak */
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { utils } from 'comm-recipientapp-shared';
import { handleEnterKeyPress } from 'comm-recipientapp-shared/src/utils/helpers';
import { usePreferencesSettings } from '../../hooks/usePreferencesSettings';
import { EMERGENCY_ID, ATTENDANCE_ID } from './index';
import styles from './styles';

function PhoneCard({ item, activePhone, handleUpdatePhone, showError, emergencyPhones, attendancePhones, ...props }) {
    const strings = utils.useStrings();
    const activeSchool = useSelector(state => state.preferences.activeSchool);

    const { requireEmergencyPhone, requireHighPriorityPhone } = usePreferencesSettings(activeSchool);

    const currentPhone = useMemo(() => {
        return props.preferences.phones?.find(phone => phone.phone === activePhone);
    }, [activePhone, props.preferences]);

    const callMeChecked = useMemo(() => {
        return currentPhone?.notificationTypes?.includes(item.id);
    }, [currentPhone, item]);

    const currentSms = useMemo(() => {
        return props.preferences.smses?.find(sms => sms.sms === activePhone);
    }, [activePhone, props.preferences]);

    const smsChecked = currentSms?.notificationTypes?.includes(item.id);

    const callMeDisabled = useMemo(() => {
        return utils.helpers.isCallMeDisabled(activeSchool, item, currentPhone);
    }, [activeSchool, item, currentPhone]);

    const showPhone = useMemo(() => {
        return utils.helpers.showPhone(props.type, activeSchool, activePhone);
    }, [props.type, activeSchool, activePhone]);

    const showSMS = useMemo(() => {
        return utils.helpers.showSMS(props.type, activeSchool, activePhone, item);
    }, [props.type, activeSchool, activePhone, item]);

    const checkCallMe = id => {
        if (callMeDisabled) return;

        handleUpdatePhone(id);
    };

    const handlePhoneRadioClick = () => {
        if (callMeDisabled) return;
        // if at least one emergency phone is required and the user is trying to uncheck an emergency notification and it has no other emergency numbers, show error, same for attandance
        if (
            item.priority === EMERGENCY_ID &&
            requireEmergencyPhone &&
            emergencyPhones.length === 1 &&
            callMeChecked &&
            emergencyPhones[0].phone === activePhone
        ) {
            showError(true);
            return;
        }

        if (
            item.priority === ATTENDANCE_ID &&
            requireHighPriorityPhone &&
            attendancePhones.length === 1 &&
            callMeChecked &&
            attendancePhones[0].phone === activePhone
        ) {
            showError(true);
            return;
        }

        checkCallMe(item.id);
    };

    return (
        <Card sx={styles.message_card} tabIndex="0">
            <Box sx={styles.message_card_bg}>
                <Typography sx={styles.message_card_title} variant="h5">
                    {item.name}
                </Typography>
            </Box>
            <Box sx={styles.message_card_bottom}>
                <RadioGroup name="radio-buttons-group">
                    {showPhone && (
                        <FormControlLabel
                            checked={callMeChecked}
                            aria-label={`${strings.CALL_ME}, ${callMeChecked ? strings.CHECKED : strings.UNCHECKED}`}
                            control={<Radio checkedIcon={<CheckBoxIcon />} icon={<CheckBoxOutlineBlankIcon />} aria-hidden />}
                            disabled={callMeDisabled}
                            label={<Typography aria-hidden>{strings.CALL_ME}</Typography>}
                            onClick={handlePhoneRadioClick}
                            onKeyDown={handleEnterKeyPress(() => handlePhoneRadioClick())}
                            data-testid={`check_callme_${props.index}`}
                            value="call"
                            tabIndex="0"
                        />
                    )}
                    {item.type === utils.constants.SURVEY ? (
                        <Typography sx={styles.survey_title} variant="subtitle2">
                            {strings.SURVEYS_ARE_NOT_DISPLAYED_IN_SCHOOLMESSENGER_MESSAGES}
                        </Typography>
                    ) : (
                        showSMS && (
                            <FormControlLabel
                                value="sms"
                                control={<Radio checkedIcon={<CheckBoxIcon />} icon={<CheckBoxOutlineBlankIcon />} aria-hidden />}
                                label={<Typography aria-hidden>{strings.SEND_TEXT}</Typography>}
                                checked={smsChecked}
                                aria-label={`${strings.SEND_TEXT}, ${smsChecked ? strings.CHECKED : strings.UNCHECKED}`}
                                onClick={() => props.handleUpdateSMS(item.id)}
                                onKeyDown={handleEnterKeyPress(() => props.handleUpdateSMS(item.id))}
                                data-testid={`check_sms_${props.index}`}
                                tabIndex="0"
                            />
                        )
                    )}
                </RadioGroup>
            </Box>
        </Card>
    );
}

export default PhoneCard;
