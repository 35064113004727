/* eslint-disable prettier/prettier */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Container from '@mui/material/Container';
import { Box } from '@mui/material';
import { actions, utils } from 'comm-recipientapp-shared';
import { handlePhonePriorityEnforcement } from 'comm-recipientapp-shared/src/utils/preferencesUtils';
import PreferecesSVG from 'comm-recipientapp-shared/src/assets/preferences.svg';
import Title from '../Shared/NewUI/Title';
import ContactInformation from './ContactInformation';
import Layout from '../Layout';
import MessagePreferences from './Messages';
import Schools from './Schools';
import { usePreferencesSettings } from './hooks/usePreferencesSettings';
import styles from './styles';

function Preferences() {
    const dispatch = useDispatch();
    const schools = useSelector(state => state.preferences.schools);
    const activeSchool = useSelector(state => state.preferences.activeSchool);
    const strings = utils.useStrings();
    const { canAddDeleteManualDestinations, hasContentError } = usePreferencesSettings(activeSchool);

    useEffect(() => {
        const fetchData = async () => {
            const { data } = await dispatch(actions.preferences.getData());

            handlePhonePriorityEnforcement(data, 'web');
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (schools.length > 0) {
            const schoolUpdatedData = schools.find(school => school.id === activeSchool.id) || schools[0];
            dispatch(actions.preferences.setActiveSchool(schoolUpdatedData));
        }
    }, [schools]);

    const handleChangeSchool = school => dispatch(actions.preferences.setActiveSchool(school));

    return (
        <Layout>
            <Container disableGutters maxWidth="lg" sx={styles.content}>
                <Box sx={styles.title_container} component="header">
                    <Box
                        alt={strings.PREFERENCES}
                        component="img"
                        src={PreferecesSVG}
                        sx={styles.icon_title}
                    />
                    <Title variant="h1">{strings.PREFERENCES}</Title>
                </Box>
                <Box component="main">
                    <Schools activeSchool={activeSchool} schools={schools} handleChangeSchool={handleChangeSchool} />
                    <ContactInformation
                        activeSchool={activeSchool}
                        showContent={!hasContentError}
                        showAddMore={canAddDeleteManualDestinations}
                    />
                    <MessagePreferences activeSchool={activeSchool} showContent={!hasContentError} />
                </Box>
            </Container>
        </Layout>
    );
}

export default Preferences;
