import React, { Fragment } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { utils } from 'comm-recipientapp-shared';
import Button from '../../Shared/Button';

import styles from './styles';

function ClassScheduleModal({ open, onClose, enrollment, studentName = 'Bobby' }) {
    const strings = utils.useStrings();

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle sx={styles.header} id="responsive-dialog-title">
                {`${strings.CLASS_SCHEDULE_FOR} ${studentName}`}
            </DialogTitle>
            <DialogContent sx={styles.content}>
                {enrollment.map(item => {
                    //const [user] = item.users;
                    const user = item.users && (item.users.length > 0) ? item.users[0] : null;
                    return (
                        <Fragment key={item.sectionId}>
                            <Box sx={styles.itemContainer}>
                                <Typography sx={styles.itemTitle}>{item.sectionId}</Typography>
                                {user && (
                                    <Box sx={styles.iconBtnContainer}>
                                        {user.phone && (
                                            <Tooltip title={user.phone}>
                                                <IconButton color="primary" aria-label="upload picture" component="a" href={`tel:${user.phone}`}>
                                                    <PhoneIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                        {user.email && (
                                            <Tooltip title={user.email}>
                                                <IconButton color="primary" aria-label="upload picture" component="a" href={`mailto:${user.email}`}>
                                                    <EmailIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </Box>
                                )}
                                <Box sx={styles.info_container}>
                                    {Object.keys(item.details).map(detail => {
                                        if (!item.details[detail]) {
                                            return null;
                                        }
                                        return (
                                            <Typography key={detail} sx={styles.itemInfo}>
                                                {detail}: {item.details[detail]}
                                            </Typography>
                                        );
                                    })}
                                    {item.descriptiveGrade && (
                                        <Typography sx={styles.itemInfo}>
                                            {strings.LAST_REPORTED_CLASSROOM_GRADE}: {item.descriptiveGrade}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                            <Divider />
                        </Fragment>
                    );
                })}
            </DialogContent>
            <Box sx={styles.actionsContainer}>
                <Button sx={styles.ok_button} onClick={onClose}>
                    {strings.OK.toUpperCase()}
                </Button>
            </Box>
        </Dialog>
    );
}

export default ClassScheduleModal;

