import React, { useState, useEffect, useMemo } from 'react';
import Layout from '../Layout';
import { useParams } from 'react-router-dom';
import SmLogoLinks from '../SmLogoLinks';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import AppBlockingIcon from '@mui/icons-material/AppBlocking';
import { utils } from 'comm-recipientapp-shared';
import {
    Box,
    Button,
    Container,
    Grid,
    Typography,
    FormControlLabel,
    Checkbox,
    Divider,
    CircularProgress,
    Icon, 
    Skeleton,
    Fade
} from "@mui/material";

import { 
    getCustomerInfo, 
    getEmailPreferences, 
    updateEmailPreferences,
    blockEmail, 
    getEmailBlocked
} from 'comm-recipientapp-shared/src/api/unsubscribe';

import styles from './styles'

function Unsubscribe () {

    const strings = utils.useStrings();

    const params = useParams();
    
    const [loading, setLoading] = useState(true);
    const [email, setEmail] = useState(null);
    const [customerName, setCustomerName] = useState(null);
    const [infocenterEnabled, setInfocenterEnabled] = useState(null);
    const [preferences, setPreferences] = useState([]);
    const [blockAllTypes, setBlockAllTypes] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [submitResponseCode, setSubmitResponseCode] = useState(null);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [error, setError] = useState(false);

    const errors = {
        'generic': {
            icon: WarningIcon,
            color: "warning",
            text: strings.UNSUBSCRIBE_PREFERENCES_UNAVAILABLE,
        },
        'email-blocked': {
            icon: AppBlockingIcon,
            color: "primary",
            text: strings.UNSUBSCRIBE_EMAIL_BLOCKED,
        }
    }

    const asyncFetchCustomerName = async (customerId) => {
        try {
            const customerData = await getCustomerInfo(customerId);
            return customerData?.data;
        } catch (e) {
            setError(errors['generic']);
            setLoading(false);
            return false;
        }
    }

    const asyncFetchEmailBlocked = async (customerId, email) => {
        try {
            const emailBlockedResponse = await getEmailBlocked(customerId, email); 
            const blocked = emailBlockedResponse.data;
            
            if (blocked === true) {
                setError(errors['email-blocked']);
                return true; 
            } else {
                return false;
            }
              
        } catch (e) {
            setError(errors['generic']); 
            setLoading(false);
            return true;
        }
    }

    const asyncGetEmailPreferences = async (customerId, email) => {
        try {
            const preferencesResponse = await getEmailPreferences(customerId, email);
            setPreferences(preferencesResponse.data);
            return true;
        } catch (e) {
            setError(errors['generic']);
            setLoading(false);
        }
    }

    const asyncFetchAll = async (email) => {
        const blocked = await asyncFetchEmailBlocked(params.customerId, email);
        const fetchedPrefs = await asyncGetEmailPreferences(params.customerId, email);

        if (! blocked && fetchedPrefs) {
            const customerInfo = await asyncFetchCustomerName(params.customerId);
            if (customerInfo) {
                setInfocenterEnabled(customerInfo.isInfoCenterEnabled);
                setCustomerName(customerInfo.name);
            }
        }
        
        setLoading(false);
    };
    
    const decodeEmailAddress = (base64email) => {
        if (base64email) {
            const email = window.atob(base64email);
            setEmail(email);
        }
    }

    useEffect(() => {
        decodeEmailAddress(params.base64email);
    }, []);
    
    useEffect(() => {
        email && asyncFetchAll(email);
    }, [email])

    useEffect(() => {
        if (submitting || loading || error) {
            setSubmitDisabled(true);
        }
        else setSubmitDisabled(false);
    }, [submitting, loading, error])

    const toggleBlockAllTypes = ({target}) => {
        setBlockAllTypes(target.checked);
    }

    const updatePreference = ({target}, id) => {
        const preferencesClone = [...preferences]
        const preference = preferencesClone.find(pref => pref.id === id);
        preference.enabled = target.checked;
        setPreferences(preferencesClone);
    }

    const submitPreferences = async () => {
        let result = null;
        setSubmitting(true);
        try {
            if (blockAllTypes) {
                result = await blockEmail(params.customerId, email);
            }
            else {
                result = await updateEmailPreferences(params.customerId, email, preferences);
            }

            setSubmitResponseCode(result.status);
        } catch (e) {
            setError(errors['generic'])    
        }
    };

    return (
        <Layout>
            <Container sx={styles.container}>
                <Grid container>
                    <Box sx={styles.userInfoContainer}>
                        <Box sx={styles.userInfo}>
                            <Typography color="primary" sx={styles.userInfo.header}>
                               {strings.UNSUBSCRIBE_UPDATE_FOR}:
                            </Typography>
                            <Typography sx={styles.userInfo.info}>
                                {email}
                            </Typography>
                        </Box>
                        <Box sx={styles.userInfo}>
                            <Typography color="primary" sx={styles.userInfo.header}>
                                {strings.SCHOOL_DISTRICT_LABEL}:
                            </Typography>
                            {customerName && (
                                <Typography sx={styles.userInfo.info}>
                                    {customerName}
                                </Typography>
                            )}
                            {! customerName && (
                                <Skeleton 
                                    variant="rectangular" 
                                    sx={styles.userInfo.skeleton} 
                                />
                            )}
                        </Box>
                    </Box>
                    <Grid item lg={6} xs={12}>
                        <Box sx={styles.mainActionArea}>
                            {error && (
                                <Box sx={styles.errorContainer}>
                                    <Box sx={{ textAlign: "center" }}>
                                        <Icon component={error.icon} color={error.color} sx={styles.overlayIcon} />
                                        <Typography color="primary" sx={styles.overlayText}>
                                            {error.text}
                                        </Typography>
                                    </Box>
                              </Box>
                            )}
                            {! error && (
                            <Box>
                                <Box sx={styles.prefError}>
                                    <Typography sx={styles.selectPrefText}>
                                        {strings.UNSUBSCRIBE_SELECT_FROM_LIST}
                                    </Typography>
                                    <Box sx={styles.selectPrefText.subText}>
                                        &nbsp;{strings.UNSUBSCRIBE_CONTINUE_RECEIVING}&nbsp;
                                    </Box> 
                                    <Typography sx={styles.selectPrefText}>
                                        {strings.UNSUBSCRIBE_EMAIL_NOTIFICATIONS}
                                    </Typography>
                                </Box>
                                <Box sx={styles.preferencesContainer}>
                                    {preferences.map((preference) => {
                                        return (
                                            <FormControlLabel
                                                disabled={blockAllTypes}
                                                checked={preference.enabled}
                                                onChange={(e) => updatePreference(e, preference.id)}
                                                key={preference.id}
                                                value={preference.enabled}
                                                control={<Checkbox />}
                                                label={<Typography>{preference.name}</Typography>}
                                            />
                                        )
                                    })}
                                </Box>
                                <Divider />
                                <Box sx={styles.blockContainer}>
                                    <FormControlLabel
                                        onChange={toggleBlockAllTypes}
                                        value={false}
                                        control={<Checkbox />}
                                        label={
                                            <Typography sx={styles.unsubscribeFromAll}>
                                                {strings.UNSUBSCRIBE_UNSUBSCRIBE_FROM_ALL}
                                            </Typography>
                                        } 
                                    />
                                    <Typography sx={styles.thisWillBlockText}>
                                        {strings.UNSUBSCRIBE_THIS_WILL_BLOCK}
                                    </Typography>
                                </Box>
                            </Box>
                            )}
                            {(submitting || loading) && (
                                <Box sx={styles.loadingContainer}> 
                                    {! submitResponseCode && (
                                        <CircularProgress />
                                    )}
                                    {submitResponseCode === 200 && (
                                        <Box sx={styles.overlayContainer}>
                                            <CheckCircleIcon color="success" sx={styles.overlayIcon} />
                                            <Typography color="primary" sx={styles.overlayText}>
                                                {strings.UNSUBSCRIBE_THANK_YOU}
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>
                            )}
                        </Box>
                        <Box sx={styles.submitContainer}>
                            <Box sx={styles.submitContainer.submitPreferencesContainer}>
                                <Button sx={styles.submitContainer.submitButton}
                                    aria-label="submit preferences button"
                                    disabled={submitDisabled}
                                    variant="contained"
                                    onClick={submitPreferences}>
                                    Update preferences *
                                </Button>
                                {customerName && (
                                    <Box>
                                        <Typography sx={styles.updatesWillApplyText}>
                                            {strings.UNSUBSCRIBE_UPDATES_WILL_APPLY}
                                        </Typography>
                                        <Box sx={styles.updatesWillApplyText.subText}>
                                            {customerName}
                                        </Box> 
                                        <Typography>
                                            {strings.UNSUBSCRIBE_FOR_EMAIL} {email}
                                        </Typography>
                                    </Box>
                                )}
                                {! customerName && (
                                    <Box sx={styles.submitSkeletonContainer}>
                                        <Skeleton variant="rectangular" sx={styles.submitSkeleton}/>
                                        <Skeleton variant="rectangular" sx={styles.submitSkeleton}/>
                                        <Skeleton variant="rectangular" sx={styles.submitSkeleton}/>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        {infocenterEnabled && (
                            <Fade in={infocenterEnabled}>
                                <Typography sx={styles.youCanAlsoText}>
                                    {strings.UNSUBSCRIBE_YOU_CAN_ALSO}
                                </Typography>
                            </Fade>
                        )}
                        <SmLogoLinks />
                    </Grid>
                </Grid>
            </Container>
        </Layout>
    )
}

export default Unsubscribe;
