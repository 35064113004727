import { createSlice } from '@reduxjs/toolkit';
import { studentsAPI } from '../../api';
import { resetState } from '../resetStore';

const initialState = {
    persons: [],
    dependents: [],
    users: [],
    roles: [],
    portalUserId: null,
    inlineMode: false,
    isLoading: false,
    changedSinceLastTime: false,
};

const initializeSlice = createSlice({
    name: 'initializeSlice',
    extraReducers: builder => builder.addCase(resetState, () => initialState),
    initialState,
    reducers: {
        setPersons: (state, { payload }) => {
            state.persons = payload;
        },
        setDependents: (state, { payload }) => {
            state.dependents = payload;
        },
        setUsers: (state, { payload }) => {
            state.users = payload;
        },
        setRoles: (state, { payload }) => {
            state.roles = payload;
        },
        setPortalUserId: (state, { payload }) => {
            state.portalUserId = payload;
        },
        setInlineMode: (state, { payload }) => {
            state.inlineMode = payload;
        },
        setIsLoading: (state, { payload }) => {
            state.isLoading = payload;
            if (typeof window === 'undefined' || typeof Event === 'undefined' || typeof window.dispatchEvent === 'undefined') return;
            if (payload) window.dispatchEvent(new Event('startLoading'));
            else window.dispatchEvent(new Event('stopLoading'));
        },
        setChangedSinceLastTime: (state, { payload }) => {
            state.changedSinceLastTime = state.changedSinceLastTime || payload;
        },
        resetChangedSinceLastTime: (state) => {
            state.changedSinceLastTime = false;
        },
    },
});

export const {
    setDependents,
    setUsers,
    setRoles,
    setPortalUserId,
    setInlineMode,
    setIsLoading,
    setPersons,
    setChangedSinceLastTime,
    resetChangedSinceLastTime,
} = initializeSlice.actions;

const updateDependents = persons => async dispatch => {
    dispatch(setDependents(personsToDependents(persons)));
};

export const personsToDependents = persons => {
    return persons.reduce(
        (acu, curr) => [
            ...acu,
            ...curr.dependents,
            {
                dependent: curr,
                guardianCategory: !curr.dependents.length ? 'Primary' : 'Secondary',
            },
        ],
        []
    );
};

export const getInitialize = () => async dispatch => {
    try {
        dispatch(setIsLoading(true));
        const { data } = await studentsAPI.getPersons();
        dispatch(updateDependents(data.persons));
        dispatch(setUsers(data.users));
        dispatch(setPersons(data.persons));
        dispatch(setRoles(data.roles));
        dispatch(setPortalUserId(data.portalUserId));
        dispatch(setIsLoading(false));
        dispatch(setChangedSinceLastTime(data.changedSinceLastTime));
        return true;
    } catch (error) {
        return false;
    }
};

export const refreshStudents = () => async dispatch => {
    try {
        const { data, status } = await studentsAPI.refreshContacts();
        dispatch(updateDependents(data.persons));
        return status;
    } catch (error) {
        return false;
    }
};

export default initializeSlice.reducer;
