import React, {
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Autocomplete,
    Alert,
    Box,
    Grid,
    TextField,
    Typography,
    InputLabel,
    IconButton
} from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { utils, hooks, actions } from 'comm-recipientapp-shared';
import useAttachment from '../Attendance/Calendar/Popup/Absence/hooks/useAttachment';
import AttachmentUpload from '../Attendance/Calendar/Popup/Absence/AttachmentUpload';
import AttachmentPreviewPopup from '../Attendance/Calendar/Popup/Absence/AttachmentPreview';
import Avatar from '../Avatar';
import Popup from '../Attendance/Calendar/Popup';
import Button from '../Shared/Button';
import styles from './styles';

const { IMAGE_TYPES } = utils.constants;

function AbsenceForm({ open, handleClose }) {
    const [isAttachmentUploadOpen, setIsAttachmentUploadOpen] = useState(false);
    const [isAttachmentPreviewOpen, setIsAttachmentPreviewOpen] = useState(false);
    const { enabledStudents = [], organizationSettings = [], newAbsenceError } = useSelector(state => state.absence);
    const dispatch = useDispatch();

    const strings = utils.useStrings();
    const {
        values,
        errors,
        handlers,
        handleSubmit,
        isNewAbsenceValid
    } = hooks.absence.useNewAbsence();

    const {
        absenceType,
        comment,
        dateOfAbsence,
        expectedTimeOfArrival,
        firstDayOfAbsence,
        lastDayOfAbsence,
        reason,
        returningToSchoolAt,
        selectedStudent,
        timeOfDeparture,
        attachment,
    } = values;

    const { commentError } = errors;

    const {
        handleAbsenceType,
        handleDateOfAbsence,
        handleExpectedTimeOfArrival,
        handleFirstDayOfAbsence,
        handleLastDayOfAbsence,
        handleReason,
        handleReturningToSchoolAt,
        handleSelectedStudent,
        handleTimeOfDeparture,
        handleComment,
        handleAttachment,
    } = handlers;

    const {
        AttachmentIcon,
        attachmentSizeInMb,
        attachmentUrl,
        downloadAttachment,
        AttachmentType
    } = useAttachment(attachment);

    // Get the list of students with no duplicate entries
    const formattedStudents = useMemo(() => {
        const formattedSettings = organizationSettings.reduce((prev, curr) => ({ ...prev, [curr.organizationId]: curr }), {});
        return Object.values(
            enabledStudents.reduce(
                (acu, curr) => ({
                    ...acu,
                    [`${curr.personId}_${curr.organizationId}`]: {
                        ...curr,
                        organization: formattedSettings[curr.organizationId],
                    },
                }),
                {}
            )
        );
    }, [enabledStudents, organizationSettings]);

    function disableWeekends(date) {
        const day = new Date(date.$d).getDay();
        return day === 0 || day === 6;
    }

    useEffect(() => () => dispatch(actions.absence.setNewAbsenceError('')), []);

    const handleAttachmentUploadResult = useCallback(
        result => {
            const filesUploaded = result?.filesUploaded || [];

            if (filesUploaded.length > 0) {
                const file = filesUploaded[0];

                const attachmentData = {
                    filename: file.filename,
                    handle: file.handle,
                    mimetype: file.mimetype,
                    size: file.size,
                    url: file.url,
                };

                handleAttachment(attachmentData);
            }
        },
        [handleAttachment]
    );

    const handleAttachmentPreviewOpen = useCallback(openState => {
        if (typeof openState === 'boolean') {
            setIsAttachmentPreviewOpen(openState);
        } else {
            setIsAttachmentPreviewOpen(prevOpenState => !prevOpenState);
        }
    }, []);

    return (
        <Popup
        isOpen={open}
        disableEscapeKeyDown={isAttachmentPreviewOpen && attachment || isAttachmentUploadOpen}
        handleClose={handleClose}
        customStyles={styles.popup}
        hideCloseButton>
            {isAttachmentUploadOpen && (
                <AttachmentUpload
                    handleUploadDone={handleAttachmentUploadResult}
                    handleClose={() => setIsAttachmentUploadOpen(prevState => !prevState)}
                />
            )}
            {isAttachmentPreviewOpen && attachment && (
                <AttachmentPreviewPopup
                    AttachmentIcon={AttachmentIcon}
                    attachmentMimeType={attachment.mimetype}
                    attachmentName={attachment.filename}
                    attachmentSize={attachmentSizeInMb}
                    attachmentUrl={attachmentUrl}
                    handleClose={handleAttachmentPreviewOpen}
                    isOpen={isAttachmentPreviewOpen}
                    downloadAttachment={downloadAttachment}
                />
            )}
            <Box sx={styles.titleContainer}>
                <Box />
                <Box>
                    <Typography sx={styles.title} component="h1">
                        {strings.REPORTING_ABSENCES_JUST_GOT_A_WHOLE_LOT_EASIER}
                    </Typography>
                    <Typography sx={styles.subtitle} component="h2">
                        {strings.JUST_TELL_US_WHO_WILL_BE_ABSENT_WHEN_AND_WHY_AND_WE_INFORM_THE_SCHOOL}.
                    </Typography>
                </Box>
                <IconButton onClick={handleClose}>
                    <CloseIcon sx={styles.close_btn} />
                </IconButton>
            </Box>
            <Grid onSubmit={handleSubmit} sx={styles.form} container component="form" spacing={2}>
                <Grid item xs={12}>
                    {formattedStudents && (
                        <Autocomplete
                            disablePortal
                            fullWidth
                            options={formattedStudents}
                            onChange={(_, value) => {
                                handleSelectedStudent(value);
                            }}
                            value={selectedStudent}
                            getOptionLabel={option => `${option.firstName} ${option.lastName}`}
                            renderInput={params => (
                                <TextField
                                    fullWidth
                                    placeholder={strings.SELECT_STUDENT}
                                    variant="standard"
                                    label={strings.WHO_WILL_BE_ABSENT}
                                    {...params}
                                />
                            )}
                            renderOption={(props, option) => (
                                <Box component="li" {...props} key={`${option.personId}_${option.organizationId}`}>
                                    <Avatar
                                        label={`${option.firstName} ${option.lastName}`}
                                        sx={styles.avatar}
                                        stringForColor={`${option.personId}${option.firstName}${option.lastName}`}
                                    />
                                    <Typography>
                                        {`${option.firstName} ${option.lastName}`}
                                        <Typography sx={styles.autocompleteOption} component="span">
                                            {option.organizationName}
                                        </Typography>
                                    </Typography>
                                </Box>
                            )}
                        />
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        disablePortal
                        fullWidth
                        disabled={!selectedStudent}
                        options={selectedStudent ? selectedStudent.organization.absenceTypes : []}
                        getOptionLabel={option => option.text}
                        value={absenceType}
                        onChange={(_, value) => handleAbsenceType(value)}
                        renderInput={params => (
                            <TextField
                                fullWidth
                                placeholder={strings.SELECT_ABSENCE_TYPE}
                                variant="standard"
                                label={`${strings.TYPE_OF_ABSENCE}`}
                                {...params}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        disablePortal
                        fullWidth
                        disabled={!absenceType}
                        options={absenceType ? absenceType.reasons : []}
                        getOptionLabel={option => option.text}
                        onChange={(_, value) => handleReason(value)}
                        value={reason}
                        renderInput={params => (
                            <TextField fullWidth placeholder={strings.SELECT_REASON} variant="standard" label={strings.REASON} {...params} />
                        )}
                    />
                </Grid>
                {selectedStudent && absenceType && absenceType.type !== 'multiDay' && (
                    <>
                        <Grid item xs={12} sm={6}>
                            <DatePicker
                                label={strings.DATE_OF_ABSENCE}
                                inputFormat="MM/DD/YYYY"
                                value={dateOfAbsence}
                                onChange={value => handleDateOfAbsence(value)}
                                shouldDisableDate={disableWeekends}
                                minDate={new Date()}
                                renderInput={params => <TextField fullWidth variant="standard" {...params} />}
                            />
                        </Grid>
                        {absenceType && absenceType.type === 'late' && (
                            <Grid item xs={6}>
                                <TimePicker
                                    ampm
                                    label={strings.EXPECTED_TIME_OF_ARRIVAL}
                                    value={expectedTimeOfArrival}
                                    onChange={value => handleExpectedTimeOfArrival(value)}
                                    renderInput={params => <TextField fullWidth variant="standard" {...params} />}
                                />
                            </Grid>
                        )}
                        {absenceType && ['earlyDeparture', 'partialDay'].includes(absenceType.type) && (
                            <Grid item xs={12} sm={6}>
                                <TimePicker
                                    ampm
                                    label={strings.TIME_OF_DEPARTURE}
                                    value={timeOfDeparture}
                                    onChange={value => handleTimeOfDeparture(value)}
                                    renderInput={params => <TextField fullWidth variant="standard" {...params} />}
                                />
                            </Grid>
                        )}
                        {absenceType && absenceType.type === 'partialDay' && (
                            <>
                                <Grid item xs={12} sm={6} />
                                <Grid item xs={12} sm={6}>
                                    <TimePicker
                                        ampm
                                        label={strings.RETURNING_TO_SCHOOL_AT}
                                        value={returningToSchoolAt}
                                        onChange={value => handleReturningToSchoolAt(value)}
                                        renderInput={params => <TextField fullWidth variant="standard" {...params} />}
                                    />
                                </Grid>
                            </>
                        )}
                    </>
                )}
                {selectedStudent && absenceType && absenceType.type === 'multiDay' && (
                    <>
                        <Grid item xs={12} sm={6}>
                            <DatePicker
                                label={strings.FIRST_DAY_OF_ABSENCE}
                                inputFormat="MM/DD/YYYY"
                                value={firstDayOfAbsence}
                                onChange={value => handleFirstDayOfAbsence(value)}
                                minDate={new Date()}
                                shouldDisableDate={disableWeekends}
                                renderInput={params => <TextField fullWidth variant="standard" {...params} />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DatePicker
                                label={strings.LAST_DAY_OF_ABSENCE}
                                inputFormat="MM/DD/YYYY"
                                value={lastDayOfAbsence}
                                onChange={value => handleLastDayOfAbsence(value)}
                                minDate={new Date(firstDayOfAbsence ? firstDayOfAbsence.$d : '')}
                                shouldDisableDate={disableWeekends}
                                renderInput={params => <TextField fullWidth variant="standard" {...params} />}
                            />
                        </Grid>
                    </>
                )}
                {selectedStudent && selectedStudent?.organization?.commentsEnabled && (
                    <Grid item xs={12}>
                        <TextField
                            value={comment}
                            onChange={e => handleComment(e.target.value)}
                            fullWidth
                            multiline
                            variant="standard"
                            label={strings.COMMENTS}
                            placeholder={strings.COMMENTS_CHARACTER_LIMIT}
                            error={!!commentError}
                            helperText={commentError}
                        />
                    </Grid>
                )}
                {selectedStudent && selectedStudent?.organization?.attachmentsEnabled && (
                    <>
                        <Grid item xs={12} sx={styles.preview_attachement_container}>
                            <InputLabel
                                sx={{
                                    ...styles.attachments_label,
                                    cursor: attachment ? 'default' : 'pointer',
                                }}
                            >
                                {strings.ABSENCE_POPUP_ATTACHMENTS}
                            </InputLabel>
                            <Box
                                sx={{
                                    ...styles.attachment_add_button,
                                    cursor: attachment ? 'default' : 'pointer',
                                }}
                                onClick={() => {
                                    if (!attachment) {
                                        setIsAttachmentUploadOpen(true);
                                    }
                                }}
                            >
                                <Typography sx={styles.attachements_add_text}>{strings.ABSENCE_POPUP_ATTACHMENTS}</Typography>
                                <Box
                                    sx={
                                        attachment
                                            ? styles.add_attachments_button_container
                                            : {
                                                  ...styles.add_attachments_button_container,
                                                  ...styles.add_attachments_button_container_active,
                                              }
                                    }
                                >
                                    <IconButton disabled={attachment}>
                                        <AttachFileIcon sx={styles.attachments_icon} />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Grid>
                        {attachment && (
                            <Grid item xs={12}>
                                <Box sx={styles.attachments_details_container}>
                                    <Box sx={styles.attachment_container}>
                                        {IMAGE_TYPES.includes(AttachmentType) ? (
                                            <Box
                                                component="img"
                                                src={attachmentUrl}
                                                alt="attachment_file"
                                                onClick={() => handleAttachmentPreviewOpen()}
                                            />
                                        ) : (
                                            <IconButton>
                                                <AttachmentIcon width="80px" height="70px" />
                                            </IconButton>
                                        )}
                                        <Box sx={styles.edit_popup_attachments_metadata_container}>
                                            <Typography sx={styles.attachment_name}>{attachment?.filename}</Typography>
                                            <Typography sx={styles.attachment_size}>({attachmentSizeInMb} MB)</Typography>
                                        </Box>
                                    </Box>
                                    <IconButton disableRipple sx={styles.edit_popup_attachment_clear_button} onClick={() => handleAttachment(null)}>
                                        x
                                    </IconButton>
                                </Box>
                            </Grid>
                        )}
                    </>
                )}
                <Grid item xs={12}>
                    {newAbsenceError && <Alert severity="error">{newAbsenceError}</Alert>}
                </Grid>
                <Box sx={styles.actions}>
                    <Button sx={styles.cancelBtn} onClick={handleClose}>
                        {strings.CANCEL}
                    </Button>
                    <Button sx={styles.sendBtn} type="submit" disabled={!isNewAbsenceValid}>
                        {strings.SEND}
                    </Button>
                </Box>
            </Grid>
        </Popup>
    );
}

export default AbsenceForm;
