import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import MailIcon from '@mui/icons-material/Mail';
import Box from '@mui/material/Box';
import { utils, actions } from 'comm-recipientapp-shared';
import Layout from '../../../Layout';
import BackButton from '../../../Shared/BackButton';
import Notification from '../../../Utils/Notification';
import Item from './item';
import Button from '../../../Shared/Button';
import styles from './styles';


function EditPhonePreferences() {
    const [preferences, setPreferences] = useState({});
    const [notification, setNotification] = useState({
        open: false,
        type: '',
        message: '',
    });
    const [startLoading, setStartLoading] = useState(false);
    const strings = utils.useStrings();
    const activeSchool = useSelector(state => state.preferences.activeSchool);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    let { index } = useParams();
    const getEmail =
        activeSchool?.destinationPreferences?.emails.length > 0 &&
        activeSchool?.destinationPreferences?.emails[index];
    const goBackTo = location.state?.goBackTo || -1;
    useEffect(() => {
        if (activeSchool) {
            setPreferences(activeSchool.destinationPreferences || []);
        }
    }, [activeSchool]);

    const handleClickBackButton = () => {
        navigate(goBackTo);
    };
    const handleSavePreferences = async () => {
        let response;
        setStartLoading(true);
        try {
            const payload = {
                ...preferences,
            };
            response = await dispatch(actions.preferences.updatePreferences(activeSchool?.id, payload));

            if (response) {
                setNotification({
                    open: true,
                    type: utils.constants.NotificationType.INFO,
                    message: strings.SUCCESSFULLY_SAVED_RETURNING_TO_THE_PREFERENCES_PAGE,
                });
                setTimeout(() => {
                    handleClickBackButton();
                }, 4000);
            }
        } catch (error) {
            response = false;
        } finally {
            setStartLoading(false);
        }

        if (!response) {
            setNotification({
                open: true,
                type: utils.constants.NotificationType.ERROR,
                message: strings.SOMETHING_WENT_WRONG_TRY_LATER,
            });
        }
    };

    const handleUpdateEmail = notificationId => {
        const emailIndex = preferences.emails.findIndex(val => val.email === getEmail?.email);
        const currentNotifications = preferences.emails[emailIndex]?.notificationTypes || [];
        const newNotifications = utils.helpers.toggleArrayValue(
            currentNotifications,
            notificationId
        );

        setPreferences(preference => ({
            ...preference,
            emails: preference.emails.map((obj, i) =>
                i === emailIndex ? { ...obj, notificationTypes: newNotifications } : obj
            ),
        }));
    };

    const notifications = activeSchool?.notificationTypes;
    const data =
        notifications &&
        notifications
            .concat()
            .sort(
                utils.helpers.orderBy(
                    ['priority', 'name'],
                    [utils.constants.ASCENDING, utils.constants.ASCENDING]
                )
            );
    return (
        <Layout>
            <BackButton handleClickBackButton={handleClickBackButton} />
            <Container disableGutters maxWidth="lg" sx={styles.content}>
                <div style={styles.content_title}>
                    <Typography style={styles.title}>{strings.MY_EMAIL}</Typography>
                </div>
                <Box sx={styles.card}>
                    <Box sx={styles.card_info}>
                        <Box sx={styles.card_status}>
                            <div style={styles.card_circle}>
                                <MailIcon style={styles.icon_color} />
                            </div>
                        </Box>
                        <Typography sx={styles.value}>{getEmail?.email}</Typography>
                    </Box>
                </Box>
                <Box sx={styles.content}>
                    <Typography sx={styles.titles}>
                        {strings.CHOOSE_THE_MESSAGES_YOU_ARE_INTERESTED_IN_RECEIVED_FROM} {' '}
                        {activeSchool?.name}, {strings.AND_HOW_YOU_WANT_TO_RECEIVE_THEM}
                    </Typography>
                    <Box sx={styles.box_list}>
                        <Grid container rowSpacing={2} columnSpacing={2} sx={styles.message_list}>
                            {data?.map((value, index) => (
                                <Grid key={value.id} xs={12} sm={12} md={3}>
                                    <Item
                                        activeEmail={getEmail}
                                        handleUpdateEmail={handleUpdateEmail}
                                        item={value}
                                        index={index}
                                        preferences={preferences}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Box>
                <Box sx={styles.actions_button}>
                    <Button sx={styles.btn} onClick={handleClickBackButton} data-testid='cancel_btn'>
                        {strings.CANCEL}
                    </Button>
                    <Box sx={styles.ButttonBox}>
                        <Button onClick={handleSavePreferences} data-testid="save_btn">
                            {strings.SAVE}
                        </Button>
                        {startLoading && (
                            <CircularProgress
                                role="status"
                                aria-live="assertive"
                                aria-label={strings.LOADING}
                                size={24}
                                sx={styles.CircularProgress}
                            />
                        )}
                    </Box>
                </Box>
            </Container>
            <Notification
                open={notification.open}
                handleClose={() => setNotification({ ...notification, open: !notification.open })}
                type={notification.type}
                message={notification.message}
            />
        </Layout>
    );
}

export default EditPhonePreferences;
