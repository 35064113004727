import theme from '../../config/style/muiTheme';

const styles = {
    userEmail: {
        width: '155px',
        display: 'inline-block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: theme.palette.grey[600],
    },
    userMenu: {
        width: '220px',
        overflow: 'visible',
        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        mt: 3,
        '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
        },
        '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
        },
    },
    toolbar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: 'white',
    },
    flex: {
        display: 'flex',
        width: {
            xs: '15%',
            sm: '25%',
            md: '25%',
            lg: '15%',
        },
        justifyContent: 'flex-end',
    },
    mobileFlex: {
        display: 'flex',
        width: {
            xs: '15%',
            sm: '20%',
            md: '25%',
            lg: '15%',
        },
        justifyContent: 'flex-end',
    },
    notUserFlex: {
        display: 'flex',
        width: {
            xs: '50%',
            sm: '50%',
            md: '5%',
            lg: '5%',
        },
        justifyContent: 'flex-end',
    },
    logoBox: {
        height: {
            xs: '28px',
            sm: '28px',
            md: '28px',
            lg: '28px',
        },
        width: {
            xs: '153px',
            sm: '153px',
            md: '183px',
            lg: '183px',
        },
    },
    logoMobileBox: {
        width: '28px',
        height: '28px',
    },
    accountImg: {
        cursor: 'pointer',
        borderRadius: 0,
        height: {
            xs: '40px',
            sm: '40px',
            md: '40px',
            lg: '40px',
        },
        width: {
            xs: '40px',
            sm: '40px',
            md: '40px',
            lg: '40px',
        },
    },
    link: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        width: {
            xs: theme.spacing(30),
            sm: theme.spacing(35),
            md: theme.spacing(35),
            lg: theme.spacing(35),
        },
    },
    boxMenu: {
        display: 'flex',
        flexDirection: 'row',
        width: '60%',
        justifyContent: 'center',
    },
    rightBoxMenu: {
        display: {
            xs: 'none',
            sm: 'none',
            md: 'flex',
            lg: 'flex',
        },
        flexDirection: 'row',
        width: '80%',
        justifyContent: 'flex-end',
    },
    boxItem: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        margin: '2px',
        padding: '7px',
        borderRadius: '5px',
        color: '#8D919F',
        gap: '6px',
        textDecoration: 'none',
        background: '#f9f9f9',
    },
    menuItem: {
        alignSelf: 'center',
        textDecoration: 'none',
        fontSize: {
            xs: '12px',
            sm: '14px',
            md: '16px',
            lg: '16px',
        },
        fontWeight: 500,
        color: theme.palette.purple['80'],
        fontStyle: 'normal',
        fontFamily: 'Inter',
    },
    hideOnSmall: {
        display: {
            xs: 'none',
            sm: 'none',
            md: 'block',
            lg: 'block',
        },
    },
    floatMenuItem: {
        alignSelf: 'center',
        textDecoration: 'none',
        fontSize: {
            xs: '12px',
            sm: '14px',
            md: '16px',
            lg: '16px',
        },
        fontWeight: 500,
        color: 'inherit',
        marginLeft: 1,
    },
    menuIcon: {
        marginTop: '2px',
        fontSize: '18px',
        color: theme.palette.purple['80'],
    },
    AppleGoogleIcon: {
        color: theme.palette.purple['80'],
    },
    activeLink: {
        background: '#E5EFFA',
        color: theme.palette.purple['80'],
    },
    initialsUser: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column',
        width: {
            xs: '28px',
            sm: '28px',
            md: '40px',
            lg: '40px',
        },
        height: {
            xs: '28px',
            sm: '28px',
            md: '40px',
            lg: '40px',
        },
        borderRadius: '20px',
        textAlign: 'center',
        backgroundColor: theme.palette.purple['80'],
        cursor: 'pointer',
    },
    initials: {
        fontWeight: '700',
        fontSize: {
            xs: '13px',
            sm: '13px',
            md: '16px',
            lg: '16px',
        },
        lineHeight: '120%',
        color: 'white',
    },
    myContactsMenuItem: { mt: '10px' },
    helpMenuItems: {
        pt: 0,
        pb: 0,
    },
    pl0: { pl: 0 },
    removeHoverEffect: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    menu_button_container: {
        display: 'flex',
        gap: '10px',
    },
    captionLeft: {
        marginLeft: '-10px',
    },
};

export default styles;
