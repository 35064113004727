import theme from '../../../config/style/muiTheme';

const styles = {
    splashBox: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        bgcolor: theme.palette.common.gray,
    },
    splashBottomBox: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        justifyContent: 'center',
    },
    splashTopBox: {
        display: 'flex',
    },
    welcomeText: {
        color: theme.palette.common.white,
        textAlign: 'center',
        fontFamily: 'Hornbill',
        fontSize: '48px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '110%',
        letterSpacing: '-1.44px',
        whiteSpace: 'pre-line',
        bgcolor: theme.palette.purple['80'],
    },
    welcomeTextBody: {
        color: theme.palette.common.white,
        textAlign: 'center',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '150%',
        letterSpacing: '-0.32px',
        mx: 2,
        bgcolor: theme.palette.purple['80'],
    },
    WelcomeLine: {
        height: '22px',
    },
    marginAuto: {
        mt: 'auto',
    },
    welcomeComputerImage: {
        margin: 'auto',
        width: '80%',
    },
    mbForWelcomeBodyText: {
        mb: {
            md: 4,
            lg: 6,
            xl: 14,
        },
    },
};

export default styles;
