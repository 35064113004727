/* eslint-disable prettier/prettier */
import React, { useEffect, useState, useLayoutEffect } from 'react';
import {
    Box,
    Typography,
    Avatar,
    Stack,
    Popover,
    IconButton,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { utils, hooks } from 'comm-recipientapp-shared';
import styles from './styles';

function ChatTitle({ currentChat, rcInitialized }) {

    const strings = utils.useStrings();

    const {RC_DISCUSSION_GROUP_TYPES:{PRIVATE}} = utils.constants;

    const elRef = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isOverflown, setIsOverflown] = useState(false);

    const currentChatButRcStillLoading = (! rcInitialized) && currentChat;

    const {
        getNameToDisplayForConversations
    } = hooks.chat.useChat();

    const { stringAvatar, getConsistentColorFromString } = utils.avatar;

    const defaultChatData = {
        avatarText: '',
        name: '',
        description: null,
    }

    const populateChatData = ChatToPopulate => {
        const name = getNameToDisplayForConversations(ChatToPopulate);
        return {
            avatarText: stringAvatar(name).children || '',
            name: name || '',
            description: ChatToPopulate.type === PRIVATE? '' : (`${ChatToPopulate?.description }`),
        };
    }

    const CalculateIsOverflown = (ele) => {
        if(!ele)
        return false;
        return ele.scrollWidth > ele.clientWidth;
    }

    const handlePopoverOpen = (event) => {
        if(isOverflown){
            setAnchorEl(event.currentTarget);
        }
    };

    const handlePopoverClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    const [chatData, setChatData] = useState({...defaultChatData});

    useEffect(() => {
        if (currentChat)
            setChatData(populateChatData(currentChat));
    }, [currentChat]);

    useLayoutEffect(()=>{
        setIsOverflown(CalculateIsOverflown(elRef.current));
    },[chatData]);

    return (
        <Stack 
            aria-label={strings.CHAT_ARIA_LABEL_CURRENT_CONVERSATION_HEADING}
            direction="column" 
            spacing={1} 
            data-testid="chatDataContainer"
            sx={{
                ...styles.chatDataContainer,
                opacity: currentChatButRcStillLoading ? styles.loading.opacity : 1
            }}>
            <Box sx={styles.BoxAvatar}>
                <Avatar
                    sx={{
                        ...styles.avatarText,
                        bgcolor: `${getConsistentColorFromString(chatData.name)}`
                    }}
                    tabIndex={0}
                    aria-label={`${strings.CURRENT} ${strings.CHAT_AVATAR_TEXT}`}>
                    {chatData.avatarText}
                </Avatar>
            </Box>
            <Box>
                <Typography
                    tabIndex={0}
                    variant="h3"
                    aria-label={`${strings.CURRENT} ${strings.CHAT_NAME}`}
                    noWrap
                    sx={styles.TitleTypograpy}
                >
                    {chatData.name}
                </Typography>
                <Box 
                    aria-label={`${strings.CURRENT} ${strings.CHAT_DESCRIPTION}`}
                    sx={styles.BoxDescription} 
                    onClick={handlePopoverOpen}>
                    <Typography
                        tabIndex={0}
                        aria-label={strings.DESCRIPTION}
                        align='center'
                        ref={elRef}
                        noWrap
                    >
                        {chatData.description}
                    </Typography>
                    {isOverflown && 
                        <Typography 
                            tabIndex={0}
                            variant="caption" 
                            sx={styles.SeeMore}>
                                {strings.SEE_MORE}
                        </Typography>
                    }
                </Box>
                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    disablePortal
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    onClose={handlePopoverClose}
                    PaperProps={{
                        sx: styles.PopOver,
                    }}
                >
                    <Box  onClick={handlePopoverClose}>
                        <Stack>
                            <IconButton sx={styles.PopOverButton} size="large">
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={styles.PopOverTypography}>{chatData.description}</Typography>
                        </Stack>
                    </Box>
                </Popover>
            </Box>
        </Stack>
    );
}

export default ChatTitle;
