import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import styles from '../styles';

export default function Loader() {
    return (
        <Box sx={styles.loader}>
            <CircularProgress />
        </Box>
    );
}
