import { Box as MaterialUIBox, styled } from '@mui/material';

const UnReadIcon = styled(MaterialUIBox)(({ read = 'true' }) => ({
    margin: '0',
    padding: '0',
    '&::before': {
        content: '""',
        width: '13.5px',
        height: '13.5px',
        margin: "0 auto",
        borderRadius: '50%',
        background: 'red',
        cursor: 'default',
        display: read === 'true' ? 'none' :'block',
    },
}));

export default UnReadIcon;
