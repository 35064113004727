export const getCurrentMonth = language => new Date().toLocaleString(language || 'en', { month: 'long' });

export const getCurrentYear = () => new Date().getFullYear();

/**
 * @param {object} event
 * @param {string} language
 * @returns {string}
 */
export const formatEventHour = (event, language) => {
    if (!event.isMultiDay) {
        const generalDate = event.date.start;

        const outTimeDate = new Date(generalDate.date);
        if (generalDate.outTime) {
            outTimeDate?.setHours(generalDate.outTime?.split(':')[0]);
            outTimeDate?.setMinutes(generalDate.outTime?.split(':')[1]);
        }

        const inTimeDate = new Date(generalDate.date);
        if (generalDate.inTime) {
            inTimeDate?.setHours(generalDate.inTime?.split(':')[0]);
            inTimeDate?.setMinutes(generalDate.inTime?.split(':')[1]);
        }

        const outTime = generalDate.outTime
            ? outTimeDate
                .toLocaleTimeString(language)
                .replace(/(.*)\D\d+\s/, '$1 ')
            : undefined;
        const inTime = generalDate.inTime
            ? inTimeDate.toLocaleTimeString(language).replace(/(.*)\D\d+\s/, '$1 ')
            : undefined;

        if (outTime && inTime) return `${outTime} - ${inTime}`;
        if (outTime && !inTime) return outTime;
        if (!outTime && inTime) return inTime;
    }

    return '';
};

/**
 * @param {number} attachmentSize
 * @returns {number}
 */
export const attachmentSizeToMb = attachmentSize => (attachmentSize / (1024 * 1024)).toFixed(2);

/**
 * @param {string} absenceType
 * @returns {Record<string, string>}
 */
export const getFieldsForAbsenceType = absenceType => {
    const baseFields = {
        absenceType: '',
        reasonCode: '',
        date: '',
        attachment: null,
        comment: '',
    };

    const fieldsMap = {
        fullDay: baseFields,
        late: { ...baseFields, inTime: '' },
        earlyDeparture: { ...baseFields, outTime: '' },
        partialDay: { ...baseFields, inTime: '', outTime: '' },
        multiDay: { ...baseFields, endDate: '' },
    };

    return structuredClone(fieldsMap[absenceType] || {});
};

/** @param {Record<string, any>} object */
export const clearObjectFromNullValues = object => {
    if (
        typeof object === 'object'
        && object !== null
        && !Array.isArray(object)
    ) {
        const values = structuredClone(object);

        Object.keys(values).forEach((key) => {
            if (values[key] === null) {
                delete values[key];
            }
        });

        return values;
    }

    return null;
};

/**
 * Returns the 12 hour representation from a 24 hour time string with the
 * meridiem indicator: 12:00 AM
 *
 * @param {string} timeString String in format HH:MM without seconds e.g. 16:00
 * @param {string} locale
 * @returns {{ timeString12hr: string; meridiemIndicator: string }}
 */
export const get12HourTimeStringFrom24HourTimeString = (
    timeString,
    locale = 'en',
) => {
    const timeString12hr = new Date(
        `${new Date().toISOString().split('T')[0]}T${timeString}:00Z`,
    ).toLocaleTimeString(locale, {
        timeZone: 'UTC',
        hour12: true,
        hour: 'numeric',
        minute: 'numeric',
    });

    return {
        timeString12hr: timeString12hr.split(/\s/)[0],
        meridiemIndicator: timeString12hr.split(/\s/)[1],
    };
};

/**
 * Returns the 24 hour representation from a 12 hour time string with the
 * meridiem indicator: 12:00 AM
 *
 * @param {string} timeString String in 12 hour-format(HH:MM) without seconds
 *   e.g. 12:00
 * @param {'AM' | 'PM} meridiemIndicator AM or PM
 * @param {string} locale
 * @returns {string} String in 24 hour-format(HH:MM) without seconds e.g. 16:00
 */
export const get24HourTimeStringFrom12HourTimeString = (
    timeString,
    meridiemIndicator,
    locale = 'en',
) => {
    const timeString24hr = new Date(
        `${new Date().toISOString().split('T')[0]}T${
            timeString < 9 ? '0' : ''
        }${timeString}:00Z`,
    ).toLocaleTimeString(locale, {
        timeZone: 'UTC',
        hour12: false,
        hour: 'numeric',
        minute: 'numeric',
    });

    const timeString24hrArray = timeString24hr.split(':');

    if (meridiemIndicator === 'PM') {
        const afternoonTimeFormat = parseInt(timeString24hrArray[0]) + 12;

        return `${afternoonTimeFormat}:${timeString24hrArray[1]}`;
    }

    return `${timeString24hrArray[0]}:${timeString24hrArray[1]}`;
};
