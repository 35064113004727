import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setInlineMode } from 'comm-recipientapp-shared/src/store/initialize/initializeSlice';
import { setIsPowerschool } from 'comm-recipientapp-shared/src/store/user/userSlice';
import { login } from 'comm-recipientapp-shared/src/store/auth/authSlice';

export default function Oauthlogin () {
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/oauthlogin') {
            if (location.search) {
                const params = new URLSearchParams(location.search);
                const token = params.get("access_token");
                const inline = params.get("inline");

                if (token) {
                    if (inline)
                        dispatch(setInlineMode(true));
                    else
                        dispatch(setIsPowerschool(true));

                    dispatch(login({access_token: token}));
                }
            }
        }
    }, []);


    return null;
}
