import React from 'react';
import Landing from '../../components/Landing';
import Login from '../../components/Login';
import NotFound from '../../components/NotFound';
import Powerschool from '../../components/Powerschool';
import SignUp from '../../components/SignUp';
import ForgotPassword from '../../components/ForgotPassword';
import Learn from '../../components/Learn';
import SMSOptOut from '../../components/SMSOptOut';
import Unsubscribe from '../../components/Unsubscribe';
import AccountActivation from '../../components/AccountActivation';
import ResetPassword from '../../components/ResetPassword';

export const SMS_OPT_OUT = '/76';

const publicRoutes = [
    {
        path: '/forgotpassword',
        title: 'Forgot password',
        exact: true,
        element: <ForgotPassword />,
    },
    {
        path: '/',
        title: 'Landing',
        exact: true,
        element: <Landing />,
    },
    {
        path: '*',
        title: 'Not Found',
        exact: true,
        element: <NotFound />,
    },
    {
        path: '/login',
        title: 'Login',
        exact: false,
        element: <Login />,
    },
    {
        path: '/signup',
        title: 'Sign up',
        exact: true,
        element: <SignUp />,
    },
    {
        path: '/learn',
        title: 'Learn more',
        exact: true,
        element: <Learn />,
    },
    {
        path: '/oauthlogin',
        title: 'Powerschool',
        exact: true,
        element: <Powerschool />,
    },
    {
        path: '/unsubscribe/:customerId/:base64email',
        title: 'Unsubscribe',
        exact: false,
        element: <Unsubscribe />,
    },
    {
        path: '/account/processtoken',
        title: 'Account Activation',
        exact: true,
        element: <AccountActivation />,
    },
    {
        path: '/account/resetpassword',
        title: 'Reset Password',
        exact: true,
        element: <ResetPassword />,
    },
    {
        path: SMS_OPT_OUT,
        title: 'SMS Opt Out',
        exact: true,
        element: <SMSOptOut />,
    },
];

export default publicRoutes;
