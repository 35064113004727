import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { HashRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { QueryClientProvider, QueryClient } from 'react-query';
import { store } from 'comm-recipientapp-shared';
import { persistor } from 'comm-recipientapp-shared/src/store/store';
import I18NConfig from './components/I18NConfig';
import AppSnackbars from './components/Snackbars';
import GoogleAnalytics from './components/GoogleAnalytics';
import NewRelic from './components/NewRelic';
import RoutesContainer from './components/RoutesContainer';
import theme from './config/style/muiTheme';
import Oauthlogin from './components/OauthLogin';
import './App.css';

const queryClient = new QueryClient();

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <I18NConfig />
                <GoogleAnalytics />
                <NewRelic />
                <PersistGate loading={null} persistor={persistor}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <ThemeProvider theme={theme}>
                            <CssBaseline />
                            <AppSnackbars />
                            <HashRouter>
                                <Oauthlogin />
                                <RoutesContainer />
                            </HashRouter>
                        </ThemeProvider>
                    </LocalizationProvider>
                </PersistGate>
            </Provider>
        </QueryClientProvider>
    );
}

export default App;
