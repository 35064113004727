const styles = {
    content: {
        marginTop: '5px',
        paddingLeft: {
            lg: '0px',
            xs: '16px'
        },
        paddingRight: {
            lg: '0px',
            xs: '16px'
        },
    },
    title_container: {
        marginBottom: '5px',
        display: 'flex',
        gap: '5px',
    },
    icon_container: {
        marginTop: '30px',
    },
    icon_title: {
        width: '2rem',
        color: '#33366A',
    },
    header_title: {
        borderBottom: '1px solid #ddd',
        paddingBottom: '10px',
    },
    school_list: {
        display: 'flex',
        gap: '15px'
    },
    school_box: {
        backgroundColor: 'primary.main',
        color: '#ffffff',
        cursor: 'pointer',
        marginTop: '20px',
        padding: '15px',
        textAlign: 'center',
        width: '25%',
    },
    school_box_inactive: {
        backgroundColor: '#ffffff',
        color: '#ffffff',
        cursor: 'pointer',
        marginTop: '20px',
        padding: '15px',
        textAlign: 'center',
        width: '25%',
    },
    white: {
        color: '#ffffff'
    },
    black: {
        color: 'primary.main'
    },
    dialogHeader: {
        background: '#7546a0',
        padding: '25px 25px 15px',
        color: '#fff',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    dialogTitle: {
        paddingLeft: '4px',
        fontSize: '1.3rem',
        fontWeight: 300
    },
    modalContent: {
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    errorLegend: {
        fontWeight: 300,
        margin: '20px auto 25px',
        width: '70%'
    },
    button_ok: {
        textTransform: 'uppercase',
        backgroundColor: '#7546a0',
        width: '87px',
        borderRadius: '2px',
        fontWeight: '300',
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: '#7546a0'
        }
    }
};

export default styles;
