import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { preferenceAPI } from '../../api';
import { resetState } from '../resetStore';
import { PreferencePhoneConsent } from '../../utils/constants';

const initialState = {
    isFetched: false,
    schools: [],
    pendingPhones: [],
    activeSchool: {},
    isLoading: false,
};

const preferenceSlice = createSlice({
    name: 'preferenceSlice',
    initialState,
    reducers: {
        setSchools: (state, { payload }) => {
            state.schools = payload;
        },
        setActiveSchool: (state, { payload }) => {
            state.activeSchool = payload;
        },
        setPendingPhones: (state, { payload }) => {
            state.pendingPhones = payload
                .map(school => {
                    const phonesFiltered = school?.destinationPreferences?.phones.filter(
                        phone => phone?.registry?.consent === PreferencePhoneConsent.PENDING
                    );
                    return phonesFiltered.map(obj => ({ ...obj, school: school.id }));
                })
                .flat();
        },
        setIsFetched: (state, { payload }) => {
            state.isFetched = payload;
        },
        setIsLoading: (state, { payload }) => {
            state.isLoading = payload;
        },
    },
    extraReducers: builder => builder.addCase(resetState, () => initialState),
});

export const { setSchools, setIsLoading, setActiveSchool, setIsFetched, setPendingPhones } = preferenceSlice.actions;

export const getData = () => async dispatch => {
    try {
        const { data } = await preferenceAPI.getCustomers();
        await dispatch(setSchools(data || []));
        await dispatch(setPendingPhones(data || []));
        return { data, isSuccess: true };
    } catch (error) {
        return { isSuccess: false };
    }
};

export const updatePreferences = (customerId, requestBody, platform) => async dispatch => {
    try {
        await preferenceAPI.updatePreferences(customerId, requestBody, platform);
        const resp = await dispatch(getData());
        return resp?.isSuccess;
    } catch (error) {
        return false;
    }
};

export const getSchoolById = id => async dispatch => {
    try {
        dispatch(setIsLoading(true));
        const { data } = await preferenceAPI.getSchoolById(id);
        await dispatch(setActiveSchool(data));
        return data;
    } catch (error) {
        throw new Error(error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const updateSchoolSettings =
    ({ customerId, requestBody, platform, shouldFetchSchool = true }) =>
    async dispatch => {
        try {
            await preferenceAPI.updatePreferences(customerId, requestBody, platform);
            if (shouldFetchSchool) {
                const data = await dispatch(getSchoolById(customerId));
                return data;
            }
            return true;
        } catch (error) {
            throw new Error(error);
        }
    };

export default preferenceSlice.reducer;
