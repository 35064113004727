import { useCallback, useState } from 'react';

export default function useSnackBar() {
    const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
    const [snackBarMessage, setSnackbarMessage] = useState('');

    const handleSnackBarOpen = useCallback(() => {
        setIsSnackBarOpen(true);
    }, []);

    const handleSnacknBarClose = useCallback(() => {
        setIsSnackBarOpen(false);
    }, []);

    const _setSnackBarMessage = useCallback(message => {
        setSnackbarMessage(message);
    }, []);

    return {
        handleSnackBarOpen,
        handleSnacknBarClose,
        setSnackBarMessage: _setSnackBarMessage,
        snackBarMessage,
        isSnackBarOpen,
    };
}
