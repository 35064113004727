const styles = {
    cardContainer: {
        marginBottom: '10px',
        height: '50px',
        overflow: 'auto',
        padding: '10px 10px 10px 20px',
    },
    flexContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative',
        top: '50%',
        transform: 'translateY(-50%)',
    },
    buttonsContainer: { display: 'flex', gap: '20px' },
    studentButton: { borderRadius: '50px', maxHeight: '25px', color: '#2C5871' },
    studentButtonResetFilter: {
        backgroundColor: '#2C5871',
        '&:hover': {
            backgroundColor: '#2C5871',
        },
        color: 'white',
    },
    studentButtonInfoIcon: { marginLeft: '8px', fontSize: '20px', color: '#CCCCCC' },
    studentButtonTypography: { fontSize: '11px', marginLeft: '8px' },
    reFetchIcon: {
        maxHeight: '25px',
        '&:hover': { backgroundColor: 'transparent' },
        position: 'sticky',
        right: '0.1px',
        background: 'white',
    },
};

export default styles;
