import { palette } from 'comm-recipientapp-shared';

const styles = {
    content: {
        marginTop: '5px',
        paddingLeft: {
            lg: '0px',
            xs: '16px'
        },
        paddingRight: {
            lg: '0px',
            xs: '16px',
        },
        marginBottom: '50px',
        backgroundColor: palette.common.gray,
    },
    title_container: {
        // maxWidth: '1200px',
        // display: 'flex',
        // padding: '0 10px',
        // alignItems: 'center',
    },
    main_title_container: {
        marginBottom: '15px',
        display: 'flex',
        gap: '5px',
    },
    icon_container: {
        marginTop: '34px',
    },
    icon_title: {
        width: '2.5rem',
        color: '#33366A',
    },
    title: {
        mt: '7px',
        marginRight: '20px',
    },
    buttonsBox: { display: 'flex' },
    boxIcons: { marginTop: '7px' },
    contacts_grid: {
        margin: '10px auto',
        maxWidth: '1200px',
        padding: '0 10px',
    },
    organizations: {
        color: palette.secondary.silver,
        fontSize: '12px',
    },
    avatar: {
        marginRight: '15px',
    },
    student: {
        fontWeight: '400',
        fontSize: '18px',
    },
    header_container: {
        display: 'flex',
        alignItems: 'center',
    },
    info_item: {
        margin: '15px 5px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    action: {
        display: 'flex',
        justifyContent: 'center',
    },
    info_item_title: {
        color: palette.secondary.silver,
        fontSize: '18px',
        fontWeight: '200',
    },
    info_item_value: {
        color: palette.secondary.silver,
        fontWeight: '200',
        fontSize: '13px',
        textAlign: 'right'
    },
    action_button: {
        fontSize: '16px',
    },
    attendance_value: {
        textAlign: 'center',
        margin: '0 5px',
    },
    link_btn: {
        fontSize: '16px',
    },
    missing_contact_popup: {
        maxWidth: '700px',
        width: '100%',
    },
    txt_refreshing: {
        fontSize: '16px',
        color: palette.primary.main,
        marginRight: 1.5,
        marginLeft: 1,
        marginTop: 0.5,
    },
    txt_synchronized: {
        fontSize: '16px',
        color: palette.success.dark,
        marginRight: 1,
        marginLeft: 1,
        marginTop: 0.5,
    },
    popup_custom_styles: {
        width: '500px',
    },
    boxFindNewContacts: { mt: '4px', mb: 2 },
};

export default styles;
