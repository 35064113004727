import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { utils, actions } from 'comm-recipientapp-shared';
import ContactsSvgFocused from 'comm-recipientapp-shared/src/assets/contacts.svg';
import Layout from '../Layout';
import Loader from '../Loader';
import Popup from '../Attendance/Calendar/Popup';
import SyncPopup from '../Attendance/Calendar/SyncPopup';
import Title from '../Shared/NewUI/Title';
import useIsLoading from '../../hooks/useIsLoading';

import MissingStudent from './MissingStudent';
import SubTitle from '../Shared/SubTitle';
import Student from './Student';
import StudentAccordion from './StudentAccordion';
import styles from './styles';

const { refreshStudents } = actions.initialize;

function StudentsPage() {
    const strings = utils.useStrings();
    const dispatch = useDispatch();
    const dependents = useSelector(state => state.initialize.dependents);
    const customers = useSelector(state => state.customers.customers);
    const [isMissingContactPopupOpen, setIsMissingContactPopupOpen] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [isFetched, setIsFetched] = useState(false);
    const [isSyncPopupOpen, setIsSyncPopupOpen] = useState(false);
    const isLoading = useIsLoading();
    const { pathname } = useLocation();
    const navegate = useNavigate();

    const students = useMemo(() => dependents
    .slice()
    .sort((a, b) =>
            (`${  a.dependent?.firstName}`).localeCompare(`${  b.dependent?.firstName}`)
    ), [dependents]);

    const handleMissingContactPopupOpen = () => {
        setIsMissingContactPopupOpen(true);
    };

    const handleMissingContactPopupClose = () => {
        setIsMissingContactPopupOpen(false);
    };

    const handleRefreshContacts = async () => {
        try {
            setIsFetching(true);
            const status = await dispatch(refreshStudents());
            if (status === 200) {
                setIsSyncPopupOpen(true);
                setIsFetched(true);
                setIsFetching(false);
            } else {
                setIsFetched(true);
                setIsFetching(false);
            }
        } catch (error) {
            setIsFetched(false);
            setIsFetching(false);
        }
    };

    const handleFindWithDifferentEmail = () => {
        navegate('/add-new-email', { state: { goBackTo: pathname } });
    };

    return (
            <Layout>
                <Container disableGutters maxWidth="lg" sx={styles.content}>
                    <Loader visible={isLoading} />
                    {isMissingContactPopupOpen && (
                            <Popup
                                    isOpen={isMissingContactPopupOpen}
                                    handleClose={handleMissingContactPopupClose}
                                    hideCloseButton
                                    customStyles={styles.missing_contact_popup}
                            >
                                <MissingStudent handleClose={handleMissingContactPopupClose} />
                            </Popup>
                    )}
                    <Box sx={styles.main_title_container}>
                        <Box alt={""} component="img" src={ContactsSvgFocused} sx={styles.icon_title} />
                        <Title data-testid="mainTitle" variant="h1">
                            {strings.MY_CONTACTS}
                        </Title>
                    </Box>
                    <Stack direction="row" sx={styles.title_container}>
                        <SubTitle sx={styles.title}>{strings.CONTACTS}</SubTitle>
                        <Stack>
                            <Box sx={styles.buttonsBox}>
                                {!isFetching && isFetched && (
                                        <>
                                            <Typography sx={styles.txt_synchronized}>{strings.SYNCHRONIZED_COMPLETE}</Typography>
                                            <CheckCircleIcon sx={styles.boxIcons} fontSize="small" color="success" />
                                        </>
                                )}
                                {isFetching && !isFetched && (
                                        <>
                                            <Typography sx={styles.txt_refreshing}>{strings.REFRESHING_STUDENTS}...</Typography>
                                            <CircularProgress sx={styles.boxIcons} size={20} />
                                        </>
                                )}
                                {!isFetching && !isFetched && (
                                        <Button onClick={handleRefreshContacts} variant="text" sx={styles.link_btn}>
                                            {strings.SYNCHRONIZE_CONTACTS_WITH_YOUR_SCHOOL_DISTRICT}
                                        </Button>
                                )}
                            </Box>
                            <Box sx={styles.boxFindNewContacts}>
                                <Button onClick={handleFindWithDifferentEmail} variant="text" sx={styles.link_btn}>
                                    {strings.FIND_CONTACTS_WITH_A_DIFFERENT_EMAIL}
                                </Button>
                            </Box>
                        </Stack>
                    </Stack>
                    <Box sx={styles.contacts_grid}>
                        <Grid container spacing={2}>
                            {students.map(({ dependent, guardianCategory = 'Primary' }, index) => (
                                    <Grid key={index} item xs={12} sm={6} md={4}>
                                        {guardianCategory === 'Primary' ? (
                                                <StudentAccordion dependent={dependent} customers={customers} />
                                        ) : (
                                                <Student dependent={dependent} customers={customers} />
                                        )}
                                    </Grid>
                            ))}
                        </Grid>
                    </Box>
                    {isSyncPopupOpen && (
                            <Popup
                                    isOpen={isSyncPopupOpen}
                                    customStyles={styles.popup_custom_styles}
                                    handleClose={() => setIsSyncPopupOpen(false)}
                                    hideCloseButton
                            >
                                <SyncPopup handleClose={() => setIsSyncPopupOpen(false)} />
                            </Popup>
                    )}
                </Container>
            </Layout>
    );
}

export default StudentsPage;
