import { palette } from 'comm-recipientapp-shared';

const Styles = {
    content: {
        height: 'calc(100vh - 64px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    TypographyProcessingToken: {
        fontSize: '16px',
        fontWeight: 300,
        color: palette.blue['130'],
        margin: '40px auto',
        textAlign: 'center',
    },
    setNewPasswordButton: {
        maxWidth: '70%',
        mt: 2,
        alignSelf: 'center',
        '&:hover': {
            bgcolor: palette.blue['150'],
        },
    },
    goToLoginButton: {
        width: 'fit-content',
        alignSelf: 'center',
        '&:hover': {
            bgcolor: palette.blue['150'],
        },
    },
    TypographyErrorMessage: {
        fontSize: '16px',
        fontWeight: 300,
        color: palette.error.dark,
        margin: '40px auto',
        textAlign: 'center',
    },
    TypographyPassRequirements: {
        m: 1,
    },
    title: {
        fontSize: '24px',
        fontWeight: 400,
        marginBottom: 4,
        color: palette.blue['130'],
    },
    fillPasswordStack: { width: '100%' },
    formControl: { mb: 2 },
};

export default Styles;
