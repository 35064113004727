const centerVertically = {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column',
};

const styles = {
    title_container: {
        ...centerVertically,
    },
    title_box: {
        display: 'flex',
        gap: '5px',
    },
    inbox_button: {
        color: '#33366A',
        marginLeft: "3px",
        marginRight: "3px",
        marginTop: "1px",
        fontWeight: '500',
        lineHeight: '140%',
        fontSize: '18px',
    },
    container: {
        maxHeight: '60px',
        padding: '8px 32px 8px 32px',
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: 'solid 0.5px gray',
        zIndex: '9999',
        background: 'white',
    },
    message_title: {
        color: '#33366A',
        fontWeight: '500',
        lineHeight: '140%',
        fontSize: '18px',
    },
    left_arrow: {
        marginTop: 0.1,
        color: '#33366A',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        },
    },
    read_button: {
        backgroundColor: '#E5EFFA',
        color: '#545BE5',
    },
    trash_button: {
        backgroundColor: '#D12222',
        color: '#FFFFFF',
    },
    common_icon_button_styles: {
        marginLeft: '3px',
    },
    buttons_container: { display: 'flex', gap: '15px' },
    arrow_icon_container: { marginTop: '1px' },
    loader_container: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '60px',
    },
    loader_box: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '20px',
    },
    loader: {
        color: '#545BE5',
    },
    msgDetailsBackgroundColor: '#F2F7FA', //'#FFFFFF',
};

export default styles;
