import { isEmpty } from 'ramda';
export { isEmailFormatValid } from './common';

export const isSignUpFormValid = (email, password) => !isEmpty(email.trim()) && !isEmpty(password.trim());

export const validatePassword = password => ({
    characters: /^.{6,255}$/.test(password),
    oneLowerCase: /[a-z]/g.test(password),
    oneNumber: /[0-9]/g.test(password),
    oneUpperCase: /[A-Z]/g.test(password),
});

export const isPasswordValid = password => {
    const {
        characters,
        oneLowerCase,
        oneNumber,
        oneUpperCase,
    } = validatePassword(password);

    return oneLowerCase && oneUpperCase && oneNumber && characters;
};

