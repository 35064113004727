/* eslint-disable prettier/prettier */
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    Box,
    Container,
    Typography,
    Stack,
    Card,
    CardContent
} from '@mui/material';
import { useStrings } from 'comm-recipientapp-shared/src/utils';
import { getEmailString } from 'comm-recipientapp-shared/src/utils/helpers'
import { useUserLogins } from 'comm-recipientapp-shared/src/hooks/user';
import EnvelopeSVG from 'comm-recipientapp-shared/src/assets/envelope.svg';
import ChevronRightSVG from 'comm-recipientapp-shared/src/assets/chevron_right.svg'
import SchoolSVG from 'comm-recipientapp-shared/src/assets/school.svg'
import Layout from '../Layout';
import Loader from '../Loader';
import styles from './styles';


export default function NotFoundRecords() {
    const strings = useStrings();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { userLogins, loading } = useUserLogins();

    const handleClickTryWithDifferentEmail = () => {
        navigate('/add-new-email', { state: { goBackTo: pathname } });
    }

    const handleClickGetInTouchWithTheSchool = () => {
        navigate('/get-in-touch-with-the-school', { state: { goBackTo: pathname } });
    }

    return (
        <>
            {loading && <Loader visible={loading} />}
            <Layout>
                <Container maxWidth="xl" disableGutters sx={styles.main_container}>
                    <Stack spacing={2} justifyContent="center"  alignItems="center" sx={styles.mainStack}>
                        <Box sx={styles.container}>
                            <Typography sx={styles.bodyTitle}>{strings.NO_STUDENTS_FOUND}</Typography>
                            <Typography sx={styles.bodyText}>{strings.WE_WERENOT_ABLE_TO_FIND_ANY_STUDENS}:</Typography>
                            {userLogins.map(userLogin => <Typography key={userLogin.username} sx={styles.emailText}>{getEmailString(userLogin.username)}</Typography>)}
                            <Typography sx={styles.bodyTextBold}>{strings.HOW_DO_YOU_WANT_TO_FIND_YOUR_CHILD_S_INFORMATION}</Typography>
                        </Box>
                        <Stack spacing={2}>
                            <Box sx={styles.boxCard} onClick={handleClickTryWithDifferentEmail}>
                                <Card sx={styles.card} variant="outlined">
                                    <Box>
                                        <CardContent sx={styles.cardBody}>
                                            <Box alt={strings.ENVELOPE_ICON} component="img" src={EnvelopeSVG} sx={styles.cardReloadIcon} />
                                            <Typography sx={styles.cardTitleTypography}>{strings.TRY_A_DIFFERENT_EMAIL}.</Typography>
                                            <Typography sx={styles.cardBodyTypography}>{strings.USE_A_PREVIOUSLY_VERIFIED_EMAIL_TO_FIND_YOUR_CHILDREN}.</Typography>
                                        </CardContent>
                                    </Box>
                                    <Box alt={strings.CHEVRON_RIGHT_ICON} component="img" src={ChevronRightSVG} sx={styles.cardChevronRightIcon} />
                                </Card>
                            </Box>
                            <Box sx={styles.boxCard} onClick={handleClickGetInTouchWithTheSchool}>
                                <Card sx={styles.card} variant="outlined">
                                    <Box>
                                        <CardContent sx={styles.cardBody}>
                                            <Box alt={strings.SCHOOL_ICON} component="img" src={SchoolSVG} sx={styles.cardReloadIcon} />
                                            <Typography sx={styles.cardTitleTypography}>{strings.GET_IN_TOUCH_WITH_THE_SCHOOL}</Typography>
                                            <Typography sx={styles.cardBodyTypography}>{strings.YOU_WILL_NEED_TO_CONTACT_YOUR_SCHOOL_FOR_MORE_ASSISTANCE}</Typography>
                                        </CardContent>
                                    </Box>
                                    <Box alt={strings.CHEVRON_RIGHT_ICON} component="img" src={ChevronRightSVG} sx={styles.cardChevronRightIcon} />
                                </Card>
                            </Box>
                        </Stack>
                    </Stack>
                </Container>
            </Layout>
        </>
    );
}
