import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { utils, validations } from 'comm-recipientapp-shared';
import StudentItem from './Item';
import styles from '../styles';

function StudentList({ students, handleClick, handleClose, currentOrgName, ...props }) {
    const [showPassword, setShowPassword] = useState(false);
    const strings = utils.useStrings();

    return (
        <>
            <Typography sx={styles.title}>{' '}</Typography>
            <Typography variant="subtitle2" sx={styles.description}>
                {strings.SELECT_THE_STUDENTS_FOR_WHICH_A_PIN_PERSONAL_IDENTIFICATION_NUMBER_MUST_BE_ENTERED_WHEN_USING_THE_SAFE_ARRIVAL_PHONE_SYSTEM}.
            </Typography>
            <Box sx={styles.studentList}>
                {students.map((student, i) => (
                    <StudentItem key={i} index={i} student={student} handleClick={handleClick} />
                ))}
            </Box>
            <Box sx={styles.show_pin}>
                <Typography sx={styles.yourPin}>{strings.YOUR_4_DIGIT_PIN}</Typography>
                <Box sx={styles.content_pin}>
                    <input
                        onChange={props.handlePinChange}
                        style={{
                            ...styles.input,
                            border: props.validPin ? styles.inputValid : styles.inputInValid,
                        }}
                        type={showPassword ? 'text' : 'password'}
                        maxLength={'4'}
                        value={props.pinValue?.toString()}
                        data-testid="password"
                        onKeyPress={event => {
                            if (!validations.common.onlyNumbers.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                    />
                </Box>
                <Tooltip title={strings.TOGGLE_PASSWORD_VISIBILITY}>
                    <IconButton aria-label={strings.TOGGLE_PASSWORD_VISIBILITY} onClick={() => setShowPassword(!showPassword)} data-testid="show-pin">
                        {showPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                    </IconButton>
                </Tooltip>
            </Box>
            <Box sx={styles.contentLegend}>
                <p style={styles.contentLegendValue}>
                    {`${strings.NOTE}:`}
                    {
                        strings.ANY_OF_THE_STUDENTS_GUARDIANS_WITH_ACCESS_TO_SAFE_ARRIVAL_CAN_UPDATE_THE_REQUIREMENTS_FOR_A_PIN_ANY_PIN_BELONGING_TO_ONE_OF_THE_STUDENTS_GUARDIANS_WITH_ACCESS_TO_SAFE_ARRIVAL_WILL_BE_ACCEPTED_BY_THE_SAFE_ARRIVAL_PHONE_SYSTEM
                    }
                    .
                </p>
            </Box>
            <Box sx={styles.saveActions}>
                <Button color="primary" data-testid="button-login" fullWidth sx={styles.cancel_button} variant="outlined" onClick={handleClose}>
                    {strings.CANCEL}
                </Button>
                <Button
                    color="primary"
                    data-testid="button-login"
                    fullWidth
                    sx={styles.button}
                    disabled={!props.isValid}
                    onClick={props.handleSubmit}
                    variant="contained"
                >
                    {strings.SAVE}
                </Button>
            </Box>
        </>
    );
}

export default StudentList;
