import React, { useEffect } from 'react';
import { Box, Typography, Divider, Link, useMediaQuery } from '@mui/material';
import { utils, env } from 'comm-recipientapp-shared';
import { Trans } from 'react-i18next';
import Layout from '../Layout';
import styles from './styles';
import OptOutForm from './OptOutForm';

function SMSOptOut() {
    const isSmallScreen = useMediaQuery('(max-width:600px)');

    return (
        <Layout>
            <Box sx={styles.main_container}>
                <Box sx={isSmallScreen ? styles.container_mobile : styles.container}>
                    <TermsAndCondition />
                    <OptInInvitation isSmallScreen={isSmallScreen} />
                </Box>
                <OptOutForm />
            </Box>
        </Layout>
    );
}
export default SMSOptOut;

function TermsAndCondition() {
    const strings = utils.useStrings();
    const [currentLocation, setCurrentLocation] = React.useState('United States');

    useEffect(() => {
        const domain = window.location.hostname;
        const domainParts = domain.split('.');
        const domainExtension = domainParts[domainParts.length - 1];
        setCurrentLocation(domainExtension === 'ca' ? 'Canada' : 'United States');
    }, []);

    return (
        <Box sx={styles.column1}>
            <Box sx={styles.section}>
                <Typography component="h2" sx={styles.title}>
                    {strings.TERMS_AND_CONDITIONS}
                </Typography>
                <Typography sx={styles.text}>{strings.SCHOOLMESSENGER_ALLOWS_SCHOOLS_DISTRICTS_COLLEGES_AND_UNIVERSITIES}</Typography>
            </Box>
            <Divider sx={styles.divider} />
            <Box sx={styles.section}>
                <Typography sx={styles.text}>
                    <Trans>{currentLocation === 'United States' ? strings.FOR_USERS_IN_THE_UNITED_STATES : strings.FOR_USERS_IN_CANADA}</Trans>
                </Typography>
                <Typography sx={styles.disclaimer_text}>{strings.THE_MOBILE_CARRIERS_ARE_NOT_LIABLE_FOR_DELAYED_OR_UNDELIVERED_MESSAGES}</Typography>
            </Box>
            <Divider sx={styles.divider} />
            <Box sx={styles.section}>
                <Typography sx={styles.subtext}>{strings.WE_RESPECT_YOUR_PRIVACY}</Typography>

                <Link sx={styles.link} href={env.PRIVACY_POLICY_URL} target="_blank">
                    {` ${strings.HERE}`}.
                </Link>
                <Typography sx={styles.disclaimer_text}>
                    {strings.EMERGENCY_ALERT_SERVICES_USING_SMS_TEXT_MESSAGING_ARE_SUPPORTED_ONLY_IN_YHE_US}
                </Typography>
            </Box>
        </Box>
    );
}

function OptInInvitation({ isSmallScreen }) {
    const strings = utils.useStrings();
    return (
        <Box sx={isSmallScreen ? styles.column2_mobile : styles.column2}>
            <Box sx={styles.section}>
                <Typography sx={styles.title}>{strings.DID_YOU_RECEIVE_AN_OPT_IN_INVITATION_TEXT_MESSAGE}</Typography>
                <Typography sx={styles.text}>{strings.IF_YOU_RECEIVED_A_TEXT_MESSAGE_REQUESTING_YOU_TO_OPT_INTO_YOUR_SCHOOL}</Typography>
                <Typography sx={styles.text}>{strings.IF_YOU_WOULD_LIKE_TO_CONTINUE_RECEIVING_MESSAGES}</Typography>
            </Box>
        </Box>
    );
}
