import { useRef, useCallback } from 'react';
import EventEmitter from 'eventemitter3';

const CHANGE_EVENT = 'change';

/**
 * Hook to use a ref as an observable object that can be subscribed to, and emit changes, Always use the 'set' function to update the ref, and the
 * 'subscribe' function to subscribe to changes. You can also use the 'getRefValue' property to access the current ref value.
 *
 * The purpose of this hook is to be able to have observable instances of objects without using state, which can cause re-renders.
 *
 * @param {any} initialRef
 */
export default function useObservableRef(initialRef = {}) {
    const state = useRef(initialRef);

    const emitter = useRef(new EventEmitter());

    const set = useCallback(newState => {
        state.current = newState;
        emitter.current.emit(CHANGE_EVENT, newState);
    }, []);

    const subscribe = useCallback(callback => {
        emitter.current.on(CHANGE_EVENT, callback);

        return () => {
            emitter.current.off(CHANGE_EVENT, callback);
        };
    }, []);

    return { set, subscribe, getRefValue: () => state.current };
}
