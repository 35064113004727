/* eslint-disable prettier/prettier */
import React from 'react';
import {
    ListItem,
    Box,
    Skeleton,
} from "@mui/material";
import styles from './styles';

function ListItemSkeleton() {
    return (
        <ListItem>
            <Box sx={{display: "inline-flex"}}>
                <Skeleton
                    variant="rectangular"
                    width={40}
                    height={40}
                    sx={{...styles.sideBarListItem, marginLeft: "12px", marginTop: "3px"}}
                />
                <Skeleton
                    variant="rectangular"
                    width={140}
                    height={11}
                    sx={{...styles.ListItemButtonTypography, marginTop: "15px", marginLeft: "15px"}}
                />
            </Box>
        </ListItem>
    );
}

export default ListItemSkeleton;
