/* eslint-disable prettier/prettier */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, CircularProgress } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { useStrings } from 'comm-recipientapp-shared/src/utils';
import { sendEmailVerificationCode, verifyEmailCode } from 'comm-recipientapp-shared/src/api/preferences';
import { isEmailFormatValid } from 'comm-recipientapp-shared/src/validations/common';
import { AddEmailSteps, ContactDestinationTypes, EMAIL } from 'comm-recipientapp-shared/src/utils/constants';
import Button from '../../../Shared/Button';
import styles, { StyledInput } from './styles';
import '../AddPhone/styles.css';

export default function AddEmail({ handleClose, handleAddEmail }) {
    const {
        PREFERENCES_ADD_EMAIL_BANNER_TITLE,
        PREFERENCES_ADD_EMAIL_CANCEL_BUTTON,
        PREFERENCES_ADD_EMAIL_SAVE_BUTTON,
        PREFERENCES_ADD_EMAIL_FIELD_LABEL,
        PREFERENCES_ADD_EMAIL_SETUP_BANNER_TITLE,
        PREFERENCES_ADD_EMAIL_SETUP_YES_BUTTON,
        PREFERENCES_ADD_EMAIL_SETUP_DESCRIPTION,
        PREFERENCES_ADD_EMAIL_SETUP_NO_BUTTON,
        LOADING,
        EMAIL_VERIFICATION_TYPE_CODE,
        EMAIL_VERIFICATION_BANNER_VERIFY_TITLE,
        PLEASE_ENTER_A_PROPERLY_FORMATTED_EMAIL_ADDRESS,
        MUST_BE_A_VALID_4_DIGIT_CODE,
        WRONG_VERIFICATION_CODE,
        AN_INTERNAL_ERROR_HAS_HAPPENED_PLEASE_TRY_AGAIN,
        EMAIL_VERIFICATION_ATTEMPTS_ARE_OVER_THE_LIMIT,
        ENTER_THE_CODE_THAT_WE_SEND_TO_YOUR_EMAIL_ADDRESS
    } = useStrings();

    const [email, setEmail] = useState('');
    const [verificationCode, setVerificationCode] = useState('');

    const [step, setStep] = useState(AddEmailSteps.ADD);

    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [startLoading, setStartLoading] = React.useState(false);
    const customerId = useSelector(state => state.preferences.activeSchool.id);

    const emails = useSelector(state => state?.preferences?.activeSchool?.destinationPreferences?.emails);

    const isEmailValid = useMemo(() => isEmailFormatValid(email), [email]);

    /** @type {React.MutableRefObject<HTMLInputElement>} */
    const inputRef = useRef();
    const inputCodeRef = useRef(null);

    const navigate = useNavigate();

    const handleContinue = async () => {
        if (step === AddEmailSteps.ADD) {
            try {
                if (!isEmailValid) {
                    setHasError(true);
                    setErrorMessage(PLEASE_ENTER_A_PROPERLY_FORMATTED_EMAIL_ADDRESS);
                    return;
                }
                setStartLoading(true);
                await sendEmailVerificationCode(email, customerId);
                setStep(AddEmailSteps.VERIFY);
                return;
            } catch (error) {
                setHasError(true);
                setErrorMessage(error.message);
                if (error?.response?.status === 400) {
                    if (error.response.data?.error === "validationError") {
                        setErrorMessage(PLEASE_ENTER_A_PROPERLY_FORMATTED_EMAIL_ADDRESS);
                    }
                } else if (error?.response?.status === 429) {
                    setErrorMessage(EMAIL_VERIFICATION_ATTEMPTS_ARE_OVER_THE_LIMIT);
                }
                return;
            } finally {
                setStartLoading(false);
            }
        }

        if (step === AddEmailSteps.VERIFY) {
            try {
                setStartLoading(true);
                setHasError(false)
                await verifyEmailCode(email, customerId, verificationCode);
                await handleAddEmail(ContactDestinationTypes.EMAIL, email);
                setStep(AddEmailSteps.SETUP_PREFERENCE);
                return;
            } catch (error) {
                setHasError(true);
                if (error?.response?.status === 400) {
                    if (error.response.data?.error === "validationError") {
                        setErrorMessage(MUST_BE_A_VALID_4_DIGIT_CODE);
                    } else if(error.response.data?.error === "badRequest") {
                        setErrorMessage(WRONG_VERIFICATION_CODE);
                    }
                } else {
                    setErrorMessage(AN_INTERNAL_ERROR_HAS_HAPPENED_PLEASE_TRY_AGAIN);
                }
                return;
            } finally {
                setStartLoading(false);
            }
        }

        if (step === AddEmailSteps.SETUP_PREFERENCE) {
            const emailIndex = emails.findIndex(emailData => email === emailData.email);

            navigate(`/preferences/edit/${EMAIL}/${emailIndex}`, {
                state: {
                    type: ContactDestinationTypes.EMAIL,
                },
            });
        }
    };

    useEffect(() => {
        if (email.length > 0 && hasError) {
            setHasError(false);
            setErrorMessage('');
        }

        if (step === AddEmailSteps.ADD) {
            if (!isEmailValid && email.length > 0) {
                inputRef.current.classList.add('text_field_error_state');
            } else {
                inputRef.current.classList.remove('text_field_error_state');
            }
        }
    }, [email]);

    useEffect(()=>{
        if (inputCodeRef.current) {
            inputCodeRef.current.focus();
        }
    },[step]);

    return (
        <>
            <Box sx={styles.banner}>
                <Typography sx={styles.banner_text} aria-live="polite">
                    {step === AddEmailSteps.ADD && PREFERENCES_ADD_EMAIL_BANNER_TITLE}
                    {step === AddEmailSteps.SETUP_PREFERENCE && PREFERENCES_ADD_EMAIL_SETUP_BANNER_TITLE}
                    {step === AddEmailSteps.VERIFY && EMAIL_VERIFICATION_BANNER_VERIFY_TITLE}
                </Typography>
            </Box>
            {hasError && (
                <Box sx={styles.error_message_container}>
                    <WarningIcon sx={styles.error_message_icon} />
                    <Typography aria-live="assertive" sx={styles.error_message}>
                        {errorMessage}
                    </Typography>
                </Box>
            )}
            <Box sx={styles.content_container}>
                <Box sx={styles.content}>
                    {step === AddEmailSteps.ADD && (
                        <Box sx={styles.input_section}>
                            <Typography sx={styles.input_label}>{PREFERENCES_ADD_EMAIL_FIELD_LABEL}</Typography>
                            <StyledInput value={email} onChange={e => setEmail(e.target.value)} className="text_field" ref={inputRef} />
                        </Box>
                    )}
                    {step === AddEmailSteps.VERIFY && (
                        <Box sx={styles.input_section}>
                            <Typography sx={styles.input_label}>{EMAIL_VERIFICATION_TYPE_CODE}</Typography>
                            <StyledInput
                                value={verificationCode}
                                onChange={e => setVerificationCode(e.target.value)}
                                className="text_field"
                                ref={inputCodeRef}
                                aria-label={ENTER_THE_CODE_THAT_WE_SEND_TO_YOUR_EMAIL_ADDRESS}
                            />
                        </Box>
                    )}
                    {step === AddEmailSteps.SETUP_PREFERENCE && (
                        <Typography sx={styles.set_message_preference_description}>
                            {PREFERENCES_ADD_EMAIL_SETUP_DESCRIPTION} <b style={styles.set_message_preference_email}>{`'${email}'?`}</b>
                        </Typography>
                    )}
                </Box>

                <Box sx={styles.buttons_container}>
                    <Button onClick={handleClose} variant="contained" sx={styles.cancel_button}>
                        {step === AddEmailSteps.ADD || step === AddEmailSteps.VERIFY
                            ? PREFERENCES_ADD_EMAIL_CANCEL_BUTTON
                            : PREFERENCES_ADD_EMAIL_SETUP_NO_BUTTON}
                    </Button>
                    <Box sx={styles.ButttonBox}>
                        <Button onClick={handleContinue} sx={styles.saveButton} data-testid="add_email_btn" variant="contained" disabled={startLoading || !isEmailValid}>
                            <Typography>
                                {step === AddEmailSteps.ADD && PREFERENCES_ADD_EMAIL_SAVE_BUTTON}
                                {step === AddEmailSteps.VERIFY && 'Verify'}
                                {step === AddEmailSteps.SETUP_PREFERENCE && PREFERENCES_ADD_EMAIL_SETUP_YES_BUTTON}
                            </Typography>
                        </Button>
                        {startLoading && (
                            <CircularProgress role="status" aria-live="assertive" aria-label={LOADING} size={24} sx={styles.CircularProgress} />
                        )}
                    </Box>
                </Box>
            </Box>
        </>
    );
}
