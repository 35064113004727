import theme from '../../config/style/muiTheme';

const styles = {
    mainContainer: {
        mt: '4px',
        height: 'calc(100vh - 70px)',
    },
    mainBox: {
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        height: '100%',
    },
    stackBody: {
        maxWidth: '480px',
        mx: 'auto',
        px: 2,
    },
    typographyTitle: {
        color: theme.palette.purple['80'],
        textAlign: 'center',
        fontFamily: 'Hornbill',
        fontSize: '40px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '112%',
        letterSpacing: '-1.2px',
        mb: '32px',
        mt: 16,
    },
    boxForInput: {
        mb: '32px',
        width: '100%',
    },
    input: {
        px: '16px',
        py: '8px',
        borderRadius: '6px',
        border: `1px solid ${theme.palette.purple['32']}`,
        background: theme.palette.common.white,
        '&:focus': {
            border: `2px solid ${theme.palette.purple['55']}`,
            background: theme.palette.grey['30'],
        },
    },
    basicButton: {
        mb: '17px',
        borderRadius: '12px',
        background: theme.palette.purple['45'],
        px: '20px',
        py: '12px',
        '&:disabled': {
            background: theme.palette.purple['45'],
            opacity: '0.4',
        },
    },
    buttonLabel: {
        color: theme.palette.common.white,
        fontFamily: 'Inter',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '140%',
        letterSpacing: '-0.36px',
    },
    cardChevronRightIcon: {
        width: '24px',
        height: '24px',
        mt: 'auto',
        mb: 'auto',
    },
    leyend: {
        color: theme.palette.purple['40'],
        textAlign: 'center',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '145%',
        mb: 2,
    },
    ButttonBox: {
        position: 'relative',
        width: '100%',
    },
    CircularProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-21px',
        marginLeft: '-12px',
    },
};

export default styles;
