import theme from "../../../config/style/muiTheme";

const styles = {
    avatarStyle: {
        margin: 0,
    },
    boxAvatars: {
        display: "flex",
        marginTop: 1
    },
    boxDate: {
        alignItems: "flex-end",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        width: "50%",
    },
    boxHandler: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    category: {
        fontSize: 10,
        height: 20
    },
    cardStyle: {
        width: 280,
        height: 225,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        marginRight: 0,
    },
    cardHeader: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 0,
    },
    itemList:{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "space-between",
        padding: "5px 0px",
        width: '100%',
    },
    subject: {
        color: theme.palette.text.primary
    },
};

export default styles;
