import React, { Fragment } from "react";
import NewHeader from '../NewHeader';

function Layout({ hideHeader = false, children }) {
    return (
        <Fragment>
            {/* <Header /> */}
            {! hideHeader &&
                <NewHeader />
            }
            {children}
        </Fragment>
    );
}

export default Layout;
