import theme from '../../../../config/style/muiTheme';

const styles = {
    sideBarListItem: {
        width: 40,
        height: 40,
        borderRadius: '6px',
        bgColor: theme.palette.grey[400],
    },
    ListItemButtonTypography: {
        fontWeight: '500',
        fontSize: '16px',
        color: theme.palette.purple[100],
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    bgColorForSelectedConversation: {
        bgcolor: `${theme.palette.purple[40]}1a`,
        borderRadius: '3px',
    },
};

export default styles;
