import React from 'react';
import { Box, Typography, Divider, Link } from '@mui/material';
import { utils } from 'comm-recipientapp-shared';
import Layout from '../Layout';
import styles from './styles';

function Learn() {
    const strings = utils.useStrings();
    return (
        <Layout>
            <Box sx={styles.main_container}>
                <Box sx={styles.container}>
                    <Box sx={styles.section}>
                        <Typography component="h2" sx={styles.title}>
                            {strings.LEARN_MORE_ABOUT_SCHOOL_MESSENGER}
                        </Typography>
                        <Typography sx={styles.text}>{strings.LEARN_MORE_SCREEN_LEARN_MORE_ABOUT_SCHOOL_MESSENGER}</Typography>
                    </Box>
                    <Box sx={styles.section}>
                        <Typography component="h2" sx={styles.title}>
                            {strings.LEARN_MORE_SCREEN_WHAT_IS_SCHOOL_MESSENGER}
                        </Typography>
                        <Divider sx={styles.divider} />
                        <Typography sx={styles.text}>{strings.LEARN_MORE_SCREEN_WHAT_IS_SCHOOL_MESSENGER_CONTENT}</Typography>
                        <Box component="ul">
                            <Typography sx={styles.text} component="li">
                                {strings.SEE_YOUR_RELATED_SCHOOL_MESSENGER_CONTACTS_ACROSS_SCHOOLS_AND_DISTRICTS}
                            </Typography>
                            <Typography sx={styles.text} component="li">
                                {strings.VIEW_THE_LAST_30_DAYS_OF_SCHOOL_MESSENGER_MESSAGES}
                            </Typography>
                            <Typography sx={styles.text} component="li">
                                {strings.CHANGE_YOUR_SCHOOL_MESSENGER_COMMUNICATION_PREFERENCES}
                            </Typography>
                            <Typography sx={styles.text} component="li">
                                {strings.OPT_INTO_PUSH_NOTIFICATIONS_MOBILE_ONLY}
                            </Typography>
                        </Box>
                        <Typography sx={styles.text}>{strings.WHERE_ENABLED_BY_YOUR_SCHOOL_OR_DISTRICT_YOU_MAY_ALSO_BE_ABLE_TO}</Typography>
                        <Box component="ul">
                            <Typography sx={styles.text} component="li">
                                {strings.UPDATE_YOUR_SCHOOLMESSENGER_CONTACT_INFORMATION_ADD_PHONE_NUMBERS_EMAILS_AND_SMS_TEXT_MESSAGE_NUMBERS}
                            </Typography>
                            <Typography sx={styles.text} component="li">
                                {strings.MANAGE_YOUR_STUDENT_ATTENDANCE_VIA_SAFEARRIVAL}
                            </Typography>
                            <Typography sx={styles.text} component="li">
                                {strings.CHAT_WITH_TEACHERS_VIA_TEACHER_MESSAGING}
                            </Typography>
                            <Typography sx={styles.text} component="li">
                                {strings.VIEW_ADDITIONAL_INFORMATION_LIKE_STUDENT_GRADES_LUNCH_BALANCE_DATA_ETC}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={styles.section}>
                        <Typography component="h2" sx={styles.title}>
                            {strings.HOW_DO_I_SIGN_UP_FOR_SCHOOL_MESSENGER}
                        </Typography>
                        <Divider sx={styles.divider} />
                        <Typography sx={styles.text}>{strings.SIGNING_UP_IS_EASY}</Typography>
                        <Box component="ol">
                            <Typography sx={styles.text} component="li">
                                {strings.CLICK_SIGN_UP}
                            </Typography>
                            <Typography sx={styles.text} component="li">
                                {strings.ENTER_YOUR_EMAIL_ADDRESS}
                            </Typography>
                            <Typography sx={styles.text} component="li">
                                {strings.AFTER_AUTHENTICATING}
                            </Typography>
                        </Box>
                        <Typography sx={styles.text}>{strings.ITS_THAT_EASY}</Typography>
                    </Box>
                    <Box sx={styles.section}>
                        <Typography component="h2" sx={styles.title}>
                            {strings.WHAT_SHOULD_I_EXPECT}
                        </Typography>
                        <Divider sx={styles.divider} />
                        <Typography sx={styles.text}>{strings.AFTER_SUCCESSFULLY_LOGGING_IN}</Typography>
                    </Box>
                    <Box sx={styles.section}>
                        <Typography component="h2" sx={styles.title}>
                            {strings.IS_SCHOOLMESSENGER_SECURE}
                        </Typography>
                        <Divider sx={styles.divider} />
                        <Typography sx={styles.text}>{strings.THE_SIGN_UP_PROCESS}</Typography>
                    </Box>
                    <Box sx={styles.section}>
                        <Typography component="h2" sx={styles.title}>
                            {strings.DO_YOU_HAVE_MORE_QUESTIONS}
                        </Typography>
                        <Typography sx={styles.text}>
                            {strings.FEEL_FREE_TO_EMAIL} <Link href="mailto:appfeedback@schoolmessenger.com">appfeedback@schoolmessenger.com</Link>
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Layout>
    );
}

export default Learn;
