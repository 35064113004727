/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { utils, actions } from 'comm-recipientapp-shared';
import MessageCard from './item';
import MessageEditModal from './ModalEdit';
import Notification from '../../Utils/Notification';
import { usePreferencesSettings } from '../hooks/usePreferencesSettings';
import SubTitle from '../../Shared/SubTitle';
import styles from './styles';

const { NotificationPreferences, ASCENDING, ERROR_AT_LEAST_ONE, NotificationType } = utils.constants;

function MessagePreferences({ activeSchool, showContent }) {
    const { requireEmergencyPhone, requireHighPriorityPhone } = usePreferencesSettings(activeSchool);
    const [open, setOpen] = useState(false);
    const [currentNotification, setCurrentNotification] = useState({});
    const [preferences, setPreferences] = useState({});
    const [notification, setNotification] = useState({
        open: false,
        type: '',
        message: '',
    });
    const [errorType, setErrorType] = useState('');
    const strings = utils.useStrings();
    const dispatch = useDispatch();
    const notifications = activeSchool?.notificationTypes;
    const data = notifications && notifications.concat().sort(utils.helpers.orderBy(['priority', 'name'], [ASCENDING, ASCENDING]));

    useEffect(() => {
        if (activeSchool) {
            setPreferences(activeSchool?.destinationPreferences || {});
        }
    }, [activeSchool]);

    const handleClose = () => {
        setPreferences(activeSchool?.destinationPreferences || {});
        setErrorType('');
        setOpen(!open);
    };

    const handleMessageDetail = _notification => {
        setCurrentNotification(_notification);
        setOpen(!open);
    };

    const handleUpdatePhone = phone => {
        setErrorType('');
        const phoneIndex = preferences.phones.findIndex(val => val.phone === phone);
        const currentNotifications = preferences.phones[phoneIndex].notificationTypes;
        const newNotifications = utils.helpers.toggleArrayValue(currentNotifications, currentNotification.id);

        setPreferences(preference => ({
            ...preference,
            phones: preference.phones.map((obj, i) => (i === phoneIndex ? { ...obj, notificationTypes: newNotifications } : obj)),
        }));
    };

    const handleUpdateSMS = phone => {
        const smsIndex = preferences.smses.findIndex(val => val.sms === phone);
        const currentNotifications = preferences.smses[smsIndex].notificationTypes;
        const newNotifications = utils.helpers.toggleArrayValue(currentNotifications, currentNotification.id);

        setPreferences(preference => ({
            ...preference,
            smses: preference.smses.map((obj, i) => (i === smsIndex ? { ...obj, notificationTypes: newNotifications } : obj)),
        }));
    };

    const handleUpdateEmail = email => {
        const emailIndex = preferences.emails.findIndex(val => val.email === email);
        const currentNotifications = preferences.emails[emailIndex].notificationTypes;
        const newNotifications = utils.helpers.toggleArrayValue(currentNotifications, currentNotification.id);

        setPreferences(preference => ({
            ...preference,
            emails: preference.emails.map((obj, i) => (i === emailIndex ? { ...obj, notificationTypes: newNotifications } : obj)),
        }));
    };

    const handleSavePreferences = async () => {
        try {
            const payload = {
                ...preferences,
            };

            let countEmergencyPhones = 0;
            let countAttendanceSelected = 0;

            preferences.phones?.map(phone => {
                if (utils.helpers.haveEmergencySelected(phone)) {
                    countEmergencyPhones += 1;
                }

                if (utils.helpers.haveAttendanceSelected(phone)) {
                    countAttendanceSelected += 1;
                }
            });

            if (countEmergencyPhones === 0 && currentNotification.name === NotificationPreferences.EMERGENCY && requireEmergencyPhone) {
                setErrorType(ERROR_AT_LEAST_ONE);
                return;
            }

            if (countAttendanceSelected === 0 && currentNotification.name === NotificationPreferences.ATTENDANCE && requireHighPriorityPhone) {
                setErrorType(ERROR_AT_LEAST_ONE);
                return;
            }

            const response = await dispatch(actions.preferences.updatePreferences(activeSchool?.id, payload));

            if (response) {
                setErrorType('');
                setOpen(!open);
            }
        } catch (error) {
            setNotification({
                open: true,
                type: NotificationType.ERROR,
                message: strings.SOMETHING_WENT_WRONG_TRY_LATER,
            });
        }
    };

    return (
        <>
            {showContent && (
                <Box sx={styles.content_messages}>
                    <Box sx={styles.header}>
                        <SubTitle sx={styles.header_title} variant="h5">
                            {strings.MY_MESSAGE_PREFERENCES}
                        </SubTitle>
                    </Box>
                    <Grid container rowSpacing={2} columnSpacing={2} sx={styles.message_list}>
                        {data?.map((notificationInfo, index) => (
                            <Grid key={notificationInfo.id} xs={12} sm={12} md={3}>
                                <MessageCard
                                    activeSchool={activeSchool}
                                    notification={notificationInfo}
                                    handleMessageDetail={handleMessageDetail}
                                    index={index}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    <MessageEditModal
                        activeSchool={activeSchool}
                        preferences={preferences}
                        handleClose={handleClose}
                        handleUpdatePhone={handleUpdatePhone}
                        handleUpdateSMS={handleUpdateSMS}
                        handleUpdateEmail={handleUpdateEmail}
                        notification={currentNotification}
                        handleSavePreferences={handleSavePreferences}
                        errorType={errorType}
                        open={open}
                        type="phone"
                    />
                    <Notification
                        open={notification.open}
                        handleClose={() => setNotification(...notification, { open: !notification.open })}
                        type={notification.type}
                        message={notification.message}
                    />
                </Box>
            )}
        </>
    );
}

export default MessagePreferences;
