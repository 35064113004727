/* eslint-disable operator-linebreak */
/* eslint-disable prettier/prettier */
import { useCallback } from 'react';
import { updateUserContactPreferences } from 'comm-recipientapp-shared/src/api/preferences';
import { ContactDestinationTypes } from 'comm-recipientapp-shared/src/utils/constants';
import EmailAlreadyExistError from '../ContactInformation/Errors/EmailAlreadyExists';
import NumberAlreadyExistError from '../ContactInformation/Errors/NumberAlreadyExist';
import MaxContactInfoReachedError from '../ContactInformation/Errors/MaxContactInfoReachedError';

/**
 * @param {{
 *     devices: [];
 *     emails: {
 *         canDelete: boolean;
 *         email: string;
 *         isImported: boolean;
 *         notificationTypes: number[];
 *     }[];
 *     phones: {
 *         canDelete: boolean;
 *         isImported: boolean;
 *         notificationTypes: number[];
 *         phone: string;
 *         registry: { consent: string; active: string };
 *     }[];
 *     smses: {
 *         canDelete: boolean;
 *         isImported: boolean;
 *         notificationTypes: number[];
 *         registry: { active: true };
 *     }[];
 * }} contactPreferences
 */
export default function useContactInformationServices(contactPreferences, school) {
    const addContactData = useCallback(
        /** @param {{ type: 'devices' | 'emails' | 'phones' | 'smses'; data: {} }} newContact */
        async newContact => {
            if (newContact.type === ContactDestinationTypes.PHONE) {
                const numberExist = contactPreferences.phones.find(phoneData => newContact.data.phone === phoneData.phone);

                if (numberExist) {
                    throw new NumberAlreadyExistError(`${newContact.errMessage.PREFERENCES_ADD_NUMBER_ERROR_ALREADY_EXIST}.`);
                }
            }

            if (newContact.type === ContactDestinationTypes.EMAIL) {
                const emailExist = contactPreferences.emails.find(emailData => newContact.data.email === emailData.email);

                if (emailExist) {
                    throw new EmailAlreadyExistError(`${newContact.errMessage.PREFERENCES_ADD_EMAIL_ERROR_ALREADY_EXIST}.`);
                }
            }

            const updatedPreferences = structuredClone(contactPreferences);

            if (!Array.isArray(updatedPreferences[newContact.type])) {
                updatedPreferences[newContact.type] = [];
            }

            updatedPreferences[newContact.type].push(newContact.data);

            try {
                return await updateUserContactPreferences(updatedPreferences, school.id);
            } catch (error) {
                if (error?.response?.status === 400 && error?.response?.data?.message === 'Unable to add destination') {
                    // can be other 400 errors for different situations
                    throw new MaxContactInfoReachedError(
                        newContact.type === ContactDestinationTypes.PHONE
                            ? newContact.errMessage.PREFERENCES_ADD_NUMBER_ERROR_MAX_NUMBER_REACHED
                            : newContact.errMessage.PREFERENCES_ADD_EMAIL_ERROR_MAX_NUMBER_REACHED
                    );
                }
            }
        },
        [contactPreferences, school]
    );

    const deleteContactData = useCallback(
        /** @param {{ type: 'devices' | 'emails' | 'phones' | 'smses'; contactToDelete: string }} contactToDelete */
        ({ type, contactToDelete }) => {
            const updatedPreferences = structuredClone(contactPreferences);

            const contactArray = updatedPreferences[type];

            const propertyName = type === ContactDestinationTypes.EMAIL ? 'email' : 'phone';

            updatedPreferences[type] = contactArray.filter(contactData => contactData[propertyName] !== contactToDelete);

            if (type === ContactDestinationTypes.PHONE) {
                const smsArray = updatedPreferences[ContactDestinationTypes.SMS];
                const newSmsArray = smsArray.filter(contactData => contactData.sms !== contactToDelete);

                updatedPreferences[ContactDestinationTypes.SMS] = newSmsArray;
            }

            return updateUserContactPreferences(updatedPreferences, school.id);
        },
        [contactPreferences, school]
    );

    return { addContactData, deleteContactData };
}
