import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { utils } from 'comm-recipientapp-shared';
import { getConsistentColorFromString } from 'comm-recipientapp-shared/src/utils/avatar';
import styles from './styles';

const { stringAvatar } = utils.avatar;

export default function StudentItem({ student }) {
    const backgroundColor = useMemo(() => {
        return getConsistentColorFromString(`${student?.personId}${student?.firstName}${student?.lastName}`);
    }, [student]);

    return (
        <Box
            sx={{
                ...styles.initials_container,
                backgroundColor,
            }}
        >
            <Typography {...stringAvatar(`${student?.firstName} ${student?.lastName}`)} sx={styles.initials_text} />
        </Box>
    );
}
