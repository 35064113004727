import theme from "../../../config/style/muiTheme";

const styles = {
    closeButton: { 
        position: "absolute", 
        right: "15px", 
        top: "15px" 
    },
    logo: {
        width: "70%",
        minWidth: "160px",
        maxWidth: "300px"
    },
    title: {
        fontWeight: '500',
        fontSize: '18px',
        lineHeight: '25.44px',
        textAlign: 'center',
        color: theme.palette.primary.main
    },
    dialogContent: { 
        justifyContent: "center", 
        textAlign: "center" 
    },
    returnButton: { 
        marginTop: "20px" 
    },
    inboxButton: { 
        height: "20px", 
        width: "20px" 
    },
    inboxText: { 
        marginLeft: "10px" 
    }
}

export default styles
