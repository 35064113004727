/* eslint-disable prettier/prettier */
import React from 'react';
import { Dialog, Box, Typography } from '@mui/material';
import { useStrings, constants } from 'comm-recipientapp-shared/src/utils';
import Button from '../../../Shared/Button';
import Styles from './styles';

export default function UpdateContactPreferencesPopup(props) {
    const { type, open, onClose, formattedLastAddedContact, handleAfirmativeAction } = props;

    const { ContactTypes } = constants;

    const {
        PREFERENCES_SET_PREFERENCES_TO_NUMBER_HEADER_TITLE_PHONE,
        PREFERENCES_SET_PREFERENCES_TO_NUMBER_HEADER_TITLE_EMAIL,
        PREFERENCES_SET_PREFERENCES_TO_NUMBER_NO_BUTTON,
        PREFERENCES_SET_PREFERENCES_TO_NUMBER_YES_BUTTON,
        PREFERENCES_SET_PREFERENCES_TO_NUMBER_POPUP_TEXT,
    } = useStrings();

    return (
        <Dialog open={open} onClose={() => onClose(false)} PaperProps={{ sx: Styles.modal_styles }}>
            <Box sx={Styles.add_preferences_for_number_header}>
                <Typography sx={Styles.add_preferences_for_number_header_text}>Verification Succesful!</Typography>
            </Box>
            <Typography sx={Styles.add_preferences_for_number_text_container}>
                {PREFERENCES_SET_PREFERENCES_TO_NUMBER_POPUP_TEXT}: {`'${formattedLastAddedContact}'`} ?
            </Typography>
            <Box sx={Styles.add_preferences_for_number_buttons_container}>
                <Button
                    sx={Styles.add_preferences_for_number_cancel_button}
                    onClick={() => {
                        onClose(false);
                    }}
                >
                    {PREFERENCES_SET_PREFERENCES_TO_NUMBER_NO_BUTTON}
                </Button>
                <Button onClick={handleAfirmativeAction}>{PREFERENCES_SET_PREFERENCES_TO_NUMBER_YES_BUTTON}</Button>
            </Box>
        </Dialog>
    );
}
