import React, { useEffect, useMemo, useState } from 'react';
import { Divider, Box, Select, MenuItem } from '@mui/material';
import { get24HourTimeStringFrom12HourTimeString, get12HourTimeStringFrom24HourTimeString } from 'comm-recipientapp-shared/src/utils/attendanceUtils';
import styles from '../../styles';

const hoursArray = Array.from({ length: 12 }, (_, i) => `${i < 9 ? '0' : ''}${i + 1}`);
const minutesArray = Array.from({ length: 60 }, (_, i) => `${i < 9 ? '0' : ''}${i}`);

/**
 * @typedef {object} TimePickerProps
 * @property {(newTime: string) => void} onTimeChange
 * @property {string} defaultTime - Time in 24 hour format e.g. 16:00
 * @param {React.PropsWithChildren<TimePickerProps>} props
 */
export default function TimePicker({ onTimeChange, defaultTime = '01:00', fullWidth = false }) {
    const { meridiemIndicator, timeString12hr } = useMemo(() => {
        const formattedHour = get12HourTimeStringFrom24HourTimeString(defaultTime);
        return formattedHour;
    }, [defaultTime]);

    const [hours, setHours] = useState(timeString12hr.split(':')[0] < 9 ? `0${timeString12hr.split(':')[0]}` : timeString12hr.split(':')[0]);
    const [minutes, setMinutes] = useState(timeString12hr.split(':')[1]);
    const [meridiem, setMeridiem] = useState(meridiemIndicator);

    useEffect(() => {
        if (onTimeChange) {
            const newTime = get24HourTimeStringFrom12HourTimeString(`${hours}:${minutes}`, meridiem);

            onTimeChange(newTime);
        }
    }, [hours, minutes, meridiem, onTimeChange]);

    return (
        <Box sx={styles.time_picker_container}>
            <Box sx={styles.time_picker_select_container}>
                <Select
                    disableUnderline
                    variant="standard"
                    value={hours}
                    placeholder="HH"
                    onChange={e => setHours(e.target.value)}
                    sx={styles.edit_popup_calendar_date_text}
                >
                    {hoursArray.map(hour => (
                        <MenuItem key={`hour-item-${hour}`} value={hour}>
                            {hour}
                        </MenuItem>
                    ))}
                </Select>
                <Box sx={styles.time_picker_column}>:</Box>
                <Select
                    disableUnderline
                    variant="standard"
                    value={minutes}
                    placeholder="MM"
                    onChange={e => setMinutes(e.target.value)}
                    sx={styles.edit_popup_calendar_date_text}
                >
                    {minutesArray.map(minute => (
                        <MenuItem key={`minute-item-${minute}`} value={minute}>
                            {minute}
                        </MenuItem>
                    ))}
                </Select>
                <Select
                    disableUnderline
                    variant="standard"
                    value={meridiem}
                    placeholder="AM/PM"
                    onChange={e => setMeridiem(e.target.value)}
                    sx={styles.edit_popup_calendar_date_text}
                >
                    <MenuItem value="AM">AM</MenuItem>
                    <MenuItem value="PM">PM</MenuItem>
                </Select>
            </Box>

            <Divider sx={fullWidth ? { ...styles.time_picker_divider, width: '24rem' } : styles.time_picker_divider} variant="fullWidth" />
        </Box>
    );
}
