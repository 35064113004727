/* eslint-disable operator-linebreak */
/* eslint-disable prettier/prettier */
/* eslint-disable no-async-promise-executor */
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {
    Box,
    Dialog,
    Fab,
    Menu,
    MenuItem,
    Typography,
    Snackbar,
    IconButton,
    Alert
} from '@mui/material/';
import Grid from '@mui/material/Unstable_Grid2';
import WarningIcon from '@mui/icons-material/Warning';
import { actions, env, utils } from 'comm-recipientapp-shared';
import { isEmailFormatValid } from 'comm-recipientapp-shared/src/validations/common';
import { handlePhonePriorityEnforcement, stringContainsOnlyDigits } from 'comm-recipientapp-shared/src/utils/preferencesUtils';
import { AddPhoneNumberModes, YES } from 'comm-recipientapp-shared/src/utils/constants';
import ContactItem from './item';
import AddPhone from './AddPhone';
import AddEmail from './AddEmail';
import DeleteContactInformation from './DeleteContactInformation';
import NotAllowedToDeletePopup from './NotAllowedToDeletePopup';
import useContactInformationServices from '../hooks/useContactInformationServices';
import { usePreferencesSettings } from '../hooks/usePreferencesSettings';
import useSnackBar from '../hooks/useSnackBar';
import SubTitle from '../../Shared/SubTitle';
import ModalError from '../modalError';
import ModalErrorNotAllowToDelete from '../modalErrorNotAllowToDelete';
import UpdateContactPreferencesPopup from './UpdateContactPreferencesPopup';
import styles from './styles';

const { ContactTypes, ContactDestinationTypes, ContactOrigin } = utils.constants;

const isPhoneVerificationEnabled = env.IS_PHONE_VERIFICATION_ENABLED === 'true';

function ContactInformation(props) {
    const { activeSchool, showContent, showAddMore } = props;

    const strings = utils.useStrings();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [showModalError, setShowModalError] = useState(false);
    const [showModalErrorNotAllowToDelete, setShowModalErrorNotAllowToDelete] = useState(false);
    const [selectedPhone, setSelectedPhone] = useState('');
    const [isAddContactInformationOpen, setIsAddContactInformationOpen] = useState(false);
    const [isDeleteContactInformationOpen, setIsDeleteContactInformationOpen] = useState(false);
    const [isDeleteAccountEmailOpen, setIsDeleteAccountEmailOpen] = useState(false);
    const [mode, setMode] = useState(AddPhoneNumberModes.ADD);
    const [typeOfContact, setTypeOfContact] = useState(ContactTypes.PHONE);
    const [phones, setPhones] = useState([]);
    const [emails, setEmails] = useState([]);
    const [sms, setSms] = useState([]);

    const [isAddTextMessagesToNewNumberPopupOpen, setIsAddTextMessagesToNewNumberPopupOpen] = useState(false);

    const { addContactData, deleteContactData } = useContactInformationServices(activeSchool?.destinationPreferences, activeSchool);

    const { requireEmergencyPhone, requireHighPriorityPhone, canDeleteImportedDestinations, canAddDeleteManualDestinations } =
        usePreferencesSettings(activeSchool);

    const { handleSnackBarOpen, handleSnacknBarClose, isSnackBarOpen, setSnackBarMessage, snackBarMessage } = useSnackBar();

    const userEmail = useSelector(state => state.auth.auth.sub);

    const numberToVerifyRef = useRef(null);
    const openByEnterKey = useRef(false);

    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const lastAddedContact = useRef();

    const formattedLastAddedContact = useMemo(() => {
        if (typeOfContact === ContactTypes.PHONE) {
            return lastAddedContact.current?.replace(/^(\d{3})(\d{3})(\d{4}).*/, '($1) $2-$3');
        }
        return lastAddedContact.current; // Email doesn't need format
    }, [lastAddedContact.current, typeOfContact]);

    const refetchData = async () => {
        const fetched = await dispatch(actions.preferences.getData());
        handlePhonePriorityEnforcement(fetched.data, 'web');
    };

    const contactToDeleteRef = useRef(null);

    const open = Boolean(anchorEl);

    const parsePhones = () => {
        const data = activeSchool?.destinationPreferences?.phones?.reduce((acc, contactPhone) => {
            return [...acc, { ...contactPhone, type: utils.constants.PHONE }];
        }, []);
        setPhones(data || []);
    };

    const parseSms = () => {
        const data = activeSchool?.destinationPreferences?.smses?.reduce((acc, contactSms) => {
            return [...acc, { ...contactSms, type: utils.constants.SMS }];
        }, []);
        setSms(data || []);
    };

    const handleClick = event => setAnchorEl(event.currentTarget);

    const handleEnterKeyPress = e => {
        if (e.key === 'Enter') {
            openByEnterKey.current = true;
            setAnchorEl(e.currentTarget)
        }
    };

    const handleClose = () => setAnchorEl(null);

    const handleAddContactInformation = contactType => {
        setTypeOfContact(contactType); // ContatType can be PHONE, EMAIL or SMS (always Uppercase)
        setMode(AddPhoneNumberModes.ADD);
        setIsAddContactInformationOpen(true);
    };

    const handleAddContactInformationClose = () => {
        setIsAddContactInformationOpen(false);
    };

    const handleDeletePhoneContactCard = contactToDelete => {
        /** Check if it requires at least 1 phone to be available */
        setSelectedPhone(contactToDelete);
        let countPhonesWithEmergencySelected = 0;
        let countPhonesWithAttendanceSelected = 0;
        let lastPhoneMarkedAsEmergencyPhone = {};
        let lastPhoneMarkedAsHighPriorityPhone = {};
        phones.forEach(phone => {
            if (utils.helpers.haveEmergencySelected(phone)) {
                countPhonesWithEmergencySelected += 1;
                lastPhoneMarkedAsEmergencyPhone = phone;
            }

            if (utils.helpers.haveAttendanceSelected(phone)) {
                countPhonesWithAttendanceSelected += 1;
                lastPhoneMarkedAsHighPriorityPhone = phone;
            }
        });

        /* Check if user is allowed to remove their contact info imported by admin */
        if (!canDeleteImportedDestinations && utils.helpers.isImportedPhone(activeSchool, contactToDelete)) {
            setShowModalErrorNotAllowToDelete(ContactOrigin.IMPORTED);
            return;
        }

        /* Check if user is allowed to remove their contact info non-imported by admin */
        if (!canAddDeleteManualDestinations && !utils.helpers.isImportedPhone(activeSchool, contactToDelete)) {
            setShowModalErrorNotAllowToDelete(ContactOrigin.NON_IMPORTED);
            return;
        }

        /* Modal "Not allow to deleted" can be shown due to: */
        /* Emergency or high priority phone is require and there is just one phone left */
        if ((requireHighPriorityPhone || requireEmergencyPhone) && phones.length === 1) {
            setShowModalError(true);
            return;
        }

        /* Or, user is trying to delete the las phone that is marked as emergency number */
        if (requireEmergencyPhone && countPhonesWithEmergencySelected <= 1 && lastPhoneMarkedAsEmergencyPhone?.phone === contactToDelete) {
            setShowModalError(true);
            return;
        }

        /* Or, user is trying to delete the las phone that is marked as attendance number */
        if (requireHighPriorityPhone && countPhonesWithAttendanceSelected <= 1 && lastPhoneMarkedAsHighPriorityPhone?.phone === contactToDelete) {
            setShowModalError(true);
            return;
        }

        contactToDeleteRef.current = contactToDelete;
        setIsDeleteContactInformationOpen(true);
    };

    const handleDeleteSmsContactCard = smsToDelete => {
        contactToDeleteRef.current = smsToDelete;
        setIsDeleteContactInformationOpen(true);
    };

    const handleDeleteEmailContactCard = emailToDelete => {
        /* Check if user is allowed to remove their contact info imported by admin */
        if (!canDeleteImportedDestinations && utils.helpers.isImportedEmail(activeSchool, emailToDelete)) {
            setShowModalErrorNotAllowToDelete(ContactOrigin.IMPORTED);
            return;
        }

        /* Check if user is allowed to remove their contact info non-imported by admin */
        if (!canAddDeleteManualDestinations && !utils.helpers.isImportedEmail(activeSchool, emailToDelete)) {
            setShowModalErrorNotAllowToDelete(ContactOrigin.NON_IMPORTED);
            return;
        }

        /*
            For Email: Check if the contact to delete is equal to the user email,
            if that is the case, We show a message explaining why this is not allowed.
        */
        contactToDeleteRef.current = emailToDelete;
        if (emailToDelete === userEmail) {
            setIsDeleteAccountEmailOpen(true);
            return;
        }

        setIsDeleteContactInformationOpen(true);
    };

    const handleDeleteContactInformationClose = () => {
        contactToDeleteRef.current = null;
        setIsDeleteContactInformationOpen(false);
    };

    const handleContactCardClick = (parameterToVerify, type, isVefified = true) => {
        if (!isVefified && isPhoneVerificationEnabled) {
            numberToVerifyRef.current = parameterToVerify;
            setMode(AddPhoneNumberModes.VERIFY);
            setIsAddContactInformationOpen(true);
            return;
        }

        navigate(`/preferences/edit/${type}/${parameterToVerify}`, {
            state: {
                type,
                goBackTo: pathname
            },
        });
    };

    const handleDeleteAccountEmailClose = () => {
        contactToDeleteRef.current = null;
        setIsDeleteAccountEmailOpen(false);
    };

    /**
     * @param {string} type
     * @param {string} newData Can be an email or phone number
     */
    const addNewContact = (type, newData) => {
        const defualtNotificationTypes = activeSchool.notificationTypes.map(notificationType => notificationType.id);

        const newContact = {
            [type === ContactDestinationTypes.PHONE ? 'phone' : 'email']: newData,
            notificationTypes: defualtNotificationTypes,
            canDelete: canAddDeleteManualDestinations,
            isImported: false, // default option
        };

        if (type === ContactDestinationTypes.PHONE) {
            newContact.registry = { consent: YES };
        }

        const errMessage = {
            PREFERENCES_ADD_EMAIL_ERROR_ALREADY_EXIST: strings.PREFERENCES_ADD_EMAIL_ERROR_ALREADY_EXIST,
            PREFERENCES_ADD_EMAIL_ERROR_MAX_NUMBER_REACHED: strings.PREFERENCES_ADD_EMAIL_ERROR_MAX_NUMBER_REACHED,
            PREFERENCES_ADD_NUMBER_ERROR_ALREADY_EXIST: strings.PREFERENCES_ADD_NUMBER_ERROR_ALREADY_EXIST,
            PREFERENCES_ADD_NUMBER_ERROR_MAX_NUMBER_REACHED: strings.PREFERENCES_ADD_NUMBER_ERROR_MAX_NUMBER_REACHED,
        };

        return new Promise(async (resolve, reject) => {
            try {
                const response = await addContactData({ type, data: newContact, errMessage });
                resolve(response);
                lastAddedContact.current = newData;
                await refetchData(true);

                if (isPhoneVerificationEnabled && type === ContactDestinationTypes.PHONE) {
                    // open the verification popup after the users adds a brand new phone number
                    handleContactCardClick(newData, type, false);
                } else {
                    lastAddedContact.current = newData;
                    if (type === ContactDestinationTypes.PHONE) {
                        setIsAddTextMessagesToNewNumberPopupOpen(true);
                    }
                }
            } catch (error) {
                reject(error);
            }
        });
    };

    /**
     * @param {string} type
     * @param {string} contactData Can be an email or phone number
     */
    const deleteContact = async (type, contactToDelete) => {
        const translatedMessage = isEmailFormatValid(contactToDelete)
            ? strings.PREFERENCES_SNACKBAR_DELETED_EMAIL_MESSAGE
            : strings.PREFERENCES_SNACKBAR_DELETED_PHONE_MESSAGE;

        const messageSplitted = translatedMessage.split('/');
        try {
            await deleteContactData({ type, contactToDelete });
            handleDeleteContactInformationClose();
            setSnackBarMessage(
                `${messageSplitted[0]} ${
                    stringContainsOnlyDigits(contactToDelete) ? contactToDelete.replace(/^(\d{3})(\d{3})(\d{4}).*/, '($1) $2-$3') : contactToDelete
                } ${messageSplitted[1]}`
            );
            handleSnackBarOpen();
            refetchData();
        } catch (error) {
            handleDeleteContactInformationClose();
        }
    };

    useEffect(() => {
        if (activeSchool) {
            // eslint-disable-next-line max-len
            parsePhones();
            parseSms();
            setEmails(activeSchool.destinationPreferences?.emails ?? []);
        }
    }, [activeSchool]);

    return (
        <>
            <Snackbar
                open={isSnackBarOpen}
                onClose={handleSnacknBarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={6000}
            >
                <Alert
                    action={
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={handleSnacknBarClose}
                            tabIndex={0}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                    severity="info"
                >
                    {snackBarMessage}
                </Alert>
            </Snackbar>

            <Dialog open={isAddContactInformationOpen} onClose={handleAddContactInformationClose} PaperProps={{ sx: styles.modal_styles }}>
                {typeOfContact === ContactTypes.PHONE && (
                    <AddPhone
                        mode={mode}
                        numberToVerify={numberToVerifyRef.current}
                        handleClose={handleAddContactInformationClose}
                        handleAddNumber={addNewContact}
                        handleModeChange={newMode => setMode(newMode)}
                        customerId={activeSchool.id}
                        openSmsOptionsPopup={phone => {
                            lastAddedContact.current = phone;
                            setIsAddTextMessagesToNewNumberPopupOpen(true);
                        }}
                        refetchData={refetchData}
                    />
                )}

                {typeOfContact === ContactTypes.EMAIL && <AddEmail handleClose={handleAddContactInformationClose} handleAddEmail={addNewContact} />}
            </Dialog>

            <Dialog open={isDeleteContactInformationOpen} onClose={handleDeleteContactInformationClose} PaperProps={{ sx: styles.modal_styles }}>
                <DeleteContactInformation
                    contactToDelete={contactToDeleteRef.current}
                    handleCancel={handleDeleteContactInformationClose}
                    handleDeleteContact={deleteContact}
                />
            </Dialog>

            <Dialog open={isDeleteAccountEmailOpen} onClose={handleDeleteAccountEmailClose} PaperProps={{ sx: styles.modal_styles }}>
                <NotAllowedToDeletePopup handleClose={handleDeleteAccountEmailClose} emailToDelete={contactToDeleteRef.current} />
            </Dialog>

            {isAddTextMessagesToNewNumberPopupOpen && (
                <UpdateContactPreferencesPopup
                    open={isAddTextMessagesToNewNumberPopupOpen}
                    type={typeOfContact}
                    onClose={setIsAddTextMessagesToNewNumberPopupOpen}
                    formattedLastAddedContact={formattedLastAddedContact}
                    handleAfirmativeAction={() =>
                        handleContactCardClick(typeOfContact === ContactTypes.EMAIL ? 0 : lastAddedContact.current, typeOfContact.toLowerCase())
                    }
                />
            )}

            <Box sx={styles.contact_information}>
                <Box sx={styles.header}>
                    <SubTitle>{strings.MY_CONTACT_INFORMATION}</SubTitle>
                    {showAddMore && (
                        <Box style={styles.actions}>
                            <Typography id="add-more-contact-information" sx={styles.report_title} variant="subtitle2" aria-hidden>
                                {strings.ADD_MORE}
                            </Typography>
                            <Fab
                                aria-controls={open ? 'demo-customized-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup="true"
                                color="primary"
                                id="demo-customized-button"
                                data-testid="add_more"
                                onClick={handleClick}
                                onKeyDown={handleEnterKeyPress}
                                size="small"
                                aria-describedby='add-more-contact-information'
                            >
                                <AddIcon fontSize="small" />
                            </Fab>
                            <Menu
                                anchorEl={anchorEl}
                                id="add-more"
                                MenuListProps={{
                                    'aria-labelledby': 'add-more',
                                }}
                                onClose={handleClose}
                                sx={styles.menu}
                                elevation={0}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={open}
                            >
                                <MenuItem
                                    disableRipple
                                    data-testid="handle_add_phone"
                                    onClick={() => {
                                        handleAddContactInformation(ContactTypes.PHONE);
                                        handleClose();
                                    }}
                                    sx={openByEnterKey.current? styles.focusByKeyboarNavegation:{}}
                                >
                                    {strings.ADD_A_PHONE}
                                </MenuItem>
                                <MenuItem
                                    disableRipple
                                    data-testid="handle_add_email"
                                    onClick={() => {
                                        handleAddContactInformation(ContactTypes.EMAIL);
                                        handleClose();
                                    }}
                                    sx={openByEnterKey.current? styles.focusByKeyboarNavegation:{}}
                                >
                                    {strings.ADD_AN_EMAIL_ADDRESS}
                                </MenuItem>
                            </Menu>
                        </Box>
                    )}
                </Box>
                {!showContent && (
                    <Box style={styles.contact_info_error_container}>
                        <span style={styles.contact_info_error_image}>
                            <WarningIcon />
                        </span>
                        <span style={styles.contact_info_error_message}>{strings.YOU_CURRENTLY_HAVE_NO_PHONES_IN_YOUR_SCHOOL_MESSAGE}</span>
                        <span>
                            <br />
                            <br />
                        </span>
                        <span style={styles.contact_info_error_instruction}>{strings.YOU_CURRENTLY_HAVE_NO_PHONES_IN_YOUR_SCHOOL_INSTRUCTION}</span>
                    </Box>
                )}
                {showContent && (
                    <Grid columnSpacing={1} container rowSpacing={1} sx={styles.contact_list}>
                        {phones.map((list, index) => (
                            <Grid key={list.phone ?? list.sms} xs={12} sm={12} md={4}>
                                <ContactItem
                                    handleClick={handleContactCardClick}
                                    handleOnDelete={handleDeletePhoneContactCard}
                                    index={index}
                                    item={list}
                                    type={list.type}
                                />
                            </Grid>
                        ))}
                        {sms.map((list, index) => (
                            <Grid key={list.phone ?? list.sms} xs={12} sm={12} md={4}>
                                <ContactItem
                                    handleClick={handleContactCardClick}
                                    handleOnDelete={handleDeleteSmsContactCard}
                                    index={index}
                                    item={list}
                                    type={list.type}
                                />
                            </Grid>
                        ))}
                        {emails.map((list, index) => {
                            const type = utils.helpers.getTypeOfInformation(list);
                            return (
                                <Grid key={list.email} xs={12} sm={12} md={4}>
                                    <ContactItem
                                        handleClick={handleContactCardClick}
                                        handleOnDelete={handleDeleteEmailContactCard}
                                        index={index}
                                        item={list}
                                        type={type}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                )}
            </Box>
            <ModalError visible={showModalError} handleClose={() => setShowModalError(false)} selectedPhone={selectedPhone} />
            {showModalErrorNotAllowToDelete && (
                <ModalErrorNotAllowToDelete visible={showModalErrorNotAllowToDelete} handleClose={() => setShowModalErrorNotAllowToDelete(false)} />
            )}
        </>
    );
}

export default ContactInformation;
