import { styled } from '@mui/material';

const SubTitle = styled('h2')({
    fontSize: '20px',
    fontWeight: '300',
    lineHeight: '28px',
    textDecoration: 'none solid rgb(44, 88, 113)',
    color: '#2C5871',
    margin: '0px'
});

export default SubTitle;
