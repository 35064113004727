import React, { useEffect } from 'react';
import { Box, Typography, Divider, Link, useMediaQuery, Autocomplete, TextField, CircularProgress, Dialog } from '@mui/material';
import { utils, env } from 'comm-recipientapp-shared';
import { Trans } from 'react-i18next';
import { getSMSActions, updateOneWaySMSSupportPreferences } from 'comm-recipientapp-shared/src/api/preferences';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStrings } from 'comm-recipientapp-shared/src/utils';
import { NotificationType } from 'comm-recipientapp-shared/src/utils/constants';
import Layout from '../../Layout';
import styles from '../styles';
import Button from '../../Shared/Button';
import Notification from '../../Utils/Notification';

function OneWaySMSSupport() {
    const strings = utils.useStrings();
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const accessCode = searchParams.get('c');
    const language = searchParams.get('l');

    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const [selectedAction, setSelectedAction] = React.useState(null);
    const [availableActions, setAvailableActions] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const fetchAvailableActions = async languageCode => {
        const { data } = await getSMSActions(languageCode);

        setAvailableActions(
            Object.keys(data).map(key => {
                return { label: data[key], action: key };
            })
        );
    };

    const onSubmit = async () => {
        setIsLoading(true);
        try {
            await updateOneWaySMSSupportPreferences({ language, action: selectedAction.action, accessCode });
            setIsSuccess(true);
        } catch (error) {
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const onCloseSuccessPopUp = () => navigate('/inbox');

    useEffect(() => {
        if (language) {
            fetchAvailableActions(language);
        }
    }, [language]);

    return (
        <Layout>
            <Box sx={styles.main_container}>
                <Box sx={isSmallScreen ? styles.container_mobile : styles.container}>
                    <TermsAndCondition />
                    <OptInInvitation isSmallScreen={isSmallScreen} />
                </Box>
                <Box sx={styles.actions_box}>
                    <Autocomplete
                        disablePortal
                        sx={{ width: 300 }}
                        options={availableActions}
                        getOptionLabel={option => option.label}
                        value={selectedAction}
                        onChange={(_, value) => setSelectedAction(value)}
                        renderInput={params => <TextField fullWidth variant="standard" label={strings.SELECT} {...params} />}
                    />
                    <Box sx={{ ...styles.buttton_box, ...{ width: isSmallScreen ? '100%' : '20%' } }}>
                        <Button
                            color="primary"
                            disabled={!selectedAction}
                            fullWidth
                            type="button"
                            onClick={onSubmit}
                            variant="contained"
                            tabIndex={0}
                        >
                            {strings.SUBMIT}
                        </Button>
                        {isLoading && (
                            <CircularProgress
                                role="status"
                                aria-live="assertive"
                                aria-label={strings.LOADING}
                                size={24}
                                sx={styles.circular_progress}
                            />
                        )}
                    </Box>
                </Box>
            </Box>
            <SuccessPopUp isOpen={isSuccess} onClose={onCloseSuccessPopUp} />
            <Notification
                open={isError}
                handleClose={() => setIsError(false)}
                type={NotificationType.WARNING}
                message={strings.SOMETHING_WENT_WRONG_TRY_LATER}
            />
        </Layout>
    );
}
export default OneWaySMSSupport;

function TermsAndCondition() {
    const strings = utils.useStrings();

    return (
        <Box sx={styles.column1}>
            <Box sx={styles.section}>
                <Typography component="h2" sx={styles.title}>
                    {strings.TERMS_AND_CONDITIONS}
                </Typography>
                <Typography sx={styles.text}>{strings.SCHOOLMESSENGER_ALLOWS_SCHOOLS_DISTRICTS_COLLEGES_AND_UNIVERSITIES}</Typography>
            </Box>
            <Divider sx={styles.divider} />
            <Box sx={styles.section}>
                <Typography sx={styles.text}>
                    <Trans>{strings.FOR_USERS_IN_THE_UNITED_STATES}</Trans>
                </Typography>
                <Typography sx={styles.disclaimer_text}>{strings.THE_MOBILE_CARRIERS_ARE_NOT_LIABLE_FOR_DELAYED_OR_UNDELIVERED_MESSAGES}</Typography>
            </Box>
            <Divider sx={styles.divider} />
            <Box sx={styles.section}>
                <Typography sx={styles.subtext}>{strings.WE_RESPECT_YOUR_PRIVACY}</Typography>

                <Link sx={styles.link} href={env.PRIVACY_POLICY_URL} target="_blank">
                    {` ${strings.HERE}`}.
                </Link>
                <Typography sx={styles.disclaimer_text}>
                    {strings.EMERGENCY_ALERT_SERVICES_USING_SMS_TEXT_MESSAGING_ARE_SUPPORTED_ONLY_IN_YHE_US}
                </Typography>
            </Box>
        </Box>
    );
}

function OptInInvitation({ isSmallScreen }) {
    const strings = utils.useStrings();
    return (
        <Box sx={isSmallScreen ? styles.column2_mobile : styles.column2}>
            <Box sx={styles.section}>
                <Typography sx={styles.title}>{strings.DID_YOU_RECEIVE_AN_OPT_IN_INVITATION_TEXT_MESSAGE}</Typography>
                <Typography sx={styles.text}>{strings.IF_YOU_RECEIVED_A_TEXT_MESSAGE_REQUESTING_YOU_TO_OPT_INTO_YOUR_SCHOOL}</Typography>
            </Box>
        </Box>
    );
}

function SuccessPopUp({ isOpen, onClose }) {
    const strings = useStrings();
    return (
        <Dialog open={isOpen} PaperProps={{ sx: styles.modal_styles }}>
            <Box sx={styles.unsubscribed_contianer}>
                <Typography sx={styles.success_text} aria-live="polite">
                    {strings.YOUR_RESPONSE_IS_SUBMITTED_SUCCESSFULLY}
                </Typography>
            </Box>
            <Box sx={styles.success_button}>
                <Button onClick={onClose} sx={styles.save_button} variant="contained">
                    {strings.OKAY}
                </Button>
            </Box>
        </Dialog>
    );
}
