/* eslint-disable prettier/prettier */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal,
    Box,
    Typography,
    Stack,
    ButtonGroup,
    IconButton,
    FormHelperText,
    Button,
    CircularProgress,
    TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { utils, hooks, validations } from 'comm-recipientapp-shared';
import { joinDiscussionGroup } from 'comm-recipientapp-shared/src/api/chat';
import { setCurrentChat } from 'comm-recipientapp-shared/src/store/chat/chatSlice';

import styles from "./styles";

export default function JoinGroupModal({ open, closeModal }) {
    const strings = utils.useStrings();
    const dispatch = useDispatch();

    const { getMemberOfByChatUser } = hooks.chat.useChat();
    const { currentChatUser } = useSelector(state => state.chat);
    const [joinButtonDisable, setJoinButtonDisable] = React.useState(true);
    const [codeValue, setCodeValue] = React.useState('');
    const [errorDisplay, setErrorDisplay] = React.useState('none');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [startLoading, setStartLoading] = React.useState(false);

    const {
        ALPHANUMERIC_ERROR,
        NOT_FOUND_ERROR,
        NO_PERMISSION_ERROR,
    } = utils.constants;

    const errorText = {
        [ALPHANUMERIC_ERROR]: strings.ACCESS_CODE_MUST_BE_ALPHANUMERIC,
        [NOT_FOUND_ERROR]: strings.SORRY_THAT_CODE_DOES_NOT_EXIST,
        [NO_PERMISSION_ERROR]: strings.YOU_DO_NOT_HAVE_PERMISSION,
    }

    const enableJoinButton = () => {
        setJoinButtonDisable(false);
        setStartLoading(false);
    }

    const handleCleanAndCloseModal = (_, reason) => {
        if (reason === 'backdropClick') return; // Closing modal just on event over close button.
        setCodeValue('');
        setJoinButtonDisable(true);
        setStartLoading(false);
        setErrorDisplay('none');
        closeModal();
    }

    const handlejoinButtonClick = async () => {
        setJoinButtonDisable(true);
        setStartLoading(true);

        if(!validations.common.alphaOnly.test(codeValue)) {
            setErrorMessage(errorText[ALPHANUMERIC_ERROR]);
            setErrorDisplay('block');
            enableJoinButton();
            return;
        }

        try {
            if (currentChatUser) {
                const { customerid, personid } = currentChatUser;
                const {status, data} = await joinDiscussionGroup({
                    customerid,
                    personid,
                    joincode: codeValue,
                    needsapproval: false,
                });

                if (status === 200) {
                    // To refresh sidebar chats
                    await getMemberOfByChatUser(currentChatUser);
                    // set current chat
                    if (data?.result?.conversation){
                        dispatch(setCurrentChat({...data.result.conversation}));
                    }
                }
                handleCleanAndCloseModal();
            }
        } catch (error){
            if(error?.response?.status === 404){
                setErrorMessage(errorText[NOT_FOUND_ERROR]);
            }else{
                setErrorMessage(errorText[NO_PERMISSION_ERROR]);
            }
            enableJoinButton();
            setErrorDisplay('block');
        }
    };

    const handleOnChangeInputCode = event => {
        const {value} = event.target;
        const disabled = value === '' ? true : false;
        setJoinButtonDisable(disabled);
        setCodeValue(value);
        setErrorDisplay('none');
    };

    return (
        <Modal
            aria-label={strings.JOIN_GROUP}
            open={open}
            onClose={handleCleanAndCloseModal}
        >
            <Box sx={styles.ModalJoinGroupBox}>
                <Stack sx={styles.ModalJoinGroupBoxStack}>
                    <IconButton
                        onClick={handleCleanAndCloseModal}
                        size="small"
                        sx={styles.ModalJoinGroupCloseButton}
                        aria-label={strings.CLOSE}
                    >
                        <CloseIcon sx={styles.ModalJoinGroupCloseButtonIcon} />
                    </IconButton>
                    <Stack sx={{...styles.ModalJoinGroupBoxStack, ...styles.padding15px}}>
                        <Typography
                            tabIndex={0}
                            sx={styles.ModalJoinGroupTypoTittleOne}
                        >
                            {strings.JOIN_GROUP}
                        </Typography>
                        <Typography
                            tabIndex={0}
                            sx={{...styles.ModalJoinGroupTypoCommText, ...styles.marginTop12px}}
                        >
                            {strings.TYPE_THE_GROUP_ACCESS_CODE_BELOW}
                        </Typography>
                        <TextField 
                            label={strings.ACCESS_CODE}
                            sx={styles.codeTextField}
                            onChange={handleOnChangeInputCode}
                            inputProps={styles.codeTextFieldInputProps}
                            variant="outlined" 
                        />
                        <FormHelperText 
                            tabIndex={0}
                            aria-live='assertive' 
                            sx={{...styles.ErrorField, display: errorDisplay}} 
                            error >
                            {errorMessage}.
                        </FormHelperText>
                        <Typography
                            tabIndex={0}
                            sx={styles.ModalJoinGroupTypoTittleTwo}
                        >
                            {strings.DO_NOT_HAVE_A_CODE}
                        </Typography>
                        <Typography
                            tabIndex={0}
                            sx={{...styles.ModalJoinGroupTypoCommText, ...styles.marginBottom23px, ...styles.marginTop0px}}
                        >
                            {strings.ASK_THE_ADMIN_TO_SHARE_THE_ACCESS_CODE_FOR_THE_GROUP_YOU_ARE_TRYING_TO_JOIN}
                        </Typography>
                    </Stack>
                    <ButtonGroup
                        aria-label={strings.CHAT_ARIA_LABEL_FOOTER_TOOLBAR}
                        disableElevation
                        sx={styles.ModalJoinGroupButtonGroup}
                    >
                        <Button
                            onClick={handleCleanAndCloseModal}
                            sx={styles.ModalJoinGroupCancelButton}
                            aria-label={strings.CANCEL}
                        >
                            {strings.CANCEL}
                        </Button>
                        <Box 
                            sx={styles.ButttonBox}
                            aria-label={strings.JOIN}
                            role="button">
                            <Button
                                sx={styles.ModalJoinGroupJoinButton}
                                disabled={joinButtonDisable}
                                onClick={handlejoinButtonClick}
                                variant="contained"
                            >
                                {strings.JOIN}
                            </Button>
                            {startLoading && <CircularProgress role="status" aria-live="assertive" aria-label={strings.LOADING} size={24} sx={styles.CircularProgress} />}
                        </Box>
                    </ButtonGroup>
                </Stack>
            </Box>
        </Modal>
    );
}
