import { createTheme } from '@mui/material/styles';
import { palette, typography } from 'comm-recipientapp-shared';
import Hornbill from '../../fonts/Hornbill.woff2';
import Inter from '../../fonts/Inter.woff2';

const theme = createTheme({
    palette: {
        ...palette,
        background: {
            default: '#F2F7FA',
        },
    },
    typography: {
        fontFamily: 'Inter',
        button: {
            textTransform: "none"
        },
        useNextVariants: true,
        ...typography
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                    font-family: 'Hornbill';
                    font-style: normal;
                    font-display: swap;
                    font-weight: 400;
                    src: local('Hornbill'), local('Hornbill-Regular'), url(${Hornbill}) format('woff2');
                    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                }
                @font-face {
                    font-family: 'Inter';
                    font-style: normal;
                    font-display: swap;
                    font-weight: 400;
                    src: local('Inter'), local('Inter-Regular'), url(${Inter}) format('woff2');
                    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                }
            `,
        },
    },
    overrides: {
        MuiFormControl: {
            root: {
                marginBottom: 20
            }
        },
        MuiFormHelperText: {
            root: {
                minHeight: 0
            }
        },
        MuiExpansionPanel: {
            root: {
                marginTop: 20,
                boxShadow: 'none',
                '&:before': {
                    display: 'none'
                }
            }
        },
        MuiOutlinedInput: {
            notchedOutline: {
                border: 0
            }
        },
        MuiListItem: {
            gutters: {
                paddingLeft: 24
            }
        },
        MuiCardActions: {
            root: {
                justifyContent: 'flex-end'
            }
        },
    }
});

export default theme;
