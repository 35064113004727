import React from 'react';

export default function Calendar(props) {
    return (
        <svg data-icon="calendar" xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
            <g clipPath="url(#a)">
                <path d="M3.75 10.05h16.5V6.3a.75.75 0 0 0-.75-.75h-15a.75.75 0 0 0-.75.75v3.75Z" fill="#A1A4F1" />
                <path d="M4 19.8v-10h16v10H4Z" fill="#fff" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3 6.133C3 5.397 3.672 4.8 4.5 4.8h15c.828 0 1.5.597 1.5 1.333v13.334c0 .736-.672 1.333-1.5 1.333h-15c-.828 0-1.5-.597-1.5-1.333V6.133Zm16.5 0h-15v13.334h15V6.133Z"
                    fill="#33366A"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.5 3.3a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-1.5 0v-3a.75.75 0 0 1 .75-.75ZM7.5 3.3a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-1.5 0v-3a.75.75 0 0 1 .75-.75ZM3 10.05a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z"
                    fill="#33366A"
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 0h24v24H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}
