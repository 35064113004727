import React from 'react';
import Inbox from '../../components/Inbox';
import MessageDetails from '../../components/Inbox/MessageDetails';

const customRoutes = [
    {
        path: '*',
        title: 'Inbox',
        element: <Inbox />,
    },
    {
        path: '/inbox',
        title: 'Inbox',
        exact: true,
        element: <Inbox />,
    },
    {
        path: 'inbox/message-details',
        title: 'Message Details',
        exact: true,
        element: <MessageDetails />,
    }
];

export default customRoutes;
