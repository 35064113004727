import theme from '../../../../config/style/muiTheme';

const styles = {
    dialog: {
        borderRadius: 0,
    },
    content_title: {
        paddingBottom: 10,
        borderBottom: '1px solid #ddd',
        marginTop: 20,
        marginBottom: 15,
    },
    title: {
        color: theme.palette.blue['130'],
        fontWeight: 300,
        fontSize: 22,
    },
    card: {
        background: '#fff',
        display: 'flex',
        flexDirection: 'row',
        height: 100,
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    card_info: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        alignItems: 'center',
    },
    card_information: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '15px',
        borderLeft: '1px solid #eee',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'flex-start',
        marginLeft: '20px',
        color: theme.palette.blue['140'],
    },
    card_information_list: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '7px',
    },
    ok_call: {
        paddingRight: '12px',
        fontSize: '14px',
        paddingBottom: '6px',
    },
    ok_call_phone: {
        paddingRight: '12px',
        fontSize: '14px',
        position: 'relative',
        top: '2px',
    },
    info_bellow: {
        margintop: '15px',
        display: 'block',
    },
    consent: {
        fontSize: '13px',
    },
    spacer: {
        color: theme.palette.blue['140'],
    },
    card_button: {
        marginLeft: '5px',
        position: 'relative',
        top: '3px',
        padding: 0,
    },
    card_status: {
        background: '#43AC6A',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100px',
        height: '100%',
    },
    card_status_unavailable: {
        background: '#A4B5C5',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100px',
        height: '100%',
    },
    card_circle: {
        width: 42,
        height: 42,
        borderRadius: 21,
        border: '1px #fff solid',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    icon_color: {
        color: '#fff',
    },
    value: {
        paddingLeft: '10px',
        fontSize: '18px',
        color: theme.palette.blue['130'],
        fontWeight: 300,
    },
    content: {
        marginTop: '30px',
        marginBottom: '30px',
    },
    titles: {
        fontWeight: 300,
    },
    box_list: {
        marginTop: '25px',
    },
    message_card: {
        borderRadius: '0px',
        boxShadow: '0 1px 4px rgb(0 0 0 / 10%)',
        position: 'relative',
        width: '100%',
    },
    message_card_bg: {
        alignItems: 'center',
        backgroundColor: 'primary.main',
        display: 'flex',
        height: '99px',
        justifyContent: 'center',
        position: 'relative',
    },
    message_card_title: {
        color: '#fff',
        fontSize: 20,
        fontWeight: 300,
    },
    message_card_bottom: {
        alignItems: 'center',
        backgroundColor: '#ffffff',
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '15px',
    },
    banner: {
        backgroundColor: theme.palette.primary.main,
        height: '59px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
        fontWeight: 300,
    },
    modal_content: {
        padding: '40px 40px 20px',
    },
    select: {
        width: '100%',
        borderColor: '#cccccc',
        padding: '0.5rem',
        borderRadius: 0,
        height: '2.3125rem',
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '10px',
        paddingBottom: '15px',
        gap: '20px',
    },
    actions_button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '10px',
        marginTop: '20px',
    },
    btn: {
        background: 'white',
        color: theme.palette.primary.main,
    },
    survey_title: {
        color: theme.palette.blue['140'],
        fontSize: 11,
        fontWeight: 300,
    },
    dialogHeader: {
        background: '#ff7978',
        padding: '25px 25px 15px',
        color: '#fff',
        textAlign: 'center',
        width: 550,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    dialogTitle: {
        paddingLeft: '4px',
        fontSize: '1.3rem',
        fontWeight: 300,
    },
    modalContent: {
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    errorLegend: {
        fontWeight: 300,
        margin: '20px 0px 25px',
    },
    button_ok: {
        textTransform: 'uppercase',
        backgroundColor: '#ff7978',
        width: '87px',
        borderRadius: '2px',
        fontWeight: '300',
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: '#ff7978',
        },
    },
    ButttonBox: { position: 'relative' },
    CircularProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-12px',
        marginLeft: '-12px',
    },
};

export default styles;
