import { styled } from '@mui/material';

const Title = styled('h1')({
    fontSize: '34px',
    fontStyle: 'italic',
    lineHeight: '46px',
    textDecoration: 'none solid rgb(44, 88, 113)',
});


export default Title;
