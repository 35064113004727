/* eslint-disable no-unused-expressions */
/* eslint-disable operator-linebreak */
/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Box,
    Button,
    Container,
    Grid,
} from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { utils, hooks } from 'comm-recipientapp-shared';
import { setCurrentChat } from 'comm-recipientapp-shared/src/store/chat/chatSlice';
import Layout from '../Layout';
import ChatTitle from './ChatTitle';
import SkeletonTitle from './ChatTitle/skeletonTitle';
import Logo from './Logo';
import SearchBar from './SearchBar';
import Conversations from './Conversations';
import RCIframe from './RCIframe';
import JoinGroupModal from './JoinGroupModal'
import NewDirectMessageModal from './NewDirectMessageModal';
import DefaultOverlay from './DefaultOverlay';
import DisabledOverlay from './DisabledOverlay';
import CustomerSelect from './CustomerSelect';
import LoginErrorDialog from './LoginErrorDialog';
import styles from './styles';

function Chat() {
    const dispatch = useDispatch();
    const strings = utils.useStrings();

    const [showJoinModal, setShowJoinModal] = useState(false);
    const [showNewDirectMessageModal, setShowNewDirectMessageModal] = useState(false);
    const [fetchingMemberOf, setFetchingMemberOf] = useState(true);
    const [showOverlay, setShowOverlay] = useState(false);
    const [showPanel, setShowPanel] = useState('conversations');
    const [loginFailed, setLoginFailed] = useState(false);

    const [iframeUrl, setIframeUrl] = useState(null);
    const [iframePermissions, setIframePermissions] = useState(null);
    const [firstChatRoomName, setFirstChatRoomName] = React.useState(null);
    const { RC_IFRAME_ALLOW_PERMISSIONS } = utils.constants;

    const {
        currentChat,
        currentChatUser,
        firstChat,
        rcInitialized
    } = useSelector(state => state.chat);

    const { hasChat } = hooks.menuItems.useMenuItem();
    const { getMemberOfByChatUser, getLogInFirstChatUser } = hooks.chat.useChat();

    const { account: { chatUsers } } = useSelector(state => state.user) || [];

    const _asyncGetMemberOf = async () => {
        setFetchingMemberOf(true);
        await getMemberOfByChatUser(currentChatUser);
        setFetchingMemberOf(false);
    };

    const _asyncLoginFirstChatUser = async () => {
        const userLoggedIn = await getLogInFirstChatUser();
        if (!userLoggedIn) {
            setLoginFailed(true);
        }
    }

    const getRcRoomUrl = currentChatData => {
        const rcroomHome = currentChatData.rcroomurls.find((x) => x.environment === 'home');
        const rcHomeRoomUrl = rcroomHome.url;
        const split = rcHomeRoomUrl.split('/', 3);
        const rcHomeUrl = `https://${split[2]}`;
        return rcHomeUrl;
    };

    const getIframePermissions = (rcRoomUrl) => {
        return RC_IFRAME_ALLOW_PERMISSIONS.replace(/;/g, ` ${rcRoomUrl};`);
    };

    useEffect(() => {
        _asyncLoginFirstChatUser();
    }, []);

    useEffect(() => {
        if (currentChatUser) {
            setShowOverlay(false);
            _asyncGetMemberOf();
        }
    }, [currentChatUser])

    useEffect(() => {
        if (currentChat && !iframeUrl) {
            const rcRoomUrl = getRcRoomUrl(currentChat);
            setIframeUrl(rcRoomUrl);
            setIframePermissions(getIframePermissions(rcRoomUrl));
            setFirstChatRoomName(currentChat.rcroomname);
        }
    }, [currentChat])

    useEffect(() => {
        if ((!fetchingMemberOf) && (!firstChat)) {
            setShowOverlay(true);
        }
    }, [fetchingMemberOf, firstChat])

    useEffect(() => {
        firstChat && dispatch(setCurrentChat(firstChat));
    }, [firstChat])

    const hasCustomerSelect = chatUsers.length > 1;
    const showChatTitleSkeleton = !currentChat || fetchingMemberOf;
    const rcIframeReady = (
        currentChatUser &&
        currentChat &&
        iframeUrl &&
        iframePermissions &&
        firstChatRoomName
    );

    return (
        <Layout>
            {(!hasChat) && (
                <DisabledOverlay />
            )}
            {hasChat && (
                <>
                    <LoginErrorDialog loginFailed={loginFailed}  />
                    <Container 
                        sx={styles.chatContainer} 
                        disableGutters>
                        <Grid
                            container
                            item
                            xs={12} sm={12} md={12} lg={3} xl={3}
                            direction="column"
                            wrap="nowrap"
                            sx={{
                                display: {
                                    xs: showPanel === 'conversations' ? 'flex' : 'none',
                                    sm: showPanel === 'conversations' ? 'flex' : 'none',
                                    md: showPanel === 'conversations' ? 'flex' : 'none',
                                    lg: 'flex',
                                    xl: 'flex'
                                },
                            }}>
                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                <Box 
                                    aria-label={strings.CHAT_ARIA_LABEL_CHAT_LOGO_AND_CONVERSATION_SEARCH_TOOLBAR}
                                    sx={styles.leftPanel.top}>
                                    <Logo />
                                    <SearchBar />
                                    {hasCustomerSelect && (
                                        <CustomerSelect />
                                    )}
                                </Box>
                            </Grid>
                            <Grid item xs={10} sm={10} md={10} lg={10} xl={10} sx={{ overflow: "auto" }}>
                                <Box 
                                    sx={styles.leftPanel.bottom} 
                                    aria-label={strings.CHAT_ARIA_LABEL_AVAILABLE_CHAT_CONVERSATIONS}>
                                    {currentChatUser && (
                                        <Conversations
                                            aria-label={strings.CHAT_ARIA_LABEL_CONVERSATIONS_CONTAINER}
                                            key={currentChatUser.personid}
                                            setShowJoinModal={setShowJoinModal}
                                            setShowPanel={setShowPanel}
                                            fetchingMemberOf={fetchingMemberOf}
                                            setShowNewDirectMessageModal={setShowNewDirectMessageModal}
                                        />
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12} sm={12} md={12} lg={9} xl={9}
                            direction="column"
                            wrap="nowrap"
                            sx={{
                                display: {
                                    xs: showPanel === 'chat' ? 'flex' : 'none',
                                    sm: showPanel === 'chat' ? 'flex' : 'none',
                                    md: showPanel === 'chat' ? 'flex' : 'none',
                                    lg: 'flex',
                                    xl: 'flex'
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    display: {
                                        xs: 'flex',
                                        sm: 'flex',
                                        md: 'flex',
                                        lg: 'none',
                                        xl: 'none'
                                    },
                                }}>
                                <Box sx={styles.rightPanel.navBar}>
                                    <Button
                                        sx={styles.rightPanel.navBar.backButton}
                                        onClick={() => setShowPanel('conversations')}>
                                        <ArrowBackIosNewIcon sx={{ marginRight: 1 }} fontSize="small" />
                                        {strings.BACK}
                                    </Button>
                                </Box>
                            </Box>
                            <Grid item lg={2}>
                                <Box sx={styles.rightPanel.top}>
                                    {(currentChatUser && showChatTitleSkeleton) && (
                                        <SkeletonTitle
                                            showOverlay={showOverlay}
                                        />
                                    )}
                                    {(currentChatUser && !showChatTitleSkeleton) && (
                                        <ChatTitle
                                            key={currentChatUser.personid}
                                            currentChat={currentChat}
                                            rcInitialized={rcInitialized}
                                        />
                                    )}
                                </Box>
                            </Grid>
                            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                                {showOverlay && (
                                    <DefaultOverlay />
                                )}
                                {rcIframeReady && (
                                    <RCIframe
                                        key={currentChatUser.personid}
                                        currentChatUser={currentChatUser}
                                        currentChat={currentChat}
                                        rcInitialized={rcInitialized}
                                        showOverlay={showOverlay}
                                        iframePermissions={iframePermissions}
                                        iframeUrl={iframeUrl}
                                        firstChatRoomName={firstChatRoomName}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Container>
                    <JoinGroupModal
                        open={showJoinModal}
                        closeModal={() => { setShowJoinModal(false); }}
                    />
                    <NewDirectMessageModal
                        open={showNewDirectMessageModal}
                        closeModal={() => { setShowNewDirectMessageModal(false); }}
                    />
                </>
            )}
        </Layout>
    );
}
export default Chat;

