import theme from '../../../config/style/muiTheme';
import chatBackground from 'comm-recipientapp-shared/src/assets/chatBackground.svg';

const styles = {
    containerBox: {
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        backgroundImage: `url(${chatBackground})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
    },
    logoContainer: {
        width: '15%',
        height: 'auto',
        marginBottom: '6px',
    },
    loadingText: {
        color: theme.palette.primary.main,
        textAlign: 'center',
    },
};

export default styles;
