import React from 'react';
import { Alert, AlertTitle } from '@mui/material';

import { utils } from 'comm-recipientapp-shared';
import styles from './styles';

function ShowError(errorCode = null) {
    const strings = utils.useStrings();

    const getContentByCode = () => {
        let body = null;
        if (errorCode?.errorCode === 400) {
            body = (
                <p style={styles.alertSpan} aria-label={strings.PLEASE_DOUBLE_CHECK_YOUR_LOGIN}>
                    <span style={styles.alertSpanBold}>{strings.PLEASE_DOUBLE_CHECK_YOUR_LOGIN}</span>
                </p>
            );
        }
        if (errorCode?.errorCode === 401) {
            body = (
                <p style={styles.alertSpan} aria-label={strings.PLEASE_DOUBLE_CHECK_YOUR_LOGIN}>
                    <span style={styles.alertSpanBold}>{strings.PLEASE_DOUBLE_CHECK_YOUR_LOGIN}</span>
                </p>
            );
        }
        if (errorCode?.errorCode === 500) {
            body = (
                <p style={styles.alertSpan} aria-label={strings.INTERNAL_DATA_ERROR}>
                    <span style={styles.alertSpanBold}>{strings.INTERNAL_DATA_ERROR}</span>
                </p>
            );
        }
        return body;
    };


    const content = getContentByCode();

    return errorCode ? (
        <Alert icon={false} severity="error" sx={styles.error} aria-live="assertive">
            <AlertTitle sx={styles.errorTitle} aria-label={strings.LOGIN_FAILED}>
                {strings.LOGIN_FAILED}
            </AlertTitle>
            {content}
        </Alert>
    ) : null;
}

export default ShowError;
