/* eslint-disable import/prefer-default-export */
import { useState } from 'react';
import { ApiInterceptorKeys } from '../../api/constants';

export function useHandleServerErrorSnackbar() {
    const [hasServerError, setHasServerError] = useState(
        localStorage.getItem(ApiInterceptorKeys.FATAL_SERVER_ERROR) === 'true',
    );

    const handleErrorSnackbarClose = () => {
        setHasServerError(false);
        localStorage.removeItem(ApiInterceptorKeys.FATAL_SERVER_ERROR);
    };

    return { hasServerError, handleErrorSnackbarClose };
}
