import React, { useMemo } from 'react';
import { MessageCategoriesTypes, MessageFilterSectionsTypes, MessageStatusTypes } from 'comm-recipientapp-shared/src/utils/constants';
import Emergency from '../Icons/Emergency';
import Calendar from '../Icons/Calendar';
import Unread from '../Icons/Unread';
import Read from '../Icons/Read';
import SecondaryCornet from '../Icons/SecondaryCornet';
import styles from './styles';

export const capitilizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

export const capitilizeEachFirstLetter = (string) => string.toLowerCase().split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

export const SECTIONS_WITH_EXTERNAL_DATA = [MessageFilterSectionsTypes.STUDENT, MessageFilterSectionsTypes.CATEGORIES, MessageFilterSectionsTypes.FROM];

const iconsMap = {
    [MessageFilterSectionsTypes.CATEGORIES]: {
        [MessageCategoriesTypes.GENERAL]: SecondaryCornet,
        [MessageCategoriesTypes.EMERGENCIES]: Emergency,
        [MessageCategoriesTypes.ATTENDANCE]: Calendar,
    },
    [MessageFilterSectionsTypes.STATUS]: {
        [MessageStatusTypes.UNREAD]: Unread,
        [MessageStatusTypes.READ]: Read,
    },
};

export function GetIcon(props) {
    const { section, filter } = props;

    const Icon = useMemo(() => iconsMap[section]?.[filter], [section, filter]);

    if (Icon) {
        return <Icon style={styles.filter_icon_styles} />;
    }
    return null;
}

export const getLabel = (index, strings) => {
    let label = '';
    switch (index) {
        case 0:
        label = strings.STATUS;
        break;
        case 1:
        label = strings.BROADCAST_TYPE;
        break;
        case 2:
        label = strings.CONTACT;
        break;
        case 3:
        label = strings.FROM_FIRST_UPPERCASE;
        break;
        default:
        label = '';
    }
    return label.charAt(0).toUpperCase() + label.slice(1);
};
