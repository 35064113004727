const styles = {
    item: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '6px',
        boxShadow: '1px 1px 3px #aaa',
        padding: '3px',
    },
    item_info: {
        display: 'flex',
        alignItems: 'center',
    },
    studentName: {
        paddingLeft: '10px',
    },
};

export default styles;
